/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTaxGroup } from '../models/api-response-ienumerable-tax-group';
import { ApiResponseTaxGroup } from '../models/api-response-tax-group';
import { TaxGroupCreateRequest } from '../models/tax-group-create-request';
import { TaxGroupUpdateRequest } from '../models/tax-group-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class TaxGroupsService extends __BaseService {
  static readonly GetTaxGroupsPath = '/TaxGroups';
  static readonly CreateTaxGroupPath = '/TaxGroups';
  static readonly UpdateTaxGroupPath = '/TaxGroups/{taxId}';
  static readonly GetTaxGroupByIdPath = '/TaxGroups/{taxId}';
  static readonly DeleteTaxGroupPath = '/TaxGroups/{taxId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all the tax groups.
   * @return Success
   */
  GetTaxGroupsResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTaxGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/TaxGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTaxGroup>;
      })
    );
  }
  /**
   * Get all the tax groups.
   * @return Success
   */
  GetTaxGroups(): __Observable<ApiResponseIEnumerableTaxGroup> {
    return this.GetTaxGroupsResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTaxGroup)
    );
  }

  /**
   * Create a new tax group.
   * @param taxCreateRequest The tax group create request.
   * @return Success
   */
  CreateTaxGroupResponse(taxCreateRequest?: TaxGroupCreateRequest): __Observable<__StrictHttpResponse<ApiResponseTaxGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = taxCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/TaxGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTaxGroup>;
      })
    );
  }
  /**
   * Create a new tax group.
   * @param taxCreateRequest The tax group create request.
   * @return Success
   */
  CreateTaxGroup(taxCreateRequest?: TaxGroupCreateRequest): __Observable<ApiResponseTaxGroup> {
    return this.CreateTaxGroupResponse(taxCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseTaxGroup)
    );
  }

  /**
   * Update an existing tax group.
   * @param params The `TaxGroupsService.UpdateTaxGroupParams` containing the following parameters:
   *
   * - `taxId`: The tax group id.
   *
   * - `taxUpdateRequest`: The tax group update request.
   *
   * @return Success
   */
  UpdateTaxGroupResponse(params: TaxGroupsService.UpdateTaxGroupParams): __Observable<__StrictHttpResponse<ApiResponseTaxGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.taxUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/TaxGroups/${encodeURIComponent(String(params.taxId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTaxGroup>;
      })
    );
  }
  /**
   * Update an existing tax group.
   * @param params The `TaxGroupsService.UpdateTaxGroupParams` containing the following parameters:
   *
   * - `taxId`: The tax group id.
   *
   * - `taxUpdateRequest`: The tax group update request.
   *
   * @return Success
   */
  UpdateTaxGroup(params: TaxGroupsService.UpdateTaxGroupParams): __Observable<ApiResponseTaxGroup> {
    return this.UpdateTaxGroupResponse(params).pipe(
      __map(_r => _r.body as ApiResponseTaxGroup)
    );
  }

  /**
   * Get a particular tax by ID.
   * @param taxId The tax id.
   * @return Success
   */
  GetTaxGroupByIdResponse(taxId: number): __Observable<__StrictHttpResponse<ApiResponseTaxGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/TaxGroups/${encodeURIComponent(String(taxId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTaxGroup>;
      })
    );
  }
  /**
   * Get a particular tax by ID.
   * @param taxId The tax id.
   * @return Success
   */
  GetTaxGroupById(taxId: number): __Observable<ApiResponseTaxGroup> {
    return this.GetTaxGroupByIdResponse(taxId).pipe(
      __map(_r => _r.body as ApiResponseTaxGroup)
    );
  }

  /**
   * Deletes a tax by its ID.
   * @param taxId The tax id.
   * @return Success
   */
  DeleteTaxGroupResponse(taxId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/TaxGroups/${encodeURIComponent(String(taxId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a tax by its ID.
   * @param taxId The tax id.
   * @return Success
   */
  DeleteTaxGroup(taxId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteTaxGroupResponse(taxId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module TaxGroupsService {

  /**
   * Parameters for UpdateTaxGroup
   */
  export interface UpdateTaxGroupParams {

    /**
     * The tax group id.
     */
    taxId: number;

    /**
     * The tax group update request.
     */
    taxUpdateRequest?: TaxGroupUpdateRequest;
  }
}

export { TaxGroupsService }
