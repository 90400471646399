import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'booleanOnOffPipe',
})
export class BooleanOnOffPipe implements PipeTransform {
    transform(value: boolean): string {
        return value === true ? 'On' : 'Off';
    }
}
