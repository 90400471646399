export class MediaConstants {
    public static ImageExtensions = ['png', 'tiff', 'tif', 'jpeg', 'jpg', 'gif', 'bmp', 'bmpf', 'ico', 'cur', 'xbm'];
    public static VideoExtensions = ['mp4', 'mov', 'm4v'];
    public static AudioExtensions = ['m4a', 'wav', 'mp3', 'ogg'];
    public static PdfExtension = 'pdf';

    public static AllSupportedExtensions = [
        MediaConstants.PdfExtension,
        ...MediaConstants.ImageExtensions,
        ...MediaConstants.VideoExtensions,
        ...MediaConstants.AudioExtensions,
    ];

    public static TemplateTag = 'PC';
}
