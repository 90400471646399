/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableCptCodeItemSettings } from '../models/api-response-ienumerable-cpt-code-item-settings';
import { CptCodeItemSettingCreateUpdateRequest } from '../models/cpt-code-item-setting-create-update-request';
import { ItemCptModifiersUpdateRequest } from '../models/item-cpt-modifiers-update-request';
@Injectable({
  providedIn: 'root',
})
class ItemsCptCodesService extends __BaseService {
  static readonly GetCptCodeItemSettingsPath = '/Insurance/Items/{itemId}/CptCodes';
  static readonly CreateCptCodeItemSettingPath = '/Insurance/Items/{itemId}/CptCodes';
  static readonly GetCptCodeItemSettingsByCptCodePath = '/Insurance/CptCodes/{cptCodeId}/Items';
  static readonly UpdateCptCodeItemSettingPath = '/Insurance/Items/{itemId}/CptCodes/{cptCodeItemSettingsId}';
  static readonly DeleteCptCodeItemSettingPath = '/Insurance/Items/{itemId}/CptCodes/{cptCodeItemSettingsId}';
  static readonly UpdateItemCptModifiersSettingPath = '/Insurance/Items/{itemId}/CptCodes/{cptCodeItemSettingsId}/CptModifiers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of CPT code item settings.
   * @param itemId The item id.
   * @return Success
   */
  GetCptCodeItemSettingsResponse(itemId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(itemId))}/CptCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>;
      })
    );
  }
  /**
   * Gets a list of CPT code item settings.
   * @param itemId The item id.
   * @return Success
   */
  GetCptCodeItemSettings(itemId: number): __Observable<ApiResponseIEnumerableCptCodeItemSettings> {
    return this.GetCptCodeItemSettingsResponse(itemId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptCodeItemSettings)
    );
  }

  /**
   * Create a CPT code item settings.
   * @param params The `ItemsCptCodesService.CreateCptCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingCreateRequest`: The CPT code item settings to create.
   *
   * @return Success
   */
  CreateCptCodeItemSettingResponse(params: ItemsCptCodesService.CreateCptCodeItemSettingParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.cptCodeItemSettingCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(params.itemId))}/CptCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>;
      })
    );
  }
  /**
   * Create a CPT code item settings.
   * @param params The `ItemsCptCodesService.CreateCptCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingCreateRequest`: The CPT code item settings to create.
   *
   * @return Success
   */
  CreateCptCodeItemSetting(params: ItemsCptCodesService.CreateCptCodeItemSettingParams): __Observable<ApiResponseIEnumerableCptCodeItemSettings> {
    return this.CreateCptCodeItemSettingResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptCodeItemSettings)
    );
  }

  /**
   * Get a list of item ids with insurance settings.
   * @param cptCodeId The CPT Code identifier.
   * @return Success
   */
  GetCptCodeItemSettingsByCptCodeResponse(cptCodeId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/CptCodes/${encodeURIComponent(String(cptCodeId))}/Items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>;
      })
    );
  }
  /**
   * Get a list of item ids with insurance settings.
   * @param cptCodeId The CPT Code identifier.
   * @return Success
   */
  GetCptCodeItemSettingsByCptCode(cptCodeId: number): __Observable<ApiResponseIEnumerableCptCodeItemSettings> {
    return this.GetCptCodeItemSettingsByCptCodeResponse(cptCodeId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptCodeItemSettings)
    );
  }

  /**
   * Update a CPT code item settings.
   * @param params The `ItemsCptCodesService.UpdateCptCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingsId`: The CPT Code settings Id.
   *
   * - `cptCodeItemSettingUpdateRequest`: The CPT code item settings to update.
   *
   * @return Success
   */
  UpdateCptCodeItemSettingResponse(params: ItemsCptCodesService.UpdateCptCodeItemSettingParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.cptCodeItemSettingUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(params.itemId))}/CptCodes/${encodeURIComponent(String(params.cptCodeItemSettingsId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>;
      })
    );
  }
  /**
   * Update a CPT code item settings.
   * @param params The `ItemsCptCodesService.UpdateCptCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingsId`: The CPT Code settings Id.
   *
   * - `cptCodeItemSettingUpdateRequest`: The CPT code item settings to update.
   *
   * @return Success
   */
  UpdateCptCodeItemSetting(params: ItemsCptCodesService.UpdateCptCodeItemSettingParams): __Observable<ApiResponseIEnumerableCptCodeItemSettings> {
    return this.UpdateCptCodeItemSettingResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptCodeItemSettings)
    );
  }

  /**
   * Delete a CPT code item settings.
   * @param params The `ItemsCptCodesService.DeleteCptCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingsId`: The CPT Code settings Id.
   *
   * @return Success
   */
  DeleteCptCodeItemSettingResponse(params: ItemsCptCodesService.DeleteCptCodeItemSettingParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(params.itemId))}/CptCodes/${encodeURIComponent(String(params.cptCodeItemSettingsId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>;
      })
    );
  }
  /**
   * Delete a CPT code item settings.
   * @param params The `ItemsCptCodesService.DeleteCptCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingsId`: The CPT Code settings Id.
   *
   * @return Success
   */
  DeleteCptCodeItemSetting(params: ItemsCptCodesService.DeleteCptCodeItemSettingParams): __Observable<ApiResponseIEnumerableCptCodeItemSettings> {
    return this.DeleteCptCodeItemSettingResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptCodeItemSettings)
    );
  }

  /**
   * Update the CPT modifiers item settings.
   * @param params The `ItemsCptCodesService.UpdateItemCptModifiersSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingsId`: The CPT Code settings Id.
   *
   * - `itemCptModifiersUpdateRequest`: The CPT modifier item settings to update.
   *
   * @return Success
   */
  UpdateItemCptModifiersSettingResponse(params: ItemsCptCodesService.UpdateItemCptModifiersSettingParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.itemCptModifiersUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(params.itemId))}/CptCodes/${encodeURIComponent(String(params.cptCodeItemSettingsId))}/CptModifiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptCodeItemSettings>;
      })
    );
  }
  /**
   * Update the CPT modifiers item settings.
   * @param params The `ItemsCptCodesService.UpdateItemCptModifiersSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `cptCodeItemSettingsId`: The CPT Code settings Id.
   *
   * - `itemCptModifiersUpdateRequest`: The CPT modifier item settings to update.
   *
   * @return Success
   */
  UpdateItemCptModifiersSetting(params: ItemsCptCodesService.UpdateItemCptModifiersSettingParams): __Observable<ApiResponseIEnumerableCptCodeItemSettings> {
    return this.UpdateItemCptModifiersSettingResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptCodeItemSettings)
    );
  }
}

module ItemsCptCodesService {

  /**
   * Parameters for CreateCptCodeItemSetting
   */
  export interface CreateCptCodeItemSettingParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The CPT code item settings to create.
     */
    cptCodeItemSettingCreateRequest?: CptCodeItemSettingCreateUpdateRequest;
  }

  /**
   * Parameters for UpdateCptCodeItemSetting
   */
  export interface UpdateCptCodeItemSettingParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The CPT Code settings Id.
     */
    cptCodeItemSettingsId: number;

    /**
     * The CPT code item settings to update.
     */
    cptCodeItemSettingUpdateRequest?: CptCodeItemSettingCreateUpdateRequest;
  }

  /**
   * Parameters for DeleteCptCodeItemSetting
   */
  export interface DeleteCptCodeItemSettingParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The CPT Code settings Id.
     */
    cptCodeItemSettingsId: number;
  }

  /**
   * Parameters for UpdateItemCptModifiersSetting
   */
  export interface UpdateItemCptModifiersSettingParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The CPT Code settings Id.
     */
    cptCodeItemSettingsId: number;

    /**
     * The CPT modifier item settings to update.
     */
    itemCptModifiersUpdateRequest?: ItemCptModifiersUpdateRequest;
  }
}

export { ItemsCptCodesService }
