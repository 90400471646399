import MurmurHash3 from 'imurmurhash';
import {
    getBrowserInfoFingerprint,
    getCanvasFingerprint,
    getScreenFingerprint,
    getWebglFingerprint,
    getWebglInfoFingerprint,
} from './strategies';

export function getBrowserFingerprint(): string {
    const hashState = new MurmurHash3();

    const browserFingerprint = getBrowserInfoFingerprint();
    const screenFingerprint = getScreenFingerprint();
    const canvasFingerprint = getCanvasFingerprint();
    const webglInfoFingerprint = getWebglInfoFingerprint();
    const webglFingerprint = getWebglFingerprint();

    [browserFingerprint, screenFingerprint, canvasFingerprint, webglInfoFingerprint, webglFingerprint]
        .filter((value) => !!value)
        .forEach((value) => hashState.hash(value as string));

    return hashState.result().toString();
}
