import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

type AlignContent = 'left' | 'center' | 'right' | 'space-between';

@Component({
    selector: 'symplast-dialog-footer, [symplast-dialog-footer], [symplastDialogFooter]',
    standalone: true,
    imports: [CommonModule],
    template: `<div class="symplast-dialog-footer" [ngClass]="'symplast-dialog-footer--' + alignContent"><ng-content></ng-content></div>`,
    styles: [
        `
            :host {
                display: block;
                width: 100%;
                height: 100%;
            }

            .symplast-dialog-footer {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 20px;
                padding-top: 10px;
                box-sizing: border-box;
            }

            .symplast-dialog-footer--left {
                justify-content: flex-start;
            }

            .symplast-dialog-footer--right {
                justify-content: flex-end;
            }

            .symplast-dialog-footer--center {
                justify-content: center;
            }

            .symplast-dialog-footer--space-between {
                justify-content: space-between;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymplastDialogFooterComponent {
    private _fixed!: boolean;
    private _alignContent!: AlignContent;

    @Input()
    public get fixed(): boolean {
        if (typeof this._fixed === 'boolean') {
            return this._fixed;
        }

        return true;
    }
    public set fixed(value: boolean) {
        this._fixed = value;
    }

    @Input()
    public get alignContent(): AlignContent {
        return this._alignContent || 'space-between';
    }
    public set alignContent(value: AlignContent) {
        this._alignContent = value;
    }
}
