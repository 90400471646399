import { Injectable } from '@angular/core';
import { PatientsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadEmploymentTypes, ReloadEmploymentTypes } from './employment-types.actions';
import { IEmploymentTypesStateModel } from './employment-types.model';

@State<IEmploymentTypesStateModel>({
    name: 'employmentTypes',
    defaults: {
        employmentTypes: [],
        loading: false,
    },
})
@Injectable()
export class EmploymentTypesState {
    constructor(private patientService: PatientsService) {}

    @Action(LoadEmploymentTypes)
    public load(context: StateContext<IEmploymentTypesStateModel>, { refresh }: LoadEmploymentTypes) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.employmentTypes.length) || refresh) {
            context.patchState({ loading: true });
            this.patientService
                .GetEmploymentTypes()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    context.patchState({
                        employmentTypes: [...response.result],
                    });
                });
        }
    }

    @Action(ReloadEmploymentTypes)
    public reload(context: StateContext<IEmploymentTypesStateModel>) {
        context.dispatch(new LoadEmploymentTypes(true));
    }
}
