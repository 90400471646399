import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
    name: 'utcDate',
    standalone: true,
})
export class UtcDatePipe implements PipeTransform {
    public transform(value: string): any {
        if (!value) {
            return value;
        }
        const dateValue = moment(value.replace('Z', ''));

        return dateValue.toISOString();
    }
}
