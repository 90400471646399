<div class="dashboard homepage" data-e2e="home-page">
    <div class="head">
        <h1 class="mat-h1" data-e2e="home-page__title">Home</h1>
        <mat-divider></mat-divider>
        <div class="breadcrumb">
            <span class="current-item">Home</span>
        </div>
    </div>
    <div class="container mat-body homepage-wrapper">
        <app-announcement *ngIf="showSymplastPaymentsAnnouncement" url="/Settings/Integrations">
            <b>Announcing Symplast Payments -</b> a powerful and easy way to accept credit cards both in-person and online.
            <b>Click Here to Learn More...</b>
        </app-announcement>
        <br />

        <app-announcement *ngIf="showCalendarBlocksAnnouncement" url="/Settings/CalendarBlocks">
            <b>Announcing Calendar Blocks -</b> a simplified way to manage your practice app and online appointment request calendars all in
            one place. <b>Click Here to Learn More...</b>
        </app-announcement>

        <br />

        <app-announcement [url]="securityNotificationsUrl" [queryParams]="securityNotificationsQueryParams" [showClose]="true">
            <b>Announcing Security Notifications -</b> an enhanced security measure for your user account.
            <b>Click Here to Learn More...</b>
        </app-announcement>

        <app-announcement *ngIf="showMultiFactorAuthenticationAnnouncement" [url]="multiFactorAuthenticationUrl" [showClose]="true">
            <b>Announcing Multi-Factor Authentication -</b> an enhanced security measure for your user account.
            <b>Click Here to Learn More...</b>
        </app-announcement>

        <nav class="menu">
            <!--TODO: (a.vakhrushin) add trackBy pipe-->
            <ng-container *ngFor="let tab of userTabsService.userTabs">
                <ng-container *ngIf="!tab.visible$ || (tab.visible$ | async)">
                    <a [attr.data-e2e]="'home-page__tab-' + tab.e2e" [routerLink]="tab.link" class="menu-item" href="#">
                        <i>
                            <svg-icon
                                [attr.data-e2e]="'home-page__tab-' + tab.e2e + '__icon'"
                                [ngStyle]="{ color: tab.color }"
                                [src]="tab.iconSrc"></svg-icon>
                        </i>
                        <span [attr.data-e2e]="'home-page__tab-' + tab.e2e + '__text'">{{ tab.name }}</span>
                    </a>
                </ng-container>
            </ng-container>
        </nav>
    </div>
</div>
