import { Injectable } from '@angular/core';
import { PatientsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadEthnicities, ReloadEthnicities } from './ethnicities.actions';
import { IEthnicitiesStateModel } from './ethnicities.model';

@State<IEthnicitiesStateModel>({
    name: 'ethnicities',
    defaults: {
        ethnicities: [],
        loading: false,
    },
})
@Injectable()
export class EthnicitiesState {
    constructor(private patientService: PatientsService) {}

    @Action(LoadEthnicities)
    public load(context: StateContext<IEthnicitiesStateModel>, { refresh }: LoadEthnicities) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.ethnicities.length) || refresh) {
            context.patchState({ loading: true });
            this.patientService
                .GetEthnicities()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result = [] }) => {
                    context.patchState({
                        ethnicities: [...result].sort((eth_1, eth_2) =>
                            (eth_1.ethnicityName ?? '').localeCompare(eth_2.ethnicityName ?? ''),
                        ),
                    });
                });
        }
    }

    @Action(ReloadEthnicities)
    public reload(context: StateContext<IEthnicitiesStateModel>) {
        context.dispatch(new LoadEthnicities(true));
    }
}
