import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { catchError, map, Observable, of, switchMap, take } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';

/**
 * Guard, which is designed to load the user if it is not loaded.
 */
@Injectable({ providedIn: 'root' })
export class CurrentUserGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private currentUserService: CurrentUserService) {}

    public canActivate(): Observable<boolean> {
        return this.hasAccess();
    }

    public canActivateChild(): Observable<boolean> {
        return this.hasAccess();
    }

    public canLoad(): Observable<boolean> {
        return this.hasAccess();
    }

    private hasAccess(): Observable<boolean> {
        return this.currentUserService.user$.pipe(
            take(1),
            switchMap((user) =>
                !!user
                    ? of(true)
                    : this.currentUserService.loadUser().pipe(
                          map((loadedUser) => !!loadedUser),
                          catchError(() => of(false)),
                      ),
            ),
        );
    }
}
