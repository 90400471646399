import { Injectable } from '@angular/core';
import { UsersService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadPracticeUsers, ReloadPracticeUsers } from './practice-users.actions';
import { IPracticeUsersStateModel } from './practice-users.model';

@State<IPracticeUsersStateModel>({
    name: 'PracticeUsers',
    defaults: {
        practiceUsers: [],
        loading: false,
    },
})
@Injectable()
export class PracticeUsersState {
    constructor(private usersService: UsersService) {}

    @Action(LoadPracticeUsers)
    public load(context: StateContext<IPracticeUsersStateModel>, { refresh }: LoadPracticeUsers): void {
        if (!context.getState().practiceUsers.length || refresh) {
            context.patchState({ loading: true });
            this.usersService
                .GetUsers({ userType: 'AllPractice' })
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    if (response.result) {
                        context.patchState({
                            practiceUsers: [...response.result].sort((a, b) => a.displayName?.localeCompare(b.displayName ?? '') ?? 0),
                        });
                    }
                });
        }
    }

    @Action(ReloadPracticeUsers)
    public reload(context: StateContext<IPracticeUsersStateModel>): void {
        context.dispatch(new LoadPracticeUsers(true));
    }
}
