/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionLicenseGroup } from '../models/api-response-icollection-license-group';
import { ApiResponseICollectionLicenseGroupDetails } from '../models/api-response-icollection-license-group-details';
@Injectable({
  providedIn: 'root',
})
class LicenseGroupsService extends __BaseService {
  static readonly getLicenseGroupsPath = '/LicenseGroups';
  static readonly getLicenseGroupsDetailsPath = '/LicenseGroups/Details';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get license groups.
   * @return Success
   */
  getLicenseGroupsResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionLicenseGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/LicenseGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionLicenseGroup>;
      })
    );
  }
  /**
   * The get license groups.
   * @return Success
   */
  getLicenseGroups(): __Observable<ApiResponseICollectionLicenseGroup> {
    return this.getLicenseGroupsResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionLicenseGroup)
    );
  }

  /**
   * The get license group details.
   * @param includeUsers The include users.
   * @return Success
   */
  getLicenseGroupsDetailsResponse(includeUsers?: boolean): __Observable<__StrictHttpResponse<ApiResponseICollectionLicenseGroupDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeUsers != null) __params = __params.set('includeUsers', includeUsers.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/LicenseGroups/Details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionLicenseGroupDetails>;
      })
    );
  }
  /**
   * The get license group details.
   * @param includeUsers The include users.
   * @return Success
   */
  getLicenseGroupsDetails(includeUsers?: boolean): __Observable<ApiResponseICollectionLicenseGroupDetails> {
    return this.getLicenseGroupsDetailsResponse(includeUsers).pipe(
      __map(_r => _r.body as ApiResponseICollectionLicenseGroupDetails)
    );
  }
}

module LicenseGroupsService {
}

export { LicenseGroupsService }
