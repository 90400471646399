import { Injectable } from '@angular/core';
import { BaseEnumService } from './base-enum.service';

@Injectable({
    providedIn: 'root',
})
export class TaskStatusEnumService extends BaseEnumService {
    constructor() {
        super();
        this.data = [
            {
                name: 'All',
                key: '',
            },
            {
                name: 'Draft',
                key: 'Draft',
            },
            {
                name: 'Open',
                key: 'Open',
            },
            {
                name: 'In Progress',
                key: 'InProgress',
            },
            {
                name: 'Cancel',
                key: 'Cancel',
            },
            {
                name: 'Completed',
                key: 'Completed',
            },
        ];
    }
}
