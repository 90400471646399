import { Selector } from '@ngxs/store';
import { IOccasionEdit } from '@symplast/models/shared';
import { IOccasionsStateModel } from './occasions.model';
import { OccasionsState } from './occasions.state';

export class OccasionsSelectors {
    @Selector([OccasionsState])
    public static loading(state: IOccasionsStateModel): boolean {
        return state.loading;
    }

    @Selector([OccasionsState])
    public static backgroundLoading(state: IOccasionsStateModel): boolean {
        return state.backgroundLoading;
    }

    @Selector([OccasionsState])
    public static occassions(state: IOccasionsStateModel): IOccasionEdit[] {
        return state.occasions;
    }

    @Selector([OccasionsState])
    public static message(state: IOccasionsStateModel): string | null {
        return state.message;
    }
}
