import { Selector } from '@ngxs/store';
import { ILocationsStateModel } from './locations.model';
import { LocationsState } from './locations.state';

export class LocationsSelectors {
    @Selector([LocationsState])
    public static loading(state: ILocationsStateModel): boolean {
        return state.loading;
    }

    @Selector([LocationsState])
    public static locations(state: ILocationsStateModel): ILocationsStateModel['locations'] {
        return state.locations;
    }

    @Selector([LocationsState])
    public static activeLocations(state: ILocationsStateModel): ILocationsStateModel['activeLocations'] {
        return state.activeLocations;
    }
}
