import { Injectable } from '@angular/core';
import { getActionTypeFromInstance, InitState, NgxsNextPluginFn, NgxsPlugin, UpdateState } from '@ngxs/store';

export class Logout {
    public static readonly type = '[Meta] Logout from the App';
}

@Injectable({ providedIn: 'root' })
export class LogoutPlugin implements NgxsPlugin {
    private initState: Object;

    public handle(state: Object, action: any, next: NgxsNextPluginFn): void {
        if (getActionTypeFromInstance(action) === InitState.type) {
            this.initState = state;
        }

        if (getActionTypeFromInstance(action) === UpdateState.type) {
            this.initState = {
                ...this.initState,
                ...(action.addedStates || []),
            };
        }

        if (getActionTypeFromInstance(action) === Logout.type) {
            state = this.initState;
        }

        return next(state, action);
    }
}
