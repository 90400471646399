/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableItemDetail } from '../models/api-response-ienumerable-item-detail';
@Injectable({
    providedIn: 'root',
})
class ItemDetailsService extends __BaseService {
    static readonly GetItemDetailsPath = '/ItemDetails';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets the all items for a tenant.
     * @param params The `ItemDetailsService.GetItemDetailsParams` containing the following parameters:
     *
     * - `showItemsWithoutBillingCodesOnly`: The show items without billing codes only.
     *
     * - `locationId`: Optional parameter to filter items by location Id.
     *
     * - `isNotCollected`: Optional parameter to filter items by flag isNotCollected.
     *
     * - `isForSale`: Optional parameter to filter items by flag isForSale.
     *
     * - `isDeleted`: Optional parameter to filter items by flag isDeleted.
     *
     * - `includeTieredPrices`: Include tiered prices.
     *
     * - `UPC`: Gets or sets the UPC.
     *
     * - `TrackInventoryMode`: Gets or sets the track inventory mode.
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `SearchQuery`: Gets or sets the search query.
     *
     * - `SKU`: Gets or sets the SKU.
     *
     * - `ParentChildType`: Gets or sets the parent child type.
     *
     * - `Name`: Gets or sets the name.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `ItemType`: Gets or sets the Item Type identifier.
     *
     * - `ItemStatusId`: Gets or sets the status identifier.
     *
     * - `ItemIds`: Gets or sets the item ids.
     *
     * - `IncludePreferredProviderName`: Gets or sets the include preferred provider name.
     *
     * - `IcdCodeId`: Gets or sets the ICD Identifier.
     *
     * - `HierarchicalCategoryId`: Gets or sets the hierarchical category id.
     *
     * - `GenderId`: Gets or sets the gender Identifier.
     *
     * - `CptCodeId`: Gets or sets the CPT Identifier.
     *
     * - `Categories`: Gets or sets the categories.
     *
     * - `Brand`: Gets or sets the brand.
     *
     * @return Success
     */
    GetItemDetailsResponse(
        params: ItemDetailsService.GetItemDetailsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableItemDetail>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.showItemsWithoutBillingCodesOnly != null)
            __params = __params.set('showItemsWithoutBillingCodesOnly', params.showItemsWithoutBillingCodesOnly.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.isNotCollected != null) __params = __params.set('isNotCollected', params.isNotCollected.toString());
        if (params.isForSale != null) __params = __params.set('isForSale', params.isForSale.toString());
        if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
        if (params.includeTieredPrices != null) __params = __params.set('includeTieredPrices', params.includeTieredPrices.toString());
        if (params.UPC != null) __params = __params.set('UPC', params.UPC.toString());
        if (params.TrackInventoryMode != null) __params = __params.set('TrackInventoryMode', params.TrackInventoryMode.toString());
        if (params.SortingRequest != null) __params = __params.set('SortingRequest', params.SortingRequest.toString());
        if (params.SortingDirectionRequest != null)
            __params = __params.set('SortingDirectionRequest', params.SortingDirectionRequest.toString());
        if (params.SearchQuery != null) __params = __params.set('SearchQuery', params.SearchQuery.toString());
        if (params.SKU != null) __params = __params.set('SKU', params.SKU.toString());
        if (params.ParentChildType != null) __params = __params.set('ParentChildType', params.ParentChildType.toString());
        if (params.Name != null) __params = __params.set('Name', params.Name.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        if (params.ItemType != null) __params = __params.set('ItemType', params.ItemType.toString());
        if (params.ItemStatusId != null) __params = __params.set('ItemStatusId', params.ItemStatusId.toString());
        if (params.ItemIds != null) __params = __params.set('ItemIds', params.ItemIds.toString());
        if (params.IncludePreferredProviderName != null)
            __params = __params.set('IncludePreferredProviderName', params.IncludePreferredProviderName.toString());
        if (params.IcdCodeId != null) __params = __params.set('IcdCodeId', params.IcdCodeId.toString());
        if (params.HierarchicalCategoryId != null)
            __params = __params.set('HierarchicalCategoryId', params.HierarchicalCategoryId.toString());
        if (params.GenderId != null) __params = __params.set('GenderId', params.GenderId.toString());
        if (params.CptCodeId != null) __params = __params.set('CptCodeId', params.CptCodeId.toString());
        (params.Categories || []).forEach((val) => {
            if (val != null) __params = __params.append('Categories', val.toString());
        });
        if (params.Brand != null) __params = __params.set('Brand', params.Brand.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/ItemDetails`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableItemDetail>;
            }),
        );
    }
    /**
     * Gets the all items for a tenant.
     * @param params The `ItemDetailsService.GetItemDetailsParams` containing the following parameters:
     *
     * - `showItemsWithoutBillingCodesOnly`: The show items without billing codes only.
     *
     * - `locationId`: Optional parameter to filter items by location Id.
     *
     * - `isNotCollected`: Optional parameter to filter items by flag isNotCollected.
     *
     * - `isForSale`: Optional parameter to filter items by flag isForSale.
     *
     * - `isDeleted`: Optional parameter to filter items by flag isDeleted.
     *
     * - `includeTieredPrices`: Include tiered prices.
     *
     * - `UPC`: Gets or sets the UPC.
     *
     * - `TrackInventoryMode`: Gets or sets the track inventory mode.
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `SearchQuery`: Gets or sets the search query.
     *
     * - `SKU`: Gets or sets the SKU.
     *
     * - `ParentChildType`: Gets or sets the parent child type.
     *
     * - `Name`: Gets or sets the name.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `ItemType`: Gets or sets the Item Type identifier.
     *
     * - `ItemStatusId`: Gets or sets the status identifier.
     *
     * - `ItemIds`: Gets or sets the item ids.
     *
     * - `IncludePreferredProviderName`: Gets or sets the include preferred provider name.
     *
     * - `IcdCodeId`: Gets or sets the ICD Identifier.
     *
     * - `HierarchicalCategoryId`: Gets or sets the hierarchical category id.
     *
     * - `GenderId`: Gets or sets the gender Identifier.
     *
     * - `CptCodeId`: Gets or sets the CPT Identifier.
     *
     * - `Categories`: Gets or sets the categories.
     *
     * - `Brand`: Gets or sets the brand.
     *
     * @return Success
     */
    GetItemDetails(params: ItemDetailsService.GetItemDetailsParams): __Observable<ApiResponseIEnumerableItemDetail> {
        return this.GetItemDetailsResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableItemDetail));
    }
}

module ItemDetailsService {
    /**
     * Parameters for GetItemDetails
     */
    export interface GetItemDetailsParams {
        /**
         * The show items without billing codes only.
         */
        showItemsWithoutBillingCodesOnly?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter items by location Id.
         */
        locationId?: number;

        /**
         * Optional parameter to filter items by flag isNotCollected.
         */
        isNotCollected?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter items by flag isForSale.
         */
        isForSale?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter items by flag isDeleted.
         */
        isDeleted?: 'False' | 'True' | 'Null';

        /**
         * Include tiered prices.
         */
        includeTieredPrices?: 'False' | 'True' | 'Null';

        /**
         * Gets or sets the UPC.
         */
        UPC?: string;

        /**
         * Gets or sets the track inventory mode.
         */
        TrackInventoryMode?:
            | 'NoTracking'
            | 'GlobalLevelOnly'
            | 'PerLocationOnly'
            | 'GlobalAndPerLocation'
            | 'PerBatchOnly'
            | 'GlobalAndPerBatch'
            | 'PerBatchPerLocation'
            | 'GlobalAndPerBatchPerLocation';

        /**
         * Gets or sets the sorting request.
         */
        SortingRequest?: 'Id' | 'Name' | 'ModifiedDate';

        /**
         * Gets or sets the sorting direction request.
         */
        SortingDirectionRequest?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the search query.
         */
        SearchQuery?: string;

        /**
         * Gets or sets the SKU.
         */
        SKU?: string;

        /**
         * Gets or sets the parent child type.
         */
        ParentChildType?: 'Parent' | 'Child' | 'All';

        /**
         * Gets or sets the name.
         */
        Name?: string;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;

        /**
         * Gets or sets the Item Type identifier.
         */
        ItemType?:
            | 'Products'
            | 'Services'
            | 'FeeTypeId'
            | 'MembershipTypeId'
            | 'GiftCardTypeId'
            | 'Adjustment'
            | 'WooCommerceItem'
            | 'CreditCardSurchargeTypeId';

        /**
         * Gets or sets the status identifier.
         */
        ItemStatusId?: number;

        /**
         * Gets or sets the item ids.
         */
        ItemIds?: string;

        /**
         * Gets or sets the include preferred provider name.
         */
        IncludePreferredProviderName?: boolean;

        /**
         * Gets or sets the ICD Identifier.
         */
        IcdCodeId?: number;

        /**
         * Gets or sets the hierarchical category id.
         */
        HierarchicalCategoryId?: number;

        /**
         * Gets or sets the gender Identifier.
         */
        GenderId?: number;

        /**
         * Gets or sets the CPT Identifier.
         */
        CptCodeId?: number;

        /**
         * Gets or sets the categories.
         */
        Categories?: Array<string>;

        /**
         * Gets or sets the brand.
         */
        Brand?: string;
    }
}

export { ItemDetailsService };
