export class LoadInsuranceSettings {
    public static readonly type = '[InsuranceSettings] Load';
}

export class SaveGeneralRequest {
    public static readonly type = '[InsuranceSettings] Save General';
    constructor(public enabled: boolean, public acceptAssignmentsByDefault: boolean, public defaultBillingLocationId?: number) {}
}

export class SaveTrizettoRequest {
    public static readonly type = '[InsuranceSettings] Save Trizetto';
    constructor(public enabledReceiving835File: boolean) {}
}

export class CancelRequest {
    public static readonly type = '[InsuranceSettings] Cancel';
}
