/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionLocationItemTax } from '../models/api-response-icollection-location-item-tax';
import { LocationItemTaxesCreateRequest } from '../models/location-item-taxes-create-request';
import { ApiResponseIEnumerableLocationItemTax } from '../models/api-response-ienumerable-location-item-tax';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { LocationItemTaxesUpdateRequest } from '../models/location-item-taxes-update-request';
import { ApiResponseLocationItemTax } from '../models/api-response-location-item-tax';
import { LocationItemTaxUpdateRequest } from '../models/location-item-tax-update-request';
@Injectable({
    providedIn: 'root',
})
class LocalTaxOverridesService extends __BaseService {
    static readonly CreateLocalTaxOverridePath = '/Items/{itemId}/LocalTaxOverrides';
    static readonly GetLocalTaxOverridesPath = '/Items/{itemId}/LocalTaxOverrides';
    static readonly UpdateLocationItemTaxesPath = '/Items/{itemId}/LocalTaxOverrides';
    static readonly DeleteLocalTaxOverridePath = '/Items/LocalTaxOverrides{locationItemTaxToDeleteId}';
    static readonly GetLocalTaxOverrideByIdPath = '/Items/LocalTaxOverrides/{locationItemTaxId}';
    static readonly UpdateLocationItemTaxPath = '/Items/LocalTaxOverrides/{locationItemTaxId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Creates a locationItemTax.
     * @param params The `LocalTaxOverridesService.CreateLocalTaxOverrideParams` containing the following parameters:
     *
     * - `itemId`: The item identification number.
     *
     * - `locationItemTaxesCreateRequest`: The  LocationItemTaxes create requests.
     *
     * - `isEnabled`: The flag indicating overriding is enabled.
     *
     * @return Success
     */
    CreateLocalTaxOverrideResponse(
        params: LocalTaxOverridesService.CreateLocalTaxOverrideParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionLocationItemTax>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.locationItemTaxesCreateRequest;
        if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/LocalTaxOverrides`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionLocationItemTax>;
            }),
        );
    }
    /**
     * Creates a locationItemTax.
     * @param params The `LocalTaxOverridesService.CreateLocalTaxOverrideParams` containing the following parameters:
     *
     * - `itemId`: The item identification number.
     *
     * - `locationItemTaxesCreateRequest`: The  LocationItemTaxes create requests.
     *
     * - `isEnabled`: The flag indicating overriding is enabled.
     *
     * @return Success
     */
    CreateLocalTaxOverride(
        params: LocalTaxOverridesService.CreateLocalTaxOverrideParams,
    ): __Observable<ApiResponseICollectionLocationItemTax> {
        return this.CreateLocalTaxOverrideResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionLocationItemTax));
    }

    /**
     * Gets all location item tax.
     * @param itemId The Item identifier .
     * @return Success
     */
    GetLocalTaxOverridesResponse(itemId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableLocationItemTax>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Items/${encodeURIComponent(String(itemId))}/LocalTaxOverrides`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableLocationItemTax>;
            }),
        );
    }
    /**
     * Gets all location item tax.
     * @param itemId The Item identifier .
     * @return Success
     */
    GetLocalTaxOverrides(itemId: number): __Observable<ApiResponseIEnumerableLocationItemTax> {
        return this.GetLocalTaxOverridesResponse(itemId).pipe(__map((_r) => _r.body as ApiResponseIEnumerableLocationItemTax));
    }

    /**
     * Modify a Location Item taxes. Attention! This method changes the entire list of tax overrides for a given item.
     * @param params The `LocalTaxOverridesService.UpdateLocationItemTaxesParams` containing the following parameters:
     *
     * - `itemId`: the item id to location taxes modify.
     *
     * - `locationItemTaxesUpdateRequest`: the new list of location item taxes.
     *
     * - `isEnabled`: The flag indicating overriding is enabled.
     *
     * @return Success
     */
    UpdateLocationItemTaxesResponse(
        params: LocalTaxOverridesService.UpdateLocationItemTaxesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.locationItemTaxesUpdateRequest;
        if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/LocalTaxOverrides`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modify a Location Item taxes. Attention! This method changes the entire list of tax overrides for a given item.
     * @param params The `LocalTaxOverridesService.UpdateLocationItemTaxesParams` containing the following parameters:
     *
     * - `itemId`: the item id to location taxes modify.
     *
     * - `locationItemTaxesUpdateRequest`: the new list of location item taxes.
     *
     * - `isEnabled`: The flag indicating overriding is enabled.
     *
     * @return Success
     */
    UpdateLocationItemTaxes(params: LocalTaxOverridesService.UpdateLocationItemTaxesParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateLocationItemTaxesResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Disables a locationItemTax.
     * @param locationItemTaxToDeleteId The location Item tax Id to soft delete.
     * @return Success
     */
    DeleteLocalTaxOverrideResponse(locationItemTaxToDeleteId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/Items/LocalTaxOverrides${encodeURIComponent(String(locationItemTaxToDeleteId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Disables a locationItemTax.
     * @param locationItemTaxToDeleteId The location Item tax Id to soft delete.
     * @return Success
     */
    DeleteLocalTaxOverride(locationItemTaxToDeleteId: number): __Observable<ApiResponseOkResponse> {
        return this.DeleteLocalTaxOverrideResponse(locationItemTaxToDeleteId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets a location item tax.
     * @param locationItemTaxId the location item tax id to search.
     * @return Success
     */
    GetLocalTaxOverrideByIdResponse(locationItemTaxId: number): __Observable<__StrictHttpResponse<ApiResponseLocationItemTax>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Items/LocalTaxOverrides/${encodeURIComponent(String(locationItemTaxId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocationItemTax>;
            }),
        );
    }
    /**
     * Gets a location item tax.
     * @param locationItemTaxId the location item tax id to search.
     * @return Success
     */
    GetLocalTaxOverrideById(locationItemTaxId: number): __Observable<ApiResponseLocationItemTax> {
        return this.GetLocalTaxOverrideByIdResponse(locationItemTaxId).pipe(__map((_r) => _r.body as ApiResponseLocationItemTax));
    }

    /**
     * Modify a Location Item tax.
     * @param params The `LocalTaxOverridesService.UpdateLocationItemTaxParams` containing the following parameters:
     *
     * - `locationItemTaxId`: the location item tax id to modify.
     *
     * - `locationItemTaxUpdateRequest`: the new value for location item tax.
     *
     * - `isEnabled`: The flag indicating overriding is enabled.
     *
     * @return Success
     */
    UpdateLocationItemTaxResponse(
        params: LocalTaxOverridesService.UpdateLocationItemTaxParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.locationItemTaxUpdateRequest;
        if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Items/LocalTaxOverrides/${encodeURIComponent(String(params.locationItemTaxId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modify a Location Item tax.
     * @param params The `LocalTaxOverridesService.UpdateLocationItemTaxParams` containing the following parameters:
     *
     * - `locationItemTaxId`: the location item tax id to modify.
     *
     * - `locationItemTaxUpdateRequest`: the new value for location item tax.
     *
     * - `isEnabled`: The flag indicating overriding is enabled.
     *
     * @return Success
     */
    UpdateLocationItemTax(params: LocalTaxOverridesService.UpdateLocationItemTaxParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateLocationItemTaxResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module LocalTaxOverridesService {
    /**
     * Parameters for CreateLocalTaxOverride
     */
    export interface CreateLocalTaxOverrideParams {
        /**
         * The item identification number.
         */
        itemId: number;

        /**
         * The  LocationItemTaxes create requests.
         */
        locationItemTaxesCreateRequest?: LocationItemTaxesCreateRequest;

        /**
         * The flag indicating overriding is enabled.
         */
        isEnabled?: boolean;
    }

    /**
     * Parameters for UpdateLocationItemTaxes
     */
    export interface UpdateLocationItemTaxesParams {
        /**
         * the item id to location taxes modify.
         */
        itemId: number;

        /**
         * the new list of location item taxes.
         */
        locationItemTaxesUpdateRequest?: LocationItemTaxesUpdateRequest;

        /**
         * The flag indicating overriding is enabled.
         */
        isEnabled?: boolean;
    }

    /**
     * Parameters for UpdateLocationItemTax
     */
    export interface UpdateLocationItemTaxParams {
        /**
         * the location item tax id to modify.
         */
        locationItemTaxId: number;

        /**
         * the new value for location item tax.
         */
        locationItemTaxUpdateRequest?: LocationItemTaxUpdateRequest;

        /**
         * The flag indicating overriding is enabled.
         */
        isEnabled?: boolean;
    }
}

export { LocalTaxOverridesService };
