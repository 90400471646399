/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseBaseItem } from '../models/api-response-base-item';
import { ApiResponseICollectionBaseItem } from '../models/api-response-icollection-base-item';
@Injectable({
  providedIn: 'root',
})
class BaseItemsService extends __BaseService {
  static readonly GetBaseItemByIdPath = '/BaseItems/{baseItemId}';
  static readonly GetBaseItemsPath = '/BaseItems';
  static readonly GetBaseItemsForTenantPath = '/BaseItems/Tenant/{tenantId}/BaseItems';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a particular base item by ID.
   * @param baseItemId The base id.
   * @return Success
   */
  GetBaseItemByIdResponse(baseItemId: number): __Observable<__StrictHttpResponse<ApiResponseBaseItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/BaseItems/${encodeURIComponent(String(baseItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseBaseItem>;
      })
    );
  }
  /**
   * Get a particular base item by ID.
   * @param baseItemId The base id.
   * @return Success
   */
  GetBaseItemById(baseItemId: number): __Observable<ApiResponseBaseItem> {
    return this.GetBaseItemByIdResponse(baseItemId).pipe(
      __map(_r => _r.body as ApiResponseBaseItem)
    );
  }

  /**
   * Get all base items.
   * @param params The `BaseItemsService.GetBaseItemsParams` containing the following parameters:
   *
   * - `name`: The base item name to filter.
   *
   * - `itemTypeId`: The item type id to filter.
   *
   * @return Success
   */
  GetBaseItemsResponse(params: BaseItemsService.GetBaseItemsParams): __Observable<__StrictHttpResponse<ApiResponseICollectionBaseItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.itemTypeId != null) __params = __params.set('itemTypeId', params.itemTypeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/BaseItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionBaseItem>;
      })
    );
  }
  /**
   * Get all base items.
   * @param params The `BaseItemsService.GetBaseItemsParams` containing the following parameters:
   *
   * - `name`: The base item name to filter.
   *
   * - `itemTypeId`: The item type id to filter.
   *
   * @return Success
   */
  GetBaseItems(params: BaseItemsService.GetBaseItemsParams): __Observable<ApiResponseICollectionBaseItem> {
    return this.GetBaseItemsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionBaseItem)
    );
  }

  /**
   * Get all base items.
   * @param params The `BaseItemsService.GetBaseItemsForTenantParams` containing the following parameters:
   *
   * - `tenantId`: The tenant id.
   *
   * - `name`: The base item name to filter.
   *
   * - `itemTypeId`: The item type id to filter.
   *
   * @return Success
   */
  GetBaseItemsForTenantResponse(params: BaseItemsService.GetBaseItemsForTenantParams): __Observable<__StrictHttpResponse<ApiResponseICollectionBaseItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.itemTypeId != null) __params = __params.set('itemTypeId', params.itemTypeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/BaseItems/Tenant/${encodeURIComponent(String(params.tenantId))}/BaseItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionBaseItem>;
      })
    );
  }
  /**
   * Get all base items.
   * @param params The `BaseItemsService.GetBaseItemsForTenantParams` containing the following parameters:
   *
   * - `tenantId`: The tenant id.
   *
   * - `name`: The base item name to filter.
   *
   * - `itemTypeId`: The item type id to filter.
   *
   * @return Success
   */
  GetBaseItemsForTenant(params: BaseItemsService.GetBaseItemsForTenantParams): __Observable<ApiResponseICollectionBaseItem> {
    return this.GetBaseItemsForTenantResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionBaseItem)
    );
  }
}

module BaseItemsService {

  /**
   * Parameters for GetBaseItems
   */
  export interface GetBaseItemsParams {

    /**
     * The base item name to filter.
     */
    name?: string;

    /**
     * The item type id to filter.
     */
    itemTypeId?: number;
  }

  /**
   * Parameters for GetBaseItemsForTenant
   */
  export interface GetBaseItemsForTenantParams {

    /**
     * The tenant id.
     */
    tenantId: number;

    /**
     * The base item name to filter.
     */
    name?: string;

    /**
     * The item type id to filter.
     */
    itemTypeId?: number;
  }
}

export { BaseItemsService }
