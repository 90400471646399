/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionKiosk } from '../models/api-response-icollection-kiosk';
import { ApiResponseKiosk } from '../models/api-response-kiosk';
import { KioskUpdateRequest } from '../models/kiosk-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { KioskNameUpdateRequest } from '../models/kiosk-name-update-request';
import { KioskLogoUpdateRequest } from '../models/kiosk-logo-update-request';
import { KioskWelcomeMessageUpdateRequest } from '../models/kiosk-welcome-message-update-request';
import { ApiResponseKioskUpdateFeatureStatus } from '../models/api-response-kiosk-update-feature-status';
import { KioskUpdateFeatureStatus } from '../models/kiosk-update-feature-status';
@Injectable({
  providedIn: 'root',
})
class KiosksService extends __BaseService {
  static readonly GetKiosksPath = '/Kiosks';
  static readonly UpdateKioskSettingsPath = '/Kiosks';
  static readonly DeleteKioskPath = '/Kiosks';
  static readonly UpdateKioskNamePath = '/Kiosks/{kioskId}/Name';
  static readonly UpdateKioskLogoPath = '/Kiosks/{kioskId}/Logo';
  static readonly UpdateKioskWelcomeMessagePath = '/Kiosks/{kioskId}/WelcomeMessage';
  static readonly UpdateKioskFeatureStatusPath = '/Kiosks/UpdateKioskFeatureStatus';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the kiosks.
   * @return Success
   */
  GetKiosksResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionKiosk>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Kiosks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionKiosk>;
      })
    );
  }
  /**
   * Gets the kiosks.
   * @return Success
   */
  GetKiosks(): __Observable<ApiResponseICollectionKiosk> {
    return this.GetKiosksResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionKiosk)
    );
  }

  /**
   * Updates the kiosk settings.
   * @param params The `KiosksService.UpdateKioskSettingsParams` containing the following parameters:
   *
   * - `kioskUpdateRequest`: The kiosk update request.
   *
   * - `kioskId`: The kiosk identifier.
   *
   * @return Success
   */
  UpdateKioskSettingsResponse(params: KiosksService.UpdateKioskSettingsParams): __Observable<__StrictHttpResponse<ApiResponseKiosk>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.kioskUpdateRequest;
    if (params.kioskId != null) __params = __params.set('kioskId', params.kioskId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Kiosks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseKiosk>;
      })
    );
  }
  /**
   * Updates the kiosk settings.
   * @param params The `KiosksService.UpdateKioskSettingsParams` containing the following parameters:
   *
   * - `kioskUpdateRequest`: The kiosk update request.
   *
   * - `kioskId`: The kiosk identifier.
   *
   * @return Success
   */
  UpdateKioskSettings(params: KiosksService.UpdateKioskSettingsParams): __Observable<ApiResponseKiosk> {
    return this.UpdateKioskSettingsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseKiosk)
    );
  }

  /**
   * Deletes the kiosk.
   * @param kioskId The kiosk identifier.
   * @return Success
   */
  DeleteKioskResponse(kioskId?: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (kioskId != null) __params = __params.set('kioskId', kioskId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Kiosks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes the kiosk.
   * @param kioskId The kiosk identifier.
   * @return Success
   */
  DeleteKiosk(kioskId?: string): __Observable<ApiResponseOkResponse> {
    return this.DeleteKioskResponse(kioskId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates the kiosk name.
   * @param params The `KiosksService.UpdateKioskNameParams` containing the following parameters:
   *
   * - `kioskId`: The kiosk identifier.
   *
   * - `kioskNameUpdateRequest`: The kiosk name update request.
   *
   * @return Success
   */
  UpdateKioskNameResponse(params: KiosksService.UpdateKioskNameParams): __Observable<__StrictHttpResponse<ApiResponseKiosk>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.kioskNameUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Kiosks/${encodeURIComponent(String(params.kioskId))}/Name`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseKiosk>;
      })
    );
  }
  /**
   * Updates the kiosk name.
   * @param params The `KiosksService.UpdateKioskNameParams` containing the following parameters:
   *
   * - `kioskId`: The kiosk identifier.
   *
   * - `kioskNameUpdateRequest`: The kiosk name update request.
   *
   * @return Success
   */
  UpdateKioskName(params: KiosksService.UpdateKioskNameParams): __Observable<ApiResponseKiosk> {
    return this.UpdateKioskNameResponse(params).pipe(
      __map(_r => _r.body as ApiResponseKiosk)
    );
  }

  /**
   * Updates the kiosk logo.
   * @param params The `KiosksService.UpdateKioskLogoParams` containing the following parameters:
   *
   * - `kioskId`: The kiosk identifier.
   *
   * - `kioskLogoUpdateRequest`: The kiosk logo update request.
   *
   * @return Success
   */
  UpdateKioskLogoResponse(params: KiosksService.UpdateKioskLogoParams): __Observable<__StrictHttpResponse<ApiResponseKiosk>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.kioskLogoUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Kiosks/${encodeURIComponent(String(params.kioskId))}/Logo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseKiosk>;
      })
    );
  }
  /**
   * Updates the kiosk logo.
   * @param params The `KiosksService.UpdateKioskLogoParams` containing the following parameters:
   *
   * - `kioskId`: The kiosk identifier.
   *
   * - `kioskLogoUpdateRequest`: The kiosk logo update request.
   *
   * @return Success
   */
  UpdateKioskLogo(params: KiosksService.UpdateKioskLogoParams): __Observable<ApiResponseKiosk> {
    return this.UpdateKioskLogoResponse(params).pipe(
      __map(_r => _r.body as ApiResponseKiosk)
    );
  }

  /**
   * Update the kiosk welcome message.
   * @param params The `KiosksService.UpdateKioskWelcomeMessageParams` containing the following parameters:
   *
   * - `kioskId`: The kiosk identifier.
   *
   * - `kioskWelcomeMessageUpdateRequest`: The kiosk welcome message update request.
   *
   * @return Success
   */
  UpdateKioskWelcomeMessageResponse(params: KiosksService.UpdateKioskWelcomeMessageParams): __Observable<__StrictHttpResponse<ApiResponseKiosk>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.kioskWelcomeMessageUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Kiosks/${encodeURIComponent(String(params.kioskId))}/WelcomeMessage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseKiosk>;
      })
    );
  }
  /**
   * Update the kiosk welcome message.
   * @param params The `KiosksService.UpdateKioskWelcomeMessageParams` containing the following parameters:
   *
   * - `kioskId`: The kiosk identifier.
   *
   * - `kioskWelcomeMessageUpdateRequest`: The kiosk welcome message update request.
   *
   * @return Success
   */
  UpdateKioskWelcomeMessage(params: KiosksService.UpdateKioskWelcomeMessageParams): __Observable<ApiResponseKiosk> {
    return this.UpdateKioskWelcomeMessageResponse(params).pipe(
      __map(_r => _r.body as ApiResponseKiosk)
    );
  }

  /**
   * Updates the kiosk feature status.
   * @param request The kiosk feature status request.
   * @return Success
   */
  UpdateKioskFeatureStatusResponse(request?: KioskUpdateFeatureStatus): __Observable<__StrictHttpResponse<ApiResponseKioskUpdateFeatureStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Kiosks/UpdateKioskFeatureStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseKioskUpdateFeatureStatus>;
      })
    );
  }
  /**
   * Updates the kiosk feature status.
   * @param request The kiosk feature status request.
   * @return Success
   */
  UpdateKioskFeatureStatus(request?: KioskUpdateFeatureStatus): __Observable<ApiResponseKioskUpdateFeatureStatus> {
    return this.UpdateKioskFeatureStatusResponse(request).pipe(
      __map(_r => _r.body as ApiResponseKioskUpdateFeatureStatus)
    );
  }
}

module KiosksService {

  /**
   * Parameters for UpdateKioskSettings
   */
  export interface UpdateKioskSettingsParams {

    /**
     * The kiosk update request.
     */
    kioskUpdateRequest?: KioskUpdateRequest;

    /**
     * The kiosk identifier.
     */
    kioskId?: string;
  }

  /**
   * Parameters for UpdateKioskName
   */
  export interface UpdateKioskNameParams {

    /**
     * The kiosk identifier.
     */
    kioskId: string;

    /**
     * The kiosk name update request.
     */
    kioskNameUpdateRequest?: KioskNameUpdateRequest;
  }

  /**
   * Parameters for UpdateKioskLogo
   */
  export interface UpdateKioskLogoParams {

    /**
     * The kiosk identifier.
     */
    kioskId: string;

    /**
     * The kiosk logo update request.
     */
    kioskLogoUpdateRequest?: KioskLogoUpdateRequest;
  }

  /**
   * Parameters for UpdateKioskWelcomeMessage
   */
  export interface UpdateKioskWelcomeMessageParams {

    /**
     * The kiosk identifier.
     */
    kioskId: string;

    /**
     * The kiosk welcome message update request.
     */
    kioskWelcomeMessageUpdateRequest?: KioskWelcomeMessageUpdateRequest;
  }
}

export { KiosksService }
