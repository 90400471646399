import { Observable, take } from 'rxjs';

/**
 * Helper function that provides last value from Observable.
 * Be careful, this function works ONLY with hot Observables, namely: Subjects, BehaviorSubjects, ReplaySubjects, etc.
 * @param source$
 * @returns
 */
export function getObservableSnasphot<TResult>(source$: Observable<TResult>): TResult {
    let result: TResult;

    source$.pipe(take(1)).subscribe((value) => (result = value));

    // @ts-ignore
    return result;
}
