/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseTaxAuthority } from '../models/api-response-tax-authority';
import { TaxAuthorityRequest } from '../models/tax-authority-request';
import { ApiResponseIEnumerableTaxAuthority } from '../models/api-response-ienumerable-tax-authority';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class TaxAuthoritiesService extends __BaseService {
  static readonly CreateTaxAuthorityPath = '/TaxAuthorities';
  static readonly GetTaxAuthoritiesPath = '/TaxAuthorities';
  static readonly DeleteTaxAuthorityPath = '/TaxAuthorities/{taxAuthorityId}';
  static readonly GetTaxAuthorityByIdPath = '/TaxAuthorities/{taxAuthorityId}';
  static readonly UpdateTaxAuthorityPath = '/TaxAuthorities/{taxAuthorityId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates a TaxAuthority.
   * @param taxAuthorityCreateRequest The Tax Authority creation requests.
   * @return Success or Created
   */
  CreateTaxAuthorityResponse(taxAuthorityCreateRequest?: TaxAuthorityRequest): __Observable<__StrictHttpResponse<ApiResponseTaxAuthority | ApiResponseTaxAuthority>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = taxAuthorityCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/TaxAuthorities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTaxAuthority | ApiResponseTaxAuthority>;
      })
    );
  }
  /**
   * Creates a TaxAuthority.
   * @param taxAuthorityCreateRequest The Tax Authority creation requests.
   * @return Success or Created
   */
  CreateTaxAuthority(taxAuthorityCreateRequest?: TaxAuthorityRequest): __Observable<ApiResponseTaxAuthority | ApiResponseTaxAuthority> {
    return this.CreateTaxAuthorityResponse(taxAuthorityCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseTaxAuthority | ApiResponseTaxAuthority)
    );
  }

  /**
   * Gets the tax authorities.
   * @return Success
   */
  GetTaxAuthoritiesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTaxAuthority>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/TaxAuthorities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTaxAuthority>;
      })
    );
  }
  /**
   * Gets the tax authorities.
   * @return Success
   */
  GetTaxAuthorities(): __Observable<ApiResponseIEnumerableTaxAuthority> {
    return this.GetTaxAuthoritiesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTaxAuthority)
    );
  }

  /**
   * Deletes the tax authority.
   * @param taxAuthorityId The tax authority identifier.
   * @return Success
   */
  DeleteTaxAuthorityResponse(taxAuthorityId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/TaxAuthorities/${encodeURIComponent(String(taxAuthorityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes the tax authority.
   * @param taxAuthorityId The tax authority identifier.
   * @return Success
   */
  DeleteTaxAuthority(taxAuthorityId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteTaxAuthorityResponse(taxAuthorityId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the tax authority by identifier.
   * @param taxAuthorityId The tax authority identifier.
   * @return Success
   */
  GetTaxAuthorityByIdResponse(taxAuthorityId: number): __Observable<__StrictHttpResponse<ApiResponseTaxAuthority>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/TaxAuthorities/${encodeURIComponent(String(taxAuthorityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTaxAuthority>;
      })
    );
  }
  /**
   * Gets the tax authority by identifier.
   * @param taxAuthorityId The tax authority identifier.
   * @return Success
   */
  GetTaxAuthorityById(taxAuthorityId: number): __Observable<ApiResponseTaxAuthority> {
    return this.GetTaxAuthorityByIdResponse(taxAuthorityId).pipe(
      __map(_r => _r.body as ApiResponseTaxAuthority)
    );
  }

  /**
   * Modifies the tax authority.
   * @param params The `TaxAuthoritiesService.UpdateTaxAuthorityParams` containing the following parameters:
   *
   * - `taxAuthorityId`: The tax authority identifier.
   *
   * - `taxAuthorityUpdateRequest`: The tax authority update request.
   *
   * @return Success
   */
  UpdateTaxAuthorityResponse(params: TaxAuthoritiesService.UpdateTaxAuthorityParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.taxAuthorityUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/TaxAuthorities/${encodeURIComponent(String(params.taxAuthorityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Modifies the tax authority.
   * @param params The `TaxAuthoritiesService.UpdateTaxAuthorityParams` containing the following parameters:
   *
   * - `taxAuthorityId`: The tax authority identifier.
   *
   * - `taxAuthorityUpdateRequest`: The tax authority update request.
   *
   * @return Success
   */
  UpdateTaxAuthority(params: TaxAuthoritiesService.UpdateTaxAuthorityParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateTaxAuthorityResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module TaxAuthoritiesService {

  /**
   * Parameters for UpdateTaxAuthority
   */
  export interface UpdateTaxAuthorityParams {

    /**
     * The tax authority identifier.
     */
    taxAuthorityId: number;

    /**
     * The tax authority update request.
     */
    taxAuthorityUpdateRequest?: TaxAuthorityRequest;
  }
}

export { TaxAuthoritiesService }
