/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseAppointmentsGetResponse } from '../models/api-response-appointments-get-response';
import { AppointmentsGetRequest } from '../models/appointments-get-request';
@Injectable({
    providedIn: 'root',
})
class AppointmentsService extends __BaseService {
    static readonly GetAppointmentsForSingleTenantPath = '/Appointments';
    static readonly GetAppointmentsExcelFilePath = '/AppointmentsExcelFile';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets appointment information.
     * @param params The `AppointmentsService.GetAppointmentsForSingleTenantParams` containing the following parameters:
     *
     * - `ToDate`: Gets or sets the to date.
     *
     * - `IncludedFields`: Gets or sets the included fields.
     *
     * - `FromDate`: Gets or sets the from date.
     *
     * @return Success
     */
    GetAppointmentsForSingleTenantResponse(
        params: AppointmentsService.GetAppointmentsForSingleTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointmentsGetResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
        (params.IncludedFields || []).forEach((val) => {
            if (val != null) __params = __params.append('IncludedFields', val.toString());
        });
        if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Appointments`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointmentsGetResponse>;
            }),
        );
    }
    /**
     * Gets appointment information.
     * @param params The `AppointmentsService.GetAppointmentsForSingleTenantParams` containing the following parameters:
     *
     * - `ToDate`: Gets or sets the to date.
     *
     * - `IncludedFields`: Gets or sets the included fields.
     *
     * - `FromDate`: Gets or sets the from date.
     *
     * @return Success
     */
    GetAppointmentsForSingleTenant(
        params: AppointmentsService.GetAppointmentsForSingleTenantParams,
    ): __Observable<ApiResponseAppointmentsGetResponse> {
        return this.GetAppointmentsForSingleTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointmentsGetResponse));
    }

    /**
     * Gets the appointments excel file.
     * @param request The request.
     * @return Success
     */
    GetAppointmentsExcelFileResponse(request?: AppointmentsGetRequest): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/AppointmentsExcelFile`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Gets the appointments excel file.
     * @param request The request.
     * @return Success
     */
    GetAppointmentsExcelFile(request?: AppointmentsGetRequest): __Observable<Blob> {
        return this.GetAppointmentsExcelFileResponse(request).pipe(__map((_r) => _r.body as Blob));
    }
}

module AppointmentsService {
    /**
     * Parameters for GetAppointmentsForSingleTenant
     */
    export interface GetAppointmentsForSingleTenantParams {
        /**
         * Gets or sets the to date.
         */
        ToDate?: string;

        /**
         * Gets or sets the included fields.
         */
        IncludedFields?: Array<string>;

        /**
         * Gets or sets the from date.
         */
        FromDate?: string;
    }
}

export { AppointmentsService };
