import { Injectable } from '@angular/core';
import { BaseEnumService } from './base-enum.service';

@Injectable({
    providedIn: 'root',
})
export class AmountBracketsEnumService extends BaseEnumService {
    constructor() {
        super();
        this.data = [
            {
                key: '',
                name: 'All',
            },
            {
                key: 'From0To500Dollars',
                name: '$500 or less',
            },
            {
                key: 'From500To1000Dollars',
                name: '$1000 or less',
            },
            {
                key: 'From1000To5000Dollars',
                name: '$5000 or less',
            },
            {
                key: 'MoreThan5000Dollars',
                name: 'Over $5000',
            },
        ];
    }
}
