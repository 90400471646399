import { Injectable } from '@angular/core';
import moment from 'moment';
import { MediaConstants } from '@symplast/app-constants';
import { PrivateSettingsService } from '@symplast/services/shared';

@Injectable({
    providedIn: 'root',
})
export class FilesHelperService {
    constructor(private privateSettingsService: PrivateSettingsService) {}

    getFileExtension(fileName: string): string {
        return fileName != null && fileName.includes('.') ? fileName.split('.').pop().toLowerCase() : '';
    }

    getFileType(fileName: string): 'Unknown' | 'Image' | 'Video' | 'Pdf' | 'Audio' {
        const fileExtension = this.getFileExtension(fileName);

        return this.getFileTypeByExtension(fileExtension);
    }

    getFileTypeByExtension(fileExtension: string): 'Unknown' | 'Image' | 'Video' | 'Pdf' | 'Audio' {
        if (MediaConstants.ImageExtensions.includes(fileExtension)) {
            return 'Image';
        }

        if (MediaConstants.VideoExtensions.includes(fileExtension)) {
            return 'Video';
        }

        if (MediaConstants.AudioExtensions.includes(fileExtension)) {
            return 'Audio';
        }

        if (fileExtension === MediaConstants.PdfExtension) {
            return 'Pdf';
        }

        return 'Unknown';
    }

    getFilePreviewSrc(fileType: 'Unknown' | 'Image' | 'Video' | 'Pdf' | 'Audio'): string {
        switch (fileType) {
            case 'Video':
                return './../../../assets/images/video.png';
            case 'Audio':
                return './../../../assets/images/audio.png';
            case 'Pdf':
                return './../../../assets/images/pdf.png';
            case 'Image':
                return './../../../assets/images/image.png';
            default:
                return '';
        }
    }

    public getFileNameForExportClaims() {
        return `ClaimExport_${this.privateSettingsService.CurrentTenantValue.Name}_${moment().format('DDMMMYY_HHmm').toUpperCase()}.xlsx`;
    }
}
