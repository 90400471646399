/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableIcdCodeItemSettings } from '../models/api-response-ienumerable-icd-code-item-settings';
import { IcdCodeItemSettingCreateUpdateRequest } from '../models/icd-code-item-setting-create-update-request';
@Injectable({
  providedIn: 'root',
})
class ItemsIcdCodesService extends __BaseService {
  static readonly GetIcdCodeItemSettingsPath = '/Insurance/Items/{itemId}/IcdCodes';
  static readonly CreateIcdCodeItemSettingPath = '/Insurance/Items/{itemId}/IcdCodes';
  static readonly UpdateIcdCodeItemSettingPath = '/Insurance/Items/{itemId}/IcdCodes/{icdCodeSettingId}';
  static readonly DeleteIcdCodeItemSettingPath = '/Insurance/Items/{itemId}/IcdCodes/{icdCodeSettingId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of ICD code item settings.
   * @param itemId The item id.
   * @return Success
   */
  GetIcdCodeItemSettingsResponse(itemId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(itemId))}/IcdCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>;
      })
    );
  }
  /**
   * Gets a list of ICD code item settings.
   * @param itemId The item id.
   * @return Success
   */
  GetIcdCodeItemSettings(itemId: number): __Observable<ApiResponseIEnumerableIcdCodeItemSettings> {
    return this.GetIcdCodeItemSettingsResponse(itemId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableIcdCodeItemSettings)
    );
  }

  /**
   * Create a ICD code item settings.
   * @param params The `ItemsIcdCodesService.CreateIcdCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `icdCodeItemSettingCreateRequest`: The ICD code item settings to create.
   *
   * @return Success
   */
  CreateIcdCodeItemSettingResponse(params: ItemsIcdCodesService.CreateIcdCodeItemSettingParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.icdCodeItemSettingCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(params.itemId))}/IcdCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>;
      })
    );
  }
  /**
   * Create a ICD code item settings.
   * @param params The `ItemsIcdCodesService.CreateIcdCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `icdCodeItemSettingCreateRequest`: The ICD code item settings to create.
   *
   * @return Success
   */
  CreateIcdCodeItemSetting(params: ItemsIcdCodesService.CreateIcdCodeItemSettingParams): __Observable<ApiResponseIEnumerableIcdCodeItemSettings> {
    return this.CreateIcdCodeItemSettingResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableIcdCodeItemSettings)
    );
  }

  /**
   * Update a ICD code item settings.
   * @param params The `ItemsIcdCodesService.UpdateIcdCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `icdCodeSettingId`: The ICD Code settings Id.
   *
   * - `icdCodeItemSettingUpdateRequest`: The ICD code item settings to update.
   *
   * @return Success
   */
  UpdateIcdCodeItemSettingResponse(params: ItemsIcdCodesService.UpdateIcdCodeItemSettingParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.icdCodeItemSettingUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(params.itemId))}/IcdCodes/${encodeURIComponent(String(params.icdCodeSettingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>;
      })
    );
  }
  /**
   * Update a ICD code item settings.
   * @param params The `ItemsIcdCodesService.UpdateIcdCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `icdCodeSettingId`: The ICD Code settings Id.
   *
   * - `icdCodeItemSettingUpdateRequest`: The ICD code item settings to update.
   *
   * @return Success
   */
  UpdateIcdCodeItemSetting(params: ItemsIcdCodesService.UpdateIcdCodeItemSettingParams): __Observable<ApiResponseIEnumerableIcdCodeItemSettings> {
    return this.UpdateIcdCodeItemSettingResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableIcdCodeItemSettings)
    );
  }

  /**
   * Delete a ICD code item settings.
   * @param params The `ItemsIcdCodesService.DeleteIcdCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `icdCodeSettingId`: The ICD Code settings Id.
   *
   * @return Success
   */
  DeleteIcdCodeItemSettingResponse(params: ItemsIcdCodesService.DeleteIcdCodeItemSettingParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Insurance/Items/${encodeURIComponent(String(params.itemId))}/IcdCodes/${encodeURIComponent(String(params.icdCodeSettingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableIcdCodeItemSettings>;
      })
    );
  }
  /**
   * Delete a ICD code item settings.
   * @param params The `ItemsIcdCodesService.DeleteIcdCodeItemSettingParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `icdCodeSettingId`: The ICD Code settings Id.
   *
   * @return Success
   */
  DeleteIcdCodeItemSetting(params: ItemsIcdCodesService.DeleteIcdCodeItemSettingParams): __Observable<ApiResponseIEnumerableIcdCodeItemSettings> {
    return this.DeleteIcdCodeItemSettingResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableIcdCodeItemSettings)
    );
  }
}

module ItemsIcdCodesService {

  /**
   * Parameters for CreateIcdCodeItemSetting
   */
  export interface CreateIcdCodeItemSettingParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The ICD code item settings to create.
     */
    icdCodeItemSettingCreateRequest?: IcdCodeItemSettingCreateUpdateRequest;
  }

  /**
   * Parameters for UpdateIcdCodeItemSetting
   */
  export interface UpdateIcdCodeItemSettingParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The ICD Code settings Id.
     */
    icdCodeSettingId: number;

    /**
     * The ICD code item settings to update.
     */
    icdCodeItemSettingUpdateRequest?: IcdCodeItemSettingCreateUpdateRequest;
  }

  /**
   * Parameters for DeleteIcdCodeItemSetting
   */
  export interface DeleteIcdCodeItemSettingParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The ICD Code settings Id.
     */
    icdCodeSettingId: number;
  }
}

export { ItemsIcdCodesService }
