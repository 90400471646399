import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin/src/symbols';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { NgxsConfig } from '@ngxs/store/src/symbols';

import { environment } from '../../environments/environment';
import { LogoutPlugin } from './plugins/logout.plugin';
import { rootState } from '@symplast/services/shared';

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
    /**
     * Run in development mode. This will add additional debugging features:
     * - Object.freeze on the state and actions to guarantee immutability
     */
    developmentMode: !environment.production,
};

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
    /**
     * Whether the dev tools is enabled or not. Useful for setting during production.
     */
    disabled: environment.production,
};

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
    /**
     * Disable the logger. Useful for prod mode..
     */
    disabled: environment.production,
};

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forRoot(rootState, OPTIONS_CONFIG),
        NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
        NgxsLoggerPluginModule.forRoot(LOGGER_CONFIG),
        NgxsEmitPluginModule.forRoot(),
        NgxsDispatchPluginModule.forRoot(),
    ],
    providers: [
        {
            provide: NGXS_PLUGINS,
            useClass: LogoutPlugin,
            multi: true,
        },
    ],
    exports: [NgxsModule],
})
export class NgxsStoreModule {}
