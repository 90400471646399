import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnDestroy,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { TextareaHighlightElementDirective } from './textarea-highlight.directive';
import { styleProperties } from './style-properties';
import { CommonModule } from '@angular/common';
import { regexpWrapAll } from '@symplast/utils';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    standalone: true,
    selector: 'symplast-textarea-highlight',
    imports: [CommonModule],
    templateUrl: './textarea-highlight.component.html',
    styleUrls: ['./textarea-highlight.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaHighlightComponent implements AfterViewInit, OnDestroy {
    @Input() regexp!: RegExp;
    @ContentChild(TextareaHighlightElementDirective) textarea!: TextareaHighlightElementDirective;
    @ViewChild('highlightElement') highlightElement!: ElementRef;

    /** @private */
    public highlightElementContainerStyle: { [key: string]: string } = {};
    public highlightedText = '';

    private resizeObserver!: ResizeObserver | null;
    private textareaListeners: Function[] = [];

    constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

    /** Manually call this function to refresh the highlight element if the textarea styles have changed */
    public refresh(): void {
        this.hightlightText();
        this.refreshLayout();

        this.cdr.detectChanges();
    }

    /** @private */
    public ngAfterViewInit(): void {
        this.setupResizeListener();
        this.setupScrollListener();
        this.setupInputListener();
        this.refresh();
    }

    /** @private */
    public ngOnDestroy(): void {
        this.textareaListeners.forEach((unregister) => unregister());
        this.textareaListeners = [];
    }

    private refreshLayout(): void {
        const computed = getComputedStyle(this.textarea.elementRef.nativeElement);

        styleProperties.forEach((prop: string) => {
            this.highlightElementContainerStyle[prop] = computed[prop as unknown as any];
        });
    }

    private setupScrollListener(): void {
        const unregister = this.renderer.listen(this.textarea.elementRef.nativeElement, 'scroll', () => {
            this.highlightElement.nativeElement.scrollTop = this.textarea.elementRef.nativeElement.scrollTop;
            this.cdr.detectChanges();
        });

        this.textareaListeners.push(unregister);
    }

    private setupInputListener(): void {
        const onInput = (): void => {
            this.hightlightText();
            this.cdr.detectChanges();
        };

        if (this.textarea.ngControl) {
            const subscription = (this.textarea.ngControl.valueChanges as Observable<string>).pipe(untilDestroyed(this)).subscribe(onInput);

            this.textareaListeners.push(() => subscription.unsubscribe());
        } else {
            const unregister = this.renderer.listen(this.textarea.elementRef.nativeElement, 'input', onInput);

            this.textareaListeners.push(unregister);
        }
    }

    private hightlightText(): void {
        const value = this.textarea.elementRef.nativeElement.value;
        // This line fixes a bug where a trailing carriage return causes the highlights <div> to become misaligned
        const fixedValue = value.replace(/\n$/g, '\n\n');
        const highlightedText = regexpWrapAll(fixedValue, this.regexp, (match: string) => `<mark>${match}</mark>`);

        this.highlightedText = highlightedText;
    }

    private setupResizeListener(): void {
        this.resizeObserver = new ResizeObserver(() => this.refresh());

        this.resizeObserver.observe(this.textarea.elementRef.nativeElement);

        this.textareaListeners.push(() => {
            if (this.resizeObserver) {
                this.resizeObserver.disconnect();
                this.resizeObserver = null;
            }
        });
    }
}
