<nav class="menu" [class.menu--open]="isOpen" data-e2e="sidenav">
    <div class="menu__logo">
        <img src="/assets/images/Symplast-logo.png" data-e2e="sidenav__logo" />
    </div>
    <ul class="menu__items">
        <li class="item menu__item" routerLinkActive="item--active" [routerLinkActiveOptions]="{ exact: true }">
            <a class="item__link" routerLink="/" href="#" data-e2e="sidenav__tab-home">
                <i class="item__icon">
                    <svg-icon src="/assets/icons/icon_home.svg" data-e2e="sidenav__tab-home__icon"></svg-icon>
                </i>
                <span class="item__text" data-e2e="sidenav__tab-home__text">Home</span>
            </a>
        </li>

        <!-- TODO: (a.vakhrushin) add trackBy pipe -->
        <ng-container *ngFor="let tab of userTabsService.userTabs">
            <li *ngIf="!tab.visible$ || (tab.visible$ | async)" class="item menu__item" routerLinkActive="item--active">
                <a class="item__link" [routerLink]="tab.link" href="#" [attr.data-e2e]="'sidenav__tab-' + tab.e2e">
                    <i class="item__icon">
                        <svg-icon [src]="tab.iconSrc" [attr.data-e2e]="'sidenav__tab-' + tab.e2e + '__icon'"></svg-icon>
                    </i>

                    <span class="item__text" [attr.data-e2e]="'sidenav__tab-' + tab.e2e + '__text'">{{ tab.name }}</span>
                </a>
            </li>
        </ng-container>
    </ul>
    <button class="menu__toggler" (click)="toggle()" data-e2e="sidenav__toggle-button">
        <span class="version-number" [hidden]="!isOpen">version {{ version }}</span>
        <i [class.menu__toggler--active]="isOpen">
            <svg-icon src="/assets/icons/icon_OpenPane.svg"></svg-icon>
        </i>
    </button>
</nav>
