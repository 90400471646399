/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseEstimate } from '../models/api-response-estimate';
import { ItemGroupRequest } from '../models/item-group-request';
import { EstimateCreateRequest } from '../models/estimate-create-request';
import { ApiResponseICollectionEstimate } from '../models/api-response-icollection-estimate';
import { ApiResponsePatientSignature } from '../models/api-response-patient-signature';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { EstimateUpdateRequest } from '../models/estimate-update-request';
import { ApiResponseAvailableDepositsAmount } from '../models/api-response-available-deposits-amount';
import { ApiResponseICollectionAuditEntryHeader } from '../models/api-response-icollection-audit-entry-header';
import { ActionResult } from '../models/action-result';
import { ApiResponseString } from '../models/api-response-string';
import { ApiResponseICollectionPatientSignature } from '../models/api-response-icollection-patient-signature';
@Injectable({
    providedIn: 'root',
})
class EstimatesService extends __BaseService {
    static readonly AddItemGroupToEstimatePath = '/Estimates/{estimateId}/ItemGroups';
    static readonly AddItemGroupToEstimateObsoletePath = '/Estimates/Estimates/{estimateId}/ItemGroups';
    static readonly CloneEstimatePath = '/Estimates/{estimateId}/Clone';
    static readonly CreateEstimatePath = '/Estimates';
    static readonly GetEstimatesPath = '/Estimates';
    static readonly CreatePatientSignaturePath = '/Estimates/{estimateId}/PatientSignature';
    static readonly DeleteEstimatePath = '/Estimates/{estimateId}';
    static readonly GetEstimateByIdPath = '/Estimates/{estimateId}';
    static readonly UpdateEstimatePath = '/Estimates/{estimateId}';
    static readonly GetAvailableDepositsAmountPath = '/Estimates/{estimateId}/AvailableDepositsAmount';
    static readonly GetEstimateHistoryPath = '/Estimates/{estimateId}/History';
    static readonly GetEstimateHtmlByIdPath = '/Estimates/{estimateId}/Html';
    static readonly GetEstimatePdfByIdPath = '/Estimates/{estimateId}/Pdf';
    static readonly GetNextEstimateNumberPath = '/Estimates/NextEstimateNumber';
    static readonly GetPatientSignaturesByEstimateIdPath = '/Estimates/{estimateId}/PatientSignatures';
    static readonly UpdateEstimateStatusPath = '/Estimates/{estimateId}/Status';
    static readonly UpdateSharedWithPatientFlagForEstimatePath = '/Estimates/{estimateId}/SharedWithPatient';
    static readonly AddMembershipToEstimatePath = '/Estimates/Estimates/{estimateId}/Memberships/{membershipId}';
    static readonly DeleteEstimateMembershipPath = '/Estimates/Estimates/{estimateId}/Memberships/{estimateMembershipId}';
    static readonly GetEstimateItemDiscountPath = '/Estimates/Estimates/{estimateId}/EstimateItems/{estimateItemId}/DiscountRate';
    static readonly ChangeDepositLinkPath = '/Estimates/Estimates/{estimateId}/Deposit/{patientCreditId}/Link';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Add ItemGroup items to Estimate.
     * @param params The `EstimatesService.AddItemGroupToEstimateParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToEstimateResponse(
        params: EstimatesService.AddItemGroupToEstimateParams,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.itemGroupRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}/ItemGroups`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Add ItemGroup items to Estimate.
     * @param params The `EstimatesService.AddItemGroupToEstimateParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToEstimate(params: EstimatesService.AddItemGroupToEstimateParams): __Observable<ApiResponseEstimate> {
        return this.AddItemGroupToEstimateResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * Add ItemGroup items to Estimate.
     * @param params The `EstimatesService.AddItemGroupToEstimateObsoleteParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToEstimateObsoleteResponse(
        params: EstimatesService.AddItemGroupToEstimateObsoleteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.itemGroupRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Estimates/Estimates/${encodeURIComponent(String(params.estimateId))}/ItemGroups`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Add ItemGroup items to Estimate.
     * @param params The `EstimatesService.AddItemGroupToEstimateObsoleteParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToEstimateObsolete(params: EstimatesService.AddItemGroupToEstimateObsoleteParams): __Observable<ApiResponseEstimate> {
        return this.AddItemGroupToEstimateObsoleteResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * Clones an Estimate.
     * @param estimateId The estimate identifier.
     * @return Success or Created
     */
    CloneEstimateResponse(estimateId: number): __Observable<__StrictHttpResponse<ApiResponseEstimate | ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/Estimates/${encodeURIComponent(String(estimateId))}/Clone`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate | ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Clones an Estimate.
     * @param estimateId The estimate identifier.
     * @return Success or Created
     */
    CloneEstimate(estimateId: number): __Observable<ApiResponseEstimate | ApiResponseEstimate> {
        return this.CloneEstimateResponse(estimateId).pipe(__map((_r) => _r.body as ApiResponseEstimate | ApiResponseEstimate));
    }

    /**
     * Creates an Estimate.
     * @param estimateCreateRequest The estimate create request.
     * @return Success or Created
     */
    CreateEstimateResponse(
        estimateCreateRequest?: EstimateCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate | ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = estimateCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/Estimates`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate | ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Creates an Estimate.
     * @param estimateCreateRequest The estimate create request.
     * @return Success or Created
     */
    CreateEstimate(estimateCreateRequest?: EstimateCreateRequest): __Observable<ApiResponseEstimate | ApiResponseEstimate> {
        return this.CreateEstimateResponse(estimateCreateRequest).pipe(__map((_r) => _r.body as ApiResponseEstimate | ApiResponseEstimate));
    }

    /**
     * Gets the estimates by the search criteria.
     * @param params The `EstimatesService.GetEstimatesParams` containing the following parameters:
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether estimates need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OwnerId`: Gets or sets the owner identifier.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeIncomeType`: Gets or sets the include income type.
     *
     * - `IncludeEstimateItems`: Gets or sets a value indicating whether to include estimate items or not.
     *
     * - `EstimateType`: Gets or sets the estimate type.
     *
     * - `EstimateNumberQuery`: Gets or sets the estimate number query for estimate number to contain.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetEstimatesResponse(params: EstimatesService.GetEstimatesParams): __Observable<__StrictHttpResponse<ApiResponseICollectionEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.Take != null) __params = __params.set('Take', params.Take.toString());
        if (params.Status != null) __params = __params.set('Status', params.Status.toString());
        if (params.SortingRequest != null) __params = __params.set('SortingRequest', params.SortingRequest.toString());
        if (params.SortingDirectionRequest != null)
            __params = __params.set('SortingDirectionRequest', params.SortingDirectionRequest.toString());
        if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
        if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
        if (params.OwnerId != null) __params = __params.set('OwnerId', params.OwnerId.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
        if (params.IncludePatientName != null) __params = __params.set('IncludePatientName', params.IncludePatientName.toString());
        if (params.IncludeIncomeType != null) __params = __params.set('IncludeIncomeType', params.IncludeIncomeType.toString());
        if (params.IncludeEstimateItems != null) __params = __params.set('IncludeEstimateItems', params.IncludeEstimateItems.toString());
        if (params.EstimateType != null) __params = __params.set('EstimateType', params.EstimateType.toString());
        if (params.EstimateNumberQuery != null) __params = __params.set('EstimateNumberQuery', params.EstimateNumberQuery.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Estimates`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionEstimate>;
            }),
        );
    }
    /**
     * Gets the estimates by the search criteria.
     * @param params The `EstimatesService.GetEstimatesParams` containing the following parameters:
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether estimates need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OwnerId`: Gets or sets the owner identifier.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeIncomeType`: Gets or sets the include income type.
     *
     * - `IncludeEstimateItems`: Gets or sets a value indicating whether to include estimate items or not.
     *
     * - `EstimateType`: Gets or sets the estimate type.
     *
     * - `EstimateNumberQuery`: Gets or sets the estimate number query for estimate number to contain.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetEstimates(params: EstimatesService.GetEstimatesParams): __Observable<ApiResponseICollectionEstimate> {
        return this.GetEstimatesResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionEstimate));
    }

    /**
     * Creates an patient signature.
     * @param params The `EstimatesService.CreatePatientSignatureParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `file`:
     *
     * @return Success or Created
     */
    CreatePatientSignatureResponse(
        params: EstimatesService.CreatePatientSignatureParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientSignature | ApiResponsePatientSignature>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.file != null) {
            __formData.append('file', params.file as string | Blob);
        }
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}/PatientSignature`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientSignature | ApiResponsePatientSignature>;
            }),
        );
    }
    /**
     * Creates an patient signature.
     * @param params The `EstimatesService.CreatePatientSignatureParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `file`:
     *
     * @return Success or Created
     */
    CreatePatientSignature(
        params: EstimatesService.CreatePatientSignatureParams,
    ): __Observable<ApiResponsePatientSignature | ApiResponsePatientSignature> {
        return this.CreatePatientSignatureResponse(params).pipe(
            __map((_r) => _r.body as ApiResponsePatientSignature | ApiResponsePatientSignature),
        );
    }

    /**
     * Deletes the estimate.
     * @param params The `EstimatesService.DeleteEstimateParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `reason`: The reason for the estimate being deleted.
     *
     * @return Success
     */
    DeleteEstimateResponse(params: EstimatesService.DeleteEstimateParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the estimate.
     * @param params The `EstimatesService.DeleteEstimateParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `reason`: The reason for the estimate being deleted.
     *
     * @return Success
     */
    DeleteEstimate(params: EstimatesService.DeleteEstimateParams): __Observable<ApiResponseOkResponse> {
        return this.DeleteEstimateResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the estimate by identifier.
     * @param params The `EstimatesService.GetEstimateByIdParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `includeProviderName`: if set to `true` [include provider name].
     *
     * @return Success
     */
    GetEstimateByIdResponse(params: EstimatesService.GetEstimateByIdParams): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeProviderName != null) __params = __params.set('includeProviderName', params.includeProviderName.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Gets the estimate by identifier.
     * @param params The `EstimatesService.GetEstimateByIdParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `includeProviderName`: if set to `true` [include provider name].
     *
     * @return Success
     */
    GetEstimateById(params: EstimatesService.GetEstimateByIdParams): __Observable<ApiResponseEstimate> {
        return this.GetEstimateByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * Updates the Estimate.
     * @param params The `EstimatesService.UpdateEstimateParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `estimateUpdateRequest`: The estimate update request.
     *
     * @return Success
     */
    UpdateEstimateResponse(params: EstimatesService.UpdateEstimateParams): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.estimateUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Updates the Estimate.
     * @param params The `EstimatesService.UpdateEstimateParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `estimateUpdateRequest`: The estimate update request.
     *
     * @return Success
     */
    UpdateEstimate(params: EstimatesService.UpdateEstimateParams): __Observable<ApiResponseEstimate> {
        return this.UpdateEstimateResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * Gets the total amount of all available deposits applied to a given estimate.
     * @param estimateId the estimate identifier.
     * @return Success
     */
    GetAvailableDepositsAmountResponse(estimateId: number): __Observable<__StrictHttpResponse<ApiResponseAvailableDepositsAmount>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Estimates/${encodeURIComponent(String(estimateId))}/AvailableDepositsAmount`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAvailableDepositsAmount>;
            }),
        );
    }
    /**
     * Gets the total amount of all available deposits applied to a given estimate.
     * @param estimateId the estimate identifier.
     * @return Success
     */
    GetAvailableDepositsAmount(estimateId: number): __Observable<ApiResponseAvailableDepositsAmount> {
        return this.GetAvailableDepositsAmountResponse(estimateId).pipe(__map((_r) => _r.body as ApiResponseAvailableDepositsAmount));
    }

    /**
     * Gets the estimate history.
     * @param estimateId The estimate identifier.
     * @return Success
     */
    GetEstimateHistoryResponse(estimateId: number): __Observable<__StrictHttpResponse<ApiResponseICollectionAuditEntryHeader>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Estimates/${encodeURIComponent(String(estimateId))}/History`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionAuditEntryHeader>;
            }),
        );
    }
    /**
     * Gets the estimate history.
     * @param estimateId The estimate identifier.
     * @return Success
     */
    GetEstimateHistory(estimateId: number): __Observable<ApiResponseICollectionAuditEntryHeader> {
        return this.GetEstimateHistoryResponse(estimateId).pipe(__map((_r) => _r.body as ApiResponseICollectionAuditEntryHeader));
    }

    /**
     * Gets the html view for printing an estimate.
     * @param estimateId the estimate identifier.
     * @return Success
     */
    GetEstimateHtmlByIdResponse(estimateId: number): __Observable<__StrictHttpResponse<ActionResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Estimates/${encodeURIComponent(String(estimateId))}/Html`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ActionResult>;
            }),
        );
    }
    /**
     * Gets the html view for printing an estimate.
     * @param estimateId the estimate identifier.
     * @return Success
     */
    GetEstimateHtmlById(estimateId: number): __Observable<ActionResult> {
        return this.GetEstimateHtmlByIdResponse(estimateId).pipe(__map((_r) => _r.body as ActionResult));
    }

    /**
     * Gets the estimate PDF by identifier.
     * @param estimateId The estimate identifier.
     * @return Success
     */
    GetEstimatePdfByIdResponse(estimateId: number): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Estimates/${encodeURIComponent(String(estimateId))}/Pdf`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Gets the estimate PDF by identifier.
     * @param estimateId The estimate identifier.
     * @return Success
     */
    GetEstimatePdfById(estimateId: number): __Observable<Blob> {
        return this.GetEstimatePdfByIdResponse(estimateId).pipe(__map((_r) => _r.body as Blob));
    }

    /**
     * Gets the next estimate number.
     * @param estimateType The estimate Type.
     * @return Success
     */
    GetNextEstimateNumberResponse(estimateType?: 'Estimate' | 'Package'): __Observable<__StrictHttpResponse<ApiResponseString>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (estimateType != null) __params = __params.set('estimateType', estimateType.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Estimates/NextEstimateNumber`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseString>;
            }),
        );
    }
    /**
     * Gets the next estimate number.
     * @param estimateType The estimate Type.
     * @return Success
     */
    GetNextEstimateNumber(estimateType?: 'Estimate' | 'Package'): __Observable<ApiResponseString> {
        return this.GetNextEstimateNumberResponse(estimateType).pipe(__map((_r) => _r.body as ApiResponseString));
    }

    /**
     * Gets the patient signatures by estimate identifier.
     * @param params The `EstimatesService.GetPatientSignaturesByEstimateIdParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `isDeleted`: if set to `true` [is deleted].
     *
     * @return Success
     */
    GetPatientSignaturesByEstimateIdResponse(
        params: EstimatesService.GetPatientSignaturesByEstimateIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientSignature>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}/PatientSignatures`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionPatientSignature>;
            }),
        );
    }
    /**
     * Gets the patient signatures by estimate identifier.
     * @param params The `EstimatesService.GetPatientSignaturesByEstimateIdParams` containing the following parameters:
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `isDeleted`: if set to `true` [is deleted].
     *
     * @return Success
     */
    GetPatientSignaturesByEstimateId(
        params: EstimatesService.GetPatientSignaturesByEstimateIdParams,
    ): __Observable<ApiResponseICollectionPatientSignature> {
        return this.GetPatientSignaturesByEstimateIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionPatientSignature));
    }

    /**
     * Updates the Estimate's Status.
     * @param params The `EstimatesService.UpdateEstimateStatusParams` containing the following parameters:
     *
     * - `newStatus`: The new status.
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `reason`: The reject reason. Must be provided only when changing status to Rejected and optionally when
     *               deleted.
     *
     * - `patientNotPresentApproved`: if set to `true` [patient not present approved].
     *
     * @return Success
     */
    UpdateEstimateStatusResponse(
        params: EstimatesService.UpdateEstimateStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.newStatus != null) __params = __params.set('newStatus', params.newStatus.toString());

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        if (params.patientNotPresentApproved != null)
            __params = __params.set('patientNotPresentApproved', params.patientNotPresentApproved.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}/Status`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Updates the Estimate's Status.
     * @param params The `EstimatesService.UpdateEstimateStatusParams` containing the following parameters:
     *
     * - `newStatus`: The new status.
     *
     * - `estimateId`: The estimate identifier.
     *
     * - `reason`: The reject reason. Must be provided only when changing status to Rejected and optionally when
     *               deleted.
     *
     * - `patientNotPresentApproved`: if set to `true` [patient not present approved].
     *
     * @return Success
     */
    UpdateEstimateStatus(params: EstimatesService.UpdateEstimateStatusParams): __Observable<ApiResponseEstimate> {
        return this.UpdateEstimateStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * Updates the Estimate's SharedWithPatient parameter.
     * @param params The `EstimatesService.UpdateSharedWithPatientFlagForEstimateParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `estimateId`: The estimate identifier.
     *
     * @return Success
     */
    UpdateSharedWithPatientFlagForEstimateResponse(
        params: EstimatesService.UpdateSharedWithPatientFlagForEstimateParams,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.newValue != null) __params = __params.set('newValue', params.newValue.toString());

        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Estimates/${encodeURIComponent(String(params.estimateId))}/SharedWithPatient`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Updates the Estimate's SharedWithPatient parameter.
     * @param params The `EstimatesService.UpdateSharedWithPatientFlagForEstimateParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `estimateId`: The estimate identifier.
     *
     * @return Success
     */
    UpdateSharedWithPatientFlagForEstimate(
        params: EstimatesService.UpdateSharedWithPatientFlagForEstimateParams,
    ): __Observable<ApiResponseEstimate> {
        return this.UpdateSharedWithPatientFlagForEstimateResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * The add membership.
     * @param params The `EstimatesService.AddMembershipToEstimateParams` containing the following parameters:
     *
     * - `membershipId`: The membership id.
     *
     * - `estimateId`: The estimate id.
     *
     * @return Success
     */
    AddMembershipToEstimateResponse(
        params: EstimatesService.AddMembershipToEstimateParams,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl +
                `/Estimates/Estimates/${encodeURIComponent(String(params.estimateId))}/Memberships/${encodeURIComponent(
                    String(params.membershipId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * The add membership.
     * @param params The `EstimatesService.AddMembershipToEstimateParams` containing the following parameters:
     *
     * - `membershipId`: The membership id.
     *
     * - `estimateId`: The estimate id.
     *
     * @return Success
     */
    AddMembershipToEstimate(params: EstimatesService.AddMembershipToEstimateParams): __Observable<ApiResponseEstimate> {
        return this.AddMembershipToEstimateResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * The delete estimate membership.
     * @param params The `EstimatesService.DeleteEstimateMembershipParams` containing the following parameters:
     *
     * - `estimateMembershipId`: The estimate membership id.
     *
     * - `estimateId`: The estimate id.
     *
     * @return Success
     */
    DeleteEstimateMembershipResponse(
        params: EstimatesService.DeleteEstimateMembershipParams,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl +
                `/Estimates/Estimates/${encodeURIComponent(String(params.estimateId))}/Memberships/${encodeURIComponent(
                    String(params.estimateMembershipId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * The delete estimate membership.
     * @param params The `EstimatesService.DeleteEstimateMembershipParams` containing the following parameters:
     *
     * - `estimateMembershipId`: The estimate membership id.
     *
     * - `estimateId`: The estimate id.
     *
     * @return Success
     */
    DeleteEstimateMembership(params: EstimatesService.DeleteEstimateMembershipParams): __Observable<ApiResponseEstimate> {
        return this.DeleteEstimateMembershipResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * Gets the item discount.
     * @param params The `EstimatesService.GetEstimateItemDiscountParams` containing the following parameters:
     *
     * - `estimateItemId`: The estimate item identifier.
     *
     * - `estimateId`: The estimate identifier.
     *
     * @return Success
     */
    GetEstimateItemDiscountResponse(
        params: EstimatesService.GetEstimateItemDiscountParams,
    ): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl +
                `/Estimates/Estimates/${encodeURIComponent(String(params.estimateId))}/EstimateItems/${encodeURIComponent(
                    String(params.estimateItemId),
                )}/DiscountRate`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Gets the item discount.
     * @param params The `EstimatesService.GetEstimateItemDiscountParams` containing the following parameters:
     *
     * - `estimateItemId`: The estimate item identifier.
     *
     * - `estimateId`: The estimate identifier.
     *
     * @return Success
     */
    GetEstimateItemDiscount(params: EstimatesService.GetEstimateItemDiscountParams): __Observable<ApiResponseEstimate> {
        return this.GetEstimateItemDiscountResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }

    /**
     * Link a patient credit as a deposit to an estimate.
     * @param params The `EstimatesService.ChangeDepositLinkParams` containing the following parameters:
     *
     * - `patientCreditId`: The patient credit id.
     *
     * - `estimateId`: The estimate id.
     *
     * - `shouldBeLinked`: Should the deposit be linked.
     *
     * @return Success
     */
    ChangeDepositLinkResponse(params: EstimatesService.ChangeDepositLinkParams): __Observable<__StrictHttpResponse<ApiResponseEstimate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.shouldBeLinked != null) __params = __params.set('shouldBeLinked', params.shouldBeLinked.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/Estimates/Estimates/${encodeURIComponent(String(params.estimateId))}/Deposit/${encodeURIComponent(
                    String(params.patientCreditId),
                )}/Link`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseEstimate>;
            }),
        );
    }
    /**
     * Link a patient credit as a deposit to an estimate.
     * @param params The `EstimatesService.ChangeDepositLinkParams` containing the following parameters:
     *
     * - `patientCreditId`: The patient credit id.
     *
     * - `estimateId`: The estimate id.
     *
     * - `shouldBeLinked`: Should the deposit be linked.
     *
     * @return Success
     */
    ChangeDepositLink(params: EstimatesService.ChangeDepositLinkParams): __Observable<ApiResponseEstimate> {
        return this.ChangeDepositLinkResponse(params).pipe(__map((_r) => _r.body as ApiResponseEstimate));
    }
}

module EstimatesService {
    /**
     * Parameters for AddItemGroupToEstimate
     */
    export interface AddItemGroupToEstimateParams {
        /**
         * The estimate identifier.
         */
        estimateId: number;

        /**
         * The item group request.
         */
        itemGroupRequest?: ItemGroupRequest;
    }

    /**
     * Parameters for AddItemGroupToEstimateObsolete
     */
    export interface AddItemGroupToEstimateObsoleteParams {
        /**
         * The estimate identifier.
         */
        estimateId: number;

        /**
         * The item group request.
         */
        itemGroupRequest?: ItemGroupRequest;
    }

    /**
     * Parameters for GetEstimates
     */
    export interface GetEstimatesParams {
        /**
         * Gets or sets the take.
         */
        Take?: number;

        /**
         * Gets or sets a value in indicating whether estimates need to be filtered by their status or group of statuses (like All).
         */
        Status?:
            | 'Draft'
            | 'Open'
            | 'ReviewedByPatient'
            | 'Rejected'
            | 'ApprovedByPatient'
            | 'Delivered'
            | 'InvoiceCreated'
            | 'Cancelled'
            | 'Deleted'
            | 'Funded'
            | 'PartiallyFunded'
            | 'AllActive'
            | 'AllIncludingDeleted'
            | 'AllInactive'
            | 'AllExcludingDeleted'
            | 'AllOpenPackages'
            | 'AllClosedPackages'
            | 'AllAwaitingPayment';

        /**
         * Gets or sets the sorting request.
         */
        SortingRequest?: 'Id' | 'Date' | 'Number' | 'PatientName' | 'ModifiedDate';

        /**
         * Gets or sets the sorting direction request.
         */
        SortingDirectionRequest?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the skip.
         */
        Skip?: number;

        /**
         * Gets or sets the patient identifier.
         */
        PatientId?: number;

        /**
         * Gets or sets the owner identifier.
         */
        OwnerId?: number;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;

        /**
         * Gets or sets the location identifier.
         */
        LocationId?: number;

        /**
         * Gets or sets a value indicating whether [include patient name].
         */
        IncludePatientName?: boolean;

        /**
         * Gets or sets the include income type.
         */
        IncludeIncomeType?: boolean;

        /**
         * Gets or sets a value indicating whether to include estimate items or not.
         */
        IncludeEstimateItems?: boolean;

        /**
         * Gets or sets the estimate type.
         */
        EstimateType?: 'Estimate' | 'Package';

        /**
         * Gets or sets the estimate number query for estimate number to contain.
         */
        EstimateNumberQuery?: string;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;
    }

    /**
     * Parameters for CreatePatientSignature
     */
    export interface CreatePatientSignatureParams {
        /**
         * The estimate identifier.
         */
        estimateId: number;
        file?: Blob;
    }

    /**
     * Parameters for DeleteEstimate
     */
    export interface DeleteEstimateParams {
        /**
         * The estimate identifier.
         */
        estimateId: number;

        /**
         * The reason for the estimate being deleted.
         */
        reason?: string;
    }

    /**
     * Parameters for GetEstimateById
     */
    export interface GetEstimateByIdParams {
        /**
         * The estimate identifier.
         */
        estimateId: number;

        /**
         * if set to `true` [include provider name].
         */
        includeProviderName?: boolean;
    }

    /**
     * Parameters for UpdateEstimate
     */
    export interface UpdateEstimateParams {
        /**
         * The estimate identifier.
         */
        estimateId: number;

        /**
         * The estimate update request.
         */
        estimateUpdateRequest?: EstimateUpdateRequest;
    }

    /**
     * Parameters for GetPatientSignaturesByEstimateId
     */
    export interface GetPatientSignaturesByEstimateIdParams {
        /**
         * The estimate identifier.
         */
        estimateId: number;

        /**
         * if set to `true` [is deleted].
         */
        isDeleted?: boolean;
    }

    /**
     * Parameters for UpdateEstimateStatus
     */
    export interface UpdateEstimateStatusParams {
        /**
         * The new status.
         */
        newStatus: 'Draft' | 'Open' | 'ReviewedByPatient' | 'Rejected' | 'ApprovedByPatient' | 'Delivered' | 'InvoiceCreated' | 'Cancelled';

        /**
         * The estimate identifier.
         */
        estimateId: number;

        /**
         * The reject reason. Must be provided only when changing status to Rejected and optionally when
         *             deleted.
         */
        reason?: string;

        /**
         * if set to `true` [patient not present approved].
         */
        patientNotPresentApproved?: boolean;
    }

    /**
     * Parameters for UpdateSharedWithPatientFlagForEstimate
     */
    export interface UpdateSharedWithPatientFlagForEstimateParams {
        /**
         * if set to `true` [new value].
         */
        newValue: boolean;

        /**
         * The estimate identifier.
         */
        estimateId: number;
    }

    /**
     * Parameters for AddMembershipToEstimate
     */
    export interface AddMembershipToEstimateParams {
        /**
         * The membership id.
         */
        membershipId: number;

        /**
         * The estimate id.
         */
        estimateId: number;
    }

    /**
     * Parameters for DeleteEstimateMembership
     */
    export interface DeleteEstimateMembershipParams {
        /**
         * The estimate membership id.
         */
        estimateMembershipId: number;

        /**
         * The estimate id.
         */
        estimateId: number;
    }

    /**
     * Parameters for GetEstimateItemDiscount
     */
    export interface GetEstimateItemDiscountParams {
        /**
         * The estimate item identifier.
         */
        estimateItemId: number;

        /**
         * The estimate identifier.
         */
        estimateId: number;
    }

    /**
     * Parameters for ChangeDepositLink
     */
    export interface ChangeDepositLinkParams {
        /**
         * The patient credit id.
         */
        patientCreditId: number;

        /**
         * The estimate id.
         */
        estimateId: number;

        /**
         * Should the deposit be linked.
         */
        shouldBeLinked?: boolean;
    }
}

export { EstimatesService };
