import { Injectable } from '@angular/core';
import { PrivateSettingsService } from '@symplast/services/shared';
import { CurrentUserService } from '../current-user.service';
import { Observable, combineLatest, map } from 'rxjs';
import { getObservableSnasphot } from '@symplast/utils';
import { InsuranceAccessConfiguration, InsuranceContractType, InsuranceLicenseType } from './insurance-access.model';
import { INSURANCE_CONTRACT_MAP, INSURANCE_LICENSE_MAP } from './insurance-access.consts';

@Injectable({ providedIn: 'root' })
export class InsuranceAccessService {
    public static readonly INSURANCE_RELATED_ROLES = ['Admin', 'Billing Company'];

    // true if insurance is enabled in the app settings
    public insuranceEnabled$ = this.privateSettingsService.insuranceEnabled$;
    // true if the user has any of the insurance related roles
    public hasInsuranceRelatedRoles$!: Observable<boolean>;
    // returns the license type of the insurance module for the current user
    public insuranceLicense$!: Observable<InsuranceLicenseType>;
    // returns the contract type of the insurance module for the current user
    public insuranceContract$ = this.privateSettingsService.insuranceContract$.pipe(
        map((contract) => INSURANCE_CONTRACT_MAP[contract] || InsuranceContractType.NOT_CONTRACTED),
    );

    constructor(private privateSettingsService: PrivateSettingsService, private currentUserService: CurrentUserService) {
        this.setupInsuranceRelatedRoles();
        this.setupInsuranceLicenseType();
    }

    public get insuranceEnabled(): boolean {
        return this.privateSettingsService.insuranceEnabled;
    }

    public get insuranceLicense(): InsuranceLicenseType {
        return getObservableSnasphot(this.insuranceLicense$) || InsuranceLicenseType.NONE;
    }

    public get insuranceContract(): InsuranceContractType {
        return INSURANCE_CONTRACT_MAP[this.privateSettingsService.insuranceContract] || InsuranceContractType.NOT_CONTRACTED;
    }

    public checkAccess(configuration: InsuranceAccessConfiguration | InsuranceAccessConfiguration[]): boolean {
        if (!this.insuranceEnabled) {
            return false;
        }

        const configurations = Array.isArray(configuration) ? configuration : [configuration];

        return configurations.some((_configuration) => this.checkAccessConfiguration(_configuration));
    }

    public checkAccess$(configuration: InsuranceAccessConfiguration | InsuranceAccessConfiguration[]): Observable<boolean> {
        return combineLatest([this.insuranceEnabled$, this.insuranceLicense$, this.insuranceContract$]).pipe(
            map(() => this.checkAccess(configuration)),
        );
    }

    private checkAccessConfiguration(accessConfiguration: InsuranceAccessConfiguration): boolean {
        const contractAccess = this.checkContractAccess(accessConfiguration.contract);
        const licenseAccess = this.checkLicenseAccess(accessConfiguration.license);

        return contractAccess && licenseAccess;
    }

    private checkContractAccess(contract?: InsuranceContractType | InsuranceContractType[]): boolean {
        if (!contract) {
            return true;
        }

        const contracts = Array.isArray(contract) ? contract : [contract];

        return contracts.includes(this.insuranceContract);
    }

    private checkLicenseAccess(license?: InsuranceLicenseType | InsuranceLicenseType[]): boolean {
        if (!license) {
            return true;
        }

        const licenses = Array.isArray(license) ? license : [license];

        return licenses.includes(this.insuranceLicense);
    }

    private setupInsuranceRelatedRoles(): void {
        this.hasInsuranceRelatedRoles$ = this.currentUserService.user$.pipe(
            map((user) => user?.hasRole(InsuranceAccessService.INSURANCE_RELATED_ROLES)),
        );
    }

    private setupInsuranceLicenseType(): void {
        this.insuranceLicense$ = this.currentUserService.user$.pipe(
            map((user) => {
                const licenses = [InsuranceLicenseType.READ_ONLY, InsuranceLicenseType.FULL_ACCESS];
                const license = licenses.find((licenseType) => user?.hasLicense(INSURANCE_LICENSE_MAP[licenseType]));

                return license || InsuranceLicenseType.NONE;
            }),
        );
    }
}
