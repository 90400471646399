/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionItemType } from '../models/api-response-icollection-item-type';
import { ApiResponseItemType } from '../models/api-response-item-type';
import { ItemTypeCreateRequest } from '../models/item-type-create-request';
import { ItemTypeUpdateRequest } from '../models/item-type-update-request';
@Injectable({
    providedIn: 'root',
})
class ItemTypesService extends __BaseService {
    static readonly GetAllItemTypesPath = '/ItemTypes';
    static readonly CreateItemTypePath = '/ItemTypes';
    static readonly GetItemTypePath = '/ItemTypes/{itemTypeId}';
    static readonly UpdateItemTypePath = '/ItemTypes/{itemTypeId}';
    static readonly DeletePath = '/ItemTypes/{itemTypeId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Get all the Item Types.
     * @param params The `ItemTypesService.GetAllItemTypesParams` containing the following parameters:
     *
     * - `showPaymentSchedules`: Optional parameter to filter item types by flag ShowPaymentSchedules.
     *
     * - `showIsSelectable`: The show Is Selectable.
     *
     * - `showInventory`: Optional parameter to filter item types by flag ShowInventory.
     *
     * - `showCollection`: Optional parameter to filter item types by flag ShowCollection.
     *
     * @return Success
     */
    GetAllItemTypesResponse(
        params: ItemTypesService.GetAllItemTypesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionItemType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.showPaymentSchedules != null) __params = __params.set('showPaymentSchedules', params.showPaymentSchedules.toString());
        if (params.showIsSelectable != null) __params = __params.set('showIsSelectable', params.showIsSelectable.toString());
        if (params.showInventory != null) __params = __params.set('showInventory', params.showInventory.toString());
        if (params.showCollection != null) __params = __params.set('showCollection', params.showCollection.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/ItemTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionItemType>;
            }),
        );
    }
    /**
     * Get all the Item Types.
     * @param params The `ItemTypesService.GetAllItemTypesParams` containing the following parameters:
     *
     * - `showPaymentSchedules`: Optional parameter to filter item types by flag ShowPaymentSchedules.
     *
     * - `showIsSelectable`: The show Is Selectable.
     *
     * - `showInventory`: Optional parameter to filter item types by flag ShowInventory.
     *
     * - `showCollection`: Optional parameter to filter item types by flag ShowCollection.
     *
     * @return Success
     */
    GetAllItemTypes(params: ItemTypesService.GetAllItemTypesParams): __Observable<ApiResponseICollectionItemType> {
        return this.GetAllItemTypesResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionItemType));
    }

    /**
     * Create an item type.
     * @param itemType The item type.
     * @return Success
     */
    CreateItemTypeResponse(itemType?: ItemTypeCreateRequest): __Observable<__StrictHttpResponse<ApiResponseItemType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = itemType;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/ItemTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemType>;
            }),
        );
    }
    /**
     * Create an item type.
     * @param itemType The item type.
     * @return Success
     */
    CreateItemType(itemType?: ItemTypeCreateRequest): __Observable<ApiResponseItemType> {
        return this.CreateItemTypeResponse(itemType).pipe(__map((_r) => _r.body as ApiResponseItemType));
    }

    /**
     * Get a specific Item Type.
     * @param itemTypeId The item type id.
     * @return Success
     */
    GetItemTypeResponse(itemTypeId: number): __Observable<__StrictHttpResponse<ApiResponseItemType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/ItemTypes/${encodeURIComponent(String(itemTypeId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemType>;
            }),
        );
    }
    /**
     * Get a specific Item Type.
     * @param itemTypeId The item type id.
     * @return Success
     */
    GetItemType(itemTypeId: number): __Observable<ApiResponseItemType> {
        return this.GetItemTypeResponse(itemTypeId).pipe(__map((_r) => _r.body as ApiResponseItemType));
    }

    /**
     * Update an Item Type.
     * @param params The `ItemTypesService.UpdateItemTypeParams` containing the following parameters:
     *
     * - `itemTypeId`: The item type id.
     *
     * - `itemType`: The item type.
     *
     * @return Success
     */
    UpdateItemTypeResponse(params: ItemTypesService.UpdateItemTypeParams): __Observable<__StrictHttpResponse<ApiResponseItemType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.itemType;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/ItemTypes/${encodeURIComponent(String(params.itemTypeId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemType>;
            }),
        );
    }
    /**
     * Update an Item Type.
     * @param params The `ItemTypesService.UpdateItemTypeParams` containing the following parameters:
     *
     * - `itemTypeId`: The item type id.
     *
     * - `itemType`: The item type.
     *
     * @return Success
     */
    UpdateItemType(params: ItemTypesService.UpdateItemTypeParams): __Observable<ApiResponseItemType> {
        return this.UpdateItemTypeResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemType));
    }

    /**
     * Delete an item type.
     * @param itemTypeId The item type id.
     */
    DeleteResponse(itemTypeId: number): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/ItemTypes/${encodeURIComponent(String(itemTypeId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            }),
        );
    }
    /**
     * Delete an item type.
     * @param itemTypeId The item type id.
     */
    Delete(itemTypeId: number): __Observable<null> {
        return this.DeleteResponse(itemTypeId).pipe(__map((_r) => _r.body as null));
    }
}

module ItemTypesService {
    /**
     * Parameters for GetAllItemTypes
     */
    export interface GetAllItemTypesParams {
        /**
         * Optional parameter to filter item types by flag ShowPaymentSchedules.
         */
        showPaymentSchedules?: 'False' | 'True' | 'Null';

        /**
         * The show Is Selectable.
         */
        showIsSelectable?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter item types by flag ShowInventory.
         */
        showInventory?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter item types by flag ShowCollection.
         */
        showCollection?: 'False' | 'True' | 'Null';
    }

    /**
     * Parameters for UpdateItemType
     */
    export interface UpdateItemTypeParams {
        /**
         * The item type id.
         */
        itemTypeId: number;

        /**
         * The item type.
         */
        itemType?: ItemTypeUpdateRequest;
    }
}

export { ItemTypesService };
