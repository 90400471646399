/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableItem } from '../models/api-response-ienumerable-item';
import { ApiResponseItem } from '../models/api-response-item';
import { ItemCreationRequest } from '../models/item-creation-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ItemUpdateRequest } from '../models/item-update-request';
import { ApiResponseICollectionCategory } from '../models/api-response-icollection-category';
import { GaldermaProductLinkUpdateRequest } from '../models/galderma-product-link-update-request';
@Injectable({
    providedIn: 'root',
})
class ItemsService extends __BaseService {
    static readonly GetItemsPath = '/Items';
    static readonly CreateItemPath = '/Items';
    static readonly GetItemPath = '/Items/{itemId}';
    static readonly DeleteItemPath = '/Items/{itemId}';
    static readonly UpdateItemPath = '/Items/{itemId}';
    static readonly GetItemCategoriesPath = '/Items/{itemId}/Categories';
    static readonly InsertUpdateCategoriesPath = '/Items/{itemId}/Categories';
    static readonly UpdateGaldermaProductLinkForItemPath = '/Items/{itemId}/GaldermaProductLink';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets the all items for a tenant.
     * @param params The `ItemsService.GetItemsParams` containing the following parameters:
     *
     * - `showItemsWithoutBillingCodesOnly`: Optional parameter to filter items by flag showItemsWithoutBillingCodesOnly.
     *
     * - `isNotCollected`: Optional parameter to filter items by flag isNotCollected.
     *
     * - `isForSale`: Optional parameter to filter items by flag isForSale.
     *
     * - `isDeleted`: Optional parameter to filter items by flag isDeleted.
     *
     * - `UPC`: Gets or sets the UPC.
     *
     * - `TrackInventoryMode`: Gets or sets the track inventory mode.
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `SearchQuery`: Gets or sets the search query.
     *
     * - `SKU`: Gets or sets the SKU.
     *
     * - `ParentChildType`: Gets or sets the parent child type.
     *
     * - `Name`: Gets or sets the name.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `ItemType`: Gets or sets the Item Type identifier.
     *
     * - `ItemStatusId`: Gets or sets the status identifier.
     *
     * - `ItemIds`: Gets or sets the item ids.
     *
     * - `IncludePreferredProviderName`: Gets or sets the include preferred provider name.
     *
     * - `IcdCodeId`: Gets or sets the ICD Identifier.
     *
     * - `HierarchicalCategoryId`: Gets or sets the hierarchical category id.
     *
     * - `GenderId`: Gets or sets the gender Identifier.
     *
     * - `CptCodeId`: Gets or sets the CPT Identifier.
     *
     * - `Categories`: Gets or sets the categories.
     *
     * - `Brand`: Gets or sets the brand.
     *
     * @return Success
     */
    GetItemsResponse(params: ItemsService.GetItemsParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableItem>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.showItemsWithoutBillingCodesOnly != null)
            __params = __params.set('showItemsWithoutBillingCodesOnly', params.showItemsWithoutBillingCodesOnly.toString());
        if (params.isNotCollected != null) __params = __params.set('isNotCollected', params.isNotCollected.toString());
        if (params.isForSale != null) __params = __params.set('isForSale', params.isForSale.toString());
        if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
        if (params.UPC != null) __params = __params.set('UPC', params.UPC.toString());
        if (params.TrackInventoryMode != null) __params = __params.set('TrackInventoryMode', params.TrackInventoryMode.toString());
        if (params.SortingRequest != null) __params = __params.set('SortingRequest', params.SortingRequest.toString());
        if (params.SortingDirectionRequest != null)
            __params = __params.set('SortingDirectionRequest', params.SortingDirectionRequest.toString());
        if (params.SearchQuery != null) __params = __params.set('SearchQuery', params.SearchQuery.toString());
        if (params.SKU != null) __params = __params.set('SKU', params.SKU.toString());
        if (params.ParentChildType != null) __params = __params.set('ParentChildType', params.ParentChildType.toString());
        if (params.Name != null) __params = __params.set('Name', params.Name.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        if (params.ItemType != null) __params = __params.set('ItemType', params.ItemType.toString());
        if (params.ItemStatusId != null) __params = __params.set('ItemStatusId', params.ItemStatusId.toString());
        if (params.ItemIds != null) __params = __params.set('ItemIds', params.ItemIds.toString());
        if (params.IncludePreferredProviderName != null)
            __params = __params.set('IncludePreferredProviderName', params.IncludePreferredProviderName.toString());
        if (params.IcdCodeId != null) __params = __params.set('IcdCodeId', params.IcdCodeId.toString());
        if (params.HierarchicalCategoryId != null)
            __params = __params.set('HierarchicalCategoryId', params.HierarchicalCategoryId.toString());
        if (params.GenderId != null) __params = __params.set('GenderId', params.GenderId.toString());
        if (params.CptCodeId != null) __params = __params.set('CptCodeId', params.CptCodeId.toString());
        (params.Categories || []).forEach((val) => {
            if (val != null) __params = __params.append('Categories', val.toString());
        });
        if (params.Brand != null) __params = __params.set('Brand', params.Brand.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Items`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableItem>;
            }),
        );
    }
    /**
     * Gets the all items for a tenant.
     * @param params The `ItemsService.GetItemsParams` containing the following parameters:
     *
     * - `showItemsWithoutBillingCodesOnly`: Optional parameter to filter items by flag showItemsWithoutBillingCodesOnly.
     *
     * - `isNotCollected`: Optional parameter to filter items by flag isNotCollected.
     *
     * - `isForSale`: Optional parameter to filter items by flag isForSale.
     *
     * - `isDeleted`: Optional parameter to filter items by flag isDeleted.
     *
     * - `UPC`: Gets or sets the UPC.
     *
     * - `TrackInventoryMode`: Gets or sets the track inventory mode.
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `SearchQuery`: Gets or sets the search query.
     *
     * - `SKU`: Gets or sets the SKU.
     *
     * - `ParentChildType`: Gets or sets the parent child type.
     *
     * - `Name`: Gets or sets the name.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `ItemType`: Gets or sets the Item Type identifier.
     *
     * - `ItemStatusId`: Gets or sets the status identifier.
     *
     * - `ItemIds`: Gets or sets the item ids.
     *
     * - `IncludePreferredProviderName`: Gets or sets the include preferred provider name.
     *
     * - `IcdCodeId`: Gets or sets the ICD Identifier.
     *
     * - `HierarchicalCategoryId`: Gets or sets the hierarchical category id.
     *
     * - `GenderId`: Gets or sets the gender Identifier.
     *
     * - `CptCodeId`: Gets or sets the CPT Identifier.
     *
     * - `Categories`: Gets or sets the categories.
     *
     * - `Brand`: Gets or sets the brand.
     *
     * @return Success
     */
    GetItems(params: ItemsService.GetItemsParams): __Observable<ApiResponseIEnumerableItem> {
        return this.GetItemsResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableItem));
    }

    /**
     * Create a new Item.
     * @param itemCreationRequest The item creation request.
     * @return Success
     */
    CreateItemResponse(itemCreationRequest?: ItemCreationRequest): __Observable<__StrictHttpResponse<ApiResponseItem>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = itemCreationRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/Items`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItem>;
            }),
        );
    }
    /**
     * Create a new Item.
     * @param itemCreationRequest The item creation request.
     * @return Success
     */
    CreateItem(itemCreationRequest?: ItemCreationRequest): __Observable<ApiResponseItem> {
        return this.CreateItemResponse(itemCreationRequest).pipe(__map((_r) => _r.body as ApiResponseItem));
    }

    /**
     * Gets an existing item for a tenant.
     * @param params The `ItemsService.GetItemParams` containing the following parameters:
     *
     * - `itemId`: item identification.
     *
     * - `includeSettings`: The include Settings.
     *
     * - `includePreferredProviderName`: The include Preferred Provider Name.
     *
     * @return Success
     */
    GetItemResponse(params: ItemsService.GetItemParams): __Observable<__StrictHttpResponse<ApiResponseItem>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeSettings != null) __params = __params.set('includeSettings', params.includeSettings.toString());
        if (params.includePreferredProviderName != null)
            __params = __params.set('includePreferredProviderName', params.includePreferredProviderName.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItem>;
            }),
        );
    }
    /**
     * Gets an existing item for a tenant.
     * @param params The `ItemsService.GetItemParams` containing the following parameters:
     *
     * - `itemId`: item identification.
     *
     * - `includeSettings`: The include Settings.
     *
     * - `includePreferredProviderName`: The include Preferred Provider Name.
     *
     * @return Success
     */
    GetItem(params: ItemsService.GetItemParams): __Observable<ApiResponseItem> {
        return this.GetItemResponse(params).pipe(__map((_r) => _r.body as ApiResponseItem));
    }

    /**
     * Delete an item.
     * @param itemId The item id.
     * @return Success
     */
    DeleteItemResponse(itemId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/Items/${encodeURIComponent(String(itemId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Delete an item.
     * @param itemId The item id.
     * @return Success
     */
    DeleteItem(itemId: number): __Observable<ApiResponseOkResponse> {
        return this.DeleteItemResponse(itemId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Update an item.
     * @param params The `ItemsService.UpdateItemParams` containing the following parameters:
     *
     * - `itemId`: The item id.
     *
     * - `itemUpdateRequest`: The item update request.
     *
     * @return Success
     */
    UpdateItemResponse(params: ItemsService.UpdateItemParams): __Observable<__StrictHttpResponse<ApiResponseItem>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.itemUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItem>;
            }),
        );
    }
    /**
     * Update an item.
     * @param params The `ItemsService.UpdateItemParams` containing the following parameters:
     *
     * - `itemId`: The item id.
     *
     * - `itemUpdateRequest`: The item update request.
     *
     * @return Success
     */
    UpdateItem(params: ItemsService.UpdateItemParams): __Observable<ApiResponseItem> {
        return this.UpdateItemResponse(params).pipe(__map((_r) => _r.body as ApiResponseItem));
    }

    /**
     * Get the full list of categories for an item.
     * @param itemId The item id.
     * @return Success
     */
    GetItemCategoriesResponse(itemId: number): __Observable<__StrictHttpResponse<ApiResponseICollectionCategory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Items/${encodeURIComponent(String(itemId))}/Categories`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionCategory>;
            }),
        );
    }
    /**
     * Get the full list of categories for an item.
     * @param itemId The item id.
     * @return Success
     */
    GetItemCategories(itemId: number): __Observable<ApiResponseICollectionCategory> {
        return this.GetItemCategoriesResponse(itemId).pipe(__map((_r) => _r.body as ApiResponseICollectionCategory));
    }

    /**
     * Update the list of categories of an item.
     * @param params The `ItemsService.InsertUpdateCategoriesParams` containing the following parameters:
     *
     * - `itemId`: The item id.
     *
     * - `categoryIds`: The category ids.
     *
     * @return Success
     */
    InsertUpdateCategoriesResponse(
        params: ItemsService.InsertUpdateCategoriesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionCategory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.categoryIds;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/Categories`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionCategory>;
            }),
        );
    }
    /**
     * Update the list of categories of an item.
     * @param params The `ItemsService.InsertUpdateCategoriesParams` containing the following parameters:
     *
     * - `itemId`: The item id.
     *
     * - `categoryIds`: The category ids.
     *
     * @return Success
     */
    InsertUpdateCategories(params: ItemsService.InsertUpdateCategoriesParams): __Observable<ApiResponseICollectionCategory> {
        return this.InsertUpdateCategoriesResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionCategory));
    }

    /**
     * The update galderma product link for item.
     * @param params The `ItemsService.UpdateGaldermaProductLinkForItemParams` containing the following parameters:
     *
     * - `itemId`: The item id.
     *
     * - `updateRequest`: The update Request.
     *
     * @return Success
     */
    UpdateGaldermaProductLinkForItemResponse(
        params: ItemsService.UpdateGaldermaProductLinkForItemParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.updateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/GaldermaProductLink`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * The update galderma product link for item.
     * @param params The `ItemsService.UpdateGaldermaProductLinkForItemParams` containing the following parameters:
     *
     * - `itemId`: The item id.
     *
     * - `updateRequest`: The update Request.
     *
     * @return Success
     */
    UpdateGaldermaProductLinkForItem(params: ItemsService.UpdateGaldermaProductLinkForItemParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateGaldermaProductLinkForItemResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module ItemsService {
    /**
     * Parameters for GetItems
     */
    export interface GetItemsParams {
        /**
         * Optional parameter to filter items by flag showItemsWithoutBillingCodesOnly.
         */
        showItemsWithoutBillingCodesOnly?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter items by flag isNotCollected.
         */
        isNotCollected?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter items by flag isForSale.
         */
        isForSale?: 'False' | 'True' | 'Null';

        /**
         * Optional parameter to filter items by flag isDeleted.
         */
        isDeleted?: 'False' | 'True' | 'Null';

        /**
         * Gets or sets the UPC.
         */
        UPC?: string;

        /**
         * Gets or sets the track inventory mode.
         */
        TrackInventoryMode?:
            | 'NoTracking'
            | 'GlobalLevelOnly'
            | 'PerLocationOnly'
            | 'GlobalAndPerLocation'
            | 'PerBatchOnly'
            | 'GlobalAndPerBatch'
            | 'PerBatchPerLocation'
            | 'GlobalAndPerBatchPerLocation';

        /**
         * Gets or sets the sorting request.
         */
        SortingRequest?: 'Id' | 'Name' | 'ModifiedDate';

        /**
         * Gets or sets the sorting direction request.
         */
        SortingDirectionRequest?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the search query.
         */
        SearchQuery?: string;

        /**
         * Gets or sets the SKU.
         */
        SKU?: string;

        /**
         * Gets or sets the parent child type.
         */
        ParentChildType?: 'Parent' | 'Child' | 'All';

        /**
         * Gets or sets the name.
         */
        Name?: string;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;

        /**
         * Gets or sets the Item Type identifier.
         */
        ItemType?:
            | 'Products'
            | 'Services'
            | 'FeeTypeId'
            | 'MembershipTypeId'
            | 'GiftCardTypeId'
            | 'Adjustment'
            | 'WooCommerceItem'
            | 'CreditCardSurchargeTypeId';

        /**
         * Gets or sets the status identifier.
         */
        ItemStatusId?: number;

        /**
         * Gets or sets the item ids.
         */
        ItemIds?: string;

        /**
         * Gets or sets the include preferred provider name.
         */
        IncludePreferredProviderName?: boolean;

        /**
         * Gets or sets the ICD Identifier.
         */
        IcdCodeId?: number;

        /**
         * Gets or sets the hierarchical category id.
         */
        HierarchicalCategoryId?: number;

        /**
         * Gets or sets the gender Identifier.
         */
        GenderId?: number;

        /**
         * Gets or sets the CPT Identifier.
         */
        CptCodeId?: number;

        /**
         * Gets or sets the categories.
         */
        Categories?: Array<string>;

        /**
         * Gets or sets the brand.
         */
        Brand?: string;
    }

    /**
     * Parameters for GetItem
     */
    export interface GetItemParams {
        /**
         * item identification.
         */
        itemId: number;

        /**
         * The include Settings.
         */
        includeSettings?: boolean;

        /**
         * The include Preferred Provider Name.
         */
        includePreferredProviderName?: boolean;
    }

    /**
     * Parameters for UpdateItem
     */
    export interface UpdateItemParams {
        /**
         * The item id.
         */
        itemId: number;

        /**
         * The item update request.
         */
        itemUpdateRequest?: ItemUpdateRequest;
    }

    /**
     * Parameters for InsertUpdateCategories
     */
    export interface InsertUpdateCategoriesParams {
        /**
         * The item id.
         */
        itemId: number;

        /**
         * The category ids.
         */
        categoryIds?: Array<number>;
    }

    /**
     * Parameters for UpdateGaldermaProductLinkForItem
     */
    export interface UpdateGaldermaProductLinkForItemParams {
        /**
         * The item id.
         */
        itemId: number;

        /**
         * The update Request.
         */
        updateRequest?: GaldermaProductLinkUpdateRequest;
    }
}

export { ItemsService };
