import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperSlideDirective } from './swiper-slide.directive';
import { SwiperComponent } from './swiper.component';

@NgModule({
    imports: [CommonModule],
    declarations: [SwiperComponent, SwiperSlideDirective],
    exports: [SwiperComponent, SwiperSlideDirective],
})
export class SwiperModule {}
