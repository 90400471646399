import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import pick from 'lodash-es/pick';
import omit from 'lodash-es/omit';
import { SwiperDialogComponent } from './swiper-dialog.component';
import { SwiperDialogConfig, SwiperDialogData } from './swiper-dialog.model';
import { SwiperDialogRef } from './swiper-dialog.reference';

const DIALOG_CONFIG_DEFAULT: Partial<SwiperDialogData> = {
    close: { label: 'Got it' },
};

@Injectable({ providedIn: 'root' })
export class SwiperDialogService {
    constructor(private dialog: MatDialog) {}

    public open(config: SwiperDialogConfig): SwiperDialogRef {
        const dialogRef = this.dialog.open<SwiperDialogComponent, SwiperDialogData, unknown>(SwiperDialogComponent, {
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            width: '1000px',
            ...omit(config, ['title', 'content', 'close']),
            data: {
                ...DIALOG_CONFIG_DEFAULT,
                ...pick(config, ['title', 'content', 'close', 'checkbox']),
            },
        });

        return this.decorateDialogRef(dialogRef);
    }

    private decorateDialogRef(dialogRef: MatDialogRef<any, unknown>): SwiperDialogRef {
        (dialogRef as SwiperDialogRef).on = ({ close }) => {
            if (close) {
                dialogRef.afterClosed().subscribe(() => close());
            }
        };

        return dialogRef as SwiperDialogRef;
    }
}
