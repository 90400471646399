import { BehaviorSubject, debounceTime, delay, distinctUntilChanged, Observable } from 'rxjs';

export class LoaderHelperInstance {
    /**
     * A stream that determines if some process is currently running that requires a loading state
     */
    public loading$: Observable<boolean>;
    private _loading$ = new BehaviorSubject(false);
    private processSet = new Set<Symbol>();

    constructor() {
        this.loading$ = this._loading$.asObservable().pipe(distinctUntilChanged(), debounceTime(0), delay(0));
    }

    /**
     * Starts a unique process that requires some loading state
     * @returns { Function } The function that stops a running loading process
     */
    public startLoading(): () => void {
        const PROCESS_SYMBOL = Symbol('__loading_process');

        this.processSet.add(PROCESS_SYMBOL);
        this._loading$.next(true);

        return () => {
            if (this.processSet.has(PROCESS_SYMBOL)) {
                this.processSet.delete(PROCESS_SYMBOL);

                const hasLoadingProcesses = this.processSet.size !== 0;

                this._loading$.next(hasLoadingProcesses);
            }
        };
    }

    /** Ends all running loading processes */
    public finishAll(): void {
        this.processSet.clear();
        this._loading$.next(false);
    }
}

// TODO: (a.vakhrushin) Add the ability to end the loading process on ngOnDestroy
export abstract class LoaderHelper {
    private static _rootLoader: LoaderHelperInstance;

    /**
     * A stream that determines if some process is currently running that requires a loading state
     */
    public static get loading$(): Observable<boolean> {
        return LoaderHelper._rootLoader.loading$;
    }

    /**
     * Initialize a root loader that can be accessed everywhere
     */
    public static init(): void {
        LoaderHelper._rootLoader = LoaderHelper.createInstance();
    }

    /**
     * Creates an instance of a loader helper that helps manage the loading state for a specific location.
     * @returns { LoaderHelperInstance } Loader instance that can manage the loading state locally
     */
    public static createInstance(): LoaderHelperInstance {
        return new LoaderHelperInstance();
    }

    /**
     * Starts a unique process that requires the application to be in a loading state
     * @returns { Function } The function that stops a running loading process
     */
    public static startLoading(): () => void {
        return LoaderHelper._rootLoader.startLoading();
    }

    /** Ends all running loading processes */
    public static finishAll(): void {
        LoaderHelper._rootLoader.finishAll();
    }
}
