import { EventEmitter, Injectable } from '@angular/core';
import { ApiResponseOkResponse, FilesService } from '@symplast/generated-clients/multimedia';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFileUploadDetial, IFileUploadOptions, TagSelectOptions, IFileDefinition } from '@symplast/models/media';
import { UtilityService } from '@symplast/utils';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    currentUploadDetails$: Observable<IFileUploadDetial[]>;

    fileUploaded = new EventEmitter();

    private uploadDetails$ = new BehaviorSubject<IFileUploadDetial[]>([]);

    constructor(private filesService: FilesService, private utilityService: UtilityService) {
        const files: IFileUploadDetial[] = [];

        this.currentUploadDetails$ = this.uploadDetails$.asObservable();
        this.uploadDetails$.next(files);
    }

    addFile(file: IFileDefinition, options: IFileUploadOptions): void {
        const files = this.uploadDetails$.value;
        const fileUploadDetial = {} as IFileUploadDetial;

        fileUploadDetial.id = this.utilityService.newGuid();
        fileUploadDetial.file = file;
        fileUploadDetial.isUploadCompleted = false;
        const tags =
            options.tagSettings === TagSelectOptions.MathchDestinationTags
                ? options.tags
                : options.customTags.filter((t) => t.length > 0 && t.trim() !== '');

        this.filesService
            .CreateFile({
                file: file.file,
                PatientId: options.patientId,
                SharedWithPatient: false,
                FileNameOverride: file.overridedName ?? file.file.name,
                OriginalCreatedDateTimeUtc: new Date(file.file.lastModified).toUTCString(),
                UploadToAlbum: options.albumName,
                Tags: tags,
            } as FilesService.CreateFileParams)
            .subscribe(
                (r) => {
                    this.onUploadCompleted(fileUploadDetial.id, !!r.errorMessage);
                    this.fileUploaded.emit(r.result);
                },
                (r) => {
                    this.onUploadCompleted(fileUploadDetial.id, true);
                },
            );

        files.splice(0, 0, fileUploadDetial);

        this.uploadDetails$.next(files);
    }

    onUploadCompleted(id: string, isError: boolean): void {
        const files = this.uploadDetails$.value;
        const fileUploadDetial = files.find((f) => f.id === id);

        if (!fileUploadDetial) {
            return;
        }

        fileUploadDetial.isUploadCompleted = true;
        fileUploadDetial.isError = isError;

        this.uploadDetails$.next(files);
    }

    addFiles(files: IFileDefinition[], options: IFileUploadOptions): void {
        files.map((f) => this.addFile(f, options));
    }

    cancelUpload(): void {
        this.uploadDetails$.next([]);
    }

    deleteFile(fileId: string): Observable<ApiResponseOkResponse> {
        return this.filesService.DeleteFile(fileId);
    }
}
