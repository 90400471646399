import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeaturesSettings, TenantsService } from '@symplast/generated-clients/tenants';
import { ITenant, Tenant } from '@symplast/models/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadIntegrationAppSettings, SetTenant } from './../stores/app-settings';
import { CurrentUserService } from 'apps/webportal/src/app/core/services/current-user.service';
import isNil from 'lodash-es/isNil';

@Injectable({
    providedIn: 'root',
})
export class PrivateSettingsService {
    public insuranceEnabled$: Observable<boolean>;
    public insuranceContract$: Observable<string | null>;
    public loading = false;
    public weightLossManagementEnabled!: boolean;
    public doseSpotUseV2!: boolean;

    private _insuranceEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _insuranceContract$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    private _currentTenant$: BehaviorSubject<Tenant | null> = new BehaviorSubject<Tenant | null>(null);

    private _scheduleTemplatesEnabled = false;
    private _calendarBlockStatus: 'Inactive' | 'ActivationRequested' | 'Active' = 'Inactive';
    private _calendarBlocksFeatureEnabled = false;
    private _isCalendarBlocksRollOutModeAutomated = false;
    private _financialsType = '';
    private _surchargesEnabled = false;
    private _kiosksEnabled = false;
    private _wieghtLossManagementUrl?: string;
    private _isRecurringPaymentsEnabled = false;
    private _multiFactorAuthenticationFeatureAvailable!: boolean;
    private featureFlags: Partial<FeaturesSettings> = {};

    constructor(private tenantsService: TenantsService, private store: Store, private currentUserService: CurrentUserService) {
        this.currentUserService.user$.subscribe((user) => {
            if (user) {
                this.refresh();
            }
        });
        this.insuranceEnabled$ = this._insuranceEnabled$.asObservable();
        this.insuranceContract$ = this._insuranceContract$.asObservable();
    }

    get wieghtLossManagementUrl(): string | undefined {
        return this._wieghtLossManagementUrl;
    }

    set wieghtLossManagementUrl(value: string | undefined) {
        try {
            if (value && typeof value === 'string') {
                this._wieghtLossManagementUrl = new URL(value).origin;
            }
        } catch {
            this._wieghtLossManagementUrl = undefined;
        }
    }

    public get multiFactorAuthenticationFeatureAvailable(): boolean {
        return this._multiFactorAuthenticationFeatureAvailable;
    }

    get insuranceEnabled(): boolean {
        return this._insuranceEnabled$.value && this.isFinancialsEnabled;
    }
    set insuranceEnabled(enabled: boolean) {
        this._insuranceEnabled$.next(enabled && this.isFinancialsEnabled);
    }

    get insuranceContract(): string | null {
        return this._insuranceContract$.value;
    }
    set insuranceContract(contract: string | null) {
        this._insuranceContract$.next(contract);
    }

    get isFinancialsEnabled(): boolean {
        return this._financialsType !== 'LegacyFinancials';
    }

    set financialsType(type: string) {
        this._financialsType = type;
    }

    get financialsType(): string {
        return this._financialsType;
    }

    get CurrentTenant$(): Observable<Tenant | null> {
        return this._currentTenant$.asObservable();
    }

    get CurrentTenantValue(): Tenant {
        return { ...this._currentTenant$.value } as Tenant;
    }

    get scheduleTemplatesEnabled(): boolean {
        return this._scheduleTemplatesEnabled;
    }

    set scheduleTemplatesEnabled(value) {
        this._scheduleTemplatesEnabled = value;
    }

    get calendarBlockStatus(): 'Inactive' | 'ActivationRequested' | 'Active' {
        return this._calendarBlockStatus;
    }

    set calendarBlockStatus(value) {
        this._calendarBlockStatus = value;
    }

    get IsCalendarBlocksRollOutModeAutomated(): boolean {
        return this._isCalendarBlocksRollOutModeAutomated;
    }

    set IsCalendarBlocksRollOutModeAutomated(value) {
        this._isCalendarBlocksRollOutModeAutomated = value;
    }

    get calendarBlocksFeatureEnabled(): boolean {
        return this._calendarBlocksFeatureEnabled;
    }

    set calendarBlocksFeatureEnabled(value) {
        this._calendarBlocksFeatureEnabled = value;
    }

    get calendarBlocksEnabled(): boolean {
        return this._calendarBlockStatus === 'Active';
    }

    get surchargesEnabled(): boolean {
        return this._surchargesEnabled;
    }

    set surchargesEnabled(value: boolean) {
        this._surchargesEnabled = value;
    }

    public get kiosksEnabled(): boolean {
        return this._kiosksEnabled;
    }

    public set kiosksEnabled(value: boolean) {
        this._kiosksEnabled = value;
    }

    public get isRecurringPaymentsEnabled(): boolean {
        return this._isRecurringPaymentsEnabled;
    }

    public set isRecurringPaymentsEnabled(value: boolean) {
        this._isRecurringPaymentsEnabled = value;
    }

    public setFeatureFlags(flags?: Partial<FeaturesSettings>): void {
        this.featureFlags = flags || {};
    }

    public hasFeatureFlag(feature: keyof FeaturesSettings): boolean {
        return this.featureFlags[feature] === true;
    }

    public setMultiFactorAuthenticationFeatureState(mfaFeatureEnabled: boolean | undefined): void {
        this._multiFactorAuthenticationFeatureAvailable = !isNil(mfaFeatureEnabled);
    }

    public setCurrentTenant(tenant: ITenant): void {
        this._currentTenant$.next(new Tenant(tenant.name, tenant.logoUrl, tenant.defaultIntakeProvider));
    }

    public updateLogo(logoUrl: string): void {
        const tenant = this._currentTenant$.value as Tenant;

        tenant.Logo = logoUrl;
        this._currentTenant$.next(tenant);
    }

    public refresh(): void {
        this.loading = true;
        // FIXME: (a.vakhrushin) USER STORY 100663 We call PrivateSettings API twice, once here and once in ConfigurationApplicationService
        this.tenantsService.GetPrivate({}).subscribe((data) => {
            if (data != null && data.result != null && data.result[0] != null) {
                const tenant = data.result[0];

                this.setMultiFactorAuthenticationFeatureState(
                    tenant?.infrastructure?.securityConfiguration?.multiFactorAuthenticationEnabled,
                );

                this.insuranceEnabled = tenant?.insurance?.enabled ?? false;
                this.insuranceContract = tenant?.insurance?.contractStatus || null;

                this.scheduleTemplatesEnabled = tenant?.apps?.practice?.isScheduleTemplatesFeatureEnabled ?? false;
                this.surchargesEnabled = tenant.apps?.practice?.isSurchargesFeatureEnabled ?? false;
                this.kiosksEnabled = tenant.apps?.practice?.isKioskFeatureEnabled ?? false;
                this._calendarBlocksFeatureEnabled = <boolean>tenant.calendarBlocks?.isCalendarBlocksFeatureEnabled;
                this.calendarBlockStatus = tenant.calendarBlocks?.isCalendarBlocksFeatureEnabled
                    ? tenant.calendarBlocks?.calendarBlockStatus ?? 'Inactive'
                    : 'Inactive';
                this.financialsType = tenant?.financials?.type ?? 'FinancialsWihtMigratedData';
                this._isCalendarBlocksRollOutModeAutomated = <boolean>tenant.calendarBlocks?.isRollOutModeAutomated;
                this._currentTenant$.next(
                    new Tenant(
                        tenant?.name ?? '',
                        `${tenant.logoUrl}?t=${new Date().getMilliseconds()}`,
                        tenant?.intakes?.defaultIntakeProvider ?? 'None',
                    ),
                );

                this._isRecurringPaymentsEnabled = tenant?.apps?.practice?.isRecurringPaymentsFeatureEnabled ?? false;
                this.setFeatureFlags(tenant.features);

                this.refreshIntegrations();
                this.store.dispatch(new SetTenant(tenant.tenantId, tenant?.name ?? ''));
            }
            this.loading = false;
        });
    }

    public refreshIntegrations(): void {
        this.store.dispatch(new LoadIntegrationAppSettings(this.isFinancialsEnabled));
    }
}
