/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { AuditRecordCreateRequest } from '../models/audit-record-create-request';
import { ApiResponseIListAuditEntryResponse } from '../models/api-response-ilist-audit-entry-response';
import { ApiResponseIEnumerableAppointment } from '../models/api-response-ienumerable-appointment';
import { ApiResponseAppointment } from '../models/api-response-appointment';
import { AppointmentCreateRequest } from '../models/appointment-create-request';
import { ApiResponseIEnumerableAppointmentClaim } from '../models/api-response-ienumerable-appointment-claim';
import { ApiResponseAppointmentClaimCount } from '../models/api-response-appointment-claim-count';
import { ApiResponseIEnumerableAnonymizedAppointmentCalendar } from '../models/api-response-ienumerable-anonymized-appointment-calendar';
import { AppointmentUpdateRequest } from '../models/appointment-update-request';
import { ApiResponseIEnumerableAppointmentWithEhrNote } from '../models/api-response-ienumerable-appointment-with-ehr-note';
import { ApiResponseIEnumerableAppointmentWithNotes } from '../models/api-response-ienumerable-appointment-with-notes';
import { ApiResponseAppointmentCalendarFromAnonymousUserResponse } from '../models/api-response-appointment-calendar-from-anonymous-user-response';
import { ApiResponseIReadOnlyCollectionCpt } from '../models/api-response-iread-only-collection-cpt';
import { ApiResponseIReadOnlyCollectionAppointmentCptCode } from '../models/api-response-iread-only-collection-appointment-cpt-code';
import { CptCodeBillRequestList } from '../models/cpt-code-bill-request-list';
import { ApiResponseIReadOnlyCollectionIcd } from '../models/api-response-iread-only-collection-icd';
import { ApiResponseIReadOnlyCollectionAppointmentIcdCode } from '../models/api-response-iread-only-collection-appointment-icd-code';
import { ApiResponseIReadOnlyCollectionEHRProblem } from '../models/api-response-iread-only-collection-ehrproblem';
import { ApiResponseIReadOnlyCollectionLegacyAppointmentType } from '../models/api-response-iread-only-collection-legacy-appointment-type';
import { ApiResponseIReadOnlyCollectionAppointmentPurposeResponse } from '../models/api-response-iread-only-collection-appointment-purpose-response';
import { ApiResponseIReadOnlyCollectionAppointmentStatus } from '../models/api-response-iread-only-collection-appointment-status';
import { ApiResponseAppointmentStatus } from '../models/api-response-appointment-status';
import { AppointmentStatusUpdateRequest } from '../models/appointment-status-update-request';
import { AppointmentStatusCreateRequest } from '../models/appointment-status-create-request';
import { ApiResponseIReadOnlyCollectionEHRSubProblem } from '../models/api-response-iread-only-collection-ehrsub-problem';
import { ApiResponseIReadOnlyCollectionAppointmentPaymentType } from '../models/api-response-iread-only-collection-appointment-payment-type';
import { StatusUpdateRequest } from '../models/status-update-request';
import { OutcomeStatusUpdateRequest } from '../models/outcome-status-update-request';
import { AppointmentClaimIdUpdateRequest } from '../models/appointment-claim-id-update-request';
@Injectable({
    providedIn: 'root',
})
class AppointmentsService extends __BaseService {
    static readonly AppointmentsPostAuditRecordsPath = '/api/Appointments/AuditRecords';
    static readonly AppointmentsGetAuditRecordsPath = '/api/Appointments/{appointmentId}/AuditRecords';
    static readonly AppointmentsGetAppointmentsByPeriodPath = '/api/Appointments';
    static readonly AppointmentsCreateAppointmentPath = '/api/Appointments';
    static readonly AppointmentsGetAppointmentClaimsPath = '/api/Appointments/Claims';
    static readonly AppointmentsGetAppointmentClaimsWithBillableListPath = '/api/Appointments/Claims';
    static readonly AppointmentsGetAppointmentClaimsCountPath = '/api/Appointments/Claims/Count';
    static readonly AppointmentsGetAppointmentClaimsWithBillableListCountPath = '/api/Appointments/Claims/Count';
    static readonly AppointmentsGetAnonymizedAppointmentByPeriodPath = '/api/AnonymizedAppointments';
    static readonly AppointmentsGetByIdPath = '/api/Appointments/{id}';
    static readonly AppointmentsUpdateAppointmentPath = '/api/Appointments/{id}';
    static readonly AppointmentsGetAppointmentsWithLatestNotePath = '/api/Appointments/WithLatestNote';
    static readonly AppointmentsGetAppointmentsWithNotesPath = '/api/Appointments/WithNotes';
    static readonly AppointmentsGetByIdFromAnonymousUserPath = '/api/Appointments/{appointmentId}/patients/{patientId}/Tenants/{tenantId}';
    static readonly AppointmentsGetCptCodesPath = '/api/Appointments/{id}/CptCodes';
    static readonly AppointmentsGetAppointmentCptCodesPath = '/api/Appointments/{appointmentId}/AppointmentCptCodes';
    static readonly AppointmentsUpdateAppointmentCptCodesPath = '/api/Appointments/AppointmentCptCodes';
    static readonly AppointmentsGetIcdCodesPath = '/api/Appointments/{id}/IcdCodes';
    static readonly AppointmentsGetAppointmentIcdCodesPath = '/api/Appointments/{appointmentId}/AppointmentIcdCodes';
    static readonly AppointmentsGetProblemsPath = '/api/Appointments/Problems';
    static readonly AppointmentsGetTypesPath = '/api/Appointments/Types';
    static readonly AppointmentsGetPurposesByTypePath = '/api/Appointments/Types/{typeId}/Purposes';
    static readonly AppointmentsGetStatusesPath = '/api/Appointments/Statuses';
    static readonly AppointmentsUpdateAppointmentStatusPath = '/api/Appointments/Status';
    static readonly AppointmentsCreateAppointmentStatusPath = '/api/Appointments/Status';
    static readonly AppointmentsDeleteAppointmentStatusPath = '/api/Appointments/Status';
    static readonly AppointmentsGetSubProblemsByProblemPath = '/api/Appointments/Problems/{problemId}/SubProblems';
    static readonly AppointmentsGetPaymentTypesPath = '/api/Appointments/PaymentTypes';
    static readonly AppointmentsUpdateStatusPath = '/api/Appointments/{id}/Status';
    static readonly AppointmentsUpdateOutcomeStatusPath = '/api/Appointments/{id}/OutcomeStatus';
    static readonly AppointmentsUpdateAppointmentClaimIdPath = '/api/Appointments/{id}/Claim';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param request The request.
     * @return OK
     */
    AppointmentsPostAuditRecordsResponse(request: AuditRecordCreateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Appointments/AuditRecords`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param request The request.
     * @return OK
     */
    AppointmentsPostAuditRecords(request: AuditRecordCreateRequest): __Observable<ApiResponseOkResponse> {
        return this.AppointmentsPostAuditRecordsResponse(request).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param appointmentId ID of the appointment for which to get audit records
     * @return OK
     */
    AppointmentsGetAuditRecordsResponse(appointmentId: number): __Observable<__StrictHttpResponse<ApiResponseIListAuditEntryResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${appointmentId}/AuditRecords`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIListAuditEntryResponse>;
            }),
        );
    }
    /**
     * @param appointmentId ID of the appointment for which to get audit records
     * @return OK
     */
    AppointmentsGetAuditRecords(appointmentId: number): __Observable<ApiResponseIListAuditEntryResponse> {
        return this.AppointmentsGetAuditRecordsResponse(appointmentId).pipe(__map((_r) => _r.body as ApiResponseIListAuditEntryResponse));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentsByPeriodParams` containing the following parameters:
     *
     * - `typeIds`:
     *
     * - `showVideoVisitOnly`: Filters appointment by isVideoVisit flag
     *
     * - `showCancelled`: Filters appointments by fields: IsDeleted and IsCancelled
     *
     * - `providerId`: Filters appointments by providerId
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC. When provided returns only appointments created or modified after specified time.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `isPhysicianAppointment`: Specifies if get appointment for physician (without patient attached)
     *
     * - `includePatientInformation`: The include Patient Information.
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentsByPeriodResponse(
        params: AppointmentsService.AppointmentsGetAppointmentsByPeriodParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (params.typeIds || []).forEach((val) => {
            if (val != null) __params = __params.append('typeIds', val.toString());
        });
        if (params.showVideoVisitOnly != null) __params = __params.set('showVideoVisitOnly', params.showVideoVisitOnly.toString());
        if (params.showCancelled != null) __params = __params.set('showCancelled', params.showCancelled.toString());
        (params.providerId || []).forEach((val) => {
            if (val != null) __params = __params.append('providerId', val.toString());
        });
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
        if (params.modifiedAfterTimeInUtc != null)
            __params = __params.set('modifiedAfterTimeInUtc', params.modifiedAfterTimeInUtc.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.isPhysicianAppointment != null)
            __params = __params.set('isPhysicianAppointment', params.isPhysicianAppointment.toString());
        if (params.includePatientInformation != null)
            __params = __params.set('includePatientInformation', params.includePatientInformation.toString());
        if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointment>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentsByPeriodParams` containing the following parameters:
     *
     * - `typeIds`:
     *
     * - `showVideoVisitOnly`: Filters appointment by isVideoVisit flag
     *
     * - `showCancelled`: Filters appointments by fields: IsDeleted and IsCancelled
     *
     * - `providerId`: Filters appointments by providerId
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC. When provided returns only appointments created or modified after specified time.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `isPhysicianAppointment`: Specifies if get appointment for physician (without patient attached)
     *
     * - `includePatientInformation`: The include Patient Information.
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentsByPeriod(
        params: AppointmentsService.AppointmentsGetAppointmentsByPeriodParams,
    ): __Observable<ApiResponseIEnumerableAppointment> {
        return this.AppointmentsGetAppointmentsByPeriodResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableAppointment));
    }

    /**
     * @param appointmentRequest The appointment.
     * @return Appointment Created
     */
    AppointmentsCreateAppointmentResponse(
        appointmentRequest: AppointmentCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = appointmentRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Appointments`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointment>;
            }),
        );
    }
    /**
     * @param appointmentRequest The appointment.
     * @return Appointment Created
     */
    AppointmentsCreateAppointment(appointmentRequest: AppointmentCreateRequest): __Observable<ApiResponseAppointment> {
        return this.AppointmentsCreateAppointmentResponse(appointmentRequest).pipe(__map((_r) => _r.body as ApiResponseAppointment));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `order`: The order direction.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaimsResponse(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointmentClaim>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
        if (params.order != null) __params = __params.set('order', params.order.toString());
        if (params.onlyWithoutClaims != null) __params = __params.set('onlyWithoutClaims', params.onlyWithoutClaims.toString());
        if (params.onlyWithEhrNote != null) __params = __params.set('onlyWithEhrNote', params.onlyWithEhrNote.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
        if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/Claims`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointmentClaim>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `order`: The order direction.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaims(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsParams,
    ): __Observable<ApiResponseIEnumerableAppointmentClaim> {
        return this.AppointmentsGetAppointmentClaimsResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableAppointmentClaim));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListParams` containing the following parameters:
     *
     * - `unbillableAppointmentIds`: The list of not-billable appointments.
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `order`: The order direction.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `isBillable`: Show billable or not.
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaimsWithBillableListResponse(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointmentClaim>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.unbillableAppointmentIds;
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
        if (params.order != null) __params = __params.set('order', params.order.toString());
        if (params.onlyWithoutClaims != null) __params = __params.set('onlyWithoutClaims', params.onlyWithoutClaims.toString());
        if (params.onlyWithEhrNote != null) __params = __params.set('onlyWithEhrNote', params.onlyWithEhrNote.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.isBillable != null) __params = __params.set('isBillable', params.isBillable.toString());
        if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
        if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Appointments/Claims`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointmentClaim>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListParams` containing the following parameters:
     *
     * - `unbillableAppointmentIds`: The list of not-billable appointments.
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `order`: The order direction.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `isBillable`: Show billable or not.
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaimsWithBillableList(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListParams,
    ): __Observable<ApiResponseIEnumerableAppointmentClaim> {
        return this.AppointmentsGetAppointmentClaimsWithBillableListResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerableAppointmentClaim),
        );
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsCountParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaimsCountResponse(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsCountParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointmentClaimCount>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.onlyWithoutClaims != null) __params = __params.set('onlyWithoutClaims', params.onlyWithoutClaims.toString());
        if (params.onlyWithEhrNote != null) __params = __params.set('onlyWithEhrNote', params.onlyWithEhrNote.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
        if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/Claims/Count`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointmentClaimCount>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsCountParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaimsCount(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsCountParams,
    ): __Observable<ApiResponseAppointmentClaimCount> {
        return this.AppointmentsGetAppointmentClaimsCountResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointmentClaimCount));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListCountParams` containing the following parameters:
     *
     * - `unbillableAppointmentIds`: The list of not-billable appointments.
     *
     * - `patientId`: The patient identifier.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `isBillable`: Show billable or not.
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaimsWithBillableListCountResponse(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListCountParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointmentClaimCount>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.unbillableAppointmentIds;
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.onlyWithoutClaims != null) __params = __params.set('onlyWithoutClaims', params.onlyWithoutClaims.toString());
        if (params.onlyWithEhrNote != null) __params = __params.set('onlyWithEhrNote', params.onlyWithEhrNote.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.isBillable != null) __params = __params.set('isBillable', params.isBillable.toString());
        if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
        if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Appointments/Claims/Count`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointmentClaimCount>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListCountParams` containing the following parameters:
     *
     * - `unbillableAppointmentIds`: The list of not-billable appointments.
     *
     * - `patientId`: The patient identifier.
     *
     * - `onlyWithoutClaims`: Show only without claims
     *
     * - `onlyWithEhrNote`: Show only with ehr note.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * - `isBillable`: Show billable or not.
     *
     * - `insuranceId`: Filters appointments by insurance
     *
     * - `dateTo`: Filters appointments to provided DateTime
     *
     * - `dateFrom`: Filters appointments from provided DateTime
     *
     * @return Ok
     */
    AppointmentsGetAppointmentClaimsWithBillableListCount(
        params: AppointmentsService.AppointmentsGetAppointmentClaimsWithBillableListCountParams,
    ): __Observable<ApiResponseAppointmentClaimCount> {
        return this.AppointmentsGetAppointmentClaimsWithBillableListCountResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseAppointmentClaimCount),
        );
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAnonymizedAppointmentByPeriodParams` containing the following parameters:
     *
     * - `showCancelled`: Filters appointments by fields: IsDeleted and IsCancelled
     *
     * - `providerId`: Filters appointments by providerId
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC. When provided returns only appointments created or modified after specified time.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * @return Ok
     */
    AppointmentsGetAnonymizedAppointmentByPeriodResponse(
        params: AppointmentsService.AppointmentsGetAnonymizedAppointmentByPeriodParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAnonymizedAppointmentCalendar>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.showCancelled != null) __params = __params.set('showCancelled', params.showCancelled.toString());
        if (params.providerId != null) __params = __params.set('providerId', params.providerId.toString());
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
        if (params.modifiedAfterTimeInUtc != null)
            __params = __params.set('modifiedAfterTimeInUtc', params.modifiedAfterTimeInUtc.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AnonymizedAppointments`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAnonymizedAppointmentCalendar>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAnonymizedAppointmentByPeriodParams` containing the following parameters:
     *
     * - `showCancelled`: Filters appointments by fields: IsDeleted and IsCancelled
     *
     * - `providerId`: Filters appointments by providerId
     *
     * - `patientId`: The patient identifier.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC. When provided returns only appointments created or modified after specified time.
     *
     * - `locationId`: Filters appointments by BranchLocationID
     *
     * @return Ok
     */
    AppointmentsGetAnonymizedAppointmentByPeriod(
        params: AppointmentsService.AppointmentsGetAnonymizedAppointmentByPeriodParams,
    ): __Observable<ApiResponseIEnumerableAnonymizedAppointmentCalendar> {
        return this.AppointmentsGetAnonymizedAppointmentByPeriodResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerableAnonymizedAppointmentCalendar),
        );
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetByIdParams` containing the following parameters:
     *
     * - `id`: ID of the calendar appointment.
     *
     * - `isPhysicianAppointment`: Specifies if get appointment for physician (without patient attached)
     *
     * - `includeCombinedConsentForms`: The include Combined Consent Forms.
     *
     * @return Ok
     */
    AppointmentsGetByIdResponse(
        params: AppointmentsService.AppointmentsGetByIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.isPhysicianAppointment != null)
            __params = __params.set('isPhysicianAppointment', params.isPhysicianAppointment.toString());
        if (params.includeCombinedConsentForms != null)
            __params = __params.set('includeCombinedConsentForms', params.includeCombinedConsentForms.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointment>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetByIdParams` containing the following parameters:
     *
     * - `id`: ID of the calendar appointment.
     *
     * - `isPhysicianAppointment`: Specifies if get appointment for physician (without patient attached)
     *
     * - `includeCombinedConsentForms`: The include Combined Consent Forms.
     *
     * @return Ok
     */
    AppointmentsGetById(params: AppointmentsService.AppointmentsGetByIdParams): __Observable<ApiResponseAppointment> {
        return this.AppointmentsGetByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointment));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsUpdateAppointmentParams` containing the following parameters:
     *
     * - `id`: The identifier of appointment
     *
     * - `appointmentRequest`: The appointment.
     *
     * @return Appointment updated
     */
    AppointmentsUpdateAppointmentResponse(
        params: AppointmentsService.AppointmentsUpdateAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.appointmentRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Appointments/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsUpdateAppointmentParams` containing the following parameters:
     *
     * - `id`: The identifier of appointment
     *
     * - `appointmentRequest`: The appointment.
     *
     * @return Appointment updated
     */
    AppointmentsUpdateAppointment(params: AppointmentsService.AppointmentsUpdateAppointmentParams): __Observable<ApiResponseOkResponse> {
        return this.AppointmentsUpdateAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentsWithLatestNoteParams` containing the following parameters:
     *
     * - `take`: The take.
     *
     * - `skip`: The skip.
     *
     * - `showCancelled`: The show Cancelled.
     *
     * - `providerId`: The provider Id.
     *
     * - `patientId`: The patient id.
     *
     * @return OK
     */
    AppointmentsGetAppointmentsWithLatestNoteResponse(
        params: AppointmentsService.AppointmentsGetAppointmentsWithLatestNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointmentWithEhrNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.take != null) __params = __params.set('take', params.take.toString());
        if (params.skip != null) __params = __params.set('skip', params.skip.toString());
        if (params.showCancelled != null) __params = __params.set('showCancelled', params.showCancelled.toString());
        if (params.providerId != null) __params = __params.set('providerId', params.providerId.toString());
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/WithLatestNote`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointmentWithEhrNote>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentsWithLatestNoteParams` containing the following parameters:
     *
     * - `take`: The take.
     *
     * - `skip`: The skip.
     *
     * - `showCancelled`: The show Cancelled.
     *
     * - `providerId`: The provider Id.
     *
     * - `patientId`: The patient id.
     *
     * @return OK
     */
    AppointmentsGetAppointmentsWithLatestNote(
        params: AppointmentsService.AppointmentsGetAppointmentsWithLatestNoteParams,
    ): __Observable<ApiResponseIEnumerableAppointmentWithEhrNote> {
        return this.AppointmentsGetAppointmentsWithLatestNoteResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerableAppointmentWithEhrNote),
        );
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentsWithNotesParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `patientId`: The patient identifier.
     *
     * - `take`: The take.
     *
     * - `skip`: The skip.
     *
     * - `searchStr`: The search Str.
     *
     * - `noteIsSharedWithPatient`: The note is shared with patient.
     *
     * - `billingProfile`: The billing Profile.
     *
     * @return OK
     */
    AppointmentsGetAppointmentsWithNotesResponse(
        params: AppointmentsService.AppointmentsGetAppointmentsWithNotesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointmentWithNotes>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.take != null) __params = __params.set('take', params.take.toString());
        if (params.skip != null) __params = __params.set('skip', params.skip.toString());
        if (params.searchStr != null) __params = __params.set('searchStr', params.searchStr.toString());
        if (params.noteIsSharedWithPatient != null)
            __params = __params.set('noteIsSharedWithPatient', params.noteIsSharedWithPatient.toString());
        if (params.billingProfile != null) __params = __params.set('billingProfile', params.billingProfile.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/WithNotes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointmentWithNotes>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentsWithNotesParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `patientId`: The patient identifier.
     *
     * - `take`: The take.
     *
     * - `skip`: The skip.
     *
     * - `searchStr`: The search Str.
     *
     * - `noteIsSharedWithPatient`: The note is shared with patient.
     *
     * - `billingProfile`: The billing Profile.
     *
     * @return OK
     */
    AppointmentsGetAppointmentsWithNotes(
        params: AppointmentsService.AppointmentsGetAppointmentsWithNotesParams,
    ): __Observable<ApiResponseIEnumerableAppointmentWithNotes> {
        return this.AppointmentsGetAppointmentsWithNotesResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerableAppointmentWithNotes),
        );
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetByIdFromAnonymousUserParams` containing the following parameters:
     *
     * - `tenantId`: ID of the tenant
     *
     * - `patientId`: ID of the patient
     *
     * - `appointmentId`: ID of the calendar appointment.
     *
     * @return OK
     */
    AppointmentsGetByIdFromAnonymousUserResponse(
        params: AppointmentsService.AppointmentsGetByIdFromAnonymousUserParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointmentCalendarFromAnonymousUserResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/patients/${params.patientId}/Tenants/${params.tenantId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointmentCalendarFromAnonymousUserResponse>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetByIdFromAnonymousUserParams` containing the following parameters:
     *
     * - `tenantId`: ID of the tenant
     *
     * - `patientId`: ID of the patient
     *
     * - `appointmentId`: ID of the calendar appointment.
     *
     * @return OK
     */
    AppointmentsGetByIdFromAnonymousUser(
        params: AppointmentsService.AppointmentsGetByIdFromAnonymousUserParams,
    ): __Observable<ApiResponseAppointmentCalendarFromAnonymousUserResponse> {
        return this.AppointmentsGetByIdFromAnonymousUserResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseAppointmentCalendarFromAnonymousUserResponse),
        );
    }

    /**
     * @param id ID of the calendar appointment.
     * @return OK
     */
    AppointmentsGetCptCodesResponse(id: number): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionCpt>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${id}/CptCodes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionCpt>;
            }),
        );
    }
    /**
     * @param id ID of the calendar appointment.
     * @return OK
     */
    AppointmentsGetCptCodes(id: number): __Observable<ApiResponseIReadOnlyCollectionCpt> {
        return this.AppointmentsGetCptCodesResponse(id).pipe(__map((_r) => _r.body as ApiResponseIReadOnlyCollectionCpt));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentCptCodesParams` containing the following parameters:
     *
     * - `appointmentId`: The appointment Id.
     *
     * - `includeBilled`: The include Billed.
     *
     * @return appointment CPT code list
     */
    AppointmentsGetAppointmentCptCodesResponse(
        params: AppointmentsService.AppointmentsGetAppointmentCptCodesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentCptCode>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeBilled != null) __params = __params.set('includeBilled', params.includeBilled.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${params.appointmentId}/AppointmentCptCodes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentCptCode>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsGetAppointmentCptCodesParams` containing the following parameters:
     *
     * - `appointmentId`: The appointment Id.
     *
     * - `includeBilled`: The include Billed.
     *
     * @return appointment CPT code list
     */
    AppointmentsGetAppointmentCptCodes(
        params: AppointmentsService.AppointmentsGetAppointmentCptCodesParams,
    ): __Observable<ApiResponseIReadOnlyCollectionAppointmentCptCode> {
        return this.AppointmentsGetAppointmentCptCodesResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionAppointmentCptCode),
        );
    }

    /**
     * @param cptCodeBillRequest The CPT Code Bill Request.
     * @return Is billed flag updated
     */
    AppointmentsUpdateAppointmentCptCodesResponse(
        cptCodeBillRequest: CptCodeBillRequestList,
    ): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentCptCode>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = cptCodeBillRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Appointments/AppointmentCptCodes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentCptCode>;
            }),
        );
    }
    /**
     * @param cptCodeBillRequest The CPT Code Bill Request.
     * @return Is billed flag updated
     */
    AppointmentsUpdateAppointmentCptCodes(
        cptCodeBillRequest: CptCodeBillRequestList,
    ): __Observable<ApiResponseIReadOnlyCollectionAppointmentCptCode> {
        return this.AppointmentsUpdateAppointmentCptCodesResponse(cptCodeBillRequest).pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionAppointmentCptCode),
        );
    }

    /**
     * @param id ID of the calendar appointment.
     * @return OK
     */
    AppointmentsGetIcdCodesResponse(id: number): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionIcd>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${id}/IcdCodes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionIcd>;
            }),
        );
    }
    /**
     * @param id ID of the calendar appointment.
     * @return OK
     */
    AppointmentsGetIcdCodes(id: number): __Observable<ApiResponseIReadOnlyCollectionIcd> {
        return this.AppointmentsGetIcdCodesResponse(id).pipe(__map((_r) => _r.body as ApiResponseIReadOnlyCollectionIcd));
    }

    /**
     * @param appointmentId The appointment Id.
     * @return appointment ICD code list
     */
    AppointmentsGetAppointmentIcdCodesResponse(
        appointmentId: number,
    ): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentIcdCode>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${appointmentId}/AppointmentIcdCodes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentIcdCode>;
            }),
        );
    }
    /**
     * @param appointmentId The appointment Id.
     * @return appointment ICD code list
     */
    AppointmentsGetAppointmentIcdCodes(appointmentId: number): __Observable<ApiResponseIReadOnlyCollectionAppointmentIcdCode> {
        return this.AppointmentsGetAppointmentIcdCodesResponse(appointmentId).pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionAppointmentIcdCode),
        );
    }

    /**
     * @return OK
     */
    AppointmentsGetProblemsResponse(): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionEHRProblem>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/Problems`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionEHRProblem>;
            }),
        );
    }
    /**
     * @return OK
     */
    AppointmentsGetProblems(): __Observable<ApiResponseIReadOnlyCollectionEHRProblem> {
        return this.AppointmentsGetProblemsResponse().pipe(__map((_r) => _r.body as ApiResponseIReadOnlyCollectionEHRProblem));
    }

    /**
     * @return OK
     */
    AppointmentsGetTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionLegacyAppointmentType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/Types`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionLegacyAppointmentType>;
            }),
        );
    }
    /**
     * @return OK
     */
    AppointmentsGetTypes(): __Observable<ApiResponseIReadOnlyCollectionLegacyAppointmentType> {
        return this.AppointmentsGetTypesResponse().pipe(__map((_r) => _r.body as ApiResponseIReadOnlyCollectionLegacyAppointmentType));
    }

    /**
     * @param typeId Appointment type identifier.
     * @return OK
     */
    AppointmentsGetPurposesByTypeResponse(
        typeId: number,
    ): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPurposeResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/Types/${typeId}/Purposes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPurposeResponse>;
            }),
        );
    }
    /**
     * @param typeId Appointment type identifier.
     * @return OK
     */
    AppointmentsGetPurposesByType(typeId: number): __Observable<ApiResponseIReadOnlyCollectionAppointmentPurposeResponse> {
        return this.AppointmentsGetPurposesByTypeResponse(typeId).pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionAppointmentPurposeResponse),
        );
    }

    /**
     * @param showDeleted Show deleted statuses flag.
     * @return OK
     */
    AppointmentsGetStatusesResponse(
        showDeleted?: boolean,
    ): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentStatus>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (showDeleted != null) __params = __params.set('showDeleted', showDeleted.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/Statuses`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentStatus>;
            }),
        );
    }
    /**
     * @param showDeleted Show deleted statuses flag.
     * @return OK
     */
    AppointmentsGetStatuses(showDeleted?: boolean): __Observable<ApiResponseIReadOnlyCollectionAppointmentStatus> {
        return this.AppointmentsGetStatusesResponse(showDeleted).pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionAppointmentStatus),
        );
    }

    /**
     * @param params The `AppointmentsService.AppointmentsUpdateAppointmentStatusParams` containing the following parameters:
     *
     * - `statusId`: The status Id.
     *
     * - `appointmentStatusUpdateRequest`: The appointment Status Update Request.
     *
     * @return Status is updated
     */
    AppointmentsUpdateAppointmentStatusResponse(
        params: AppointmentsService.AppointmentsUpdateAppointmentStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointmentStatus>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.statusId != null) __params = __params.set('statusId', params.statusId.toString());
        __body = params.appointmentStatusUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Appointments/Status`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointmentStatus>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsUpdateAppointmentStatusParams` containing the following parameters:
     *
     * - `statusId`: The status Id.
     *
     * - `appointmentStatusUpdateRequest`: The appointment Status Update Request.
     *
     * @return Status is updated
     */
    AppointmentsUpdateAppointmentStatus(
        params: AppointmentsService.AppointmentsUpdateAppointmentStatusParams,
    ): __Observable<ApiResponseAppointmentStatus> {
        return this.AppointmentsUpdateAppointmentStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointmentStatus));
    }

    /**
     * @param appointmentStatusCreateRequest The appointment Status Create Request.
     * @return Status is created
     */
    AppointmentsCreateAppointmentStatusResponse(
        appointmentStatusCreateRequest: AppointmentStatusCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointmentStatus>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = appointmentStatusCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Appointments/Status`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointmentStatus>;
            }),
        );
    }
    /**
     * @param appointmentStatusCreateRequest The appointment Status Create Request.
     * @return Status is created
     */
    AppointmentsCreateAppointmentStatus(
        appointmentStatusCreateRequest: AppointmentStatusCreateRequest,
    ): __Observable<ApiResponseAppointmentStatus> {
        return this.AppointmentsCreateAppointmentStatusResponse(appointmentStatusCreateRequest).pipe(
            __map((_r) => _r.body as ApiResponseAppointmentStatus),
        );
    }

    /**
     * @param statusId The status Id.
     * @return Status is deleted
     */
    AppointmentsDeleteAppointmentStatusResponse(statusId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (statusId != null) __params = __params.set('statusId', statusId.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/Appointments/Status`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param statusId The status Id.
     * @return Status is deleted
     */
    AppointmentsDeleteAppointmentStatus(statusId: number): __Observable<ApiResponseOkResponse> {
        return this.AppointmentsDeleteAppointmentStatusResponse(statusId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param problemId The problem identifier.
     * @return OK
     */
    AppointmentsGetSubProblemsByProblemResponse(
        problemId: number,
    ): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionEHRSubProblem>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/Problems/${problemId}/SubProblems`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionEHRSubProblem>;
            }),
        );
    }
    /**
     * @param problemId The problem identifier.
     * @return OK
     */
    AppointmentsGetSubProblemsByProblem(problemId: number): __Observable<ApiResponseIReadOnlyCollectionEHRSubProblem> {
        return this.AppointmentsGetSubProblemsByProblemResponse(problemId).pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionEHRSubProblem),
        );
    }

    /**
     * @return OK
     */
    AppointmentsGetPaymentTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPaymentType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/PaymentTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPaymentType>;
            }),
        );
    }
    /**
     * @return OK
     */
    AppointmentsGetPaymentTypes(): __Observable<ApiResponseIReadOnlyCollectionAppointmentPaymentType> {
        return this.AppointmentsGetPaymentTypesResponse().pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionAppointmentPaymentType),
        );
    }

    /**
     * @param params The `AppointmentsService.AppointmentsUpdateStatusParams` containing the following parameters:
     *
     * - `updateRequest`: The update request.
     *
     * - `id`: The appointment identifier.
     *
     * @return Appointment's status updated
     */
    AppointmentsUpdateStatusResponse(
        params: AppointmentsService.AppointmentsUpdateStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.updateRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Appointments/${params.id}/Status`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointment>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsUpdateStatusParams` containing the following parameters:
     *
     * - `updateRequest`: The update request.
     *
     * - `id`: The appointment identifier.
     *
     * @return Appointment's status updated
     */
    AppointmentsUpdateStatus(params: AppointmentsService.AppointmentsUpdateStatusParams): __Observable<ApiResponseAppointment> {
        return this.AppointmentsUpdateStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointment));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsUpdateOutcomeStatusParams` containing the following parameters:
     *
     * - `updateRequest`: The update request.
     *
     * - `id`: The appointment identifier.
     *
     * @return Appointment's outcome status updated
     */
    AppointmentsUpdateOutcomeStatusResponse(
        params: AppointmentsService.AppointmentsUpdateOutcomeStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.updateRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Appointments/${params.id}/OutcomeStatus`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointment>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsUpdateOutcomeStatusParams` containing the following parameters:
     *
     * - `updateRequest`: The update request.
     *
     * - `id`: The appointment identifier.
     *
     * @return Appointment's outcome status updated
     */
    AppointmentsUpdateOutcomeStatus(
        params: AppointmentsService.AppointmentsUpdateOutcomeStatusParams,
    ): __Observable<ApiResponseAppointment> {
        return this.AppointmentsUpdateOutcomeStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointment));
    }

    /**
     * @param params The `AppointmentsService.AppointmentsUpdateAppointmentClaimIdParams` containing the following parameters:
     *
     * - `updateRequest`: The update request.
     *
     * - `id`: The appointment identifier.
     *
     * @return Appointment's claim id updated
     */
    AppointmentsUpdateAppointmentClaimIdResponse(
        params: AppointmentsService.AppointmentsUpdateAppointmentClaimIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.updateRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Appointments/${params.id}/Claim`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointment>;
            }),
        );
    }
    /**
     * @param params The `AppointmentsService.AppointmentsUpdateAppointmentClaimIdParams` containing the following parameters:
     *
     * - `updateRequest`: The update request.
     *
     * - `id`: The appointment identifier.
     *
     * @return Appointment's claim id updated
     */
    AppointmentsUpdateAppointmentClaimId(
        params: AppointmentsService.AppointmentsUpdateAppointmentClaimIdParams,
    ): __Observable<ApiResponseAppointment> {
        return this.AppointmentsUpdateAppointmentClaimIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointment));
    }
}

module AppointmentsService {
    /**
     * Parameters for AppointmentsGetAppointmentsByPeriod
     */
    export interface AppointmentsGetAppointmentsByPeriodParams {
        typeIds?: Array<number>;

        /**
         * Filters appointment by isVideoVisit flag
         */
        showVideoVisitOnly?: boolean;

        /**
         * Filters appointments by fields: IsDeleted and IsCancelled
         */
        showCancelled?: boolean;

        /**
         * Filters appointments by providerId
         */
        providerId?: Array<number>;

        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Size of the page, must be greater than zero.
         */
        pageSize?: number;

        /**
         * Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
         */
        pageIndex?: number;

        /**
         * The modified after time in UTC. When provided returns only appointments created or modified after specified time.
         */
        modifiedAfterTimeInUtc?: string;

        /**
         * Filters appointments by BranchLocationID
         */
        locationId?: number;

        /**
         * Specifies if get appointment for physician (without patient attached)
         */
        isPhysicianAppointment?: boolean;

        /**
         * The include Patient Information.
         */
        includePatientInformation?: boolean;

        /**
         * Filters appointments to provided DateTime
         */
        dateTo?: string;

        /**
         * Filters appointments from provided DateTime
         */
        dateFrom?: string;
    }

    /**
     * Parameters for AppointmentsGetAppointmentClaims
     */
    export interface AppointmentsGetAppointmentClaimsParams {
        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Size of the page, must be greater than zero.
         */
        pageSize?: number;

        /**
         * Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
         */
        pageIndex?: number;

        /**
         * The order direction.
         */
        order?: 'Newest' | 'Oldest';

        /**
         * Show only without claims
         */
        onlyWithoutClaims?: boolean;

        /**
         * Show only with ehr note.
         */
        onlyWithEhrNote?: boolean;

        /**
         * Filters appointments by BranchLocationID
         */
        locationId?: number;

        /**
         * Filters appointments by insurance
         */
        insuranceId?: number;

        /**
         * Filters appointments to provided DateTime
         */
        dateTo?: string;

        /**
         * Filters appointments from provided DateTime
         */
        dateFrom?: string;
    }

    /**
     * Parameters for AppointmentsGetAppointmentClaimsWithBillableList
     */
    export interface AppointmentsGetAppointmentClaimsWithBillableListParams {
        /**
         * The list of not-billable appointments.
         */
        unbillableAppointmentIds: Array<number>;

        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Size of the page, must be greater than zero.
         */
        pageSize?: number;

        /**
         * Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
         */
        pageIndex?: number;

        /**
         * The order direction.
         */
        order?: 'Newest' | 'Oldest';

        /**
         * Show only without claims
         */
        onlyWithoutClaims?: boolean;

        /**
         * Show only with ehr note.
         */
        onlyWithEhrNote?: boolean;

        /**
         * Filters appointments by BranchLocationID
         */
        locationId?: number;

        /**
         * Show billable or not.
         */
        isBillable?: boolean;

        /**
         * Filters appointments by insurance
         */
        insuranceId?: number;

        /**
         * Filters appointments to provided DateTime
         */
        dateTo?: string;

        /**
         * Filters appointments from provided DateTime
         */
        dateFrom?: string;
    }

    /**
     * Parameters for AppointmentsGetAppointmentClaimsCount
     */
    export interface AppointmentsGetAppointmentClaimsCountParams {
        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Show only without claims
         */
        onlyWithoutClaims?: boolean;

        /**
         * Show only with ehr note.
         */
        onlyWithEhrNote?: boolean;

        /**
         * Filters appointments by BranchLocationID
         */
        locationId?: number;

        /**
         * Filters appointments by insurance
         */
        insuranceId?: number;

        /**
         * Filters appointments to provided DateTime
         */
        dateTo?: string;

        /**
         * Filters appointments from provided DateTime
         */
        dateFrom?: string;
    }

    /**
     * Parameters for AppointmentsGetAppointmentClaimsWithBillableListCount
     */
    export interface AppointmentsGetAppointmentClaimsWithBillableListCountParams {
        /**
         * The list of not-billable appointments.
         */
        unbillableAppointmentIds: Array<number>;

        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Show only without claims
         */
        onlyWithoutClaims?: boolean;

        /**
         * Show only with ehr note.
         */
        onlyWithEhrNote?: boolean;

        /**
         * Filters appointments by BranchLocationID
         */
        locationId?: number;

        /**
         * Show billable or not.
         */
        isBillable?: boolean;

        /**
         * Filters appointments by insurance
         */
        insuranceId?: number;

        /**
         * Filters appointments to provided DateTime
         */
        dateTo?: string;

        /**
         * Filters appointments from provided DateTime
         */
        dateFrom?: string;
    }

    /**
     * Parameters for AppointmentsGetAnonymizedAppointmentByPeriod
     */
    export interface AppointmentsGetAnonymizedAppointmentByPeriodParams {
        /**
         * Filters appointments by fields: IsDeleted and IsCancelled
         */
        showCancelled?: boolean;

        /**
         * Filters appointments by providerId
         */
        providerId?: number;

        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Size of the page, must be greater than zero.
         */
        pageSize?: number;

        /**
         * Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
         */
        pageIndex?: number;

        /**
         * The modified after time in UTC. When provided returns only appointments created or modified after specified time.
         */
        modifiedAfterTimeInUtc?: string;

        /**
         * Filters appointments by BranchLocationID
         */
        locationId?: number;
    }

    /**
     * Parameters for AppointmentsGetById
     */
    export interface AppointmentsGetByIdParams {
        /**
         * ID of the calendar appointment.
         */
        id: number;

        /**
         * Specifies if get appointment for physician (without patient attached)
         */
        isPhysicianAppointment?: boolean;

        /**
         * The include Combined Consent Forms.
         */
        includeCombinedConsentForms?: boolean;
    }

    /**
     * Parameters for AppointmentsUpdateAppointment
     */
    export interface AppointmentsUpdateAppointmentParams {
        /**
         * The identifier of appointment
         */
        id: number;

        /**
         * The appointment.
         */
        appointmentRequest: AppointmentUpdateRequest;
    }

    /**
     * Parameters for AppointmentsGetAppointmentsWithLatestNote
     */
    export interface AppointmentsGetAppointmentsWithLatestNoteParams {
        /**
         * The take.
         */
        take?: number;

        /**
         * The skip.
         */
        skip?: number;

        /**
         * The show Cancelled.
         */
        showCancelled?: boolean;

        /**
         * The provider Id.
         */
        providerId?: number;

        /**
         * The patient id.
         */
        patientId?: number;
    }

    /**
     * Parameters for AppointmentsGetAppointmentsWithNotes
     */
    export interface AppointmentsGetAppointmentsWithNotesParams {
        /**
         * The user identifier.
         */
        userId: number;

        /**
         * The patient identifier.
         */
        patientId: number;

        /**
         * The take.
         */
        take?: number;

        /**
         * The skip.
         */
        skip?: number;

        /**
         * The search Str.
         */
        searchStr?: string;

        /**
         * The note is shared with patient.
         */
        noteIsSharedWithPatient?: boolean;

        /**
         * The billing Profile.
         */
        billingProfile?: 'All' | 'InsuranceRelated' | 'NonInsurance';
    }

    /**
     * Parameters for AppointmentsGetByIdFromAnonymousUser
     */
    export interface AppointmentsGetByIdFromAnonymousUserParams {
        /**
         * ID of the tenant
         */
        tenantId: number;

        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * ID of the calendar appointment.
         */
        appointmentId: number;
    }

    /**
     * Parameters for AppointmentsGetAppointmentCptCodes
     */
    export interface AppointmentsGetAppointmentCptCodesParams {
        /**
         * The appointment Id.
         */
        appointmentId: number;

        /**
         * The include Billed.
         */
        includeBilled?: boolean;
    }

    /**
     * Parameters for AppointmentsUpdateAppointmentStatus
     */
    export interface AppointmentsUpdateAppointmentStatusParams {
        /**
         * The status Id.
         */
        statusId: number;

        /**
         * The appointment Status Update Request.
         */
        appointmentStatusUpdateRequest: AppointmentStatusUpdateRequest;
    }

    /**
     * Parameters for AppointmentsUpdateStatus
     */
    export interface AppointmentsUpdateStatusParams {
        /**
         * The update request.
         */
        updateRequest: StatusUpdateRequest;

        /**
         * The appointment identifier.
         */
        id: number;
    }

    /**
     * Parameters for AppointmentsUpdateOutcomeStatus
     */
    export interface AppointmentsUpdateOutcomeStatusParams {
        /**
         * The update request.
         */
        updateRequest: OutcomeStatusUpdateRequest;

        /**
         * The appointment identifier.
         */
        id: number;
    }

    /**
     * Parameters for AppointmentsUpdateAppointmentClaimId
     */
    export interface AppointmentsUpdateAppointmentClaimIdParams {
        /**
         * The update request.
         */
        updateRequest: AppointmentClaimIdUpdateRequest;

        /**
         * The appointment identifier.
         */
        id: number;
    }
}

export { AppointmentsService };
