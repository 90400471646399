/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerablePaymentMode } from '../models/api-response-ienumerable-payment-mode';
import { ApiResponsePaymentMode } from '../models/api-response-payment-mode';
import { PaymentModeCreateRequest } from '../models/payment-mode-create-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PaymentModeUpdateRequest } from '../models/payment-mode-update-request';
@Injectable({
  providedIn: 'root',
})
class PaymentModesService extends __BaseService {
  static readonly GetAllPaymentModesPath = '/PaymentModes';
  static readonly CreatePaymentModePath = '/PaymentModes';
  static readonly UpdatePaymentModeIsDeletedPath = '/PaymentModes/{paymentModeId}/IsDeleted';
  static readonly UpdatePaymentModePath = '/PaymentModes/{paymentModeId}';
  static readonly UpdatePaymentModeImagePath = '/PaymentModes/{paymentModeId}/Image';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all the available payment modes.
   * @param params The `PaymentModesService.GetAllPaymentModesParams` containing the following parameters:
   *
   * - `paymentType`: The payment Type.
   *
   * - `paymentGatewayTypes`: The payment Gateway Types.
   *
   * - `includeImages`: The include Images.
   *
   * - `includeDeleted`: The include Deleted.
   *
   * @return Success
   */
  GetAllPaymentModesResponse(params: PaymentModesService.GetAllPaymentModesParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.paymentType != null) __params = __params.set('paymentType', params.paymentType.toString());
    (params.paymentGatewayTypes || []).forEach(val => {if (val != null) __params = __params.append('paymentGatewayTypes', val.toString())});
    if (params.includeImages != null) __params = __params.set('includeImages', params.includeImages.toString());
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PaymentModes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerablePaymentMode>;
      })
    );
  }
  /**
   * Get all the available payment modes.
   * @param params The `PaymentModesService.GetAllPaymentModesParams` containing the following parameters:
   *
   * - `paymentType`: The payment Type.
   *
   * - `paymentGatewayTypes`: The payment Gateway Types.
   *
   * - `includeImages`: The include Images.
   *
   * - `includeDeleted`: The include Deleted.
   *
   * @return Success
   */
  GetAllPaymentModes(params: PaymentModesService.GetAllPaymentModesParams): __Observable<ApiResponseIEnumerablePaymentMode> {
    return this.GetAllPaymentModesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerablePaymentMode)
    );
  }

  /**
   * Creates the payment mode.
   * @param paymentModeCreationRequest The paymentMode creation request.
   * @return Success or Created
   */
  CreatePaymentModeResponse(paymentModeCreationRequest?: PaymentModeCreateRequest): __Observable<__StrictHttpResponse<ApiResponsePaymentMode | ApiResponsePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = paymentModeCreationRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/PaymentModes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePaymentMode | ApiResponsePaymentMode>;
      })
    );
  }
  /**
   * Creates the payment mode.
   * @param paymentModeCreationRequest The paymentMode creation request.
   * @return Success or Created
   */
  CreatePaymentMode(paymentModeCreationRequest?: PaymentModeCreateRequest): __Observable<ApiResponsePaymentMode | ApiResponsePaymentMode> {
    return this.CreatePaymentModeResponse(paymentModeCreationRequest).pipe(
      __map(_r => _r.body as ApiResponsePaymentMode | ApiResponsePaymentMode)
    );
  }

  /**
   * Deletes/Restores the payment mode.
   * @param params The `PaymentModesService.UpdatePaymentModeIsDeletedParams` containing the following parameters:
   *
   * - `paymentModeId`: The paymentMode identifier.
   *
   * - `isDeleted`: The is Deleted.
   *
   * @return Success
   */
  UpdatePaymentModeIsDeletedResponse(params: PaymentModesService.UpdatePaymentModeIsDeletedParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PaymentModes/${encodeURIComponent(String(params.paymentModeId))}/IsDeleted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes/Restores the payment mode.
   * @param params The `PaymentModesService.UpdatePaymentModeIsDeletedParams` containing the following parameters:
   *
   * - `paymentModeId`: The paymentMode identifier.
   *
   * - `isDeleted`: The is Deleted.
   *
   * @return Success
   */
  UpdatePaymentModeIsDeleted(params: PaymentModesService.UpdatePaymentModeIsDeletedParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePaymentModeIsDeletedResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Modifies the payment mode.
   * @param params The `PaymentModesService.UpdatePaymentModeParams` containing the following parameters:
   *
   * - `paymentModeId`: The payment mode identifier.
   *
   * - `paymentModeUpdateRequest`: The payment mode update request.
   *
   * @return Success
   */
  UpdatePaymentModeResponse(params: PaymentModesService.UpdatePaymentModeParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.paymentModeUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PaymentModes/${encodeURIComponent(String(params.paymentModeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Modifies the payment mode.
   * @param params The `PaymentModesService.UpdatePaymentModeParams` containing the following parameters:
   *
   * - `paymentModeId`: The payment mode identifier.
   *
   * - `paymentModeUpdateRequest`: The payment mode update request.
   *
   * @return Success
   */
  UpdatePaymentMode(params: PaymentModesService.UpdatePaymentModeParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePaymentModeResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The update payment mode image.
   * @param params The `PaymentModesService.UpdatePaymentModeImageParams` containing the following parameters:
   *
   * - `paymentModeId`: The payment mode id.
   *
   * - `file`:
   *
   * @return Success
   */
  UpdatePaymentModeImageResponse(params: PaymentModesService.UpdatePaymentModeImageParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PaymentModes/${encodeURIComponent(String(params.paymentModeId))}/Image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update payment mode image.
   * @param params The `PaymentModesService.UpdatePaymentModeImageParams` containing the following parameters:
   *
   * - `paymentModeId`: The payment mode id.
   *
   * - `file`:
   *
   * @return Success
   */
  UpdatePaymentModeImage(params: PaymentModesService.UpdatePaymentModeImageParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePaymentModeImageResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module PaymentModesService {

  /**
   * Parameters for GetAllPaymentModes
   */
  export interface GetAllPaymentModesParams {

    /**
     * The payment Type.
     */
    paymentType?: 'CreditCard' | 'Cash' | 'Other' | 'Rewards' | 'PatientCredit' | 'GiftCard' | 'InsurancePayment' | 'CreditCardIntegrated' | 'PatientFinancing';

    /**
     * The payment Gateway Types.
     */
    paymentGatewayTypes?: Array<number>;

    /**
     * The include Images.
     */
    includeImages?: boolean;

    /**
     * The include Deleted.
     */
    includeDeleted?: boolean;
  }

  /**
   * Parameters for UpdatePaymentModeIsDeleted
   */
  export interface UpdatePaymentModeIsDeletedParams {

    /**
     * The paymentMode identifier.
     */
    paymentModeId: number;

    /**
     * The is Deleted.
     */
    isDeleted?: boolean;
  }

  /**
   * Parameters for UpdatePaymentMode
   */
  export interface UpdatePaymentModeParams {

    /**
     * The payment mode identifier.
     */
    paymentModeId: number;

    /**
     * The payment mode update request.
     */
    paymentModeUpdateRequest?: PaymentModeUpdateRequest;
  }

  /**
   * Parameters for UpdatePaymentModeImage
   */
  export interface UpdatePaymentModeImageParams {

    /**
     * The payment mode id.
     */
    paymentModeId: number;
    file?: Blob;
  }
}

export { PaymentModesService }
