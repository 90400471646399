import { Injectable } from '@angular/core';
import { LocationsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadLocationTypes, ReloadLocationTypes } from './location-types.actions';
import { ILocationTypesStateModel } from './location-types.model';

@State<ILocationTypesStateModel>({
    name: 'LocationTypes',
    defaults: {
        locationTypes: [],
        loading: false,
    },
})
@Injectable()
export class LocationTypesState {
    constructor(private locationService: LocationsService) {}

    @Action(LoadLocationTypes)
    public load(context: StateContext<ILocationTypesStateModel>, { refresh }: LoadLocationTypes) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.locationTypes.length) || refresh) {
            context.patchState({ loading: true });
            this.locationService
                .GetLocationTypesList()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    if (response.result) {
                        context.patchState({
                            locationTypes: [...response.result].sort((a, b) => (+a.code > +b.code ? 1 : -1)),
                        });
                    }
                });
        }
    }

    @Action(ReloadLocationTypes)
    public reload(context: StateContext<ILocationTypesStateModel>) {
        context.dispatch(new LoadLocationTypes(true));
    }
}
