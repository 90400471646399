/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIListPatientDemographicsReportRow } from '../models/api-response-ilist-patient-demographics-report-row';
import { ApiResponseICollectionTipsReportRow } from '../models/api-response-icollection-tips-report-row';
import { ApiResponseExportResult } from '../models/api-response-export-result';
import { AgingReportExportRequest } from '../models/aging-report-export-request';
import { ApiResponseAgingReportDetail } from '../models/api-response-aging-report-detail';
import { AgingReportDetailRequest } from '../models/aging-report-detail-request';
import { ApiResponseAgingReportSummary } from '../models/api-response-aging-report-summary';
import { AgingReportRequest } from '../models/aging-report-request';
import { ApiResponseIEnumerableClaimStatusReportRow } from '../models/api-response-ienumerable-claim-status-report-row';
import { ApiResponseIEnumerableSurgicalCaseReportRow } from '../models/api-response-ienumerable-surgical-case-report-row';
import { ApiResponseIEnumerableInsuranceTrendsReportRow } from '../models/api-response-ienumerable-insurance-trends-report-row';
@Injectable({
  providedIn: 'root',
})
class ReportsService extends __BaseService {
  static readonly PatientsDemographicsReportPath = '/Patients/Reports/PatientsDemographicsReport';
  static readonly GetTipsReportPath = '/TipsReport';
  static readonly ExportAgingReportToExcelPath = '/Insurance/Reports/Aging/Excel';
  static readonly GetAgingReportDetailsPath = '/Insurance/Reports/Aging/Details';
  static readonly GetAgingReportSummaryPath = '/Insurance/Reports/Aging/Summary';
  static readonly GetClaimsStatusReportPath = '/Insurance/Reports/ClaimsStatus';
  static readonly GetSurgicalCaseReportPath = '/Insurance/Reports/SurgicalCase';
  static readonly GetInsuranceTrendsReportPath = '/Insurance/Reports/InsuranceTrends';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the Patients Demographics Report.
   * @param params The `ReportsService.PatientsDemographicsReportParams` containing the following parameters:
   *
   * - `toDate`: The date to.
   *
   * - `patientTypes`: The patient types.
   *
   * - `patientStatuses`: The patient statuses.
   *
   * - `fromDate`: The date from.
   *
   * @return Success
   */
  PatientsDemographicsReportResponse(params: ReportsService.PatientsDemographicsReportParams): __Observable<__StrictHttpResponse<ApiResponseIListPatientDemographicsReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    (params.patientTypes || []).forEach(val => {if (val != null) __params = __params.append('patientTypes', val.toString())});
    (params.patientStatuses || []).forEach(val => {if (val != null) __params = __params.append('patientStatuses', val.toString())});
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/Reports/PatientsDemographicsReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIListPatientDemographicsReportRow>;
      })
    );
  }
  /**
   * Gets the Patients Demographics Report.
   * @param params The `ReportsService.PatientsDemographicsReportParams` containing the following parameters:
   *
   * - `toDate`: The date to.
   *
   * - `patientTypes`: The patient types.
   *
   * - `patientStatuses`: The patient statuses.
   *
   * - `fromDate`: The date from.
   *
   * @return Success
   */
  PatientsDemographicsReport(params: ReportsService.PatientsDemographicsReportParams): __Observable<ApiResponseIListPatientDemographicsReportRow> {
    return this.PatientsDemographicsReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIListPatientDemographicsReportRow)
    );
  }

  /**
   * Get the tips report.
   * @param params The `ReportsService.GetTipsReportParams` containing the following parameters:
   *
   * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
   *
   * - `RecipientIds`: Gets or sets the list of Recipient Ids to filter.
   *
   * - `LocationIds`: Gets or sets the list of Location Ids to filter.
   *
   * - `DateTo`: Gets or sets the date to.
   *
   * - `DateFrom`: Gets or sets the date from.
   *
   * @return Success
   */
  GetTipsReportResponse(params: ReportsService.GetTipsReportParams): __Observable<__StrictHttpResponse<ApiResponseICollectionTipsReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SplitByLocation != null) __params = __params.set('SplitByLocation', params.SplitByLocation.toString());
    (params.RecipientIds || []).forEach(val => {if (val != null) __params = __params.append('RecipientIds', val.toString())});
    (params.LocationIds || []).forEach(val => {if (val != null) __params = __params.append('LocationIds', val.toString())});
    if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
    if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/TipsReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionTipsReportRow>;
      })
    );
  }
  /**
   * Get the tips report.
   * @param params The `ReportsService.GetTipsReportParams` containing the following parameters:
   *
   * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
   *
   * - `RecipientIds`: Gets or sets the list of Recipient Ids to filter.
   *
   * - `LocationIds`: Gets or sets the list of Location Ids to filter.
   *
   * - `DateTo`: Gets or sets the date to.
   *
   * - `DateFrom`: Gets or sets the date from.
   *
   * @return Success
   */
  GetTipsReport(params: ReportsService.GetTipsReportParams): __Observable<ApiResponseICollectionTipsReportRow> {
    return this.GetTipsReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionTipsReportRow)
    );
  }

  /**
   * Get aging report excel export.
   * @param agingReportExportRequest The aging Report Export Request.
   * @return Success
   */
  ExportAgingReportToExcelResponse(agingReportExportRequest?: AgingReportExportRequest): __Observable<__StrictHttpResponse<ApiResponseExportResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = agingReportExportRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Reports/Aging/Excel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseExportResult>;
      })
    );
  }
  /**
   * Get aging report excel export.
   * @param agingReportExportRequest The aging Report Export Request.
   * @return Success
   */
  ExportAgingReportToExcel(agingReportExportRequest?: AgingReportExportRequest): __Observable<ApiResponseExportResult> {
    return this.ExportAgingReportToExcelResponse(agingReportExportRequest).pipe(
      __map(_r => _r.body as ApiResponseExportResult)
    );
  }

  /**
   * Get aging report details.
   * @param agingReportDetailRequest The aging report detail request.
   * @return Success
   */
  GetAgingReportDetailsResponse(agingReportDetailRequest?: AgingReportDetailRequest): __Observable<__StrictHttpResponse<ApiResponseAgingReportDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = agingReportDetailRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Reports/Aging/Details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAgingReportDetail>;
      })
    );
  }
  /**
   * Get aging report details.
   * @param agingReportDetailRequest The aging report detail request.
   * @return Success
   */
  GetAgingReportDetails(agingReportDetailRequest?: AgingReportDetailRequest): __Observable<ApiResponseAgingReportDetail> {
    return this.GetAgingReportDetailsResponse(agingReportDetailRequest).pipe(
      __map(_r => _r.body as ApiResponseAgingReportDetail)
    );
  }

  /**
   * Get aging report.
   * @param agingReportRequest The request.
   * @return Success
   */
  GetAgingReportSummaryResponse(agingReportRequest?: AgingReportRequest): __Observable<__StrictHttpResponse<ApiResponseAgingReportSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = agingReportRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Reports/Aging/Summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAgingReportSummary>;
      })
    );
  }
  /**
   * Get aging report.
   * @param agingReportRequest The request.
   * @return Success
   */
  GetAgingReportSummary(agingReportRequest?: AgingReportRequest): __Observable<ApiResponseAgingReportSummary> {
    return this.GetAgingReportSummaryResponse(agingReportRequest).pipe(
      __map(_r => _r.body as ApiResponseAgingReportSummary)
    );
  }

  /**
   * Get claims status report.
   * @param params The `ReportsService.GetClaimsStatusReportParams` containing the following parameters:
   *
   * - `Status`: Gets or sets the status.
   *
   * - `ProviderId`: Gets or sets the provider id.
   *
   * - `PatientId`: Gets or sets the patient id.
   *
   * - `LocationIds`: Gets or sets the location ids.
   *
   * - `InsuranceId`: Gets or sets the insurance id.
   *
   * - `EffectiveDateTo`: Gets or sets the effective date to.
   *
   * - `EffectiveDateFrom`: Gets or sets the effective date from.
   *
   * @return Success
   */
  GetClaimsStatusReportResponse(params: ReportsService.GetClaimsStatusReportParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableClaimStatusReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.ProviderId != null) __params = __params.set('ProviderId', params.ProviderId.toString());
    if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
    (params.LocationIds || []).forEach(val => {if (val != null) __params = __params.append('LocationIds', val.toString())});
    if (params.InsuranceId != null) __params = __params.set('InsuranceId', params.InsuranceId.toString());
    if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
    if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Reports/ClaimsStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableClaimStatusReportRow>;
      })
    );
  }
  /**
   * Get claims status report.
   * @param params The `ReportsService.GetClaimsStatusReportParams` containing the following parameters:
   *
   * - `Status`: Gets or sets the status.
   *
   * - `ProviderId`: Gets or sets the provider id.
   *
   * - `PatientId`: Gets or sets the patient id.
   *
   * - `LocationIds`: Gets or sets the location ids.
   *
   * - `InsuranceId`: Gets or sets the insurance id.
   *
   * - `EffectiveDateTo`: Gets or sets the effective date to.
   *
   * - `EffectiveDateFrom`: Gets or sets the effective date from.
   *
   * @return Success
   */
  GetClaimsStatusReport(params: ReportsService.GetClaimsStatusReportParams): __Observable<ApiResponseIEnumerableClaimStatusReportRow> {
    return this.GetClaimsStatusReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableClaimStatusReportRow)
    );
  }

  /**
   * Get surgical case log report.
   * @param params The `ReportsService.GetSurgicalCaseReportParams` containing the following parameters:
   *
   * - `ProviderId`: Gets or sets the provider identifier.
   *
   * - `LocationId`: Gets or sets the location identifier.
   *
   * - `InsuranceId`: Gets or sets the insurance company id.
   *
   * - `DateOfServiceTo`: Gets or sets the date of service to.
   *
   * - `DateOfServiceFrom`: Gets or sets the date of service from.
   *
   * - `CptCodes`: Gets or sets the CPT codes.
   *
   * @return Success
   */
  GetSurgicalCaseReportResponse(params: ReportsService.GetSurgicalCaseReportParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableSurgicalCaseReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProviderId != null) __params = __params.set('ProviderId', params.ProviderId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.InsuranceId != null) __params = __params.set('InsuranceId', params.InsuranceId.toString());
    if (params.DateOfServiceTo != null) __params = __params.set('DateOfServiceTo', params.DateOfServiceTo.toString());
    if (params.DateOfServiceFrom != null) __params = __params.set('DateOfServiceFrom', params.DateOfServiceFrom.toString());
    (params.CptCodes || []).forEach(val => {if (val != null) __params = __params.append('CptCodes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Reports/SurgicalCase`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableSurgicalCaseReportRow>;
      })
    );
  }
  /**
   * Get surgical case log report.
   * @param params The `ReportsService.GetSurgicalCaseReportParams` containing the following parameters:
   *
   * - `ProviderId`: Gets or sets the provider identifier.
   *
   * - `LocationId`: Gets or sets the location identifier.
   *
   * - `InsuranceId`: Gets or sets the insurance company id.
   *
   * - `DateOfServiceTo`: Gets or sets the date of service to.
   *
   * - `DateOfServiceFrom`: Gets or sets the date of service from.
   *
   * - `CptCodes`: Gets or sets the CPT codes.
   *
   * @return Success
   */
  GetSurgicalCaseReport(params: ReportsService.GetSurgicalCaseReportParams): __Observable<ApiResponseIEnumerableSurgicalCaseReportRow> {
    return this.GetSurgicalCaseReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableSurgicalCaseReportRow)
    );
  }

  /**
   * Get insurance trends report.
   * @param params The `ReportsService.GetInsuranceTrendsReportParams` containing the following parameters:
   *
   * - `ProviderId`: Gets or sets the provider identifier.
   *
   * - `LocationId`: Gets or sets the location identifier.
   *
   * - `InsuranceId`: Gets or sets the insurance company id.
   *
   * - `DateOfServiceTo`: Gets or sets the date of service to.
   *
   * - `DateOfServiceFrom`: Gets or sets the date of service from.
   *
   * - `CptCodes`: Gets or sets the CPT codes.
   *
   * @return Success
   */
  GetInsuranceTrendsReportResponse(params: ReportsService.GetInsuranceTrendsReportParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInsuranceTrendsReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProviderId != null) __params = __params.set('ProviderId', params.ProviderId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.InsuranceId != null) __params = __params.set('InsuranceId', params.InsuranceId.toString());
    if (params.DateOfServiceTo != null) __params = __params.set('DateOfServiceTo', params.DateOfServiceTo.toString());
    if (params.DateOfServiceFrom != null) __params = __params.set('DateOfServiceFrom', params.DateOfServiceFrom.toString());
    (params.CptCodes || []).forEach(val => {if (val != null) __params = __params.append('CptCodes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Reports/InsuranceTrends`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableInsuranceTrendsReportRow>;
      })
    );
  }
  /**
   * Get insurance trends report.
   * @param params The `ReportsService.GetInsuranceTrendsReportParams` containing the following parameters:
   *
   * - `ProviderId`: Gets or sets the provider identifier.
   *
   * - `LocationId`: Gets or sets the location identifier.
   *
   * - `InsuranceId`: Gets or sets the insurance company id.
   *
   * - `DateOfServiceTo`: Gets or sets the date of service to.
   *
   * - `DateOfServiceFrom`: Gets or sets the date of service from.
   *
   * - `CptCodes`: Gets or sets the CPT codes.
   *
   * @return Success
   */
  GetInsuranceTrendsReport(params: ReportsService.GetInsuranceTrendsReportParams): __Observable<ApiResponseIEnumerableInsuranceTrendsReportRow> {
    return this.GetInsuranceTrendsReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableInsuranceTrendsReportRow)
    );
  }
}

module ReportsService {

  /**
   * Parameters for PatientsDemographicsReport
   */
  export interface PatientsDemographicsReportParams {

    /**
     * The date to.
     */
    toDate?: string;

    /**
     * The patient types.
     */
    patientTypes?: Array<'Active' | 'Prospect'>;

    /**
     * The patient statuses.
     */
    patientStatuses?: Array<'All' | 'Registered' | 'NotRegistered' | 'Disabled'>;

    /**
     * The date from.
     */
    fromDate?: string;
  }

  /**
   * Parameters for GetTipsReport
   */
  export interface GetTipsReportParams {

    /**
     * Gets or sets a value indicating whether the data would be split by location.
     */
    SplitByLocation?: boolean;

    /**
     * Gets or sets the list of Recipient Ids to filter.
     */
    RecipientIds?: Array<number>;

    /**
     * Gets or sets the list of Location Ids to filter.
     */
    LocationIds?: Array<number>;

    /**
     * Gets or sets the date to.
     */
    DateTo?: string;

    /**
     * Gets or sets the date from.
     */
    DateFrom?: string;
  }

  /**
   * Parameters for GetClaimsStatusReport
   */
  export interface GetClaimsStatusReportParams {

    /**
     * Gets or sets the status.
     */
    Status?: 'Active' | 'Closed' | 'Submitted' | 'Rejected' | 'Approved' | 'Deleted' | 'Draft' | 'Queued' | 'Transmitted' | 'Exported' | 'Denied' | 'PaidPatientDue' | 'PaymentReceivedNeedsReview' | 'PaymentReceivedAppealing' | 'PaymentReceivedReadyForSecondary' | 'PaymentReceivedSecondaryCreated' | 'ClosedSecondaryCreated' | 'TransmittingFailed';

    /**
     * Gets or sets the provider id.
     */
    ProviderId?: number;

    /**
     * Gets or sets the patient id.
     */
    PatientId?: number;

    /**
     * Gets or sets the location ids.
     */
    LocationIds?: Array<number>;

    /**
     * Gets or sets the insurance id.
     */
    InsuranceId?: number;

    /**
     * Gets or sets the effective date to.
     */
    EffectiveDateTo?: string;

    /**
     * Gets or sets the effective date from.
     */
    EffectiveDateFrom?: string;
  }

  /**
   * Parameters for GetSurgicalCaseReport
   */
  export interface GetSurgicalCaseReportParams {

    /**
     * Gets or sets the provider identifier.
     */
    ProviderId?: number;

    /**
     * Gets or sets the location identifier.
     */
    LocationId?: number;

    /**
     * Gets or sets the insurance company id.
     */
    InsuranceId?: number;

    /**
     * Gets or sets the date of service to.
     */
    DateOfServiceTo?: string;

    /**
     * Gets or sets the date of service from.
     */
    DateOfServiceFrom?: string;

    /**
     * Gets or sets the CPT codes.
     */
    CptCodes?: Array<string>;
  }

  /**
   * Parameters for GetInsuranceTrendsReport
   */
  export interface GetInsuranceTrendsReportParams {

    /**
     * Gets or sets the provider identifier.
     */
    ProviderId?: number;

    /**
     * Gets or sets the location identifier.
     */
    LocationId?: number;

    /**
     * Gets or sets the insurance company id.
     */
    InsuranceId?: number;

    /**
     * Gets or sets the date of service to.
     */
    DateOfServiceTo?: string;

    /**
     * Gets or sets the date of service from.
     */
    DateOfServiceFrom?: string;

    /**
     * Gets or sets the CPT codes.
     */
    CptCodes?: Array<string>;
  }
}

export { ReportsService }
