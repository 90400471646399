export class LoadGeneralSettings {
    public static readonly type = '[GeneralSettings] Load';
    constructor(public refresh?: boolean) {}
}

export class SaveGeneralSettingRequest {
    public static readonly type = '[GeneralSettings] Save General';
    constructor(
        public isAutomaticLogOutExitingESign: boolean,
        public sendPatientAppInvitation: boolean,
        public isReviewAfterSignedRequired: boolean,
    ) {}
}

export class SavePatientFileSettingRequest {
    public static readonly type = '[GeneralSettings] Save Patient File Setting';
    constructor(public patientFileFinancialViewMode: 'Grid' | 'List') {}
}
