import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { ApplicationInsightsService, LoadAppSettings, PrivateSettingsService } from '@symplast/services/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { combineLatest, filter, fromEvent, map, Observable, startWith } from 'rxjs';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent, MenuState } from './components/menu/menu.component';
import { BrowserService } from '@symplast/utils';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ConfirmPhoneDialogComponent } from '../shared/confirm-phone-dialog/confirm-phone-dialog.component';
import { CurrentUserService } from '../core/services/current-user.service';
import { TenantsEssentialsService } from '../core/services/tenants-essentials.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SessionService } from './services/session.service';
import { LayoutService, LayoutViewEnum } from './layout.service';
import { MobilePhoneConfirmStorage } from '../core/services/mobile-phone-confirm.storage';
import { PasswordResetPromptService } from '../core/services/password-reset-prompt.service';
import { AppRoutePath } from '../app.route-path';
import { AdminRoutePath } from '../admin/admin.route-path';
import { FileUploadComponent } from '../shared/file-upload/file-upload.component';
import { LoaderComponent } from '../shared/loader/loader.component';
import { NotificationsComponent } from '../shared/notifications/notifications.component';
import { HiddenInWebViewDirective } from '../shared/directives/hidden-in-web-view.directive';

@UntilDestroy()
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    // TODO: (a.vakhrushin) use OnPush to increase performance
    // Everything seems to work with OnPush, but should be careful
    // changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        FileUploadComponent,
        MatProgressBarModule,
        ScrollingModule,
        LoaderComponent,
        NotificationsComponent,
        HiddenInWebViewDirective,
    ],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('content', { read: ElementRef }) contentRef: ElementRef;

    public layoutViewEnum = LayoutViewEnum;
    public isMenuOpen = false;
    public processInProgress$: Observable<boolean>;
    public readonly isWebView = this.browserService.IsWebView();

    constructor(
        public layoutService: LayoutService,
        public readonly browserService: BrowserService,
        private readonly store: Store,
        private readonly dialog: MatDialog,
        private readonly cdr: ChangeDetectorRef,
        private readonly router: Router,
        private readonly currentUserService: CurrentUserService,
        private tenantsEssentialsService: TenantsEssentialsService,
        private applicationInsightsService: ApplicationInsightsService,
        private sessionService: SessionService,
        private privateSettingsService: PrivateSettingsService,
        private mobilePhoneConfirmStorage: MobilePhoneConfirmStorage,
        private passwordResetPromptService: PasswordResetPromptService,
    ) {}

    public ngOnInit(): void {
        this.store.dispatch(new LoadAppSettings(this.privateSettingsService.isFinancialsEnabled));
        this.initProgressBar();
        this.applicationInsightsService.init();

        if (!this.isWebView) {
            this.sessionService.init();
        }

        if (!this.passwordResetPromptService.canBeShown) {
            this.checkConformationPhoneDialog();
        } else {
            this.passwordResetPromptService.prompt().on({
                confirm: () =>
                    this.router.navigate(['/', AppRoutePath.SETTINGS, AdminRoutePath.PROFILE], { queryParams: { resetPassword: true } }),
                deny: () => this.checkConformationPhoneDialog(),
            });
        }

        const userId = this.currentUserService.user.userId;
        const tenantId = this.tenantsEssentialsService.tenant.tenantId;

        this.applicationInsightsService.updateUserData(tenantId, +userId);
    }

    public ngAfterViewInit(): void {
        this.browserService.setUpScrollProgressObservable(fromEvent(this.contentRef.nativeElement, 'scroll'));

        this.browserService.isLoading$.pipe(untilDestroyed(this)).subscribe(() => {
            // FIXME: (a.vakhrushin) Possibly unnecessary functionality. Recheck.
            if (!this.cdr['destroyed']) {
                this.cdr.detectChanges();
            }
        });
    }

    public ngOnDestroy(): void {
        this.sessionService.destroy();
    }

    public onMenuToggle(menuState: MenuState): void {
        this.isMenuOpen = menuState === MenuState.OPEN;
    }

    private initProgressBar(): void {
        this.processInProgress$ = combineLatest([
            this.browserService.isBackgroundLoading$.pipe(startWith(false)),
            this.router.events.pipe(
                filter((event) => [RouteConfigLoadStart, RouteConfigLoadEnd, NavigationCancel].some((step) => event instanceof step)),
                map((event) => event instanceof RouteConfigLoadStart),
                startWith(false),
            ),
        ]).pipe(
            map(([isBackgroundLoading, isRouteLoadStart]) => isBackgroundLoading || isRouteLoadStart),
            untilDestroyed(this),
        );
    }

    private checkConformationPhoneDialog(): void {
        const user = this.currentUserService.user;
        const currentDate = new Date();
        const delayedDate = user?.mobilePhoneRequestDisabledUntilUtc;
        const isDelayed = delayedDate && currentDate < delayedDate;
        const declined = this.mobilePhoneConfirmStorage.getMobilePhoneConfirmCanceled();

        if (!declined && !user?.isMobilePhoneNumberConfirmed && !isDelayed && !this.browserService.IsWebView()) {
            this.dialog.open(ConfirmPhoneDialogComponent, {
                width: '500px',
                panelClass: 'dialog-modal',
            });
        }
    }
}
