import { Selector } from '@ngxs/store';
import { IEthnicitiesStateModel } from './ethnicities.model';
import { EthnicitiesState } from './ethnicities.state';

export class EthnicitiesSelectors {
    @Selector([EthnicitiesState])
    public static loading(state: IEthnicitiesStateModel): boolean {
        return state.loading;
    }

    @Selector([EthnicitiesState])
    public static ethnicities(state: IEthnicitiesStateModel): IEthnicitiesStateModel['ethnicities'] {
        return state.ethnicities;
    }
}
