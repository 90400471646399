import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserService } from '@symplast/utils';
import { UtilityService } from '@symplast/utils';
import { NotificationService, ApplicationInsightsService } from '@symplast/services/shared';
import { ApplicationConstants } from '@symplast/app-constants';

const errorsToExclude = [
    // Ignore the error caused by PowerBI when rapidly clicking the side panel links. To be further investigated.
    "Error: Uncaught (in promise): TypeError: Cannot read properties of null (reading 'postMessage')",
    // Ignore the error caused by ng2-pdf-viewer when rapidly creating and destroying the component. To be further investigated.
    'Error: Transport destroyed',
];

@Injectable({ providedIn: 'root' })
export class CustomizedErrorHandler extends ErrorHandler {
    constructor(
        private notificationService: NotificationService,
        private browserService: BrowserService,
        private utilityService: UtilityService,
        private router: Router,
        private ngZone: NgZone,
        private appInsightsService: ApplicationInsightsService,
    ) {
        super();
    }

    handleError(error: any): void {
        const clientErrorId = this.utilityService.newGuid();

        if (error instanceof HttpErrorResponse) {
            // Server or connection error happened
            if (!navigator.onLine) {
                // Handle offline error
                return this.notificationService.addNotification('No Internet Connection');
            } else {
                // Handle Http Error (error.status === 403, 404...)
                const statusCode = error.error != null ? error.error.status : error.status;

                // exclude non-cancelled call
                if (statusCode !== 0) {
                    this.appInsightsService.logError(error, {
                        name: error.name,
                        message: error.message,
                        errorId: clientErrorId,
                        url: error.url,
                        errorMessage: error.error != null ? error.error.ErrorMessage : error.message,
                        StatusCode: statusCode,
                    });
                    this.browserService.setLoadingStatus(false);

                    return this.notificationService.addNotification(
                        `There was an issue,
                             please try again later or refer this issue id: ${clientErrorId} to your support team.`,
                    );
                } else {
                    this.appInsightsService.logEvent(error.message, {
                        name: error.name,
                        message: error.message,
                        errorId: clientErrorId,
                        url: error.url,
                        errorMessage: error.error != null ? error.error.ErrorMessage : error.message,
                        StatusCode: statusCode,
                    });
                }
                this.browserService.setLoadingStatus(false);
            }
        } else {
            // Handle Client Error (Angular Error, ReferenceError...)
            this.appInsightsService.logError(error, {
                name: error.name,
                message: error.message,
                errorId: clientErrorId,
            });

            if (!errorsToExclude.some((errorToExclude) => error.toString().includes(errorToExclude))) {
                this.appInsightsService.destroy();

                let errorToDisplay: string;

                try {
                    errorToDisplay = encodeURIComponent(error.toString().substring(0, ApplicationConstants.MaxErrorLength));

                    if (errorToDisplay.length > ApplicationConstants.MaxBrowserUrlLength) {
                        errorToDisplay = encodeURIComponent(ApplicationConstants.DefaultErrorMessage);
                    }
                } catch {
                    errorToDisplay = encodeURIComponent(ApplicationConstants.DefaultErrorMessage);
                }

                this.ngZone.run(() => {
                    this.browserService.setLoadingStatus(false);
                    this.router.navigate(['/error'], { queryParams: { error: errorToDisplay } });
                });
            }
        }

        // Log the error anyway
        console.error(error);
    }
}
