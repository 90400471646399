import { ChangeDetectorRef, Directive, Input, Optional, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';

@Directive()
export abstract class ControlValueAccessorBaseDirective implements ControlValueAccessor {
    @ViewChild(FormControlDirective, { static: true })
    public formControlDirective?: FormControlDirective;

    @Input()
    public formControl!: FormControl;
    @Input()
    public formControlName!: string;

    private readonly newFormControl = new FormControl();

    protected constructor(@Optional() public cdr?: ChangeDetectorRef, @Optional() protected readonly controlContainer?: ControlContainer) {}

    public get control(): FormControl {
        return this.formControl || (this.controlContainer?.control?.get(this.formControlName) as FormControl) || this.newFormControl;
    }

    private get valueAccessorExists(): boolean {
        return !!(this.formControlDirective && this.formControlDirective?.valueAccessor);
    }

    public registerOnTouched(fn: () => void): void {
        this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
    }

    public registerOnChange(fn: () => void): void {
        this.formControlDirective?.valueAccessor?.registerOnChange(fn);
    }

    public writeValue(obj: any): void {
        this.formControlDirective?.valueAccessor?.writeValue(obj);
        this.cdr?.markForCheck();
    }

    public setDisabledState(isDisabled: boolean): void {
        if (this.valueAccessorExists && this.formControlDirective?.valueAccessor?.setDisabledState) {
            this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
        }
    }
}
