import { Selector } from '@ngxs/store';
import { IInsuranceSettingsStateModel } from './insurance-settings.model';
import { InsuranceSettingsState } from './insurance-settings.state';

export class InsuranceSettingsSelectors {
    @Selector([InsuranceSettingsState])
    public static loading(state: IInsuranceSettingsStateModel): boolean {
        return state.loading;
    }

    @Selector([InsuranceSettingsState])
    public static enable(state: IInsuranceSettingsStateModel): boolean {
        return state.enabled;
    }

    @Selector([InsuranceSettingsState])
    public static enabledReceiving835File(state: IInsuranceSettingsStateModel): boolean {
        return state.enabledReceiving835File;
    }

    @Selector([InsuranceSettingsState])
    public static acceptAssignmentsByDefault(state: IInsuranceSettingsStateModel): boolean {
        return state.acceptAssignmentsByDefault;
    }

    @Selector([InsuranceSettingsState])
    public static defaultBillingLocationId(state: IInsuranceSettingsStateModel): number | undefined {
        return state.defaultBillingLocationId;
    }
}
