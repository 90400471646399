import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngxs/store';
import { ReferralSourcesService as ReferralSourcesServiceAPI, ReferralSource } from '@symplast/generated-clients/web-portal';
import { EMPTY, Subscription, throwError } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LoadReferralSources, ReferralSourcesSelectors, ReloadReferralSources } from '../stores/referral-sources';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root',
})
export class ReferralSourcesService {
    private _mainReferralList: ReferralSource[];
    loading = false;
    filteredReferralList: ReferralSource[];
    notificationSubscription: Subscription;

    set mainReferralList(referralList) {
        this._mainReferralList = referralList;
        this.filteredReferralList = referralList;
    }

    get mainReferralList() {
        return this._mainReferralList;
    }

    constructor(
        private referralSApiService: ReferralSourcesServiceAPI,
        public dialog: MatDialog,
        private notificationService: NotificationService,
        private store: Store,
    ) {}

    getReferralsList(initSearch?: string) {
        this.loading = true;
        this.store.dispatch(new LoadReferralSources());

        this.store
            .select(ReferralSourcesSelectors.loading)
            .pipe(
                filter((loading) => !loading),
                take(1),
            )
            .subscribe((res) => {
                this.mainReferralList = this.store.selectSnapshot(ReferralSourcesSelectors.referralSources);
                if (initSearch) {
                    this.filterMainReferrals(initSearch);
                }
                this.loading = false;
            });
    }

    getReferralById(id: number) {
        return this.referralSApiService.GetReferralSourceById({ id });
    }

    filterMainReferrals(search: string) {
        const searchQuery = search.trim().toLocaleLowerCase();

        this.filteredReferralList = this.mainReferralList.filter((referral) => referral.name.toLocaleLowerCase().includes(searchQuery));
    }

    handleError(err, formGroup: FormGroup) {
        if (err.error.errorMessage.match(/already exist$/) || err.error.errorMessage.match(/same source names/)) {
            this.notificationService.addNotification('This referral source already exists');
            formGroup.setErrors({ duplicateName: true });

            return EMPTY;
        } else {
            return throwError(err);
        }
    }

    deleteMainReferralById(referralId: number) {
        this.loading = true;
        this.referralSApiService.DeleteReferralSource(referralId).subscribe((res) => {
            this.store.dispatch(new ReloadReferralSources());
            if (res.statusCode === 200) {
                this.getReferralsList();
            }
            this.loading = false;
        });
    }

    subscribeToApiErrors() {
        this.notificationSubscription = this.notificationService.currentNotifications$.subscribe((notification) => {
            if (notification.length) {
                this.loading = false;
            }
        });
    }

    unsubscribeApiErrors() {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
    }
}
