import { LocationItemType } from '@symplast/generated-clients/web-portal';

export class LoadTaxes {
    public static readonly type = '[Taxes] Load';
    constructor(public refresh?: boolean) {}
}

export class LoadLocationTaxDefaults {
    public static readonly type = '[Location Tax Defaults] Load';
    constructor(public refresh?: boolean) {}
}

export class CreateLocationTaxDefault {
    public static readonly type = '[Location Tax Defaults] Create';
    constructor(public locationId: number, public itemTypeId: number, public defaultTaxId: number) {}
}

export class DeleteLocationTaxDefault {
    public static readonly type = '[Location Tax Defaults] Delete';
    constructor(public locationItemTypeId: number) {}
}

export class UpdateLocationTaxDefault {
    public static readonly type = '[Location Tax Defaults] Update';
    constructor(public locationId: number, public oldIds: LocationItemType[], public newIds: number[]) {}
}

export class UpdateTaxIsEditMode {
    public static readonly type = '[Location Tax Defaults] Update IsEditMode';
    constructor(public locationId: number, public isEditMode: boolean) {}
}
