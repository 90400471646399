/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableAppointment } from '../models/api-response-ienumerable-appointment';
import { ApiResponseAppointment } from '../models/api-response-appointment';
import { ApiResponseIEnumerableAppointmentInsuranceInfo } from '../models/api-response-ienumerable-appointment-insurance-info';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { InsuranceAppointmentCreateUpdateRequest } from '../models/insurance-appointment-create-update-request';
import { ApiResponseAppointmentsCount } from '../models/api-response-appointments-count';
import { ApiResponseCardTwilioToken } from '../models/api-response-card-twilio-token';
import { ApiResponseGenerateInsuranceAppointmentListDocumentResponse } from '../models/api-response-generate-insurance-appointment-list-document-response';
import { ApiResponseAppointmentListReport } from '../models/api-response-appointment-list-report';
import { AppointmentListReportRequest } from '../models/appointment-list-report-request';
import { ApiResponseString } from '../models/api-response-string';
import { AppointmentListReportExportRequest } from '../models/appointment-list-report-export-request';
@Injectable({
  providedIn: 'root',
})
class AppointmentsService extends __BaseService {
  static readonly GetAppointmentsPath = '/Appointments';
  static readonly GetAppointmentByIdPath = '/Appointments/{appointmentId}';
  static readonly GetAppointmentInsurancesPath = '/Appointments/Insurance';
  static readonly CreateAppointmentInsurancesPath = '/Appointments/Insurance';
  static readonly UpdateAppointmentInsurancesPath = '/Appointments/Insurance/{insuranceAppointmentId}';
  static readonly GetAppointmentInsurancesCountPath = '/Appointments/Insurance/Count';
  static readonly GetVideoVisitTokenPath = '/Appointments/VideoVisits/Tokens';
  static readonly GenerateInsuranceAppointmentDocumentPath = '/Appointments/GenerateDocument';
  static readonly GetAppointmentListReportPath = '/Appointments/AppointmentListReport';
  static readonly ExportAppointmentsListReportPath = '/Appointments/AppointmentsListReport/Excel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get appointment types.
   * @param params The `AppointmentsService.GetAppointmentsParams` containing the following parameters:
   *
   * - `showVideoVisitOnly`: The show Video Visit Only.
   *
   * - `showCancelled`: The show Cancelled.
   *
   * - `providerIds`: The provider Id.
   *
   * - `patientId`: The patient Id.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `modifiedAfterTimeInUtc`: The modified After Time In UTC.
   *
   * - `locationId`: The location Id.
   *
   * - `isPhysicianAppointment`: The is Physician Appointment.
   *
   * - `dateToInLocal`: The date To In Local.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFromInLocal`: The date From In Local.
   *
   * - `dateFrom`: The date From.
   *
   * @return Success
   */
  GetAppointmentsResponse(params: AppointmentsService.GetAppointmentsParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showVideoVisitOnly != null) __params = __params.set('showVideoVisitOnly', params.showVideoVisitOnly.toString());
    if (params.showCancelled != null) __params = __params.set('showCancelled', params.showCancelled.toString());
    (params.providerIds || []).forEach(val => {if (val != null) __params = __params.append('providerIds', val.toString())});
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.modifiedAfterTimeInUtc != null) __params = __params.set('modifiedAfterTimeInUtc', params.modifiedAfterTimeInUtc.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.isPhysicianAppointment != null) __params = __params.set('isPhysicianAppointment', params.isPhysicianAppointment.toString());
    if (params.dateToInLocal != null) __params = __params.set('dateToInLocal', params.dateToInLocal.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFromInLocal != null) __params = __params.set('dateFromInLocal', params.dateFromInLocal.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Appointments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointment>;
      })
    );
  }
  /**
   * The get appointment types.
   * @param params The `AppointmentsService.GetAppointmentsParams` containing the following parameters:
   *
   * - `showVideoVisitOnly`: The show Video Visit Only.
   *
   * - `showCancelled`: The show Cancelled.
   *
   * - `providerIds`: The provider Id.
   *
   * - `patientId`: The patient Id.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `modifiedAfterTimeInUtc`: The modified After Time In UTC.
   *
   * - `locationId`: The location Id.
   *
   * - `isPhysicianAppointment`: The is Physician Appointment.
   *
   * - `dateToInLocal`: The date To In Local.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFromInLocal`: The date From In Local.
   *
   * - `dateFrom`: The date From.
   *
   * @return Success
   */
  GetAppointments(params: AppointmentsService.GetAppointmentsParams): __Observable<ApiResponseIEnumerableAppointment> {
    return this.GetAppointmentsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableAppointment)
    );
  }

  /**
   * Get appointments by id.
   * @param appointmentId the appointment identifier.
   * @return Success
   */
  GetAppointmentByIdResponse(appointmentId: number): __Observable<__StrictHttpResponse<ApiResponseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Appointments/${encodeURIComponent(String(appointmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointment>;
      })
    );
  }
  /**
   * Get appointments by id.
   * @param appointmentId the appointment identifier.
   * @return Success
   */
  GetAppointmentById(appointmentId: number): __Observable<ApiResponseAppointment> {
    return this.GetAppointmentByIdResponse(appointmentId).pipe(
      __map(_r => _r.body as ApiResponseAppointment)
    );
  }

  /**
   * Get appointments with insurance info.
   * @param params The `AppointmentsService.GetAppointmentInsurancesParams` containing the following parameters:
   *
   * - `providerIds`: The provider Ids.
   *
   * - `patientId`: The patient Id.
   *
   * - `patientDob`: The patient Date  of birth.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `orderBy`: The order By.
   *
   * - `onlyWithoutClaims`: Show only without claims.
   *
   * - `onlyWithEhrNote`: Show only with EHR note.
   *
   * - `isBillable`: Show only billable.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * - `insuranceId`: The insurance Id.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFrom`: The date From.
   *
   * - `appointmentTypeId`: The appointment Type Id.
   *
   * @return Success
   */
  GetAppointmentInsurancesResponse(params: AppointmentsService.GetAppointmentInsurancesParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointmentInsuranceInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.providerIds || []).forEach(val => {if (val != null) __params = __params.append('providerIds', val.toString())});
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.patientDob != null) __params = __params.set('patientDob', params.patientDob.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.orderBy != null) __params = __params.set('orderBy', params.orderBy.toString());
    if (params.onlyWithoutClaims != null) __params = __params.set('onlyWithoutClaims', params.onlyWithoutClaims.toString());
    if (params.onlyWithEhrNote != null) __params = __params.set('onlyWithEhrNote', params.onlyWithEhrNote.toString());
    if (params.isBillable != null) __params = __params.set('isBillable', params.isBillable.toString());
    if (params.isAscendingOrder != null) __params = __params.set('isAscendingOrder', params.isAscendingOrder.toString());
    if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.appointmentTypeId != null) __params = __params.set('appointmentTypeId', params.appointmentTypeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Appointments/Insurance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointmentInsuranceInfo>;
      })
    );
  }
  /**
   * Get appointments with insurance info.
   * @param params The `AppointmentsService.GetAppointmentInsurancesParams` containing the following parameters:
   *
   * - `providerIds`: The provider Ids.
   *
   * - `patientId`: The patient Id.
   *
   * - `patientDob`: The patient Date  of birth.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `orderBy`: The order By.
   *
   * - `onlyWithoutClaims`: Show only without claims.
   *
   * - `onlyWithEhrNote`: Show only with EHR note.
   *
   * - `isBillable`: Show only billable.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * - `insuranceId`: The insurance Id.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFrom`: The date From.
   *
   * - `appointmentTypeId`: The appointment Type Id.
   *
   * @return Success
   */
  GetAppointmentInsurances(params: AppointmentsService.GetAppointmentInsurancesParams): __Observable<ApiResponseIEnumerableAppointmentInsuranceInfo> {
    return this.GetAppointmentInsurancesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableAppointmentInsuranceInfo)
    );
  }

  /**
   * The create appointment.
   * @param request The request.
   * @return Success
   */
  CreateAppointmentInsurancesResponse(request?: InsuranceAppointmentCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Appointments/Insurance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The create appointment.
   * @param request The request.
   * @return Success
   */
  CreateAppointmentInsurances(request?: InsuranceAppointmentCreateUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.CreateAppointmentInsurancesResponse(request).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update appointment.
   * @param params The `AppointmentsService.UpdateAppointmentInsurancesParams` containing the following parameters:
   *
   * - `insuranceAppointmentId`: The insurance appointment id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  UpdateAppointmentInsurancesResponse(params: AppointmentsService.UpdateAppointmentInsurancesParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Appointments/Insurance/${encodeURIComponent(String(params.insuranceAppointmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update appointment.
   * @param params The `AppointmentsService.UpdateAppointmentInsurancesParams` containing the following parameters:
   *
   * - `insuranceAppointmentId`: The insurance appointment id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  UpdateAppointmentInsurances(params: AppointmentsService.UpdateAppointmentInsurancesParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateAppointmentInsurancesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get appointments with insurance count.
   * @param params The `AppointmentsService.GetAppointmentInsurancesCountParams` containing the following parameters:
   *
   * - `providerIds`: The provider Ids.
   *
   * - `patientId`: The patient Id.
   *
   * - `patientDob`: The patient date of birth.
   *
   * - `onlyWithoutClaims`: Show only without claims.
   *
   * - `onlyWithEhrNote`: Show only with EHR note.
   *
   * - `isBillable`: Show only billable.
   *
   * - `insuranceId`: The insurance Id.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFrom`: The date From.
   *
   * - `appointmentTypeId`: The appointment Type Id.
   *
   * @return Success
   */
  GetAppointmentInsurancesCountResponse(params: AppointmentsService.GetAppointmentInsurancesCountParams): __Observable<__StrictHttpResponse<ApiResponseAppointmentsCount>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.providerIds || []).forEach(val => {if (val != null) __params = __params.append('providerIds', val.toString())});
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.patientDob != null) __params = __params.set('patientDob', params.patientDob.toString());
    if (params.onlyWithoutClaims != null) __params = __params.set('onlyWithoutClaims', params.onlyWithoutClaims.toString());
    if (params.onlyWithEhrNote != null) __params = __params.set('onlyWithEhrNote', params.onlyWithEhrNote.toString());
    if (params.isBillable != null) __params = __params.set('isBillable', params.isBillable.toString());
    if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.appointmentTypeId != null) __params = __params.set('appointmentTypeId', params.appointmentTypeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Appointments/Insurance/Count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentsCount>;
      })
    );
  }
  /**
   * Get appointments with insurance count.
   * @param params The `AppointmentsService.GetAppointmentInsurancesCountParams` containing the following parameters:
   *
   * - `providerIds`: The provider Ids.
   *
   * - `patientId`: The patient Id.
   *
   * - `patientDob`: The patient date of birth.
   *
   * - `onlyWithoutClaims`: Show only without claims.
   *
   * - `onlyWithEhrNote`: Show only with EHR note.
   *
   * - `isBillable`: Show only billable.
   *
   * - `insuranceId`: The insurance Id.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFrom`: The date From.
   *
   * - `appointmentTypeId`: The appointment Type Id.
   *
   * @return Success
   */
  GetAppointmentInsurancesCount(params: AppointmentsService.GetAppointmentInsurancesCountParams): __Observable<ApiResponseAppointmentsCount> {
    return this.GetAppointmentInsurancesCountResponse(params).pipe(
      __map(_r => _r.body as ApiResponseAppointmentsCount)
    );
  }

  /**
   * The get video visit token async.
   * @param appointmentId The appointment id.
   * @return Success
   */
  GetVideoVisitTokenResponse(appointmentId?: number): __Observable<__StrictHttpResponse<ApiResponseCardTwilioToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (appointmentId != null) __params = __params.set('appointmentId', appointmentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Appointments/VideoVisits/Tokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCardTwilioToken>;
      })
    );
  }
  /**
   * The get video visit token async.
   * @param appointmentId The appointment id.
   * @return Success
   */
  GetVideoVisitToken(appointmentId?: number): __Observable<ApiResponseCardTwilioToken> {
    return this.GetVideoVisitTokenResponse(appointmentId).pipe(
      __map(_r => _r.body as ApiResponseCardTwilioToken)
    );
  }

  /**
   * The Generate Document for print.
   * @param params The `AppointmentsService.GenerateInsuranceAppointmentDocumentParams` containing the following parameters:
   *
   * - `providerIds`: The provider Ids.
   *
   * - `patientId`: The patient Id.
   *
   * - `patientDob`: The patient Dob.
   *
   * - `orderBy`:
   *
   * - `onlyWithoutClaims`: The only Without Claims.
   *
   * - `onlyWithEhrNote`: The only With EHR Note.
   *
   * - `isBillable`: The is Billable.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * - `insuranceId`: The insurance Id.
   *
   * - `documentType`: The document Type.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFrom`: The date From.
   *
   * - `appointmentTypeId`: The appointment Type Id.
   *
   * @return Success
   */
  GenerateInsuranceAppointmentDocumentResponse(params: AppointmentsService.GenerateInsuranceAppointmentDocumentParams): __Observable<__StrictHttpResponse<ApiResponseGenerateInsuranceAppointmentListDocumentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.providerIds || []).forEach(val => {if (val != null) __params = __params.append('providerIds', val.toString())});
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.patientDob != null) __params = __params.set('patientDob', params.patientDob.toString());
    if (params.orderBy != null) __params = __params.set('orderBy', params.orderBy.toString());
    if (params.onlyWithoutClaims != null) __params = __params.set('onlyWithoutClaims', params.onlyWithoutClaims.toString());
    if (params.onlyWithEhrNote != null) __params = __params.set('onlyWithEhrNote', params.onlyWithEhrNote.toString());
    if (params.isBillable != null) __params = __params.set('isBillable', params.isBillable.toString());
    if (params.isAscendingOrder != null) __params = __params.set('isAscendingOrder', params.isAscendingOrder.toString());
    if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
    if (params.documentType != null) __params = __params.set('documentType', params.documentType.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.appointmentTypeId != null) __params = __params.set('appointmentTypeId', params.appointmentTypeId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Appointments/GenerateDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseGenerateInsuranceAppointmentListDocumentResponse>;
      })
    );
  }
  /**
   * The Generate Document for print.
   * @param params The `AppointmentsService.GenerateInsuranceAppointmentDocumentParams` containing the following parameters:
   *
   * - `providerIds`: The provider Ids.
   *
   * - `patientId`: The patient Id.
   *
   * - `patientDob`: The patient Dob.
   *
   * - `orderBy`:
   *
   * - `onlyWithoutClaims`: The only Without Claims.
   *
   * - `onlyWithEhrNote`: The only With EHR Note.
   *
   * - `isBillable`: The is Billable.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * - `insuranceId`: The insurance Id.
   *
   * - `documentType`: The document Type.
   *
   * - `dateTo`: The date To.
   *
   * - `dateFrom`: The date From.
   *
   * - `appointmentTypeId`: The appointment Type Id.
   *
   * @return Success
   */
  GenerateInsuranceAppointmentDocument(params: AppointmentsService.GenerateInsuranceAppointmentDocumentParams): __Observable<ApiResponseGenerateInsuranceAppointmentListDocumentResponse> {
    return this.GenerateInsuranceAppointmentDocumentResponse(params).pipe(
      __map(_r => _r.body as ApiResponseGenerateInsuranceAppointmentListDocumentResponse)
    );
  }

  /**
   * Gets the appointment list report.
   * @param request The request.
   * @return Success
   */
  GetAppointmentListReportResponse(request?: AppointmentListReportRequest): __Observable<__StrictHttpResponse<ApiResponseAppointmentListReport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Appointments/AppointmentListReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentListReport>;
      })
    );
  }
  /**
   * Gets the appointment list report.
   * @param request The request.
   * @return Success
   */
  GetAppointmentListReport(request?: AppointmentListReportRequest): __Observable<ApiResponseAppointmentListReport> {
    return this.GetAppointmentListReportResponse(request).pipe(
      __map(_r => _r.body as ApiResponseAppointmentListReport)
    );
  }

  /**
   * Exports the appointment list report into an Excel file.
   * @param request The request.
   * @return Success
   */
  ExportAppointmentsListReportResponse(request?: AppointmentListReportExportRequest): __Observable<__StrictHttpResponse<ApiResponseString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Appointments/AppointmentsListReport/Excel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseString>;
      })
    );
  }
  /**
   * Exports the appointment list report into an Excel file.
   * @param request The request.
   * @return Success
   */
  ExportAppointmentsListReport(request?: AppointmentListReportExportRequest): __Observable<ApiResponseString> {
    return this.ExportAppointmentsListReportResponse(request).pipe(
      __map(_r => _r.body as ApiResponseString)
    );
  }
}

module AppointmentsService {

  /**
   * Parameters for GetAppointments
   */
  export interface GetAppointmentsParams {

    /**
     * The show Video Visit Only.
     */
    showVideoVisitOnly?: boolean;

    /**
     * The show Cancelled.
     */
    showCancelled?: boolean;

    /**
     * The provider Id.
     */
    providerIds?: Array<number>;

    /**
     * The patient Id.
     */
    patientId?: number;

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * The modified After Time In UTC.
     */
    modifiedAfterTimeInUtc?: string;

    /**
     * The location Id.
     */
    locationId?: number;

    /**
     * The is Physician Appointment.
     */
    isPhysicianAppointment?: boolean;

    /**
     * The date To In Local.
     */
    dateToInLocal?: string;

    /**
     * The date To.
     */
    dateTo?: string;

    /**
     * The date From In Local.
     */
    dateFromInLocal?: string;

    /**
     * The date From.
     */
    dateFrom?: string;
  }

  /**
   * Parameters for GetAppointmentInsurances
   */
  export interface GetAppointmentInsurancesParams {

    /**
     * The provider Ids.
     */
    providerIds?: Array<number>;

    /**
     * The patient Id.
     */
    patientId?: number;

    /**
     * The patient Date  of birth.
     */
    patientDob?: string;

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * The order By.
     */
    orderBy?: 'DateOfService' | 'PatientName' | 'AppointmentTypePurpose' | 'Provider' | 'InsuranceCompany' | 'IsBillable';

    /**
     * Show only without claims.
     */
    onlyWithoutClaims?: boolean;

    /**
     * Show only with EHR note.
     */
    onlyWithEhrNote?: boolean;

    /**
     * Show only billable.
     */
    isBillable?: boolean;

    /**
     * The is Ascending Order.
     */
    isAscendingOrder?: boolean;

    /**
     * The insurance Id.
     */
    insuranceId?: number;

    /**
     * The date To.
     */
    dateTo?: string;

    /**
     * The date From.
     */
    dateFrom?: string;

    /**
     * The appointment Type Id.
     */
    appointmentTypeId?: number;
  }

  /**
   * Parameters for UpdateAppointmentInsurances
   */
  export interface UpdateAppointmentInsurancesParams {

    /**
     * The insurance appointment id.
     */
    insuranceAppointmentId: number;

    /**
     * The request.
     */
    request?: InsuranceAppointmentCreateUpdateRequest;
  }

  /**
   * Parameters for GetAppointmentInsurancesCount
   */
  export interface GetAppointmentInsurancesCountParams {

    /**
     * The provider Ids.
     */
    providerIds?: Array<number>;

    /**
     * The patient Id.
     */
    patientId?: number;

    /**
     * The patient date of birth.
     */
    patientDob?: string;

    /**
     * Show only without claims.
     */
    onlyWithoutClaims?: boolean;

    /**
     * Show only with EHR note.
     */
    onlyWithEhrNote?: boolean;

    /**
     * Show only billable.
     */
    isBillable?: boolean;

    /**
     * The insurance Id.
     */
    insuranceId?: number;

    /**
     * The date To.
     */
    dateTo?: string;

    /**
     * The date From.
     */
    dateFrom?: string;

    /**
     * The appointment Type Id.
     */
    appointmentTypeId?: number;
  }

  /**
   * Parameters for GenerateInsuranceAppointmentDocument
   */
  export interface GenerateInsuranceAppointmentDocumentParams {

    /**
     * The provider Ids.
     */
    providerIds?: Array<number>;

    /**
     * The patient Id.
     */
    patientId?: number;

    /**
     * The patient Dob.
     */
    patientDob?: string;
    orderBy?: 'DateOfService' | 'PatientName' | 'AppointmentTypePurpose' | 'Provider' | 'InsuranceCompany' | 'IsBillable';

    /**
     * The only Without Claims.
     */
    onlyWithoutClaims?: boolean;

    /**
     * The only With EHR Note.
     */
    onlyWithEhrNote?: boolean;

    /**
     * The is Billable.
     */
    isBillable?: boolean;

    /**
     * The is Ascending Order.
     */
    isAscendingOrder?: boolean;

    /**
     * The insurance Id.
     */
    insuranceId?: number;

    /**
     * The document Type.
     */
    documentType?: 'Pdf' | 'Xlsx';

    /**
     * The date To.
     */
    dateTo?: string;

    /**
     * The date From.
     */
    dateFrom?: string;

    /**
     * The appointment Type Id.
     */
    appointmentTypeId?: number;
  }
}

export { AppointmentsService }
