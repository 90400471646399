/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseAuditReportViewEntryResponse } from '../models/api-response-audit-report-view-entry-response';
import { ApiResponseIEnumerableString } from '../models/api-response-ienumerable-string';
@Injectable({
  providedIn: 'root',
})
class AuditRecordsService extends __BaseService {
  static readonly GetAuditRecordsPath = '/AuditRecords';
  static readonly GetEntityTypesPath = '/AuditEntityTypes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get audit records.
   * @param params The `AuditRecordsService.GetAuditRecordsParams` containing the following parameters:
   *
   * - `UserId`: Gets or sets the user id.
   *
   * - `ToEntryDate`: Gets or sets to entry date (UTC).
   *
   * - `PageSize`: Gets or sets the size of the page.
   *
   * - `PageIndex`: Gets or sets the index of the page.
   *
   * - `OrderDirection`: Gets or sets the order direction.
   *
   * - `OrderBy`: Gets or sets the order by field.
   *
   * - `LocationId`: Gets or sets the location id.
   *
   * - `FromEntryDate`: Gets or sets from entry date (UTC).
   *
   * - `EntityTypes`: Gets or sets the entity types.
   *
   * @return Success
   */
  GetAuditRecordsResponse(params: AuditRecordsService.GetAuditRecordsParams): __Observable<__StrictHttpResponse<ApiResponseAuditReportViewEntryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UserId != null) __params = __params.set('UserId', params.UserId.toString());
    if (params.ToEntryDate != null) __params = __params.set('ToEntryDate', params.ToEntryDate.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageIndex != null) __params = __params.set('PageIndex', params.PageIndex.toString());
    if (params.OrderDirection != null) __params = __params.set('OrderDirection', params.OrderDirection.toString());
    if (params.OrderBy != null) __params = __params.set('OrderBy', params.OrderBy.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.FromEntryDate != null) __params = __params.set('FromEntryDate', params.FromEntryDate.toString());
    (params.EntityTypes || []).forEach(val => {if (val != null) __params = __params.append('EntityTypes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AuditRecords`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAuditReportViewEntryResponse>;
      })
    );
  }
  /**
   * The get audit records.
   * @param params The `AuditRecordsService.GetAuditRecordsParams` containing the following parameters:
   *
   * - `UserId`: Gets or sets the user id.
   *
   * - `ToEntryDate`: Gets or sets to entry date (UTC).
   *
   * - `PageSize`: Gets or sets the size of the page.
   *
   * - `PageIndex`: Gets or sets the index of the page.
   *
   * - `OrderDirection`: Gets or sets the order direction.
   *
   * - `OrderBy`: Gets or sets the order by field.
   *
   * - `LocationId`: Gets or sets the location id.
   *
   * - `FromEntryDate`: Gets or sets from entry date (UTC).
   *
   * - `EntityTypes`: Gets or sets the entity types.
   *
   * @return Success
   */
  GetAuditRecords(params: AuditRecordsService.GetAuditRecordsParams): __Observable<ApiResponseAuditReportViewEntryResponse> {
    return this.GetAuditRecordsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseAuditReportViewEntryResponse)
    );
  }

  /**
   * The get entity types.
   * @return Success
   */
  GetEntityTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AuditEntityTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableString>;
      })
    );
  }
  /**
   * The get entity types.
   * @return Success
   */
  GetEntityTypes(): __Observable<ApiResponseIEnumerableString> {
    return this.GetEntityTypesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableString)
    );
  }
}

module AuditRecordsService {

  /**
   * Parameters for GetAuditRecords
   */
  export interface GetAuditRecordsParams {

    /**
     * Gets or sets the user id.
     */
    UserId?: number;

    /**
     * Gets or sets to entry date (UTC).
     */
    ToEntryDate?: string;

    /**
     * Gets or sets the size of the page.
     */
    PageSize?: number;

    /**
     * Gets or sets the index of the page.
     */
    PageIndex?: number;

    /**
     * Gets or sets the order direction.
     */
    OrderDirection?: 'Ascending' | 'Descending';

    /**
     * Gets or sets the order by field.
     */
    OrderBy?: 'EntryDate' | 'FriendlyMessage';

    /**
     * Gets or sets the location id.
     */
    LocationId?: number;

    /**
     * Gets or sets from entry date (UTC).
     */
    FromEntryDate?: string;

    /**
     * Gets or sets the entity types.
     */
    EntityTypes?: Array<'Estimate' | 'Invoice' | 'Payment' | 'Deposit' | 'Refund' | 'CreditNote' | 'InventoryAdjustment' | 'TransactionTypeAlias' | 'Account' | 'GiftCard' | 'MembershipProgram' | 'Membership' | 'PatientStatement' | 'Appointment' | 'Item' | 'PatientCredit' | 'InsuranceCase' | 'InsuranceClaim' | 'InsuranceCharge' | 'InsurancePayment' | 'Remittance' | 'User' | 'SecurityGroup' | 'UserLogin' | 'UserLogout' | 'AutoLogout' | 'Document' | 'IncomingDocument' | 'ActivityNote' | 'CalendarBlocksActivation' | 'CalendarBlocksActivationRequest' | 'PatientFileExportRequest' | 'PracticeLicenseChange' | 'UserLicenseAssigned' | 'UserLicenseUnassigned' | 'TerminalLog' | 'Kiosk' | 'ConsentForm'>;
  }
}

export { AuditRecordsService }
