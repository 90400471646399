import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, booleanAttribute, inject } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'symplast-bottom-bar',
    standalone: true,
    imports: [NgClass],
    template: `
        <div class="symplast-bottom-bar" [ngClass]="'symplast-bottom-bar--align-' + align">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            :host {
                position: sticky;
                bottom: 0;
                left: 0;
                right: 100%;
                width: 100%;
                z-index: 10;
                margin-top: auto;
            }

            .symplast-bottom-bar {
                display: flex;
                box-sizing: border-box;
                padding: 10px;
                border-top: 1px solid var(--color-divider);
                background-color: var(--color-white);
            }

            .symplast-bottom-bar--align-left {
                justify-content: flex-start;
            }

            .symplast-bottom-bar--align-right {
                justify-content: flex-end;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomBarComponent implements AfterViewInit {
    @Input() align: 'left' | 'right' = 'right';
    @Input({ transform: booleanAttribute }) customParentStyles = false;

    private elementRef = inject(ElementRef);
    private renderer = inject(Renderer2);

    public ngAfterViewInit(): void {
        const parentElement = this.elementRef.nativeElement.parentElement;

        if (parentElement && !this.customParentStyles) {
            this.renderer.addClass(parentElement, 'symplast-bottom-bar-parent');
        }
    }
}
