import { Injectable } from '@angular/core';
import { PatientsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadGenders, ReloadGenders } from './genders.actions';
import { IGendersStateModel } from './genders.model';

@State<IGendersStateModel>({
    name: 'Genders',
    defaults: {
        genders: [],
        loading: false,
    },
})
@Injectable()
export class GendersState {
    constructor(private patientService: PatientsService) {}

    @Action(LoadGenders)
    public load(context: StateContext<IGendersStateModel>, { refresh }: LoadGenders) {
        if (!context.getState().genders.length || refresh) {
            context.patchState({ loading: true });
            this.patientService
                .GetGenders()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    context.patchState({
                        genders: [...response.result],
                    });
                });
        }
    }

    @Action(ReloadGenders)
    public reload(context: StateContext<IGendersStateModel>) {
        context.dispatch(new LoadGenders(true));
    }
}
