/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTenantIntegrationResponse } from '../models/api-response-ienumerable-tenant-integration-response';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { TenantIntegrationUpdateRequest } from '../models/tenant-integration-update-request';
import { ApiResponseInfrastructureTenantResponse } from '../models/api-response-infrastructure-tenant-response';
import { WooCommerceSettingsUpdateRequest } from '../models/woo-commerce-settings-update-request';
import { ApiResponseIEnumerableIntakeProviderDescription } from '../models/api-response-ienumerable-intake-provider-description';
@Injectable({
    providedIn: 'root',
})
class TenantIntegrationsService extends __BaseService {
    static readonly GetTenantIntegrationsPath = '/tenantIntegrations';
    static readonly UpdateTenantIntegrationPath = '/tenants/{tenantId}/tenantIntegrations';
    static readonly UpdateWooCommerceSettingsPath = '/tenants/{tenantId}/infrastructureSettings/wooCommerce';
    static readonly GetAvailableIntakeProvidersPath = '/tenants/{tenantId}/tenantIntegrations/intakeProviders';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets all integrations for a tenant
     * @return Success
     */
    GetTenantIntegrationsResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTenantIntegrationResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenantIntegrations`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableTenantIntegrationResponse>;
            }),
        );
    }
    /**
     * Gets all integrations for a tenant
     * @return Success
     */
    GetTenantIntegrations(): __Observable<ApiResponseIEnumerableTenantIntegrationResponse> {
        return this.GetTenantIntegrationsResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableTenantIntegrationResponse));
    }

    /**
     * Modifies the tenant integration status
     * @param params The `TenantIntegrationsService.UpdateTenantIntegrationParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `tenantIntegrationUpdateRequest`: The tenant integration update request.
     *
     * @return Success
     */
    UpdateTenantIntegrationResponse(
        params: TenantIntegrationsService.UpdateTenantIntegrationParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.tenantIntegrationUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/tenantIntegrations`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies the tenant integration status
     * @param params The `TenantIntegrationsService.UpdateTenantIntegrationParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `tenantIntegrationUpdateRequest`: The tenant integration update request.
     *
     * @return Success
     */
    UpdateTenantIntegration(params: TenantIntegrationsService.UpdateTenantIntegrationParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateTenantIntegrationResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Modifies WooCommerce settings of the tenant
     * @param params The `TenantIntegrationsService.UpdateWooCommerceSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `wooCommerceSettingsUpdateRequest`: The WooCommerce settings update request.
     *
     * @return Success
     */
    UpdateWooCommerceSettingsResponse(
        params: TenantIntegrationsService.UpdateWooCommerceSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.wooCommerceSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/wooCommerce`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies WooCommerce settings of the tenant
     * @param params The `TenantIntegrationsService.UpdateWooCommerceSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `wooCommerceSettingsUpdateRequest`: The WooCommerce settings update request.
     *
     * @return Success
     */
    UpdateWooCommerceSettings(
        params: TenantIntegrationsService.UpdateWooCommerceSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateWooCommerceSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Gets the available intake providers for a tenant.
     * @param tenantId The tenant id.
     * @return Success
     */
    GetAvailableIntakeProvidersResponse(
        tenantId: number,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableIntakeProviderDescription>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/tenants/${encodeURIComponent(String(tenantId))}/tenantIntegrations/intakeProviders`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableIntakeProviderDescription>;
            }),
        );
    }
    /**
     * Gets the available intake providers for a tenant.
     * @param tenantId The tenant id.
     * @return Success
     */
    GetAvailableIntakeProviders(tenantId: number): __Observable<ApiResponseIEnumerableIntakeProviderDescription> {
        return this.GetAvailableIntakeProvidersResponse(tenantId).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerableIntakeProviderDescription),
        );
    }
}

module TenantIntegrationsService {
    /**
     * Parameters for UpdateTenantIntegration
     */
    export interface UpdateTenantIntegrationParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The tenant integration update request.
         */
        tenantIntegrationUpdateRequest?: TenantIntegrationUpdateRequest;
    }

    /**
     * Parameters for UpdateWooCommerceSettings
     */
    export interface UpdateWooCommerceSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The WooCommerce settings update request.
         */
        wooCommerceSettingsUpdateRequest?: WooCommerceSettingsUpdateRequest;
    }
}

export { TenantIntegrationsService };
