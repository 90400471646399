import { Brand } from '@symplast/generated-clients/web-portal';

export class LoadBrands {
    public static readonly type = '[Brands] Load';
    constructor(public refresh?: boolean) {}
}

export class ReloadBrands {
    public static readonly type = '[Brands] Reload';
}

export class UpdateBrand {
    public static readonly type = '[Brands] Update';
    constructor(public brandId: string, public name: string) {}
}

export class UpdateIsEditMode {
    public static readonly type = '[Brands] Update IsEditMode';
    constructor(public brandId: string, public isEditMode: boolean) {}
}

export class DeleteBrand {
    public static readonly type = '[Brands] Delete';
    constructor(public brand: Brand) {}
}

export class ShowHideAddBrand {
    public static readonly type = '[Brands] Show Hide Add';
    constructor(public showAddBrand: boolean) {}
}

export class AddBrand {
    public static readonly type = '[Brands] Add';
    constructor(public brandName: string) {}
}
