/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponsePaymentIntegrationSettings } from '../models/api-response-payment-integration-settings';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PaymentIntegrationSettingsRequest } from '../models/payment-integration-settings-request';
import { ApiResponsePracticeSettings } from '../models/api-response-practice-settings';
import { PracticeSettingsRequest } from '../models/practice-settings-request';
import { TippingSettingsRequest } from '../models/tipping-settings-request';
@Injectable({
  providedIn: 'root',
})
class PracticeSettingsService extends __BaseService {
  static readonly GetPaymentIntegrationSettingsPath = '/PracticeSettings/PaymentIntegrationSettings';
  static readonly UpdatePaymentIntegrationSettingsPath = '/PracticeSettings/PaymentIntegrationSettings';
  static readonly CreatePaymentIntegrationSettingsPath = '/PracticeSettings/PaymentIntegrationSettings';
  static readonly GetPracticeSettingsPath = '/PracticeSettings';
  static readonly UpdatePracticeSettingsPath = '/PracticeSettings';
  static readonly UpdateTippingSettingsPath = '/PracticeSettings/TippingSettings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the payment integration settings.
   * @return Success
   */
  GetPaymentIntegrationSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponsePaymentIntegrationSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PracticeSettings/PaymentIntegrationSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePaymentIntegrationSettings>;
      })
    );
  }
  /**
   * Get the payment integration settings.
   * @return Success
   */
  GetPaymentIntegrationSettings(): __Observable<ApiResponsePaymentIntegrationSettings> {
    return this.GetPaymentIntegrationSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponsePaymentIntegrationSettings)
    );
  }

  /**
   * Update payment integration settings.
   * @param paymentIntegrationSettingsRequest The payment integration settings request.
   * @return Success
   */
  UpdatePaymentIntegrationSettingsResponse(paymentIntegrationSettingsRequest?: Array<PaymentIntegrationSettingsRequest>): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = paymentIntegrationSettingsRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PracticeSettings/PaymentIntegrationSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update payment integration settings.
   * @param paymentIntegrationSettingsRequest The payment integration settings request.
   * @return Success
   */
  UpdatePaymentIntegrationSettings(paymentIntegrationSettingsRequest?: Array<PaymentIntegrationSettingsRequest>): __Observable<ApiResponseOkResponse> {
    return this.UpdatePaymentIntegrationSettingsResponse(paymentIntegrationSettingsRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Create payment integration settings.
   * @param paymentIntegrationSettingsRequest The payment integration settings request.
   * @return Success
   */
  CreatePaymentIntegrationSettingsResponse(paymentIntegrationSettingsRequest?: PaymentIntegrationSettingsRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = paymentIntegrationSettingsRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/PracticeSettings/PaymentIntegrationSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Create payment integration settings.
   * @param paymentIntegrationSettingsRequest The payment integration settings request.
   * @return Success
   */
  CreatePaymentIntegrationSettings(paymentIntegrationSettingsRequest?: PaymentIntegrationSettingsRequest): __Observable<ApiResponseOkResponse> {
    return this.CreatePaymentIntegrationSettingsResponse(paymentIntegrationSettingsRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the practice settings.
   * @return Success
   */
  GetPracticeSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponsePracticeSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PracticeSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePracticeSettings>;
      })
    );
  }
  /**
   * Gets the practice settings.
   * @return Success
   */
  GetPracticeSettings(): __Observable<ApiResponsePracticeSettings> {
    return this.GetPracticeSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponsePracticeSettings)
    );
  }

  /**
   * Updates the practice settings.
   * @param practiceSettingsUpdateRequest The practice settings update request.
   * @return Success
   */
  UpdatePracticeSettingsResponse(practiceSettingsUpdateRequest?: PracticeSettingsRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = practiceSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PracticeSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates the practice settings.
   * @param practiceSettingsUpdateRequest The practice settings update request.
   * @return Success
   */
  UpdatePracticeSettings(practiceSettingsUpdateRequest?: PracticeSettingsRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdatePracticeSettingsResponse(practiceSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates the tipping settings.
   * @param tippingSettingsUpdateRequest The tipping settings update request.
   * @return Success
   */
  UpdateTippingSettingsResponse(tippingSettingsUpdateRequest?: TippingSettingsRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tippingSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PracticeSettings/TippingSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates the tipping settings.
   * @param tippingSettingsUpdateRequest The tipping settings update request.
   * @return Success
   */
  UpdateTippingSettings(tippingSettingsUpdateRequest?: TippingSettingsRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateTippingSettingsResponse(tippingSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module PracticeSettingsService {
}

export { PracticeSettingsService }
