import { Selector } from '@ngxs/store';
import { IPaymentReasonsStateModel } from './payment-reasons.model';
import { PaymentReasonsState } from './payment-reasons.state';

export class PaymentReasonsSelectors {
    @Selector([PaymentReasonsState])
    public static loading(state: IPaymentReasonsStateModel): boolean {
        return state.loading;
    }

    @Selector([PaymentReasonsState])
    public static paymentReasons(state: IPaymentReasonsStateModel): IPaymentReasonsStateModel['paymentReasons'] {
        return state.paymentReasons;
    }
}
