import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import {
    City,
    Country,
    LocationShort,
    LocationType,
    State,
    TimeZone,
    AddressService,
    LocationsService,
    ApiResponseIEnumerableState,
} from '@symplast/generated-clients/web-portal';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import {
    LoadLocations,
    LoadLocationTypes,
    LoadCountries,
    LoadTimezones,
    LocationsSelectors,
    LocationTypesSelectors,
    CountriesSelectors,
    TimezonesSelectors,
    StatesSelectors,
} from './../stores';
import { LoadStates } from '../stores/states';
import { getSearchTextRegExp } from '@symplast/utils';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    isLoading = false;
    countries: Country[] = [];
    states: State[] = [];
    timeZones: TimeZone[] = [];
    locationTypes: LocationType[] = [];
    selectedCity?: City;
    showInactive = false;
    notificationSubscription?: Subscription;

    private _logoChanged$: Subject<string> = new Subject();
    private _cityWithZipsSubject$: BehaviorSubject<City[]> = new BehaviorSubject([]);
    private _locationList: LocationShort[] = [];
    private _cities: City[] = [];

    constructor(
        private locationsApiService: LocationsService,
        private addressApiService: AddressService,
        private notificationService: NotificationService,
        private store: Store,
    ) {
        this.getInitializationData();
    }

    public set cities(newCities: City[]) {
        this._cityWithZipsSubject$.next([]);
        this._cities = newCities;
    }

    public get cities(): City[] {
        return this._cities;
    }

    public get logoChanged$(): Observable<string> {
        return this._logoChanged$.asObservable();
    }

    public get citiesWithZipCodes$(): Observable<City[]> {
        return this._cityWithZipsSubject$.asObservable();
    }

    public set locationList(val) {
        if (val) {
            this._locationList = [...val].sort((a, b) => {
                return Number(b['isActive']) - Number(a['isActive']) || a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        }
    }

    public get locationList(): LocationShort[] {
        return this._locationList;
    }

    public get noCititesLoaded(): boolean {
        return !this.cities || (this.cities && !this.cities.length);
    }

    public get noCitySuggestions(): boolean {
        return !this._cityWithZipsSubject$.value.length;
    }

    getInitializationData() {
        this.isLoading = true;
        this.store.dispatch(new LoadLocations());
        this.store.dispatch(new LoadLocationTypes());
        this.store.dispatch(new LoadCountries());
        this.store.dispatch(new LoadTimezones());

        const multLoadings$ = combineLatest([
            this.store.select(LocationsSelectors.loading),
            this.store.select(LocationTypesSelectors.loading),
            this.store.select(CountriesSelectors.loading),
            this.store.select(TimezonesSelectors.loading),
        ]).pipe(
            filter((loadings) => !loadings.some(Boolean)),
            take(1),
        );

        multLoadings$.subscribe(() => {
            this.locationList = this.store.selectSnapshot(LocationsSelectors.locations);
            this.locationTypes = this.store.selectSnapshot(LocationTypesSelectors.locationTypes);
            this.countries = this.store.selectSnapshot(CountriesSelectors.countries);
            this.timeZones = this.store.selectSnapshot(TimezonesSelectors.timezones);

            this.isLoading = false;
        });
    }

    getLocationsShortList(inactive?: boolean): void {
        this.showInactive = inactive ?? false;
        this.isLoading = true;
        this.locationsApiService.GetLocationsList(inactive).subscribe((res) => {
            if (res.statusCode === 200) {
                this.locationList = res.result ?? [];
            }
            this.isLoading = false;
        });
    }

    adjustStatesAndCitiesOnChange(fGroup: FormGroup, fgName: string, controlName: string, changes?: any): void {
        const val = fGroup.controls[controlName].value;

        if (controlName === 'countryId') {
            this.getStatesByCountry(val);
            fGroup.controls.stateId.patchValue(null, { emitEvent: false });
            fGroup.controls.cityId.patchValue(null, { emitEvent: false });
            fGroup.controls.city.patchValue('', { emitEvent: false });
            fGroup.controls.zipCode.patchValue('', { emitEvent: false });
            fGroup.controls.city.disable({ emitEvent: false });
            fGroup.controls.zipCode.disable({ emitEvent: false });
            if (changes) {
                delete changes[fgName]['stateId'];
                delete changes[fgName]['city'];
                delete changes[fgName]['zipCode'];
            }
            this._cityWithZipsSubject$.next([]);
            this.selectedCity = undefined;
        }

        if (controlName === 'stateId') {
            this.getCitiesByStatesAndZipCode(val);
            if (changes) {
                delete changes[fgName]['city'];
                delete changes[fgName]['zipCode'];
            }
            fGroup.controls.cityId.patchValue(null, { emitEvent: false });
            fGroup.controls.city.patchValue('', { emitEvent: false });
            fGroup.controls.zipCode.patchValue('', { emitEvent: false });
            fGroup.controls.city.enable({ emitEvent: false });
            fGroup.controls.zipCode.enable({ emitEvent: false });
            this._cityWithZipsSubject$.next([]);
            this.selectedCity = undefined;
        }
    }

    updateCitySuggestionsList(event: KeyboardEvent, fGroup: FormGroup, controlName: string): void {
        let val = (event.target as any).value;
        let resultArr = [];

        if (controlName === 'city') {
            resultArr = this.cities.filter((city) => city.name?.match(getSearchTextRegExp(val)));
            this._cityWithZipsSubject$.next(resultArr);

            fGroup.controls.cityId.patchValue(null, { emitEvent: false });
            fGroup.controls.zipCode.patchValue('', { emitEvent: false });
        } else if (controlName === 'zipCode') {
            val = String(val);
            if (val.match(/-/)) {
                val = val.split('-')[0];
            }

            resultArr = this.cities.filter((city) => city.zipCode?.match(getSearchTextRegExp(val)));
            this._cityWithZipsSubject$.next(resultArr);

            fGroup.controls.cityId.patchValue(null, { emitEvent: false });
            fGroup.controls.city.patchValue('', { emitEvent: false });
        }
    }

    showNoCitySuggestionsError(control: AbstractControl): boolean {
        return (
            this.noCitySuggestions &&
            control &&
            control.dirty &&
            !control.hasError('required') &&
            !control.hasError('pattern') &&
            !this.showNoStateSelectedError(control)
        );
    }

    showNoStateSelectedError(control: AbstractControl): boolean {
        return (
            this.noCititesLoaded &&
            !(control.parent as FormGroup).controls.stateId.value &&
            !control.hasError('required') &&
            !control.hasError('pattern') &&
            control.dirty
        );
    }

    setSelectedCity(city: City, addressFg: FormGroup, event: MatOptionSelectionChange): void {
        if (event.source.selected) {
            this.selectedCity = city;
            addressFg.controls.cityId.patchValue(city.id);
            addressFg.controls.city.patchValue(city.name, { emitEvent: false });
            addressFg.controls.zipCode.patchValue(city.zipCode, { emitEvent: false });
        }
    }

    resetCitiesSuggestions(): void {
        this._cityWithZipsSubject$.next([]);
    }

    getStatesByCountry(countryId: number): void {
        this.isLoading = true;
        this.store.dispatch(new LoadStates(countryId));

        this.store
            .select(StatesSelectors.loading)
            .pipe(
                filter((loading) => !loading),
                take(1),
            )
            .subscribe((_) => {
                this.states = this.store.selectSnapshot(StatesSelectors.states)[countryId];
                this.cities = [];
                this.isLoading = false;
            });
    }

    getCitiesByStatesAndZipCode(stateId: number, zipCode?: string, fGroup?: FormGroup): void {
        this.isLoading = true;
        const params: AddressService.GetCitiesListParams = {
            stateId,
        };

        if (zipCode) {
            params.zipCode = zipCode.replace(/\s/g, '');
        }

        this.addressApiService.GetCitiesList(params).subscribe((res) => {
            if (res.statusCode === 200) {
                this.cities = res.result ?? [];
            }
            this.isLoading = false;
        });
    }

    getCitiesAndStates(countryId: number, stateId: number): void {
        this.isLoading = true;
        const params: AddressService.GetCitiesListParams = {
            stateId,
        };

        this.store.dispatch(new LoadStates(countryId));

        const statesReq$: Observable<boolean> = this.store.select(StatesSelectors.loading).pipe(
            filter((loading) => !loading),
            take(1),
        );
        const citiesReq$: Observable<ApiResponseIEnumerableState> = this.addressApiService.GetCitiesList(params);

        forkJoin([statesReq$, citiesReq$]).subscribe(([_, citiesRes]) => {
            this.states = this.store.selectSnapshot(StatesSelectors.states)[countryId];

            if (citiesRes.statusCode === 200) {
                this.cities = citiesRes.result ?? [];
            }
            this.isLoading = false;
        });
    }

    deleteLocationById(locationId: number): void {
        this.isLoading = true;
        this.locationsApiService.DeleteLocation(locationId).subscribe((res) => {
            if (res.statusCode === 200) {
                this.getLocationsShortList();
            }
            this.store.dispatch(new LoadLocations(true));
        });
    }

    triggerLogoChanged(newUrl: string): void {
        this._logoChanged$.next(newUrl);
    }

    subscribeToApiErrors(): void {
        this.notificationSubscription = this.notificationService.currentNotifications$.subscribe((notification) => {
            if (notification.length) {
                this.isLoading = false;
            }
        });
    }

    unsubscribeApiErrors(): void {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
    }
}
