import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { Device } from '@symplast/models/shared';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {
    public scrollProgress$!: Observable<number>;
    public embeddedCookie = 'embedded';
    public showPatientSearch$ = new BehaviorSubject<boolean>(true);
    public isBackgroundLoading$: Observable<boolean>;
    public isLoading$: Observable<boolean>;
    private isLoadingSource$ = new BehaviorSubject<boolean>(false);

    private _isBackgroundLoading$ = new BehaviorSubject<boolean>(false);
    private _scrollReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private cookieService: CookieService) {
        this.isBackgroundLoading$ = this._isBackgroundLoading$.asObservable();
        this.isLoading$ = this.isLoadingSource$.asObservable();
    }

    public get scrollReady$(): Observable<boolean> {
        return this._scrollReady$.asObservable();
    }

    public get device(): Device {
        if (/SymplastWindowsApplication/g.test(window.navigator.userAgent)) {
            return Device.Windows;
        }
        if (/SymplastAndroidApplication/g.test(window.navigator.userAgent)) {
            return Device.Android;
        }
        if (/SymplastMacApplication/g.test(window.navigator.userAgent)) {
            return Device.Mac;
        }
        if (/SymplastiPadApplication/g.test(window.navigator.userAgent)) {
            return Device.iPad;
        }
        if (/SymplastiPhoneApplication/g.test(window.navigator.userAgent)) {
            return Device.iPhone;
        }
        if (/SymplastiOSApplication/g.test(window.navigator.userAgent)) {
            return Device.iOS;
        }
        const embeddedInfo = this.cookieService.get(this.embeddedCookie);

        if (embeddedInfo?.toLowerCase() === 'true') {
            return Device.EmbeddedView;
        }

        return Device.Browser;
    }

    public get isMobileDevice(): boolean {
        return this.device === Device.iPhone || this.device === Device.Android;
    }

    /**
     * If app opened in ios application
     */
    public IsWebView(): boolean {
        return this.device !== Device.Browser;
    }

    public setUpScrollProgressObservable(scroll$: Observable<Event>): void {
        this.scrollProgress$ = scroll$.pipe(
            throttleTime(200),
            map(({ target }) => this.updateScrollPercentage(target)),
        );
        this._scrollReady$.next(true);
    }

    public updateScrollPercentage(target: any): number {
        if (!target || target.scrollHeight - target.clientHeight === 0) {
            return 0;
        }

        return Math.round((target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100);
    }

    public setLoadingStatus(isLoading: boolean): void {
        this.isLoadingSource$.next(isLoading);
    }

    public setBackgroundLoadingStatus(isLoading: boolean): void {
        this._isBackgroundLoading$.next(isLoading);
    }
}
