import { Selector } from '@ngxs/store';
import { IEmploymentTypesStateModel } from './employment-types.model';
import { EmploymentTypesState } from './employment-types.state';

export class EmploymentTypesSelectors {
    @Selector([EmploymentTypesState])
    public static loading(state: IEmploymentTypesStateModel): boolean {
        return state.loading;
    }

    @Selector([EmploymentTypesState])
    public static employmentTypes(state: IEmploymentTypesStateModel): IEmploymentTypesStateModel['employmentTypes'] {
        return state.employmentTypes;
    }
}
