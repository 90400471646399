import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
    standalone: true,
})
export class HighlightPipe implements PipeTransform {
    transform(value: string, args: string): string {
        const searchText = args?.replace(/"/g, '');

        if (!searchText) {
            return value;
        }

        value = (value ?? '').toString().replace(/\s\s/g, ' ').trim();

        const regex = this.getRegExp(searchText);

        if (!regex) {
            return value;
        }

        const match = value.match(regex);

        if (match) {
            return value.replace(regex, `<span class='highlight'>${match[0]}</span>`);
        }

        const splittedSearchText = searchText.split(' ');
        let result = value;

        // For partial matching
        if (splittedSearchText.length > 1) {
            for (let i = 0; i < splittedSearchText.length; i++) {
                const partialRegex = this.getRegExp(splittedSearchText[i]);

                if (!partialRegex) {
                    continue;
                }
                const partialmatch = value.match(partialRegex);

                if (partialmatch) {
                    result = result.replace(partialRegex, `<span class='highlight'>${partialmatch[0]}</span>`);
                } else {
                    return value;
                }
            }

            return result;
        }

        return value;
    }

    private getRegExp(value: string): RegExp | null {
        const cleanValue = value?.trim().replace(/([^a-zA-Z0-9])/g, '\\$1');

        if (cleanValue) {
            return new RegExp(cleanValue, 'gi');
        }

        return null;
    }
}
