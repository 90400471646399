import { inject } from '@angular/core';
import { CurrentUserService } from '../../services/current-user.service';
import Bugsnag from '@bugsnag/js';
import { PrivateSettingsService } from '@symplast/services/shared';

export function bugsnagInitializerFactory(): Function {
    const currentUsersService = inject(CurrentUserService);
    const privateSettingsService = inject(PrivateSettingsService);

    return () => {
        Bugsnag.addOnError((event) => {
            const user = currentUsersService.user;

            if (user) {
                const userId = user.userId?.toString();
                const userName = user.globalUserName;
                const tenantName = privateSettingsService.CurrentTenantValue?.Name || '';

                event.setUser(userId, tenantName, userName);
            }
        });
    };
}
