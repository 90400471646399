import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
    name: 'estDate',
    standalone: true,
})
export class EstDatePipe implements PipeTransform {
    transform(dateString: string, format: string): string {
        const date = moment.utc(dateString);

        return date.tz('America/New_York').format(format);
    }
}
