/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionManagementGroup } from '../models/api-response-icollection-management-group';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ManagementGroupCreateUpdateRequest } from '../models/management-group-create-update-request';
import { ApiResponseManagementGroup } from '../models/api-response-management-group';
import { ApiResponseIEnumerableUserDetails } from '../models/api-response-ienumerable-user-details';
@Injectable({
  providedIn: 'root',
})
class ManagementGroupsService extends __BaseService {
  static readonly GetManagementGroupsPath = '/ManagementGroups';
  static readonly CreateManagementGroupPath = '/ManagementGroups';
  static readonly GetManagementGroupByIdPath = '/ManagementGroups/{managementGroupId}';
  static readonly UpdateManagementGroupPath = '/ManagementGroups/{managementGroupId}';
  static readonly DeleteManagementGroupPath = '/ManagementGroups/{managementGroupId}';
  static readonly GetAssigneeGroupsAvailableUsersPath = '/ManagementGroups/AvailableUsers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get management groups async.
   * @return Success
   */
  GetManagementGroupsResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionManagementGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ManagementGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionManagementGroup>;
      })
    );
  }
  /**
   * The get management groups async.
   * @return Success
   */
  GetManagementGroups(): __Observable<ApiResponseICollectionManagementGroup> {
    return this.GetManagementGroupsResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionManagementGroup)
    );
  }

  /**
   * The create management group async.
   * @param createRequest The create request.
   * @return Success
   */
  CreateManagementGroupResponse(createRequest?: ManagementGroupCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ManagementGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The create management group async.
   * @param createRequest The create request.
   * @return Success
   */
  CreateManagementGroup(createRequest?: ManagementGroupCreateUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.CreateManagementGroupResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get management group by id async.
   * @param managementGroupId The management group id.
   * @return Success
   */
  GetManagementGroupByIdResponse(managementGroupId: string): __Observable<__StrictHttpResponse<ApiResponseManagementGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ManagementGroups/${encodeURIComponent(String(managementGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseManagementGroup>;
      })
    );
  }
  /**
   * The get management group by id async.
   * @param managementGroupId The management group id.
   * @return Success
   */
  GetManagementGroupById(managementGroupId: string): __Observable<ApiResponseManagementGroup> {
    return this.GetManagementGroupByIdResponse(managementGroupId).pipe(
      __map(_r => _r.body as ApiResponseManagementGroup)
    );
  }

  /**
   * The update management group async.
   * @param params The `ManagementGroupsService.UpdateManagementGroupParams` containing the following parameters:
   *
   * - `managementGroupId`: The management Group Id.
   *
   * - `updateRequest`: The create request.
   *
   * @return Success
   */
  UpdateManagementGroupResponse(params: ManagementGroupsService.UpdateManagementGroupParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ManagementGroups/${encodeURIComponent(String(params.managementGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update management group async.
   * @param params The `ManagementGroupsService.UpdateManagementGroupParams` containing the following parameters:
   *
   * - `managementGroupId`: The management Group Id.
   *
   * - `updateRequest`: The create request.
   *
   * @return Success
   */
  UpdateManagementGroup(params: ManagementGroupsService.UpdateManagementGroupParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateManagementGroupResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The delete management group async.
   * @param managementGroupId The management Group Id.
   * @return Success
   */
  DeleteManagementGroupResponse(managementGroupId: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ManagementGroups/${encodeURIComponent(String(managementGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The delete management group async.
   * @param managementGroupId The management Group Id.
   * @return Success
   */
  DeleteManagementGroup(managementGroupId: string): __Observable<ApiResponseOkResponse> {
    return this.DeleteManagementGroupResponse(managementGroupId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get all.
   * @return Success
   */
  GetAssigneeGroupsAvailableUsersResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableUserDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ManagementGroups/AvailableUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableUserDetails>;
      })
    );
  }
  /**
   * The get all.
   * @return Success
   */
  GetAssigneeGroupsAvailableUsers(): __Observable<ApiResponseIEnumerableUserDetails> {
    return this.GetAssigneeGroupsAvailableUsersResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableUserDetails)
    );
  }
}

module ManagementGroupsService {

  /**
   * Parameters for UpdateManagementGroup
   */
  export interface UpdateManagementGroupParams {

    /**
     * The management Group Id.
     */
    managementGroupId: string;

    /**
     * The create request.
     */
    updateRequest?: ManagementGroupCreateUpdateRequest;
  }
}

export { ManagementGroupsService }
