export interface ICurrentUser {
    readonly userId: number;
    title?: string;
    displayName?: string;
    mobilePhone?: string;
    isMobilePhoneNumberConfirmed?: boolean;
    userImageThumbnailUrl?: string;
    mobilePhoneRequestDisabledUntilUtc?: Date | string;
    globalUserName?: string;
    doseSpotId: string;
    isAutoFileSharing: boolean;
    readonly patientSection?: string;
    readonly role?: string;
    readonly permissions?: string[];
    readonly licenses?: string[];
}

export class CurrentUser implements ICurrentUser {
    public readonly userId: number;
    public title?: string;
    public displayName?: string;
    public mobilePhone?: string;
    public isMobilePhoneNumberConfirmed?: boolean;
    public userImageThumbnailUrl?: string;
    public mobilePhoneRequestDisabledUntilUtc?: Date;
    public globalUserName?: string;
    public isAutoFileSharing: boolean;
    public readonly doseSpotId: string;
    public readonly patientSection?: string;
    public readonly role?: string;
    public readonly permissions?: string[];
    public readonly licenses?: string[];

    constructor(user: ICurrentUser) {
        Object.assign(this, user);
        this.mobilePhoneRequestDisabledUntilUtc = user.mobilePhoneRequestDisabledUntilUtc
            ? new Date(user.mobilePhoneRequestDisabledUntilUtc)
            : undefined;
    }

    public get isAdmin(): boolean {
        return this.hasRole('Admin');
    }

    public hasRole(role: string | string[]): boolean {
        return role === this.role || role.includes(this.role);
    }

    public hasPermission(permission: string): boolean {
        return this.permissions.some((p) => p.includes(permission));
    }

    public hasPermissions(permissions: string[]): boolean {
        return permissions.some((permission) => this.hasPermission(permission));
    }

    public hasLicense(license: string): boolean {
        return this.licenses.includes(license);
    }
}
