/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTax } from '../models/api-response-ienumerable-tax';
import { ApiResponseTax } from '../models/api-response-tax';
import { TaxCreateRequest } from '../models/tax-create-request';
import { TaxUpdateRequest } from '../models/tax-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class TaxesService extends __BaseService {
  static readonly GetTaxesPath = '/Taxes';
  static readonly CreateTaxPath = '/Taxes';
  static readonly UpdateTaxPath = '/Taxes/{taxId}';
  static readonly GetTaxPath = '/Taxes/{taxId}';
  static readonly DeleteTaxPath = '/Taxes/{taxId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all taxes.
   * @return Success
   */
  GetTaxesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTax>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTax>;
      })
    );
  }
  /**
   * Get all taxes.
   * @return Success
   */
  GetTaxes(): __Observable<ApiResponseIEnumerableTax> {
    return this.GetTaxesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTax)
    );
  }

  /**
   * Create a new tax.
   * @param taxCreateRequest The tax create request.
   * @return Success
   */
  CreateTaxResponse(taxCreateRequest?: TaxCreateRequest): __Observable<__StrictHttpResponse<ApiResponseTax>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = taxCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTax>;
      })
    );
  }
  /**
   * Create a new tax.
   * @param taxCreateRequest The tax create request.
   * @return Success
   */
  CreateTax(taxCreateRequest?: TaxCreateRequest): __Observable<ApiResponseTax> {
    return this.CreateTaxResponse(taxCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseTax)
    );
  }

  /**
   * Update an existing tax.
   * @param params The `TaxesService.UpdateTaxParams` containing the following parameters:
   *
   * - `taxId`: The tax id.
   *
   * - `taxUpdateRequest`: The tax update request.
   *
   * @return Success
   */
  UpdateTaxResponse(params: TaxesService.UpdateTaxParams): __Observable<__StrictHttpResponse<ApiResponseTax>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.taxUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Taxes/${encodeURIComponent(String(params.taxId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTax>;
      })
    );
  }
  /**
   * Update an existing tax.
   * @param params The `TaxesService.UpdateTaxParams` containing the following parameters:
   *
   * - `taxId`: The tax id.
   *
   * - `taxUpdateRequest`: The tax update request.
   *
   * @return Success
   */
  UpdateTax(params: TaxesService.UpdateTaxParams): __Observable<ApiResponseTax> {
    return this.UpdateTaxResponse(params).pipe(
      __map(_r => _r.body as ApiResponseTax)
    );
  }

  /**
   * Get a particular tax by ID.
   * @param taxId The tax id.
   * @return Success
   */
  GetTaxResponse(taxId: number): __Observable<__StrictHttpResponse<ApiResponseTax>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Taxes/${encodeURIComponent(String(taxId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTax>;
      })
    );
  }
  /**
   * Get a particular tax by ID.
   * @param taxId The tax id.
   * @return Success
   */
  GetTax(taxId: number): __Observable<ApiResponseTax> {
    return this.GetTaxResponse(taxId).pipe(
      __map(_r => _r.body as ApiResponseTax)
    );
  }

  /**
   * Deletes a tax by its ID.
   * @param taxId The tax id.
   * @return Success
   */
  DeleteTaxResponse(taxId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Taxes/${encodeURIComponent(String(taxId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a tax by its ID.
   * @param taxId The tax id.
   * @return Success
   */
  DeleteTax(taxId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteTaxResponse(taxId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module TaxesService {

  /**
   * Parameters for UpdateTax
   */
  export interface UpdateTaxParams {

    /**
     * The tax id.
     */
    taxId: number;

    /**
     * The tax update request.
     */
    taxUpdateRequest?: TaxUpdateRequest;
  }
}

export { TaxesService }
