/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseLegalText } from '../models/api-response-legal-text';
import { LegalTextRequest } from '../models/legal-text-request';
import { ApiResponseIEnumerableLegalText } from '../models/api-response-ienumerable-legal-text';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class LegalTextsService extends __BaseService {
  static readonly CreateLegalTextPath = '/LegalTexts';
  static readonly GetLegalTextsPath = '/LegalTexts';
  static readonly DeleteLegalTextPath = '/LegalTexts/{legalTextId}';
  static readonly GetLegalTextByIdPath = '/LegalTexts/{legalTextId}';
  static readonly UpdateLegalTextPath = '/LegalTexts/{legalTextId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates the legal text.
   * @param legalTextCreationRequest The legalText creation request.
   * @return Success or Created
   */
  CreateLegalTextResponse(legalTextCreationRequest?: LegalTextRequest): __Observable<__StrictHttpResponse<ApiResponseLegalText | ApiResponseLegalText>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = legalTextCreationRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/LegalTexts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseLegalText | ApiResponseLegalText>;
      })
    );
  }
  /**
   * Creates the legal text.
   * @param legalTextCreationRequest The legalText creation request.
   * @return Success or Created
   */
  CreateLegalText(legalTextCreationRequest?: LegalTextRequest): __Observable<ApiResponseLegalText | ApiResponseLegalText> {
    return this.CreateLegalTextResponse(legalTextCreationRequest).pipe(
      __map(_r => _r.body as ApiResponseLegalText | ApiResponseLegalText)
    );
  }

  /**
   * Gets the list of available legal texts.
   * @param includeDeleted The include Deleted.
   * @return Success
   */
  GetLegalTextsResponse(includeDeleted?: boolean): __Observable<__StrictHttpResponse<ApiResponseIEnumerableLegalText>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeDeleted != null) __params = __params.set('includeDeleted', includeDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/LegalTexts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableLegalText>;
      })
    );
  }
  /**
   * Gets the list of available legal texts.
   * @param includeDeleted The include Deleted.
   * @return Success
   */
  GetLegalTexts(includeDeleted?: boolean): __Observable<ApiResponseIEnumerableLegalText> {
    return this.GetLegalTextsResponse(includeDeleted).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableLegalText)
    );
  }

  /**
   * Deletes the legal text.
   * @param legalTextId The legalText identifier.
   * @return Success
   */
  DeleteLegalTextResponse(legalTextId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/LegalTexts/${encodeURIComponent(String(legalTextId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes the legal text.
   * @param legalTextId The legalText identifier.
   * @return Success
   */
  DeleteLegalText(legalTextId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteLegalTextResponse(legalTextId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the legal text by identifier.
   * @param legalTextId The legal text identifier.
   * @return Success
   */
  GetLegalTextByIdResponse(legalTextId: number): __Observable<__StrictHttpResponse<ApiResponseLegalText>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/LegalTexts/${encodeURIComponent(String(legalTextId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseLegalText>;
      })
    );
  }
  /**
   * Gets the legal text by identifier.
   * @param legalTextId The legal text identifier.
   * @return Success
   */
  GetLegalTextById(legalTextId: number): __Observable<ApiResponseLegalText> {
    return this.GetLegalTextByIdResponse(legalTextId).pipe(
      __map(_r => _r.body as ApiResponseLegalText)
    );
  }

  /**
   * Modifies the legal text.
   * @param params The `LegalTextsService.UpdateLegalTextParams` containing the following parameters:
   *
   * - `legalTextId`: The legalText identifier.
   *
   * - `legalTextUpdateRequest`: The legalText update request.
   *
   * @return Success
   */
  UpdateLegalTextResponse(params: LegalTextsService.UpdateLegalTextParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.legalTextUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/LegalTexts/${encodeURIComponent(String(params.legalTextId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Modifies the legal text.
   * @param params The `LegalTextsService.UpdateLegalTextParams` containing the following parameters:
   *
   * - `legalTextId`: The legalText identifier.
   *
   * - `legalTextUpdateRequest`: The legalText update request.
   *
   * @return Success
   */
  UpdateLegalText(params: LegalTextsService.UpdateLegalTextParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateLegalTextResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module LegalTextsService {

  /**
   * Parameters for UpdateLegalText
   */
  export interface UpdateLegalTextParams {

    /**
     * The legalText identifier.
     */
    legalTextId: number;

    /**
     * The legalText update request.
     */
    legalTextUpdateRequest?: LegalTextRequest;
  }
}

export { LegalTextsService }
