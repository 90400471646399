import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './shared/error/error.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AuthGuard } from './core/guards/auth-guard';
import { FeatureFlagGuard } from './services/guards/feature-flag.guard';
import { AppRoutePath } from './app.route-path';
import { LayoutComponent } from './layout/layout.component';
import { AuthenticationRoutePath } from './authentication/routes/authentication.route-path';
import { CompositeRouteGuard } from './core/guards/composite-route.guard';
import { AppConfigurationGuard } from './core/guards/app-configuration.guard';
import { CurrentUserGuard } from './core/guards/current-user.guard';
import { RolesGuard } from './services/guards/roles.guard';
import { PasswordResetRequiredGuard } from './core/guards/password-reset-required.guard';
import { PermissionGuard } from './services/guards/permission.guard';
import { InsuranceAccessService } from './core/services/insurance-access/insurance-access.service';
import { InsuranceAccessGuard } from './core/guards/insurance-access.guard';
import { InsuranceAccessPreset } from './core/services/insurance-access/insurance-access.consts';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [CompositeRouteGuard],
        data: { routeGuards: [AuthGuard, AppConfigurationGuard, CurrentUserGuard, PasswordResetRequiredGuard] },
        children: [
            {
                path: AppRoutePath.DASHBOARD,
                pathMatch: 'full',
                loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent),
                data: { title: 'Dashboard', role: 'Admin' },
                canActivate: [RolesGuard],
            },
            {
                path: AppRoutePath.REPORTS,
                loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
            },
            {
                path: AppRoutePath.MEDIA,
                loadChildren: () => import('./media/media.module').then((m) => m.MediaModule),
            },
            {
                path: AppRoutePath.SETTINGS,
                loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: AppRoutePath.INSURANCE,
                loadChildren: () => import('./insurance/insurance.module').then((m) => m.InsuranceModule),
                canActivate: [RolesGuard, InsuranceAccessGuard],
                data: { role: InsuranceAccessService.INSURANCE_RELATED_ROLES, insuranceAccessConfiguration: InsuranceAccessPreset.ENABLED },
            },
            {
                path: AppRoutePath.VIDEO_VISITS,
                loadChildren: () => import('./video-visit/video-visit.module').then((m) => m.VideoVisitModule),
            },
            {
                path: AppRoutePath.FORMS,
                canActivate: [FeatureFlagGuard],
                loadChildren: () => import('./symplast-forms/symplast-forms.module').then((m) => m.SymplastFormsModule),
                data: {
                    requiredFeatureFlag: 'symplast-forms',
                },
            },
            {
                path: AppRoutePath.PATIENTS,
                loadChildren: () => import('./patient/patient.module').then((m) => m.PatientModule),
            },
            {
                path: AppRoutePath.FILE_INBOX,
                loadChildren: () => import('./efax-inbox/routes/efax-inbox.routes').then((r) => r.ROUTES),
            },
            {
                path: AppRoutePath.FINANCIAL,
                loadChildren: () => import('./financial/routes/financial.routes').then((r) => r.FINANCIAL_ROUTES),
            },
            {
                path: AppRoutePath.KIOSKS,
                canActivate: [PermissionGuard],
                loadComponent: () => import('./kiosk/kiosk.component').then((c) => c.KioskComponent),
                data: { hideFeatureToggle: true },
            },
            {
                path: AppRoutePath.RTE,
                loadComponent: () => import('./rich-text-editor/rte-embedded.component').then((c) => c.RTEEmbeddedComponent),
            },
            {
                path: '',
                pathMatch: 'full',
                component: HomePageComponent,
                data: { title: 'Home' },
            },
        ],
    },
    {
        path: AppRoutePath.AUTHENTICATION,
        loadChildren: () => import('./authentication/routes/authentication.routes').then((m) => m.AUTHENTICATION_ROUTES),
    },
    { path: AppRoutePath.ERROR, component: ErrorComponent },
    // FIXME: (a.vakhrushin) Authentication route redirects. Remove them when urls are changed everywhere
    { path: AuthenticationRoutePath.LOGIN, redirectTo: `${AppRoutePath.AUTHENTICATION}/${AuthenticationRoutePath.LOGIN}` },
    {
        path: AuthenticationRoutePath.FORGOT_PASSWORD,
        redirectTo: `${AppRoutePath.AUTHENTICATION}/${AuthenticationRoutePath.FORGOT_PASSWORD}`,
    },
    {
        path: `${AuthenticationRoutePath.RESET_PASSWORD}/:code`,
        redirectTo: `${AppRoutePath.AUTHENTICATION}/${AuthenticationRoutePath.RESET_PASSWORD}/:code`,
    },
    {
        path: `${AuthenticationRoutePath.NEW_USER_PASSWORD}/:code`,
        redirectTo: `${AppRoutePath.AUTHENTICATION}/${AuthenticationRoutePath.NEW_USER_PASSWORD}/:code`,
    },
    {
        path: `${AuthenticationRoutePath.MOBILE_CONFIRM}/:code`,
        redirectTo: `${AppRoutePath.AUTHENTICATION}/${AuthenticationRoutePath.MOBILE_CONFIRM}/:code`,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
