import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelper } from '@symplast/utils';

@Pipe({
    name: 'symplastDate',
    standalone: true,
})
/**
 * Transform Date into standart date format
 */
export class SymplastDatePipe implements PipeTransform {
    public transform(value: string | Date, format: 'date' | 'datetime' | string, timezoneShift: boolean): string | Date | undefined {
        if (!value) {
            return undefined;
        }

        if (format === 'date' && timezoneShift) {
            return DateTimeHelper.displayLocalDate(value);
        } else if (format === 'datetime' && timezoneShift) {
            return DateTimeHelper.displayLocalDateTime(value);
        } else if (format === 'date' && !timezoneShift) {
            return DateTimeHelper.displayUtcDate(value);
        } else if (format === 'datetime' && !timezoneShift) {
            return DateTimeHelper.displayUtcDateTime(value);
        } else if (format) {
            return DateTimeHelper.format(value, format);
        }

        return value;
    }
}
