import { Selector } from '@ngxs/store';
import { IGendersStateModel } from './genders.model';
import { GendersState } from './genders.state';

export class GendersSelectors {
    @Selector([GendersState])
    public static loading(state: IGendersStateModel): boolean {
        return state.loading;
    }

    @Selector([GendersState])
    public static genders(state: IGendersStateModel): IGendersStateModel['genders'] {
        return state.genders;
    }
}
