/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseDoseSpotSettings } from '../models/api-response-dose-spot-settings';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { DoseSpotSettings } from '../models/dose-spot-settings';
import { ApiResponseDoseSpotNotificationsResponse } from '../models/api-response-dose-spot-notifications-response';
import { ApiResponseString } from '../models/api-response-string';
import { DoseSpotPatientUpdateRequest } from '../models/dose-spot-patient-update-request';
@Injectable({
  providedIn: 'root',
})
class DoseSpotService extends __BaseService {
  static readonly GetDoseSpotSettingsPath = '/DoseSpot';
  static readonly UpdateDoseSpotSettingsPath = '/DoseSpot';
  static readonly GetNotificationsCountPath = '/DoseSpot/NotificationsCount';
  static readonly SubmitPatientToDoseSpotPath = '/DoseSpot/{patientId}/Submit';
  static readonly SaveAndSubmitPatientToDoseSpotPath = '/DoseSpot/{patientId}/Submit';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the DoseSpot settings.
   * @return Success
   */
  GetDoseSpotSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseDoseSpotSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/DoseSpot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDoseSpotSettings>;
      })
    );
  }
  /**
   * Gets the DoseSpot settings.
   * @return Success
   */
  GetDoseSpotSettings(): __Observable<ApiResponseDoseSpotSettings> {
    return this.GetDoseSpotSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseDoseSpotSettings)
    );
  }

  /**
   * Updates the DoseSpot settings.
   * @param settingsUpdateRequest undefined
   * @return Success
   */
  UpdateDoseSpotSettingsResponse(settingsUpdateRequest?: DoseSpotSettings): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = settingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/DoseSpot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates the DoseSpot settings.
   * @param settingsUpdateRequest undefined
   * @return Success
   */
  UpdateDoseSpotSettings(settingsUpdateRequest?: DoseSpotSettings): __Observable<ApiResponseOkResponse> {
    return this.UpdateDoseSpotSettingsResponse(settingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the notifications count from DoseSpot.
   * @return Success
   */
  GetNotificationsCountResponse(): __Observable<__StrictHttpResponse<ApiResponseDoseSpotNotificationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/DoseSpot/NotificationsCount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDoseSpotNotificationsResponse>;
      })
    );
  }
  /**
   * Gets the notifications count from DoseSpot.
   * @return Success
   */
  GetNotificationsCount(): __Observable<ApiResponseDoseSpotNotificationsResponse> {
    return this.GetNotificationsCountResponse().pipe(
      __map(_r => _r.body as ApiResponseDoseSpotNotificationsResponse)
    );
  }

  /**
   * Submits a patient to DoseSpot.
   * @param patientId The patient Id.
   * @return Success
   */
  SubmitPatientToDoseSpotResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponseString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/DoseSpot/${encodeURIComponent(String(patientId))}/Submit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseString>;
      })
    );
  }
  /**
   * Submits a patient to DoseSpot.
   * @param patientId The patient Id.
   * @return Success
   */
  SubmitPatientToDoseSpot(patientId: number): __Observable<ApiResponseString> {
    return this.SubmitPatientToDoseSpotResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponseString)
    );
  }

  /**
   * Updates a patient and submits it to DoseSpot.
   * @param params The `DoseSpotService.SaveAndSubmitPatientToDoseSpotParams` containing the following parameters:
   *
   * - `patientId`: The patient Id.
   *
   * - `doseSpotPatient`: The DoseSpot patient.
   *
   * @return Success
   */
  SaveAndSubmitPatientToDoseSpotResponse(params: DoseSpotService.SaveAndSubmitPatientToDoseSpotParams): __Observable<__StrictHttpResponse<ApiResponseString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.doseSpotPatient;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/DoseSpot/${encodeURIComponent(String(params.patientId))}/Submit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseString>;
      })
    );
  }
  /**
   * Updates a patient and submits it to DoseSpot.
   * @param params The `DoseSpotService.SaveAndSubmitPatientToDoseSpotParams` containing the following parameters:
   *
   * - `patientId`: The patient Id.
   *
   * - `doseSpotPatient`: The DoseSpot patient.
   *
   * @return Success
   */
  SaveAndSubmitPatientToDoseSpot(params: DoseSpotService.SaveAndSubmitPatientToDoseSpotParams): __Observable<ApiResponseString> {
    return this.SaveAndSubmitPatientToDoseSpotResponse(params).pipe(
      __map(_r => _r.body as ApiResponseString)
    );
  }
}

module DoseSpotService {

  /**
   * Parameters for SaveAndSubmitPatientToDoseSpot
   */
  export interface SaveAndSubmitPatientToDoseSpotParams {

    /**
     * The patient Id.
     */
    patientId: number;

    /**
     * The DoseSpot patient.
     */
    doseSpotPatient?: DoseSpotPatientUpdateRequest;
  }
}

export { DoseSpotService }
