import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from './logout.plugin';

@Injectable({
    providedIn: 'root',
})
export class NgxsLogoutPluginFacade {
    constructor(private store: Store) {}

    public logout(): void {
        this.store.dispatch(new Logout());
    }
}
