import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
    MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { UsersService } from '@symplast/generated-clients/web-portal';
import { CurrentUserService } from '../../core/services/current-user.service';
import { MobilePhoneConfirmStorage } from '../../core/services/mobile-phone-confirm.storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationDialogService } from '../../core/services/dialogs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';

@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatLegacyDialogModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatButtonModule,
        NgxMaskDirective,
    ],
    selector: 'app-confirm-phone-dialog',
    templateUrl: './confirm-phone-dialog.component.html',
    styleUrls: ['./confirm-phone-dialog.component.scss'],
})
export class ConfirmPhoneDialogComponent implements OnInit {
    public phoneGroup: FormGroup;
    public isPhoneEmpty = false;

    constructor(
        public dialogRef: MatDialogRef<ConfirmPhoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public usersService: UsersService,
        public confirmDialog: ConfirmationDialogService,
        private currentUserService: CurrentUserService,
        private mobilePhoneConfirmStorage: MobilePhoneConfirmStorage,
    ) {}

    ngOnInit(): void {
        const user = this.currentUserService.user;

        this.isPhoneEmpty = !user?.mobilePhone;
        this.phoneGroup = this.formBuilder.group({
            mobilePhone: [user?.mobilePhone, [Validators.required]],
        });

        this.dialogRef.disableClose = this.isPhoneEmpty;
    }

    public confirm(): void {
        if (this.phoneGroup.valid) {
            this.usersService
                .SetMobilePhoneUser({
                    userId: this.currentUserService.user?.userId,
                    request: {
                        mobilePhone: this.phoneGroup.get('mobilePhone').value,
                    },
                })
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.dialogRef.close();
                });
        }
    }

    public cancel(): void {
        this.mobilePhoneConfirmStorage.setMobilePhoneConfirmCanceled();
        this.dialogRef.close();
    }

    public delay(): void {
        const currentDate = new Date();

        currentDate.setDate(currentDate.getDate() + 1);
        const delayedDate = currentDate.toISOString();

        this.usersService
            .SetMobilePhoneConfirmationDelayedForUser(delayedDate)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.dialogRef.close();
            });
    }

    public decline(): void {
        this.confirmDialog
            .open({
                title: 'Notice',
                content:
                    'Are you sure? If you decline providing your mobile phone and later forget your password, you will need to separately contact your Practice Administrator for assistance.',
                confirm: { label: 'OK' },
            })
            .afterConfirmed()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setMobilePhoneConfirmationDelay());
    }

    private setMobilePhoneConfirmationDelay(): void {
        const currentDate = new Date();

        currentDate.setFullYear(currentDate.getFullYear() + 100);
        const delayedDate = currentDate.toISOString();

        this.usersService
            .SetMobilePhoneConfirmationDelayedForUser(delayedDate)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.dialogRef.close();
            });
    }
}
