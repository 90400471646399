/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionPaymentReason } from '../models/api-response-icollection-payment-reason';
import { ApiResponsePaymentReason } from '../models/api-response-payment-reason';
import { PaymentReasonCreateRequest } from '../models/payment-reason-create-request';
import { PaymentReasonUpdateRequest } from '../models/payment-reason-update-request';
@Injectable({
  providedIn: 'root',
})
class PaymentReasonsService extends __BaseService {
  static readonly GetAllPaymentReasonsPath = '/PaymentReasons';
  static readonly CreatePaymentReasonPath = '/PaymentReasons';
  static readonly GetPaymentReasonPath = '/PaymentReasons/{paymentReasonId}';
  static readonly UpdatePaymentReasonPath = '/PaymentReasons/{paymentReasonId}';
  static readonly DeletePaymentReasonPath = '/PaymentReasons/{paymentReasonId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all payment reasons.
   * @param isUseInsurance The is use insurance.
   * @return Success
   */
  GetAllPaymentReasonsResponse(isUseInsurance?: boolean): __Observable<__StrictHttpResponse<ApiResponseICollectionPaymentReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (isUseInsurance != null) __params = __params.set('isUseInsurance', isUseInsurance.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PaymentReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPaymentReason>;
      })
    );
  }
  /**
   * Gets all payment reasons.
   * @param isUseInsurance The is use insurance.
   * @return Success
   */
  GetAllPaymentReasons(isUseInsurance?: boolean): __Observable<ApiResponseICollectionPaymentReason> {
    return this.GetAllPaymentReasonsResponse(isUseInsurance).pipe(
      __map(_r => _r.body as ApiResponseICollectionPaymentReason)
    );
  }

  /**
   * Creates the payment reason.
   * @param paymentReasonCreateRequest The payment reason create request.
   * @return Success
   */
  CreatePaymentReasonResponse(paymentReasonCreateRequest?: PaymentReasonCreateRequest): __Observable<__StrictHttpResponse<ApiResponsePaymentReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = paymentReasonCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/PaymentReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePaymentReason>;
      })
    );
  }
  /**
   * Creates the payment reason.
   * @param paymentReasonCreateRequest The payment reason create request.
   * @return Success
   */
  CreatePaymentReason(paymentReasonCreateRequest?: PaymentReasonCreateRequest): __Observable<ApiResponsePaymentReason> {
    return this.CreatePaymentReasonResponse(paymentReasonCreateRequest).pipe(
      __map(_r => _r.body as ApiResponsePaymentReason)
    );
  }

  /**
   * Gets the payment reason.
   * @param paymentReasonId The payment reason identifier.
   * @return Success
   */
  GetPaymentReasonResponse(paymentReasonId: number): __Observable<__StrictHttpResponse<ApiResponsePaymentReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PaymentReasons/${encodeURIComponent(String(paymentReasonId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePaymentReason>;
      })
    );
  }
  /**
   * Gets the payment reason.
   * @param paymentReasonId The payment reason identifier.
   * @return Success
   */
  GetPaymentReason(paymentReasonId: number): __Observable<ApiResponsePaymentReason> {
    return this.GetPaymentReasonResponse(paymentReasonId).pipe(
      __map(_r => _r.body as ApiResponsePaymentReason)
    );
  }

  /**
   * Updates the payment reason.
   * @param params The `PaymentReasonsService.UpdatePaymentReasonParams` containing the following parameters:
   *
   * - `paymentReasonId`: The payment reason identifier.
   *
   * - `paymentReasonUpdateRequest`: The payment reason update request.
   *
   * @return Success
   */
  UpdatePaymentReasonResponse(params: PaymentReasonsService.UpdatePaymentReasonParams): __Observable<__StrictHttpResponse<ApiResponsePaymentReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.paymentReasonUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PaymentReasons/${encodeURIComponent(String(params.paymentReasonId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePaymentReason>;
      })
    );
  }
  /**
   * Updates the payment reason.
   * @param params The `PaymentReasonsService.UpdatePaymentReasonParams` containing the following parameters:
   *
   * - `paymentReasonId`: The payment reason identifier.
   *
   * - `paymentReasonUpdateRequest`: The payment reason update request.
   *
   * @return Success
   */
  UpdatePaymentReason(params: PaymentReasonsService.UpdatePaymentReasonParams): __Observable<ApiResponsePaymentReason> {
    return this.UpdatePaymentReasonResponse(params).pipe(
      __map(_r => _r.body as ApiResponsePaymentReason)
    );
  }

  /**
   * Deletes the payment reason.
   * @param paymentReasonId The payment reason identifier.
   */
  DeletePaymentReasonResponse(paymentReasonId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/PaymentReasons/${encodeURIComponent(String(paymentReasonId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the payment reason.
   * @param paymentReasonId The payment reason identifier.
   */
  DeletePaymentReason(paymentReasonId: number): __Observable<null> {
    return this.DeletePaymentReasonResponse(paymentReasonId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PaymentReasonsService {

  /**
   * Parameters for UpdatePaymentReason
   */
  export interface UpdatePaymentReasonParams {

    /**
     * The payment reason identifier.
     */
    paymentReasonId: number;

    /**
     * The payment reason update request.
     */
    paymentReasonUpdateRequest?: PaymentReasonUpdateRequest;
  }
}

export { PaymentReasonsService }
