import { Selector } from '@ngxs/store';
import { ItemOrItemGroup } from '@symplast/models/shared';
import { IItemsStateModel } from './items.model';
import { ItemsState } from './items.state';

export class ItemsSelectors {
    @Selector([ItemsState])
    public static filters(state: IItemsStateModel): any {
        return {
            searchString: state.searchText,
            selectedLocationId: state.selectedLocationId,
            selectedBrandId: state.selectedBrandId,
            selectedType: state.selectedType,
            isRelatedToInsurance: state.isRelatedToInsurance,
        };
    }

    @Selector([ItemsState])
    public static loading(state: IItemsStateModel): boolean {
        return state.loading;
    }

    @Selector([ItemsState])
    public static filteredItems(state: IItemsStateModel): ItemOrItemGroup[] {
        return state.filteredItems;
    }
}
