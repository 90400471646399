/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionCase } from '../models/api-response-icollection-case';
import { CaseCreateRequest } from '../models/case-create-request';
import { ApiResponseCountResponse } from '../models/api-response-count-response';
import { ApiResponseCase } from '../models/api-response-case';
import { CaseUpdateRequest } from '../models/case-update-request';
import { ApiResponseIEnumerableIncidentQualifierCode } from '../models/api-response-ienumerable-incident-qualifier-code';
@Injectable({
  providedIn: 'root',
})
class CasesService extends __BaseService {
  static readonly CreateCasePath = '/Insurance/Cases';
  static readonly GetCasesPath = '/Insurance/Cases';
  static readonly GetCasesCountPath = '/Insurance/Cases/Count';
  static readonly GetCaseByIdPath = '/Cases/{caseId}';
  static readonly UpdateCasePath = '/Insurance/Cases/{caseId}';
  static readonly GetIncidentQualifierCodesPath = '/Cases/IncidentQualifierCodes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates a new case.
   * @param caseCreateRequest The case Create Request.
   * @return Success
   */
  CreateCaseResponse(caseCreateRequest?: CaseCreateRequest): __Observable<__StrictHttpResponse<ApiResponseICollectionCase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = caseCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Cases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionCase>;
      })
    );
  }
  /**
   * Creates a new case.
   * @param caseCreateRequest The case Create Request.
   * @return Success
   */
  CreateCase(caseCreateRequest?: CaseCreateRequest): __Observable<ApiResponseICollectionCase> {
    return this.CreateCaseResponse(caseCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseICollectionCase)
    );
  }

  /**
   * Get the cases.
   * @param params The `CasesService.GetCasesParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `patientId`: The patient.
   *
   * - `pageSize`: The amount of records per page.
   *
   * - `page`: The current page number.
   *
   * - `orderByDate`: Order by date, descending.
   *
   * - `includePatientNames`: Include patient names in the result.
   *
   * @return Success
   */
  GetCasesResponse(params: CasesService.GetCasesParams): __Observable<__StrictHttpResponse<ApiResponseICollectionCase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.orderByDate != null) __params = __params.set('orderByDate', params.orderByDate.toString());
    if (params.includePatientNames != null) __params = __params.set('includePatientNames', params.includePatientNames.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Cases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionCase>;
      })
    );
  }
  /**
   * Get the cases.
   * @param params The `CasesService.GetCasesParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `patientId`: The patient.
   *
   * - `pageSize`: The amount of records per page.
   *
   * - `page`: The current page number.
   *
   * - `orderByDate`: Order by date, descending.
   *
   * - `includePatientNames`: Include patient names in the result.
   *
   * @return Success
   */
  GetCases(params: CasesService.GetCasesParams): __Observable<ApiResponseICollectionCase> {
    return this.GetCasesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionCase)
    );
  }

  /**
   * Get the cases.
   * @param params The `CasesService.GetCasesCountParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `patientId`: The patient.
   *
   * @return Success
   */
  GetCasesCountResponse(params: CasesService.GetCasesCountParams): __Observable<__StrictHttpResponse<ApiResponseCountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Cases/Count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCountResponse>;
      })
    );
  }
  /**
   * Get the cases.
   * @param params The `CasesService.GetCasesCountParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `patientId`: The patient.
   *
   * @return Success
   */
  GetCasesCount(params: CasesService.GetCasesCountParams): __Observable<ApiResponseCountResponse> {
    return this.GetCasesCountResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCountResponse)
    );
  }

  /**
   * Get case by id.
   * @param caseId The case id.
   * @return Success
   */
  GetCaseByIdResponse(caseId: number): __Observable<__StrictHttpResponse<ApiResponseCase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Cases/${encodeURIComponent(String(caseId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCase>;
      })
    );
  }
  /**
   * Get case by id.
   * @param caseId The case id.
   * @return Success
   */
  GetCaseById(caseId: number): __Observable<ApiResponseCase> {
    return this.GetCaseByIdResponse(caseId).pipe(
      __map(_r => _r.body as ApiResponseCase)
    );
  }

  /**
   * Update the case.
   * @param params The `CasesService.UpdateCaseParams` containing the following parameters:
   *
   * - `caseId`: The case id.
   *
   * - `caseUpdateRequest`: The case to update.
   *
   * @return Success
   */
  UpdateCaseResponse(params: CasesService.UpdateCaseParams): __Observable<__StrictHttpResponse<ApiResponseCase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.caseUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Cases/${encodeURIComponent(String(params.caseId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCase>;
      })
    );
  }
  /**
   * Update the case.
   * @param params The `CasesService.UpdateCaseParams` containing the following parameters:
   *
   * - `caseId`: The case id.
   *
   * - `caseUpdateRequest`: The case to update.
   *
   * @return Success
   */
  UpdateCase(params: CasesService.UpdateCaseParams): __Observable<ApiResponseCase> {
    return this.UpdateCaseResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCase)
    );
  }

  /**
   * Get incident qualifier codes.
   * @return Success
   */
  GetIncidentQualifierCodesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableIncidentQualifierCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Cases/IncidentQualifierCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableIncidentQualifierCode>;
      })
    );
  }
  /**
   * Get incident qualifier codes.
   * @return Success
   */
  GetIncidentQualifierCodes(): __Observable<ApiResponseIEnumerableIncidentQualifierCode> {
    return this.GetIncidentQualifierCodesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableIncidentQualifierCode)
    );
  }
}

module CasesService {

  /**
   * Parameters for GetCases
   */
  export interface GetCasesParams {

    /**
     * The status.
     */
    status?: 'New' | 'Open' | 'Submitted' | 'Rejected' | 'Closed';

    /**
     * The patient.
     */
    patientId?: number;

    /**
     * The amount of records per page.
     */
    pageSize?: number;

    /**
     * The current page number.
     */
    page?: number;

    /**
     * Order by date, descending.
     */
    orderByDate?: boolean;

    /**
     * Include patient names in the result.
     */
    includePatientNames?: boolean;
  }

  /**
   * Parameters for GetCasesCount
   */
  export interface GetCasesCountParams {

    /**
     * The status.
     */
    status?: 'New' | 'Open' | 'Submitted' | 'Rejected' | 'Closed';

    /**
     * The patient.
     */
    patientId?: number;
  }

  /**
   * Parameters for UpdateCase
   */
  export interface UpdateCaseParams {

    /**
     * The case id.
     */
    caseId: number;

    /**
     * The case to update.
     */
    caseUpdateRequest?: CaseUpdateRequest;
  }
}

export { CasesService }
