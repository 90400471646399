import { Selector } from '@ngxs/store';
import { IReferralSourcesStateModel } from './referral-sources.model';
import { ReferralSourcesState } from './referral-sources.state';

export class ReferralSourcesSelectors {
    @Selector([ReferralSourcesState])
    public static loading(state: IReferralSourcesStateModel): boolean {
        return state.loading;
    }

    @Selector([ReferralSourcesState])
    public static referralSources(state: IReferralSourcesStateModel): IReferralSourcesStateModel['referralSources'] {
        return state.referralSources;
    }

    @Selector([ReferralSourcesState])
    public static referralSourcesFlat(state: IReferralSourcesStateModel): IReferralSourcesStateModel['referralSourcesFlat'] {
        return state.referralSourcesFlat;
    }
}
