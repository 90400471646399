import Bugsnag from '@bugsnag/js';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { AngularRoutingProvider } from '@bugsnag/angular-performance';
import { environment } from '../../../../environments/environment';

function getReleaseStageFromURL(): string {
    try {
        const hostname = window.location.hostname;

        // Check if running locally (hostname does not contain '.symplast.com')
        if (!hostname.includes('.symplast.com')) {
            return 'local';
        }

        const subdomain = hostname.split('.')[0]; // Assuming the format is subdomain.domain.tld

        if (subdomain === 'webportal') {
            return 'production';
        }

        const stage = subdomain.replace('webportal-', ''); // Extract 'dev', 'devch', 'uat', etc.

        return stage;
    } catch {
        return 'unknown';
    }
}

export function enableBugsnag(): void {
    const releaseStage = getReleaseStageFromURL();

    if (releaseStage !== 'local') {
        Bugsnag.start({
            apiKey: environment.bugsnag.apiKey,
            appVersion: environment.version,
            releaseStage,
        });
        BugsnagPerformance.start({
            apiKey: environment.bugsnag.apiKey,
            appVersion: environment.version,
            releaseStage,
            routingProvider: new AngularRoutingProvider(),
        });
    }
}
