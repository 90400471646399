import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from '@symplast/services/shared';

@UntilDestroy()
@Component({
    standalone: true,
    imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule],
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
    public errorList: string[] = [];
    constructor(private notificationService: NotificationService) {}

    ngOnInit(): void {
        this.notificationService.currentNotifications$
            .pipe(untilDestroyed(this))
            .subscribe((notifications) => (this.errorList = notifications));
    }

    public closeNotification(notification: string): void {
        this.notificationService.removeNotification(notification);
    }
}
