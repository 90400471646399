/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiPagedResponseIEnumerablePatientBalance } from '../models/api-paged-response-ienumerable-patient-balance';
@Injectable({
  providedIn: 'root',
})
class PatientBalanceService extends __BaseService {
  static readonly GetPatientBalancesPath = '/PatientBalance';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the list of patient balances.
   * @param params The `PatientBalanceService.GetPatientBalancesParams` containing the following parameters:
   *
   * - `TotalBalanceBracket`: Gets or sets the total balance bracket.
   *
   * - `Take`: Gets or sets the take.
   *
   * - `SortingDirection`: Gets or sets the sorting direction.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `SearchStr`: Gets or sets the search str.
   *
   * - `PaymentAgeBracket`: Gets or sets the payment age bracket.
   *
   * - `OrderBy`: Gets or sets the order by.
   *
   * - `InvoiceAgeBracket`: Gets or sets the invoice age bracket.
   *
   * - `InsuranceRelatedInvoiceFilter`: Gets or sets the insurance related invoice filter.
   *
   * @return Success
   */
  GetPatientBalancesResponse(params: PatientBalanceService.GetPatientBalancesParams): __Observable<__StrictHttpResponse<ApiPagedResponseIEnumerablePatientBalance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.TotalBalanceBracket != null) __params = __params.set('TotalBalanceBracket', params.TotalBalanceBracket.toString());
    if (params.Take != null) __params = __params.set('Take', params.Take.toString());
    if (params.SortingDirection != null) __params = __params.set('SortingDirection', params.SortingDirection.toString());
    if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
    if (params.SearchStr != null) __params = __params.set('SearchStr', params.SearchStr.toString());
    if (params.PaymentAgeBracket != null) __params = __params.set('PaymentAgeBracket', params.PaymentAgeBracket.toString());
    if (params.OrderBy != null) __params = __params.set('OrderBy', params.OrderBy.toString());
    if (params.InvoiceAgeBracket != null) __params = __params.set('InvoiceAgeBracket', params.InvoiceAgeBracket.toString());
    if (params.InsuranceRelatedInvoiceFilter != null) __params = __params.set('InsuranceRelatedInvoiceFilter', params.InsuranceRelatedInvoiceFilter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PatientBalance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseIEnumerablePatientBalance>;
      })
    );
  }
  /**
   * Gets the list of patient balances.
   * @param params The `PatientBalanceService.GetPatientBalancesParams` containing the following parameters:
   *
   * - `TotalBalanceBracket`: Gets or sets the total balance bracket.
   *
   * - `Take`: Gets or sets the take.
   *
   * - `SortingDirection`: Gets or sets the sorting direction.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `SearchStr`: Gets or sets the search str.
   *
   * - `PaymentAgeBracket`: Gets or sets the payment age bracket.
   *
   * - `OrderBy`: Gets or sets the order by.
   *
   * - `InvoiceAgeBracket`: Gets or sets the invoice age bracket.
   *
   * - `InsuranceRelatedInvoiceFilter`: Gets or sets the insurance related invoice filter.
   *
   * @return Success
   */
  GetPatientBalances(params: PatientBalanceService.GetPatientBalancesParams): __Observable<ApiPagedResponseIEnumerablePatientBalance> {
    return this.GetPatientBalancesResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseIEnumerablePatientBalance)
    );
  }
}

module PatientBalanceService {

  /**
   * Parameters for GetPatientBalances
   */
  export interface GetPatientBalancesParams {

    /**
     * Gets or sets the total balance bracket.
     */
    TotalBalanceBracket?: 'From0To500Dollars' | 'From500To1000Dollars' | 'From1000To5000Dollars' | 'MoreThan5000Dollars';

    /**
     * Gets or sets the take.
     */
    Take?: number;

    /**
     * Gets or sets the sorting direction.
     */
    SortingDirection?: 'Ascending' | 'Descending';

    /**
     * Gets or sets the skip.
     */
    Skip?: number;

    /**
     * Gets or sets the search str.
     */
    SearchStr?: string;

    /**
     * Gets or sets the payment age bracket.
     */
    PaymentAgeBracket?: 'From0To30Days' | 'From31To60Days' | 'From61To90Days' | 'From91To120Days' | 'From121To180Days' | 'MoreThan180Days';

    /**
     * Gets or sets the order by.
     */
    OrderBy?: 'PatientName' | 'OpenInvoicesCount' | 'OldestOpenInvoiceDate' | 'MostRecentPaymentDate' | 'TotalBalance';

    /**
     * Gets or sets the invoice age bracket.
     */
    InvoiceAgeBracket?: 'From0To30Days' | 'From31To60Days' | 'From61To90Days' | 'From91To120Days' | 'From121To180Days' | 'MoreThan180Days';

    /**
     * Gets or sets the insurance related invoice filter.
     */
    InsuranceRelatedInvoiceFilter?: 'Include' | 'Exclude' | 'Only';
  }
}

export { PatientBalanceService }
