import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppRoutePath } from '../../app.route-path';
import { AuthenticationRoutePath } from '../../authentication/routes/authentication.route-path';
import { AuthenticationDataStorage } from '../services/authentication/authentication-data.storage';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private authStorage: AuthenticationDataStorage) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
        return this.hasAccess(state);
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
        return this.hasAccess(state);
    }

    private hasAccess(state: RouterStateSnapshot): UrlTree | boolean {
        if (!this.authStorage.data) {
            return this.router.createUrlTree(['/', AppRoutePath.AUTHENTICATION, AuthenticationRoutePath.LOGIN], {
                queryParams: { redirectTo: state.url },
            });
        }

        return true;
    }
}
