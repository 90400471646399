import { Selector } from '@ngxs/store';
import { IPracticeUsersStateModel } from './practice-users.model';
import { PracticeUsersState } from './practice-users.state';

export class PracticeUsersSelectors {
    @Selector([PracticeUsersState])
    public static loading(state: IPracticeUsersStateModel): boolean {
        return state.loading;
    }

    @Selector([PracticeUsersState])
    public static practiceUsers(state: IPracticeUsersStateModel): IPracticeUsersStateModel['practiceUsers'] {
        return state.practiceUsers;
    }

    @Selector([PracticeUsersState])
    public static tipRecipients(state: IPracticeUsersStateModel): IPracticeUsersStateModel['practiceUsers'] {
        return state.practiceUsers.filter((user) => user.isTipRecipient);
    }
}
