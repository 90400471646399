import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    standalone: true,
    name: 'longDate',
})
export class LongDatePipe implements PipeTransform {
    public transform(value: string): any {
        if (!value) {
            return 'n/a';
        }

        return new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'long',
        });
    }
}
