import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserService } from '@symplast/utils';
import { PrivateSettingsService } from '@symplast/services/shared';
import { Router } from '@angular/router';
import { PatientsHelperService } from '../../../services/patients/patients-helper.service';
import { Tenant } from '@symplast/models/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppRoutePath } from '../../../app.route-path';
import { CurrentUserService } from '../../../core/services/current-user.service';
import { LogoutService } from '../../../core/services/logout/logout.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoaderComponent } from '../../../shared/loader/loader.component';

@UntilDestroy()
@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatMenuModule,
        MatDividerModule,
        AngularSvgIconModule,
        LoaderComponent,
    ],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit {
    public patientSearchControl = new FormControl('');
    public currentTenant: Tenant;
    public currentUser$ = this.currentUserService.user$;

    constructor(
        public readonly browserService: BrowserService,
        public readonly patientHelperSvc: PatientsHelperService,
        private readonly cdr: ChangeDetectorRef,
        private readonly privateSettingsService: PrivateSettingsService,
        private readonly router: Router,
        private readonly currentUserService: CurrentUserService,
        private logoutService: LogoutService,
    ) {}

    public ngOnInit(): void {
        this.patientHelperSvc.subscribeToSearchChanges(this.patientSearchControl);
        this.getTenantData();
    }

    public ngAfterViewInit(): void {
        // FIXME: (a.vakhrushin) Possibly unnecessary functionality. Recheck.
        this.patientHelperSvc.isLoading$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.cdr && !(this.cdr as ViewRef).destroyed) {
                this.cdr.detectChanges();
            }
        });
    }

    public signOut(): void {
        this.logoutService.logout();
    }

    public openProfile(): void {
        this.router.navigate([AppRoutePath.SETTINGS, 'Profile']);
    }

    private getTenantData(): void {
        this.privateSettingsService.CurrentTenant$.pipe(untilDestroyed(this)).subscribe((tenant) => {
            this.currentTenant = tenant;
            this.currentTenant.Logo = `${tenant.Logo}?t=${new Date().getMilliseconds()}`;
        });
    }
}
