import { SalesReportRequest } from '@symplast/generated-clients/financial';
import { FilterSet } from '@symplast/generated-clients/web-portal';
import moment from 'moment';

export const SALES_SECTION_NAME = 'Reports/SalesReport';

export const DEFAULT_SALES_FILTER: FilterSet = {
    id: 'defaultFilterId',
    name: 'Defaults',
    isPublic: true,
    isDefault: false,
    isReadonly: true,
    section: SALES_SECTION_NAME,
    values: {
        effectiveDateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        effectiveDateTo: moment().format('YYYY-MM-DD'),
        locationIds: new Array<number>(),
        groupBy: 'Item',
        isGroupByAllLocation: false,
        providerId: null,
        providerType: null,
    } as SalesReportRequest,
} as FilterSet;
