import { Selector } from '@ngxs/store';
import { IUserRolesStateModel } from './user-roles.model';
import { UserRolesState } from './user-roles.state';

export class UserRolesSelectors {
    @Selector([UserRolesState])
    public static loading(state: IUserRolesStateModel): boolean {
        return state.loading;
    }

    @Selector([UserRolesState])
    public static userRoles(state: IUserRolesStateModel): IUserRolesStateModel['userRoles'] {
        return state.userRoles;
    }
}
