import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    public currentNotifications$: Observable<string[]>;

    private notifications$ = new BehaviorSubject<string[]>([]);

    constructor() {
        this.currentNotifications$ = this.notifications$.asObservable();
    }

    addNotification(notification: string): void {
        const notifications = this.notifications$.value;

        notifications.push(notification);
        this.notifications$.next(notifications);
    }

    removeNotification(notification: string): void {
        const notifications = this.notifications$.value;
        const index = notifications.indexOf(notification, 0);

        if (index > -1) {
            notifications.splice(index, 1);
        }

        this.notifications$.next(notifications);
    }
}
