/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTransactionType } from '../models/api-response-ienumerable-transaction-type';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { TransactionTypeToUpdateRequest } from '../models/transaction-type-to-update-request';
@Injectable({
    providedIn: 'root',
})
class TransactionTypesService extends __BaseService {
    static readonly GetTransactionTypesPath = '/TransactionTypes';
    static readonly UpdateTransactionTypePath = '/TransactionTypes/{transactionTypeId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets All Transaction types.
     * @return Success
     */
    GetTransactionTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTransactionType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/TransactionTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableTransactionType>;
            }),
        );
    }
    /**
     * Gets All Transaction types.
     * @return Success
     */
    GetTransactionTypes(): __Observable<ApiResponseIEnumerableTransactionType> {
        return this.GetTransactionTypesResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableTransactionType));
    }

    /**
     * Modifies a Transaction Type Name.
     * @param params The `TransactionTypesService.UpdateTransactionTypeParams` containing the following parameters:
     *
     * - `transactionTypeId`: The transaction type identifier.
     *
     * - `transactionTypeToUpdateRequest`: The transaction to update requests.
     *
     * @return Success
     */
    UpdateTransactionTypeResponse(
        params: TransactionTypesService.UpdateTransactionTypeParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.transactionTypeToUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/TransactionTypes/${encodeURIComponent(String(params.transactionTypeId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies a Transaction Type Name.
     * @param params The `TransactionTypesService.UpdateTransactionTypeParams` containing the following parameters:
     *
     * - `transactionTypeId`: The transaction type identifier.
     *
     * - `transactionTypeToUpdateRequest`: The transaction to update requests.
     *
     * @return Success
     */
    UpdateTransactionType(params: TransactionTypesService.UpdateTransactionTypeParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateTransactionTypeResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module TransactionTypesService {
    /**
     * Parameters for UpdateTransactionType
     */
    export interface UpdateTransactionTypeParams {
        /**
         * The transaction type identifier.
         */
        transactionTypeId: number;

        /**
         * The transaction to update requests.
         */
        transactionTypeToUpdateRequest?: TransactionTypeToUpdateRequest;
    }
}

export { TransactionTypesService };
