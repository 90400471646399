import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import pick from 'lodash-es/pick';
import omit from 'lodash-es/omit';
import { ErrorDialogConfig, ErrorDialogData } from './error-dialog.model';
import { ErrorDialogRef } from './error-dialog.reference';
import { ErrorDialogComponent } from './error-dialog.component';

const DIALOG_CONFIG_DEFAULT: Partial<ErrorDialogData> = {
    title: 'Error!',
    close: { label: 'Ok' },
};

@Injectable({ providedIn: 'root' })
export class ErrorDialogService {
    constructor(private dialog: MatDialog) {}

    public open(config: ErrorDialogConfig): ErrorDialogRef {
        const dialogRef = this.dialog.open<ErrorDialogComponent, ErrorDialogData, unknown>(ErrorDialogComponent, {
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            width: '450px',
            ...omit(config, ['title', 'content', 'close']),
            data: {
                ...DIALOG_CONFIG_DEFAULT,
                ...pick(config, ['title', 'content', 'close']),
            },
        });

        return this.decorateDialogRef(dialogRef);
    }

    private decorateDialogRef(dialogRef: MatDialogRef<any, unknown>): ErrorDialogRef {
        (dialogRef as ErrorDialogRef).on = ({ close }) => {
            if (close) {
                dialogRef.afterClosed().subscribe(() => close());
            }
        };

        return dialogRef as ErrorDialogRef;
    }
}
