/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerablePatientDemographic } from '../models/api-response-ienumerable-patient-demographic';
import { PatientDemographicsFilter } from '../models/patient-demographics-filter';
@Injectable({
    providedIn: 'root',
})
class ReportsService extends __BaseService {
    static readonly ReportsGetPath = '/api/PatientDemographics';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param filter The PatientDemographics report filter.
     * @return OK
     */
    ReportsGetResponse(filter: PatientDemographicsFilter): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientDemographic>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = filter;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/PatientDemographics`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientDemographic>;
            }),
        );
    }
    /**
     * @param filter The PatientDemographics report filter.
     * @return OK
     */
    ReportsGet(filter: PatientDemographicsFilter): __Observable<ApiResponseIEnumerablePatientDemographic> {
        return this.ReportsGetResponse(filter).pipe(__map((_r) => _r.body as ApiResponseIEnumerablePatientDemographic));
    }
}

module ReportsService {}

export { ReportsService };
