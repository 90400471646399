/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIReadOnlyCollectionResourceItem } from '../models/api-response-iread-only-collection-resource-item';
import { ApiResponseResourceItem } from '../models/api-response-resource-item';
import { ResourceItemUpsertRequest } from '../models/resource-item-upsert-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseResourceItemImageUploadResponse } from '../models/api-response-resource-item-image-upload-response';
@Injectable({
  providedIn: 'root',
})
class ResourceItemsService extends __BaseService {
  static readonly GetResourceItemsPath = '/ResourceItems';
  static readonly CreateResourceItemPath = '/ResourceItems';
  static readonly GetResourceItemByIdPath = '/ResourceItems/{resourceItemId}';
  static readonly UpdateResourceItemPath = '/ResourceItems/{resourceItemId}';
  static readonly DeleteResourceItemPath = '/ResourceItems/{resourceItemId}';
  static readonly UpdateResourceItemImagePath = '/ResourceItems/{resourceItemId}/Image';
  static readonly UpdateResourceItemActiveStatusPath = '/ResourceItems/{resourceItemId}/IsActive';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the appointment resources.
   * @return Success
   */
  GetResourceItemsResponse(): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionResourceItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ResourceItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionResourceItem>;
      })
    );
  }
  /**
   * Gets the appointment resources.
   * @return Success
   */
  GetResourceItems(): __Observable<ApiResponseIReadOnlyCollectionResourceItem> {
    return this.GetResourceItemsResponse().pipe(
      __map(_r => _r.body as ApiResponseIReadOnlyCollectionResourceItem)
    );
  }

  /**
   * Creates an appointment resource.
   * @param createRequest The create request.
   * @return Success
   */
  CreateResourceItemResponse(createRequest?: ResourceItemUpsertRequest): __Observable<__StrictHttpResponse<ApiResponseResourceItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ResourceItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseResourceItem>;
      })
    );
  }
  /**
   * Creates an appointment resource.
   * @param createRequest The create request.
   * @return Success
   */
  CreateResourceItem(createRequest?: ResourceItemUpsertRequest): __Observable<ApiResponseResourceItem> {
    return this.CreateResourceItemResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseResourceItem)
    );
  }

  /**
   * Gets an appointment resource by Id.
   * @param resourceItemId The appointment resource Id.
   * @return Success
   */
  GetResourceItemByIdResponse(resourceItemId: string): __Observable<__StrictHttpResponse<ApiResponseResourceItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ResourceItems/${encodeURIComponent(String(resourceItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseResourceItem>;
      })
    );
  }
  /**
   * Gets an appointment resource by Id.
   * @param resourceItemId The appointment resource Id.
   * @return Success
   */
  GetResourceItemById(resourceItemId: string): __Observable<ApiResponseResourceItem> {
    return this.GetResourceItemByIdResponse(resourceItemId).pipe(
      __map(_r => _r.body as ApiResponseResourceItem)
    );
  }

  /**
   * Updates an appointment resource.
   * @param params The `ResourceItemsService.UpdateResourceItemParams` containing the following parameters:
   *
   * - `resourceItemId`: The appointment resource Id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateResourceItemResponse(params: ResourceItemsService.UpdateResourceItemParams): __Observable<__StrictHttpResponse<ApiResponseResourceItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ResourceItems/${encodeURIComponent(String(params.resourceItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseResourceItem>;
      })
    );
  }
  /**
   * Updates an appointment resource.
   * @param params The `ResourceItemsService.UpdateResourceItemParams` containing the following parameters:
   *
   * - `resourceItemId`: The appointment resource Id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateResourceItem(params: ResourceItemsService.UpdateResourceItemParams): __Observable<ApiResponseResourceItem> {
    return this.UpdateResourceItemResponse(params).pipe(
      __map(_r => _r.body as ApiResponseResourceItem)
    );
  }

  /**
   * Deletes an appointment resource.
   * @param resourceItemId The appointment resource Id.
   * @return Success
   */
  DeleteResourceItemResponse(resourceItemId: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ResourceItems/${encodeURIComponent(String(resourceItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes an appointment resource.
   * @param resourceItemId The appointment resource Id.
   * @return Success
   */
  DeleteResourceItem(resourceItemId: string): __Observable<ApiResponseOkResponse> {
    return this.DeleteResourceItemResponse(resourceItemId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates an appointment resource's image.
   * @param params The `ResourceItemsService.UpdateResourceItemImageParams` containing the following parameters:
   *
   * - `resourceItemId`: The appointment resource Id.
   *
   * - `image`:
   *
   * @return Success
   */
  UpdateResourceItemImageResponse(params: ResourceItemsService.UpdateResourceItemImageParams): __Observable<__StrictHttpResponse<ApiResponseResourceItemImageUploadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.image != null) { __formData.append('image', params.image as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ResourceItems/${encodeURIComponent(String(params.resourceItemId))}/Image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseResourceItemImageUploadResponse>;
      })
    );
  }
  /**
   * Updates an appointment resource's image.
   * @param params The `ResourceItemsService.UpdateResourceItemImageParams` containing the following parameters:
   *
   * - `resourceItemId`: The appointment resource Id.
   *
   * - `image`:
   *
   * @return Success
   */
  UpdateResourceItemImage(params: ResourceItemsService.UpdateResourceItemImageParams): __Observable<ApiResponseResourceItemImageUploadResponse> {
    return this.UpdateResourceItemImageResponse(params).pipe(
      __map(_r => _r.body as ApiResponseResourceItemImageUploadResponse)
    );
  }

  /**
   * Updates an appointment resource's Active status.
   * @param params The `ResourceItemsService.UpdateResourceItemActiveStatusParams` containing the following parameters:
   *
   * - `resourceItemId`: The appointment resource Id.
   *
   * - `isActive`: The isActive flag.
   *
   * @return Success
   */
  UpdateResourceItemActiveStatusResponse(params: ResourceItemsService.UpdateResourceItemActiveStatusParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/ResourceItems/${encodeURIComponent(String(params.resourceItemId))}/IsActive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates an appointment resource's Active status.
   * @param params The `ResourceItemsService.UpdateResourceItemActiveStatusParams` containing the following parameters:
   *
   * - `resourceItemId`: The appointment resource Id.
   *
   * - `isActive`: The isActive flag.
   *
   * @return Success
   */
  UpdateResourceItemActiveStatus(params: ResourceItemsService.UpdateResourceItemActiveStatusParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateResourceItemActiveStatusResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module ResourceItemsService {

  /**
   * Parameters for UpdateResourceItem
   */
  export interface UpdateResourceItemParams {

    /**
     * The appointment resource Id.
     */
    resourceItemId: string;

    /**
     * The update request.
     */
    updateRequest?: ResourceItemUpsertRequest;
  }

  /**
   * Parameters for UpdateResourceItemImage
   */
  export interface UpdateResourceItemImageParams {

    /**
     * The appointment resource Id.
     */
    resourceItemId: string;
    image?: Blob;
  }

  /**
   * Parameters for UpdateResourceItemActiveStatus
   */
  export interface UpdateResourceItemActiveStatusParams {

    /**
     * The appointment resource Id.
     */
    resourceItemId: string;

    /**
     * The isActive flag.
     */
    isActive: boolean;
  }
}

export { ResourceItemsService }
