import { Selector } from '@ngxs/store';
import { IRacesStateModel } from './races.model';
import { RacesState } from './races.state';

export class RacesSelectors {
    @Selector([RacesState])
    public static loading(state: IRacesStateModel): boolean {
        return state.loading;
    }

    @Selector([RacesState])
    public static races(state: IRacesStateModel): IRacesStateModel['races'] {
        return state.races;
    }
}
