import { Injectable } from '@angular/core';
import { AlbumStatistics, FileStatisticModel, AlbumsService, FilesService } from '@symplast/generated-clients/multimedia';
import { Observable } from 'rxjs';
import { MediaConstants } from '@symplast/app-constants';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MediaCatalogService {
    constructor(private albumService: AlbumsService, private filesService: FilesService) {}

    public loadItems(): Observable<AlbumStatistics[]> {
        return this.albumService.GetAlbums(false).pipe(
            map((response) => {
                const albums = response.result as AlbumStatistics[];

                albums.unshift(this.emptyMediaAlbum());

                return albums;
            }),
        );
    }

    public loadAllMedia(): Observable<AlbumStatistics> {
        return this.filesService.GetFileStatistic({}).pipe(
            map((response) => {
                return this.allMediaAlbum(response.result as FileStatisticModel[]);
            }),
        );
    }

    private isPracticeCatalog(album: AlbumStatistics): boolean {
        return album.tagFilter.split(' AND ').some((d) => d === MediaConstants.TemplateTag);
    }

    private emptyMediaAlbum(): AlbumStatistics {
        return {
            id: 'All',
            audience: [],
            albumCoverThumbnailUrl: '../assets/images/all_media_new.png',
            name: 'All Media',
            fileStatistics: null,
        };
    }

    private allMediaAlbum(statistics: FileStatisticModel[]): AlbumStatistics {
        return {
            id: 'All',
            audience: [],
            albumCoverThumbnailUrl: '../assets/images/all_media_new.png',
            name: 'All Media',
            fileStatistics: statistics,
        };
    }

    public deleteAlbum(id: string): Observable<any> {
        return this.albumService.DeleteAlbum(id);
    }

    public deleteMultipleAlbums(ids: string[]): Observable<any> {
        return this.albumService.DeleteMultipleAlbums(ids);
    }
}
