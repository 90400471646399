/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiPagedResponseICollectionFormRequest } from '../models/api-paged-response-icollection-form-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class InvitesService extends __BaseService {
  static readonly GetInvitesPath = '/SymplastForms/Invites';
  static readonly ResendInvitePath = '/SymplastForms/Invites/{formRequestId}/resend';
  static readonly CancelInvitePath = '/SymplastForms/Invites/{formRequestId}/cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of Invites.
   * @param params The `InvitesService.GetInvitesParams` containing the following parameters:
   *
   * - `status`: The status string.
   *
   * - `patientLastName`: The patient last name.
   *
   * - `patientFirstName`: The patient first name.
   *
   * @return Success
   */
  GetInvitesResponse(params: InvitesService.GetInvitesParams): __Observable<__StrictHttpResponse<ApiPagedResponseICollectionFormRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.patientLastName != null) __params = __params.set('patientLastName', params.patientLastName.toString());
    if (params.patientFirstName != null) __params = __params.set('patientFirstName', params.patientFirstName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SymplastForms/Invites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseICollectionFormRequest>;
      })
    );
  }
  /**
   * Gets a list of Invites.
   * @param params The `InvitesService.GetInvitesParams` containing the following parameters:
   *
   * - `status`: The status string.
   *
   * - `patientLastName`: The patient last name.
   *
   * - `patientFirstName`: The patient first name.
   *
   * @return Success
   */
  GetInvites(params: InvitesService.GetInvitesParams): __Observable<ApiPagedResponseICollectionFormRequest> {
    return this.GetInvitesResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseICollectionFormRequest)
    );
  }

  /**
   * Resend the Invite.
   * @param formRequestId The form request id.
   * @return Success
   */
  ResendInviteResponse(formRequestId: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SymplastForms/Invites/${encodeURIComponent(String(formRequestId))}/resend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Resend the Invite.
   * @param formRequestId The form request id.
   * @return Success
   */
  ResendInvite(formRequestId: string): __Observable<ApiResponseOkResponse> {
    return this.ResendInviteResponse(formRequestId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Cancel the Invite.
   * @param params The `InvitesService.CancelInviteParams` containing the following parameters:
   *
   * - `formRequestId`: The form request id.
   *
   * - `patientId`: The patient id.
   *
   * @return Success
   */
  CancelInviteResponse(params: InvitesService.CancelInviteParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SymplastForms/Invites/${encodeURIComponent(String(params.formRequestId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Cancel the Invite.
   * @param params The `InvitesService.CancelInviteParams` containing the following parameters:
   *
   * - `formRequestId`: The form request id.
   *
   * - `patientId`: The patient id.
   *
   * @return Success
   */
  CancelInvite(params: InvitesService.CancelInviteParams): __Observable<ApiResponseOkResponse> {
    return this.CancelInviteResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module InvitesService {

  /**
   * Parameters for GetInvites
   */
  export interface GetInvitesParams {

    /**
     * The status string.
     */
    status?: string;

    /**
     * The patient last name.
     */
    patientLastName?: string;

    /**
     * The patient first name.
     */
    patientFirstName?: string;
  }

  /**
   * Parameters for CancelInvite
   */
  export interface CancelInviteParams {

    /**
     * The form request id.
     */
    formRequestId: string;

    /**
     * The patient id.
     */
    patientId?: number;
  }
}

export { InvitesService }
