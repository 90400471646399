import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'age',
    standalone: true,
})
export class AgePipe implements PipeTransform {
    transform(birthDate: Date): number {
        const birthDateObj = new Date(birthDate.toString());
        const ageDifMs = Date.now() - birthDateObj.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch

        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
}
