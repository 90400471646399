/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTemplateVariable } from '../models/api-response-ienumerable-template-variable';
import { ApiResponseIEnumerableTemplate } from '../models/api-response-ienumerable-template';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { CreateUpdateTemplateRequest } from '../models/create-update-template-request';
import { ApiResponseString } from '../models/api-response-string';
@Injectable({
  providedIn: 'root',
})
class TemplatesService extends __BaseService {
  static readonly GetTemplateVariablesPath = '/Variables';
  static readonly GetTemplatesPath = '/Templates';
  static readonly CreateTemplatePath = '/Templates';
  static readonly UpdateTemplatePath = '/Templates/{templateId}';
  static readonly AddImagesPath = '/Templates/Images';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all template variables.
   * @return Success
   */
  GetTemplateVariablesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTemplateVariable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Variables`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTemplateVariable>;
      })
    );
  }
  /**
   * Gets all template variables.
   * @return Success
   */
  GetTemplateVariables(): __Observable<ApiResponseIEnumerableTemplateVariable> {
    return this.GetTemplateVariablesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTemplateVariable)
    );
  }

  /**
   * Gets all templates.
   * @param templateTypes The template Types.
   * @return Success
   */
  GetTemplatesResponse(templateTypes?: 'AppointmentInvalidUserResponse' | 'VcUserConfirm' | 'VcNotConfirmedByUser' | 'VcNew' | 'PatientsWelcome' | 'NotRegisteredPatientVcNew' | 'ApptUserConfirm' | 'ApptNotConfirmedByUser' | 'ApptNew' | 'ApptConfirmedByUser' | 'AppointmentStopTexts' | 'ApptCancellation' | 'NotRegisteredPatientVcUserConfirm' | 'VcConfirmedByUser' | 'BirthdaySms' | 'BirthdayEmail' | 'PatientInvitationCode' | 'PaymentRequestReminder' | 'PaymentRequestNotification' | 'UserCreatedSms' | 'UserForgottenPasswordSms' | 'UserResetPasswordSms' | 'UserMobilePhoneConfirmSms' | 'LockedUserSms' | 'DisabledUserSms' | 'NotConfirmedVcEmail' | 'ConfirmedVcEmail' | 'NotConfirmedApptEmail' | 'ConfirmedApptEmail' | 'OtherAppointmentsUnConfirmedVcEmailPart' | 'OtherAppointmentsConfirmedVcEmailPart' | 'OtherAppointmentsUnConfirmedApptEmailPart' | 'OtherAppointmentsConfirmedApptEmailPart' | 'AppointmentConfirmationInvalidUserResponse' | 'SecurityNotificationNewLoginEmail' | 'SecurityNotificationNewLoginSms' | 'SecurityNotificationNewLoginPush' | 'SecurityNotificationLockedUserEmail' | 'SecurityNotificationLockedUserSms' | 'SecurityNotificationLockedUserPush' | 'VcNewEmail' | 'NotRegisteredPatientVcNewEmail' | 'ApptNewEmail' | 'MultiFactorAuthenticationSms' | 'MultiFactorAuthenticationEmail' | 'ExpiredCardsNotificationEmail'): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (templateTypes != null) __params = __params.set('templateTypes', templateTypes.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTemplate>;
      })
    );
  }
  /**
   * Gets all templates.
   * @param templateTypes The template Types.
   * @return Success
   */
  GetTemplates(templateTypes?: 'AppointmentInvalidUserResponse' | 'VcUserConfirm' | 'VcNotConfirmedByUser' | 'VcNew' | 'PatientsWelcome' | 'NotRegisteredPatientVcNew' | 'ApptUserConfirm' | 'ApptNotConfirmedByUser' | 'ApptNew' | 'ApptConfirmedByUser' | 'AppointmentStopTexts' | 'ApptCancellation' | 'NotRegisteredPatientVcUserConfirm' | 'VcConfirmedByUser' | 'BirthdaySms' | 'BirthdayEmail' | 'PatientInvitationCode' | 'PaymentRequestReminder' | 'PaymentRequestNotification' | 'UserCreatedSms' | 'UserForgottenPasswordSms' | 'UserResetPasswordSms' | 'UserMobilePhoneConfirmSms' | 'LockedUserSms' | 'DisabledUserSms' | 'NotConfirmedVcEmail' | 'ConfirmedVcEmail' | 'NotConfirmedApptEmail' | 'ConfirmedApptEmail' | 'OtherAppointmentsUnConfirmedVcEmailPart' | 'OtherAppointmentsConfirmedVcEmailPart' | 'OtherAppointmentsUnConfirmedApptEmailPart' | 'OtherAppointmentsConfirmedApptEmailPart' | 'AppointmentConfirmationInvalidUserResponse' | 'SecurityNotificationNewLoginEmail' | 'SecurityNotificationNewLoginSms' | 'SecurityNotificationNewLoginPush' | 'SecurityNotificationLockedUserEmail' | 'SecurityNotificationLockedUserSms' | 'SecurityNotificationLockedUserPush' | 'VcNewEmail' | 'NotRegisteredPatientVcNewEmail' | 'ApptNewEmail' | 'MultiFactorAuthenticationSms' | 'MultiFactorAuthenticationEmail' | 'ExpiredCardsNotificationEmail'): __Observable<ApiResponseIEnumerableTemplate> {
    return this.GetTemplatesResponse(templateTypes).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTemplate)
    );
  }

  /**
   * Create notification templates.
   * @param templateCreateRequest Template create Request.
   * @return Success
   */
  CreateTemplateResponse(templateCreateRequest?: CreateUpdateTemplateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = templateCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Create notification templates.
   * @param templateCreateRequest Template create Request.
   * @return Success
   */
  CreateTemplate(templateCreateRequest?: CreateUpdateTemplateRequest): __Observable<ApiResponseOkResponse> {
    return this.CreateTemplateResponse(templateCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates notification templates.
   * @param params The `TemplatesService.UpdateTemplateParams` containing the following parameters:
   *
   * - `templateId`: The template Id.
   *
   * - `templateUpdateRequest`: template Update Request.
   *
   * @return Success
   */
  UpdateTemplateResponse(params: TemplatesService.UpdateTemplateParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.templateUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Templates/${encodeURIComponent(String(params.templateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates notification templates.
   * @param params The `TemplatesService.UpdateTemplateParams` containing the following parameters:
   *
   * - `templateId`: The template Id.
   *
   * - `templateUpdateRequest`: template Update Request.
   *
   * @return Success
   */
  UpdateTemplate(params: TemplatesService.UpdateTemplateParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateTemplateResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Adds the image for template.
   * @param uploadFile undefined
   * @return Success
   */
  AddImagesResponse(uploadFile?: Blob): __Observable<__StrictHttpResponse<ApiResponseString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (uploadFile != null) { __formData.append('uploadFile', uploadFile as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Templates/Images`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseString>;
      })
    );
  }
  /**
   * Adds the image for template.
   * @param uploadFile undefined
   * @return Success
   */
  AddImages(uploadFile?: Blob): __Observable<ApiResponseString> {
    return this.AddImagesResponse(uploadFile).pipe(
      __map(_r => _r.body as ApiResponseString)
    );
  }
}

module TemplatesService {

  /**
   * Parameters for UpdateTemplate
   */
  export interface UpdateTemplateParams {

    /**
     * The template Id.
     */
    templateId: string;

    /**
     * template Update Request.
     */
    templateUpdateRequest?: CreateUpdateTemplateRequest;
  }
}

export { TemplatesService }
