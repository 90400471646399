import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ConfigurationApplicationService } from '@symplast/services/shared';
import { map, Observable } from 'rxjs';
import { TenantsEssentialsService } from '../services/tenants-essentials.service';

@Injectable({ providedIn: 'root' })
export class AppConfigurationGuard implements CanActivate {
    constructor(
        private tenanstEssentialsService: TenantsEssentialsService,
        private configurationApplicationService: ConfigurationApplicationService,
    ) {}

    public canActivate(): Observable<boolean> {
        const tenant = this.tenanstEssentialsService.tenant;

        // TODO: (a.vakhrushin) add catchError and define what to do in that case
        return this.configurationApplicationService.configureAppServices(tenant).pipe(map(() => true));
    }
}
