import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({ name: 'timeRedable' })
export class TimeRedablePipe implements PipeTransform {
    transform(time: string): string {
        if (!time) {
            return '';
        }

        return moment(time, 'HH:mm:ss').format('LT');
    }
}
