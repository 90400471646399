export class LoadAppSettings {
    public static readonly type = '[AppSettings] Load';
    constructor(public isFinancialsEnabled: boolean) {}
}

export class LoadTasksAppSettings {
    public static readonly type = '[AppSettings] Tasks Settings Load';
}

export class LoadIntegrationAppSettings {
    public static readonly type = '[AppSettings] Integration Settings Load';
    constructor(public isFinancialsEnabled: boolean) {}
}

export class SetTenant {
    public static readonly type = '[AppSettings] Set Tenant Id';
    constructor(public tenantId: number, public tenantName: string) {}
}
