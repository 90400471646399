import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { WebStorageService } from '@symplast/services/shared';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-announcement',
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss'],
    standalone: true,
    imports: [AngularSvgIconModule, RouterModule, MatIconModule, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementComponent implements OnInit {
    @Input()
    public url: string;

    @Input() public queryParams = {};

    @Input()
    public showClose: boolean;

    public hidden: boolean;

    constructor(private webStorage: WebStorageService) {}

    private get key(): string {
        return 'announcement_' + this.url;
    }

    public ngOnInit(): void {
        if (this.showClose && this.webStorage.local.getItem(this.key)) {
            this.hidden = true;
        }
    }

    public hide(): void {
        this.hidden = true;
        this.webStorage.local.setItem(this.key, true);
    }
}
