import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_QUANTITY_FORMAT } from '@symplast/app-constants';

@Pipe({
    name: 'negative',
})
export class NegativePipe implements PipeTransform {
    transform(value: number): string {
        const stringValue = Math.abs(value).toLocaleString('en-US', DEFAULT_QUANTITY_FORMAT);

        if (value < 0) {
            return `(${stringValue})`;
        } else {
            return stringValue;
        }
    }
}
