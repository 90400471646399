import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnInit, TemplateRef, inject } from '@angular/core';
import { PolymorphContent } from './polymorph-content.model';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'app-polymorph-content',
    template: `
        <ng-container *ngIf="content" [ngSwitch]="contentType">
            <ng-container *ngSwitchCase="'string'">
                <div [innerHTML]="content"></div>
            </ng-container>

            <ng-container *ngSwitchCase="'template'">
                <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletInjector]="injector"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'component'">
                <ng-container *ngComponentOutlet="content; injector: injector"></ng-container>
            </ng-container>
        </ng-container>
    `,
    styles: [
        `
            :host {
                overflow-y: auto;
            }
        `,
    ],
})
export class PolymorphContentComponent implements OnInit {
    @Input() content: PolymorphContent;
    public contentType: 'string' | 'template' | 'component';
    public injector = inject(Injector);

    public ngOnInit(): void {
        this.contentType = this.getContentType();
    }

    private getContentType(): 'string' | 'template' | 'component' {
        if (typeof this.content === 'string') {
            return 'string';
        } else if (this.content instanceof TemplateRef) {
            return 'template';
        } else {
            return 'component';
        }
    }
}
