/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseDocumentSharingInformation } from '../models/api-response-document-sharing-information';
import { ApiResponseDocument } from '../models/api-response-document';
import { ApiPagedResponseICollectionDocument } from '../models/api-paged-response-icollection-document';
import { ApiResponseCountResponse } from '../models/api-response-count-response';
import { ApiResponseDocumentsCount } from '../models/api-response-documents-count';
@Injectable({
  providedIn: 'root',
})
class DocumentsService extends __BaseService {
  static readonly UpdateDocumentSharingInformationPath = '/Documents/{documentId}/SharingInfo';
  static readonly GetDocumentSharingInformationPath = '/Documents/{documentId}/SharingInfo';
  static readonly GetDocumentByIdPath = '/Documents/{documentId}';
  static readonly GetDocumentsPath = '/Documents';
  static readonly UploadDocumentPath = '/Documents';
  static readonly GetDocumentsCountPath = '/Documents/Count';
  static readonly GetDocumentsCountByTabNamePath = '/Documents/TabNames/Count';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Updates the document sharing information.
   * @param params The `DocumentsService.UpdateDocumentSharingInformationParams` containing the following parameters:
   *
   * - `documentId`: The document identifier.
   *
   * - `SharedWithPatient`: Gets or sets a value indicating whether shared with patient.
   *
   * - `Description`: Gets or sets the description.
   *
   * @return Success
   */
  UpdateDocumentSharingInformationResponse(params: DocumentsService.UpdateDocumentSharingInformationParams): __Observable<__StrictHttpResponse<ApiResponseDocumentSharingInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.SharedWithPatient != null) __params = __params.set('SharedWithPatient', params.SharedWithPatient.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Documents/${encodeURIComponent(String(params.documentId))}/SharingInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDocumentSharingInformation>;
      })
    );
  }
  /**
   * Updates the document sharing information.
   * @param params The `DocumentsService.UpdateDocumentSharingInformationParams` containing the following parameters:
   *
   * - `documentId`: The document identifier.
   *
   * - `SharedWithPatient`: Gets or sets a value indicating whether shared with patient.
   *
   * - `Description`: Gets or sets the description.
   *
   * @return Success
   */
  UpdateDocumentSharingInformation(params: DocumentsService.UpdateDocumentSharingInformationParams): __Observable<ApiResponseDocumentSharingInformation> {
    return this.UpdateDocumentSharingInformationResponse(params).pipe(
      __map(_r => _r.body as ApiResponseDocumentSharingInformation)
    );
  }

  /**
   * Gets the document sharing information.
   * @param documentId The document identifier.
   * @return Success
   */
  GetDocumentSharingInformationResponse(documentId: number): __Observable<__StrictHttpResponse<ApiResponseDocumentSharingInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Documents/${encodeURIComponent(String(documentId))}/SharingInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDocumentSharingInformation>;
      })
    );
  }
  /**
   * Gets the document sharing information.
   * @param documentId The document identifier.
   * @return Success
   */
  GetDocumentSharingInformation(documentId: number): __Observable<ApiResponseDocumentSharingInformation> {
    return this.GetDocumentSharingInformationResponse(documentId).pipe(
      __map(_r => _r.body as ApiResponseDocumentSharingInformation)
    );
  }

  /**
   * Gets the document by identifier.
   * @param documentId The document identifier.
   * @return Success
   */
  GetDocumentByIdResponse(documentId: number): __Observable<__StrictHttpResponse<ApiResponseDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Documents/${encodeURIComponent(String(documentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDocument>;
      })
    );
  }
  /**
   * Gets the document by identifier.
   * @param documentId The document identifier.
   * @return Success
   */
  GetDocumentById(documentId: number): __Observable<ApiResponseDocument> {
    return this.GetDocumentByIdResponse(documentId).pipe(
      __map(_r => _r.body as ApiResponseDocument)
    );
  }

  /**
   * Gets the documents.
   * @param params The `DocumentsService.GetDocumentsParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `tabNames`: The tab names.
   *
   * - `pageSize`: Size of the page.
   *
   * - `pageIndex`: Index of the page.
   *
   * @return Success
   */
  GetDocumentsResponse(params: DocumentsService.GetDocumentsParams): __Observable<__StrictHttpResponse<ApiPagedResponseICollectionDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    (params.tabNames || []).forEach(val => {if (val != null) __params = __params.append('tabNames', val.toString())});
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseICollectionDocument>;
      })
    );
  }
  /**
   * Gets the documents.
   * @param params The `DocumentsService.GetDocumentsParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `tabNames`: The tab names.
   *
   * - `pageSize`: Size of the page.
   *
   * - `pageIndex`: Index of the page.
   *
   * @return Success
   */
  GetDocuments(params: DocumentsService.GetDocumentsParams): __Observable<ApiPagedResponseICollectionDocument> {
    return this.GetDocumentsResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseICollectionDocument)
    );
  }

  /**
   * Uploads the document.
   * @param params The `DocumentsService.UploadDocumentParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `file`:
   *
   * - `TabName`: Gets or sets the tab name.
   *
   * - `SharedWithPatient`: Gets or sets the shared with patient.
   *
   * - `PhysicianId`: Gets or sets the physician id.
   *
   * - `LocationId`: Gets or sets the location id.
   *
   * - `FileName`: Gets or sets the modified file name.
   *
   * - `ExtensionType`: Gets or sets the extension type.
   *
   * - `Description`: Gets or sets the description.
   *
   * @return Success
   */
  UploadDocumentResponse(params: DocumentsService.UploadDocumentParams): __Observable<__StrictHttpResponse<ApiResponseDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.TabName != null) __params = __params.set('TabName', params.TabName.toString());
    if (params.SharedWithPatient != null) __params = __params.set('SharedWithPatient', params.SharedWithPatient.toString());
    if (params.PhysicianId != null) __params = __params.set('PhysicianId', params.PhysicianId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.FileName != null) __params = __params.set('FileName', params.FileName.toString());
    if (params.ExtensionType != null) __params = __params.set('ExtensionType', params.ExtensionType.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDocument>;
      })
    );
  }
  /**
   * Uploads the document.
   * @param params The `DocumentsService.UploadDocumentParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `file`:
   *
   * - `TabName`: Gets or sets the tab name.
   *
   * - `SharedWithPatient`: Gets or sets the shared with patient.
   *
   * - `PhysicianId`: Gets or sets the physician id.
   *
   * - `LocationId`: Gets or sets the location id.
   *
   * - `FileName`: Gets or sets the modified file name.
   *
   * - `ExtensionType`: Gets or sets the extension type.
   *
   * - `Description`: Gets or sets the description.
   *
   * @return Success
   */
  UploadDocument(params: DocumentsService.UploadDocumentParams): __Observable<ApiResponseDocument> {
    return this.UploadDocumentResponse(params).pipe(
      __map(_r => _r.body as ApiResponseDocument)
    );
  }

  /**
   * Gets the documents count.
   * @param params The `DocumentsService.GetDocumentsCountParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `tabNames`: The tab names.
   *
   * @return Success
   */
  GetDocumentsCountResponse(params: DocumentsService.GetDocumentsCountParams): __Observable<__StrictHttpResponse<ApiResponseCountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    (params.tabNames || []).forEach(val => {if (val != null) __params = __params.append('tabNames', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Documents/Count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCountResponse>;
      })
    );
  }
  /**
   * Gets the documents count.
   * @param params The `DocumentsService.GetDocumentsCountParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `tabNames`: The tab names.
   *
   * @return Success
   */
  GetDocumentsCount(params: DocumentsService.GetDocumentsCountParams): __Observable<ApiResponseCountResponse> {
    return this.GetDocumentsCountResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCountResponse)
    );
  }

  /**
   * Gets the name of the documents count by tab.
   * @param params The `DocumentsService.GetDocumentsCountByTabNameParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `tabNames`: The tab names.
   *
   * @return Success
   */
  GetDocumentsCountByTabNameResponse(params: DocumentsService.GetDocumentsCountByTabNameParams): __Observable<__StrictHttpResponse<ApiResponseDocumentsCount>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    (params.tabNames || []).forEach(val => {if (val != null) __params = __params.append('tabNames', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Documents/TabNames/Count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDocumentsCount>;
      })
    );
  }
  /**
   * Gets the name of the documents count by tab.
   * @param params The `DocumentsService.GetDocumentsCountByTabNameParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `tabNames`: The tab names.
   *
   * @return Success
   */
  GetDocumentsCountByTabName(params: DocumentsService.GetDocumentsCountByTabNameParams): __Observable<ApiResponseDocumentsCount> {
    return this.GetDocumentsCountByTabNameResponse(params).pipe(
      __map(_r => _r.body as ApiResponseDocumentsCount)
    );
  }
}

module DocumentsService {

  /**
   * Parameters for UpdateDocumentSharingInformation
   */
  export interface UpdateDocumentSharingInformationParams {

    /**
     * The document identifier.
     */
    documentId: number;

    /**
     * Gets or sets a value indicating whether shared with patient.
     */
    SharedWithPatient: boolean;

    /**
     * Gets or sets the description.
     */
    Description?: string;
  }

  /**
   * Parameters for GetDocuments
   */
  export interface GetDocumentsParams {

    /**
     * The patient identifier.
     */
    patientId: number;

    /**
     * The tab names.
     */
    tabNames?: Array<string>;

    /**
     * Size of the page.
     */
    pageSize?: number;

    /**
     * Index of the page.
     */
    pageIndex?: number;
  }

  /**
   * Parameters for UploadDocument
   */
  export interface UploadDocumentParams {

    /**
     * The patient identifier.
     */
    patientId: number;
    file?: Blob;

    /**
     * Gets or sets the tab name.
     */
    TabName?: string;

    /**
     * Gets or sets the shared with patient.
     */
    SharedWithPatient?: boolean;

    /**
     * Gets or sets the physician id.
     */
    PhysicianId?: number;

    /**
     * Gets or sets the location id.
     */
    LocationId?: number;

    /**
     * Gets or sets the modified file name.
     */
    FileName?: string;

    /**
     * Gets or sets the extension type.
     */
    ExtensionType?: string;

    /**
     * Gets or sets the description.
     */
    Description?: string;
  }

  /**
   * Parameters for GetDocumentsCount
   */
  export interface GetDocumentsCountParams {

    /**
     * The patient identifier.
     */
    patientId: number;

    /**
     * The tab names.
     */
    tabNames?: Array<string>;
  }

  /**
   * Parameters for GetDocumentsCountByTabName
   */
  export interface GetDocumentsCountByTabNameParams {

    /**
     * The patient identifier.
     */
    patientId: number;

    /**
     * The tab names.
     */
    tabNames?: Array<string>;
  }
}

export { DocumentsService }
