<div
    data-e2e="symplast-toggle"
    class="toggle"
    [class.toggle--checked]="checked"
    [class.toggle--readonly]="readonly"
    [attr.tabindex]="readonly || disabled ? -1 : 0"
    [attr.disabled]="disabled ? 'true' : null"
    (keypress)="onKeyPressHandler($event)"
    (blur)="onTouched()">
    <label class="toggle__label" [class.toggle__label--reverse]="labelPosition === 'start'">
        <div class="toggle__thumb-container" [ngStyle]="{ 'width.px': thumbContainerWidth }">
            <input
                class="toggle__input"
                data-e2e="symplast-toggle__checkbox-input"
                type="checkbox"
                [checked]="checked"
                (change)="onChangeHandler($event)" />

            <span class="toggle__thumb" [ngStyle]="{ transform: thumbXPosition }"></span>

            <span
                data-e2e="symplast-toggle__switch-on-label"
                class="toggle__switch-label toggle__switch-label--on"
                [class.toggle__switch-label--visible]="checked">
                {{ switchOnLabel }}
            </span>
            <span
                data-e2e="symplast-toggle__switch-off-label"
                class="toggle__switch-label toggle__switch-label--off"
                [class.toggle__switch-label--visible]="!checked">
                {{ switchOffLabel }}
            </span>
        </div>
        <span class="toggle__content" data-e2e="symplast-toggle__content">
            <span class="toggle__content__label">
                <ng-content select="symplast-toggle-label, [symplast-toggle-label], [symplastToggleLabel]"></ng-content>
            </span>
            <span class="toggle__content__description">
                <ng-content select="symplast-toggle-description, [symplast-toggle-description], [symplastToggleDescription]"></ng-content>
            </span>
        </span>
    </label>
</div>
