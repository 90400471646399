/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableScheduleTemplate } from '../models/api-response-ienumerable-schedule-template';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ScheduleTemplateCreateUpdateRequest } from '../models/schedule-template-create-update-request';
import { ApiResponseScheduleTemplateDetails } from '../models/api-response-schedule-template-details';
@Injectable({
  providedIn: 'root',
})
class ScheduleTemplatesService extends __BaseService {
  static readonly GetScheduleTemplatesPath = '/ScheduleTemplates';
  static readonly CreateScheduleTemplatePath = '/ScheduleTemplates';
  static readonly GetScheduleTemplateByIdPath = '/ScheduleTemplates/{id}';
  static readonly DeleteScheduleTemplateByIdPath = '/ScheduleTemplates/{id}';
  static readonly UpdateScheduleTemplatePath = '/ScheduleTemplates/{id}';
  static readonly SetScheduleTemplateEnabledPath = '/ScheduleTemplates/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets list of schedule templates.
   * @param params The `ScheduleTemplatesService.GetScheduleTemplatesParams` containing the following parameters:
   *
   * - `search`: The search query.
   *
   * - `providerId`: The provider id.
   *
   * - `locationId`: The location id.
   *
   * @return Success
   */
  GetScheduleTemplatesResponse(params: ScheduleTemplatesService.GetScheduleTemplatesParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableScheduleTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.providerId != null) __params = __params.set('providerId', params.providerId.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ScheduleTemplates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableScheduleTemplate>;
      })
    );
  }
  /**
   * Gets list of schedule templates.
   * @param params The `ScheduleTemplatesService.GetScheduleTemplatesParams` containing the following parameters:
   *
   * - `search`: The search query.
   *
   * - `providerId`: The provider id.
   *
   * - `locationId`: The location id.
   *
   * @return Success
   */
  GetScheduleTemplates(params: ScheduleTemplatesService.GetScheduleTemplatesParams): __Observable<ApiResponseIEnumerableScheduleTemplate> {
    return this.GetScheduleTemplatesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableScheduleTemplate)
    );
  }

  /**
   * Creates schedule template.
   * @param createRequest The create Request.
   * @return Success
   */
  CreateScheduleTemplateResponse(createRequest?: ScheduleTemplateCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ScheduleTemplates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Creates schedule template.
   * @param createRequest The create Request.
   * @return Success
   */
  CreateScheduleTemplate(createRequest?: ScheduleTemplateCreateUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.CreateScheduleTemplateResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets schedule template by id.
   * @param id The template id.
   * @return Success or No Content
   */
  GetScheduleTemplateByIdResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseScheduleTemplateDetails | ApiResponseScheduleTemplateDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ScheduleTemplates/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseScheduleTemplateDetails | ApiResponseScheduleTemplateDetails>;
      })
    );
  }
  /**
   * Gets schedule template by id.
   * @param id The template id.
   * @return Success or No Content
   */
  GetScheduleTemplateById(id: string): __Observable<ApiResponseScheduleTemplateDetails | ApiResponseScheduleTemplateDetails> {
    return this.GetScheduleTemplateByIdResponse(id).pipe(
      __map(_r => _r.body as ApiResponseScheduleTemplateDetails | ApiResponseScheduleTemplateDetails)
    );
  }

  /**
   * Deletes schedule template by id (hard delete).
   * @param id The id.
   * @return Success or No Content
   */
  DeleteScheduleTemplateByIdResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ScheduleTemplates/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes schedule template by id (hard delete).
   * @param id The id.
   * @return Success or No Content
   */
  DeleteScheduleTemplateById(id: string): __Observable<ApiResponseOkResponse | ApiResponseOkResponse> {
    return this.DeleteScheduleTemplateByIdResponse(id).pipe(
      __map(_r => _r.body as ApiResponseOkResponse | ApiResponseOkResponse)
    );
  }

  /**
   * Updates schedule template.
   * @param params The `ScheduleTemplatesService.UpdateScheduleTemplateParams` containing the following parameters:
   *
   * - `id`: The id.
   *
   * - `updateRequest`: The update Request.
   *
   * @return Success or No Content
   */
  UpdateScheduleTemplateResponse(params: ScheduleTemplatesService.UpdateScheduleTemplateParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ScheduleTemplates/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates schedule template.
   * @param params The `ScheduleTemplatesService.UpdateScheduleTemplateParams` containing the following parameters:
   *
   * - `id`: The id.
   *
   * - `updateRequest`: The update Request.
   *
   * @return Success or No Content
   */
  UpdateScheduleTemplate(params: ScheduleTemplatesService.UpdateScheduleTemplateParams): __Observable<ApiResponseOkResponse | ApiResponseOkResponse> {
    return this.UpdateScheduleTemplateResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse | ApiResponseOkResponse)
    );
  }

  /**
   * The set schedule template enabled.
   * @param params The `ScheduleTemplatesService.SetScheduleTemplateEnabledParams` containing the following parameters:
   *
   * - `isEnabled`: The is enabled.
   *
   * - `id`: The id.
   *
   * @return Success or No Content
   */
  SetScheduleTemplateEnabledResponse(params: ScheduleTemplatesService.SetScheduleTemplateEnabledParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/ScheduleTemplates/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The set schedule template enabled.
   * @param params The `ScheduleTemplatesService.SetScheduleTemplateEnabledParams` containing the following parameters:
   *
   * - `isEnabled`: The is enabled.
   *
   * - `id`: The id.
   *
   * @return Success or No Content
   */
  SetScheduleTemplateEnabled(params: ScheduleTemplatesService.SetScheduleTemplateEnabledParams): __Observable<ApiResponseOkResponse | ApiResponseOkResponse> {
    return this.SetScheduleTemplateEnabledResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse | ApiResponseOkResponse)
    );
  }
}

module ScheduleTemplatesService {

  /**
   * Parameters for GetScheduleTemplates
   */
  export interface GetScheduleTemplatesParams {

    /**
     * The search query.
     */
    search?: string;

    /**
     * The provider id.
     */
    providerId?: number;

    /**
     * The location id.
     */
    locationId?: number;
  }

  /**
   * Parameters for UpdateScheduleTemplate
   */
  export interface UpdateScheduleTemplateParams {

    /**
     * The id.
     */
    id: string;

    /**
     * The update Request.
     */
    updateRequest?: ScheduleTemplateCreateUpdateRequest;
  }

  /**
   * Parameters for SetScheduleTemplateEnabled
   */
  export interface SetScheduleTemplateEnabledParams {

    /**
     * The is enabled.
     */
    isEnabled: boolean;

    /**
     * The id.
     */
    id: string;
  }
}

export { ScheduleTemplatesService }
