import { PresetsModel } from '@syncfusion/ej2-calendars';

export const DEFAULT_PRESETS = [
    {
        label: 'Today',
        start: new Date(),
        end: new Date(),
    },
    {
        label: 'Yesterday',
        start: new Date(new Date().setDate(new Date().getDate() - 1)),
        end: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
        label: 'This Month',
        start: new Date(new Date().setDate(1)),
        end: new Date(),
    },
    {
        label: 'Last Month',
        start: new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)),
        end: new Date(new Date().setDate(0)),
    },
    {
        label: 'This Year',
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(),
    },
    {
        label: 'Last Year',
        start: new Date(new Date().getFullYear() - 1, 0, 1),
        end: new Date(new Date().getFullYear() - 1, 11, 31),
    },
] as PresetsModel[];

export const DASHBOARD_PRESETS = [
    {
        label: 'Today',
        start: new Date(),
        end: new Date(),
    },
    {
        label: 'This Month',
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    },
    {
        label: 'Last Month',
        start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    },
    {
        label: 'This Quarter',
        start: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3, 1),
        end: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3 + 3, 0),
    },
    {
        label: 'Last Quarter',
        start: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3 - 3, 1),
        end: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3, 0),
    },
    {
        label: 'Year to Date',
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(),
    },
    {
        label: 'This Year',
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(new Date().getFullYear(), 11, 31),
    },
    {
        label: 'Last Year',
        start: new Date(new Date().getFullYear() - 1, 0, 1),
        end: new Date(new Date().getFullYear() - 1, 11, 31),
    },
] as PresetsModel[];
