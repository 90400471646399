/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseGeneratePriceListDocumentResponse } from '../models/api-response-generate-price-list-document-response';
@Injectable({
  providedIn: 'root',
})
class PriceListService extends __BaseService {
  static readonly GeneratePriceListDocumentPath = '/PriceList/GenerateDocument';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The Generate Document for print.
   * @param params The `PriceListService.GeneratePriceListDocumentParams` containing the following parameters:
   *
   * - `searchQuery`: The search Query.
   *
   * - `locationId`: The location ID.
   *
   * - `itemTypeId`: The item Type ID.
   *
   * - `documentType`: The document Type.
   *
   * - `cptCodeItemsFilter`: The cpt Code Items Filter.
   *
   * - `brandId`: The brand ID.
   *
   * @return Success
   */
  GeneratePriceListDocumentResponse(params: PriceListService.GeneratePriceListDocumentParams): __Observable<__StrictHttpResponse<ApiResponseGeneratePriceListDocumentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.itemTypeId != null) __params = __params.set('itemTypeId', params.itemTypeId.toString());
    if (params.documentType != null) __params = __params.set('documentType', params.documentType.toString());
    if (params.cptCodeItemsFilter != null) __params = __params.set('cptCodeItemsFilter', params.cptCodeItemsFilter.toString());
    if (params.brandId != null) __params = __params.set('brandId', params.brandId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/PriceList/GenerateDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseGeneratePriceListDocumentResponse>;
      })
    );
  }
  /**
   * The Generate Document for print.
   * @param params The `PriceListService.GeneratePriceListDocumentParams` containing the following parameters:
   *
   * - `searchQuery`: The search Query.
   *
   * - `locationId`: The location ID.
   *
   * - `itemTypeId`: The item Type ID.
   *
   * - `documentType`: The document Type.
   *
   * - `cptCodeItemsFilter`: The cpt Code Items Filter.
   *
   * - `brandId`: The brand ID.
   *
   * @return Success
   */
  GeneratePriceListDocument(params: PriceListService.GeneratePriceListDocumentParams): __Observable<ApiResponseGeneratePriceListDocumentResponse> {
    return this.GeneratePriceListDocumentResponse(params).pipe(
      __map(_r => _r.body as ApiResponseGeneratePriceListDocumentResponse)
    );
  }
}

module PriceListService {

  /**
   * Parameters for GeneratePriceListDocument
   */
  export interface GeneratePriceListDocumentParams {

    /**
     * The search Query.
     */
    searchQuery?: string;

    /**
     * The location ID.
     */
    locationId?: number;

    /**
     * The item Type ID.
     */
    itemTypeId?: number;

    /**
     * The document Type.
     */
    documentType?: 'Pdf' | 'Xlsx';

    /**
     * The cpt Code Items Filter.
     */
    cptCodeItemsFilter?: 'Include' | 'Exclude' | 'Only';

    /**
     * The brand ID.
     */
    brandId?: number;
  }
}

export { PriceListService }
