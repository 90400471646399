import { Directive, Inject, Input, Optional } from '@angular/core';
import { SYMPLAST_DIALOG_PRESET, SYMPLAST_DIALOG_PRESET_MAP, SymplastDialogPreset } from './symplast-dialog.preset';

@Directive({
    standalone: true,
    selector: 'symplast-dialog-title, [symplast-dialog-title], [symplastDialogTitle]',
})
export class SymplastDialogTitleDirective {
    private _position!: 'left' | 'center';

    constructor(@Optional() @Inject(SYMPLAST_DIALOG_PRESET) public preset: SymplastDialogPreset) {}

    @Input()
    public get position(): 'left' | 'center' {
        return this._position || SYMPLAST_DIALOG_PRESET_MAP[this.preset]?.titlePosition || 'left';
    }
    public set position(value: 'left' | 'center') {
        this._position = value;
    }
}

@Directive({
    standalone: true,
    selector: 'symplast-dialog-header-actions, [symplast-dialog-header-actions], [symplastDialogHeaderActions]',
})
export class SymplastDialogHeaderActionsDirective {}

@Directive({
    standalone: true,
    selector: 'symplast-dialog-content, [symplast-dialog-content], [symplastDialogContent]',
})
export class SymplastDialogContentDirective {
    @Input() paddings: 'full' | 'top-bottom' | 'left-right' | 'none' = 'full';
}
