/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseInvoice } from '../models/api-response-invoice';
import { ItemGroupRequest } from '../models/item-group-request';
import { InvoiceCreateRequest } from '../models/invoice-create-request';
import { ApiResponseICollectionInvoice } from '../models/api-response-icollection-invoice';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { InvoiceUpdateRequest } from '../models/invoice-update-request';
import { ApiResponseICollectionAuditEntryHeader } from '../models/api-response-icollection-audit-entry-header';
import { ActionResult } from '../models/action-result';
import { ApiResponseItemDiscountRate } from '../models/api-response-item-discount-rate';
import { ApiResponseString } from '../models/api-response-string';
import { ApiResponseICollectionInvoiceBalance } from '../models/api-response-icollection-invoice-balance';
@Injectable({
    providedIn: 'root',
})
class InvoicesService extends __BaseService {
    static readonly AddItemGroupToInvoicePath = '/Invoices/Invoices/{invoiceId}/ItemGroups';
    static readonly AddMembershipToInvoicePath = '/Invoices/Invoices/{invoiceId}/Memberships/{membershipId}';
    static readonly CreateInvoicePath = '/Invoices';
    static readonly GetInvoicesPath = '/Invoices';
    static readonly CreateInvoiceForTenantPath = '/Tenants/{tenantId}/Invoices';
    static readonly GetInvoicesForTenantPath = '/Tenants/{tenantId}/Invoices';
    static readonly DeleteInvoicePath = '/Invoices/{invoiceId}';
    static readonly GetInvoiceByIdPath = '/Invoices/{invoiceId}';
    static readonly UpdateInvoicePath = '/Invoices/{invoiceId}';
    static readonly DeleteInvoiceForTenantPath = '/Tenants/{tenantId}/Invoices/{invoiceId}';
    static readonly UpdateInvoiceForTenantPath = '/Tenants/{tenantId}/Invoices/{invoiceId}';
    static readonly DeleteInvoiceMembershipPath = '/Invoices/Invoices/{invoiceId}/Memberships/{invoiceMembershipId}';
    static readonly GetInvoiceHistoryPath = '/Invoices/{invoiceId}/History';
    static readonly GetInvoiceHtmlByIdPath = '/Invoices/{invoiceId}/Html';
    static readonly GetInvoicePdfByIdPath = '/Invoices/{invoiceId}/Pdf';
    static readonly GetInvoiceItemItemDiscountPath = '/Invoices/Invoices/{invoiceId}/InvoiceItems/{invoiceItemId}/DiscountRate';
    static readonly GetNextInvoiceNumberPath = '/Invoices/NextInvoiceNumber';
    static readonly GetNextInvoiceNumberForTenantPath = '/Tenants/{tenantId}/NextInvoiceNumber';
    static readonly UpdateInvoiceStatusPath = '/Invoices/{invoiceId}/Status';
    static readonly UpdateInvoiceStatusForTenantPath = '/Tenants/{tenantId}/Invoices/{invoiceId}/Status';
    static readonly UpdateIsViewedByPatientFlagForInvoicePath = '/Invoices/{invoiceId}/IsViewedByPatient';
    static readonly GetInvoicesBalancePath = '/Tenants/{tenantId}/Invoices/Balance';
    static readonly UpdateSharedWithPatientFlagForInvoicePath = '/Invoices/{invoiceId}/SharedWithPatient';
    static readonly UpdateLegalTextPath = '/Tenants/{tenantId}/Invoices/{invoiceId}/LegalTexts';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Add ItemGroup items to Invoice.
     * @param params The `InvoicesService.AddItemGroupToInvoiceParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToInvoiceResponse(
        params: InvoicesService.AddItemGroupToInvoiceParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.itemGroupRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Invoices/Invoices/${encodeURIComponent(String(params.invoiceId))}/ItemGroups`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Add ItemGroup items to Invoice.
     * @param params The `InvoicesService.AddItemGroupToInvoiceParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToInvoice(params: InvoicesService.AddItemGroupToInvoiceParams): __Observable<ApiResponseInvoice> {
        return this.AddItemGroupToInvoiceResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * The add membership.
     * @param params The `InvoicesService.AddMembershipToInvoiceParams` containing the following parameters:
     *
     * - `membershipId`: The membership id.
     *
     * - `invoiceId`: The invoice id.
     *
     * @return Success
     */
    AddMembershipToInvoiceResponse(
        params: InvoicesService.AddMembershipToInvoiceParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl +
                `/Invoices/Invoices/${encodeURIComponent(String(params.invoiceId))}/Memberships/${encodeURIComponent(
                    String(params.membershipId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * The add membership.
     * @param params The `InvoicesService.AddMembershipToInvoiceParams` containing the following parameters:
     *
     * - `membershipId`: The membership id.
     *
     * - `invoiceId`: The invoice id.
     *
     * @return Success
     */
    AddMembershipToInvoice(params: InvoicesService.AddMembershipToInvoiceParams): __Observable<ApiResponseInvoice> {
        return this.AddMembershipToInvoiceResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Creates an Invoice.
     * @param invoiceCreateRequest The invoice create request.
     * @return Success or Created
     */
    CreateInvoiceResponse(
        invoiceCreateRequest?: InvoiceCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice | ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = invoiceCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/Invoices`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice | ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Creates an Invoice.
     * @param invoiceCreateRequest The invoice create request.
     * @return Success or Created
     */
    CreateInvoice(invoiceCreateRequest?: InvoiceCreateRequest): __Observable<ApiResponseInvoice | ApiResponseInvoice> {
        return this.CreateInvoiceResponse(invoiceCreateRequest).pipe(__map((_r) => _r.body as ApiResponseInvoice | ApiResponseInvoice));
    }

    /**
     * Gets the invoices by the search criteria.
     * @param params The `InvoicesService.GetInvoicesParams` containing the following parameters:
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether invoices need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OnlyBasedOnClaims`: Gets or sets a value indicating whether to show only invoices based on claims.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `IsInsuranceRelated`: Gets or sets the is insurance related.
     *
     * - `InvoiceNumberQuery`: Gets or sets the invoice number query for invoice number to contain.
     *
     * - `IncludePayments`: Gets or sets the include payments.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeInvoiceItems`: Gets or sets a value indicating whether to include invoice items or not.
     *
     * - `IncludeIncomeType`: Gets or sets the include income type.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Success
     */
    GetInvoicesResponse(params: InvoicesService.GetInvoicesParams): __Observable<__StrictHttpResponse<ApiResponseICollectionInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.Take != null) __params = __params.set('Take', params.Take.toString());
        if (params.Status != null) __params = __params.set('Status', params.Status.toString());
        if (params.SortingRequest != null) __params = __params.set('SortingRequest', params.SortingRequest.toString());
        if (params.SortingDirectionRequest != null)
            __params = __params.set('SortingDirectionRequest', params.SortingDirectionRequest.toString());
        if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
        if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
        if (params.OnlyBasedOnClaims != null) __params = __params.set('OnlyBasedOnClaims', params.OnlyBasedOnClaims.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
        if (params.IsInsuranceRelated != null) __params = __params.set('IsInsuranceRelated', params.IsInsuranceRelated.toString());
        if (params.InvoiceNumberQuery != null) __params = __params.set('InvoiceNumberQuery', params.InvoiceNumberQuery.toString());
        if (params.IncludePayments != null) __params = __params.set('IncludePayments', params.IncludePayments.toString());
        if (params.IncludePatientName != null) __params = __params.set('IncludePatientName', params.IncludePatientName.toString());
        if (params.IncludeInvoiceItems != null) __params = __params.set('IncludeInvoiceItems', params.IncludeInvoiceItems.toString());
        if (params.IncludeIncomeType != null) __params = __params.set('IncludeIncomeType', params.IncludeIncomeType.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        if (params.AppointmentId != null) __params = __params.set('AppointmentId', params.AppointmentId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Invoices`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionInvoice>;
            }),
        );
    }
    /**
     * Gets the invoices by the search criteria.
     * @param params The `InvoicesService.GetInvoicesParams` containing the following parameters:
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether invoices need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OnlyBasedOnClaims`: Gets or sets a value indicating whether to show only invoices based on claims.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `IsInsuranceRelated`: Gets or sets the is insurance related.
     *
     * - `InvoiceNumberQuery`: Gets or sets the invoice number query for invoice number to contain.
     *
     * - `IncludePayments`: Gets or sets the include payments.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeInvoiceItems`: Gets or sets a value indicating whether to include invoice items or not.
     *
     * - `IncludeIncomeType`: Gets or sets the include income type.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Success
     */
    GetInvoices(params: InvoicesService.GetInvoicesParams): __Observable<ApiResponseICollectionInvoice> {
        return this.GetInvoicesResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionInvoice));
    }

    /**
     * Creates an Invoice.
     * @param params The `InvoicesService.CreateInvoiceForTenantParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `tenantId`: The tenantId.
     *
     * - `invoiceCreateRequest`: The invoice create request.
     *
     * @return Success or Created
     */
    CreateInvoiceForTenantResponse(
        params: InvoicesService.CreateInvoiceForTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice | ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());

        __body = params.invoiceCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/Invoices`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice | ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Creates an Invoice.
     * @param params The `InvoicesService.CreateInvoiceForTenantParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `tenantId`: The tenantId.
     *
     * - `invoiceCreateRequest`: The invoice create request.
     *
     * @return Success or Created
     */
    CreateInvoiceForTenant(params: InvoicesService.CreateInvoiceForTenantParams): __Observable<ApiResponseInvoice | ApiResponseInvoice> {
        return this.CreateInvoiceForTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice | ApiResponseInvoice));
    }

    /**
     * Gets the invoices by the search criteria.
     * @param params The `InvoicesService.GetInvoicesForTenantParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `requestByPatientId`: The request by patient identifier.
     *
     * - `includeServiceDate`: The include date of service.
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether invoices need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OnlyBasedOnClaims`: Gets or sets a value indicating whether to show only invoices based on claims.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `IsInsuranceRelated`: Gets or sets the is insurance related.
     *
     * - `InvoiceNumberQuery`: Gets or sets the invoice number query for invoice number to contain.
     *
     * - `InvoiceItemIds`: Gets or sets the invoice item ids.
     *
     * - `InvoiceIds`: Gets or sets the invoice ids.
     *
     * - `IncludePayments`: Gets or sets the include payments.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeInvoiceItems`: Gets or sets a value indicating whether to include invoice items or not.
     *
     * - `IncludeIncomeType`: Gets or sets the include income type.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Success
     */
    GetInvoicesForTenantResponse(
        params: InvoicesService.GetInvoicesForTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());

        if (params.requestByPatientId != null) __params = __params.set('requestByPatientId', params.requestByPatientId.toString());
        if (params.includeServiceDate != null) __params = __params.set('includeServiceDate', params.includeServiceDate.toString());
        if (params.Take != null) __params = __params.set('Take', params.Take.toString());
        if (params.Status != null) __params = __params.set('Status', params.Status.toString());
        if (params.SortingRequest != null) __params = __params.set('SortingRequest', params.SortingRequest.toString());
        if (params.SortingDirectionRequest != null)
            __params = __params.set('SortingDirectionRequest', params.SortingDirectionRequest.toString());
        if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
        if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
        if (params.OnlyBasedOnClaims != null) __params = __params.set('OnlyBasedOnClaims', params.OnlyBasedOnClaims.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
        if (params.IsInsuranceRelated != null) __params = __params.set('IsInsuranceRelated', params.IsInsuranceRelated.toString());
        if (params.InvoiceNumberQuery != null) __params = __params.set('InvoiceNumberQuery', params.InvoiceNumberQuery.toString());
        (params.InvoiceItemIds || []).forEach((val) => {
            if (val != null) __params = __params.append('InvoiceItemIds', val.toString());
        });
        (params.InvoiceIds || []).forEach((val) => {
            if (val != null) __params = __params.append('InvoiceIds', val.toString());
        });
        if (params.IncludePayments != null) __params = __params.set('IncludePayments', params.IncludePayments.toString());
        if (params.IncludePatientName != null) __params = __params.set('IncludePatientName', params.IncludePatientName.toString());
        if (params.IncludeInvoiceItems != null) __params = __params.set('IncludeInvoiceItems', params.IncludeInvoiceItems.toString());
        if (params.IncludeIncomeType != null) __params = __params.set('IncludeIncomeType', params.IncludeIncomeType.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        if (params.AppointmentId != null) __params = __params.set('AppointmentId', params.AppointmentId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/Invoices`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionInvoice>;
            }),
        );
    }
    /**
     * Gets the invoices by the search criteria.
     * @param params The `InvoicesService.GetInvoicesForTenantParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `requestByPatientId`: The request by patient identifier.
     *
     * - `includeServiceDate`: The include date of service.
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether invoices need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OnlyBasedOnClaims`: Gets or sets a value indicating whether to show only invoices based on claims.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `IsInsuranceRelated`: Gets or sets the is insurance related.
     *
     * - `InvoiceNumberQuery`: Gets or sets the invoice number query for invoice number to contain.
     *
     * - `InvoiceItemIds`: Gets or sets the invoice item ids.
     *
     * - `InvoiceIds`: Gets or sets the invoice ids.
     *
     * - `IncludePayments`: Gets or sets the include payments.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeInvoiceItems`: Gets or sets a value indicating whether to include invoice items or not.
     *
     * - `IncludeIncomeType`: Gets or sets the include income type.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Success
     */
    GetInvoicesForTenant(params: InvoicesService.GetInvoicesForTenantParams): __Observable<ApiResponseICollectionInvoice> {
        return this.GetInvoicesForTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionInvoice));
    }

    /**
     * Deletes the invoice.
     * @param params The `InvoicesService.DeleteInvoiceParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason for the invoice being deleted.
     *
     * @return Success
     */
    DeleteInvoiceResponse(params: InvoicesService.DeleteInvoiceParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/Invoices/${encodeURIComponent(String(params.invoiceId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the invoice.
     * @param params The `InvoicesService.DeleteInvoiceParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason for the invoice being deleted.
     *
     * @return Success
     */
    DeleteInvoice(params: InvoicesService.DeleteInvoiceParams): __Observable<ApiResponseOkResponse> {
        return this.DeleteInvoiceResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the invoice by identifier.
     * @param params The `InvoicesService.GetInvoiceByIdParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `includeProviderName`: if set to `true` [include provider name].
     *
     * - `includeDeletedPayments`: if set to `true` [include deleted payments].
     *
     * - `includeCertificates`: if set to `true` [include certificates].
     *
     * @return Success
     */
    GetInvoiceByIdResponse(params: InvoicesService.GetInvoiceByIdParams): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeProviderName != null) __params = __params.set('includeProviderName', params.includeProviderName.toString());
        if (params.includeDeletedPayments != null)
            __params = __params.set('includeDeletedPayments', params.includeDeletedPayments.toString());
        if (params.includeCertificates != null) __params = __params.set('includeCertificates', params.includeCertificates.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Invoices/${encodeURIComponent(String(params.invoiceId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Gets the invoice by identifier.
     * @param params The `InvoicesService.GetInvoiceByIdParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `includeProviderName`: if set to `true` [include provider name].
     *
     * - `includeDeletedPayments`: if set to `true` [include deleted payments].
     *
     * - `includeCertificates`: if set to `true` [include certificates].
     *
     * @return Success
     */
    GetInvoiceById(params: InvoicesService.GetInvoiceByIdParams): __Observable<ApiResponseInvoice> {
        return this.GetInvoiceByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Updates the Invoice.
     * @param params The `InvoicesService.UpdateInvoiceParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `invoiceUpdateRequest`: The invoice update request.
     *
     * @return Success
     */
    UpdateInvoiceResponse(params: InvoicesService.UpdateInvoiceParams): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.invoiceUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/Invoices/${encodeURIComponent(String(params.invoiceId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Updates the Invoice.
     * @param params The `InvoicesService.UpdateInvoiceParams` containing the following parameters:
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `invoiceUpdateRequest`: The invoice update request.
     *
     * @return Success
     */
    UpdateInvoice(params: InvoicesService.UpdateInvoiceParams): __Observable<ApiResponseInvoice> {
        return this.UpdateInvoiceResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Deletes the invoice.
     * @param params The `InvoicesService.DeleteInvoiceForTenantParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason for the invoice being deleted.
     *
     * @return Success
     */
    DeleteInvoiceForTenantResponse(
        params: InvoicesService.DeleteInvoiceForTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl +
                `/Tenants/${encodeURIComponent(String(params.tenantId))}/Invoices/${encodeURIComponent(String(params.invoiceId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the invoice.
     * @param params The `InvoicesService.DeleteInvoiceForTenantParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason for the invoice being deleted.
     *
     * @return Success
     */
    DeleteInvoiceForTenant(params: InvoicesService.DeleteInvoiceForTenantParams): __Observable<ApiResponseOkResponse> {
        return this.DeleteInvoiceForTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Updates the Invoice.
     * @param params The `InvoicesService.UpdateInvoiceForTenantParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `invoiceUpdateRequest`: The invoice update request.
     *
     * @return Success
     */
    UpdateInvoiceForTenantResponse(
        params: InvoicesService.UpdateInvoiceForTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());

        __body = params.invoiceUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/Tenants/${encodeURIComponent(String(params.tenantId))}/Invoices/${encodeURIComponent(String(params.invoiceId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Updates the Invoice.
     * @param params The `InvoicesService.UpdateInvoiceForTenantParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `invoiceUpdateRequest`: The invoice update request.
     *
     * @return Success
     */
    UpdateInvoiceForTenant(params: InvoicesService.UpdateInvoiceForTenantParams): __Observable<ApiResponseInvoice> {
        return this.UpdateInvoiceForTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * The delete invoice membership.
     * @param params The `InvoicesService.DeleteInvoiceMembershipParams` containing the following parameters:
     *
     * - `invoiceMembershipId`: The invoice membership id.
     *
     * - `invoiceId`: The invoice id.
     *
     * @return Success
     */
    DeleteInvoiceMembershipResponse(
        params: InvoicesService.DeleteInvoiceMembershipParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl +
                `/Invoices/Invoices/${encodeURIComponent(String(params.invoiceId))}/Memberships/${encodeURIComponent(
                    String(params.invoiceMembershipId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * The delete invoice membership.
     * @param params The `InvoicesService.DeleteInvoiceMembershipParams` containing the following parameters:
     *
     * - `invoiceMembershipId`: The invoice membership id.
     *
     * - `invoiceId`: The invoice id.
     *
     * @return Success
     */
    DeleteInvoiceMembership(params: InvoicesService.DeleteInvoiceMembershipParams): __Observable<ApiResponseInvoice> {
        return this.DeleteInvoiceMembershipResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Gets the invoice history.
     * @param invoiceId The invoice identifier.
     * @return Success
     */
    GetInvoiceHistoryResponse(invoiceId: number): __Observable<__StrictHttpResponse<ApiResponseICollectionAuditEntryHeader>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Invoices/${encodeURIComponent(String(invoiceId))}/History`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionAuditEntryHeader>;
            }),
        );
    }
    /**
     * Gets the invoice history.
     * @param invoiceId The invoice identifier.
     * @return Success
     */
    GetInvoiceHistory(invoiceId: number): __Observable<ApiResponseICollectionAuditEntryHeader> {
        return this.GetInvoiceHistoryResponse(invoiceId).pipe(__map((_r) => _r.body as ApiResponseICollectionAuditEntryHeader));
    }

    /**
     * Gets the html view for printing an invoice.
     * @param invoiceId the invoice identifier.
     * @return Success
     */
    GetInvoiceHtmlByIdResponse(invoiceId: number): __Observable<__StrictHttpResponse<ActionResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Invoices/${encodeURIComponent(String(invoiceId))}/Html`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ActionResult>;
            }),
        );
    }
    /**
     * Gets the html view for printing an invoice.
     * @param invoiceId the invoice identifier.
     * @return Success
     */
    GetInvoiceHtmlById(invoiceId: number): __Observable<ActionResult> {
        return this.GetInvoiceHtmlByIdResponse(invoiceId).pipe(__map((_r) => _r.body as ActionResult));
    }

    /**
     * Gets the invoice PDF by identifier.
     * @param invoiceId The invoice identifier.
     * @return Success
     */
    GetInvoicePdfByIdResponse(invoiceId: number): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Invoices/${encodeURIComponent(String(invoiceId))}/Pdf`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Gets the invoice PDF by identifier.
     * @param invoiceId The invoice identifier.
     * @return Success
     */
    GetInvoicePdfById(invoiceId: number): __Observable<Blob> {
        return this.GetInvoicePdfByIdResponse(invoiceId).pipe(__map((_r) => _r.body as Blob));
    }

    /**
     * Gets the item discount.
     * @param params The `InvoicesService.GetInvoiceItemItemDiscountParams` containing the following parameters:
     *
     * - `invoiceItemId`: The invoice item identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * @return Success
     */
    GetInvoiceItemItemDiscountResponse(
        params: InvoicesService.GetInvoiceItemItemDiscountParams,
    ): __Observable<__StrictHttpResponse<ApiResponseItemDiscountRate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl +
                `/Invoices/Invoices/${encodeURIComponent(String(params.invoiceId))}/InvoiceItems/${encodeURIComponent(
                    String(params.invoiceItemId),
                )}/DiscountRate`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemDiscountRate>;
            }),
        );
    }
    /**
     * Gets the item discount.
     * @param params The `InvoicesService.GetInvoiceItemItemDiscountParams` containing the following parameters:
     *
     * - `invoiceItemId`: The invoice item identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * @return Success
     */
    GetInvoiceItemItemDiscount(params: InvoicesService.GetInvoiceItemItemDiscountParams): __Observable<ApiResponseItemDiscountRate> {
        return this.GetInvoiceItemItemDiscountResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemDiscountRate));
    }

    /**
     * Gets the next invoice number.
     * @return Success
     */
    GetNextInvoiceNumberResponse(): __Observable<__StrictHttpResponse<ApiResponseString>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Invoices/NextInvoiceNumber`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseString>;
            }),
        );
    }
    /**
     * Gets the next invoice number.
     * @return Success
     */
    GetNextInvoiceNumber(): __Observable<ApiResponseString> {
        return this.GetNextInvoiceNumberResponse().pipe(__map((_r) => _r.body as ApiResponseString));
    }

    /**
     * Gets the next invoice number.
     * @param tenantId The tenant id.
     * @return Success
     */
    GetNextInvoiceNumberForTenantResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponseString>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Tenants/${encodeURIComponent(String(tenantId))}/NextInvoiceNumber`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseString>;
            }),
        );
    }
    /**
     * Gets the next invoice number.
     * @param tenantId The tenant id.
     * @return Success
     */
    GetNextInvoiceNumberForTenant(tenantId: number): __Observable<ApiResponseString> {
        return this.GetNextInvoiceNumberForTenantResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponseString));
    }

    /**
     * Updates the Invoice's Status.
     * @param params The `InvoicesService.UpdateInvoiceStatusParams` containing the following parameters:
     *
     * - `newStatus`: The new status.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason.
     *
     * @return Success
     */
    UpdateInvoiceStatusResponse(params: InvoicesService.UpdateInvoiceStatusParams): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.newStatus != null) __params = __params.set('newStatus', params.newStatus.toString());

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/Invoices/${encodeURIComponent(String(params.invoiceId))}/Status`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Updates the Invoice's Status.
     * @param params The `InvoicesService.UpdateInvoiceStatusParams` containing the following parameters:
     *
     * - `newStatus`: The new status.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason.
     *
     * @return Success
     */
    UpdateInvoiceStatus(params: InvoicesService.UpdateInvoiceStatusParams): __Observable<ApiResponseInvoice> {
        return this.UpdateInvoiceStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Updates the Invoice's Status.
     * @param params The `InvoicesService.UpdateInvoiceStatusForTenantParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `newStatus`: The new status.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason.
     *
     * @return Success
     */
    UpdateInvoiceStatusForTenantResponse(
        params: InvoicesService.UpdateInvoiceStatusForTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());

        if (params.newStatus != null) __params = __params.set('newStatus', params.newStatus.toString());

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/Tenants/${encodeURIComponent(String(params.tenantId))}/Invoices/${encodeURIComponent(String(params.invoiceId))}/Status`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Updates the Invoice's Status.
     * @param params The `InvoicesService.UpdateInvoiceStatusForTenantParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `newStatus`: The new status.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `reason`: The reason.
     *
     * @return Success
     */
    UpdateInvoiceStatusForTenant(params: InvoicesService.UpdateInvoiceStatusForTenantParams): __Observable<ApiResponseInvoice> {
        return this.UpdateInvoiceStatusForTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Updates the Invoice's isViewedByPatient parameter.
     * @param params The `InvoicesService.UpdateIsViewedByPatientFlagForInvoiceParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `invoiceId`: The invoice identifier.
     *
     * @return Success
     */
    UpdateIsViewedByPatientFlagForInvoiceResponse(
        params: InvoicesService.UpdateIsViewedByPatientFlagForInvoiceParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.newValue != null) __params = __params.set('newValue', params.newValue.toString());

        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Invoices/${encodeURIComponent(String(params.invoiceId))}/IsViewedByPatient`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Updates the Invoice's isViewedByPatient parameter.
     * @param params The `InvoicesService.UpdateIsViewedByPatientFlagForInvoiceParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `invoiceId`: The invoice identifier.
     *
     * @return Success
     */
    UpdateIsViewedByPatientFlagForInvoice(
        params: InvoicesService.UpdateIsViewedByPatientFlagForInvoiceParams,
    ): __Observable<ApiResponseInvoice> {
        return this.UpdateIsViewedByPatientFlagForInvoiceResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Get invoices balance by ids.
     * @param params The `InvoicesService.GetInvoicesBalanceParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `invoiceIds`: The invoice ids.
     *
     * @return Success
     */
    GetInvoicesBalanceResponse(
        params: InvoicesService.GetInvoicesBalanceParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionInvoiceBalance>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.invoiceIds;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/Invoices/Balance`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionInvoiceBalance>;
            }),
        );
    }
    /**
     * Get invoices balance by ids.
     * @param params The `InvoicesService.GetInvoicesBalanceParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `invoiceIds`: The invoice ids.
     *
     * @return Success
     */
    GetInvoicesBalance(params: InvoicesService.GetInvoicesBalanceParams): __Observable<ApiResponseICollectionInvoiceBalance> {
        return this.GetInvoicesBalanceResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionInvoiceBalance));
    }

    /**
     * Updates the Invoice's SharedWithPatient parameter.
     * @param params The `InvoicesService.UpdateSharedWithPatientFlagForInvoiceParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `invoiceId`: The invoice identifier.
     *
     * @return Success
     */
    UpdateSharedWithPatientFlagForInvoiceResponse(
        params: InvoicesService.UpdateSharedWithPatientFlagForInvoiceParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.newValue != null) __params = __params.set('newValue', params.newValue.toString());

        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Invoices/${encodeURIComponent(String(params.invoiceId))}/SharedWithPatient`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoice>;
            }),
        );
    }
    /**
     * Updates the Invoice's SharedWithPatient parameter.
     * @param params The `InvoicesService.UpdateSharedWithPatientFlagForInvoiceParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `invoiceId`: The invoice identifier.
     *
     * @return Success
     */
    UpdateSharedWithPatientFlagForInvoice(
        params: InvoicesService.UpdateSharedWithPatientFlagForInvoiceParams,
    ): __Observable<ApiResponseInvoice> {
        return this.UpdateSharedWithPatientFlagForInvoiceResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoice));
    }

    /**
     * Updates the Invoice's legal text of an invoice.
     * @param params The `InvoicesService.UpdateLegalTextParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `requestedByUserId`: The requested user identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `legalTextId`: The legal text identifier.
     *
     * @return Success
     */
    UpdateLegalTextResponse(params: InvoicesService.UpdateLegalTextParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());

        if (params.legalTextId != null) __params = __params.set('legalTextId', params.legalTextId.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/Tenants/${encodeURIComponent(String(params.tenantId))}/Invoices/${encodeURIComponent(
                    String(params.invoiceId),
                )}/LegalTexts`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Updates the Invoice's legal text of an invoice.
     * @param params The `InvoicesService.UpdateLegalTextParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `requestedByUserId`: The requested user identifier.
     *
     * - `invoiceId`: The invoice identifier.
     *
     * - `legalTextId`: The legal text identifier.
     *
     * @return Success
     */
    UpdateLegalText(params: InvoicesService.UpdateLegalTextParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateLegalTextResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module InvoicesService {
    /**
     * Parameters for AddItemGroupToInvoice
     */
    export interface AddItemGroupToInvoiceParams {
        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The item group request.
         */
        itemGroupRequest?: ItemGroupRequest;
    }

    /**
     * Parameters for AddMembershipToInvoice
     */
    export interface AddMembershipToInvoiceParams {
        /**
         * The membership id.
         */
        membershipId: number;

        /**
         * The invoice id.
         */
        invoiceId: number;
    }

    /**
     * Parameters for GetInvoices
     */
    export interface GetInvoicesParams {
        /**
         * Gets or sets the take.
         */
        Take?: number;

        /**
         * Gets or sets a value in indicating whether invoices need to be filtered by their status or group of statuses (like All).
         */
        Status?:
            | 'Draft'
            | 'Open'
            | 'ViewedByPatient'
            | 'PartiallyPaid'
            | 'Paid'
            | 'Void'
            | 'Deleted'
            | 'AllActive'
            | 'AllIncludingDeletedAndVoid'
            | 'AllInactive'
            | 'AllExcludingDeleted'
            | 'AwaitingPayment'
            | 'AllExcludingSurcharges';

        /**
         * Gets or sets the sorting request.
         */
        SortingRequest?: 'Id' | 'Date' | 'Number' | 'PatientName' | 'ModifiedDate';

        /**
         * Gets or sets the sorting direction request.
         */
        SortingDirectionRequest?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the skip.
         */
        Skip?: number;

        /**
         * Gets or sets the patient identifier.
         */
        PatientId?: number;

        /**
         * Gets or sets a value indicating whether to show only invoices based on claims.
         */
        OnlyBasedOnClaims?: boolean;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;

        /**
         * Gets or sets the location identifier.
         */
        LocationId?: number;

        /**
         * Gets or sets the is insurance related.
         */
        IsInsuranceRelated?: boolean;

        /**
         * Gets or sets the invoice number query for invoice number to contain.
         */
        InvoiceNumberQuery?: string;

        /**
         * Gets or sets the include payments.
         */
        IncludePayments?: boolean;

        /**
         * Gets or sets a value indicating whether [include patient name].
         */
        IncludePatientName?: boolean;

        /**
         * Gets or sets a value indicating whether to include invoice items or not.
         */
        IncludeInvoiceItems?: boolean;

        /**
         * Gets or sets the include income type.
         */
        IncludeIncomeType?: boolean;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets the appointment identifier.
         */
        AppointmentId?: number;
    }

    /**
     * Parameters for CreateInvoiceForTenant
     */
    export interface CreateInvoiceForTenantParams {
        /**
         * The user Id.
         */
        userId: number;

        /**
         * The tenantId.
         */
        tenantId: number;

        /**
         * The invoice create request.
         */
        invoiceCreateRequest?: InvoiceCreateRequest;
    }

    /**
     * Parameters for GetInvoicesForTenant
     */
    export interface GetInvoicesForTenantParams {
        /**
         * The user identifier.
         */
        userId: number;

        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The request by patient identifier.
         */
        requestByPatientId?: number;

        /**
         * The include date of service.
         */
        includeServiceDate?: boolean;

        /**
         * Gets or sets the take.
         */
        Take?: number;

        /**
         * Gets or sets a value in indicating whether invoices need to be filtered by their status or group of statuses (like All).
         */
        Status?:
            | 'Draft'
            | 'Open'
            | 'ViewedByPatient'
            | 'PartiallyPaid'
            | 'Paid'
            | 'Void'
            | 'Deleted'
            | 'AllActive'
            | 'AllIncludingDeletedAndVoid'
            | 'AllInactive'
            | 'AllExcludingDeleted'
            | 'AwaitingPayment'
            | 'AllExcludingSurcharges';

        /**
         * Gets or sets the sorting request.
         */
        SortingRequest?: 'Id' | 'Date' | 'Number' | 'PatientName' | 'ModifiedDate';

        /**
         * Gets or sets the sorting direction request.
         */
        SortingDirectionRequest?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the skip.
         */
        Skip?: number;

        /**
         * Gets or sets the patient identifier.
         */
        PatientId?: number;

        /**
         * Gets or sets a value indicating whether to show only invoices based on claims.
         */
        OnlyBasedOnClaims?: boolean;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;

        /**
         * Gets or sets the location identifier.
         */
        LocationId?: number;

        /**
         * Gets or sets the is insurance related.
         */
        IsInsuranceRelated?: boolean;

        /**
         * Gets or sets the invoice number query for invoice number to contain.
         */
        InvoiceNumberQuery?: string;

        /**
         * Gets or sets the invoice item ids.
         */
        InvoiceItemIds?: Array<number>;

        /**
         * Gets or sets the invoice ids.
         */
        InvoiceIds?: Array<number>;

        /**
         * Gets or sets the include payments.
         */
        IncludePayments?: boolean;

        /**
         * Gets or sets a value indicating whether [include patient name].
         */
        IncludePatientName?: boolean;

        /**
         * Gets or sets a value indicating whether to include invoice items or not.
         */
        IncludeInvoiceItems?: boolean;

        /**
         * Gets or sets the include income type.
         */
        IncludeIncomeType?: boolean;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets the appointment identifier.
         */
        AppointmentId?: number;
    }

    /**
     * Parameters for DeleteInvoice
     */
    export interface DeleteInvoiceParams {
        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The reason for the invoice being deleted.
         */
        reason?: string;
    }

    /**
     * Parameters for GetInvoiceById
     */
    export interface GetInvoiceByIdParams {
        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * if set to `true` [include provider name].
         */
        includeProviderName?: boolean;

        /**
         * if set to `true` [include deleted payments].
         */
        includeDeletedPayments?: boolean;

        /**
         * if set to `true` [include certificates].
         */
        includeCertificates?: boolean;
    }

    /**
     * Parameters for UpdateInvoice
     */
    export interface UpdateInvoiceParams {
        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The invoice update request.
         */
        invoiceUpdateRequest?: InvoiceUpdateRequest;
    }

    /**
     * Parameters for DeleteInvoiceForTenant
     */
    export interface DeleteInvoiceForTenantParams {
        /**
         * The user Id.
         */
        userId: number;

        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The reason for the invoice being deleted.
         */
        reason?: string;
    }

    /**
     * Parameters for UpdateInvoiceForTenant
     */
    export interface UpdateInvoiceForTenantParams {
        /**
         * The user identifier.
         */
        userId: number;

        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The invoice update request.
         */
        invoiceUpdateRequest?: InvoiceUpdateRequest;
    }

    /**
     * Parameters for DeleteInvoiceMembership
     */
    export interface DeleteInvoiceMembershipParams {
        /**
         * The invoice membership id.
         */
        invoiceMembershipId: number;

        /**
         * The invoice id.
         */
        invoiceId: number;
    }

    /**
     * Parameters for GetInvoiceItemItemDiscount
     */
    export interface GetInvoiceItemItemDiscountParams {
        /**
         * The invoice item identifier.
         */
        invoiceItemId: number;

        /**
         * The invoice identifier.
         */
        invoiceId: number;
    }

    /**
     * Parameters for UpdateInvoiceStatus
     */
    export interface UpdateInvoiceStatusParams {
        /**
         * The new status.
         */
        newStatus: 'Draft' | 'Open' | 'ViewedByPatient' | 'PartiallyPaid' | 'Paid' | 'Void';

        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The reason.
         */
        reason?: string;
    }

    /**
     * Parameters for UpdateInvoiceStatusForTenant
     */
    export interface UpdateInvoiceStatusForTenantParams {
        /**
         * The user identifier.
         */
        userId: number;

        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The new status.
         */
        newStatus: 'Draft' | 'Open' | 'ViewedByPatient' | 'PartiallyPaid' | 'Paid' | 'Void';

        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The reason.
         */
        reason?: string;
    }

    /**
     * Parameters for UpdateIsViewedByPatientFlagForInvoice
     */
    export interface UpdateIsViewedByPatientFlagForInvoiceParams {
        /**
         * if set to `true` [new value].
         */
        newValue: boolean;

        /**
         * The invoice identifier.
         */
        invoiceId: number;
    }

    /**
     * Parameters for GetInvoicesBalance
     */
    export interface GetInvoicesBalanceParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The invoice ids.
         */
        invoiceIds: Array<number>;
    }

    /**
     * Parameters for UpdateSharedWithPatientFlagForInvoice
     */
    export interface UpdateSharedWithPatientFlagForInvoiceParams {
        /**
         * if set to `true` [new value].
         */
        newValue: boolean;

        /**
         * The invoice identifier.
         */
        invoiceId: number;
    }

    /**
     * Parameters for UpdateLegalText
     */
    export interface UpdateLegalTextParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The requested user identifier.
         */
        requestedByUserId: number;

        /**
         * The invoice identifier.
         */
        invoiceId: number;

        /**
         * The legal text identifier.
         */
        legalTextId?: number;
    }
}

export { InvoicesService };
