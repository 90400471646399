import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';

/**
 * Addon for Wysiwyg that adds common group button for alignment into toolbar
 */
export class WysiwygAlignmentAddon implements WysiwygAddon {
    public static button = 'alignment';
    public name = 'alignment';

    public init(config: WysiwygEditorConfiguration): void {
        config.options = {
            ...config.options,
            toolbar_groups: {
                ...config.options?.toolbar_groups,
                alignment: {
                    icon: 'align-left',
                    tooltip: 'Alignment',
                    items: 'alignleft aligncenter alignright alignjustify',
                },
            },
        };
    }
}
