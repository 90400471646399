import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { ApplicationConstants } from '@symplast/app-constants';

@Component({
    standalone: true,
    imports: [MatButtonModule],
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnInit {
    public error: Error;
    public errorMessage: string;
    private document = inject(DOCUMENT);
    private activatedRoute = inject(ActivatedRoute);

    public ngOnInit(): void {
        try {
            const errorMessage = this.activatedRoute.snapshot.queryParamMap.get('error');

            this.errorMessage = errorMessage ? decodeURIComponent(errorMessage) : ApplicationConstants.DefaultErrorMessage;
            this.errorMessage =
                this.errorMessage.length >= ApplicationConstants.MaxErrorLength ? this.errorMessage.concat('...') : this.errorMessage;
        } catch {
            this.errorMessage = ApplicationConstants.DefaultErrorMessage;
        }
    }

    public onReturnToHome(): void {
        this.document.location.href = '/';
    }
}
