export enum AdminRoutePath {
    ACTIVITY_TYPES = 'ActivityTypes',
    APPOINTMENTS = 'Appointments',
    APPOINTMENT_NOTIFICATIONS = 'AppointmentNotifications',
    BIRTHDAY_NOTIFICATION = 'BirthdayNotification',
    BRANDS = 'Brands',
    BULK_INVENTORY = 'BulkInventory',
    CAMPAIGNS = 'Campaigns',
    CLAIMS_INTELLIGENCE = 'ClaimsIntelligence',
    COMMUNICATIONS = 'Communications',
    COMPANY = 'Company',
    EHR_TEMPLATES = 'EhrTemplates',
    FINANCIAL = 'Financial',
    FINANCIAL_LOCATION_TAX_DEFAULTS = 'LocationTaxDefaults',
    FINANCIAL_TAGS = 'Tags',
    GALDERMA_ITEMS = 'GaldermaItems',
    INSURANCE_COMPANIES = 'InsuranceCompanies',
    INSURANCE_PRINT_SETTINGS = 'ClaimsPrintSetting',
    INTAKE = 'Intake',
    INTEGRATIONS = 'Integrations',
    ITEM_CATEGORIES = 'ItemCategories',
    ITEMS_MANAGEMENT = 'ItemsManagement',
    LEGAL_TEXTS = 'LegalTexts',
    LEGAL_FORMS = 'LegalForms',
    LICENSE_MANAGEMENT = 'LicenseManagement',
    PAYMENT_MODES = 'PaymentModes',
    PAYMENT_REQUESTS = 'PaymentRequests',
    PAYMENT_REASONS = 'PaymentReasons',
    PRACTICE_SETTINGS = 'PracticeSettings',
    PROFILE = 'Profile',
    REFERRAL_SOURCES = 'ReferralSources',
    REFERRING_PROVIDERS = 'ReferringProviders',
    PATIENT_APP_REFERRALS = 'PatientAppReferrals',
    SCHEDULE_TEMPLATES = 'ScheduleTemplates',
    SCHEDULING = 'Scheduling',
    SECURE_MESSAGING = 'SecureMessaging',
    SECURITY_CONFIGURATIONS = 'SecurityConfigurations',
    SIMPAY_SETTINGS = 'SymplastPaymentsSettings',
    SYSTEM = 'System',
    TASK_MANAGEMENT_GROUPS = 'AssigneeGroups',
    TASKS = 'Tasks',
    TAX_AUTHORITIES = 'TaxAuthorities',
    TAX_GROUPS = 'TaxGroups',
    TAXES = 'Taxes',
    TIPS_CONFIGURATION = 'TipsConfiguration',
    TRIZETTO_SETTINGS = 'TrizettoSettings',
    USER_MANAGEMENT = 'UserManagement',
    RESOURCE_MANAGEMENT = 'ResourceManagement',
    MEMBERSHIP_PROGRAMS = 'MembershipPrograms',
    CALENDAR_BLOCKS = 'CalendarBlocks',
}
