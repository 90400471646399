import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'flattensmart' })
export class FlattenArraySmartPipe implements PipeTransform {
    transform(value: any[] = [], itemLimit?: number, keyName?: string): string {
        const flattenedArray = value.map((elem) => (keyName ? elem[keyName] : elem));

        if (!itemLimit || flattenedArray.length <= itemLimit) {
            return flattenedArray.sort().join(', ');
        }

        const slicedArray = flattenedArray.slice(0, itemLimit);

        return `${slicedArray.sort().join(', ')} + ${value.length - itemLimit} other${value.length - itemLimit > 1 ? 's' : ''}`;
    }
}
