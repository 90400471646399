/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableHierarchicalCategoryTree } from '../models/api-response-ienumerable-hierarchical-category-tree';
import { ApiResponseIEnumerableHierarchicalCategory } from '../models/api-response-ienumerable-hierarchical-category';
import { HierarchicalCategoryCreateRequest } from '../models/hierarchical-category-create-request';
import { ApiResponseHierarchicalCategory } from '../models/api-response-hierarchical-category';
import { HierarchicalCategoryUpdateRequest } from '../models/hierarchical-category-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class HierarchicalCategoriesService extends __BaseService {
  static readonly GetHierarchicalCategoriesPath = '/HierarchicalCategories';
  static readonly CreateHierarchicalCategoriesPath = '/HierarchicalCategories';
  static readonly GetHierarchicalCategoryByIdPath = '/HierarchicalCategories/{id}';
  static readonly UpdateHierarchicalCategoryPath = '/HierarchicalCategories/{id}';
  static readonly DeleteHierarchicalCategoryPath = '/HierarchicalCategories/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets hierarchical categories tree.
   * @return Success
   */
  GetHierarchicalCategoriesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableHierarchicalCategoryTree>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/HierarchicalCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableHierarchicalCategoryTree>;
      })
    );
  }
  /**
   * Gets hierarchical categories tree.
   * @return Success
   */
  GetHierarchicalCategories(): __Observable<ApiResponseIEnumerableHierarchicalCategoryTree> {
    return this.GetHierarchicalCategoriesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableHierarchicalCategoryTree)
    );
  }

  /**
   * Creates new hierarchical categories.
   * @param hierarchicalCategoryCreateRequest Hierarchical category create request.
   * @return Success or Created
   */
  CreateHierarchicalCategoriesResponse(hierarchicalCategoryCreateRequest?: HierarchicalCategoryCreateRequest): __Observable<__StrictHttpResponse<ApiResponseIEnumerableHierarchicalCategory | ApiResponseIEnumerableHierarchicalCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = hierarchicalCategoryCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/HierarchicalCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableHierarchicalCategory | ApiResponseIEnumerableHierarchicalCategory>;
      })
    );
  }
  /**
   * Creates new hierarchical categories.
   * @param hierarchicalCategoryCreateRequest Hierarchical category create request.
   * @return Success or Created
   */
  CreateHierarchicalCategories(hierarchicalCategoryCreateRequest?: HierarchicalCategoryCreateRequest): __Observable<ApiResponseIEnumerableHierarchicalCategory | ApiResponseIEnumerableHierarchicalCategory> {
    return this.CreateHierarchicalCategoriesResponse(hierarchicalCategoryCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableHierarchicalCategory | ApiResponseIEnumerableHierarchicalCategory)
    );
  }

  /**
   * Gets hierarchical category info.
   * @param id hierarchical category id.
   * @return Success
   */
  GetHierarchicalCategoryByIdResponse(id: number): __Observable<__StrictHttpResponse<ApiResponseHierarchicalCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/HierarchicalCategories/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseHierarchicalCategory>;
      })
    );
  }
  /**
   * Gets hierarchical category info.
   * @param id hierarchical category id.
   * @return Success
   */
  GetHierarchicalCategoryById(id: number): __Observable<ApiResponseHierarchicalCategory> {
    return this.GetHierarchicalCategoryByIdResponse(id).pipe(
      __map(_r => _r.body as ApiResponseHierarchicalCategory)
    );
  }

  /**
   * Updates hierarchical category info.
   * @param params The `HierarchicalCategoriesService.UpdateHierarchicalCategoryParams` containing the following parameters:
   *
   * - `id`: hierarchical category ID.
   *
   * - `hierarchicalCategoryUpdateRequest`: hierarchical category update request.
   *
   * @return Success
   */
  UpdateHierarchicalCategoryResponse(params: HierarchicalCategoriesService.UpdateHierarchicalCategoryParams): __Observable<__StrictHttpResponse<ApiResponseHierarchicalCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.hierarchicalCategoryUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/HierarchicalCategories/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseHierarchicalCategory>;
      })
    );
  }
  /**
   * Updates hierarchical category info.
   * @param params The `HierarchicalCategoriesService.UpdateHierarchicalCategoryParams` containing the following parameters:
   *
   * - `id`: hierarchical category ID.
   *
   * - `hierarchicalCategoryUpdateRequest`: hierarchical category update request.
   *
   * @return Success
   */
  UpdateHierarchicalCategory(params: HierarchicalCategoriesService.UpdateHierarchicalCategoryParams): __Observable<ApiResponseHierarchicalCategory> {
    return this.UpdateHierarchicalCategoryResponse(params).pipe(
      __map(_r => _r.body as ApiResponseHierarchicalCategory)
    );
  }

  /**
   * Deletes a hierarchical category.
   * @param id hierarchical category ID.
   * @return Success
   */
  DeleteHierarchicalCategoryResponse(id: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/HierarchicalCategories/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a hierarchical category.
   * @param id hierarchical category ID.
   * @return Success
   */
  DeleteHierarchicalCategory(id: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteHierarchicalCategoryResponse(id).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module HierarchicalCategoriesService {

  /**
   * Parameters for UpdateHierarchicalCategory
   */
  export interface UpdateHierarchicalCategoryParams {

    /**
     * hierarchical category ID.
     */
    id: number;

    /**
     * hierarchical category update request.
     */
    hierarchicalCategoryUpdateRequest?: HierarchicalCategoryUpdateRequest;
  }
}

export { HierarchicalCategoriesService }
