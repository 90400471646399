/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseInvoicesGetResponse } from '../models/api-response-invoices-get-response';
import { InvoicesGetRequest } from '../models/invoices-get-request';
@Injectable({
    providedIn: 'root',
})
class InvoicesService extends __BaseService {
    static readonly GetInvoicesForSingleTenantPath = '/Invoices';
    static readonly GetInvoicesExcelFilePath = '/InvoicesExcelFile';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets invoice information.
     * @param params The `InvoicesService.GetInvoicesForSingleTenantParams` containing the following parameters:
     *
     * - `ToDate`: Gets or sets the to date.
     *
     * - `IncludedFields`: Gets or sets the included fields.
     *
     * - `FromDate`: Gets or sets the from date.
     *
     * @return Success
     */
    GetInvoicesForSingleTenantResponse(
        params: InvoicesService.GetInvoicesForSingleTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInvoicesGetResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
        (params.IncludedFields || []).forEach((val) => {
            if (val != null) __params = __params.append('IncludedFields', val.toString());
        });
        if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Invoices`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInvoicesGetResponse>;
            }),
        );
    }
    /**
     * Gets invoice information.
     * @param params The `InvoicesService.GetInvoicesForSingleTenantParams` containing the following parameters:
     *
     * - `ToDate`: Gets or sets the to date.
     *
     * - `IncludedFields`: Gets or sets the included fields.
     *
     * - `FromDate`: Gets or sets the from date.
     *
     * @return Success
     */
    GetInvoicesForSingleTenant(params: InvoicesService.GetInvoicesForSingleTenantParams): __Observable<ApiResponseInvoicesGetResponse> {
        return this.GetInvoicesForSingleTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseInvoicesGetResponse));
    }

    /**
     * Gets the invoices excel file.
     * @param request The request.
     * @return Success
     */
    GetInvoicesExcelFileResponse(request?: InvoicesGetRequest): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/InvoicesExcelFile`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Gets the invoices excel file.
     * @param request The request.
     * @return Success
     */
    GetInvoicesExcelFile(request?: InvoicesGetRequest): __Observable<Blob> {
        return this.GetInvoicesExcelFileResponse(request).pipe(__map((_r) => _r.body as Blob));
    }
}

module InvoicesService {
    /**
     * Parameters for GetInvoicesForSingleTenant
     */
    export interface GetInvoicesForSingleTenantParams {
        /**
         * Gets or sets the to date.
         */
        ToDate?: string;

        /**
         * Gets or sets the included fields.
         */
        IncludedFields?: Array<string>;

        /**
         * Gets or sets the from date.
         */
        FromDate?: string;
    }
}

export { InvoicesService };
