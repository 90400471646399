/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTemplateBundle } from '../models/api-response-ienumerable-template-bundle';
import { ApiResponseTemplateBundle } from '../models/api-response-template-bundle';
import { ApiResponseIEnumerableDeliveryTime } from '../models/api-response-ienumerable-delivery-time';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { UpdateAppointmentTemplateRequest } from '../models/update-appointment-template-request';
@Injectable({
  providedIn: 'root',
})
class AppointmentNotificationsService extends __BaseService {
  static readonly GetAppointmentNotificationsPath = '/AppointmentNotifications';
  static readonly GetAppointmentNotificationByTypePath = '/AppointmentNotifications/{templateType}';
  static readonly GetDeliveryTimesPath = '/AppointmentNotifications/DeliveryTimes';
  static readonly UpdateAppointmentNotificationPath = '/AppointmentNotifications/{templateId}';
  static readonly UpdateAppointmentNotificationBundlePath = '/AppointmentNotifications/Bundle';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of all Appointment Notifications.
   * @return Success
   */
  GetAppointmentNotificationsResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTemplateBundle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentNotifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTemplateBundle>;
      })
    );
  }
  /**
   * Gets a list of all Appointment Notifications.
   * @return Success
   */
  GetAppointmentNotifications(): __Observable<ApiResponseIEnumerableTemplateBundle> {
    return this.GetAppointmentNotificationsResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTemplateBundle)
    );
  }

  /**
   * Gets an appointment notification by its template type.
   * @param templateType undefined
   * @return Success
   */
  GetAppointmentNotificationByTypeResponse(templateType: 'AppointmentInvalidUserResponse' | 'VcUserConfirm' | 'VcNotConfirmedByUser' | 'VcNew' | 'PatientsWelcome' | 'NotRegisteredPatientVcNew' | 'ApptUserConfirm' | 'ApptNotConfirmedByUser' | 'ApptNew' | 'ApptConfirmedByUser' | 'AppointmentStopTexts' | 'ApptCancellation' | 'NotRegisteredPatientVcUserConfirm' | 'VcConfirmedByUser' | 'BirthdaySms' | 'BirthdayEmail' | 'PatientInvitationCode' | 'PaymentRequestReminder' | 'PaymentRequestNotification' | 'UserCreatedSms' | 'UserForgottenPasswordSms' | 'UserResetPasswordSms' | 'UserMobilePhoneConfirmSms' | 'LockedUserSms' | 'DisabledUserSms' | 'NotConfirmedVcEmail' | 'ConfirmedVcEmail' | 'NotConfirmedApptEmail' | 'ConfirmedApptEmail' | 'OtherAppointmentsUnConfirmedVcEmailPart' | 'OtherAppointmentsConfirmedVcEmailPart' | 'OtherAppointmentsUnConfirmedApptEmailPart' | 'OtherAppointmentsConfirmedApptEmailPart' | 'AppointmentConfirmationInvalidUserResponse' | 'SecurityNotificationNewLoginEmail' | 'SecurityNotificationNewLoginSms' | 'SecurityNotificationNewLoginPush' | 'SecurityNotificationLockedUserEmail' | 'SecurityNotificationLockedUserSms' | 'SecurityNotificationLockedUserPush' | 'VcNewEmail' | 'NotRegisteredPatientVcNewEmail' | 'ApptNewEmail' | 'MultiFactorAuthenticationSms' | 'MultiFactorAuthenticationEmail' | 'ExpiredCardsNotificationEmail'): __Observable<__StrictHttpResponse<ApiResponseTemplateBundle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentNotifications/${encodeURIComponent(String(templateType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTemplateBundle>;
      })
    );
  }
  /**
   * Gets an appointment notification by its template type.
   * @param templateType undefined
   * @return Success
   */
  GetAppointmentNotificationByType(templateType: 'AppointmentInvalidUserResponse' | 'VcUserConfirm' | 'VcNotConfirmedByUser' | 'VcNew' | 'PatientsWelcome' | 'NotRegisteredPatientVcNew' | 'ApptUserConfirm' | 'ApptNotConfirmedByUser' | 'ApptNew' | 'ApptConfirmedByUser' | 'AppointmentStopTexts' | 'ApptCancellation' | 'NotRegisteredPatientVcUserConfirm' | 'VcConfirmedByUser' | 'BirthdaySms' | 'BirthdayEmail' | 'PatientInvitationCode' | 'PaymentRequestReminder' | 'PaymentRequestNotification' | 'UserCreatedSms' | 'UserForgottenPasswordSms' | 'UserResetPasswordSms' | 'UserMobilePhoneConfirmSms' | 'LockedUserSms' | 'DisabledUserSms' | 'NotConfirmedVcEmail' | 'ConfirmedVcEmail' | 'NotConfirmedApptEmail' | 'ConfirmedApptEmail' | 'OtherAppointmentsUnConfirmedVcEmailPart' | 'OtherAppointmentsConfirmedVcEmailPart' | 'OtherAppointmentsUnConfirmedApptEmailPart' | 'OtherAppointmentsConfirmedApptEmailPart' | 'AppointmentConfirmationInvalidUserResponse' | 'SecurityNotificationNewLoginEmail' | 'SecurityNotificationNewLoginSms' | 'SecurityNotificationNewLoginPush' | 'SecurityNotificationLockedUserEmail' | 'SecurityNotificationLockedUserSms' | 'SecurityNotificationLockedUserPush' | 'VcNewEmail' | 'NotRegisteredPatientVcNewEmail' | 'ApptNewEmail' | 'MultiFactorAuthenticationSms' | 'MultiFactorAuthenticationEmail' | 'ExpiredCardsNotificationEmail'): __Observable<ApiResponseTemplateBundle> {
    return this.GetAppointmentNotificationByTypeResponse(templateType).pipe(
      __map(_r => _r.body as ApiResponseTemplateBundle)
    );
  }

  /**
   * Gets a list of all Delivery Times.
   * @return Success
   */
  GetDeliveryTimesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableDeliveryTime>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentNotifications/DeliveryTimes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableDeliveryTime>;
      })
    );
  }
  /**
   * Gets a list of all Delivery Times.
   * @return Success
   */
  GetDeliveryTimes(): __Observable<ApiResponseIEnumerableDeliveryTime> {
    return this.GetDeliveryTimesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableDeliveryTime)
    );
  }

  /**
   * Updates an Appointment Notification.
   * @param params The `AppointmentNotificationsService.UpdateAppointmentNotificationParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `request`:
   *
   * @return Success
   */
  UpdateAppointmentNotificationResponse(params: AppointmentNotificationsService.UpdateAppointmentNotificationParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/AppointmentNotifications/${encodeURIComponent(String(params.templateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates an Appointment Notification.
   * @param params The `AppointmentNotificationsService.UpdateAppointmentNotificationParams` containing the following parameters:
   *
   * - `templateId`:
   *
   * - `request`:
   *
   * @return Success
   */
  UpdateAppointmentNotification(params: AppointmentNotificationsService.UpdateAppointmentNotificationParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateAppointmentNotificationResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates a template bundle.
   * @param params The `AppointmentNotificationsService.UpdateAppointmentNotificationBundleParams` containing the following parameters:
   *
   * - `type`: The type of template.
   *
   * - `isActive`: The value indicating whether the template should be active or inactive.
   *
   * - `deliveryTime`: The delivery time.
   *
   * - `deliveryMethod`: The delivery method.
   *
   * @return Success
   */
  UpdateAppointmentNotificationBundleResponse(params: AppointmentNotificationsService.UpdateAppointmentNotificationBundleParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.deliveryTime != null) __params = __params.set('deliveryTime', params.deliveryTime.toString());
    if (params.deliveryMethod != null) __params = __params.set('deliveryMethod', params.deliveryMethod.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/AppointmentNotifications/Bundle`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates a template bundle.
   * @param params The `AppointmentNotificationsService.UpdateAppointmentNotificationBundleParams` containing the following parameters:
   *
   * - `type`: The type of template.
   *
   * - `isActive`: The value indicating whether the template should be active or inactive.
   *
   * - `deliveryTime`: The delivery time.
   *
   * - `deliveryMethod`: The delivery method.
   *
   * @return Success
   */
  UpdateAppointmentNotificationBundle(params: AppointmentNotificationsService.UpdateAppointmentNotificationBundleParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateAppointmentNotificationBundleResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module AppointmentNotificationsService {

  /**
   * Parameters for UpdateAppointmentNotification
   */
  export interface UpdateAppointmentNotificationParams {
    templateId: string;
    request?: UpdateAppointmentTemplateRequest;
  }

  /**
   * Parameters for UpdateAppointmentNotificationBundle
   */
  export interface UpdateAppointmentNotificationBundleParams {

    /**
     * The type of template.
     */
    type?: 'Confirmation' | 'Reminder';

    /**
     * The value indicating whether the template should be active or inactive.
     */
    isActive?: boolean;

    /**
     * The delivery time.
     */
    deliveryTime?: number;

    /**
     * The delivery method.
     */
    deliveryMethod?: 'Text' | 'Email';
  }
}

export { AppointmentNotificationsService }
