import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'spaceByCapitalized',
    standalone: true,
})
export class SpaceByCapitalizedPipe implements PipeTransform {
    transform(value: string): string {
        return value?.replace(/([A-Z])/g, ' $1').trim();
    }
}
