import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

export abstract class ServerErrorHandlerHelper {
    private static snackbar: MatSnackBar;

    public static init(snackbar: MatSnackBar): void {
        ServerErrorHandlerHelper.snackbar = snackbar;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static handle(error: any): void {
        const message =
            error.error?.errorMessage || error.error?.result?.message || error.message || 'Something went wrong. Please try again later';

        ServerErrorHandlerHelper.snackbar.open(message, null, { duration: 5000 });
    }
}
