/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { TenantIntegrationCreateRequest } from '../models/tenant-integration-create-request';
import { ApiResponseIEnumerableTenantIntegrationRequest } from '../models/api-response-ienumerable-tenant-integration-request';
@Injectable({
    providedIn: 'root',
})
class TenantIntegrationRequestsService extends __BaseService {
    static readonly CreateTenantIntegrationRequestsPath = '/tenantIntegrationRequests';
    static readonly GetTenantIntegrationRequestsPath = '/tenantIntegrationRequests';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * creates a tenant integration request
     * @param tenantIntegrationCreateRequest The tenant integration create request.
     * @return Success
     */
    CreateTenantIntegrationRequestsResponse(
        tenantIntegrationCreateRequest?: TenantIntegrationCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = tenantIntegrationCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/tenantIntegrationRequests`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * creates a tenant integration request
     * @param tenantIntegrationCreateRequest The tenant integration create request.
     * @return Success
     */
    CreateTenantIntegrationRequests(tenantIntegrationCreateRequest?: TenantIntegrationCreateRequest): __Observable<ApiResponseOkResponse> {
        return this.CreateTenantIntegrationRequestsResponse(tenantIntegrationCreateRequest).pipe(
            __map((_r) => _r.body as ApiResponseOkResponse),
        );
    }

    /**
     * Gets all tenant integration request.
     * @return Success
     */
    GetTenantIntegrationRequestsResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTenantIntegrationRequest>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenantIntegrationRequests`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableTenantIntegrationRequest>;
            }),
        );
    }
    /**
     * Gets all tenant integration request.
     * @return Success
     */
    GetTenantIntegrationRequests(): __Observable<ApiResponseIEnumerableTenantIntegrationRequest> {
        return this.GetTenantIntegrationRequestsResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableTenantIntegrationRequest));
    }
}

module TenantIntegrationRequestsService {}

export { TenantIntegrationRequestsService };
