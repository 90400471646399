import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
    standalone: true,
    name: 'daysAgo',
})
export class DaysAgoPipe implements PipeTransform {
    public transform(value: string): any {
        if (!value) {
            return value;
        }
        const currentDate = moment().startOf('day');
        const dateValue = moment(value.replace('Z', '')).startOf('day');

        return currentDate.diff(dateValue, 'day').toString();
    }
}
