import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Inject, Input, Optional } from '@angular/core';
import { SymplastDialogTitleDirective } from '../../symplast-dialog.directive';
import { SYMPLAST_DIALOG_PRESET, SYMPLAST_DIALOG_PRESET_MAP, SymplastDialogPreset } from '../../symplast-dialog.preset';

type Color = 'primary' | 'error' | 'inherit';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'symplast-dialog-header, [symplast-dialog-header], [symplastDialogHeader]',
    templateUrl: './symplast-dialog-header.component.html',
    styleUrls: ['./symplast-dialog-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymplastDialogHeaderComponent {
    private _color!: Color;
    private _fullWidth!: boolean;

    @ContentChild(SymplastDialogTitleDirective) public title?: SymplastDialogTitleDirective;

    constructor(@Optional() @Inject(SYMPLAST_DIALOG_PRESET) public preset: SymplastDialogPreset) {}

    @Input()
    public get color(): Color {
        return this._color || SYMPLAST_DIALOG_PRESET_MAP[this.preset]?.headerColor || 'primary';
    }
    public set color(value: Color) {
        this._color = value;
    }

    @Input()
    public get fullWidth(): boolean {
        if (typeof this._fullWidth === 'boolean') {
            return this._fullWidth;
        }

        return SYMPLAST_DIALOG_PRESET_MAP[this.preset]?.headerFullWidth || true;
    }
    public set fullWidth(value: boolean) {
        this._fullWidth = value;
    }
}
