import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SYMPLAST_DIALOG_MODULE, SYMPLAST_DIALOG_PRESET } from '@symplast/ui/shared';
import { PolymorphContentComponent } from '../../../../shared/polymorph-content/polymorph-content.component';
import { CommonModule } from '@angular/common';
import { TipsService } from '../../tips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OverviewDialogData } from './overview-dialog.model';

/** Do not use directly. Use only with OverviewDialogService */
@Component({
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatCheckboxModule, PolymorphContentComponent, ...SYMPLAST_DIALOG_MODULE],
    template: `
        <symplast-dialog data-e2e="symplast-overview-dialog">
            <symplast-dialog-header>
                <symplast-dialog-title *ngIf="data.title" class="symplast-font__heading-2">
                    {{ data.title }}
                </symplast-dialog-title>
            </symplast-dialog-header>

            <symplast-dialog-content>
                <app-polymorph-content [content]="data.content"></app-polymorph-content>
            </symplast-dialog-content>

            <symplast-dialog-footer alignContent="right">
                <mat-checkbox
                    data-e2e="symplast-overview-dialog__do-not-show-checkbox"
                    *ngIf="this.data.checkbox?.tip"
                    class="tip-checkbox"
                    color="primary"
                    [checked]="tipChecked"
                    (change)="setTip($event.checked)">
                    {{ data.checkbox.label || 'Do not show this message again' }}
                </mat-checkbox>

                <div class="buttons-wrapper">
                    <button data-e2e="symplast-overview-dialog__got-it-button" mat-flat-button color="primary" mat-dialog-close>
                        {{ data.close.label || 'Got it' }}
                    </button>
                </div>
            </symplast-dialog-footer>
        </symplast-dialog>
    `,
    styles: [
        `
            .buttons-wrapper {
                display: flex;
                align-items: center;
                gap: 16px;
            }
            button {
                margin-right: 0;
            }
            .tip-checkbox {
                margin-right: auto;

                .mat-checkbox-inner-container {
                    background-color: var(--color-white);
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: SYMPLAST_DIALOG_PRESET, useValue: 'info' }],
})
export class OverviewDialogComponent implements OnInit {
    public data: OverviewDialogData = inject(MAT_DIALOG_DATA);
    public cdr = inject(ChangeDetectorRef);
    public singleTitle = typeof this.data.title === 'string';
    public tipsService = inject(TipsService);
    public tipChecked: boolean;

    public ngOnInit(): void {
        if (this.data.checkbox?.tip) {
            this.tipChecked = this.tipsService.checkTip(this.data.checkbox.tip);
        }
    }

    public setTip(checked: boolean): void {
        this.tipChecked = checked;
        this.tipsService.setTip(this.data.checkbox.tip, checked);
    }
}
