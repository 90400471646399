/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableItemSettings } from '../models/api-response-ienumerable-item-settings';
@Injectable({
  providedIn: 'root',
})
class ItemSettingsService extends __BaseService {
  static readonly GetSettingsPath = '/api/ItemSettings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get item settings.
   * @param itemIds The items id.
   * @return Success
   */
  GetSettingsResponse(itemIds?: Array<number>): __Observable<__StrictHttpResponse<ApiResponseIEnumerableItemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (itemIds || []).forEach(val => {if (val != null) __params = __params.append('itemIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ItemSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableItemSettings>;
      })
    );
  }
  /**
   * Get item settings.
   * @param itemIds The items id.
   * @return Success
   */
  GetSettings(itemIds?: Array<number>): __Observable<ApiResponseIEnumerableItemSettings> {
    return this.GetSettingsResponse(itemIds).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableItemSettings)
    );
  }
}

module ItemSettingsService {
}

export { ItemSettingsService }
