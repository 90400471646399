/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionActivityType } from '../models/api-response-icollection-activity-type';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ActivityCreateRequest } from '../models/activity-create-request';
import { ApiResponseActivityType } from '../models/api-response-activity-type';
import { ActivityUpdateRequest } from '../models/activity-update-request';
import { ActivityTypeStatusChangeRequest } from '../models/activity-type-status-change-request';
@Injectable({
  providedIn: 'root',
})
class ActivityTypesService extends __BaseService {
  static readonly GetActivitiesPath = '/ActivityTypes';
  static readonly CreateActivityPath = '/ActivityTypes';
  static readonly GetActivityPath = '/ActivityTypes/{activityId}';
  static readonly UpdateActivityPath = '/ActivityTypes/{activityTypeId}';
  static readonly DeleteActivityPath = '/ActivityTypes/{activityTypeId}';
  static readonly UpdateActivityStatusPath = '/ActivityTypes/{activityTypeId}/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of activities.
   * @param params The `ActivityTypesService.GetActivitiesParams` containing the following parameters:
   *
   * - `includeDeactivated`: The include deactivated.
   *
   * - `activitySystemType`: The activity System Type.
   *
   * @return Success
   */
  GetActivitiesResponse(params: ActivityTypesService.GetActivitiesParams): __Observable<__StrictHttpResponse<ApiResponseICollectionActivityType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeDeactivated != null) __params = __params.set('includeDeactivated', params.includeDeactivated.toString());
    if (params.activitySystemType != null) __params = __params.set('activitySystemType', params.activitySystemType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ActivityTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionActivityType>;
      })
    );
  }
  /**
   * Get the list of activities.
   * @param params The `ActivityTypesService.GetActivitiesParams` containing the following parameters:
   *
   * - `includeDeactivated`: The include deactivated.
   *
   * - `activitySystemType`: The activity System Type.
   *
   * @return Success
   */
  GetActivities(params: ActivityTypesService.GetActivitiesParams): __Observable<ApiResponseICollectionActivityType> {
    return this.GetActivitiesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionActivityType)
    );
  }

  /**
   * Create the activity.
   * @param activityCreateRequest The activity for create.
   * @return Success
   */
  CreateActivityResponse(activityCreateRequest?: ActivityCreateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = activityCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ActivityTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Create the activity.
   * @param activityCreateRequest The activity for create.
   * @return Success
   */
  CreateActivity(activityCreateRequest?: ActivityCreateRequest): __Observable<ApiResponseOkResponse> {
    return this.CreateActivityResponse(activityCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the activity by id.
   * @param params The `ActivityTypesService.GetActivityParams` containing the following parameters:
   *
   * - `activityId`: The activity id.
   *
   * - `includeDeactivated`: The include deactivated.
   *
   * @return Success
   */
  GetActivityResponse(params: ActivityTypesService.GetActivityParams): __Observable<__StrictHttpResponse<ApiResponseActivityType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeDeactivated != null) __params = __params.set('includeDeactivated', params.includeDeactivated.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ActivityTypes/${encodeURIComponent(String(params.activityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseActivityType>;
      })
    );
  }
  /**
   * Get the activity by id.
   * @param params The `ActivityTypesService.GetActivityParams` containing the following parameters:
   *
   * - `activityId`: The activity id.
   *
   * - `includeDeactivated`: The include deactivated.
   *
   * @return Success
   */
  GetActivity(params: ActivityTypesService.GetActivityParams): __Observable<ApiResponseActivityType> {
    return this.GetActivityResponse(params).pipe(
      __map(_r => _r.body as ApiResponseActivityType)
    );
  }

  /**
   * Update the activity.
   * @param params The `ActivityTypesService.UpdateActivityParams` containing the following parameters:
   *
   * - `activityTypeId`: The activity id.
   *
   * - `activityUpdateRequest`: The activity for update.
   *
   * @return Success
   */
  UpdateActivityResponse(params: ActivityTypesService.UpdateActivityParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.activityUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ActivityTypes/${encodeURIComponent(String(params.activityTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the activity.
   * @param params The `ActivityTypesService.UpdateActivityParams` containing the following parameters:
   *
   * - `activityTypeId`: The activity id.
   *
   * - `activityUpdateRequest`: The activity for update.
   *
   * @return Success
   */
  UpdateActivity(params: ActivityTypesService.UpdateActivityParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateActivityResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Delete the activity.
   * @param activityTypeId The activity type id.
   * @return Success
   */
  DeleteActivityResponse(activityTypeId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ActivityTypes/${encodeURIComponent(String(activityTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete the activity.
   * @param activityTypeId The activity type id.
   * @return Success
   */
  DeleteActivity(activityTypeId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteActivityResponse(activityTypeId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update status for readonly activity.
   * @param params The `ActivityTypesService.UpdateActivityStatusParams` containing the following parameters:
   *
   * - `activityTypeId`: The activity id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateActivityStatusResponse(params: ActivityTypesService.UpdateActivityStatusParams): __Observable<__StrictHttpResponse<ApiResponseActivityType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ActivityTypes/${encodeURIComponent(String(params.activityTypeId))}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseActivityType>;
      })
    );
  }
  /**
   * Update status for readonly activity.
   * @param params The `ActivityTypesService.UpdateActivityStatusParams` containing the following parameters:
   *
   * - `activityTypeId`: The activity id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateActivityStatus(params: ActivityTypesService.UpdateActivityStatusParams): __Observable<ApiResponseActivityType> {
    return this.UpdateActivityStatusResponse(params).pipe(
      __map(_r => _r.body as ApiResponseActivityType)
    );
  }
}

module ActivityTypesService {

  /**
   * Parameters for GetActivities
   */
  export interface GetActivitiesParams {

    /**
     * The include deactivated.
     */
    includeDeactivated?: boolean;

    /**
     * The activity System Type.
     */
    activitySystemType?: 'ActivityNotes' | 'ClaimNotes';
  }

  /**
   * Parameters for GetActivity
   */
  export interface GetActivityParams {

    /**
     * The activity id.
     */
    activityId: number;

    /**
     * The include deactivated.
     */
    includeDeactivated?: boolean;
  }

  /**
   * Parameters for UpdateActivity
   */
  export interface UpdateActivityParams {

    /**
     * The activity id.
     */
    activityTypeId: number;

    /**
     * The activity for update.
     */
    activityUpdateRequest?: ActivityUpdateRequest;
  }

  /**
   * Parameters for UpdateActivityStatus
   */
  export interface UpdateActivityStatusParams {

    /**
     * The activity id.
     */
    activityTypeId: number;

    /**
     * The update request.
     */
    updateRequest?: ActivityTypeStatusChangeRequest;
  }
}

export { ActivityTypesService }
