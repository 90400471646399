/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseRemittanceDocument } from '../models/api-response-remittance-document';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseRemittance } from '../models/api-response-remittance';
import { RemittanceCreateUpdateRequest } from '../models/remittance-create-update-request';
import { ApiPagedResponseICollectionRemittance } from '../models/api-paged-response-icollection-remittance';
import { ApiDetailedErrorResponseIEnumerableStringIEnumerableDeleteRemittanceException } from '../models/api-detailed-error-response-ienumerable-string-ienumerable-delete-remittance-exception';
import { ApiResponseFollowUpCount } from '../models/api-response-follow-up-count';
import { ApiResponseIEnumerableRemittanceDocument } from '../models/api-response-ienumerable-remittance-document';
import { ApiResponseICollectionRemittance } from '../models/api-response-icollection-remittance';
import { ApiResponseCountResponse } from '../models/api-response-count-response';
import { ApiResponseRemittanceStatistics } from '../models/api-response-remittance-statistics';
@Injectable({
  providedIn: 'root',
})
class RemittancesService extends __BaseService {
  static readonly AddFileToRemittancePath = '/Insurance/Remittances/{remittanceId}/AddDocument';
  static readonly SendTrizettoRequestPath = '/Insurance/Remittances/Check';
  static readonly CreateRemittancePath = '/Insurance/Remittances';
  static readonly GetRemittancesPath = '/Insurance/Remittances';
  static readonly RemoveFileFromRemittancePath = '/Insurance/Remittances/{remittanceId}/DeleteDocument/{remittanceDocumentId}';
  static readonly DeleteRemittancePath = '/Insurance/Remittances/{remittanceId}';
  static readonly GetRemittanceByIdPath = '/Insurance/Remittances/{remittanceId}';
  static readonly UpdateRemittancePath = '/Insurance/Remittances/{remittanceId}';
  static readonly GetFlaggedChargesCountPath = '/Remittances/FlaggedChargesCount';
  static readonly GetRemittanceDocumentsPath = '/Insurance/Remittances/Documents';
  static readonly GetRemittanceDocumentsWithDescriptionPath = '/Insurance/Remittances/DocumentsWithDescription';
  static readonly GetRemittancesByClaimIdPath = '/Insurance/Remittances/GetByClaimId/{claimId}';
  static readonly GetRemittancesCountPath = '/Insurance/Remittances/Count';
  static readonly GetRemittanceStatisticsPath = '/Remittances/Statistics';
  static readonly PostRemittancePath = '/Insurance/Remittances/{remittanceId}/Posted';
  static readonly ResendTrizettoRemittancePath = '/Remittances/{remittanceId}/Resend';
  static readonly UpdateRemittanceStatusPath = '/Insurance/Remittances/{remittanceId}/Status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Add a document to a remittance.
   * @param params The `RemittancesService.AddFileToRemittanceParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance id.
   *
   * - `file`:
   *
   * @return Success
   */
  AddFileToRemittanceResponse(params: RemittancesService.AddFileToRemittanceParams): __Observable<__StrictHttpResponse<ApiResponseRemittanceDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Remittances/${encodeURIComponent(String(params.remittanceId))}/AddDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseRemittanceDocument>;
      })
    );
  }
  /**
   * Add a document to a remittance.
   * @param params The `RemittancesService.AddFileToRemittanceParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance id.
   *
   * - `file`:
   *
   * @return Success
   */
  AddFileToRemittance(params: RemittancesService.AddFileToRemittanceParams): __Observable<ApiResponseRemittanceDocument> {
    return this.AddFileToRemittanceResponse(params).pipe(
      __map(_r => _r.body as ApiResponseRemittanceDocument)
    );
  }

  /**
   * Send request to Trizetto for getting remittance.
   * @return Success
   */
  SendTrizettoRequestResponse(): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Remittances/Check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Send request to Trizetto for getting remittance.
   * @return Success
   */
  SendTrizettoRequest(): __Observable<ApiResponseOkResponse> {
    return this.SendTrizettoRequestResponse().pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Creates a new remittance.
   * @param remittanceCreateUpdateRequest The remittance Create Request.
   * @return Success
   */
  CreateRemittanceResponse(remittanceCreateUpdateRequest?: RemittanceCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseRemittance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = remittanceCreateUpdateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Remittances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseRemittance>;
      })
    );
  }
  /**
   * Creates a new remittance.
   * @param remittanceCreateUpdateRequest The remittance Create Request.
   * @return Success
   */
  CreateRemittance(remittanceCreateUpdateRequest?: RemittanceCreateUpdateRequest): __Observable<ApiResponseRemittance> {
    return this.CreateRemittanceResponse(remittanceCreateUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseRemittance)
    );
  }

  /**
   * Get the remittances.
   * @param params The `RemittancesService.GetRemittancesParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `remittanceDateTo`: The remittance Date To.
   *
   * - `remittanceDateFrom`: The remittance Date From.
   *
   * - `reference`: The reference.
   *
   * - `paymentType`: The payment type.
   *
   * - `patientId`: The patient id.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `onlyNeedsFollowUp`: Show only remittances with charges that need follow up.
   *
   * - `insuranceCompanyId`: The Insurance Company Id.
   *
   * - `includePatientNames`: The include patient names.
   *
   * - `createdBy`: The created By.
   *
   * @return Success
   */
  GetRemittancesResponse(params: RemittancesService.GetRemittancesParams): __Observable<__StrictHttpResponse<ApiPagedResponseICollectionRemittance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.remittanceDateTo != null) __params = __params.set('remittanceDateTo', params.remittanceDateTo.toString());
    if (params.remittanceDateFrom != null) __params = __params.set('remittanceDateFrom', params.remittanceDateFrom.toString());
    if (params.reference != null) __params = __params.set('reference', params.reference.toString());
    if (params.paymentType != null) __params = __params.set('paymentType', params.paymentType.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.onlyNeedsFollowUp != null) __params = __params.set('onlyNeedsFollowUp', params.onlyNeedsFollowUp.toString());
    if (params.insuranceCompanyId != null) __params = __params.set('insuranceCompanyId', params.insuranceCompanyId.toString());
    if (params.includePatientNames != null) __params = __params.set('includePatientNames', params.includePatientNames.toString());
    if (params.createdBy != null) __params = __params.set('createdBy', params.createdBy.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Remittances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseICollectionRemittance>;
      })
    );
  }
  /**
   * Get the remittances.
   * @param params The `RemittancesService.GetRemittancesParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `remittanceDateTo`: The remittance Date To.
   *
   * - `remittanceDateFrom`: The remittance Date From.
   *
   * - `reference`: The reference.
   *
   * - `paymentType`: The payment type.
   *
   * - `patientId`: The patient id.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `onlyNeedsFollowUp`: Show only remittances with charges that need follow up.
   *
   * - `insuranceCompanyId`: The Insurance Company Id.
   *
   * - `includePatientNames`: The include patient names.
   *
   * - `createdBy`: The created By.
   *
   * @return Success
   */
  GetRemittances(params: RemittancesService.GetRemittancesParams): __Observable<ApiPagedResponseICollectionRemittance> {
    return this.GetRemittancesResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseICollectionRemittance)
    );
  }

  /**
   * Remove a document from a remittance.
   * @param params The `RemittancesService.RemoveFileFromRemittanceParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance id.
   *
   * - `remittanceDocumentId`: The remittance document id.
   *
   * @return Success
   */
  RemoveFileFromRemittanceResponse(params: RemittancesService.RemoveFileFromRemittanceParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Insurance/Remittances/${encodeURIComponent(String(params.remittanceId))}/DeleteDocument/${encodeURIComponent(String(params.remittanceDocumentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Remove a document from a remittance.
   * @param params The `RemittancesService.RemoveFileFromRemittanceParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance id.
   *
   * - `remittanceDocumentId`: The remittance document id.
   *
   * @return Success
   */
  RemoveFileFromRemittance(params: RemittancesService.RemoveFileFromRemittanceParams): __Observable<ApiResponseOkResponse> {
    return this.RemoveFileFromRemittanceResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Delete the remittance.
   * @param remittanceId The remittance Id.
   * @return Success
   */
  DeleteRemittanceResponse(remittanceId: number): __Observable<__StrictHttpResponse<ApiDetailedErrorResponseIEnumerableStringIEnumerableDeleteRemittanceException>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Insurance/Remittances/${encodeURIComponent(String(remittanceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiDetailedErrorResponseIEnumerableStringIEnumerableDeleteRemittanceException>;
      })
    );
  }
  /**
   * Delete the remittance.
   * @param remittanceId The remittance Id.
   * @return Success
   */
  DeleteRemittance(remittanceId: number): __Observable<ApiDetailedErrorResponseIEnumerableStringIEnumerableDeleteRemittanceException> {
    return this.DeleteRemittanceResponse(remittanceId).pipe(
      __map(_r => _r.body as ApiDetailedErrorResponseIEnumerableStringIEnumerableDeleteRemittanceException)
    );
  }

  /**
   * Get the remittances.
   * @param remittanceId The remittance Id.
   * @return Success
   */
  GetRemittanceByIdResponse(remittanceId: number): __Observable<__StrictHttpResponse<ApiResponseRemittance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Remittances/${encodeURIComponent(String(remittanceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseRemittance>;
      })
    );
  }
  /**
   * Get the remittances.
   * @param remittanceId The remittance Id.
   * @return Success
   */
  GetRemittanceById(remittanceId: number): __Observable<ApiResponseRemittance> {
    return this.GetRemittanceByIdResponse(remittanceId).pipe(
      __map(_r => _r.body as ApiResponseRemittance)
    );
  }

  /**
   * Update the remittance.
   * @param params The `RemittancesService.UpdateRemittanceParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance Id.
   *
   * - `remittanceCreateUpdateRequest`: The remittance to update.
   *
   * @return Success
   */
  UpdateRemittanceResponse(params: RemittancesService.UpdateRemittanceParams): __Observable<__StrictHttpResponse<ApiResponseRemittance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.remittanceCreateUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Remittances/${encodeURIComponent(String(params.remittanceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseRemittance>;
      })
    );
  }
  /**
   * Update the remittance.
   * @param params The `RemittancesService.UpdateRemittanceParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance Id.
   *
   * - `remittanceCreateUpdateRequest`: The remittance to update.
   *
   * @return Success
   */
  UpdateRemittance(params: RemittancesService.UpdateRemittanceParams): __Observable<ApiResponseRemittance> {
    return this.UpdateRemittanceResponse(params).pipe(
      __map(_r => _r.body as ApiResponseRemittance)
    );
  }

  /**
   * Get the total amount of flagged charges within all remittances.
   * @return Success
   */
  GetFlaggedChargesCountResponse(): __Observable<__StrictHttpResponse<ApiResponseFollowUpCount>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Remittances/FlaggedChargesCount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFollowUpCount>;
      })
    );
  }
  /**
   * Get the total amount of flagged charges within all remittances.
   * @return Success
   */
  GetFlaggedChargesCount(): __Observable<ApiResponseFollowUpCount> {
    return this.GetFlaggedChargesCountResponse().pipe(
      __map(_r => _r.body as ApiResponseFollowUpCount)
    );
  }

  /**
   * Get the remittance documents.
   * @param params The `RemittancesService.GetRemittanceDocumentsParams` containing the following parameters:
   *
   * - `uploadDateTo`: The upload Date To.
   *
   * - `uploadDateFrom`: The upload Date From.
   *
   * - `remittanceId`: The remittance id.
   *
   * - `patientId`: The patient id.
   *
   * - `fileName`: The file name.
   *
   * @return Success
   */
  GetRemittanceDocumentsResponse(params: RemittancesService.GetRemittanceDocumentsParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableRemittanceDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uploadDateTo != null) __params = __params.set('uploadDateTo', params.uploadDateTo.toString());
    if (params.uploadDateFrom != null) __params = __params.set('uploadDateFrom', params.uploadDateFrom.toString());
    if (params.remittanceId != null) __params = __params.set('remittanceId', params.remittanceId.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.fileName != null) __params = __params.set('fileName', params.fileName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Remittances/Documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableRemittanceDocument>;
      })
    );
  }
  /**
   * Get the remittance documents.
   * @param params The `RemittancesService.GetRemittanceDocumentsParams` containing the following parameters:
   *
   * - `uploadDateTo`: The upload Date To.
   *
   * - `uploadDateFrom`: The upload Date From.
   *
   * - `remittanceId`: The remittance id.
   *
   * - `patientId`: The patient id.
   *
   * - `fileName`: The file name.
   *
   * @return Success
   */
  GetRemittanceDocuments(params: RemittancesService.GetRemittanceDocumentsParams): __Observable<ApiResponseIEnumerableRemittanceDocument> {
    return this.GetRemittanceDocumentsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableRemittanceDocument)
    );
  }

  /**
   * Get remittance documents with description for a patient
   * @param patientId The patient Id
   * @return Success
   */
  GetRemittanceDocumentsWithDescriptionResponse(patientId?: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableRemittanceDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Remittances/DocumentsWithDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableRemittanceDocument>;
      })
    );
  }
  /**
   * Get remittance documents with description for a patient
   * @param patientId The patient Id
   * @return Success
   */
  GetRemittanceDocumentsWithDescription(patientId?: number): __Observable<ApiResponseIEnumerableRemittanceDocument> {
    return this.GetRemittanceDocumentsWithDescriptionResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableRemittanceDocument)
    );
  }

  /**
   * Get the remittances by Claim Id.
   * @param claimId The Claim Id.
   * @return Success
   */
  GetRemittancesByClaimIdResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseICollectionRemittance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Remittances/GetByClaimId/${encodeURIComponent(String(claimId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionRemittance>;
      })
    );
  }
  /**
   * Get the remittances by Claim Id.
   * @param claimId The Claim Id.
   * @return Success
   */
  GetRemittancesByClaimId(claimId: number): __Observable<ApiResponseICollectionRemittance> {
    return this.GetRemittancesByClaimIdResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseICollectionRemittance)
    );
  }

  /**
   * Get the remittances.
   * @param params The `RemittancesService.GetRemittancesCountParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `remittanceDateTo`: The remittance Date To.
   *
   * - `remittanceDateFrom`: The remittance Date From.
   *
   * - `reference`: The reference.
   *
   * - `patientId`: The patient id.
   *
   * - `onlyAutoCreated`: The only Auto Created.
   *
   * - `insuranceCompanyId`: The Insurance Company Id.
   *
   * @return Success
   */
  GetRemittancesCountResponse(params: RemittancesService.GetRemittancesCountParams): __Observable<__StrictHttpResponse<ApiResponseCountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.remittanceDateTo != null) __params = __params.set('remittanceDateTo', params.remittanceDateTo.toString());
    if (params.remittanceDateFrom != null) __params = __params.set('remittanceDateFrom', params.remittanceDateFrom.toString());
    if (params.reference != null) __params = __params.set('reference', params.reference.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.onlyAutoCreated != null) __params = __params.set('onlyAutoCreated', params.onlyAutoCreated.toString());
    if (params.insuranceCompanyId != null) __params = __params.set('insuranceCompanyId', params.insuranceCompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Remittances/Count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCountResponse>;
      })
    );
  }
  /**
   * Get the remittances.
   * @param params The `RemittancesService.GetRemittancesCountParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `remittanceDateTo`: The remittance Date To.
   *
   * - `remittanceDateFrom`: The remittance Date From.
   *
   * - `reference`: The reference.
   *
   * - `patientId`: The patient id.
   *
   * - `onlyAutoCreated`: The only Auto Created.
   *
   * - `insuranceCompanyId`: The Insurance Company Id.
   *
   * @return Success
   */
  GetRemittancesCount(params: RemittancesService.GetRemittancesCountParams): __Observable<ApiResponseCountResponse> {
    return this.GetRemittancesCountResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCountResponse)
    );
  }

  /**
   * The get statistics.
   * @return Success
   */
  GetRemittanceStatisticsResponse(): __Observable<__StrictHttpResponse<ApiResponseRemittanceStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Remittances/Statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseRemittanceStatistics>;
      })
    );
  }
  /**
   * The get statistics.
   * @return Success
   */
  GetRemittanceStatistics(): __Observable<ApiResponseRemittanceStatistics> {
    return this.GetRemittanceStatisticsResponse().pipe(
      __map(_r => _r.body as ApiResponseRemittanceStatistics)
    );
  }

  /**
   * Update the remittance.
   * @param remittanceId The remittance Id.
   * @return Success
   */
  PostRemittanceResponse(remittanceId: number): __Observable<__StrictHttpResponse<ApiResponseRemittance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Remittances/${encodeURIComponent(String(remittanceId))}/Posted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseRemittance>;
      })
    );
  }
  /**
   * Update the remittance.
   * @param remittanceId The remittance Id.
   * @return Success
   */
  PostRemittance(remittanceId: number): __Observable<ApiResponseRemittance> {
    return this.PostRemittanceResponse(remittanceId).pipe(
      __map(_r => _r.body as ApiResponseRemittance)
    );
  }

  /**
   * The resend Trizetto Remittance
   * @param remittanceId The remittance identifier.
   * @return Success
   */
  ResendTrizettoRemittanceResponse(remittanceId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Remittances/${encodeURIComponent(String(remittanceId))}/Resend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The resend Trizetto Remittance
   * @param remittanceId The remittance identifier.
   * @return Success
   */
  ResendTrizettoRemittance(remittanceId: number): __Observable<ApiResponseOkResponse> {
    return this.ResendTrizettoRemittanceResponse(remittanceId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update the remittance.
   * @param params The `RemittancesService.UpdateRemittanceStatusParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance Id.
   *
   * - `status`: The remittance status.
   *
   * @return Success
   */
  UpdateRemittanceStatusResponse(params: RemittancesService.UpdateRemittanceStatusParams): __Observable<__StrictHttpResponse<ApiResponseRemittance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.status != null) __params = __params.set('status', params.status.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Remittances/${encodeURIComponent(String(params.remittanceId))}/Status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseRemittance>;
      })
    );
  }
  /**
   * Update the remittance.
   * @param params The `RemittancesService.UpdateRemittanceStatusParams` containing the following parameters:
   *
   * - `remittanceId`: The remittance Id.
   *
   * - `status`: The remittance status.
   *
   * @return Success
   */
  UpdateRemittanceStatus(params: RemittancesService.UpdateRemittanceStatusParams): __Observable<ApiResponseRemittance> {
    return this.UpdateRemittanceStatusResponse(params).pipe(
      __map(_r => _r.body as ApiResponseRemittance)
    );
  }
}

module RemittancesService {

  /**
   * Parameters for AddFileToRemittance
   */
  export interface AddFileToRemittanceParams {

    /**
     * The remittance id.
     */
    remittanceId: number;
    file?: Blob;
  }

  /**
   * Parameters for GetRemittances
   */
  export interface GetRemittancesParams {

    /**
     * The status.
     */
    status?: 'Draft' | 'Posted' | 'FailedImport' | 'Ignored';

    /**
     * The remittance Date To.
     */
    remittanceDateTo?: string;

    /**
     * The remittance Date From.
     */
    remittanceDateFrom?: string;

    /**
     * The reference.
     */
    reference?: string;

    /**
     * The payment type.
     */
    paymentType?: 'Ebt' | 'Cash' | 'Check';

    /**
     * The patient id.
     */
    patientId?: number;

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * Show only remittances with charges that need follow up.
     */
    onlyNeedsFollowUp?: boolean;

    /**
     * The Insurance Company Id.
     */
    insuranceCompanyId?: number;

    /**
     * The include patient names.
     */
    includePatientNames?: boolean;

    /**
     * The created By.
     */
    createdBy?: number;
  }

  /**
   * Parameters for RemoveFileFromRemittance
   */
  export interface RemoveFileFromRemittanceParams {

    /**
     * The remittance id.
     */
    remittanceId: number;

    /**
     * The remittance document id.
     */
    remittanceDocumentId: string;
  }

  /**
   * Parameters for UpdateRemittance
   */
  export interface UpdateRemittanceParams {

    /**
     * The remittance Id.
     */
    remittanceId: number;

    /**
     * The remittance to update.
     */
    remittanceCreateUpdateRequest?: RemittanceCreateUpdateRequest;
  }

  /**
   * Parameters for GetRemittanceDocuments
   */
  export interface GetRemittanceDocumentsParams {

    /**
     * The upload Date To.
     */
    uploadDateTo?: string;

    /**
     * The upload Date From.
     */
    uploadDateFrom?: string;

    /**
     * The remittance id.
     */
    remittanceId?: number;

    /**
     * The patient id.
     */
    patientId?: number;

    /**
     * The file name.
     */
    fileName?: string;
  }

  /**
   * Parameters for GetRemittancesCount
   */
  export interface GetRemittancesCountParams {

    /**
     * The status.
     */
    status?: 'Draft' | 'Posted' | 'FailedImport' | 'Ignored';

    /**
     * The remittance Date To.
     */
    remittanceDateTo?: string;

    /**
     * The remittance Date From.
     */
    remittanceDateFrom?: string;

    /**
     * The reference.
     */
    reference?: string;

    /**
     * The patient id.
     */
    patientId?: number;

    /**
     * The only Auto Created.
     */
    onlyAutoCreated?: boolean;

    /**
     * The Insurance Company Id.
     */
    insuranceCompanyId?: number;
  }

  /**
   * Parameters for UpdateRemittanceStatus
   */
  export interface UpdateRemittanceStatusParams {

    /**
     * The remittance Id.
     */
    remittanceId: number;

    /**
     * The remittance status.
     */
    status?: 'Draft' | 'Posted' | 'FailedImport' | 'Ignored';
  }
}

export { RemittancesService }
