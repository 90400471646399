/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionCharge } from '../models/api-response-icollection-charge';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ChargeFlagUpdateRequest } from '../models/charge-flag-update-request';
@Injectable({
  providedIn: 'root',
})
class ChargesService extends __BaseService {
  static readonly GetChargesPath = '/Insurance/Charges';
  static readonly UpdateChargeFlagPath = '/Insurance/Charges/{chargeId}/FollowUps';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get charges.
   * @param params The `ChargesService.GetChargesParams` containing the following parameters:
   *
   * - `showFlaggedOnly`: The flagged only.
   *
   * - `patientId`: The patient id.
   *
   * - `invoiceItemId`: The invoice item id.
   *
   * - `insuranceId`: The insurance id.
   *
   * - `includeRelatedCharges`:
   *
   * - `dateOfService`: The date of service.
   *
   * - `claimStatus`: The list of claim statuses to filter comma delimited.
   *
   * - `claimId`: The claim id.
   *
   * - `chargeStatus`: The status.
   *
   * @return Success
   */
  GetChargesResponse(params: ChargesService.GetChargesParams): __Observable<__StrictHttpResponse<ApiResponseICollectionCharge>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showFlaggedOnly != null) __params = __params.set('showFlaggedOnly', params.showFlaggedOnly.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.invoiceItemId != null) __params = __params.set('invoiceItemId', params.invoiceItemId.toString());
    if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
    if (params.includeRelatedCharges != null) __params = __params.set('includeRelatedCharges', params.includeRelatedCharges.toString());
    if (params.dateOfService != null) __params = __params.set('dateOfService', params.dateOfService.toString());
    if (params.claimStatus != null) __params = __params.set('claimStatus', params.claimStatus.toString());
    if (params.claimId != null) __params = __params.set('claimId', params.claimId.toString());
    if (params.chargeStatus != null) __params = __params.set('chargeStatus', params.chargeStatus.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Charges`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionCharge>;
      })
    );
  }
  /**
   * Get charges.
   * @param params The `ChargesService.GetChargesParams` containing the following parameters:
   *
   * - `showFlaggedOnly`: The flagged only.
   *
   * - `patientId`: The patient id.
   *
   * - `invoiceItemId`: The invoice item id.
   *
   * - `insuranceId`: The insurance id.
   *
   * - `includeRelatedCharges`:
   *
   * - `dateOfService`: The date of service.
   *
   * - `claimStatus`: The list of claim statuses to filter comma delimited.
   *
   * - `claimId`: The claim id.
   *
   * - `chargeStatus`: The status.
   *
   * @return Success
   */
  GetCharges(params: ChargesService.GetChargesParams): __Observable<ApiResponseICollectionCharge> {
    return this.GetChargesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionCharge)
    );
  }

  /**
   * Update the charge flag to follow up.
   * @param params The `ChargesService.UpdateChargeFlagParams` containing the following parameters:
   *
   * - `chargeId`: The charge id.
   *
   * - `chargeFlagUpdateRequest`: the  charge flag update request.
   *
   * @return Success
   */
  UpdateChargeFlagResponse(params: ChargesService.UpdateChargeFlagParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.chargeFlagUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Charges/${encodeURIComponent(String(params.chargeId))}/FollowUps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the charge flag to follow up.
   * @param params The `ChargesService.UpdateChargeFlagParams` containing the following parameters:
   *
   * - `chargeId`: The charge id.
   *
   * - `chargeFlagUpdateRequest`: the  charge flag update request.
   *
   * @return Success
   */
  UpdateChargeFlag(params: ChargesService.UpdateChargeFlagParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateChargeFlagResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module ChargesService {

  /**
   * Parameters for GetCharges
   */
  export interface GetChargesParams {

    /**
     * The flagged only.
     */
    showFlaggedOnly?: boolean;

    /**
     * The patient id.
     */
    patientId?: number;

    /**
     * The invoice item id.
     */
    invoiceItemId?: number;

    /**
     * The insurance id.
     */
    insuranceId?: number;
    includeRelatedCharges?: boolean;

    /**
     * The date of service.
     */
    dateOfService?: string;

    /**
     * The list of claim statuses to filter comma delimited.
     */
    claimStatus?: string;

    /**
     * The claim id.
     */
    claimId?: number;

    /**
     * The status.
     */
    chargeStatus?: 'Active' | 'Closed' | 'Deleted';
  }

  /**
   * Parameters for UpdateChargeFlag
   */
  export interface UpdateChargeFlagParams {

    /**
     * The charge id.
     */
    chargeId: number;

    /**
     * the  charge flag update request.
     */
    chargeFlagUpdateRequest?: ChargeFlagUpdateRequest;
  }
}

export { ChargesService }
