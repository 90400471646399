import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';

export class WysiwygCheckboxAddon implements WysiwygAddon {
    public static button = 'symplastCheckbox';
    public name = 'symplast-checkbox';

    public init(config: WysiwygEditorConfiguration): void {
        config.registerSetupCallback((editor) => {
            editor.ui.registry.addButton(WysiwygCheckboxAddon.button, {
                icon: 'checkmark',
                tooltip: 'Insert checkbox',
                onAction: () => {
                    editor.insertContent(`<input type="checkbox" style="width: 15px; height: 15px" />`);
                },
            });

            // Event listener for checkbox interaction
            editor.on('click', (event: any) => {
                if (event.target?.tagName === 'INPUT' && event.target?.type === 'checkbox') {
                    // Toggle checkbox state
                    if (event.target.checked) {
                        event.target.setAttribute('checked', 'checked');
                    } else {
                        event.target.removeAttribute('checked');
                    }
                }
            });
        });
    }
}
