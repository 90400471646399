/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableAppVersionInfo } from '../models/api-response-ienumerable-app-version-info';
import { ApiResponseIEnumerableAdminTenantResponse } from '../models/api-response-ienumerable-admin-tenant-response';
import { ApiResponseIEnumerableInfrastructureTenantResponse } from '../models/api-response-ienumerable-infrastructure-tenant-response';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { InfrastructureTenantUpsertRequest } from '../models/infrastructure-tenant-upsert-request';
import { ApiResponseIEnumerablePrivateTenantResponse } from '../models/api-response-ienumerable-private-tenant-response';
import { InsurancePrivateSettingsRequest } from '../models/insurance-private-settings-request';
import { ApiResponseIEnumerableTenantPublicResponse } from '../models/api-response-ienumerable-tenant-public-response';
import { ApiResponseAdminTenantResponse } from '../models/api-response-admin-tenant-response';
import { ApiResponseInfrastructureTenantResponse } from '../models/api-response-infrastructure-tenant-response';
import { ApiResponsePrivateTenantResponse } from '../models/api-response-private-tenant-response';
import { ApiResponseTenantPublicResponse } from '../models/api-response-tenant-public-response';
import { AppsPatchRequest } from '../models/apps-patch-request';
import { TenantCreateRequest } from '../models/tenant-create-request';
import { CompanyUpdateRequest } from '../models/company-update-request';
import { CommonSettingsUpdateRequest } from '../models/common-settings-update-request';
import { InsuranceSettingsUpdateRequest } from '../models/insurance-settings-update-request';
import { ActivitiesSettingsUpdateRequest } from '../models/activities-settings-update-request';
import { PracticeConversationSettingsUpdateRequest } from '../models/practice-conversation-settings-update-request';
import { PracticeSchedulingSettingsUpdateRequest } from '../models/practice-scheduling-settings-update-request';
import { OnlineAppointmentRequestsSettingsUpdateRequest } from '../models/online-appointment-requests-settings-update-request';
import { PatientsLegalFormsSettingsUpdateRequest } from '../models/patients-legal-forms-settings-update-request';
import { PracticeSystemSettingsUpdateRequest } from '../models/practice-system-settings-update-request';
import { PracticePaymentsSettingsUpdateRequest } from '../models/practice-payments-settings-update-request';
import { PracticeReminderSettingsUpdateRequest } from '../models/practice-reminder-settings-update-request';
import { PracticeScheduleTemplatesSettingsUpdateRequest } from '../models/practice-schedule-templates-settings-update-request';
import { SurchargesSettingsUpdateRequest } from '../models/surcharges-settings-update-request';
import { RecurringPaymentsSettingsUpdateRequest } from '../models/recurring-payments-settings-update-request';
import { FinancialSettingsUpdateRequest } from '../models/financial-settings-update-request';
import { VirtualConsultationSettingsUpdateRequest } from '../models/virtual-consultation-settings-update-request';
import { TouchMdSettingsUpdateRequest } from '../models/touch-md-settings-update-request';
import { TrizettoSettingsUpdateRequest } from '../models/trizetto-settings-update-request';
import { CanfieldSettingsUpdateRequest } from '../models/canfield-settings-update-request';
import { EmailSettingsUpdateRequest } from '../models/email-settings-update-request';
import { ApiResponseTenantShortInfo } from '../models/api-response-tenant-short-info';
import { SecurityConfigurationUpdateRequest } from '../models/security-configuration-update-request';
import { GenericSettingUpdateRequest } from '../models/generic-setting-update-request';
import { IntakesSettingsUpdateRequest } from '../models/intakes-settings-update-request';
import { TasksSettingsUpdateRequest } from '../models/tasks-settings-update-request';
import { CalendarBlocksSettingsUpdateRequest } from '../models/calendar-blocks-settings-update-request';
import { TasksInfrastructureSettingsUpdateRequest } from '../models/tasks-infrastructure-settings-update-request';
import { AwsKeysPatchRequest } from '../models/aws-keys-patch-request';
import { InsuranceContractStatusUpdateRequest } from '../models/insurance-contract-status-update-request';
import { KioskFeatureStatusUpdateRequest } from '../models/kiosk-feature-status-update-request';
import { WeightLossManagementSettingsRequest } from '../models/weight-loss-management-settings-request';
import { DosespotVersionUpdateRequest } from '../models/dosespot-version-update-request';
@Injectable({
    providedIn: 'root',
})
class TenantsService extends __BaseService {
    static readonly GetAppVersionInfoPath = '/tenants/appVersionInfo';
    static readonly GetAdminPath = '/tenants/adminSettings';
    static readonly GetInfrastructurePath = '/tenants/infrastructureSettings';
    static readonly PatchMultipleInfrastructureSettingsPath = '/tenants/infrastructureSettings';
    static readonly GetPrivatePath = '/tenants/privateSettings';
    static readonly SetInsurancePrivateSettingsPath = '/tenants/{tenantId}/privateSettings/Insurance';
    static readonly GetPublicPath = '/tenants/publicSettings';
    static readonly GetSingleAdminPath = '/tenants/{tenantId}/adminSettings';
    static readonly GetSingleInfrastructurePath = '/tenants/{tenantId}/infrastructureSettings';
    static readonly GetSinglePrivatePath = '/tenants/{tenantId}/privateSettings';
    static readonly GetSinglePublicPath = '/tenants/{tenantId}/publicSettings';
    static readonly PatchAppsPath = '/tenants/{tenantId}/publicSettings/apps';
    static readonly PatchMultipleAppsPath = '/tenants/publicSettings/apps';
    static readonly CreateNewTenantPath = '/tenants';
    static readonly UpdateCompanySettingsPath = '/tenants/{tenantId}/companySettings';
    static readonly UpdateCommonSettingsPath = '/tenants/{tenantId}/infrastructureSettings/commonSettings';
    static readonly UpdateInsuranceSettingsPath = '/tenants/{tenantId}/infrastructureSettings/insurance';
    static readonly UpdateActivitiesSettingsPath = '/tenants/{tenantId}/infrastructureSettings/activities';
    static readonly UpdatePracticeConversationSettingsPath = '/tenants/{tenantId}/infrastructureSettings/practiceConversationSettings';
    static readonly UpdatePracticeSchedulingSettingsPath = '/tenants/{tenantId}/infrastructureSettings/practiceSchedulingSettings';
    static readonly UpdateOnlineAppointmentRequestsSettingsPath =
        '/tenants/{tenantId}/infrastructureSettings/onlineAppointmentRequestsSettings';
    static readonly UpdatePatientsLegalFormsPath = '/tenants/{tenantId}/infrastructureSettings/patientsLegalForms';
    static readonly UpdatePatientsLegalFormsForMultipleTenantsPath = '/tenants/patientsLegalForms';
    static readonly UpdatePracticeSystemSettingsPath = '/tenants/{tenantId}/infrastructureSettings/practiceSystemSettings';
    static readonly UpdatePracticePaymentsSettingsPath = '/tenants/{tenantId}/infrastructureSettings/practicePaymentsSettings';
    static readonly UpdateReminderSettingsPath = '/tenants/{tenantId}/infrastructureSettings/reminderSettings';
    static readonly UpdatePracticeScheduleTemplatesSettingsPath =
        '/tenants/{tenantId}/infrastructureSettings/practiceScheduleTemplatesSettings';
    static readonly UpdateSurchargeSettingsPath = '/tenants/{tenantId}/infrastructureSettings/surchargeSettings';
    static readonly UpdateRecurringPaymentsSettingsPath = '/tenants/{tenantId}/infrastructureSettings/recurringPaymentsSettings';
    static readonly UpdateFeatureStatePath = '/tenants/{tenantId}/features/{tenantFeature}/state';
    static readonly UpdateFinancialSettingsPath = '/tenants/{tenantId}/infrastructureSettings/financialSettings';
    static readonly UpdateVirtualConsultationSettingsPath = '/tenants/{tenantId}/infrastructureSettings/virtualConsultation';
    static readonly UpdateTouchMdSettingsPath = '/tenants/{tenantId}/infrastructureSettings/touchMd';
    static readonly UpdateTrizettoSettingsPath = '/tenants/{tenantId}/infrastructureSettings/trizetto';
    static readonly UpdateCanfieldSettingsPath = '/tenants/{tenantId}/infrastructureSettings/canfield';
    static readonly UpdateEmailSettingsPath = '/tenants/{tenantId}/infrastructureSettings/email';
    static readonly GetTenantsByEmailAliasPath = '/tenants/infrastructureSettings/email';
    static readonly UpdateSqlBrokerSettingsPath = '/tenants/{tenantId}/infrastructureSettings/sqlBroker';
    static readonly UpdateCalendarSettingsPath = '/tenants/{tenantId}/infrastructureSettings/calendar';
    static readonly UpdateSecurityConfigurationPath = '/tenants/{tenantId}/infrastructureSettings/securityConfiguration';
    static readonly SetGenericSettingValuePath = '/tenants/{tenantId}/privateSettings/generic';
    static readonly ClearGenericSettingValuePath = '/tenants/{tenantId}/privateSettings/generic/{settingKey}';
    static readonly UpdateIntakesSettingsPath = '/tenants/{tenantId}/privateSettings/intakes';
    static readonly UpdateTasksSettingsPath = '/tenants/{tenantId}/tasksSettings';
    static readonly UpdateCalendarBlocksSettingsPath = '/tenants/{tenantId}/calendarBlocksSettings';
    static readonly UpdateTasksInfrastructureSettingsPath = '/tenants/{tenantId}/infrastructureSettings/tasks';
    static readonly DropCachePath = '/tenants/{tenantId}/cache';
    static readonly DropCacheForMultipleTenantsAsyncPath = '/tenants/cache';
    static readonly PatchAwsKeysPath = '/tenants/AwsKeys';
    static readonly UpdateInsuranceContractStatusPath = '/tenants/{tenantId}/infrastructureSettings/insuranceContractStatus';
    static readonly UpdateKioskFeatureStatusPath = '/tenants/{tenantId}/infrastructureSettings/kioskFeatureStatus';
    static readonly UpdateWeightLossManagementPath = '/tenants/{tenantId}/infrastructureSettings/weightLossManagement';
    static readonly UpdateDosespotVersionPath = '/tenants/{tenantId}/infrastructureSettings/dosespot';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * The get app version info.
     * @param appVersion The app Version.
     * @return Success
     */
    GetAppVersionInfoResponse(appVersion?: string): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppVersionInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (appVersion != null) __params = __params.set('appVersion', appVersion.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/appVersionInfo`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAppVersionInfo>;
            }),
        );
    }
    /**
     * The get app version info.
     * @param appVersion The app Version.
     * @return Success
     */
    GetAppVersionInfo(appVersion?: string): __Observable<ApiResponseIEnumerableAppVersionInfo> {
        return this.GetAppVersionInfoResponse(appVersion).pipe(__map((_r) => _r.body as ApiResponseIEnumerableAppVersionInfo));
    }

    /**
     * Gets the admin settings for all tenants.
     * @param params The `TenantsService.GetAdminParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetAdminResponse(params: TenantsService.GetAdminParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAdminTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/adminSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAdminTenantResponse>;
            }),
        );
    }
    /**
     * Gets the admin settings for all tenants.
     * @param params The `TenantsService.GetAdminParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetAdmin(params: TenantsService.GetAdminParams): __Observable<ApiResponseIEnumerableAdminTenantResponse> {
        return this.GetAdminResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableAdminTenantResponse));
    }

    /**
     * Gets the infrastructure settings for all tenants.
     * @param params The `TenantsService.GetInfrastructureParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetInfrastructureResponse(
        params: TenantsService.GetInfrastructureParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/infrastructureSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Gets the infrastructure settings for all tenants.
     * @param params The `TenantsService.GetInfrastructureParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetInfrastructure(params: TenantsService.GetInfrastructureParams): __Observable<ApiResponseIEnumerableInfrastructureTenantResponse> {
        return this.GetInfrastructureResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableInfrastructureTenantResponse));
    }

    /**
     * Modifies or creates multiple tenants.
     * @param tenantRequests The tenant requests.
     * @return Success
     */
    PatchMultipleInfrastructureSettingsResponse(
        tenantRequests?: Array<InfrastructureTenantUpsertRequest>,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = tenantRequests;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/tenants/infrastructureSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies or creates multiple tenants.
     * @param tenantRequests The tenant requests.
     * @return Success
     */
    PatchMultipleInfrastructureSettings(tenantRequests?: Array<InfrastructureTenantUpsertRequest>): __Observable<ApiResponseOkResponse> {
        return this.PatchMultipleInfrastructureSettingsResponse(tenantRequests).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the private settings for all tenants.
     * @param params The `TenantsService.GetPrivateParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetPrivateResponse(
        params: TenantsService.GetPrivateParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePrivateTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/privateSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePrivateTenantResponse>;
            }),
        );
    }
    /**
     * Gets the private settings for all tenants.
     * @param params The `TenantsService.GetPrivateParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetPrivate(params: TenantsService.GetPrivateParams): __Observable<ApiResponseIEnumerablePrivateTenantResponse> {
        return this.GetPrivateResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerablePrivateTenantResponse));
    }

    /**
     * Update the insurance private settings flag.
     * @param params The `TenantsService.SetInsurancePrivateSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `insurancePrivateSettingsRequest`: The insurance Private Settings Request.
     *
     * @return Success
     */
    SetInsurancePrivateSettingsResponse(
        params: TenantsService.SetInsurancePrivateSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.insurancePrivateSettingsRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/privateSettings/Insurance`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Update the insurance private settings flag.
     * @param params The `TenantsService.SetInsurancePrivateSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `insurancePrivateSettingsRequest`: The insurance Private Settings Request.
     *
     * @return Success
     */
    SetInsurancePrivateSettings(params: TenantsService.SetInsurancePrivateSettingsParams): __Observable<ApiResponseOkResponse> {
        return this.SetInsurancePrivateSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the public settings for all tenants.
     * @param params The `TenantsService.GetPublicParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetPublicResponse(
        params: TenantsService.GetPublicParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTenantPublicResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/publicSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableTenantPublicResponse>;
            }),
        );
    }
    /**
     * Gets the public settings for all tenants.
     * @param params The `TenantsService.GetPublicParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    GetPublic(params: TenantsService.GetPublicParams): __Observable<ApiResponseIEnumerableTenantPublicResponse> {
        return this.GetPublicResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableTenantPublicResponse));
    }

    /**
     * Gets the admin settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSingleAdminResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponseAdminTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/${encodeURIComponent(String(tenantId))}/adminSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAdminTenantResponse>;
            }),
        );
    }
    /**
     * Gets the admin settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSingleAdmin(tenantId: number): __Observable<ApiResponseAdminTenantResponse> {
        return this.GetSingleAdminResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponseAdminTenantResponse));
    }

    /**
     * Gets the infrastructure settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSingleInfrastructureResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/tenants/${encodeURIComponent(String(tenantId))}/infrastructureSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Gets the infrastructure settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSingleInfrastructure(tenantId: number): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.GetSingleInfrastructureResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Gets the single private settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSinglePrivateResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponsePrivateTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/${encodeURIComponent(String(tenantId))}/privateSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePrivateTenantResponse>;
            }),
        );
    }
    /**
     * Gets the single private settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSinglePrivate(tenantId: number): __Observable<ApiResponsePrivateTenantResponse> {
        return this.GetSinglePrivateResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponsePrivateTenantResponse));
    }

    /**
     * Gets the public settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSinglePublicResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponseTenantPublicResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/${encodeURIComponent(String(tenantId))}/publicSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseTenantPublicResponse>;
            }),
        );
    }
    /**
     * Gets the public settings for single tenant.
     * @param tenantId The identifier.
     * @return Success
     */
    GetSinglePublic(tenantId: number): __Observable<ApiResponseTenantPublicResponse> {
        return this.GetSinglePublicResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponseTenantPublicResponse));
    }

    /**
     * Updates applications settings for single tenant.
     * @param params The `TenantsService.PatchAppsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The update request.
     *
     * @return Success
     */
    PatchAppsResponse(params: TenantsService.PatchAppsParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/publicSettings/apps`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Updates applications settings for single tenant.
     * @param params The `TenantsService.PatchAppsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The update request.
     *
     * @return Success
     */
    PatchApps(params: TenantsService.PatchAppsParams): __Observable<ApiResponseOkResponse> {
        return this.PatchAppsResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Updates applications settings for multiple tenants.
     * @param params The `TenantsService.PatchMultipleAppsParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `request`: The update request.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    PatchMultipleAppsResponse(params: TenantsService.PatchMultipleAppsParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        __body = params.request;
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/tenants/publicSettings/apps`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Updates applications settings for multiple tenants.
     * @param params The `TenantsService.PatchMultipleAppsParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `request`: The update request.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    PatchMultipleApps(params: TenantsService.PatchMultipleAppsParams): __Observable<ApiResponseOkResponse> {
        return this.PatchMultipleAppsResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Creates the new tenant.
     * @param tenantRequest The tenant request.
     * @return Success or Created
     */
    CreateNewTenantResponse(
        tenantRequest?: TenantCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse | ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = tenantRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/tenants`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse | ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Creates the new tenant.
     * @param tenantRequest The tenant request.
     * @return Success or Created
     */
    CreateNewTenant(
        tenantRequest?: TenantCreateRequest,
    ): __Observable<ApiResponseInfrastructureTenantResponse | ApiResponseInfrastructureTenantResponse> {
        return this.CreateNewTenantResponse(tenantRequest).pipe(
            __map((_r) => _r.body as ApiResponseInfrastructureTenantResponse | ApiResponseInfrastructureTenantResponse),
        );
    }

    /**
     * Modifies settings of the tenant that are available for company to modify.
     * @param params The `TenantsService.UpdateCompanySettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `companyUpdateRequest`: The company settings update request.
     *
     * @return Success
     */
    UpdateCompanySettingsResponse(
        params: TenantsService.UpdateCompanySettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.companyUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/companySettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies settings of the tenant that are available for company to modify.
     * @param params The `TenantsService.UpdateCompanySettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `companyUpdateRequest`: The company settings update request.
     *
     * @return Success
     */
    UpdateCompanySettings(params: TenantsService.UpdateCompanySettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateCompanySettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies commonly changed settings of the tenant.
     * @param params The `TenantsService.UpdateCommonSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `commonSettingsUpdateRequest`: The common settings update request.
     *
     * @return Success
     */
    UpdateCommonSettingsResponse(
        params: TenantsService.UpdateCommonSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.commonSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/commonSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies commonly changed settings of the tenant.
     * @param params The `TenantsService.UpdateCommonSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `commonSettingsUpdateRequest`: The common settings update request.
     *
     * @return Success
     */
    UpdateCommonSettings(params: TenantsService.UpdateCommonSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateCommonSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies Insurance settings of the tenant.
     * @param params The `TenantsService.UpdateInsuranceSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `insuranceSettingsUpdateRequest`: The insurance settings update request.
     *
     * @return Success
     */
    UpdateInsuranceSettingsResponse(
        params: TenantsService.UpdateInsuranceSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.insuranceSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/insurance`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies Insurance settings of the tenant.
     * @param params The `TenantsService.UpdateInsuranceSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `insuranceSettingsUpdateRequest`: The insurance settings update request.
     *
     * @return Success
     */
    UpdateInsuranceSettings(params: TenantsService.UpdateInsuranceSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateInsuranceSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies activities settings of the tenant.
     * @param params The `TenantsService.UpdateActivitiesSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `activitiesSettingsUpdateRequest`: The activities settings update request.
     *
     * @return Success
     */
    UpdateActivitiesSettingsResponse(
        params: TenantsService.UpdateActivitiesSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.activitiesSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/activities`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies activities settings of the tenant.
     * @param params The `TenantsService.UpdateActivitiesSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `activitiesSettingsUpdateRequest`: The activities settings update request.
     *
     * @return Success
     */
    UpdateActivitiesSettings(params: TenantsService.UpdateActivitiesSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateActivitiesSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies practice conversation settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeConversationSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeConversationSettingsResponse(
        params: TenantsService.UpdatePracticeConversationSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/practiceConversationSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies practice conversation settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeConversationSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeConversationSettings(
        params: TenantsService.UpdatePracticeConversationSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdatePracticeConversationSettingsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseInfrastructureTenantResponse),
        );
    }

    /**
     * Modifies practice scheduling settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeSchedulingSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeSchedulingSettingsResponse(
        params: TenantsService.UpdatePracticeSchedulingSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/practiceSchedulingSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies practice scheduling settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeSchedulingSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeSchedulingSettings(
        params: TenantsService.UpdatePracticeSchedulingSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdatePracticeSchedulingSettingsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseInfrastructureTenantResponse),
        );
    }

    /**
     * Modifies practice online appointments requests settings of the tenant.
     * @param params The `TenantsService.UpdateOnlineAppointmentRequestsSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateOnlineAppointmentRequestsSettingsResponse(
        params: TenantsService.UpdateOnlineAppointmentRequestsSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/onlineAppointmentRequestsSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies practice online appointments requests settings of the tenant.
     * @param params The `TenantsService.UpdateOnlineAppointmentRequestsSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateOnlineAppointmentRequestsSettings(
        params: TenantsService.UpdateOnlineAppointmentRequestsSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateOnlineAppointmentRequestsSettingsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseInfrastructureTenantResponse),
        );
    }

    /**
     * Modifies patients legal form settings of the tenant.
     * @param params The `TenantsService.UpdatePatientsLegalFormsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePatientsLegalFormsResponse(
        params: TenantsService.UpdatePatientsLegalFormsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/patientsLegalForms`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies patients legal form settings of the tenant.
     * @param params The `TenantsService.UpdatePatientsLegalFormsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePatientsLegalForms(params: TenantsService.UpdatePatientsLegalFormsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdatePatientsLegalFormsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies patients legal form settings of the multiple tenants.
     * @param params The `TenantsService.UpdatePatientsLegalFormsForMultipleTenantsParams` containing the following parameters:
     *
     * - `tenantType`: The tenant type filter.
     *
     * - `request`: The request.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    UpdatePatientsLegalFormsForMultipleTenantsResponse(
        params: TenantsService.UpdatePatientsLegalFormsForMultipleTenantsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        __body = params.request;
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/tenants/patientsLegalForms`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies patients legal form settings of the multiple tenants.
     * @param params The `TenantsService.UpdatePatientsLegalFormsForMultipleTenantsParams` containing the following parameters:
     *
     * - `tenantType`: The tenant type filter.
     *
     * - `request`: The request.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    UpdatePatientsLegalFormsForMultipleTenants(
        params: TenantsService.UpdatePatientsLegalFormsForMultipleTenantsParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdatePatientsLegalFormsForMultipleTenantsResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Modifies practice system settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeSystemSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeSystemSettingsResponse(
        params: TenantsService.UpdatePracticeSystemSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/practiceSystemSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies practice system settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeSystemSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeSystemSettings(
        params: TenantsService.UpdatePracticeSystemSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdatePracticeSystemSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies practice payments settings of the tenant.
     * @param params The `TenantsService.UpdatePracticePaymentsSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticePaymentsSettingsResponse(
        params: TenantsService.UpdatePracticePaymentsSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/practicePaymentsSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies practice payments settings of the tenant.
     * @param params The `TenantsService.UpdatePracticePaymentsSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticePaymentsSettings(
        params: TenantsService.UpdatePracticePaymentsSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdatePracticePaymentsSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies reminder settings of the tenant.
     * @param params The `TenantsService.UpdateReminderSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateReminderSettingsResponse(
        params: TenantsService.UpdateReminderSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/reminderSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies reminder settings of the tenant.
     * @param params The `TenantsService.UpdateReminderSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateReminderSettings(params: TenantsService.UpdateReminderSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateReminderSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies practice schedule templates settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeScheduleTemplatesSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeScheduleTemplatesSettingsResponse(
        params: TenantsService.UpdatePracticeScheduleTemplatesSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/practiceScheduleTemplatesSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies practice schedule templates settings of the tenant.
     * @param params The `TenantsService.UpdatePracticeScheduleTemplatesSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdatePracticeScheduleTemplatesSettings(
        params: TenantsService.UpdatePracticeScheduleTemplatesSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdatePracticeScheduleTemplatesSettingsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseInfrastructureTenantResponse),
        );
    }

    /**
     * Modifies surcharges settings of the tenant.
     * @param params The `TenantsService.UpdateSurchargeSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateSurchargeSettingsResponse(
        params: TenantsService.UpdateSurchargeSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/surchargeSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies surcharges settings of the tenant.
     * @param params The `TenantsService.UpdateSurchargeSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateSurchargeSettings(params: TenantsService.UpdateSurchargeSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateSurchargeSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies recurring payments settings of the tenant.
     * @param params The `TenantsService.UpdateRecurringPaymentsSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateRecurringPaymentsSettingsResponse(
        params: TenantsService.UpdateRecurringPaymentsSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/recurringPaymentsSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies recurring payments settings of the tenant.
     * @param params The `TenantsService.UpdateRecurringPaymentsSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateRecurringPaymentsSettings(
        params: TenantsService.UpdateRecurringPaymentsSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateRecurringPaymentsSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Updates the feature state asynchronous.
     * @param params The `TenantsService.UpdateFeatureStateParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `tenantFeature`: The tenant feature.
     *
     * - `isEnabled`: if set to `true` [is enabled].
     *
     * @return Success
     */
    UpdateFeatureStateResponse(
        params: TenantsService.UpdateFeatureStateParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/tenants/${encodeURIComponent(String(params.tenantId))}/features/${encodeURIComponent(
                    String(params.tenantFeature),
                )}/state`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Updates the feature state asynchronous.
     * @param params The `TenantsService.UpdateFeatureStateParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `tenantFeature`: The tenant feature.
     *
     * - `isEnabled`: if set to `true` [is enabled].
     *
     * @return Success
     */
    UpdateFeatureState(params: TenantsService.UpdateFeatureStateParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateFeatureStateResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies financial settings of the tenant.
     * @param params The `TenantsService.UpdateFinancialSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateFinancialSettingsResponse(
        params: TenantsService.UpdateFinancialSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/financialSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies financial settings of the tenant.
     * @param params The `TenantsService.UpdateFinancialSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateFinancialSettings(params: TenantsService.UpdateFinancialSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateFinancialSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies virtual consultation settings of the tenant.
     * @param params The `TenantsService.UpdateVirtualConsultationSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `virtualConsultationSettingsUpdateRequest`: The virtual consultation settings update request.
     *
     * @return Success
     */
    UpdateVirtualConsultationSettingsResponse(
        params: TenantsService.UpdateVirtualConsultationSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.virtualConsultationSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/virtualConsultation`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies virtual consultation settings of the tenant.
     * @param params The `TenantsService.UpdateVirtualConsultationSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `virtualConsultationSettingsUpdateRequest`: The virtual consultation settings update request.
     *
     * @return Success
     */
    UpdateVirtualConsultationSettings(
        params: TenantsService.UpdateVirtualConsultationSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateVirtualConsultationSettingsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseInfrastructureTenantResponse),
        );
    }

    /**
     * Modifies TouchMD settings of the tenant.
     * @param params The `TenantsService.UpdateTouchMdSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `touchMdSettingsUpdateRequest`: The TouchMD settings update request.
     *
     * @return Success
     */
    UpdateTouchMdSettingsResponse(
        params: TenantsService.UpdateTouchMdSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.touchMdSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/touchMd`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies TouchMD settings of the tenant.
     * @param params The `TenantsService.UpdateTouchMdSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `touchMdSettingsUpdateRequest`: The TouchMD settings update request.
     *
     * @return Success
     */
    UpdateTouchMdSettings(params: TenantsService.UpdateTouchMdSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateTouchMdSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies Trizetto settings of the tenant.
     * @param params The `TenantsService.UpdateTrizettoSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `trizettoSettingsUpdateRequest`: The Trizetto settings update request.
     *
     * @return Success
     */
    UpdateTrizettoSettingsResponse(
        params: TenantsService.UpdateTrizettoSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.trizettoSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/trizetto`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies Trizetto settings of the tenant.
     * @param params The `TenantsService.UpdateTrizettoSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `trizettoSettingsUpdateRequest`: The Trizetto settings update request.
     *
     * @return Success
     */
    UpdateTrizettoSettings(params: TenantsService.UpdateTrizettoSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateTrizettoSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies Canfield settings of the tenant.
     * @param params The `TenantsService.UpdateCanfieldSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `canfieldSettingsUpdateRequest`: The Canfield settings update request.
     *
     * @return Success
     */
    UpdateCanfieldSettingsResponse(
        params: TenantsService.UpdateCanfieldSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.canfieldSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/canfield`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies Canfield settings of the tenant.
     * @param params The `TenantsService.UpdateCanfieldSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `canfieldSettingsUpdateRequest`: The Canfield settings update request.
     *
     * @return Success
     */
    UpdateCanfieldSettings(params: TenantsService.UpdateCanfieldSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateCanfieldSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies Email SendGrid settings of the tenant.
     * @param params The `TenantsService.UpdateEmailSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `emailSettingsUpdateRequest`: The SendGrid settings update request.
     *
     * @return Success
     */
    UpdateEmailSettingsResponse(
        params: TenantsService.UpdateEmailSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.emailSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/email`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies Email SendGrid settings of the tenant.
     * @param params The `TenantsService.UpdateEmailSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `emailSettingsUpdateRequest`: The SendGrid settings update request.
     *
     * @return Success
     */
    UpdateEmailSettings(params: TenantsService.UpdateEmailSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateEmailSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * The get tenants by email alias.
     * @param emailAlias The email alias.
     * @return Success
     */
    GetTenantsByEmailAliasResponse(emailAlias: string): __Observable<__StrictHttpResponse<ApiResponseTenantShortInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (emailAlias != null) __params = __params.set('emailAlias', emailAlias.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/tenants/infrastructureSettings/email`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseTenantShortInfo>;
            }),
        );
    }
    /**
     * The get tenants by email alias.
     * @param emailAlias The email alias.
     * @return Success
     */
    GetTenantsByEmailAlias(emailAlias: string): __Observable<ApiResponseTenantShortInfo> {
        return this.GetTenantsByEmailAliasResponse(emailAlias).pipe(__map((_r) => _r.body as ApiResponseTenantShortInfo));
    }

    /**
     * Enables or disables SQL Broker configuration for tenant.
     * @param params The `TenantsService.UpdateSqlBrokerSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `isEnabled`: Whether the SQL broker should be enabled or not.
     *
     * @return Success
     */
    UpdateSqlBrokerSettingsResponse(
        params: TenantsService.UpdateSqlBrokerSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/sqlBroker`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Enables or disables SQL Broker configuration for tenant.
     * @param params The `TenantsService.UpdateSqlBrokerSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `isEnabled`: Whether the SQL broker should be enabled or not.
     *
     * @return Success
     */
    UpdateSqlBrokerSettings(params: TenantsService.UpdateSqlBrokerSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateSqlBrokerSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * The update calendar settings async.
     * @param params The `TenantsService.UpdateCalendarSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `useCosmosDb`: If true, get appointments for the calendar from Cosmos Db and update it.
     *
     * - `alwaysUpdateCosmosDb`: If true, update appointments in Cosmos Db even if useCosmosDb flag is disabled.
     *
     * @return Success
     */
    UpdateCalendarSettingsResponse(
        params: TenantsService.UpdateCalendarSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.useCosmosDb != null) __params = __params.set('useCosmosDb', params.useCosmosDb.toString());
        if (params.alwaysUpdateCosmosDb != null) __params = __params.set('alwaysUpdateCosmosDb', params.alwaysUpdateCosmosDb.toString());
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/calendar`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * The update calendar settings async.
     * @param params The `TenantsService.UpdateCalendarSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `useCosmosDb`: If true, get appointments for the calendar from Cosmos Db and update it.
     *
     * - `alwaysUpdateCosmosDb`: If true, update appointments in Cosmos Db even if useCosmosDb flag is disabled.
     *
     * @return Success
     */
    UpdateCalendarSettings(params: TenantsService.UpdateCalendarSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateCalendarSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Updates the tenant's security configuration.
     * @param params The `TenantsService.UpdateSecurityConfigurationParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `updateRequest`: The update request.
     *
     * @return Success
     */
    UpdateSecurityConfigurationResponse(
        params: TenantsService.UpdateSecurityConfigurationParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.updateRequest;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/securityConfiguration`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Updates the tenant's security configuration.
     * @param params The `TenantsService.UpdateSecurityConfigurationParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `updateRequest`: The update request.
     *
     * @return Success
     */
    UpdateSecurityConfiguration(
        params: TenantsService.UpdateSecurityConfigurationParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateSecurityConfigurationResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Sets a value for a tenant generic setting.
     * @param params The `TenantsService.SetGenericSettingValueParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `updateRequest`: The update request.
     *
     * @return Success
     */
    SetGenericSettingValueResponse(
        params: TenantsService.SetGenericSettingValueParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.updateRequest;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/privateSettings/generic`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Sets a value for a tenant generic setting.
     * @param params The `TenantsService.SetGenericSettingValueParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `updateRequest`: The update request.
     *
     * @return Success
     */
    SetGenericSettingValue(params: TenantsService.SetGenericSettingValueParams): __Observable<ApiResponseOkResponse> {
        return this.SetGenericSettingValueResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Removes a tenant generic setting.
     * @param params The `TenantsService.ClearGenericSettingValueParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `settingKey`: The key of the setting to remove.
     *
     * @return Success
     */
    ClearGenericSettingValueResponse(
        params: TenantsService.ClearGenericSettingValueParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl +
                `/tenants/${encodeURIComponent(String(params.tenantId))}/privateSettings/generic/${encodeURIComponent(
                    String(params.settingKey),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Removes a tenant generic setting.
     * @param params The `TenantsService.ClearGenericSettingValueParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `settingKey`: The key of the setting to remove.
     *
     * @return Success
     */
    ClearGenericSettingValue(params: TenantsService.ClearGenericSettingValueParams): __Observable<ApiResponseOkResponse> {
        return this.ClearGenericSettingValueResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Updates the tenant intakes settings.
     * @param params The `TenantsService.UpdateIntakesSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `intakesSettingsUpdateRequest`: The intakes settings update request.
     *
     * @return Success
     */
    UpdateIntakesSettingsResponse(
        params: TenantsService.UpdateIntakesSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.intakesSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/privateSettings/intakes`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Updates the tenant intakes settings.
     * @param params The `TenantsService.UpdateIntakesSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `intakesSettingsUpdateRequest`: The intakes settings update request.
     *
     * @return Success
     */
    UpdateIntakesSettings(params: TenantsService.UpdateIntakesSettingsParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateIntakesSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Modifies tasks settings of the tenant.
     * @param params The `TenantsService.UpdateTasksSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `tasksSettingsUpdateRequest`: The tasks settings update request.
     *
     * @return Success
     */
    UpdateTasksSettingsResponse(
        params: TenantsService.UpdateTasksSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.tasksSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/tasksSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies tasks settings of the tenant.
     * @param params The `TenantsService.UpdateTasksSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `tasksSettingsUpdateRequest`: The tasks settings update request.
     *
     * @return Success
     */
    UpdateTasksSettings(params: TenantsService.UpdateTasksSettingsParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateTasksSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies calendar blocks settings of the tenant.
     * @param params The `TenantsService.UpdateCalendarBlocksSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `calendarBlocksSettingsUpdateRequest`: The calendar blocks settings update request.
     *
     * @return Success
     */
    UpdateCalendarBlocksSettingsResponse(
        params: TenantsService.UpdateCalendarBlocksSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.calendarBlocksSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/calendarBlocksSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies calendar blocks settings of the tenant.
     * @param params The `TenantsService.UpdateCalendarBlocksSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `calendarBlocksSettingsUpdateRequest`: The calendar blocks settings update request.
     *
     * @return Success
     */
    UpdateCalendarBlocksSettings(
        params: TenantsService.UpdateCalendarBlocksSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateCalendarBlocksSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Modifies tasks settings of the tenant.
     * @param params The `TenantsService.UpdateTasksInfrastructureSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `tasksSettingsUpdateRequest`: The tasks settings update request.
     *
     * @return Success
     */
    UpdateTasksInfrastructureSettingsResponse(
        params: TenantsService.UpdateTasksInfrastructureSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.tasksSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/tasks`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Modifies tasks settings of the tenant.
     * @param params The `TenantsService.UpdateTasksInfrastructureSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The identifier.
     *
     * - `tasksSettingsUpdateRequest`: The tasks settings update request.
     *
     * @return Success
     */
    UpdateTasksInfrastructureSettings(
        params: TenantsService.UpdateTasksInfrastructureSettingsParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateTasksInfrastructureSettingsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseInfrastructureTenantResponse),
        );
    }

    /**
     * Drops the cache for a single tenant.
     * @param tenantId The tenant id.
     * @return Success
     */
    DropCacheResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/tenants/${encodeURIComponent(String(tenantId))}/cache`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Drops the cache for a single tenant.
     * @param tenantId The tenant id.
     * @return Success
     */
    DropCache(tenantId: number): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.DropCacheResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Drops the cache for multiple tenants.
     * @param params The `TenantsService.DropCacheForMultipleTenantsAsyncParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    DropCacheForMultipleTenantsAsyncResponse(
        params: TenantsService.DropCacheForMultipleTenantsAsyncParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/tenants/cache`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Drops the cache for multiple tenants.
     * @param params The `TenantsService.DropCacheForMultipleTenantsAsyncParams` containing the following parameters:
     *
     * - `tenantType`: Type of the tenant.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    DropCacheForMultipleTenantsAsync(
        params: TenantsService.DropCacheForMultipleTenantsAsyncParams,
    ): __Observable<ApiResponseIEnumerableInfrastructureTenantResponse> {
        return this.DropCacheForMultipleTenantsAsyncResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerableInfrastructureTenantResponse),
        );
    }

    /**
     * Patches the AWS keys for multiple tenants.
     * @param params The `TenantsService.PatchAwsKeysParams` containing the following parameters:
     *
     * - `tenantType`: The tenant type.
     *
     * - `request`: The request.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    PatchAwsKeysResponse(
        params: TenantsService.PatchAwsKeysParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantType != null) __params = __params.set('tenantType', params.tenantType.toString());
        __body = params.request;
        if (params.nameFilter != null) __params = __params.set('nameFilter', params.nameFilter.toString());
        let req = new HttpRequest<any>('PATCH', this.rootUrl + `/tenants/AwsKeys`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Patches the AWS keys for multiple tenants.
     * @param params The `TenantsService.PatchAwsKeysParams` containing the following parameters:
     *
     * - `tenantType`: The tenant type.
     *
     * - `request`: The request.
     *
     * - `nameFilter`: The name filter.
     *
     * @return Success
     */
    PatchAwsKeys(params: TenantsService.PatchAwsKeysParams): __Observable<ApiResponseIEnumerableInfrastructureTenantResponse> {
        return this.PatchAwsKeysResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableInfrastructureTenantResponse));
    }

    /**
     * Updates the insurance contract status.
     * @param params The `TenantsService.UpdateInsuranceContractStatusParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateInsuranceContractStatusResponse(
        params: TenantsService.UpdateInsuranceContractStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/insuranceContractStatus`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Updates the insurance contract status.
     * @param params The `TenantsService.UpdateInsuranceContractStatusParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateInsuranceContractStatus(
        params: TenantsService.UpdateInsuranceContractStatusParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateInsuranceContractStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Updates the kiosk feature status.
     * @param params The `TenantsService.UpdateKioskFeatureStatusParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateKioskFeatureStatusResponse(
        params: TenantsService.UpdateKioskFeatureStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/kioskFeatureStatus`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Updates the kiosk feature status.
     * @param params The `TenantsService.UpdateKioskFeatureStatusParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateKioskFeatureStatus(params: TenantsService.UpdateKioskFeatureStatusParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateKioskFeatureStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Updates the weight program loss settings.
     * @param params The `TenantsService.UpdateWeightLossManagementParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateWeightLossManagementResponse(
        params: TenantsService.UpdateWeightLossManagementParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/weightLossManagement`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Updates the weight program loss settings.
     * @param params The `TenantsService.UpdateWeightLossManagementParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateWeightLossManagement(
        params: TenantsService.UpdateWeightLossManagementParams,
    ): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateWeightLossManagementResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }

    /**
     * Updates the dosespot version.
     * @param params The `TenantsService.UpdateDosespotVersionParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateDosespotVersionResponse(
        params: TenantsService.UpdateDosespotVersionParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInfrastructureTenantResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/tenants/${encodeURIComponent(String(params.tenantId))}/infrastructureSettings/dosespot`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInfrastructureTenantResponse>;
            }),
        );
    }
    /**
     * Updates the dosespot version.
     * @param params The `TenantsService.UpdateDosespotVersionParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateDosespotVersion(params: TenantsService.UpdateDosespotVersionParams): __Observable<ApiResponseInfrastructureTenantResponse> {
        return this.UpdateDosespotVersionResponse(params).pipe(__map((_r) => _r.body as ApiResponseInfrastructureTenantResponse));
    }
}

module TenantsService {
    /**
     * Parameters for GetAdmin
     */
    export interface GetAdminParams {
        /**
         * Type of the tenant.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for GetInfrastructure
     */
    export interface GetInfrastructureParams {
        /**
         * Type of the tenant.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for GetPrivate
     */
    export interface GetPrivateParams {
        /**
         * Type of the tenant.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for SetInsurancePrivateSettings
     */
    export interface SetInsurancePrivateSettingsParams {
        /**
         * The tenant Id.
         */
        tenantId: number;

        /**
         * The insurance Private Settings Request.
         */
        insurancePrivateSettingsRequest?: InsurancePrivateSettingsRequest;
    }

    /**
     * Parameters for GetPublic
     */
    export interface GetPublicParams {
        /**
         * Type of the tenant.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for PatchApps
     */
    export interface PatchAppsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The update request.
         */
        request?: AppsPatchRequest;
    }

    /**
     * Parameters for PatchMultipleApps
     */
    export interface PatchMultipleAppsParams {
        /**
         * Type of the tenant.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The update request.
         */
        request?: AppsPatchRequest;

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for UpdateCompanySettings
     */
    export interface UpdateCompanySettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The company settings update request.
         */
        companyUpdateRequest?: CompanyUpdateRequest;
    }

    /**
     * Parameters for UpdateCommonSettings
     */
    export interface UpdateCommonSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The common settings update request.
         */
        commonSettingsUpdateRequest?: CommonSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateInsuranceSettings
     */
    export interface UpdateInsuranceSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The insurance settings update request.
         */
        insuranceSettingsUpdateRequest?: InsuranceSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateActivitiesSettings
     */
    export interface UpdateActivitiesSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The activities settings update request.
         */
        activitiesSettingsUpdateRequest?: ActivitiesSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdatePracticeConversationSettings
     */
    export interface UpdatePracticeConversationSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: PracticeConversationSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdatePracticeSchedulingSettings
     */
    export interface UpdatePracticeSchedulingSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: PracticeSchedulingSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateOnlineAppointmentRequestsSettings
     */
    export interface UpdateOnlineAppointmentRequestsSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: OnlineAppointmentRequestsSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdatePatientsLegalForms
     */
    export interface UpdatePatientsLegalFormsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: PatientsLegalFormsSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdatePatientsLegalFormsForMultipleTenants
     */
    export interface UpdatePatientsLegalFormsForMultipleTenantsParams {
        /**
         * The tenant type filter.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The request.
         */
        request?: PatientsLegalFormsSettingsUpdateRequest;

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for UpdatePracticeSystemSettings
     */
    export interface UpdatePracticeSystemSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: PracticeSystemSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdatePracticePaymentsSettings
     */
    export interface UpdatePracticePaymentsSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: PracticePaymentsSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateReminderSettings
     */
    export interface UpdateReminderSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: PracticeReminderSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdatePracticeScheduleTemplatesSettings
     */
    export interface UpdatePracticeScheduleTemplatesSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: PracticeScheduleTemplatesSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateSurchargeSettings
     */
    export interface UpdateSurchargeSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: SurchargesSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateRecurringPaymentsSettings
     */
    export interface UpdateRecurringPaymentsSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: RecurringPaymentsSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateFeatureState
     */
    export interface UpdateFeatureStateParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The tenant feature.
         */
        tenantFeature: 'ReferralsModule';

        /**
         * if set to `true` [is enabled].
         */
        isEnabled?: boolean;
    }

    /**
     * Parameters for UpdateFinancialSettings
     */
    export interface UpdateFinancialSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: FinancialSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateVirtualConsultationSettings
     */
    export interface UpdateVirtualConsultationSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The virtual consultation settings update request.
         */
        virtualConsultationSettingsUpdateRequest?: VirtualConsultationSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateTouchMdSettings
     */
    export interface UpdateTouchMdSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The TouchMD settings update request.
         */
        touchMdSettingsUpdateRequest?: TouchMdSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateTrizettoSettings
     */
    export interface UpdateTrizettoSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The Trizetto settings update request.
         */
        trizettoSettingsUpdateRequest?: TrizettoSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateCanfieldSettings
     */
    export interface UpdateCanfieldSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The Canfield settings update request.
         */
        canfieldSettingsUpdateRequest?: CanfieldSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateEmailSettings
     */
    export interface UpdateEmailSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The SendGrid settings update request.
         */
        emailSettingsUpdateRequest?: EmailSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateSqlBrokerSettings
     */
    export interface UpdateSqlBrokerSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * Whether the SQL broker should be enabled or not.
         */
        isEnabled?: boolean;
    }

    /**
     * Parameters for UpdateCalendarSettings
     */
    export interface UpdateCalendarSettingsParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * If true, get appointments for the calendar from Cosmos Db and update it.
         */
        useCosmosDb?: boolean;

        /**
         * If true, update appointments in Cosmos Db even if useCosmosDb flag is disabled.
         */
        alwaysUpdateCosmosDb?: boolean;
    }

    /**
     * Parameters for UpdateSecurityConfiguration
     */
    export interface UpdateSecurityConfigurationParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The update request.
         */
        updateRequest?: SecurityConfigurationUpdateRequest;
    }

    /**
     * Parameters for SetGenericSettingValue
     */
    export interface SetGenericSettingValueParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The update request.
         */
        updateRequest?: GenericSettingUpdateRequest;
    }

    /**
     * Parameters for ClearGenericSettingValue
     */
    export interface ClearGenericSettingValueParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The key of the setting to remove.
         */
        settingKey: string;
    }

    /**
     * Parameters for UpdateIntakesSettings
     */
    export interface UpdateIntakesSettingsParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The intakes settings update request.
         */
        intakesSettingsUpdateRequest?: IntakesSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateTasksSettings
     */
    export interface UpdateTasksSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The tasks settings update request.
         */
        tasksSettingsUpdateRequest?: TasksSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateCalendarBlocksSettings
     */
    export interface UpdateCalendarBlocksSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The calendar blocks settings update request.
         */
        calendarBlocksSettingsUpdateRequest?: CalendarBlocksSettingsUpdateRequest;
    }

    /**
     * Parameters for UpdateTasksInfrastructureSettings
     */
    export interface UpdateTasksInfrastructureSettingsParams {
        /**
         * The identifier.
         */
        tenantId: number;

        /**
         * The tasks settings update request.
         */
        tasksSettingsUpdateRequest?: TasksInfrastructureSettingsUpdateRequest;
    }

    /**
     * Parameters for DropCacheForMultipleTenantsAsync
     */
    export interface DropCacheForMultipleTenantsAsyncParams {
        /**
         * Type of the tenant.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for PatchAwsKeys
     */
    export interface PatchAwsKeysParams {
        /**
         * The tenant type.
         */
        tenantType?:
            | 'Development'
            | 'QA'
            | 'UAT'
            | 'Beta'
            | 'Production'
            | 'DevelopmentOrQA'
            | 'BetaOrProduction'
            | 'AllNonProduction'
            | 'UatOrBeta'
            | 'AllProduction'
            | 'DevelopmentCH'
            | 'DevelopmentDT'
            | 'DevelopmentTG'
            | 'DevelopmentOMG';

        /**
         * The request.
         */
        request?: AwsKeysPatchRequest;

        /**
         * The name filter.
         */
        nameFilter?: string;
    }

    /**
     * Parameters for UpdateInsuranceContractStatus
     */
    export interface UpdateInsuranceContractStatusParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: InsuranceContractStatusUpdateRequest;
    }

    /**
     * Parameters for UpdateKioskFeatureStatus
     */
    export interface UpdateKioskFeatureStatusParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: KioskFeatureStatusUpdateRequest;
    }

    /**
     * Parameters for UpdateWeightLossManagement
     */
    export interface UpdateWeightLossManagementParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: WeightLossManagementSettingsRequest;
    }

    /**
     * Parameters for UpdateDosespotVersion
     */
    export interface UpdateDosespotVersionParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: DosespotVersionUpdateRequest;
    }
}

export { TenantsService };
