import { AppointmentPurposeSelection } from './appointment-purpose-selection';
import { AppointmentTypePurposeSelection } from './appointment-type-purpose-selection';
import { AppointmentTypeSelection } from './appointment-type-selection';
import {
    AppointmentPurposeInType,
    AppointmentTypePurposeResponse,
    AppointmentTypeResponse,
    PracticeServiceId,
} from '@symplast/generated-clients/web-portal';

export class AppointmentTypePurposeSelections {
    public appointmentTypes: AppointmentTypeSelection[];
    public appointmentPurposes: AppointmentPurposeSelection[];
    public appointmentTypesPurposes: AppointmentTypePurposeSelection[];

    get visibleTypes(): (AppointmentTypeSelection | AppointmentTypePurposeSelection)[] {
        return this.appointmentTypes.filter((type) => type.isVisible).flatMap((type) => [type, ...type.visiblePurposes]);
    }

    get visiblePurposes(): (AppointmentPurposeSelection | AppointmentTypePurposeSelection)[] {
        return this.appointmentPurposes.filter((type) => type.isVisible).flatMap((purpose) => [purpose, ...purpose.visibleTypes]);
    }

    get visibleTypesPurposes(): AppointmentTypePurposeSelection[] {
        return this.appointmentTypesPurposes.filter((typePurpose) => typePurpose.isVisible);
    }

    constructor(
        appointmentTypesPurposesResponse: AppointmentTypePurposeResponse[],
        selectedTypesPurposes: PracticeServiceId[] | null = null,
    ) {
        this.appointmentTypes = appointmentTypesPurposesResponse
            .flatMap((typePurpose) => typePurpose.appointmentType as AppointmentTypeResponse)
            .filter((type, i, self) => type !== undefined && self.findIndex((v) => v?.appointmentTypeId === type.appointmentTypeId) === i)
            .map((type) => new AppointmentTypeSelection(type.appointmentTypeId, type.description as string, type.colorCode as string))
            .sort((type1, type2) => type1.description.localeCompare(type2.description));

        this.appointmentPurposes = appointmentTypesPurposesResponse
            .flatMap((typePurpose) => typePurpose.appointmentPurpose as AppointmentPurposeInType)
            .filter(
                (purpose, i, self) =>
                    purpose !== undefined && self.findIndex((v) => v.appointmentPurposeId === purpose.appointmentPurposeId) === i,
            )
            .map((purpose) => new AppointmentPurposeSelection(purpose.appointmentPurposeId, purpose.description as string))
            .sort((purpose1, purpose2) => purpose1.description.localeCompare(purpose2.description));

        this.appointmentTypesPurposes = appointmentTypesPurposesResponse
            .map(
                (appointmentTypePurpose) =>
                    new AppointmentTypePurposeSelection(
                        appointmentTypePurpose.appointmentTypePurposeId,
                        appointmentTypePurpose.overrideName as string,
                        appointmentTypePurpose.fullServiceName as string,
                        this.appointmentTypes.find(
                            (type) => type.id === appointmentTypePurpose.appointmentType?.appointmentTypeId,
                        ) as AppointmentTypeSelection,
                        this.appointmentPurposes.find(
                            (purpose) => purpose.id === appointmentTypePurpose.appointmentPurpose?.appointmentPurposeId,
                        ) as AppointmentPurposeSelection,
                        selectedTypesPurposes == null
                            ? false
                            : selectedTypesPurposes.some((service) =>
                                  service.appointmentTypeId === appointmentTypePurpose.appointmentType?.appointmentTypeId &&
                                  appointmentTypePurpose.appointmentPurpose
                                      ? service.appointmentPurposeIds?.includes(
                                            appointmentTypePurpose.appointmentPurpose.appointmentPurposeId,
                                        )
                                      : false,
                              ),
                    ),
            )
            .sort((service1, service2) => service1.description.localeCompare(service2.description));

        if (selectedTypesPurposes) {
            this.appointmentTypes.forEach((type) => {
                type.setSelectedStatus();
            });

            this.appointmentPurposes.forEach((purpose) => {
                purpose.setSelectedStatus();
            });
        }
    }

    public toPracticeServiceIds(): PracticeServiceId[] {
        return this.appointmentTypes
            .filter((type) => type.isSelected)
            .map((type) => {
                return {
                    appointmentTypeId: type.id,
                    appointmentPurposeIds: type.appointmentTypesPurposes
                        .filter((purpose) => purpose.isSelected)
                        .map((purpose) => purpose.parentAppointmentPurpose.id),
                };
            });
    }
}
