import { NumberFormatOptions } from '@syncfusion/ej2-base';

export const DEFAULT_MONEY_FORMAT = {
    useGrouping: true,
    format: 'C2',
    currency: '$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
} as NumberFormatOptions;

export const DEFAULT_QUANTITY_FORMAT = {
    useGrouping: true,
    format: 'N0',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
} as NumberFormatOptions;

export const DEFAULT_BRACKETED_QUANTITY_FORMAT = {
    useGrouping: true,
    format: 'N0',
    currency: '',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
} as NumberFormatOptions;
