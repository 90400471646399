import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { InsuranceAccessService } from '../services/insurance-access/insurance-access.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApplicationConstants } from '@symplast/app-constants';
import { InsuranceAccessConfiguration } from '../services/insurance-access';

@Injectable({ providedIn: 'root' })
export class InsuranceAccessGuard implements CanActivate, CanActivateChild {
    constructor(private insuranceAccessService: InsuranceAccessService, private router: Router, private snackbar: MatSnackBar) {}

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const urlPaths = this.retrievePreviousUrlPaths(route);
        const insuranceAccessConfiguration = route.data.insuranceAccessConfiguration as
            | InsuranceAccessConfiguration
            | InsuranceAccessConfiguration[];
        const hasAccess = this.hasInsuranceAccess(insuranceAccessConfiguration);

        if (!hasAccess) {
            this.handleNoAccess(urlPaths);
        }

        return hasAccess;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
        const urlPaths = this.retrievePreviousUrlPaths(childRoute);
        const insuranceAccessConfiguration = childRoute.data.insuranceAccessConfiguration as
            | InsuranceAccessConfiguration
            | InsuranceAccessConfiguration[];
        const hasAccess = this.hasInsuranceAccess(insuranceAccessConfiguration);

        if (!hasAccess) {
            this.handleNoAccess(urlPaths);
        }

        return hasAccess;
    }

    private hasInsuranceAccess(configuration?: InsuranceAccessConfiguration | InsuranceAccessConfiguration[]): boolean {
        if (!configuration) {
            throw new Error('InsuranceAccessGuard: configuration is required');
        }

        return this.insuranceAccessService.checkAccess(configuration);
    }

    private handleNoAccess(urlPaths: string[]): void {
        this.snackbar.open('You do not have access to this section', null, { duration: ApplicationConstants.SnackBarDuration });
        this.router.navigate(['/', ...urlPaths]);
    }

    private retrievePreviousUrlPaths(route: ActivatedRouteSnapshot): string[] {
        const urlPaths = [];
        let _route = route;

        while (_route) {
            urlPaths.unshift(..._route.url.map((url) => url.path));

            _route = _route.parent;
        }

        urlPaths.pop();

        return urlPaths;
    }
}
