import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';

export class WysiwygNoFormattingAddon implements WysiwygAddon {
    public name = 'no-formatting';

    public init(config: WysiwygEditorConfiguration): void {
        config.options = {
            ...config.options,
            paste_data_images: false,
            paste_as_text: true,
            forced_root_block: '', // Prevent TinyMCE from adding p tags
            paste_remove_styles: true, // Remove styles on paste
            paste_remove_styles_if_webkit: true, // Remove styles for webkit browsers
            paste_strip_class_attributes: true, // Strip class attributes on paste
            paste_retain_style_properties: '', // Retain only inline styles
            valid_elements: 'p,div,span,span[class|symplast-tinymce-noneditable|contenteditable|data-mce-content]', // Forbid entering other tags
            valid_children: 'body[p|div|span],div[p|div|span]', // Forbid using shortcuts to change existing tags
            formats: {
                ...config.options?.formats,
                // Forbid changing styles and tags when using shortcuts
                bold: { inline: 'p', classes: '' },
                italic: { inline: 'p', classes: '' },
                underline: { inline: 'p', classes: '' },
                strikethrough: { inline: 'p', classes: '' },
                blockquote: { inline: 'p', classes: '' },
            },
        };
    }
}
