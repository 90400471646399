import { Injectable, inject } from '@angular/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { FilterSet } from '@symplast/generated-clients/web-portal';
import { Observable, filter, map } from 'rxjs';
import { FilterSetsSelectors } from './filter-sets.selectors';
import {
    CreateFilterSet,
    CreateFilterSetFailure,
    CreateFilterSetSuccess,
    DeleteFilterSet,
    DeleteFilterSetSuccess,
    LoadFilterSets,
    LoadFilterSetsFailure,
    UpdateFilterSet,
    UpdateFilterSetFailure,
    UpdateFilterSetSuccess,
} from './filter-sets.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FilterSetsFacade {
    public filterSetsLoadFailed$: Observable<HttpErrorResponse>;
    public filterSetCreationSuccess$: Observable<FilterSet>;
    public filterSetCreationFailed$: Observable<HttpErrorResponse>;
    public filterSetUpdateSuccess$: Observable<FilterSet>;
    public filterSetUpdateFailed$: Observable<HttpErrorResponse>;
    public filterSetDeletionSuccess$: Observable<string>;
    public filterSetDeletionFailed$: Observable<HttpErrorResponse>;

    private store = inject(Store);
    private actions$ = inject(Actions);

    constructor() {
        this.filterSetsLoadFailed$ = this.actions$.pipe(
            ofActionDispatched(LoadFilterSetsFailure),
            map((action) => action.payload.error),
        );

        this.filterSetCreationSuccess$ = this.actions$.pipe(
            ofActionDispatched(CreateFilterSetSuccess),
            map((action) => action.payload.filterSet),
        );
        this.filterSetCreationFailed$ = this.actions$.pipe(
            ofActionDispatched(CreateFilterSetFailure),
            map((action) => action.payload.error),
        );

        this.filterSetUpdateSuccess$ = this.actions$.pipe(
            ofActionDispatched(UpdateFilterSetSuccess),
            map((action) => action.payload.filterSet),
        );
        this.filterSetUpdateFailed$ = this.actions$.pipe(
            ofActionDispatched(UpdateFilterSetFailure),
            map((action) => action.payload.error),
        );

        this.filterSetDeletionSuccess$ = this.actions$.pipe(
            ofActionDispatched(DeleteFilterSetSuccess),
            map((action) => action.payload.id),
        );
        this.filterSetDeletionFailed$ = this.actions$.pipe(
            ofActionDispatched(UpdateFilterSetFailure),
            map((action) => action.payload.error),
        );
    }

    public getFilterSets$(section: string): Observable<FilterSet[]> {
        return this.store.select(FilterSetsSelectors.filterSets).pipe(
            map((filterFn) => filterFn(section)),
            filter((filterSets): filterSets is FilterSet[] => !!filterSets),
        );
    }

    public getFilterSets(section: string): FilterSet[] | undefined {
        return this.store.selectSnapshot(FilterSetsSelectors.filterSets)(section);
    }

    public loadFilterSets(section: string): void {
        this.store.dispatch(new LoadFilterSets({ section }));
    }

    public createFilterSet(filterSet: FilterSet): void {
        this.store.dispatch(new CreateFilterSet({ filterSet }));
    }

    public updateFilterSet(filterSet: FilterSet): void {
        this.store.dispatch(new UpdateFilterSet({ id: filterSet.id, filterSet }));
    }

    public deleteFilterSet(filterSet: FilterSet): void {
        this.store.dispatch(new DeleteFilterSet({ id: filterSet.id }));
    }
}
