/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponsePatientCredit } from '../models/api-response-patient-credit';
import { PatientCreditUpdateRequest } from '../models/patient-credit-update-request';
import { ApiResponseOutstandingPatientCredit } from '../models/api-response-outstanding-patient-credit';
import { ApiResponseIEnumerablePatientCredit } from '../models/api-response-ienumerable-patient-credit';
@Injectable({
    providedIn: 'root',
})
class PatientCreditsService extends __BaseService {
    static readonly DeletePatientCreditPath = '/PatientCredits/{patientCreditId}';
    static readonly GetPatientCreditByIdPath = '/PatientCredits/{patientCreditId}';
    static readonly UpdatePatientCreditPath = '/PatientCredits/{patientCreditId}';
    static readonly GetOutstandingPatientCreditsByPatientIdPath = '/OutstandingPatientCredits/{patientId}';
    static readonly GetOutstandingPatientCreditsPath = '/OutstandingPatientCredits';
    static readonly GetPatientCreditsPath = '/PatientCredits';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Deletes a patient credit.
     * @param patientCreditId The patient credit id delete.
     * @return Success
     */
    DeletePatientCreditResponse(patientCreditId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/PatientCredits/${encodeURIComponent(String(patientCreditId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes a patient credit.
     * @param patientCreditId The patient credit id delete.
     * @return Success
     */
    DeletePatientCredit(patientCreditId: number): __Observable<ApiResponseOkResponse> {
        return this.DeletePatientCreditResponse(patientCreditId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets a patient credit.
     * @param patientCreditId the patient credit id to modify.
     * @return Success
     */
    GetPatientCreditByIdResponse(patientCreditId: number): __Observable<__StrictHttpResponse<ApiResponsePatientCredit>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/PatientCredits/${encodeURIComponent(String(patientCreditId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientCredit>;
            }),
        );
    }
    /**
     * Gets a patient credit.
     * @param patientCreditId the patient credit id to modify.
     * @return Success
     */
    GetPatientCreditById(patientCreditId: number): __Observable<ApiResponsePatientCredit> {
        return this.GetPatientCreditByIdResponse(patientCreditId).pipe(__map((_r) => _r.body as ApiResponsePatientCredit));
    }

    /**
     * Modify a patient credit.
     * @param params The `PatientCreditsService.UpdatePatientCreditParams` containing the following parameters:
     *
     * - `patientCreditId`: the patient credit id to modify.
     *
     * - `patientCreditUpdateRequest`: the object container to update.
     *
     * @return Success
     */
    UpdatePatientCreditResponse(
        params: PatientCreditsService.UpdatePatientCreditParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.patientCreditUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/PatientCredits/${encodeURIComponent(String(params.patientCreditId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modify a patient credit.
     * @param params The `PatientCreditsService.UpdatePatientCreditParams` containing the following parameters:
     *
     * - `patientCreditId`: the patient credit id to modify.
     *
     * - `patientCreditUpdateRequest`: the object container to update.
     *
     * @return Success
     */
    UpdatePatientCredit(params: PatientCreditsService.UpdatePatientCreditParams): __Observable<ApiResponseOkResponse> {
        return this.UpdatePatientCreditResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Get the total amount of outstanding patient credits by patient Id.
     * @param params The `PatientCreditsService.GetOutstandingPatientCreditsByPatientIdParams` containing the following parameters:
     *
     * - `patientId`: The patient id.
     *
     * - `estimateId`: The estimate Id.
     *
     * @return Success
     */
    GetOutstandingPatientCreditsByPatientIdResponse(
        params: PatientCreditsService.GetOutstandingPatientCreditsByPatientIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOutstandingPatientCredit>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.estimateId != null) __params = __params.set('estimateId', params.estimateId.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/OutstandingPatientCredits/${encodeURIComponent(String(params.patientId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOutstandingPatientCredit>;
            }),
        );
    }
    /**
     * Get the total amount of outstanding patient credits by patient Id.
     * @param params The `PatientCreditsService.GetOutstandingPatientCreditsByPatientIdParams` containing the following parameters:
     *
     * - `patientId`: The patient id.
     *
     * - `estimateId`: The estimate Id.
     *
     * @return Success
     */
    GetOutstandingPatientCreditsByPatientId(
        params: PatientCreditsService.GetOutstandingPatientCreditsByPatientIdParams,
    ): __Observable<ApiResponseOutstandingPatientCredit> {
        return this.GetOutstandingPatientCreditsByPatientIdResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseOutstandingPatientCredit),
        );
    }

    /**
     * Get the total amount of outstanding patient credits globally.
     * @return Success
     */
    GetOutstandingPatientCreditsResponse(): __Observable<__StrictHttpResponse<ApiResponseOutstandingPatientCredit>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/OutstandingPatientCredits`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOutstandingPatientCredit>;
            }),
        );
    }
    /**
     * Get the total amount of outstanding patient credits globally.
     * @return Success
     */
    GetOutstandingPatientCredits(): __Observable<ApiResponseOutstandingPatientCredit> {
        return this.GetOutstandingPatientCreditsResponse().pipe(__map((_r) => _r.body as ApiResponseOutstandingPatientCredit));
    }

    /**
     * Gets all patient credits.
     * @param params The `PatientCreditsService.GetPatientCreditsParams` containing the following parameters:
     *
     * - `showUnusedOnly`: It's a flag to show only available credit.
     *
     * - `showUnlinkedOnly`: if set to `true` [exclude credits linked to an estimate] (not compatible with [estimateId]).
     *
     * - `patientId`: the patient identifier to filter.
     *
     * - `patientCreditType`: the credit type to filter.
     *
     * - `includeDeleted`: if set to `true` [include deleted].
     *
     * - `estimateId`: Get all the patient credits for a particular estimate.
     *
     * @return Success
     */
    GetPatientCreditsResponse(
        params: PatientCreditsService.GetPatientCreditsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientCredit>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.showUnusedOnly != null) __params = __params.set('showUnusedOnly', params.showUnusedOnly.toString());
        if (params.showUnlinkedOnly != null) __params = __params.set('showUnlinkedOnly', params.showUnlinkedOnly.toString());
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.patientCreditType != null) __params = __params.set('patientCreditType', params.patientCreditType.toString());
        if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
        if (params.estimateId != null) __params = __params.set('estimateId', params.estimateId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PatientCredits`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientCredit>;
            }),
        );
    }
    /**
     * Gets all patient credits.
     * @param params The `PatientCreditsService.GetPatientCreditsParams` containing the following parameters:
     *
     * - `showUnusedOnly`: It's a flag to show only available credit.
     *
     * - `showUnlinkedOnly`: if set to `true` [exclude credits linked to an estimate] (not compatible with [estimateId]).
     *
     * - `patientId`: the patient identifier to filter.
     *
     * - `patientCreditType`: the credit type to filter.
     *
     * - `includeDeleted`: if set to `true` [include deleted].
     *
     * - `estimateId`: Get all the patient credits for a particular estimate.
     *
     * @return Success
     */
    GetPatientCredits(params: PatientCreditsService.GetPatientCreditsParams): __Observable<ApiResponseIEnumerablePatientCredit> {
        return this.GetPatientCreditsResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerablePatientCredit));
    }
}

module PatientCreditsService {
    /**
     * Parameters for UpdatePatientCredit
     */
    export interface UpdatePatientCreditParams {
        /**
         * the patient credit id to modify.
         */
        patientCreditId: number;

        /**
         * the object container to update.
         */
        patientCreditUpdateRequest?: PatientCreditUpdateRequest;
    }

    /**
     * Parameters for GetOutstandingPatientCreditsByPatientId
     */
    export interface GetOutstandingPatientCreditsByPatientIdParams {
        /**
         * The patient id.
         */
        patientId: number;

        /**
         * The estimate Id.
         */
        estimateId?: number;
    }

    /**
     * Parameters for GetPatientCredits
     */
    export interface GetPatientCreditsParams {
        /**
         * It's a flag to show only available credit.
         */
        showUnusedOnly?: boolean;

        /**
         * if set to `true` [exclude credits linked to an estimate] (not compatible with [estimateId]).
         */
        showUnlinkedOnly?: boolean;

        /**
         * the patient identifier to filter.
         */
        patientId?: number;

        /**
         * the credit type to filter.
         */
        patientCreditType?: 'PatientDeposit' | 'CreditNote' | 'ExcessPayment';

        /**
         * if set to `true` [include deleted].
         */
        includeDeleted?: boolean;

        /**
         * Get all the patient credits for a particular estimate.
         */
        estimateId?: number;
    }
}

export { PatientCreditsService };
