import { Injectable } from '@angular/core';
import { OccasionsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { IOccasionsStateModel } from './occasions.model';
import {
    ChangeStateOccasion,
    CreateOccasion,
    SaveOccasion,
    LoadOccasions,
    ReloadOccasions,
    StartEditOccasion,
    CancelEditOccasion,
    DeleteOccasion,
} from './occasions.actions';
import { IOccasionEdit } from '@symplast/models/shared';

@State<IOccasionsStateModel>({
    name: 'Occasions',
    defaults: {
        occasions: [],
        loading: false,
        backgroundLoading: false,
        message: null,
    },
})
@Injectable()
export class OccasionsState {
    private newId = -1;

    constructor(private occassionsService: OccasionsService) {}

    @Action(LoadOccasions)
    public load(context: StateContext<IOccasionsStateModel>, { refresh }: LoadOccasions) {
        if (!context.getState().occasions.length || refresh) {
            context.patchState({ loading: true });
            this.occassionsService
                .GetOccasions({ includeNotActive: true })
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    const sortedResult = response.result ?? [];

                    context.patchState({
                        occasions: [...this.sortOccasions(sortedResult.map((occasion) => ({ ...occasion, isEdit: false })))],
                        message: null,
                        loading: false,
                    });
                });
        } else {
            context.patchState({
                message: null,
                occasions: context.getState().occasions.map((d) => ({ ...d, isEdit: false })),
            });
        }
    }

    @Action(ReloadOccasions)
    public reload(context: StateContext<IOccasionsStateModel>) {
        context.dispatch(new LoadOccasions(true));
    }

    @Action(CreateOccasion)
    public create(context: StateContext<IOccasionsStateModel>) {
        const state = context.getState();

        if (!state.occasions.some((d) => d.id === this.newId)) {
            context.patchState({
                occasions: [{ id: this.newId, isActive: true, isSystem: false, isEdit: true, name: '' }, ...context.getState().occasions],
            });
        }
    }

    @Action(SaveOccasion)
    public update(context: StateContext<IOccasionsStateModel>, { id, name }: SaveOccasion) {
        const state = context.getState();

        const existOccasions = state.occasions.find((d) => d.id === id);

        if (existOccasions) {
            if (id === this.newId) {
                this.saveNewOccasion(name, context);
            } else {
                this.updateOccasion(id, name, existOccasions.isActive, context);
            }
        }
    }

    @Action(ChangeStateOccasion)
    public changeState(context: StateContext<IOccasionsStateModel>, { id, isActive }: ChangeStateOccasion) {
        const state = context.getState();

        const existOccasions = state.occasions.find((d) => d.id === id);

        if (existOccasions) {
            this.updateOccasion(id, existOccasions.name ?? '', isActive, context);
        }
    }

    @Action(StartEditOccasion)
    public startEdit(context: StateContext<IOccasionsStateModel>, { id }: StartEditOccasion) {
        const state = context.getState();
        const occasions = [...state.occasions];
        const existedIndex = occasions.findIndex((occasion) => occasion.id === id);

        // eslint-disable-next-line no-bitwise
        if (~existedIndex) {
            const existed = occasions[existedIndex];

            occasions[existedIndex] = { ...existed, isEdit: true };
            context.patchState({ occasions });
        }
    }

    @Action(CancelEditOccasion)
    public cancelEdit(context: StateContext<IOccasionsStateModel>, { id }: CancelEditOccasion) {
        const state = context.getState();
        const occasions = [...state.occasions];
        const existedIndex = occasions.findIndex((occasion) => occasion.id === id);

        // eslint-disable-next-line no-bitwise
        if (~existedIndex) {
            const existed = occasions[existedIndex];

            occasions[existedIndex] = { ...existed, isEdit: false };
            context.patchState({ occasions: occasions.filter((d) => d.id !== this.newId) });
        }
    }

    @Action(DeleteOccasion)
    public delete(context: StateContext<IOccasionsStateModel>, { id }: DeleteOccasion) {
        context.patchState({ backgroundLoading: true });
        const state = context.getState();

        this.occassionsService.DeleteOccasion(id).subscribe({
            next: (response) => {
                if (response.statusCode === 200) {
                    const oldOccasion = state.occasions.find((d) => d.id === id);

                    context.patchState({
                        occasions: [...state.occasions.filter((d) => d.id !== id)],
                        message: `The occasion "${oldOccasion?.name}" was deleted.`,
                        backgroundLoading: false,
                    });
                }
            },
            error: () => {
                context.patchState({
                    backgroundLoading: false,
                });
            },
        });
    }

    private sortOccasions(occasions: IOccasionEdit[]): IOccasionEdit[] {
        return occasions.sort((a, b) => {
            switch (+b.isSystem - +a.isSystem) {
                case -1:
                    return -1;
                case 1:
                    return 1;
                default:
                    return a.name?.localeCompare(b.name ?? '') ?? 0;
            }
        });
    }

    private saveNewOccasion(name: string, context: StateContext<IOccasionsStateModel>) {
        context.patchState({ backgroundLoading: true });

        this.occassionsService.CreateOccasion({ name }).subscribe({
            next: (d) => {
                if ((d.statusCode === 200 || d.statusCode === 201) && d.result) {
                    const state = context.getState();
                    const occassions = [...state.occasions.filter((occasion) => occasion.id !== this.newId)];

                    context.patchState({
                        occasions: this.sortOccasions([...occassions, { ...d.result, isEdit: false }]),
                        backgroundLoading: false,
                        message: `The occasion "${name}" was added.`,
                    });
                }
            },
            error: () => {
                context.patchState({
                    backgroundLoading: false,
                });
            },
        });
    }

    private updateOccasion(id: number, name: string, isActive: boolean, context: StateContext<IOccasionsStateModel>) {
        const state = context.getState();

        context.patchState({ backgroundLoading: true });
        this.occassionsService.UpdateOccasion({ occasionId: id, updateRequest: { name, isActive: isActive } }).subscribe({
            next: (response) => {
                if (response.statusCode === 200 || response.statusCode === 201) {
                    const occasions = [...state.occasions];
                    const currentOccasion = occasions.find((occasion) => occasion.id === id);

                    if (currentOccasion) {
                        context.patchState({
                            occasions: this.sortOccasions([
                                ...occasions.filter((d) => d.id !== id),
                                { ...currentOccasion, name: name, isActive: isActive, isEdit: false },
                            ]),
                            backgroundLoading: false,
                            message: `The occasion "${name}" was updated.`,
                        });
                    }
                }
            },
            error: () => {
                context.patchState({
                    backgroundLoading: false,
                });
            },
        });
    }
}
