import { Injectable } from '@angular/core';
import { AddressService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadCountries, ReloadCountries } from './countries.actions';
import { ICountriesStateModel } from './countries.model';

@State<ICountriesStateModel>({
    name: 'Countries',
    defaults: {
        countries: [],
        loading: false,
    },
})
@Injectable()
export class CountriesState {
    constructor(private addressService: AddressService) {}

    @Action(LoadCountries)
    public load(context: StateContext<ICountriesStateModel>, { refresh }: LoadCountries) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.countries.length) || refresh) {
            context.patchState({ loading: true });
            this.addressService
                .GetCountriesList()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result }) => {
                    const usa = result.find((country) => country.name === 'USA');
                    const restOfCountries = result
                        .filter((country) => country.id !== usa.id)
                        .sort((country1, country2) => (country1.name.toLocaleLowerCase() > country2.name.toLocaleLowerCase() ? 1 : -1));

                    context.patchState({
                        countries: [usa, ...restOfCountries],
                    });
                });
        }
    }

    @Action(ReloadCountries)
    public reload(context: StateContext<ICountriesStateModel>) {
        context.dispatch(new LoadCountries(true));
    }
}
