import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadInsuranceSettings } from './insurance-settings.actions';
import { InsuranceSettingsSelectors } from './insurance-settings.selectors';

@Injectable({ providedIn: 'root' })
export class InsuranceSettingsFacade {
    @Select(InsuranceSettingsSelectors.enabledReceiving835File)
    public enabledTrizetto$!: Observable<boolean>;

    @Select(InsuranceSettingsSelectors.defaultBillingLocationId)
    public defaultBillingLocationId$!: Observable<number>;

    @Select(InsuranceSettingsSelectors.acceptAssignmentsByDefault)
    public acceptAssignmentsByDefault$!: Observable<boolean>;

    constructor(private store: Store) {}

    public get defaultBillingLocationId(): number | undefined {
        return this.store.selectSnapshot(InsuranceSettingsSelectors.defaultBillingLocationId);
    }

    public get acceptAssignmentsByDefault(): boolean {
        return this.store.selectSnapshot(InsuranceSettingsSelectors.acceptAssignmentsByDefault);
    }

    public loadInsuranceSettings(): void {
        this.store.dispatch(new LoadInsuranceSettings());
    }
}
