import { Injectable } from '@angular/core';
import { AuthenticationDataStorage } from './authentication/authentication-data.storage';

export interface TenantsEssentials {
    tenantId: number;
    tenantApiUrl: string;
    tenantSettingsApi: string;
    environment: string;
}

/**
 * Service that only provides essential data about tenant stored in cookie
 */
@Injectable({
    providedIn: 'root',
})
export class TenantsEssentialsService {
    constructor(private authenticationDataStorage: AuthenticationDataStorage) {}

    public get tenant(): TenantsEssentials | null {
        if (!this.authenticationDataStorage.data) {
            return null;
        }

        const { TenantId, TenantApiUrl, TenantSettingsApi, Environment } = this.authenticationDataStorage.data;

        return { tenantId: +TenantId, tenantApiUrl: TenantApiUrl, tenantSettingsApi: TenantSettingsApi, environment: Environment };
    }
}
