/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableAppointmentRequest } from '../models/api-response-ienumerable-appointment-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { CreateAppointmentRequest } from '../models/create-appointment-request';
import { ApiResponseGetAppointmentRequestsCountResponse } from '../models/api-response-get-appointment-requests-count-response';
import { ApiResponseAppointmentRequest } from '../models/api-response-appointment-request';
import { UpdateAppointmentRequestStatusRequest } from '../models/update-appointment-request-status-request';
import { ApiResponseICollectionAppointmentRequestReportRow } from '../models/api-response-icollection-appointment-request-report-row';
@Injectable({
    providedIn: 'root',
})
class AppointmentRequestsService extends __BaseService {
    static readonly GetAppointmentRequestsPath = '/AppointmentRequests';
    static readonly CreateAppointmentRequestPath = '/AppointmentRequests';
    static readonly GetAppointmentRequestsCountPath = '/AppointmentRequests/Count';
    static readonly UpdateAppointmentRequestStatusPath = '/AppointmentRequests/{id}';
    static readonly GetAppointmentRequestReportPath = '/AppointmentRequestReport';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @return Success
     */
    GetAppointmentRequestsResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAppointmentRequest>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/AppointmentRequests`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAppointmentRequest>;
            }),
        );
    }
    /**
     * @return Success
     */
    GetAppointmentRequests(): __Observable<ApiResponseIEnumerableAppointmentRequest> {
        return this.GetAppointmentRequestsResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableAppointmentRequest));
    }

    /**
     * @param createAppointmentRequest The create appointment request object.
     * @return Success
     */
    CreateAppointmentRequestResponse(
        createAppointmentRequest?: CreateAppointmentRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = createAppointmentRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/AppointmentRequests`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param createAppointmentRequest The create appointment request object.
     * @return Success
     */
    CreateAppointmentRequest(createAppointmentRequest?: CreateAppointmentRequest): __Observable<ApiResponseOkResponse> {
        return this.CreateAppointmentRequestResponse(createAppointmentRequest).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @return Success
     */
    GetAppointmentRequestsCountResponse(): __Observable<__StrictHttpResponse<ApiResponseGetAppointmentRequestsCountResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/AppointmentRequests/Count`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseGetAppointmentRequestsCountResponse>;
            }),
        );
    }
    /**
     * @return Success
     */
    GetAppointmentRequestsCount(): __Observable<ApiResponseGetAppointmentRequestsCountResponse> {
        return this.GetAppointmentRequestsCountResponse().pipe(__map((_r) => _r.body as ApiResponseGetAppointmentRequestsCountResponse));
    }

    /**
     * @param params The `AppointmentRequestsService.UpdateAppointmentRequestStatusParams` containing the following parameters:
     *
     * - `id`: The appointment request identifier
     *
     * - `updateAppointmentRequestStatusRequest`: The update appointment request status request
     *
     * @return Success
     */
    UpdateAppointmentRequestStatusResponse(
        params: AppointmentRequestsService.UpdateAppointmentRequestStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseAppointmentRequest>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.updateAppointmentRequestStatusRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/AppointmentRequests/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAppointmentRequest>;
            }),
        );
    }
    /**
     * @param params The `AppointmentRequestsService.UpdateAppointmentRequestStatusParams` containing the following parameters:
     *
     * - `id`: The appointment request identifier
     *
     * - `updateAppointmentRequestStatusRequest`: The update appointment request status request
     *
     * @return Success
     */
    UpdateAppointmentRequestStatus(
        params: AppointmentRequestsService.UpdateAppointmentRequestStatusParams,
    ): __Observable<ApiResponseAppointmentRequest> {
        return this.UpdateAppointmentRequestStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseAppointmentRequest));
    }

    /**
     * @param params The `AppointmentRequestsService.GetAppointmentRequestReportParams` containing the following parameters:
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetAppointmentRequestReportResponse(
        params: AppointmentRequestsService.GetAppointmentRequestReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionAppointmentRequestReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/AppointmentRequestReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionAppointmentRequestReportRow>;
            }),
        );
    }
    /**
     * @param params The `AppointmentRequestsService.GetAppointmentRequestReportParams` containing the following parameters:
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetAppointmentRequestReport(
        params: AppointmentRequestsService.GetAppointmentRequestReportParams,
    ): __Observable<ApiResponseICollectionAppointmentRequestReportRow> {
        return this.GetAppointmentRequestReportResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseICollectionAppointmentRequestReportRow),
        );
    }
}

module AppointmentRequestsService {
    /**
     * Parameters for UpdateAppointmentRequestStatus
     */
    export interface UpdateAppointmentRequestStatusParams {
        /**
         * The appointment request identifier
         */
        id: string;

        /**
         * The update appointment request status request
         */
        updateAppointmentRequestStatusRequest?: UpdateAppointmentRequestStatusRequest;
    }

    /**
     * Parameters for GetAppointmentRequestReport
     */
    export interface GetAppointmentRequestReportParams {
        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;
    }
}

export { AppointmentRequestsService };
