import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'caseIdDisplay', standalone: true })
export class CaseIdPipe implements PipeTransform {
    transform(value: string): string {
        const len = value.toString().length;
        const prefix = 'IC-';
        const totalLen = 7;

        return prefix + '0'.repeat(totalLen - len) + value;
    }
}
