/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiPagedResponseICollectionUser } from '../models/api-paged-response-icollection-user';
import { ApiResponseUserExtendedInfo } from '../models/api-response-user-extended-info';
import { CreateUserRequest } from '../models/create-user-request';
import { UpdateUserRequest } from '../models/update-user-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { UpdateTipRecipientRequest } from '../models/update-tip-recipient-request';
import { ChangePasswordRequest } from '../models/change-password-request';
import { ApiResponseICollectionUserBasicDetails } from '../models/api-response-icollection-user-basic-details';
import { ChangeMobilePhoneRequest } from '../models/change-mobile-phone-request';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly GetUsersPath = '/Users';
  static readonly CreateUserPath = '/Users';
  static readonly GetUserByIdPath = '/Users/{userId}';
  static readonly UpdateUserPath = '/Users/{userId}';
  static readonly DeleteUserPath = '/Users/{userId}';
  static readonly UpdateTipRecipientsPath = '/Users/UpdateTipRecipients';
  static readonly UpdateStatusUserPath = '/Users/{userId}/Status';
  static readonly UpdatePasswordUserPath = '/Users/{userId}/Password';
  static readonly ResetPasswordUserPath = '/Users/{userId}/Password';
  static readonly CreatedLinkPasswordUserPath = '/Users/{userId}/Password/New';
  static readonly GetExternalPhysiciansPath = '/ExternalPhysicians';
  static readonly GetProvidersPath = '/Providers';
  static readonly GetCosmeticCoordinatorsPath = '/CosmeticCoordinators';
  static readonly GetAllPracticeUsersPath = '/AllPracticeUsers';
  static readonly UpdateImageUserPath = '/Users/{userId}/Image';
  static readonly SendMobilePhoneConfirmationPath = '/Users/{userId}/MobilePhone/Confirm';
  static readonly SetMobilePhoneUserPath = '/Users/{userId}/MobilePhone';
  static readonly SetMobilePhoneConfirmationDelayedForUserPath = '/Users/Current/MobilePhoneConfirmationDelayed';
  static readonly UnlockUserPath = '/Users/{userId}/Unlock';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get users information.
   * @param params The `UsersService.GetUsersParams` containing the following parameters:
   *
   * - `userType`: The user Type.
   *
   * - `status`: The status.
   *
   * - `query`: The query.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `includeOnlyWithLicenses`: Include only users with licenses
   *
   * - `hasFlags`: the parameter to get internal users only or exclude them
   *
   * @return Success
   */
  GetUsersResponse(params: UsersService.GetUsersParams): __Observable<__StrictHttpResponse<ApiPagedResponseICollectionUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userType != null) __params = __params.set('userType', params.userType.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.includeOnlyWithLicenses != null) __params = __params.set('includeOnlyWithLicenses', params.includeOnlyWithLicenses.toString());
    if (params.hasFlags != null) __params = __params.set('hasFlags', params.hasFlags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseICollectionUser>;
      })
    );
  }
  /**
   * Get users information.
   * @param params The `UsersService.GetUsersParams` containing the following parameters:
   *
   * - `userType`: The user Type.
   *
   * - `status`: The status.
   *
   * - `query`: The query.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `includeOnlyWithLicenses`: Include only users with licenses
   *
   * - `hasFlags`: the parameter to get internal users only or exclude them
   *
   * @return Success
   */
  GetUsers(params: UsersService.GetUsersParams): __Observable<ApiPagedResponseICollectionUser> {
    return this.GetUsersResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseICollectionUser)
    );
  }

  /**
   * The method for creation of the user.
   * @param createRequest The create request.
   * @return Success
   */
  CreateUserResponse(createRequest?: CreateUserRequest): __Observable<__StrictHttpResponse<ApiResponseUserExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseUserExtendedInfo>;
      })
    );
  }
  /**
   * The method for creation of the user.
   * @param createRequest The create request.
   * @return Success
   */
  CreateUser(createRequest?: CreateUserRequest): __Observable<ApiResponseUserExtendedInfo> {
    return this.CreateUserResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseUserExtendedInfo)
    );
  }

  /**
   * The get user by id async.
   * @param params The `UsersService.GetUserByIdParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `includeCustomSettings`: if set to `true` [include custom settings].
   *
   * @return Success
   */
  GetUserByIdResponse(params: UsersService.GetUserByIdParams): __Observable<__StrictHttpResponse<ApiResponseUserExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeCustomSettings != null) __params = __params.set('includeCustomSettings', params.includeCustomSettings.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Users/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseUserExtendedInfo>;
      })
    );
  }
  /**
   * The get user by id async.
   * @param params The `UsersService.GetUserByIdParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `includeCustomSettings`: if set to `true` [include custom settings].
   *
   * @return Success
   */
  GetUserById(params: UsersService.GetUserByIdParams): __Observable<ApiResponseUserExtendedInfo> {
    return this.GetUserByIdResponse(params).pipe(
      __map(_r => _r.body as ApiResponseUserExtendedInfo)
    );
  }

  /**
   * The update user.
   * @param params The `UsersService.UpdateUserParams` containing the following parameters:
   *
   * - `userId`: The user ID.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateUserResponse(params: UsersService.UpdateUserParams): __Observable<__StrictHttpResponse<ApiResponseUserExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseUserExtendedInfo>;
      })
    );
  }
  /**
   * The update user.
   * @param params The `UsersService.UpdateUserParams` containing the following parameters:
   *
   * - `userId`: The user ID.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateUser(params: UsersService.UpdateUserParams): __Observable<ApiResponseUserExtendedInfo> {
    return this.UpdateUserResponse(params).pipe(
      __map(_r => _r.body as ApiResponseUserExtendedInfo)
    );
  }

  /**
   * The delete user.
   * @param userId The user ID.
   * @return Success
   */
  DeleteUserResponse(userId: number): __Observable<__StrictHttpResponse<ApiResponseUserExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Users/${encodeURIComponent(String(userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseUserExtendedInfo>;
      })
    );
  }
  /**
   * The delete user.
   * @param userId The user ID.
   * @return Success
   */
  DeleteUser(userId: number): __Observable<ApiResponseUserExtendedInfo> {
    return this.DeleteUserResponse(userId).pipe(
      __map(_r => _r.body as ApiResponseUserExtendedInfo)
    );
  }

  /**
   * Updates the specified users as tip recipients.
   * @param updateTipRecipientsRequest The update tip recipients request.
   * @return Success
   */
  UpdateTipRecipientsResponse(updateTipRecipientsRequest?: Array<UpdateTipRecipientRequest>): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = updateTipRecipientsRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/UpdateTipRecipients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates the specified users as tip recipients.
   * @param updateTipRecipientsRequest The update tip recipients request.
   * @return Success
   */
  UpdateTipRecipients(updateTipRecipientsRequest?: Array<UpdateTipRecipientRequest>): __Observable<ApiResponseOkResponse> {
    return this.UpdateTipRecipientsResponse(updateTipRecipientsRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update status for user.
   * @param params The `UsersService.UpdateStatusUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `status`: The status
   *
   * @return Success
   */
  UpdateStatusUserResponse(params: UsersService.UpdateStatusUserParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.status != null) __params = __params.set('status', params.status.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/${encodeURIComponent(String(params.userId))}/Status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update status for user.
   * @param params The `UsersService.UpdateStatusUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `status`: The status
   *
   * @return Success
   */
  UpdateStatusUser(params: UsersService.UpdateStatusUserParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateStatusUserResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update password for user.
   * @param params The `UsersService.UpdatePasswordUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  UpdatePasswordUserResponse(params: UsersService.UpdatePasswordUserParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/${encodeURIComponent(String(params.userId))}/Password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update password for user.
   * @param params The `UsersService.UpdatePasswordUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  UpdatePasswordUser(params: UsersService.UpdatePasswordUserParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePasswordUserResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Reset password for user.
   * @param userId The user id.
   * @return Success
   */
  ResetPasswordUserResponse(userId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Users/${encodeURIComponent(String(userId))}/Password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Reset password for user.
   * @param userId The user id.
   * @return Success
   */
  ResetPasswordUser(userId: number): __Observable<ApiResponseOkResponse> {
    return this.ResetPasswordUserResponse(userId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Send password link for new user.
   * @param userId The user id.
   * @return Success
   */
  CreatedLinkPasswordUserResponse(userId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Users/${encodeURIComponent(String(userId))}/Password/New`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Send password link for new user.
   * @param userId The user id.
   * @return Success
   */
  CreatedLinkPasswordUser(userId: number): __Observable<ApiResponseOkResponse> {
    return this.CreatedLinkPasswordUserResponse(userId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets external physician information.
   * @return Success
   */
  GetExternalPhysiciansResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionUserBasicDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ExternalPhysicians`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionUserBasicDetails>;
      })
    );
  }
  /**
   * Gets external physician information.
   * @return Success
   */
  GetExternalPhysicians(): __Observable<ApiResponseICollectionUserBasicDetails> {
    return this.GetExternalPhysiciansResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionUserBasicDetails)
    );
  }

  /**
   * Gets providers information.
   * @return Success
   */
  GetProvidersResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionUserBasicDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionUserBasicDetails>;
      })
    );
  }
  /**
   * Gets providers information.
   * @return Success
   */
  GetProviders(): __Observable<ApiResponseICollectionUserBasicDetails> {
    return this.GetProvidersResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionUserBasicDetails)
    );
  }

  /**
   * Gets cosmetic coordinators information.
   * @return Success
   */
  GetCosmeticCoordinatorsResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionUserBasicDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/CosmeticCoordinators`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionUserBasicDetails>;
      })
    );
  }
  /**
   * Gets cosmetic coordinators information.
   * @return Success
   */
  GetCosmeticCoordinators(): __Observable<ApiResponseICollectionUserBasicDetails> {
    return this.GetCosmeticCoordinatorsResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionUserBasicDetails)
    );
  }

  /**
   * Gets all practice users.
   * @return Success
   */
  GetAllPracticeUsersResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionUserBasicDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AllPracticeUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionUserBasicDetails>;
      })
    );
  }
  /**
   * Gets all practice users.
   * @return Success
   */
  GetAllPracticeUsers(): __Observable<ApiResponseICollectionUserBasicDetails> {
    return this.GetAllPracticeUsersResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionUserBasicDetails)
    );
  }

  /**
   * Update user image.
   * @param params The `UsersService.UpdateImageUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `file`:
   *
   * @return Success
   */
  UpdateImageUserResponse(params: UsersService.UpdateImageUserParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/${encodeURIComponent(String(params.userId))}/Image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update user image.
   * @param params The `UsersService.UpdateImageUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `file`:
   *
   * @return Success
   */
  UpdateImageUser(params: UsersService.UpdateImageUserParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateImageUserResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The send mobile phone confirmation async.
   * @param userId The user id.
   * @return Success
   */
  SendMobilePhoneConfirmationResponse(userId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Users/${encodeURIComponent(String(userId))}/MobilePhone/Confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The send mobile phone confirmation async.
   * @param userId The user id.
   * @return Success
   */
  SendMobilePhoneConfirmation(userId: number): __Observable<ApiResponseOkResponse> {
    return this.SendMobilePhoneConfirmationResponse(userId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The set mobile phone async.
   * @param params The `UsersService.SetMobilePhoneUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  SetMobilePhoneUserResponse(params: UsersService.SetMobilePhoneUserParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/${encodeURIComponent(String(params.userId))}/MobilePhone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The set mobile phone async.
   * @param params The `UsersService.SetMobilePhoneUserParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  SetMobilePhoneUser(params: UsersService.SetMobilePhoneUserParams): __Observable<ApiResponseOkResponse> {
    return this.SetMobilePhoneUserResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The set mobile confirmation delayed until async.
   * @param delayedUntilUtc The confirmation delayed until UTC.
   * @return Success
   */
  SetMobilePhoneConfirmationDelayedForUserResponse(delayedUntilUtc?: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (delayedUntilUtc != null) __params = __params.set('delayedUntilUtc', delayedUntilUtc.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/Current/MobilePhoneConfirmationDelayed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The set mobile confirmation delayed until async.
   * @param delayedUntilUtc The confirmation delayed until UTC.
   * @return Success
   */
  SetMobilePhoneConfirmationDelayedForUser(delayedUntilUtc?: string): __Observable<ApiResponseOkResponse> {
    return this.SetMobilePhoneConfirmationDelayedForUserResponse(delayedUntilUtc).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The set mobile phone async.
   * @param userId The user ID.
   * @return Success
   */
  UnlockUserResponse(userId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Users/${encodeURIComponent(String(userId))}/Unlock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The set mobile phone async.
   * @param userId The user ID.
   * @return Success
   */
  UnlockUser(userId: number): __Observable<ApiResponseOkResponse> {
    return this.UnlockUserResponse(userId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module UsersService {

  /**
   * Parameters for GetUsers
   */
  export interface GetUsersParams {

    /**
     * The user Type.
     */
    userType?: 'All' | 'Physician' | 'Nurse' | 'Admin' | 'Staff' | 'Patient' | 'RefByPhysician' | 'CosmeticCoordinator' | 'NursePractitioner' | 'AllPractice' | 'AllExceptPatient' | 'Unknown';

    /**
     * The status.
     */
    status?: boolean;

    /**
     * The query.
     */
    query?: string;

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * Include only users with licenses
     */
    includeOnlyWithLicenses?: boolean;

    /**
     * the parameter to get internal users only or exclude them
     */
    hasFlags?: 'None' | 'IsSymplastUser';
  }

  /**
   * Parameters for GetUserById
   */
  export interface GetUserByIdParams {

    /**
     * The user id.
     */
    userId: number;

    /**
     * if set to `true` [include custom settings].
     */
    includeCustomSettings?: boolean;
  }

  /**
   * Parameters for UpdateUser
   */
  export interface UpdateUserParams {

    /**
     * The user ID.
     */
    userId: number;

    /**
     * The update request.
     */
    updateRequest?: UpdateUserRequest;
  }

  /**
   * Parameters for UpdateStatusUser
   */
  export interface UpdateStatusUserParams {

    /**
     * The user id.
     */
    userId: number;

    /**
     * The status
     */
    status: boolean;
  }

  /**
   * Parameters for UpdatePasswordUser
   */
  export interface UpdatePasswordUserParams {

    /**
     * The user id.
     */
    userId: number;

    /**
     * The request.
     */
    request: ChangePasswordRequest;
  }

  /**
   * Parameters for UpdateImageUser
   */
  export interface UpdateImageUserParams {

    /**
     * The user id.
     */
    userId: number;
    file?: Blob;
  }

  /**
   * Parameters for SetMobilePhoneUser
   */
  export interface SetMobilePhoneUserParams {

    /**
     * The user id.
     */
    userId: number;

    /**
     * The request.
     */
    request?: ChangeMobilePhoneRequest;
  }
}

export { UsersService }
