/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseNotificationsSettings } from '../models/api-response-notifications-settings';
import { ApiResponseUpdateBirthdayNotificationSettings } from '../models/api-response-update-birthday-notification-settings';
import { UpdateBirthdayNotificationSettings } from '../models/update-birthday-notification-settings';
@Injectable({
  providedIn: 'root',
})
class NotificationsService extends __BaseService {
  static readonly GetNotificationsSettingsPath = '/Settings';
  static readonly UpdateBirthdayNotificationSettingsPath = '/Notifications/BirthdaySettings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets settings information.
   * @param includeTemplates Include template to response.
   * @return Success
   */
  GetNotificationsSettingsResponse(includeTemplates?: boolean): __Observable<__StrictHttpResponse<ApiResponseNotificationsSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeTemplates != null) __params = __params.set('includeTemplates', includeTemplates.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseNotificationsSettings>;
      })
    );
  }
  /**
   * Gets settings information.
   * @param includeTemplates Include template to response.
   * @return Success
   */
  GetNotificationsSettings(includeTemplates?: boolean): __Observable<ApiResponseNotificationsSettings> {
    return this.GetNotificationsSettingsResponse(includeTemplates).pipe(
      __map(_r => _r.body as ApiResponseNotificationsSettings)
    );
  }

  /**
   * Updates birthday notification settings information.
   * @param birthdayNotificationSettingsRequest The user Push Notification Settings Request.
   * @return Success
   */
  UpdateBirthdayNotificationSettingsResponse(birthdayNotificationSettingsRequest: UpdateBirthdayNotificationSettings): __Observable<__StrictHttpResponse<ApiResponseUpdateBirthdayNotificationSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = birthdayNotificationSettingsRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Notifications/BirthdaySettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseUpdateBirthdayNotificationSettings>;
      })
    );
  }
  /**
   * Updates birthday notification settings information.
   * @param birthdayNotificationSettingsRequest The user Push Notification Settings Request.
   * @return Success
   */
  UpdateBirthdayNotificationSettings(birthdayNotificationSettingsRequest: UpdateBirthdayNotificationSettings): __Observable<ApiResponseUpdateBirthdayNotificationSettings> {
    return this.UpdateBirthdayNotificationSettingsResponse(birthdayNotificationSettingsRequest).pipe(
      __map(_r => _r.body as ApiResponseUpdateBirthdayNotificationSettings)
    );
  }
}

module NotificationsService {
}

export { NotificationsService }
