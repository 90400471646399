import { EditorEvent } from 'tinymce';
import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygTemplateVariablesAddon } from './template-variables.addon';
import { countOccurrences } from '@symplast/utils';

export interface WysiwygRequiredTemplateVariablesAddonOptions {
    requriedVariables?: string[];
    removeAttemptCallback?: (variables: string[]) => void;
}

function findDeletedKeys(object: Record<string, number>): string[] {
    return Object.keys(object).filter((key) => object[key] === 0);
}

export class WysiwygRequiredTemplateVariablesAddon extends WysiwygTemplateVariablesAddon {
    public name = 'required-template-variables';
    private previousContent = '';
    private requriedVariables!: string[];
    private removeAttemptCallback?: (variables: string[]) => void;

    constructor(options?: WysiwygRequiredTemplateVariablesAddonOptions) {
        super();

        this.requriedVariables = options?.requriedVariables || [];
        this.removeAttemptCallback = options?.removeAttemptCallback;
    }

    public init(config: WysiwygEditorConfiguration): void {
        super.init(config);

        if (!this.requriedVariables.length) {
            return;
        }

        config.registerSetupCallback((editor) => {
            const beforeContentChanged = (event: Event | EditorEvent<unknown>): void => {
                const selection = editor.selection.getContent({ format: 'text' });
                const includedRequiredVariables = this.requriedVariables.filter((s) => selection.includes(s));

                // Check if the selected content contains the target string
                if (selection && includedRequiredVariables?.length) {
                    event.preventDefault();
                    this.removeAttemptCallback?.(includedRequiredVariables);
                }
            };

            editor.on('BeforeSetContent', (event) => beforeContentChanged(event));

            editor.on('beforeinput', (event) => {
                if (event.inputType.startsWith('delete')) {
                    // If the user is deleting content, we need to store the previous content so we can restore it if needed
                    this.previousContent = editor.getContent();
                }

                beforeContentChanged(event);
            });

            editor.on('input', (event) => {
                if (!event.inputType.startsWith('delete')) {
                    return;
                }

                const text = editor.getContent();
                const variablesOccurrences = countOccurrences(text, this.requriedVariables);
                const deletedKeys = findDeletedKeys(variablesOccurrences);

                if (deletedKeys.length) {
                    setTimeout(() => {
                        editor.undoManager.ignore(() => {
                            editor.setContent(this.previousContent);
                        });
                    });

                    this.removeAttemptCallback?.(deletedKeys);

                    // TODO: (a.vakhrushin) this is more performant, but has some issues
                    // editor.undoManager.undo();
                }
            });
        });
    }
}
