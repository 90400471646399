import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'dateUtc' })
export class DateUtcPipe implements PipeTransform {
    transform(dateString: string, displayInUtc = false): Date | string {
        const parts = dateString.match(/\d+/g);

        if (!parts || (parts && parts.length < 3)) {
            return dateString;
        }

        // parse ISO date for ios and other systems
        const date = new Date(+parts[0], +parts[1] - 1, +parts[2], -(new Date().getTimezoneOffset() / 60), 0, 0);

        if (!displayInUtc) {
            return date;
        } else {
            // Add timezone and DST for display in headers
            return new Date(date.setUTCHours(Math.abs(new Date().getTimezoneOffset() / 60 + +moment(new Date()).isDST())));
        }
    }
}
