import { AppointmentTypePurposeSelection } from './appointment-type-purpose-selection';

export class AppointmentPurposeSelection {
    public id: number;
    public description: string;
    public isSelected = false;
    public isPartiallySelected = false;
    public appointmentTypesPurposes: AppointmentTypePurposeSelection[] = [];

    get visibleTypes(): AppointmentTypePurposeSelection[] {
        return this.appointmentTypesPurposes.filter((type) => type.isVisible);
    }

    public get isVisible(): boolean {
        return this.appointmentTypesPurposes.some((typePurpose) => typePurpose.isVisible);
    }
    public selectAll() {
        const selected = !this.isSelected;

        this.appointmentTypesPurposes.forEach((purpose) => {
            purpose.isSelected = selected;
        });
    }

    public setSelectedStatus() {
        if (this.appointmentTypesPurposes.every((typePurpose) => typePurpose.isSelected)) {
            this.isSelected = true;
            this.isPartiallySelected = false;
        } else if (this.appointmentTypesPurposes.some((typePurpose) => typePurpose.isSelected)) {
            this.isSelected = true;
            this.isPartiallySelected = true;
        } else {
            this.isSelected = false;
            this.isPartiallySelected = false;
        }
    }

    constructor(id: number, description: string) {
        this.id = id;
        this.description = description;
    }
}
