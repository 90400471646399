import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum LayoutViewEnum {
    DEFAULT_VIEW = 'default',
    PRINT_VIEW = 'print',
}

@Injectable({ providedIn: 'root' })
export class LayoutService {
    public layoutView$: Observable<LayoutViewEnum>;
    private _layoutView$ = new BehaviorSubject<LayoutViewEnum>(LayoutViewEnum.DEFAULT_VIEW);

    constructor() {
        this.layoutView$ = this._layoutView$.asObservable();
    }

    public setView(view: LayoutViewEnum): void {
        this._layoutView$.next(view);
    }
}
