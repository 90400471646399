import { Injectable } from '@angular/core';
import { AddressService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadStates } from './states.actions';
import { IStatesStateModel } from './states.model';

@State<IStatesStateModel>({
    name: 'States',
    defaults: {
        states: {},
        loading: false,
    },
})
@Injectable()
export class StatesState {
    constructor(private addressService: AddressService) {}

    @Action(LoadStates)
    public load(context: StateContext<IStatesStateModel>, { countryId }: LoadStates) {
        const currentState = context.getState();

        if (!currentState.loading && !currentState.states[countryId]) {
            context.patchState({ loading: true });
            this.addressService
                .GetStatesList(countryId)
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result = [] }) => {
                    context.patchState({
                        states: { ...currentState.states, [countryId]: result },
                    });
                });
        }
    }
}
