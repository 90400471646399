/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseConfigurationSettings } from '../models/api-response-configuration-settings';
import { ApiResponseMailRemindersSettings } from '../models/api-response-mail-reminders-settings';
import { ApiResponseBusinessIntelligenceSettings } from '../models/api-response-business-intelligence-settings';
import { BusinessIntelligenceSettingsPatchRequest } from '../models/business-intelligence-settings-patch-request';
@Injectable({
    providedIn: 'root',
})
class ConfigurationsService extends __BaseService {
    static readonly ConfigurationsGetPath = '/api/Configurations/{groupName}';
    static readonly ConfigurationsGetMailSettingsPath = '/api/Configurations/MailSettings';
    static readonly ConfigurationsGetBusinessIntelligenceSettingsPath = '/api/Configurations/BusinessIntelligenceSettings';
    static readonly ConfigurationsUpdateBusinessIntelligenceSettingsPath = '/api/Configurations/BusinessIntelligenceSettings';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param groupName Name of the configuration group of settings
     * @return OK
     */
    ConfigurationsGetResponse(groupName: string): __Observable<__StrictHttpResponse<ApiResponseConfigurationSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Configurations/${groupName}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseConfigurationSettings>;
            }),
        );
    }
    /**
     * @param groupName Name of the configuration group of settings
     * @return OK
     */
    ConfigurationsGet(groupName: string): __Observable<ApiResponseConfigurationSettings> {
        return this.ConfigurationsGetResponse(groupName).pipe(__map((_r) => _r.body as ApiResponseConfigurationSettings));
    }

    /**
     * @return OK
     */
    ConfigurationsGetMailSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseMailRemindersSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Configurations/MailSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseMailRemindersSettings>;
            }),
        );
    }
    /**
     * @return OK
     */
    ConfigurationsGetMailSettings(): __Observable<ApiResponseMailRemindersSettings> {
        return this.ConfigurationsGetMailSettingsResponse().pipe(__map((_r) => _r.body as ApiResponseMailRemindersSettings));
    }

    /**
     * @return OK
     */
    ConfigurationsGetBusinessIntelligenceSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Configurations/BusinessIntelligenceSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>;
            }),
        );
    }
    /**
     * @return OK
     */
    ConfigurationsGetBusinessIntelligenceSettings(): __Observable<ApiResponseBusinessIntelligenceSettings> {
        return this.ConfigurationsGetBusinessIntelligenceSettingsResponse().pipe(
            __map((_r) => _r.body as ApiResponseBusinessIntelligenceSettings),
        );
    }

    /**
     * If you don't want to update one of the settings then leave it as null.
     * @param patchRequest The business intelligence settings patch request.
     * @return OK
     */
    ConfigurationsUpdateBusinessIntelligenceSettingsResponse(
        patchRequest: BusinessIntelligenceSettingsPatchRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = patchRequest;
        let req = new HttpRequest<any>('PATCH', this.rootUrl + `/api/Configurations/BusinessIntelligenceSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>;
            }),
        );
    }
    /**
     * If you don't want to update one of the settings then leave it as null.
     * @param patchRequest The business intelligence settings patch request.
     * @return OK
     */
    ConfigurationsUpdateBusinessIntelligenceSettings(
        patchRequest: BusinessIntelligenceSettingsPatchRequest,
    ): __Observable<ApiResponseBusinessIntelligenceSettings> {
        return this.ConfigurationsUpdateBusinessIntelligenceSettingsResponse(patchRequest).pipe(
            __map((_r) => _r.body as ApiResponseBusinessIntelligenceSettings),
        );
    }
}

module ConfigurationsService {}

export { ConfigurationsService };
