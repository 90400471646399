import { Injectable } from '@angular/core';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
// TODO: (a.vakhrushin) Circular file chain
import { environment } from 'apps/webportal/src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private appInsights!: ApplicationInsights;
    private tenantId?: number;
    private userId?: number;
    private destroyed = false;

    constructor(private router: Router) {
        if (environment.appInsights && environment.appInsights.instrumentationKey) {
            const angularPlugin = new AngularPlugin();

            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsights.instrumentationKey,
                    extensions: [angularPlugin],
                    disablePageUnloadEvents: ['beforeunload', 'unload'],
                    disableFlushOnBeforeUnload: true,
                    onunloadDisableFetch: true,
                    extensionConfig: {
                        [angularPlugin.identifier]: { router: this.router },
                    },
                },
            });
        }
    }

    public init(): void {
        if (!this.appInsights?.context) {
            this.destroyed = false;
            this.appInsights.loadAppInsights();
            this.appInsights.addTelemetryInitializer(this.telemetryInitializer);
        }
    }

    public destroy(): void {
        if (this.appInsights?.context) {
            this.appInsights.unload();
            this.destroyed = true;
        }
    }

    public updateUserData(tenantId: number, userId: number): void {
        this.tenantId = tenantId;
        this.userId = userId;
    }

    public logPageView(name: string, url?: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }): void {
        if (this.appInsights && !this.destroyed) {
            this.appInsights.trackPageView({
                name,
                uri: url,
                properties: this.addGlobalProperties(properties),
                measurements,
            });
        }
    }

    public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }): void {
        if (this.appInsights && !this.destroyed) {
            this.appInsights.trackEvent({
                name,
                properties: this.addGlobalProperties(properties),
                measurements,
            });
        }
    }

    public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }): void {
        if (this.appInsights && !this.destroyed) {
            this.appInsights.trackException({
                exception: error,
                properties: this.addGlobalProperties(properties),
                measurements,
            });
        }
    }

    private telemetryInitializer = (envelope: ITelemetryItem) => {
        if (envelope.baseData != null) {
            if (envelope.baseData.properties == null) {
                envelope.baseData.properties = {};
            }

            envelope.baseData.properties['version'] = environment.version;
            envelope.baseData.properties['environment'] = environment.tenantType;

            if (this.tenantId && this.userId) {
                envelope.baseData.properties['tenant'] = this.tenantId.toString();
                envelope.baseData.properties['userId'] = this.userId.toString();
            }
        }
    };

    private addGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }
        // space to add custom properties like app version, environment, etc
        properties['version'] = environment.version;
        properties['environment'] = environment.tenantType;

        if (this.tenantId && this.userId) {
            properties['tenant'] = this.tenantId.toString();
            properties['userId'] = this.userId.toString();
        }

        return properties;
    }
}
