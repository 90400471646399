import moment from 'moment-timezone';

export abstract class DateTimeHelper {
    public static TIMEZONE_ABBR = this.constructTimezoneAbbr();
    public static ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/;

    /**
     * Display utc date only without timezone shift (as it's presented in the date)
     * @param date - string or Date in UTC
     * @returns { string } Date string in a standart format
     */
    public static displayUtcDate(date: string | Date): string {
        const isoDateString = this.toIso8601(date);

        if (this.isUTC(isoDateString)) {
            const localDate = this.convertUtcToLocalWithoutTimezoneShift(isoDateString);

            return moment(localDate).tz(moment.tz.guess()).format('MMM DD, YYYY');
        }

        return moment(date).format('MMM DD, YYYY');
    }

    /**
     * Display utc date and time without timezone shift (as it's presented in the date)
     * @param date - string or Date in UTC
     * @returns { string } Date and Time string in a standart format
     */
    public static displayUtcDateTime(date: string | Date): string {
        const isoDateString = this.toIso8601(date);

        if (this.isUTC(isoDateString)) {
            const localDate = this.convertUtcToLocalWithoutTimezoneShift(isoDateString);

            return moment(localDate).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A');
        }

        return moment(date).format('MMM DD, YYYY hh:mm A');
    }

    /**
     * Display utc date and time WITH timezone shift (converts to a device timezone)
     * @param date - string or Date in UTC
     * @returns { string } Date and Time string in a standart format with a device timezone shift
     */
    public static displayLocalDate(date: string | Date): string {
        return moment.utc(date).tz(moment.tz.guess()).format('MMM DD, YYYY');
    }

    /**
     * Display utc date and time WITH timezone shift (converts to a device timezone)
     * @param date - string or Date in UTC
     * @returns { string } Date and Time string in a standart format with a device timezone shift
     */
    public static displayLocalDateTime(date: string | Date): string {
        // @see timezone abbr: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        return moment.utc(date).tz(moment.tz.guess()).format('MMM DD, YYYY hh:mm A') + ` ${this.TIMEZONE_ABBR}`;
    }

    /**
     * Converts Date and Time from UTC into local timezone without timezone shift (as it's presented in the date)
     * @param date - string or Date in UTC
     */
    public static convertUtcToLocalWithoutTimezoneShift(date: string | Date): string {
        const isoDate = this.toIso8601(date);

        return new Date(isoDate.replace('Z', '')).toISOString();
    }

    /**
     * Converts server format 'YYYY-MM-DD' into Date
     * @param date
     */
    public static convertServerFormatDateStringToDate(date: string): Date {
        const [year, month, day] = date.split('-').map((part) => +part);

        return new Date(year, month - 1, day);
    }

    /**
     * Format date in YYYY-MM-DD string
     * @param date - string or Date
     * @returns { string } Date formatted in YYYY-MM-DD
     */
    public static formatToServerDate(date: string | Date): string {
        return this.format(date, 'YYYY-MM-DD');
    }

    /**
     * Check if the date string is in a valid server format. Example: 'YYYY-MM-DD'
     * @param dateString - the date string to check
     * @returns { boolean } true if the date string is in a valid server format (YYYY-MM-DD)
     */
    public static isValidServerDate(dateString: string): boolean {
        const regex = /^\d{4}-\d{2}-\d{2}$/;

        try {
            const date = new Date(dateString);

            return regex.test(dateString) && date.toISOString().startsWith(dateString);
        } catch {
            return false;
        }
    }

    public static toIso8601(date: string | Date): string {
        if (typeof date === 'string' && this.isIso8601(date)) {
            return date;
        }

        return new Date(date).toISOString();
    }

    /**
     * Check if the Date is in UTC timezone
     * @param date - string or Date
     */
    public static isUTC(date: string): boolean {
        return this.isIso8601(date) && date.toLowerCase().endsWith('z');
    }

    /**
     * Check if the Date is in ISO 8601 format
     * @param date - string or Date
     */
    public static isIso8601(date: string): boolean {
        return this.ISO_8601_REGEX.test(date);
    }

    /**
     * Converts date to any format
     * @param date - string or Date
     */
    public static format(date: string | Date, format: string): string {
        return moment(date).tz(moment.tz.guess()).format(format);
    }

    private static constructTimezoneAbbr(): string {
        const momentTimezoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
        const timezoneAbbrDefined = isNaN(+momentTimezoneAbbr);

        if (timezoneAbbrDefined) {
            return momentTimezoneAbbr;
        }

        switch (momentTimezoneAbbr.length) {
            case 3:
                return `GMT${momentTimezoneAbbr}`;
            case 5:
                return `GMT${momentTimezoneAbbr.slice(0, 3)}:${momentTimezoneAbbr.slice(3, momentTimezoneAbbr.length)}`;
            default:
                return momentTimezoneAbbr;
        }
    }
}
