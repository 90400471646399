/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIReadOnlyCollectionAppointmentStatus } from '../models/api-response-iread-only-collection-appointment-status';
import { ApiResponseAppointmentStatus } from '../models/api-response-appointment-status';
import { AppointmentStatusCreateRequest } from '../models/appointment-status-create-request';
import { AppointmentStatusPatchRequest } from '../models/appointment-status-patch-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class AppointmentStatusesService extends __BaseService {
  static readonly GetAppointmentStatusesPath = '/AppointmentStatuses';
  static readonly CreateAppointmentStatusPath = '/AppointmentStatuses';
  static readonly PatchAppointmentStatusPath = '/AppointmentStatuses/{statusId}';
  static readonly DeleteAppointmentStatusPath = '/AppointmentStatuses/{statusId}';
  static readonly RestoreAppointmentStatusPath = '/AppointmentStatuses/{statusId}/Restore';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the collection of all Appointment Statuses
   * @param includeDeleted Whether to include deleted statuses in the response.
   * @return Success
   */
  GetAppointmentStatusesResponse(includeDeleted?: boolean): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeDeleted != null) __params = __params.set('includeDeleted', includeDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentStatus>;
      })
    );
  }
  /**
   * Gets the collection of all Appointment Statuses
   * @param includeDeleted Whether to include deleted statuses in the response.
   * @return Success
   */
  GetAppointmentStatuses(includeDeleted?: boolean): __Observable<ApiResponseIReadOnlyCollectionAppointmentStatus> {
    return this.GetAppointmentStatusesResponse(includeDeleted).pipe(
      __map(_r => _r.body as ApiResponseIReadOnlyCollectionAppointmentStatus)
    );
  }

  /**
   * Creates a new Appointment Status.
   * @param request The request.
   * @return Success
   */
  CreateAppointmentStatusResponse(request?: AppointmentStatusCreateRequest): __Observable<__StrictHttpResponse<ApiResponseAppointmentStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/AppointmentStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentStatus>;
      })
    );
  }
  /**
   * Creates a new Appointment Status.
   * @param request The request.
   * @return Success
   */
  CreateAppointmentStatus(request?: AppointmentStatusCreateRequest): __Observable<ApiResponseAppointmentStatus> {
    return this.CreateAppointmentStatusResponse(request).pipe(
      __map(_r => _r.body as ApiResponseAppointmentStatus)
    );
  }

  /**
   * Modifies an Appointment Status.
   * @param params The `AppointmentStatusesService.PatchAppointmentStatusParams` containing the following parameters:
   *
   * - `statusId`: The Appointment Status Id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  PatchAppointmentStatusResponse(params: AppointmentStatusesService.PatchAppointmentStatusParams): __Observable<__StrictHttpResponse<ApiResponseAppointmentStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/AppointmentStatuses/${encodeURIComponent(String(params.statusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentStatus>;
      })
    );
  }
  /**
   * Modifies an Appointment Status.
   * @param params The `AppointmentStatusesService.PatchAppointmentStatusParams` containing the following parameters:
   *
   * - `statusId`: The Appointment Status Id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  PatchAppointmentStatus(params: AppointmentStatusesService.PatchAppointmentStatusParams): __Observable<ApiResponseAppointmentStatus> {
    return this.PatchAppointmentStatusResponse(params).pipe(
      __map(_r => _r.body as ApiResponseAppointmentStatus)
    );
  }

  /**
   * Deletes an Appointment Status (soft-delete).
   * @param statusId The Appointment Status Id.
   * @return Success
   */
  DeleteAppointmentStatusResponse(statusId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/AppointmentStatuses/${encodeURIComponent(String(statusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes an Appointment Status (soft-delete).
   * @param statusId The Appointment Status Id.
   * @return Success
   */
  DeleteAppointmentStatus(statusId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteAppointmentStatusResponse(statusId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Restores a soft-deleted Appointment Status.
   * @param statusId The Appointment Status Id.
   * @return Success
   */
  RestoreAppointmentStatusResponse(statusId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/AppointmentStatuses/${encodeURIComponent(String(statusId))}/Restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Restores a soft-deleted Appointment Status.
   * @param statusId The Appointment Status Id.
   * @return Success
   */
  RestoreAppointmentStatus(statusId: number): __Observable<ApiResponseOkResponse> {
    return this.RestoreAppointmentStatusResponse(statusId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module AppointmentStatusesService {

  /**
   * Parameters for PatchAppointmentStatus
   */
  export interface PatchAppointmentStatusParams {

    /**
     * The Appointment Status Id.
     */
    statusId: number;

    /**
     * The request.
     */
    request?: AppointmentStatusPatchRequest;
  }
}

export { AppointmentStatusesService }
