import { Selector } from '@ngxs/store';
import { IStatesStateModel } from './states.model';
import { StatesState } from './states.state';

export class StatesSelectors {
    @Selector([StatesState])
    public static loading(state: IStatesStateModel): boolean {
        return state.loading;
    }

    @Selector([StatesState])
    public static states(state: IStatesStateModel): IStatesStateModel['states'] {
        return state.states;
    }
}
