import { ChangeDetectionStrategy, Component, ComponentRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { CdkPortalOutletAttachedRef, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'symplast-tooltip-wrapper',
    templateUrl: 'tooltip-wrapper.component.html',
    styleUrls: ['./tooltip-wrapper.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [PortalModule, CommonModule],
})
export class TooltipWrapperComponent<T extends { closed: EventEmitter<void> }> {
    public portal: ComponentPortal<T> | undefined;
    public content: string | undefined;
    @Output() public closed = new EventEmitter<void>();

    constructor(private renderer: Renderer2) {}

    public onComponentRendering(ref: CdkPortalOutletAttachedRef): void {
        const componentRef = ref as ComponentRef<any>;

        this.renderer.setStyle(componentRef.location.nativeElement, 'overflow', 'hidden');

        componentRef.instance.closed?.pipe(untilDestroyed(this)).subscribe(() => this.closed.emit());
    }
}
