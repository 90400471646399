export class LoadOccasions {
    public static readonly type = '[Occasions] Load';
    constructor(public refresh?: boolean) {}
}

export class ReloadOccasions {
    public static readonly type = '[Occasions] Reload';
}

export class CreateOccasion {
    public static readonly type = '[Occasions] Create';
    constructor() {}
}

export class SaveOccasion {
    public static readonly type = '[Occasions] Save';
    constructor(public id: number, public name: string) {}
}

export class ChangeStateOccasion {
    public static readonly type = '[Occasions] Change State';
    constructor(public id: number, public isActive: boolean) {}
}

export class DeleteOccasion {
    public static readonly type = '[Occasions] Delete';
    constructor(public id: number) {}
}

export class StartEditOccasion {
    public static readonly type = '[Occasions] Start Edit';
    constructor(public id: number) {}
}

export class CancelEditOccasion {
    public static readonly type = '[Occasions] Cancel Edit';
    constructor(public id: number) {}
}
