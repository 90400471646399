import { APP_INITIALIZER, Provider } from '@angular/core';
import { bugsnagBootstrapper } from '@bugsnag/angular-performance';
import { bugsnagInitializerFactory } from '../configurations/bugsnag/bugsnag-initializer';

export const BUGSNAG_PROVIDER: Provider[] = [
    bugsnagBootstrapper,
    {
        provide: APP_INITIALIZER,
        useFactory: bugsnagInitializerFactory,
        multi: true,
    },
];
