/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionCategory } from '../models/api-response-icollection-category';
import { ApiResponseCategory } from '../models/api-response-category';
import { CategoryCreateRequest } from '../models/category-create-request';
import { CategoryUpdateRequest } from '../models/category-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class CategoriesService extends __BaseService {
  static readonly GetCategoriesPath = '/Categories';
  static readonly CreateCategoryPath = '/Categories';
  static readonly GetCategoryPath = '/Categories/{categoryId}';
  static readonly UpdateCategoryPath = '/Categories/{categoryId}';
  static readonly DeleteCategoryPath = '/Categories/{categoryId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all the categories.
   * @return Success
   */
  GetCategoriesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionCategory>;
      })
    );
  }
  /**
   * Get all the categories.
   * @return Success
   */
  GetCategories(): __Observable<ApiResponseICollectionCategory> {
    return this.GetCategoriesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionCategory)
    );
  }

  /**
   * Create a category.
   * @param category The category.
   * @return Success
   */
  CreateCategoryResponse(category?: CategoryCreateRequest): __Observable<__StrictHttpResponse<ApiResponseCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = category;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCategory>;
      })
    );
  }
  /**
   * Create a category.
   * @param category The category.
   * @return Success
   */
  CreateCategory(category?: CategoryCreateRequest): __Observable<ApiResponseCategory> {
    return this.CreateCategoryResponse(category).pipe(
      __map(_r => _r.body as ApiResponseCategory)
    );
  }

  /**
   * Get a category by Id.
   * @param categoryId The category id.
   * @return Success
   */
  GetCategoryResponse(categoryId: number): __Observable<__StrictHttpResponse<ApiResponseCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Categories/${encodeURIComponent(String(categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCategory>;
      })
    );
  }
  /**
   * Get a category by Id.
   * @param categoryId The category id.
   * @return Success
   */
  GetCategory(categoryId: number): __Observable<ApiResponseCategory> {
    return this.GetCategoryResponse(categoryId).pipe(
      __map(_r => _r.body as ApiResponseCategory)
    );
  }

  /**
   * Update a category.
   * @param params The `CategoriesService.UpdateCategoryParams` containing the following parameters:
   *
   * - `categoryId`: The category id.
   *
   * - `category`: The category.
   *
   * @return Success
   */
  UpdateCategoryResponse(params: CategoriesService.UpdateCategoryParams): __Observable<__StrictHttpResponse<ApiResponseCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.category;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Categories/${encodeURIComponent(String(params.categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCategory>;
      })
    );
  }
  /**
   * Update a category.
   * @param params The `CategoriesService.UpdateCategoryParams` containing the following parameters:
   *
   * - `categoryId`: The category id.
   *
   * - `category`: The category.
   *
   * @return Success
   */
  UpdateCategory(params: CategoriesService.UpdateCategoryParams): __Observable<ApiResponseCategory> {
    return this.UpdateCategoryResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCategory)
    );
  }

  /**
   * Delete a category by Id.
   * @param categoryId The category id.
   * @return Success
   */
  DeleteCategoryResponse(categoryId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Categories/${encodeURIComponent(String(categoryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete a category by Id.
   * @param categoryId The category id.
   * @return Success
   */
  DeleteCategory(categoryId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteCategoryResponse(categoryId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module CategoriesService {

  /**
   * Parameters for UpdateCategory
   */
  export interface UpdateCategoryParams {

    /**
     * The category id.
     */
    categoryId: number;

    /**
     * The category.
     */
    category?: CategoryUpdateRequest;
  }
}

export { CategoriesService }
