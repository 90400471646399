import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { FeatureFlagsService } from '@symplast/services/shared';

@Injectable({ providedIn: 'root' })
export class FeatureFlagGuard implements CanActivate {
    constructor(private _featureFlags: FeatureFlagsService, private _router: Router) {}
    canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
        const requiredFeatureFlag: string = next.data['requiredFeatureFlag'] as string;
        const featureFlagRedirect: string = (next.data['featureFlagRedirect'] as string) || '/';

        return this._featureFlags.isFeatureFlagEnabled(requiredFeatureFlag) ? true : this._router.createUrlTree([featureFlagRedirect]);
    }
}
