import zipObject from 'lodash/zipObject';

export function countOccurrences(text: string, list: string[]): Record<string, number> {
    const occurrences: Record<string, number> = {};

    if (!text) {
        return zipObject(list, Array(list.length).fill(0));
    }

    list.forEach((item) => {
        let count = 0;
        let pos = text.indexOf(item);

        while (pos !== -1) {
            count++;
            pos = text.indexOf(item, pos + item.length);
        }

        occurrences[item] = count;
    });

    return occurrences;
}
