import { Selector } from '@ngxs/store';
import { ICountriesStateModel } from './countries.model';
import { CountriesState } from './countries.state';

export class CountriesSelectors {
    @Selector([CountriesState])
    public static loading(state: ICountriesStateModel): boolean {
        return state.loading;
    }

    @Selector([CountriesState])
    public static countries(state: ICountriesStateModel): ICountriesStateModel['countries'] {
        return state.countries;
    }
}
