import { Injectable } from '@angular/core';
import { WebStorageService } from '@symplast/services/shared';
import { CookieService } from 'ngx-cookie-service';
import { TipsEnum } from './tips.enum';

type TipsStorage = {
    [P in TipsEnum]: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class TipsService {
    private readonly key = 'tips';

    constructor(private readonly webstorage: WebStorageService, private cookieService: CookieService) {}

    private get tips(): TipsStorage {
        return this.webstorage.local.getItem(this.key) || ({} as TipsStorage);
    }

    public checkTip(tip: TipsEnum): boolean {
        // Needed check in cookie for backwards compatibility with old user.service
        // @see task 74275
        const inCookie = this.cookieService.get(tip.toString()) === 'true';
        const inLocalStorage = this.tips[tip];

        // For making sure that we can safely transition to this service without suddenly re-showing the tips.
        const inOldLocalStorage = this.webstorage.local.getItem(tip) == true;

        if (inOldLocalStorage && !inLocalStorage) {
            this.setTip(tip, true);
        }

        return inLocalStorage || inCookie || inOldLocalStorage;
    }

    public setTip(tip: TipsEnum, state: boolean): void {
        const currentTips = this.tips;

        currentTips[tip] = state;
        this.webstorage.local.setItem(this.key, currentTips);
    }
}
