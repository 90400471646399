import { Selector } from '@ngxs/store';
import { IGeneralSettingsStateModel } from './general-settings.model';
import { GeneralSettingsState } from './general-settings.state';

export class GeneralSettingsSelectors {
    @Selector([GeneralSettingsState])
    public static loading(state: IGeneralSettingsStateModel): boolean {
        return state.loading;
    }

    @Selector([GeneralSettingsState])
    public static isAutomaticLogOutExitingESign(state: IGeneralSettingsStateModel): boolean {
        return state.isAutomaticLogOutExitingESign;
    }

    @Selector([GeneralSettingsState])
    public static isReviewAfterSignedRequired(state: IGeneralSettingsStateModel): boolean {
        return state.isReviewAfterSignedRequired;
    }

    @Selector([GeneralSettingsState])
    public static sendPatientAppInvitation(state: IGeneralSettingsStateModel): boolean {
        return state.sendPatientAppInvitation;
    }

    @Selector([GeneralSettingsState])
    public static patientFileFinancialViewMode(state: IGeneralSettingsStateModel): 'Grid' | 'List' {
        return state.patientFileFinancialViewMode;
    }
}
