import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Action, State, StateContext } from '@ngxs/store';
import {
    BasicTax,
    GlobalTaxDefaultsService,
    LocationItemType,
    Tax,
    TaxesService,
    TaxGroup,
    TaxGroupsService,
} from '@symplast/generated-clients/web-portal';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LocationItemTypeModel } from './location-item-type.model';
import {
    CreateLocationTaxDefault,
    DeleteLocationTaxDefault,
    LoadLocationTaxDefaults,
    LoadTaxes,
    UpdateLocationTaxDefault,
    UpdateTaxIsEditMode,
} from './taxes.actions';
import { ITaxesStateModel } from './taxes.model';

const defaultTaxesState = (): ITaxesStateModel => {
    return {
        searchString: '',
        loading: false,
        taxes: [],
        locationItemTypes: [],
        filteredLocationItemTypes: [],
    };
};

@State<ITaxesStateModel>({
    name: 'taxes',
    defaults: defaultTaxesState(),
})
@Injectable()
export class TaxesState {
    constructor(
        private globalTaxDefaultsService: GlobalTaxDefaultsService,
        private taxesService: TaxesService,
        private taxGroupsService: TaxGroupsService,
    ) {}

    @Receiver()
    public static setSearchString(context: StateContext<ITaxesStateModel>, { payload }: EmitterAction<string>): void {
        const rawLocationItemTypes = context.getState().locationItemTypes;
        const taxDefaultsByLocationId = TaxesState.groupByLocationId(TaxesState.filterLocationItemTypes(rawLocationItemTypes, payload));

        const filteredLocationItemTypes = taxDefaultsByLocationId.map((group) => new LocationItemTypeModel(group));

        context.patchState({
            searchString: payload,
            filteredLocationItemTypes: filteredLocationItemTypes,
        });
    }

    private static sortLocationItemTypes(rawLocationItemTypes: LocationItemType[]): LocationItemType[] {
        return [
            ...rawLocationItemTypes
                .filter((locationItemType) => locationItemType.locationName)
                .sort(
                    (locationItemType_1, locationItemType_2) =>
                        locationItemType_1.locationName?.localeCompare(locationItemType_2.locationName ?? '') ?? 0,
                ),
        ];
    }

    private static sortTaxes(rawTaxes: Tax[], rawTaxGroups: TaxGroup[]): BasicTax[] {
        const taxes = rawTaxes
            .map((t) => {
                return {
                    isTaxGroup: false,
                    name: t.name,
                    taxId: t.taxId,
                    taxRate: t.taxRate,
                } as BasicTax;
            })
            .concat(
                rawTaxGroups.map((t) => {
                    return { isTaxGroup: true, name: t.name, taxId: t.taxId, taxRate: t.taxRate } as BasicTax;
                }),
            );

        return [...taxes.sort((tax_1, tax_2) => tax_1.name?.localeCompare(tax_2.name ?? '') ?? 0)];
    }

    private static filterLocationItemTypes(rawLocationItemTypes: LocationItemType[], searchString: string): LocationItemType[] {
        const lower = searchString?.toLowerCase();

        return [...rawLocationItemTypes.filter((b) => !lower || b.locationName?.toLowerCase()?.includes(lower))];
    }

    private static groupByLocationId(array: LocationItemType[]): LocationItemType[][] {
        // Return the end result
        return array.reduce((result: LocationItemType[][], currentValue) => {
            const locationIdIdnex = result.findIndex((info) => info.some((loc) => loc.locationId == currentValue.locationId));

            if (locationIdIdnex < 0) {
                result.push([currentValue]);
            } else {
                result[locationIdIdnex].push(currentValue);
            }

            return result;
        }, []); // empty object is the initial value for result object
    }

    @Action(LoadLocationTaxDefaults)
    public loadLocationTaxDefaults(context: StateContext<ITaxesStateModel>, { refresh }: LoadLocationTaxDefaults): void {
        if (!context.getState().locationItemTypes.length || refresh) {
            context.patchState({ loading: true });
            this.globalTaxDefaultsService
                .GetGlobalTax()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    const rawLocationItemTypes = TaxesState.sortLocationItemTypes(response.result ?? []);
                    const searchString = context.getState().searchString;
                    const taxDefaultsByLocationId = TaxesState.groupByLocationId(
                        TaxesState.filterLocationItemTypes(rawLocationItemTypes, searchString),
                    );

                    const filteredLocationItemTypes = taxDefaultsByLocationId.map((group) => new LocationItemTypeModel(group));

                    context.patchState({
                        locationItemTypes: rawLocationItemTypes,
                        filteredLocationItemTypes: filteredLocationItemTypes,
                    });
                });
        }
    }

    @Action(LoadTaxes)
    public loadTaxes(context: StateContext<ITaxesStateModel>, { refresh }: LoadTaxes): void {
        if (!context.getState().taxes.length || refresh) {
            context.patchState({ loading: true });

            const taxes$ = this.taxesService.GetTaxes();
            const taxGroups$ = this.taxGroupsService.GetTaxGroups();

            forkJoin([taxes$, taxGroups$])
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(([taxesResponse, taxGroupsResponse]) => {
                    context.patchState({ loading: true });

                    const rawTaxes = TaxesState.sortTaxes(taxesResponse.result ?? [], taxGroupsResponse.result ?? []);

                    context.patchState({
                        taxes: rawTaxes,
                    });
                });
        }
    }

    @Action(UpdateTaxIsEditMode)
    public updateIsEditMode(context: StateContext<ITaxesStateModel>, { locationId, isEditMode }: UpdateTaxIsEditMode): void {
        const filteredLocationItemTypes = context.getState().filteredLocationItemTypes;

        context.patchState({
            filteredLocationItemTypes: filteredLocationItemTypes.map((model) =>
                model.locationId === locationId
                    ? new LocationItemTypeModel(
                          [model.feesLocationItem, model.proceduresLocationItem, model.productsLocationItem],
                          isEditMode,
                          model,
                      )
                    : model,
            ),
        });
    }

    @Action(CreateLocationTaxDefault)
    public createLocationTaxDefault(
        context: StateContext<ITaxesStateModel>,
        { locationId, itemTypeId, defaultTaxId }: CreateLocationTaxDefault,
    ): void {
        context.patchState({ loading: true });

        this.globalTaxDefaultsService
            .CreateGlobalTax({ locationId: locationId, itemTypeId: itemTypeId, defaultTaxId: defaultTaxId })
            .pipe(finalize(() => context.patchState({ loading: false })))
            .subscribe(() => {
                return;
            });
    }

    @Action(DeleteLocationTaxDefault)
    public deleteLocationTaxDefault(context: StateContext<ITaxesStateModel>, { locationItemTypeId }: DeleteLocationTaxDefault): void {
        context.patchState({ loading: true });

        this.globalTaxDefaultsService
            .UpdateGlobalTax({ locationItemTypeId: locationItemTypeId })
            .pipe(finalize(() => context.patchState({ loading: false })))
            .subscribe(() => {
                return;
            });
    }

    @Action(UpdateLocationTaxDefault)
    public updateLocationTaxDefault(
        context: StateContext<ITaxesStateModel>,
        { locationId, oldIds, newIds }: UpdateLocationTaxDefault,
    ): void {
        const NonTaxableValue = -1;

        const processes = [];

        for (let i = 0; i < newIds.length; i++) {
            if ((!oldIds[i] && newIds[i] === NonTaxableValue) || oldIds[i]?.defaultTaxId == newIds[i]) {
                continue;
            }

            if (!oldIds[i] || oldIds[i].defaultTaxId === NonTaxableValue) {
                processes.push(
                    this.globalTaxDefaultsService.CreateGlobalTax({ locationId: locationId, itemTypeId: i + 1, defaultTaxId: newIds[i] }),
                );
                continue;
            }

            if (newIds[i] !== NonTaxableValue) {
                processes.push(
                    this.globalTaxDefaultsService.UpdateGlobalTax({
                        locationItemTypeId: oldIds[i].locationItemTypeId,
                        defaultTaxId: newIds[i],
                    }),
                );
                continue;
            }

            if (newIds[i] === NonTaxableValue) {
                processes.push(this.globalTaxDefaultsService.DeleteGlobalTax(oldIds[i].locationItemTypeId));
                continue;
            }
        }

        if (processes.length > 0) {
            context.patchState({ loading: true });
            forkJoin(processes).subscribe(() => context.dispatch(new LoadLocationTaxDefaults(true)));
        } else {
            context.dispatch(new UpdateTaxIsEditMode(locationId, false));
        }
    }
}
