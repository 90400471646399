import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadProviders, ReloadProviders } from './providers.actions';
import { IProvidersStateModel } from './providers.model';
import { ProviderSelectionType } from '@symplast/models/shared';
import { UsersService } from '@symplast/generated-clients/web-portal';

@State<IProvidersStateModel>({
    name: 'physicians',
    defaults: {
        providers: [],
        loading: false,
        providerSelectionList: [],
    },
})
@Injectable()
export class ProvidersState {
    constructor(private usersService: UsersService) {}

    @Action(LoadProviders)
    public load(context: StateContext<IProvidersStateModel>, { refresh }: LoadProviders) {
        if (!context.getState().providers.length || refresh) {
            context.patchState({ loading: true });
            this.usersService
                .GetUsers({ userType: 'Physician' })
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    const providers = [...response.result].sort((provider_1, provider_2) =>
                        provider_1.displayName.localeCompare(provider_2.displayName),
                    );

                    context.patchState({
                        providers,
                        providerSelectionList: [
                            { userId: null, displayName: 'None', providerSelectionType: ProviderSelectionType.None, isActive: true },
                            {
                                userId: null,
                                displayName: 'Not Selected',
                                providerSelectionType: ProviderSelectionType.NotSelected,
                                isActive: true,
                            },
                            ...providers.map((value) => ({
                                userId: value.userId,
                                displayName: value.displayName,
                                providerSelectionType: ProviderSelectionType.User,
                                isActive: value.status,
                            })),
                        ],
                    });
                });
        }
    }

    @Action(ReloadProviders)
    public reload(context: StateContext<IProvidersStateModel>) {
        context.dispatch(new LoadProviders(true));
    }
}
