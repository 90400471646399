/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseGenerateEhrNotePdfResponse } from '../models/api-response-generate-ehr-note-pdf-response';
@Injectable({
  providedIn: 'root',
})
class EhrNotesService extends __BaseService {
  static readonly GenerateEhrNotePdfPath = '/EhrNotes/{ehrNoteId}/GeneratePdf';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The generate EHR notes PDF async.
   * @param params The `EhrNotesService.GenerateEhrNotePdfParams` containing the following parameters:
   *
   * - `ehrNoteId`: The EHR note id.
   *
   * - `printFullPageImageVersions`: The print full page image versions.
   *
   * - `includeCurrentHealthHistory`: The include current health history.
   *
   * - `includeBillingCodes`: The include billing codes.
   *
   * @return Success
   */
  GenerateEhrNotePdfResponse(params: EhrNotesService.GenerateEhrNotePdfParams): __Observable<__StrictHttpResponse<ApiResponseGenerateEhrNotePdfResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.printFullPageImageVersions != null) __params = __params.set('printFullPageImageVersions', params.printFullPageImageVersions.toString());
    if (params.includeCurrentHealthHistory != null) __params = __params.set('includeCurrentHealthHistory', params.includeCurrentHealthHistory.toString());
    if (params.includeBillingCodes != null) __params = __params.set('includeBillingCodes', params.includeBillingCodes.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/EhrNotes/${encodeURIComponent(String(params.ehrNoteId))}/GeneratePdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseGenerateEhrNotePdfResponse>;
      })
    );
  }
  /**
   * The generate EHR notes PDF async.
   * @param params The `EhrNotesService.GenerateEhrNotePdfParams` containing the following parameters:
   *
   * - `ehrNoteId`: The EHR note id.
   *
   * - `printFullPageImageVersions`: The print full page image versions.
   *
   * - `includeCurrentHealthHistory`: The include current health history.
   *
   * - `includeBillingCodes`: The include billing codes.
   *
   * @return Success
   */
  GenerateEhrNotePdf(params: EhrNotesService.GenerateEhrNotePdfParams): __Observable<ApiResponseGenerateEhrNotePdfResponse> {
    return this.GenerateEhrNotePdfResponse(params).pipe(
      __map(_r => _r.body as ApiResponseGenerateEhrNotePdfResponse)
    );
  }
}

module EhrNotesService {

  /**
   * Parameters for GenerateEhrNotePdf
   */
  export interface GenerateEhrNotePdfParams {

    /**
     * The EHR note id.
     */
    ehrNoteId: number;

    /**
     * The print full page image versions.
     */
    printFullPageImageVersions?: boolean;

    /**
     * The include current health history.
     */
    includeCurrentHealthHistory?: boolean;

    /**
     * The include billing codes.
     */
    includeBillingCodes?: boolean;
  }
}

export { EhrNotesService }
