import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { PatientBalanceService } from '@symplast/generated-clients/web-portal';
import {
    ApplyFiltersForPawtientBalances,
    ApplySortForPatientBalances,
    ChangePageForPatientBalances,
    LoadPatientBalances,
    ResetFiltersForPatientBalances,
} from './patient-balances.actions';
import { IPatientBalancesStateModel } from './patient-balances.model';

const defaultFilters = (): any => ({
    pageIndex: 0,
    pageSize: 20,
    ageBracket: '',
    balanceBracket: '',
    insuranceRelated: 'Include',
    patientSearch: undefined,
    paymentAgeBracket: '',
});

const defaultPatientBalancesState = (): IPatientBalancesStateModel => ({
    ...defaultFilters(),
    sortBy: 'TotalBalance',
    sortDirection: 'Descending',
    totalCount: 0,
    loading: false,
    patientBalances: [],
});

@State<IPatientBalancesStateModel>({
    name: 'patientBalances',
    defaults: defaultPatientBalancesState(),
})
@Injectable()
export class PatientBalancesState {
    constructor(private patientBalancesService: PatientBalanceService) {}

    @Action(LoadPatientBalances)
    public load(context: StateContext<IPatientBalancesStateModel>) {
        const state = context.getState();

        context.patchState({
            loading: true,
        });

        this.patientBalancesService
            .GetPatientBalances({
                SearchStr: state.patientSearch,
                InvoiceAgeBracket: state.ageBracket !== '' ? state.ageBracket : undefined,
                PaymentAgeBracket: state.paymentAgeBracket !== '' ? state.paymentAgeBracket : undefined,
                TotalBalanceBracket: state.balanceBracket as any,
                InsuranceRelatedInvoiceFilter: state.insuranceRelated,
                OrderBy: state.sortBy,
                SortingDirection: state.sortDirection,
                Skip: state.pageIndex * state.pageSize,
                Take: state.pageSize,
            })
            .subscribe(
                (response) => {
                    context.patchState({
                        loading: false,
                        patientBalances: response.result,
                        totalCount: response.totalCount,
                    });
                },
                () => {
                    context.patchState({
                        loading: false,
                        patientBalances: [],
                        totalCount: 0,
                    });
                },
            );
    }

    @Action(ResetFiltersForPatientBalances)
    public reset(context: StateContext<IPatientBalancesStateModel>) {
        context.patchState({ ...defaultFilters() });
        context.dispatch(new LoadPatientBalances());
    }

    @Action(ApplyFiltersForPawtientBalances)
    public filter(
        context: StateContext<IPatientBalancesStateModel>,
        { ageBracket, balanceBracket, insuranceRelated, patientSearch, paymentAgeBracket }: ApplyFiltersForPawtientBalances,
    ) {
        context.patchState({ ageBracket, balanceBracket, insuranceRelated, patientSearch, paymentAgeBracket, pageIndex: 0 });
        context.dispatch(new LoadPatientBalances());
    }

    @Action(ChangePageForPatientBalances)
    public changePage(context: StateContext<IPatientBalancesStateModel>, { pageIndex, pageSize }: ChangePageForPatientBalances) {
        context.patchState({ pageIndex, pageSize });
        context.dispatch(new LoadPatientBalances());
    }

    @Action(ApplySortForPatientBalances)
    public sorting(context: StateContext<IPatientBalancesStateModel>, { sortBy, sortDirection }: ApplySortForPatientBalances) {
        context.patchState({ sortBy, sortDirection });
        context.dispatch(new LoadPatientBalances());
    }
}
