/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableGlobalTagModel } from '../models/api-response-ienumerable-global-tag-model';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
    providedIn: 'root',
})
class TagsService extends __BaseService {
    static readonly GetTagsPath = '/api/tags';
    static readonly DeleteTagPath = '/api/tags/{tagName}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets the tags matching name query.
     * @param tagNameQuery The tag name query.
     * @return Success
     */
    GetTagsResponse(tagNameQuery?: string): __Observable<__StrictHttpResponse<ApiResponseIEnumerableGlobalTagModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (tagNameQuery != null) __params = __params.set('tagNameQuery', tagNameQuery.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/tags`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableGlobalTagModel>;
            }),
        );
    }
    /**
     * Gets the tags matching name query.
     * @param tagNameQuery The tag name query.
     * @return Success
     */
    GetTags(tagNameQuery?: string): __Observable<ApiResponseIEnumerableGlobalTagModel> {
        return this.GetTagsResponse(tagNameQuery).pipe(__map((_r) => _r.body as ApiResponseIEnumerableGlobalTagModel));
    }

    /**
     * Deletes the specified by name tag.
     * @param tagName Name of the tag.
     * @return Success
     */
    DeleteTagResponse(tagName: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/tags/${encodeURIComponent(String(tagName))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the specified by name tag.
     * @param tagName Name of the tag.
     * @return Success
     */
    DeleteTag(tagName: string): __Observable<ApiResponseOkResponse> {
        return this.DeleteTagResponse(tagName).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module TagsService {}

export { TagsService };
