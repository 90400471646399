import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmationDialogConfig, ConfirmationDialogData } from './confirmation-dialog.model';
import { ConfirmationDialogRef } from './confirmation-dialog.reference';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { filter } from 'rxjs';
import pick from 'lodash-es/pick';
import omit from 'lodash-es/omit';

const DIALOG_CONFIG_DEFAULT: Partial<ConfirmationDialogData> = {
    confirm: { label: 'Confirm' },
    deny: { label: 'Cancel' },
};

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
    constructor(private dialog: MatDialog) {}

    public open(config: ConfirmationDialogConfig): ConfirmationDialogRef {
        const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, { confirmed: boolean }>(
            ConfirmationDialogComponent,
            {
                closeOnNavigation: true,
                autoFocus: false,
                disableClose: true,
                width: '450px',
                ...omit(config, ['title', 'content', 'confirm', 'deny', 'checkbox']),
                data: {
                    ...DIALOG_CONFIG_DEFAULT,
                    ...pick(config, ['title', 'content', 'confirm', 'deny', 'checkbox']),
                },
            },
        );

        return this.decorateDialogRef(dialogRef);
    }

    private decorateDialogRef(dialogRef: MatDialogRef<ConfirmationDialogComponent, { confirmed: boolean }>): ConfirmationDialogRef {
        (dialogRef as ConfirmationDialogRef).afterConfirmed = () => dialogRef.afterClosed().pipe(filter((result) => result?.confirmed));
        (dialogRef as ConfirmationDialogRef).afterDenied = () => dialogRef.afterClosed().pipe(filter((result) => !result?.confirmed));
        (dialogRef as ConfirmationDialogRef).on = ({ confirm, deny }) => {
            if (confirm) {
                (dialogRef as ConfirmationDialogRef).afterConfirmed().subscribe(() => confirm());
            }

            if (deny) {
                (dialogRef as ConfirmationDialogRef).afterDenied().subscribe(() => deny());
            }
        };

        return dialogRef as ConfirmationDialogRef;
    }
}
