/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTeam } from '../models/api-response-ienumerable-team';
import { ApiResponseTeam } from '../models/api-response-team';
import { TeamPostRequest } from '../models/team-post-request';
import { TeamPutRequest } from '../models/team-put-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class TeamsService extends __BaseService {
  static readonly GetTeamsPath = '/Teams';
  static readonly CreateTeamPath = '/Teams';
  static readonly GetTeamByIdPath = '/Teams/{id}';
  static readonly UpdateTeamPath = '/Teams/{id}';
  static readonly UpdateTeam_1Path = '/Teams/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all the Teams for user's tenant ID.
   * @param params The `TeamsService.GetTeamsParams` containing the following parameters:
   *
   * - `skipValue`: The skip value.
   *
   * - `includeInactive`: if set to `true` [include inactive].
   *
   * - `batchSize`: How many teams are going to be returned. Default is 100
   *
   * @return Success
   */
  GetTeamsResponse(params: TeamsService.GetTeamsParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTeam>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skipValue != null) __params = __params.set('skipValue', params.skipValue.toString());
    if (params.includeInactive != null) __params = __params.set('includeInactive', params.includeInactive.toString());
    if (params.batchSize != null) __params = __params.set('batchSize', params.batchSize.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Teams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTeam>;
      })
    );
  }
  /**
   * Gets all the Teams for user's tenant ID.
   * @param params The `TeamsService.GetTeamsParams` containing the following parameters:
   *
   * - `skipValue`: The skip value.
   *
   * - `includeInactive`: if set to `true` [include inactive].
   *
   * - `batchSize`: How many teams are going to be returned. Default is 100
   *
   * @return Success
   */
  GetTeams(params: TeamsService.GetTeamsParams): __Observable<ApiResponseIEnumerableTeam> {
    return this.GetTeamsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTeam)
    );
  }

  /**
   * Creates new team.
   * @param teamCreateRequest The team create request.
   * @return Success or Created
   */
  CreateTeamResponse(teamCreateRequest?: TeamPostRequest): __Observable<__StrictHttpResponse<ApiResponseTeam | ApiResponseTeam>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = teamCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Teams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTeam | ApiResponseTeam>;
      })
    );
  }
  /**
   * Creates new team.
   * @param teamCreateRequest The team create request.
   * @return Success or Created
   */
  CreateTeam(teamCreateRequest?: TeamPostRequest): __Observable<ApiResponseTeam | ApiResponseTeam> {
    return this.CreateTeamResponse(teamCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseTeam | ApiResponseTeam)
    );
  }

  /**
   * Gets team by it's identifier.
   * @param id The identifier.
   * @return Success
   */
  GetTeamByIdResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseTeam>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Teams/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTeam>;
      })
    );
  }
  /**
   * Gets team by it's identifier.
   * @param id The identifier.
   * @return Success
   */
  GetTeamById(id: string): __Observable<ApiResponseTeam> {
    return this.GetTeamByIdResponse(id).pipe(
      __map(_r => _r.body as ApiResponseTeam)
    );
  }

  /**
   * Updates the specified team.
   * @param params The `TeamsService.UpdateTeamParams` containing the following parameters:
   *
   * - `id`: The team identifier.
   *
   * - `teamUpdateRequest`: The team update request.
   *
   * @return Success
   */
  UpdateTeamResponse(params: TeamsService.UpdateTeamParams): __Observable<__StrictHttpResponse<ApiResponseTeam>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.teamUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Teams/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTeam>;
      })
    );
  }
  /**
   * Updates the specified team.
   * @param params The `TeamsService.UpdateTeamParams` containing the following parameters:
   *
   * - `id`: The team identifier.
   *
   * - `teamUpdateRequest`: The team update request.
   *
   * @return Success
   */
  UpdateTeam(params: TeamsService.UpdateTeamParams): __Observable<ApiResponseTeam> {
    return this.UpdateTeamResponse(params).pipe(
      __map(_r => _r.body as ApiResponseTeam)
    );
  }

  /**
   * Delete the specified team.
   * @param id The team identifier.
   * @return Success
   */
  UpdateTeam_1Response(id: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Teams/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete the specified team.
   * @param id The team identifier.
   * @return Success
   */
  UpdateTeam_1(id: string): __Observable<ApiResponseOkResponse> {
    return this.UpdateTeam_1Response(id).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module TeamsService {

  /**
   * Parameters for GetTeams
   */
  export interface GetTeamsParams {

    /**
     * The skip value.
     */
    skipValue?: number;

    /**
     * if set to `true` [include inactive].
     */
    includeInactive?: boolean;

    /**
     * How many teams are going to be returned. Default is 100
     */
    batchSize?: number;
  }

  /**
   * Parameters for UpdateTeam
   */
  export interface UpdateTeamParams {

    /**
     * The team identifier.
     */
    id: string;

    /**
     * The team update request.
     */
    teamUpdateRequest?: TeamPutRequest;
  }
}

export { TeamsService }
