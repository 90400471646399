import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseIdPipe } from './pipes/case-id.pipe';
import { DateUtcPipe } from './pipes/date-utc.pipe';
import { DaysAgoPipe } from './pipes/days-ago.pipe';
import { FlattenArrayPipe } from './pipes/flatten-array.pipe';
import { FlattenArraySmartPipe } from './pipes/flatten-array-smart.pipe';
import { BooleanOnOffPipe } from './pipes/boolean-on-off.pipe';
import { MinutesPipe } from './pipes/minutes.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { TimeRedablePipe } from './pipes/time-redable.pipe';
import { TitleCaseCustomPipe } from './pipes/title-case-custom.pipe';
import { TruncateByWordsPipe } from './pipes/truncate-by-words.pipe';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { RefundPipe } from './pipes/refund.pipe';
import { DelayPipe } from './pipes/delay.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { ExpiredPipe } from './pipes/expired.pipe';
import { LongDatePipe } from './pipes/long-date.pipe';
import { NegativePipe } from './pipes/negative.pipe';
import { SpaceByCapitalizedPipe } from './pipes/space-by-capitalized.pipe';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { HighlightPipe } from './pipes/highlight.pipe';
import { HighlightPhonePipe } from './pipes/highlight-phone.pipe';
import { HighlightBirthdayPipe } from './pipes/highlight-birthday.pipe';
import { TrackByPipe } from './pipes/track-by.pipe';
import { AndXOthersPipe } from './pipes/and-x-others.pipe';
import { AgeTitlePipe } from './pipes/age-title/age-title.pipe';

@NgModule({
    imports: [
        BooleanOnOffPipe,
        LongDatePipe,
        CommonModule,
        TrackByPipe,
        HighlightPipe,
        UtcDatePipe,
        FlattenArrayPipe,
        CaseIdPipe,
        DaysAgoPipe,
        SpaceByCapitalizedPipe,
        AgeTitlePipe,
        BooleanPipe,
        TitleCaseCustomPipe,
        HighlightBirthdayPipe,
        HighlightPhonePipe,
        MinutesPipe,
        RefundPipe,
        FlattenArraySmartPipe,
    ],
    declarations: [
        DateUtcPipe,
        PhoneNumberPipe,
        TruncateByWordsPipe,
        TimeRedablePipe,
        DelayPipe,
        NegativePipe,
        ExpiredPipe,
        StopPropagationDirective,
    ],
    exports: [
        DateUtcPipe,
        MinutesPipe,
        DaysAgoPipe,
        UtcDatePipe,
        TitleCaseCustomPipe,
        FlattenArrayPipe,
        FlattenArraySmartPipe,
        BooleanOnOffPipe,
        CaseIdPipe,
        PhoneNumberPipe,
        TruncateByWordsPipe,
        TimeRedablePipe,
        RefundPipe,
        DelayPipe,
        NegativePipe,
        BooleanPipe,
        SpaceByCapitalizedPipe,
        ExpiredPipe,
        LongDatePipe,
        StopPropagationDirective,
        HighlightPipe,
        HighlightPhonePipe,
        HighlightBirthdayPipe,
        TrackByPipe,
    ],
    providers: [
        DateUtcPipe,
        MinutesPipe,
        DaysAgoPipe,
        UtcDatePipe,
        TitleCaseCustomPipe,
        FlattenArrayPipe,
        FlattenArraySmartPipe,
        BooleanOnOffPipe,
        CaseIdPipe,
        PhoneNumberPipe,
        TruncateByWordsPipe,
        TimeRedablePipe,
        RefundPipe,
        DelayPipe,
        NegativePipe,
        BooleanPipe,
        SpaceByCapitalizedPipe,
        ExpiredPipe,
        StopPropagationDirective,
        HighlightPipe,
        HighlightPhonePipe,
        HighlightBirthdayPipe,
        AndXOthersPipe,
    ],
})
export class UiSharedModule {}
