import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'delayPipe',
})
export class DelayPipe implements PipeTransform {
    transform(value: number, args?: any): string {
        const lastMinutes = Math.trunc(value / 60);
        const minutes = lastMinutes % 60;
        const lastHours = Math.trunc(lastMinutes / 60);
        const hours = lastHours % 60;
        const days = Math.trunc(lastHours / 24);

        return `${days}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
}
