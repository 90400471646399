import { Injectable } from '@angular/core';
import { IKeyNameItem } from '@symplast/models/shared';

@Injectable({
    providedIn: 'root',
})
export class BaseEnumService {
    public data!: IKeyNameItem[];

    constructor() {}

    public get values(): IKeyNameItem[] {
        return this.data;
    }

    public getByKey(key: string) {
        const item = this.data.filter((d) => d.key === key);

        if (item.length !== 0) {
            return item[0];
        }

        return null;
    }
}
