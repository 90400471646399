/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIReadOnlyCollectionConsentFormTemplate } from '../models/api-response-iread-only-collection-consent-form-template';
import { ApiResponseConsentFormTemplate } from '../models/api-response-consent-form-template';
@Injectable({
  providedIn: 'root',
})
class ConsentFormsService extends __BaseService {
  static readonly GetConsentFormsPath = '/ConsentForms';
  static readonly CreateConsentFormPath = '/ConsentForms';
  static readonly GetConsentFormByIdPath = '/ConsentForms/{consentFormId}';
  static readonly UpdateConsentFormPath = '/ConsentForms/{consentFormId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves all consent forms for a given tenant.
   * @param includeInactive Include inactive consent forms.
   * @return Success
   */
  GetConsentFormsResponse(includeInactive?: boolean): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionConsentFormTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeInactive != null) __params = __params.set('includeInactive', includeInactive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ConsentForms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionConsentFormTemplate>;
      })
    );
  }
  /**
   * Retrieves all consent forms for a given tenant.
   * @param includeInactive Include inactive consent forms.
   * @return Success
   */
  GetConsentForms(includeInactive?: boolean): __Observable<ApiResponseIReadOnlyCollectionConsentFormTemplate> {
    return this.GetConsentFormsResponse(includeInactive).pipe(
      __map(_r => _r.body as ApiResponseIReadOnlyCollectionConsentFormTemplate)
    );
  }

  /**
   * Creates a new consent form asynchronously.
   * @param params The `ConsentFormsService.CreateConsentFormParams` containing the following parameters:
   *
   * - `name`: The name of the consent form.
   *
   * - `file`:
   *
   * - `isActive`: A boolean value indicating if the consent form is active.
   *
   * - `description`: The description of the consent form.
   *
   * @return Success
   */
  CreateConsentFormResponse(params: ConsentFormsService.CreateConsentFormParams): __Observable<__StrictHttpResponse<ApiResponseConsentFormTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ConsentForms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseConsentFormTemplate>;
      })
    );
  }
  /**
   * Creates a new consent form asynchronously.
   * @param params The `ConsentFormsService.CreateConsentFormParams` containing the following parameters:
   *
   * - `name`: The name of the consent form.
   *
   * - `file`:
   *
   * - `isActive`: A boolean value indicating if the consent form is active.
   *
   * - `description`: The description of the consent form.
   *
   * @return Success
   */
  CreateConsentForm(params: ConsentFormsService.CreateConsentFormParams): __Observable<ApiResponseConsentFormTemplate> {
    return this.CreateConsentFormResponse(params).pipe(
      __map(_r => _r.body as ApiResponseConsentFormTemplate)
    );
  }

  /**
   * Retrieves a consent form by its ID.
   * @param consentFormId The ID of the consent form.
   * @return Success
   */
  GetConsentFormByIdResponse(consentFormId: number): __Observable<__StrictHttpResponse<ApiResponseConsentFormTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ConsentForms/${encodeURIComponent(String(consentFormId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseConsentFormTemplate>;
      })
    );
  }
  /**
   * Retrieves a consent form by its ID.
   * @param consentFormId The ID of the consent form.
   * @return Success
   */
  GetConsentFormById(consentFormId: number): __Observable<ApiResponseConsentFormTemplate> {
    return this.GetConsentFormByIdResponse(consentFormId).pipe(
      __map(_r => _r.body as ApiResponseConsentFormTemplate)
    );
  }

  /**
   * Updates a consent form asynchronously.
   * @param params The `ConsentFormsService.UpdateConsentFormParams` containing the following parameters:
   *
   * - `consentFormId`: The ID of the consent form.
   *
   * - `name`: The name of the consent form.
   *
   * - `isActive`: A flag indicating whether the consent form is active.
   *
   * - `file`:
   *
   * - `description`: The description of the consent form.
   *
   * @return Success
   */
  UpdateConsentFormResponse(params: ConsentFormsService.UpdateConsentFormParams): __Observable<__StrictHttpResponse<ApiResponseConsentFormTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.description != null) __params = __params.set('description', params.description.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/ConsentForms/${encodeURIComponent(String(params.consentFormId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseConsentFormTemplate>;
      })
    );
  }
  /**
   * Updates a consent form asynchronously.
   * @param params The `ConsentFormsService.UpdateConsentFormParams` containing the following parameters:
   *
   * - `consentFormId`: The ID of the consent form.
   *
   * - `name`: The name of the consent form.
   *
   * - `isActive`: A flag indicating whether the consent form is active.
   *
   * - `file`:
   *
   * - `description`: The description of the consent form.
   *
   * @return Success
   */
  UpdateConsentForm(params: ConsentFormsService.UpdateConsentFormParams): __Observable<ApiResponseConsentFormTemplate> {
    return this.UpdateConsentFormResponse(params).pipe(
      __map(_r => _r.body as ApiResponseConsentFormTemplate)
    );
  }
}

module ConsentFormsService {

  /**
   * Parameters for CreateConsentForm
   */
  export interface CreateConsentFormParams {

    /**
     * The name of the consent form.
     */
    name: string;
    file: Blob;

    /**
     * A boolean value indicating if the consent form is active.
     */
    isActive?: boolean;

    /**
     * The description of the consent form.
     */
    description?: string;
  }

  /**
   * Parameters for UpdateConsentForm
   */
  export interface UpdateConsentFormParams {

    /**
     * The ID of the consent form.
     */
    consentFormId: number;

    /**
     * The name of the consent form.
     */
    name?: string;

    /**
     * A flag indicating whether the consent form is active.
     */
    isActive?: boolean;
    file?: Blob;

    /**
     * The description of the consent form.
     */
    description?: string;
  }
}

export { ConsentFormsService }
