import { Injectable } from '@angular/core';
import { DEFAULT_PATIENT_FILE_SECTION } from '@symplast/app-constants';
import { SecurityService, SecurityUserDetails } from '@symplast/generated-clients/web-portal';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CurrentUser, ICurrentUser } from '../../shared/models/current-user.model';

type Mutable<T> = { -readonly [P in keyof T]: T[P] };

@Injectable({
    providedIn: 'root',
})
export class CurrentUserService {
    public readonly user$: Observable<CurrentUser | null>;

    private readonly _user$ = new BehaviorSubject<CurrentUser | null>(null);

    constructor(private readonly securityService: SecurityService) {
        this.user$ = this._user$.asObservable();
    }

    public get user(): CurrentUser | null {
        return this._user$.value;
    }

    public loadUser(): Observable<CurrentUser> {
        return this.securityService.CurrentUser().pipe(
            map((currentUser) => {
                if (!currentUser?.result || currentUser.statusCode !== 200) {
                    throw new Error('Error while loading user');
                }

                return this.saveUser(currentUser.result);
            }),
        );
    }

    public updateUser(updates: Mutable<Partial<ICurrentUser>>): void {
        const newUser = new CurrentUser({ ...this.user, ...updates });

        this._user$.next(newUser);
    }

    public removeUser(): void {
        this._user$.next(null);
    }

    private saveUser(userDetails: SecurityUserDetails): CurrentUser {
        const user = new CurrentUser({
            userId: userDetails.userId,
            title: userDetails.title,
            displayName: userDetails.displayName,
            mobilePhone: userDetails.mobilePhone,
            isMobilePhoneNumberConfirmed: userDetails.isMobilePhoneNumberConfirmed,
            userImageThumbnailUrl: userDetails.userImageThumbnailUrl,
            mobilePhoneRequestDisabledUntilUtc: userDetails.mobilePhoneRequestDisabledUntilUtc,
            role: userDetails.role.roleName,
            doseSpotId: userDetails.doseSpotId,
            globalUserName: userDetails.globalUserName,
            permissions: (userDetails.permissions || []).map(({ name }) => name),
            licenses: (userDetails.licenses || []).map(({ key }) => key),
            patientSection: userDetails.customSettings?.find(({ key }) => key === DEFAULT_PATIENT_FILE_SECTION)?.value,
            isAutoFileSharing: userDetails.isAutoFileSharing,
        });

        this._user$.next(user);

        return user;
    }
}
