/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableLocationShort } from '../models/api-response-ienumerable-location-short';
import { ApiResponseLocationDetailed } from '../models/api-response-location-detailed';
import { LocationRequest } from '../models/location-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseIEnumerableLocationType } from '../models/api-response-ienumerable-location-type';
@Injectable({
  providedIn: 'root',
})
class LocationsService extends __BaseService {
  static readonly GetLocationsListPath = '/Locations';
  static readonly CreateLocationPath = '/Locations';
  static readonly GetLocationByIdPath = '/Locations/{locationId}';
  static readonly UpdateLocationPath = '/Locations/{locationId}';
  static readonly DeleteLocationPath = '/Locations/{locationId}';
  static readonly GetLocationTypesListPath = '/Locations/Types';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets short locations list.
   * @param includeDeleted If true, include deleted locations.
   * @return Success
   */
  GetLocationsListResponse(includeDeleted?: boolean): __Observable<__StrictHttpResponse<ApiResponseIEnumerableLocationShort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeDeleted != null) __params = __params.set('includeDeleted', includeDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableLocationShort>;
      })
    );
  }
  /**
   * Gets short locations list.
   * @param includeDeleted If true, include deleted locations.
   * @return Success
   */
  GetLocationsList(includeDeleted?: boolean): __Observable<ApiResponseIEnumerableLocationShort> {
    return this.GetLocationsListResponse(includeDeleted).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableLocationShort)
    );
  }

  /**
   * Creates new location.
   * @param locationCreateRequest Location create request.
   * @return Success
   */
  CreateLocationResponse(locationCreateRequest?: LocationRequest): __Observable<__StrictHttpResponse<ApiResponseLocationDetailed>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = locationCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseLocationDetailed>;
      })
    );
  }
  /**
   * Creates new location.
   * @param locationCreateRequest Location create request.
   * @return Success
   */
  CreateLocation(locationCreateRequest?: LocationRequest): __Observable<ApiResponseLocationDetailed> {
    return this.CreateLocationResponse(locationCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseLocationDetailed)
    );
  }

  /**
   * Gets detailed location info.
   * @param locationId Location id.
   * @return Success
   */
  GetLocationByIdResponse(locationId: number): __Observable<__StrictHttpResponse<ApiResponseLocationDetailed>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Locations/${encodeURIComponent(String(locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseLocationDetailed>;
      })
    );
  }
  /**
   * Gets detailed location info.
   * @param locationId Location id.
   * @return Success
   */
  GetLocationById(locationId: number): __Observable<ApiResponseLocationDetailed> {
    return this.GetLocationByIdResponse(locationId).pipe(
      __map(_r => _r.body as ApiResponseLocationDetailed)
    );
  }

  /**
   * Updates location info.
   * @param params The `LocationsService.UpdateLocationParams` containing the following parameters:
   *
   * - `locationId`: Location id.
   *
   * - `locationUpdateRequest`: Location update request.
   *
   * @return Success
   */
  UpdateLocationResponse(params: LocationsService.UpdateLocationParams): __Observable<__StrictHttpResponse<ApiResponseLocationDetailed>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.locationUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Locations/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseLocationDetailed>;
      })
    );
  }
  /**
   * Updates location info.
   * @param params The `LocationsService.UpdateLocationParams` containing the following parameters:
   *
   * - `locationId`: Location id.
   *
   * - `locationUpdateRequest`: Location update request.
   *
   * @return Success
   */
  UpdateLocation(params: LocationsService.UpdateLocationParams): __Observable<ApiResponseLocationDetailed> {
    return this.UpdateLocationResponse(params).pipe(
      __map(_r => _r.body as ApiResponseLocationDetailed)
    );
  }

  /**
   * Deletes a location.
   * @param locationId Location id.
   * @return Success
   */
  DeleteLocationResponse(locationId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Locations/${encodeURIComponent(String(locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a location.
   * @param locationId Location id.
   * @return Success
   */
  DeleteLocation(locationId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteLocationResponse(locationId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets possible location types.
   * @return Success
   */
  GetLocationTypesListResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableLocationType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Locations/Types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableLocationType>;
      })
    );
  }
  /**
   * Gets possible location types.
   * @return Success
   */
  GetLocationTypesList(): __Observable<ApiResponseIEnumerableLocationType> {
    return this.GetLocationTypesListResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableLocationType)
    );
  }
}

module LocationsService {

  /**
   * Parameters for UpdateLocation
   */
  export interface UpdateLocationParams {

    /**
     * Location id.
     */
    locationId: number;

    /**
     * Location update request.
     */
    locationUpdateRequest?: LocationRequest;
  }
}

export { LocationsService }
