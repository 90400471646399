<div>
    <div class="header-modal">
        <div>
            <span *ngIf="isPhoneEmpty">Add Mobile Number</span>
            <span *ngIf="!isPhoneEmpty">Confirm Mobile Number</span>
        </div>
        <div *ngIf="!isPhoneEmpty">
            <mat-icon class="pointer" [mat-dialog-close]="false">close</mat-icon>
        </div>
    </div>
    <div mat-dialog-content class="center">
        <p>
            Your mobile number can be used as a backup password reset method. Please {{ isPhoneEmpty ? 'enter' : 'verify' }} your mobile
            phone number and select ‘Confirm’ to allow administrators to share a password reset link.
        </p>
        <form [formGroup]="phoneGroup">
            <mat-form-field class="full-width">
                <input matInput placeholder="Mobile Phone" formControlName="mobilePhone" mask="000-000-0000" />
                <mat-error>Mobile Phone is required</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="!isPhoneEmpty" mat-stroked-button class="button" (click)="cancel()">Cancel</button>
        <button *ngIf="isPhoneEmpty" mat-stroked-button class="button" (click)="delay()">Later</button>
        <button *ngIf="isPhoneEmpty" mat-flat-button color="warn" class="button" (click)="decline()">Decline</button>
        <button mat-flat-button color="primary" class="button" [disabled]="phoneGroup.invalid" (click)="confirm()" cdkFocusInitial>
            Confirm
        </button>
    </div>
</div>
