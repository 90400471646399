import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';

export class WysiwygNoImagesAddon implements WysiwygAddon {
    public name = 'noimages';

    public init(config: WysiwygEditorConfiguration): void {
        config.options = {
            ...config.options,
            paste_data_images: false,
            file_picker_types: '',
            images_file_types: '',
        };
    }
}
