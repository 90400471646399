import { Injectable } from '@angular/core';
import { LocationsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadLocations, ReloadLocations } from './locations.actions';
import { ILocationsStateModel } from './locations.model';

@State<ILocationsStateModel>({
    name: 'Locations',
    defaults: {
        locations: [],
        activeLocations: [],
        loading: false,
    },
})
@Injectable()
export class LocationsState {
    constructor(private locationsService: LocationsService) {}

    @Action(LoadLocations)
    public load(context: StateContext<ILocationsStateModel>, { refresh }: LoadLocations) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.locations.length) || refresh) {
            context.patchState({ loading: true });
            this.locationsService
                .GetLocationsList(false)
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    if (response.result) {
                        const locations = [...response.result].sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);

                        context.patchState({
                            locations: locations,
                            activeLocations: locations.filter((location) => location.isActive),
                        });
                    }
                });
        }
    }

    @Action(ReloadLocations)
    public reload(context: StateContext<ILocationsStateModel>) {
        context.dispatch(new LoadLocations(true));
    }
}
