import { Injectable, inject } from '@angular/core';
import { WebStorage } from './webstorage';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class CookieWebStorage extends WebStorage {
    private cookieService = inject(CookieService);
    private readonly domain = window.location.href.includes('localhost') ? 'localhost' : '.symplast.com';

    public getItem<T = any>(key: string): T | null {
        try {
            const item = this.cookieService.get(WebStorage.getKey(key));

            return item ? JSON.parse(item) : null;
        } catch (e) {
            console.error(`can't parse cookie storage element: ${key}`);

            return null;
        }
    }

    /**
     * @param expires Number of days until the cookies expires or an actual `Date`
     */
    public setItem<T = any>(key: string, value: T, expires?: number | Date): void {
        try {
            const item = JSON.stringify(value);

            this.cookieService.set(WebStorage.getKey(key), item, expires, '/', this.domain, true);
        } catch (e) {
            console.error(`can't set cookie storage element: ${key}, ${value}`);
        }
    }

    public removeItem(key: string): void {
        try {
            this.cookieService.delete(WebStorage.getKey(key), '/', this.domain);
        } catch (e) {
            console.error(`can't remove cookie storage element: ${key}`);
        }
    }
}
