<div class="wrapper" data-e2e="error-page">
    <div class="error-container">
        <h1 class="mat-h1">Something went wrong!</h1>
        <div>
            <a data-e2e="error-page__to-home-link" mat-raised-button (click)="onReturnToHome()">Return to Home </a>
        </div>
    </div>
    <div class="error-description">
        <p>{{ errorMessage }}</p>
    </div>
</div>
