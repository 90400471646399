/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PatientBasicsUpdateRequest } from '../models/patient-basics-update-request';
import { ApiResponsePatientBasics } from '../models/api-response-patient-basics';
import { ApiResponseICollectionPatientType } from '../models/api-response-icollection-patient-type';
import { ApiResponseICollectionEmploymentType } from '../models/api-response-icollection-employment-type';
import { ApiResponseICollectionMaritalStatus } from '../models/api-response-icollection-marital-status';
import { ApiResponseICollectionLanguage } from '../models/api-response-icollection-language';
import { ApiResponseICollectionEthnicity } from '../models/api-response-icollection-ethnicity';
import { ApiResponseICollectionRace } from '../models/api-response-icollection-race';
import { ApiResponseICollectionString } from '../models/api-response-icollection-string';
import { ApiResponseICollectionPatientReferralSource } from '../models/api-response-icollection-patient-referral-source';
import { PatientContactInfoUpdateRequest } from '../models/patient-contact-info-update-request';
import { ApiResponsePatientEmergencyContact } from '../models/api-response-patient-emergency-contact';
import { PatientEmergencyContactUpdateRequest } from '../models/patient-emergency-contact-update-request';
import { PatientEmploymentUpdateRequest } from '../models/patient-employment-update-request';
import { InsurancePolicyHolderDetailsUpdateRequest } from '../models/insurance-policy-holder-details-update-request';
import { ApiResponsePatientInsuranceDetails } from '../models/api-response-patient-insurance-details';
import { ApiResponseIEnumerablePatientInsuranceDetails } from '../models/api-response-ienumerable-patient-insurance-details';
import { PatientOtherDetailsUpdateRequest } from '../models/patient-other-details-update-request';
import { ApiResponseICollectionPatientDisplayNameInfo } from '../models/api-response-icollection-patient-display-name-info';
import { ApiResponseICollectionPatientCredit } from '../models/api-response-icollection-patient-credit';
import { ApiResponsePatientDetails } from '../models/api-response-patient-details';
import { ApiResponseGeneratePatientDetailsPdfResponse } from '../models/api-response-generate-patient-details-pdf-response';
import { ApiResponseICollectionPatient } from '../models/api-response-icollection-patient';
import { ApiResponsePatientDisplayNameInfo } from '../models/api-response-patient-display-name-info';
import { ApiResponsePatientFileResponse } from '../models/api-response-patient-file-response';
import { PatientReferralInformationUpdateRequest } from '../models/patient-referral-information-update-request';
import { ApiResponseIEnumerableStickyNote } from '../models/api-response-ienumerable-sticky-note';
import { StickyNoteUpdateRequest } from '../models/sticky-note-update-request';
import { ApiResponseIEnumerableStickyNoteLog } from '../models/api-response-ienumerable-sticky-note-log';
@Injectable({
  providedIn: 'root',
})
class PatientsService extends __BaseService {
  static readonly UpdatePatientPath = '/Patients/{patientId}/Basics';
  static readonly GetPatientBasicsPath = '/Patients/{patientId}/Basics';
  static readonly UpdatePatientImagePath = '/Patients/{patientId}/Image';
  static readonly GetPatientTypesPath = '/PatientTypes';
  static readonly GetEmploymentTypesPath = '/EmploymentTypes';
  static readonly GetMaritalStatusesPath = '/MaritalStatuses';
  static readonly GetLanguagesPath = '/Languages';
  static readonly GetEthnicitiesPath = '/Ethnicities';
  static readonly GetRacesPath = '/Races';
  static readonly GetGendersPath = '/Genders';
  static readonly GetPatientReferralSourcesPath = '/PatientReferralSources';
  static readonly UpdatePatientContactInfoPath = '/Patients/{patientId}/ContactInfo';
  static readonly UpdateEmergencyContactPath = '/Patients/{patientId}/EmergencyContact';
  static readonly UpdatePatientEmploymentPath = '/Patients/{patientId}/Employment';
  static readonly UpdatePatientInsuranceDetailsPath = '/Patients/InsuranceDetails/{insuredPartyId}';
  static readonly GetInsuredPartyByIdPath = '/Patients/InsuranceDetails/{insuredPartyId}';
  static readonly UpdateInsuranceDetailsStatusPath = '/Patients/InsuranceDetails/{insuredPartyId}/Status';
  static readonly CreateInsuranceDetailsPath = '/Patients/{patientId}/InsuranceDetails';
  static readonly GetInsuranceDetailsPath = '/Patients/{patientId}/InsuranceDetails';
  static readonly UpdatePatientOtherDetailsPath = '/Patients/{patientId}/OtherDetails';
  static readonly GetAvailablePatientCreditsPath = '/Patients/AvailablePatientCredits';
  static readonly GetAvailablePatientCreditsByPatientIdPath = '/Patients/{patientId}/AvailablePatientCredits';
  static readonly GetPatientDetailsPath = '/Patients/{patientId}';
  static readonly GetPatientDetailsPdfPath = '/Patients/{patientId}/Pdf';
  static readonly GetPatientsPath = '/Patients';
  static readonly GetShortPatientByIdPath = '/Patients/Short/{patientId}';
  static readonly GetShortPatientsPath = '/Patients/Short';
  static readonly ExportPatientFilePath = '/Patients/{patientId}/PatientFile';
  static readonly GetPatientFileProgressPath = '/Patients/{patientId}/PatientFileProgress';
  static readonly UpdatePatientReferralInfoPath = '/Patients/{patientId}/ReferralInfo';
  static readonly SendSmsInvitePath = '/Patients/{patientId}/Invitation';
  static readonly GetPatientStickyNotesPath = '/Patients/{patientId}/StickyNotes';
  static readonly UpdatePatientStickyNotesPath = '/Patients/{patientId}/StickyNotes';
  static readonly DeletePatientStickyNotesPath = '/Patients/{patientId}/StickyNotes';
  static readonly GetPatientStickyNoteLogsPath = '/Patients/{patientId}/StickyNotes/Logs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Updates patient information.
   * @param params The `PatientsService.UpdatePatientParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientResponse(params: PatientsService.UpdatePatientParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/Basics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates patient information.
   * @param params The `PatientsService.UpdatePatientParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatient(params: PatientsService.UpdatePatientParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get patient basic async.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientBasicsResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientBasics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}/Basics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientBasics>;
      })
    );
  }
  /**
   * The get patient basic async.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientBasics(patientId: number): __Observable<ApiResponsePatientBasics> {
    return this.GetPatientBasicsResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponsePatientBasics)
    );
  }

  /**
   * Updates patient image.
   * @param params The `PatientsService.UpdatePatientImageParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `file`:
   *
   * @return Created
   */
  UpdatePatientImageResponse(params: PatientsService.UpdatePatientImageParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/Image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates patient image.
   * @param params The `PatientsService.UpdatePatientImageParams` containing the following parameters:
   *
   * - `patientId`: The patient identifier.
   *
   * - `file`:
   *
   * @return Created
   */
  UpdatePatientImage(params: PatientsService.UpdatePatientImageParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientImageResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets list of patient types.
   * @return Success
   */
  GetPatientTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PatientTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPatientType>;
      })
    );
  }
  /**
   * Gets list of patient types.
   * @return Success
   */
  GetPatientTypes(): __Observable<ApiResponseICollectionPatientType> {
    return this.GetPatientTypesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionPatientType)
    );
  }

  /**
   * Gets list of employment types.
   * @return Success
   */
  GetEmploymentTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionEmploymentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/EmploymentTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionEmploymentType>;
      })
    );
  }
  /**
   * Gets list of employment types.
   * @return Success
   */
  GetEmploymentTypes(): __Observable<ApiResponseICollectionEmploymentType> {
    return this.GetEmploymentTypesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionEmploymentType)
    );
  }

  /**
   * Gets marital statuses information.
   * @return Success
   */
  GetMaritalStatusesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionMaritalStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/MaritalStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionMaritalStatus>;
      })
    );
  }
  /**
   * Gets marital statuses information.
   * @return Success
   */
  GetMaritalStatuses(): __Observable<ApiResponseICollectionMaritalStatus> {
    return this.GetMaritalStatusesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionMaritalStatus)
    );
  }

  /**
   * Gets languages information.
   * @return Success
   */
  GetLanguagesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionLanguage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Languages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionLanguage>;
      })
    );
  }
  /**
   * Gets languages information.
   * @return Success
   */
  GetLanguages(): __Observable<ApiResponseICollectionLanguage> {
    return this.GetLanguagesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionLanguage)
    );
  }

  /**
   * Gets list of ethnicities.
   * @return Success
   */
  GetEthnicitiesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionEthnicity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Ethnicities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionEthnicity>;
      })
    );
  }
  /**
   * Gets list of ethnicities.
   * @return Success
   */
  GetEthnicities(): __Observable<ApiResponseICollectionEthnicity> {
    return this.GetEthnicitiesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionEthnicity)
    );
  }

  /**
   * Gets list of races.
   * @return Success
   */
  GetRacesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionRace>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Races`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionRace>;
      })
    );
  }
  /**
   * Gets list of races.
   * @return Success
   */
  GetRaces(): __Observable<ApiResponseICollectionRace> {
    return this.GetRacesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionRace)
    );
  }

  /**
   * Gets genders information.
   * @return Success
   */
  GetGendersResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Genders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionString>;
      })
    );
  }
  /**
   * Gets genders information.
   * @return Success
   */
  GetGenders(): __Observable<ApiResponseICollectionString> {
    return this.GetGendersResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionString)
    );
  }

  /**
   * Gets referral sources information.
   * @return Success
   */
  GetPatientReferralSourcesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientReferralSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PatientReferralSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPatientReferralSource>;
      })
    );
  }
  /**
   * Gets referral sources information.
   * @return Success
   */
  GetPatientReferralSources(): __Observable<ApiResponseICollectionPatientReferralSource> {
    return this.GetPatientReferralSourcesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionPatientReferralSource)
    );
  }

  /**
   * Updates patient contact information.
   * @param params The `PatientsService.UpdatePatientContactInfoParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientContactInfoResponse(params: PatientsService.UpdatePatientContactInfoParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/ContactInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates patient contact information.
   * @param params The `PatientsService.UpdatePatientContactInfoParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientContactInfo(params: PatientsService.UpdatePatientContactInfoParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientContactInfoResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates patient emergency contact information.
   * @param params The `PatientsService.UpdateEmergencyContactParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdateEmergencyContactResponse(params: PatientsService.UpdateEmergencyContactParams): __Observable<__StrictHttpResponse<ApiResponsePatientEmergencyContact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/EmergencyContact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientEmergencyContact>;
      })
    );
  }
  /**
   * Updates patient emergency contact information.
   * @param params The `PatientsService.UpdateEmergencyContactParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdateEmergencyContact(params: PatientsService.UpdateEmergencyContactParams): __Observable<ApiResponsePatientEmergencyContact> {
    return this.UpdateEmergencyContactResponse(params).pipe(
      __map(_r => _r.body as ApiResponsePatientEmergencyContact)
    );
  }

  /**
   * Updates patient employment information.
   * @param params The `PatientsService.UpdatePatientEmploymentParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientEmploymentResponse(params: PatientsService.UpdatePatientEmploymentParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/Employment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates patient employment information.
   * @param params The `PatientsService.UpdatePatientEmploymentParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientEmployment(params: PatientsService.UpdatePatientEmploymentParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientEmploymentResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates patient insurance information.
   * @param params The `PatientsService.UpdatePatientInsuranceDetailsParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `insuredPartyId`: The insured party Id.
   *
   * @return Success
   */
  UpdatePatientInsuranceDetailsResponse(params: PatientsService.UpdatePatientInsuranceDetailsParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/InsuranceDetails/${encodeURIComponent(String(params.insuredPartyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates patient insurance information.
   * @param params The `PatientsService.UpdatePatientInsuranceDetailsParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `insuredPartyId`: The insured party Id.
   *
   * @return Success
   */
  UpdatePatientInsuranceDetails(params: PatientsService.UpdatePatientInsuranceDetailsParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientInsuranceDetailsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get insurance details by Insured Party Id.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  GetInsuredPartyByIdResponse(insuredPartyId: number): __Observable<__StrictHttpResponse<ApiResponsePatientInsuranceDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/InsuranceDetails/${encodeURIComponent(String(insuredPartyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientInsuranceDetails>;
      })
    );
  }
  /**
   * Get insurance details by Insured Party Id.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  GetInsuredPartyById(insuredPartyId: number): __Observable<ApiResponsePatientInsuranceDetails> {
    return this.GetInsuredPartyByIdResponse(insuredPartyId).pipe(
      __map(_r => _r.body as ApiResponsePatientInsuranceDetails)
    );
  }

  /**
   * The get patient insured parties.
   * @param params The `PatientsService.UpdateInsuranceDetailsStatusParams` containing the following parameters:
   *
   * - `isActive`: The value indicating if the policy is active or not.
   *
   * - `insuredPartyId`: The insured party id.
   *
   * @return Success
   */
  UpdateInsuranceDetailsStatusResponse(params: PatientsService.UpdateInsuranceDetailsStatusParams): __Observable<__StrictHttpResponse<ApiResponsePatientInsuranceDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/InsuranceDetails/${encodeURIComponent(String(params.insuredPartyId))}/Status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientInsuranceDetails>;
      })
    );
  }
  /**
   * The get patient insured parties.
   * @param params The `PatientsService.UpdateInsuranceDetailsStatusParams` containing the following parameters:
   *
   * - `isActive`: The value indicating if the policy is active or not.
   *
   * - `insuredPartyId`: The insured party id.
   *
   * @return Success
   */
  UpdateInsuranceDetailsStatus(params: PatientsService.UpdateInsuranceDetailsStatusParams): __Observable<ApiResponsePatientInsuranceDetails> {
    return this.UpdateInsuranceDetailsStatusResponse(params).pipe(
      __map(_r => _r.body as ApiResponsePatientInsuranceDetails)
    );
  }

  /**
   * Create an insurance details.
   * @param params The `PatientsService.CreateInsuranceDetailsParams` containing the following parameters:
   *
   * - `patientId`: The patient id.
   *
   * - `insuranceDetailsRequest`: The insurance details request.
   *
   * @return Success
   */
  CreateInsuranceDetailsResponse(params: PatientsService.CreateInsuranceDetailsParams): __Observable<__StrictHttpResponse<ApiResponsePatientInsuranceDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.insuranceDetailsRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/InsuranceDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientInsuranceDetails>;
      })
    );
  }
  /**
   * Create an insurance details.
   * @param params The `PatientsService.CreateInsuranceDetailsParams` containing the following parameters:
   *
   * - `patientId`: The patient id.
   *
   * - `insuranceDetailsRequest`: The insurance details request.
   *
   * @return Success
   */
  CreateInsuranceDetails(params: PatientsService.CreateInsuranceDetailsParams): __Observable<ApiResponsePatientInsuranceDetails> {
    return this.CreateInsuranceDetailsResponse(params).pipe(
      __map(_r => _r.body as ApiResponsePatientInsuranceDetails)
    );
  }

  /**
   * Get insurance details information from a patient.
   * @param params The `PatientsService.GetInsuranceDetailsParams` containing the following parameters:
   *
   * - `patientId`: The patient id.
   *
   * - `onlyActive`: Get only active insured parties.
   *
   * - `insuranceCompanyId`: The insurance company Id.
   *
   * @return Success
   */
  GetInsuranceDetailsResponse(params: PatientsService.GetInsuranceDetailsParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientInsuranceDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.onlyActive != null) __params = __params.set('onlyActive', params.onlyActive.toString());
    if (params.insuranceCompanyId != null) __params = __params.set('insuranceCompanyId', params.insuranceCompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/InsuranceDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientInsuranceDetails>;
      })
    );
  }
  /**
   * Get insurance details information from a patient.
   * @param params The `PatientsService.GetInsuranceDetailsParams` containing the following parameters:
   *
   * - `patientId`: The patient id.
   *
   * - `onlyActive`: Get only active insured parties.
   *
   * - `insuranceCompanyId`: The insurance company Id.
   *
   * @return Success
   */
  GetInsuranceDetails(params: PatientsService.GetInsuranceDetailsParams): __Observable<ApiResponseIEnumerablePatientInsuranceDetails> {
    return this.GetInsuranceDetailsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerablePatientInsuranceDetails)
    );
  }

  /**
   * Updates patient other details information.
   * @param params The `PatientsService.UpdatePatientOtherDetailsParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientOtherDetailsResponse(params: PatientsService.UpdatePatientOtherDetailsParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/OtherDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates patient other details information.
   * @param params The `PatientsService.UpdatePatientOtherDetailsParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientOtherDetails(params: PatientsService.UpdatePatientOtherDetailsParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientOtherDetailsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the list of patients having available patient credit
   * @param params The `PatientsService.GetAvailablePatientCreditsParams` containing the following parameters:
   *
   * - `invoiceItemIds`: The invoice item ids.
   *
   * - `insurancePatientCreditOnly`: The insurance patient credit only flag.
   *
   * @return Success
   */
  GetAvailablePatientCreditsResponse(params: PatientsService.GetAvailablePatientCreditsParams): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientDisplayNameInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.invoiceItemIds || []).forEach(val => {if (val != null) __params = __params.append('invoiceItemIds', val.toString())});
    if (params.insurancePatientCreditOnly != null) __params = __params.set('insurancePatientCreditOnly', params.insurancePatientCreditOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/AvailablePatientCredits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPatientDisplayNameInfo>;
      })
    );
  }
  /**
   * Gets the list of patients having available patient credit
   * @param params The `PatientsService.GetAvailablePatientCreditsParams` containing the following parameters:
   *
   * - `invoiceItemIds`: The invoice item ids.
   *
   * - `insurancePatientCreditOnly`: The insurance patient credit only flag.
   *
   * @return Success
   */
  GetAvailablePatientCredits(params: PatientsService.GetAvailablePatientCreditsParams): __Observable<ApiResponseICollectionPatientDisplayNameInfo> {
    return this.GetAvailablePatientCreditsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionPatientDisplayNameInfo)
    );
  }

  /**
   * Gets the list of patients having available patient credit
   * @param params The `PatientsService.GetAvailablePatientCreditsByPatientIdParams` containing the following parameters:
   *
   * - `patientId`: The patient Id.
   *
   * - `insurancePatientCreditOnly`: The insurance patient credit only flag.
   *
   * @return Success
   */
  GetAvailablePatientCreditsByPatientIdResponse(params: PatientsService.GetAvailablePatientCreditsByPatientIdParams): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientCredit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.insurancePatientCreditOnly != null) __params = __params.set('insurancePatientCreditOnly', params.insurancePatientCreditOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/AvailablePatientCredits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPatientCredit>;
      })
    );
  }
  /**
   * Gets the list of patients having available patient credit
   * @param params The `PatientsService.GetAvailablePatientCreditsByPatientIdParams` containing the following parameters:
   *
   * - `patientId`: The patient Id.
   *
   * - `insurancePatientCreditOnly`: The insurance patient credit only flag.
   *
   * @return Success
   */
  GetAvailablePatientCreditsByPatientId(params: PatientsService.GetAvailablePatientCreditsByPatientIdParams): __Observable<ApiResponseICollectionPatientCredit> {
    return this.GetAvailablePatientCreditsByPatientIdResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionPatientCredit)
    );
  }

  /**
   * The get patient details async.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientDetailsResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientDetails>;
      })
    );
  }
  /**
   * The get patient details async.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientDetails(patientId: number): __Observable<ApiResponsePatientDetails> {
    return this.GetPatientDetailsResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponsePatientDetails)
    );
  }

  /**
   * The get patient details PDF.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientDetailsPdfResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponseGeneratePatientDetailsPdfResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}/Pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseGeneratePatientDetailsPdfResponse>;
      })
    );
  }
  /**
   * The get patient details PDF.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientDetailsPdf(patientId: number): __Observable<ApiResponseGeneratePatientDetailsPdfResponse> {
    return this.GetPatientDetailsPdfResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponseGeneratePatientDetailsPdfResponse)
    );
  }

  /**
   * Gets patients information.
   * @param params The `PatientsService.GetPatientsParams` containing the following parameters:
   *
   * - `Sorting`: Gets or sets the sorting.
   *
   * - `SortAscending`: Gets or sets a value indicating whether [sort ascending].
   *
   * - `Query`: Gets or sets the query.
   *
   * - `PatientIds`: Gets or sets the patient ids.
   *
   * - `PageSize`: Gets or sets the page size.
   *
   * - `PageIndex`: Gets or sets the page index.
   *
   * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
   *
   * - `IncludeOnlyActive`: Gets or sets a value indicating whether to include only active patients.
   *
   * - `GlobalUserId`: Gets or sets the global user identifier.
   *
   * @return Success
   */
  GetPatientsResponse(params: PatientsService.GetPatientsParams): __Observable<__StrictHttpResponse<ApiResponseICollectionPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PatientIds != null) __params = __params.set('PatientIds', params.PatientIds.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageIndex != null) __params = __params.set('PageIndex', params.PageIndex.toString());
    if (params.ModifiedAfterTimeInUtc != null) __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
    if (params.IncludeOnlyActive != null) __params = __params.set('IncludeOnlyActive', params.IncludeOnlyActive.toString());
    if (params.GlobalUserId != null) __params = __params.set('GlobalUserId', params.GlobalUserId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPatient>;
      })
    );
  }
  /**
   * Gets patients information.
   * @param params The `PatientsService.GetPatientsParams` containing the following parameters:
   *
   * - `Sorting`: Gets or sets the sorting.
   *
   * - `SortAscending`: Gets or sets a value indicating whether [sort ascending].
   *
   * - `Query`: Gets or sets the query.
   *
   * - `PatientIds`: Gets or sets the patient ids.
   *
   * - `PageSize`: Gets or sets the page size.
   *
   * - `PageIndex`: Gets or sets the page index.
   *
   * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
   *
   * - `IncludeOnlyActive`: Gets or sets a value indicating whether to include only active patients.
   *
   * - `GlobalUserId`: Gets or sets the global user identifier.
   *
   * @return Success
   */
  GetPatients(params: PatientsService.GetPatientsParams): __Observable<ApiResponseICollectionPatient> {
    return this.GetPatientsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionPatient)
    );
  }

  /**
   * Get short patient model by id
   * @param patientId The patient id.
   * @return Success
   */
  GetShortPatientByIdResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientDisplayNameInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/Short/${encodeURIComponent(String(patientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientDisplayNameInfo>;
      })
    );
  }
  /**
   * Get short patient model by id
   * @param patientId The patient id.
   * @return Success
   */
  GetShortPatientById(patientId: number): __Observable<ApiResponsePatientDisplayNameInfo> {
    return this.GetShortPatientByIdResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponsePatientDisplayNameInfo)
    );
  }

  /**
   * Get short patient models
   * @param params The `PatientsService.GetShortPatientsParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `insuranceCompanyId`: The insurance Company Id.
   *
   * - `includeOnlyActive`: Include only active
   *
   * @return Success
   */
  GetShortPatientsResponse(params: PatientsService.GetShortPatientsParams): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientDisplayNameInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.insuranceCompanyId != null) __params = __params.set('insuranceCompanyId', params.insuranceCompanyId.toString());
    if (params.includeOnlyActive != null) __params = __params.set('includeOnlyActive', params.includeOnlyActive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/Short`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPatientDisplayNameInfo>;
      })
    );
  }
  /**
   * Get short patient models
   * @param params The `PatientsService.GetShortPatientsParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `insuranceCompanyId`: The insurance Company Id.
   *
   * - `includeOnlyActive`: Include only active
   *
   * @return Success
   */
  GetShortPatients(params: PatientsService.GetShortPatientsParams): __Observable<ApiResponseICollectionPatientDisplayNameInfo> {
    return this.GetShortPatientsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionPatientDisplayNameInfo)
    );
  }

  /**
   * Exports the Patient File inside a Zip File into the Patient's Documents section.
   * @param params The `PatientsService.ExportPatientFileParams` containing the following parameters:
   *
   * - `patientId`: The patient Id.
   *
   * - `patientFileOptions`: The options to export.
   *
   * @return Success
   */
  ExportPatientFileResponse(params: PatientsService.ExportPatientFileParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.patientFileOptions;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/PatientFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Exports the Patient File inside a Zip File into the Patient's Documents section.
   * @param params The `PatientsService.ExportPatientFileParams` containing the following parameters:
   *
   * - `patientId`: The patient Id.
   *
   * - `patientFileOptions`: The options to export.
   *
   * @return Success
   */
  ExportPatientFile(params: PatientsService.ExportPatientFileParams): __Observable<ApiResponseOkResponse> {
    return this.ExportPatientFileResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the current progress of the Patient File being exported, if any.
   * @param patientId The patient Id.
   * @return Success
   */
  GetPatientFileProgressResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}/PatientFileProgress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePatientFileResponse>;
      })
    );
  }
  /**
   * Gets the current progress of the Patient File being exported, if any.
   * @param patientId The patient Id.
   * @return Success
   */
  GetPatientFileProgress(patientId: number): __Observable<ApiResponsePatientFileResponse> {
    return this.GetPatientFileProgressResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponsePatientFileResponse)
    );
  }

  /**
   * Updates patient referral information.
   * @param params The `PatientsService.UpdatePatientReferralInfoParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientReferralInfoResponse(params: PatientsService.UpdatePatientReferralInfoParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/ReferralInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates patient referral information.
   * @param params The `PatientsService.UpdatePatientReferralInfoParams` containing the following parameters:
   *
   * - `request`: The request.
   *
   * - `patientId`: The patient identifier.
   *
   * @return Success
   */
  UpdatePatientReferralInfo(params: PatientsService.UpdatePatientReferralInfoParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientReferralInfoResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The create SMS code async.
   * @param patientId The patient id.
   * @return Success
   */
  SendSmsInviteResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}/Invitation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The create SMS code async.
   * @param patientId The patient id.
   * @return Success
   */
  SendSmsInvite(patientId: number): __Observable<ApiResponseOkResponse> {
    return this.SendSmsInviteResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get patient sticky notes.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientStickyNotesResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableStickyNote>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}/StickyNotes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableStickyNote>;
      })
    );
  }
  /**
   * The get patient sticky notes.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientStickyNotes(patientId: number): __Observable<ApiResponseIEnumerableStickyNote> {
    return this.GetPatientStickyNotesResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableStickyNote)
    );
  }

  /**
   * The update patient sticky notes.
   * @param params The `PatientsService.UpdatePatientStickyNotesParams` containing the following parameters:
   *
   * - `patientId`: The patient id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdatePatientStickyNotesResponse(params: PatientsService.UpdatePatientStickyNotesParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Patients/${encodeURIComponent(String(params.patientId))}/StickyNotes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update patient sticky notes.
   * @param params The `PatientsService.UpdatePatientStickyNotesParams` containing the following parameters:
   *
   * - `patientId`: The patient id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdatePatientStickyNotes(params: PatientsService.UpdatePatientStickyNotesParams): __Observable<ApiResponseOkResponse> {
    return this.UpdatePatientStickyNotesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The delete patient sticky notes.
   * @param patientId The patient id.
   * @return Success
   */
  DeletePatientStickyNotesResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}/StickyNotes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The delete patient sticky notes.
   * @param patientId The patient id.
   * @return Success
   */
  DeletePatientStickyNotes(patientId: number): __Observable<ApiResponseOkResponse> {
    return this.DeletePatientStickyNotesResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get patient sticky note logs.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientStickyNoteLogsResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableStickyNoteLog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Patients/${encodeURIComponent(String(patientId))}/StickyNotes/Logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableStickyNoteLog>;
      })
    );
  }
  /**
   * The get patient sticky note logs.
   * @param patientId The patient id.
   * @return Success
   */
  GetPatientStickyNoteLogs(patientId: number): __Observable<ApiResponseIEnumerableStickyNoteLog> {
    return this.GetPatientStickyNoteLogsResponse(patientId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableStickyNoteLog)
    );
  }
}

module PatientsService {

  /**
   * Parameters for UpdatePatient
   */
  export interface UpdatePatientParams {

    /**
     * The request.
     */
    request: PatientBasicsUpdateRequest;

    /**
     * The patient identifier.
     */
    patientId: number;
  }

  /**
   * Parameters for UpdatePatientImage
   */
  export interface UpdatePatientImageParams {

    /**
     * The patient identifier.
     */
    patientId: number;
    file?: Blob;
  }

  /**
   * Parameters for UpdatePatientContactInfo
   */
  export interface UpdatePatientContactInfoParams {

    /**
     * The request.
     */
    request: PatientContactInfoUpdateRequest;

    /**
     * The patient identifier.
     */
    patientId: number;
  }

  /**
   * Parameters for UpdateEmergencyContact
   */
  export interface UpdateEmergencyContactParams {

    /**
     * The request.
     */
    request: PatientEmergencyContactUpdateRequest;

    /**
     * The patient identifier.
     */
    patientId: number;
  }

  /**
   * Parameters for UpdatePatientEmployment
   */
  export interface UpdatePatientEmploymentParams {

    /**
     * The request.
     */
    request: PatientEmploymentUpdateRequest;

    /**
     * The patient identifier.
     */
    patientId: number;
  }

  /**
   * Parameters for UpdatePatientInsuranceDetails
   */
  export interface UpdatePatientInsuranceDetailsParams {

    /**
     * The request.
     */
    request: InsurancePolicyHolderDetailsUpdateRequest;

    /**
     * The insured party Id.
     */
    insuredPartyId: number;
  }

  /**
   * Parameters for UpdateInsuranceDetailsStatus
   */
  export interface UpdateInsuranceDetailsStatusParams {

    /**
     * The value indicating if the policy is active or not.
     */
    isActive: boolean;

    /**
     * The insured party id.
     */
    insuredPartyId: number;
  }

  /**
   * Parameters for CreateInsuranceDetails
   */
  export interface CreateInsuranceDetailsParams {

    /**
     * The patient id.
     */
    patientId: number;

    /**
     * The insurance details request.
     */
    insuranceDetailsRequest?: InsurancePolicyHolderDetailsUpdateRequest;
  }

  /**
   * Parameters for GetInsuranceDetails
   */
  export interface GetInsuranceDetailsParams {

    /**
     * The patient id.
     */
    patientId: number;

    /**
     * Get only active insured parties.
     */
    onlyActive?: boolean;

    /**
     * The insurance company Id.
     */
    insuranceCompanyId?: number;
  }

  /**
   * Parameters for UpdatePatientOtherDetails
   */
  export interface UpdatePatientOtherDetailsParams {

    /**
     * The request.
     */
    request: PatientOtherDetailsUpdateRequest;

    /**
     * The patient identifier.
     */
    patientId: number;
  }

  /**
   * Parameters for GetAvailablePatientCredits
   */
  export interface GetAvailablePatientCreditsParams {

    /**
     * The invoice item ids.
     */
    invoiceItemIds?: Array<number>;

    /**
     * The insurance patient credit only flag.
     */
    insurancePatientCreditOnly?: boolean;
  }

  /**
   * Parameters for GetAvailablePatientCreditsByPatientId
   */
  export interface GetAvailablePatientCreditsByPatientIdParams {

    /**
     * The patient Id.
     */
    patientId: number;

    /**
     * The insurance patient credit only flag.
     */
    insurancePatientCreditOnly?: boolean;
  }

  /**
   * Parameters for GetPatients
   */
  export interface GetPatientsParams {

    /**
     * Gets or sets the sorting.
     */
    Sorting?: 'Id' | 'FullName' | 'Rank';

    /**
     * Gets or sets a value indicating whether [sort ascending].
     */
    SortAscending?: boolean;

    /**
     * Gets or sets the query.
     */
    Query?: string;

    /**
     * Gets or sets the patient ids.
     */
    PatientIds?: string;

    /**
     * Gets or sets the page size.
     */
    PageSize?: number;

    /**
     * Gets or sets the page index.
     */
    PageIndex?: number;

    /**
     * Gets or sets the modified after time in UTC.
     */
    ModifiedAfterTimeInUtc?: string;

    /**
     * Gets or sets a value indicating whether to include only active patients.
     */
    IncludeOnlyActive?: boolean;

    /**
     * Gets or sets the global user identifier.
     */
    GlobalUserId?: string;
  }

  /**
   * Parameters for GetShortPatients
   */
  export interface GetShortPatientsParams {

    /**
     * The query.
     */
    query?: string;

    /**
     * The insurance Company Id.
     */
    insuranceCompanyId?: number;

    /**
     * Include only active
     */
    includeOnlyActive?: boolean;
  }

  /**
   * Parameters for ExportPatientFile
   */
  export interface ExportPatientFileParams {

    /**
     * The patient Id.
     */
    patientId: number;

    /**
     * The options to export.
     */
    patientFileOptions?: Array<'PatientDetails' | 'EhrNotes' | 'Multimedia' | 'HealthHistory' | 'Documents' | 'LegalForms' | 'Appointments' | 'ActivityNotes' | 'Conversations' | 'Financials' | 'InsuranceCases' | 'EPrescriptions'>;
  }

  /**
   * Parameters for UpdatePatientReferralInfo
   */
  export interface UpdatePatientReferralInfoParams {

    /**
     * The request.
     */
    request: PatientReferralInformationUpdateRequest;

    /**
     * The patient identifier.
     */
    patientId: number;
  }

  /**
   * Parameters for UpdatePatientStickyNotes
   */
  export interface UpdatePatientStickyNotesParams {

    /**
     * The patient id.
     */
    patientId: number;

    /**
     * The update request.
     */
    updateRequest?: StickyNoteUpdateRequest;
  }
}

export { PatientsService }
