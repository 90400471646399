/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableCountry } from '../models/api-response-ienumerable-country';
import { ApiResponseIEnumerableState } from '../models/api-response-ienumerable-state';
import { ApiResponseIEnumerableCity } from '../models/api-response-ienumerable-city';
import { ApiResponseIEnumerableTimeZone } from '../models/api-response-ienumerable-time-zone';
@Injectable({
  providedIn: 'root',
})
class AddressService extends __BaseService {
  static readonly GetCountriesListPath = '/Countries';
  static readonly GetStatesListPath = '/States';
  static readonly GetCitiesListPath = '/Cities';
  static readonly GetTimeZonesListPath = '/TimeZones';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets countries list.
   * @return Success
   */
  GetCountriesListResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCountry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCountry>;
      })
    );
  }
  /**
   * Gets countries list.
   * @return Success
   */
  GetCountriesList(): __Observable<ApiResponseIEnumerableCountry> {
    return this.GetCountriesListResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCountry)
    );
  }

  /**
   * Gets states list.
   * @param countryId If specified, filters by country.
   * @return Success
   */
  GetStatesListResponse(countryId?: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (countryId != null) __params = __params.set('countryId', countryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/States`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableState>;
      })
    );
  }
  /**
   * Gets states list.
   * @param countryId If specified, filters by country.
   * @return Success
   */
  GetStatesList(countryId?: number): __Observable<ApiResponseIEnumerableState> {
    return this.GetStatesListResponse(countryId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableState)
    );
  }

  /**
   * Gets cities list with ZIP codes.
   * @param params The `AddressService.GetCitiesListParams` containing the following parameters:
   *
   * - `zipCode`: If specified, filters by ZIP code.
   *
   * - `stateId`: If specified, filters by state.
   *
   * @return Success
   */
  GetCitiesListResponse(params: AddressService.GetCitiesListParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.zipCode != null) __params = __params.set('zipCode', params.zipCode.toString());
    if (params.stateId != null) __params = __params.set('stateId', params.stateId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Cities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCity>;
      })
    );
  }
  /**
   * Gets cities list with ZIP codes.
   * @param params The `AddressService.GetCitiesListParams` containing the following parameters:
   *
   * - `zipCode`: If specified, filters by ZIP code.
   *
   * - `stateId`: If specified, filters by state.
   *
   * @return Success
   */
  GetCitiesList(params: AddressService.GetCitiesListParams): __Observable<ApiResponseIEnumerableCity> {
    return this.GetCitiesListResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCity)
    );
  }

  /**
   * Gets time zones list.
   * @return Success
   */
  GetTimeZonesListResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTimeZone>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/TimeZones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTimeZone>;
      })
    );
  }
  /**
   * Gets time zones list.
   * @return Success
   */
  GetTimeZonesList(): __Observable<ApiResponseIEnumerableTimeZone> {
    return this.GetTimeZonesListResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTimeZone)
    );
  }
}

module AddressService {

  /**
   * Parameters for GetCitiesList
   */
  export interface GetCitiesListParams {

    /**
     * If specified, filters by ZIP code.
     */
    zipCode?: string;

    /**
     * If specified, filters by state.
     */
    stateId?: number;
  }
}

export { AddressService }
