import { Editor, EditorOptions, Plugin } from 'tinymce';
import { WysiwygAddon } from './addons/wysiwyg.addon';

export type InitCallback = (editor: Editor) => void;
export type PluginInitCallback = (editor: Editor, plugin: Plugin) => void;

const CONTENT_STYLE = `
    body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:14px }
    .symplast-tinymce-noneditable {
        border-radius: 3px;
        padding: 2px;
        box-sizing: border-box;
        background-color: #fce28d;
    }
`;

export abstract class WysiwygEditorConfiguration {
    /**
     * Editor instance
     */
    public editor!: Editor;

    /**
     * Available features for toolbar
     * @see https://www.tiny.cloud/docs/tinymce/6/available-toolbar-buttons/
     */
    public abstract toolbar?: EditorOptions['toolbar'];

    /**
     * Available plugins
     * @see https://www.tiny.cloud/docs/tinymce/6/plugins/
     */
    public abstract plugins?: EditorOptions['plugins'];

    /**
     * Other options for current TinyMCE instance
     * @see https://www.tiny.cloud/docs/tinymce/6/editor-important-options/
     */
    public abstract options?: Partial<EditorOptions>;

    private readonly addons = new Map<string, WysiwygAddon>();

    /**
     * Required options for all Symplast editor instances (by design)
     */
    private readonly requiredOptions: Partial<EditorOptions> = {
        // `setup` is called before the editor instance is fully initialized and is used for configuring the editor
        setup: (editor: Editor) => {
            this.editor = editor;
            this.setupCallbacks.forEach((callback) => callback(editor));
        },
        // `init_instance_callback` is called after the editor is fully initialized and is used for executing actions that require the editor to be ready
        init_instance_callback: (editor: Editor) => {
            this.initializeCallbacks.forEach((callback) => callback(editor));

            Object.keys(editor.plugins).forEach((pluginKey) => {
                if (this.pluginsInitCallbacks.has(pluginKey)) {
                    const callbacks = this.pluginsInitCallbacks.get(pluginKey) as PluginInitCallback[];

                    callbacks.forEach((callback) => callback(editor, editor.plugins[pluginKey]));
                }
            });
        },
        menubar: false,
        statusbar: false,
        promotion: false,
        width: '100%',
        height: '100%',
        noneditable_class: 'symplast-tinymce-noneditable',
        content_style: CONTENT_STYLE,
    };

    private readonly initializeCallbacks: Array<InitCallback> = [];
    private readonly setupCallbacks: Array<InitCallback> = [];
    private readonly pluginsInitCallbacks: Map<string, Array<PluginInitCallback>> = new Map();

    constructor(protected extraOptions?: Partial<EditorOptions>) {}

    public get fullOptions(): EditorOptions {
        return {
            toolbar: this.toolbar,
            plugins: this.plugins || [],
            ...this.requiredOptions,
            ...(this.options || []),
            ...(this.extraOptions || []),
        } as EditorOptions;
    }

    public registerSetupCallback(callback: InitCallback): void {
        this.setupCallbacks.push(callback);
    }

    public registerInitCallback(callback: InitCallback): void {
        this.initializeCallbacks.push(callback);
    }

    public registerPluginInitCallback(plugin: string, callback: PluginInitCallback): void {
        const callbacks = this.pluginsInitCallbacks.get(plugin);

        if (callbacks) {
            callbacks.push(callback);

            return;
        }

        this.pluginsInitCallbacks.set(plugin, [callback]);
    }

    public getAddon<T extends WysiwygAddon>(name: string): T | undefined {
        return this.addons.get(name) as T;
    }

    public registerAddon(addon: WysiwygAddon): void {
        this.addons.set(addon.name, addon);
        addon.init(this);
    }
}
