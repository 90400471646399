/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIListSimpaySettings } from '../models/api-response-ilist-simpay-settings';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { SimpaySettingsSaveRequest } from '../models/simpay-settings-save-request';
import { SimpaySettingsUpdateRequest } from '../models/simpay-settings-update-request';
import { ApiResponseIListSimpayPaymentSurchargeSettings } from '../models/api-response-ilist-simpay-payment-surcharge-settings';
import { SurchargeSettingsUpdateRequest } from '../models/surcharge-settings-update-request';
import { ApiResponseString } from '../models/api-response-string';
import { ApiResponseEntityExistingStatus } from '../models/api-response-entity-existing-status';
@Injectable({
  providedIn: 'root',
})
class SimpayService extends __BaseService {
  static readonly GetSimpaySettingsPath = '/Simpay/Settings';
  static readonly SaveSimpaySettingsPath = '/Simpay/Settings';
  static readonly UpdateSimpaySettingsPath = '/Simpay/Settings';
  static readonly GetSimpayPaymentSurchargeSettingsPath = '/Simpay/Settings/Surcharges/Payments';
  static readonly UpdateSimpayPaymentSurchargeSettingsPath = '/Simpay/Settings/Surcharges/Payments';
  static readonly GetSimpaySupportPhoneNumberPath = '/Simpay/SimpaySupportPhoneNumber';
  static readonly ValidateKeysPath = '/Simpay/ValidateKeys';
  static readonly PaymentModeNameExistsPath = '/Simpay/PaymentModeNameExists';
  static readonly MerchantAccountNameExistsPath = '/Simpay/MerchantAccountNameExists';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get Simpay settings.
   * @param paymentModeId The payment Mode Id.
   * @return Success
   */
  GetSimpaySettingsResponse(paymentModeId?: number): __Observable<__StrictHttpResponse<ApiResponseIListSimpaySettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (paymentModeId != null) __params = __params.set('paymentModeId', paymentModeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Simpay/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIListSimpaySettings>;
      })
    );
  }
  /**
   * The get Simpay settings.
   * @param paymentModeId The payment Mode Id.
   * @return Success
   */
  GetSimpaySettings(paymentModeId?: number): __Observable<ApiResponseIListSimpaySettings> {
    return this.GetSimpaySettingsResponse(paymentModeId).pipe(
      __map(_r => _r.body as ApiResponseIListSimpaySettings)
    );
  }

  /**
   * The save settings.
   * @param request The save request.
   * @return Success
   */
  SaveSimpaySettingsResponse(request?: SimpaySettingsSaveRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Simpay/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The save settings.
   * @param request The save request.
   * @return Success
   */
  SaveSimpaySettings(request?: SimpaySettingsSaveRequest): __Observable<ApiResponseOkResponse> {
    return this.SaveSimpaySettingsResponse(request).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The update settings.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateSimpaySettingsResponse(updateRequest?: SimpaySettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Simpay/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update settings.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateSimpaySettings(updateRequest?: SimpaySettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateSimpaySettingsResponse(updateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get Simpay payment surcharge settings.
   * @param paymentModeId The payment Mode Id.
   * @return Success
   */
  GetSimpayPaymentSurchargeSettingsResponse(paymentModeId?: number): __Observable<__StrictHttpResponse<ApiResponseIListSimpayPaymentSurchargeSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (paymentModeId != null) __params = __params.set('paymentModeId', paymentModeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Simpay/Settings/Surcharges/Payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIListSimpayPaymentSurchargeSettings>;
      })
    );
  }
  /**
   * The get Simpay payment surcharge settings.
   * @param paymentModeId The payment Mode Id.
   * @return Success
   */
  GetSimpayPaymentSurchargeSettings(paymentModeId?: number): __Observable<ApiResponseIListSimpayPaymentSurchargeSettings> {
    return this.GetSimpayPaymentSurchargeSettingsResponse(paymentModeId).pipe(
      __map(_r => _r.body as ApiResponseIListSimpayPaymentSurchargeSettings)
    );
  }

  /**
   * The update surcharge settings for payments.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateSimpayPaymentSurchargeSettingsResponse(updateRequest?: SurchargeSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = updateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Simpay/Settings/Surcharges/Payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update surcharge settings for payments.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateSimpayPaymentSurchargeSettings(updateRequest?: SurchargeSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateSimpayPaymentSurchargeSettingsResponse(updateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the Simpay support phone number.
   * @return Success
   */
  GetSimpaySupportPhoneNumberResponse(): __Observable<__StrictHttpResponse<ApiResponseString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Simpay/SimpaySupportPhoneNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseString>;
      })
    );
  }
  /**
   * Gets the Simpay support phone number.
   * @return Success
   */
  GetSimpaySupportPhoneNumber(): __Observable<ApiResponseString> {
    return this.GetSimpaySupportPhoneNumberResponse().pipe(
      __map(_r => _r.body as ApiResponseString)
    );
  }

  /**
   * The validation of simpay keys.
   * @param token The token containing keys.
   * @return Success
   */
  ValidateKeysResponse(token?: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Simpay/ValidateKeys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The validation of simpay keys.
   * @param token The token containing keys.
   * @return Success
   */
  ValidateKeys(token?: string): __Observable<ApiResponseOkResponse> {
    return this.ValidateKeysResponse(token).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Check if payment mode name exists.
   * @param paymentModeName The payment mode name.
   * @return Success
   */
  PaymentModeNameExistsResponse(paymentModeName?: string): __Observable<__StrictHttpResponse<ApiResponseEntityExistingStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (paymentModeName != null) __params = __params.set('paymentModeName', paymentModeName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Simpay/PaymentModeNameExists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEntityExistingStatus>;
      })
    );
  }
  /**
   * Check if payment mode name exists.
   * @param paymentModeName The payment mode name.
   * @return Success
   */
  PaymentModeNameExists(paymentModeName?: string): __Observable<ApiResponseEntityExistingStatus> {
    return this.PaymentModeNameExistsResponse(paymentModeName).pipe(
      __map(_r => _r.body as ApiResponseEntityExistingStatus)
    );
  }

  /**
   * Check if merchant account is Exists.
   * @param merchantAccountName The merchant account name.
   * @return Success
   */
  MerchantAccountNameExistsResponse(merchantAccountName?: string): __Observable<__StrictHttpResponse<ApiResponseEntityExistingStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (merchantAccountName != null) __params = __params.set('merchantAccountName', merchantAccountName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Simpay/MerchantAccountNameExists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEntityExistingStatus>;
      })
    );
  }
  /**
   * Check if merchant account is Exists.
   * @param merchantAccountName The merchant account name.
   * @return Success
   */
  MerchantAccountNameExists(merchantAccountName?: string): __Observable<ApiResponseEntityExistingStatus> {
    return this.MerchantAccountNameExistsResponse(merchantAccountName).pipe(
      __map(_r => _r.body as ApiResponseEntityExistingStatus)
    );
  }
}

module SimpayService {
}

export { SimpayService }
