import { Injectable } from '@angular/core';
import { UserDictionariesService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadUserRoles, ReloadUserRoles } from './user-roles.actions';
import { IUserRolesStateModel } from './user-roles.model';

@State<IUserRolesStateModel>({
    name: 'UserRoles',
    defaults: {
        userRoles: [],
        loading: false,
    },
})
@Injectable()
export class UserRolesState {
    constructor(private userDictionaryService: UserDictionariesService) {}

    @Action(LoadUserRoles)
    public load(context: StateContext<IUserRolesStateModel>, { refresh }: LoadUserRoles) {
        if (!context.getState().userRoles.length || refresh) {
            context.patchState({ loading: true });
            this.userDictionaryService
                .GetUserRoles()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    context.patchState({
                        userRoles: [...response.result].sort((a, b) => (a.roleName > b.roleName ? 1 : -1)),
                    });
                });
        }
    }

    @Action(ReloadUserRoles)
    public reload(context: StateContext<IUserRolesStateModel>) {
        context.dispatch(new LoadUserRoles(true));
    }
}
