/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableCommissionRate } from '../models/api-response-ienumerable-commission-rate';
import { ApiResponseCommissionRate } from '../models/api-response-commission-rate';
import { CommissionRateCreateRequest } from '../models/commission-rate-create-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { CommissionRateUpdateRequest } from '../models/commission-rate-update-request';
@Injectable({
  providedIn: 'root',
})
class CommissionRatesService extends __BaseService {
  static readonly GetCommissionRatesPath = '/CommissionRates';
  static readonly CreateCommissionRatePath = '/CommissionRates';
  static readonly GetCommissionRatesMultipleItemsPath = '/CommissionRates/MultipleItems';
  static readonly CreateCommissionRateMultipleItemsPath = '/CommissionRates/MultipleItems';
  static readonly UpdateCommissionRatePath = '/CommissionRates/{commissionRateId}';
  static readonly GetCommissionRatePath = '/CommissionRates/{commissionRateId}';
  static readonly DeleteCommissionRatePath = '/CommissionRates/{commissionRateId}';
  static readonly UpdateCommissionRateMultipleItemsPath = '/CommissionRates/{commissionRateId}/MultipleItems';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all CommissionRates obsolete.
   * @return Success
   */
  GetCommissionRatesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCommissionRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/CommissionRates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCommissionRate>;
      })
    );
  }
  /**
   * Get all CommissionRates obsolete.
   * @return Success
   */
  GetCommissionRates(): __Observable<ApiResponseIEnumerableCommissionRate> {
    return this.GetCommissionRatesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCommissionRate)
    );
  }

  /**
   * Creates a new Commission Rate obsolete.
   * @return Success
   */
  CreateCommissionRateResponse(): __Observable<__StrictHttpResponse<ApiResponseCommissionRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/CommissionRates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCommissionRate>;
      })
    );
  }
  /**
   * Creates a new Commission Rate obsolete.
   * @return Success
   */
  CreateCommissionRate(): __Observable<ApiResponseCommissionRate> {
    return this.CreateCommissionRateResponse().pipe(
      __map(_r => _r.body as ApiResponseCommissionRate)
    );
  }

  /**
   * Get all CommissionRates.
   * @param params The `CommissionRatesService.GetCommissionRatesMultipleItemsParams` containing the following parameters:
   *
   * - `itemTypeId`: The item type identifier.
   *
   * - `itemId`: The item identifier.
   *
   * - `isDeleted`: Optional parameter to filter commission rates by flag isDeleted.
   *
   * - `commissionUserId`: The commission user identifier.
   *
   * - `commissionRateSection`: The commission rate section.
   *
   * @return Success
   */
  GetCommissionRatesMultipleItemsResponse(params: CommissionRatesService.GetCommissionRatesMultipleItemsParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCommissionRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.itemTypeId != null) __params = __params.set('itemTypeId', params.itemTypeId.toString());
    if (params.itemId != null) __params = __params.set('itemId', params.itemId.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    if (params.commissionUserId != null) __params = __params.set('commissionUserId', params.commissionUserId.toString());
    if (params.commissionRateSection != null) __params = __params.set('commissionRateSection', params.commissionRateSection.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/CommissionRates/MultipleItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCommissionRate>;
      })
    );
  }
  /**
   * Get all CommissionRates.
   * @param params The `CommissionRatesService.GetCommissionRatesMultipleItemsParams` containing the following parameters:
   *
   * - `itemTypeId`: The item type identifier.
   *
   * - `itemId`: The item identifier.
   *
   * - `isDeleted`: Optional parameter to filter commission rates by flag isDeleted.
   *
   * - `commissionUserId`: The commission user identifier.
   *
   * - `commissionRateSection`: The commission rate section.
   *
   * @return Success
   */
  GetCommissionRatesMultipleItems(params: CommissionRatesService.GetCommissionRatesMultipleItemsParams): __Observable<ApiResponseIEnumerableCommissionRate> {
    return this.GetCommissionRatesMultipleItemsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCommissionRate)
    );
  }

  /**
   * Creates a new Commission Rate.
   * @param commissionRateCreateRequest The commission rate create request.
   * @return Success
   */
  CreateCommissionRateMultipleItemsResponse(commissionRateCreateRequest?: CommissionRateCreateRequest): __Observable<__StrictHttpResponse<ApiResponseCommissionRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = commissionRateCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/CommissionRates/MultipleItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCommissionRate>;
      })
    );
  }
  /**
   * Creates a new Commission Rate.
   * @param commissionRateCreateRequest The commission rate create request.
   * @return Success
   */
  CreateCommissionRateMultipleItems(commissionRateCreateRequest?: CommissionRateCreateRequest): __Observable<ApiResponseCommissionRate> {
    return this.CreateCommissionRateMultipleItemsResponse(commissionRateCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseCommissionRate)
    );
  }

  /**
   * Update an existing Commission Rate Obsolete.
   * @param commissionRateId undefined
   * @return Success
   */
  UpdateCommissionRateResponse(commissionRateId: string): __Observable<__StrictHttpResponse<ApiResponseCommissionRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/CommissionRates/${encodeURIComponent(String(commissionRateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCommissionRate>;
      })
    );
  }
  /**
   * Update an existing Commission Rate Obsolete.
   * @param commissionRateId undefined
   * @return Success
   */
  UpdateCommissionRate(commissionRateId: string): __Observable<ApiResponseCommissionRate> {
    return this.UpdateCommissionRateResponse(commissionRateId).pipe(
      __map(_r => _r.body as ApiResponseCommissionRate)
    );
  }

  /**
   * Get a particular CommissionRate by ID.
   * @param commissionRateId The CommissionRate id.
   * @return Success
   */
  GetCommissionRateResponse(commissionRateId: number): __Observable<__StrictHttpResponse<ApiResponseCommissionRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/CommissionRates/${encodeURIComponent(String(commissionRateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCommissionRate>;
      })
    );
  }
  /**
   * Get a particular CommissionRate by ID.
   * @param commissionRateId The CommissionRate id.
   * @return Success
   */
  GetCommissionRate(commissionRateId: number): __Observable<ApiResponseCommissionRate> {
    return this.GetCommissionRateResponse(commissionRateId).pipe(
      __map(_r => _r.body as ApiResponseCommissionRate)
    );
  }

  /**
   * Deletes a CommissionRate by its ID.
   * @param commissionRateId The CommissionRate id.
   * @return Success
   */
  DeleteCommissionRateResponse(commissionRateId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/CommissionRates/${encodeURIComponent(String(commissionRateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a CommissionRate by its ID.
   * @param commissionRateId The CommissionRate id.
   * @return Success
   */
  DeleteCommissionRate(commissionRateId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteCommissionRateResponse(commissionRateId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update an existing Commission Rate.
   * @param params The `CommissionRatesService.UpdateCommissionRateMultipleItemsParams` containing the following parameters:
   *
   * - `commissionRateId`: The Commission Rate id.
   *
   * - `commissionRateUpdateRequest`: The Commission Rate update request.
   *
   * @return Success
   */
  UpdateCommissionRateMultipleItemsResponse(params: CommissionRatesService.UpdateCommissionRateMultipleItemsParams): __Observable<__StrictHttpResponse<ApiResponseCommissionRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.commissionRateUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/CommissionRates/${encodeURIComponent(String(params.commissionRateId))}/MultipleItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCommissionRate>;
      })
    );
  }
  /**
   * Update an existing Commission Rate.
   * @param params The `CommissionRatesService.UpdateCommissionRateMultipleItemsParams` containing the following parameters:
   *
   * - `commissionRateId`: The Commission Rate id.
   *
   * - `commissionRateUpdateRequest`: The Commission Rate update request.
   *
   * @return Success
   */
  UpdateCommissionRateMultipleItems(params: CommissionRatesService.UpdateCommissionRateMultipleItemsParams): __Observable<ApiResponseCommissionRate> {
    return this.UpdateCommissionRateMultipleItemsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCommissionRate)
    );
  }
}

module CommissionRatesService {

  /**
   * Parameters for GetCommissionRatesMultipleItems
   */
  export interface GetCommissionRatesMultipleItemsParams {

    /**
     * The item type identifier.
     */
    itemTypeId?: number;

    /**
     * The item identifier.
     */
    itemId?: number;

    /**
     * Optional parameter to filter commission rates by flag isDeleted.
     */
    isDeleted?: 'False' | 'True' | 'Null';

    /**
     * The commission user identifier.
     */
    commissionUserId?: number;

    /**
     * The commission rate section.
     */
    commissionRateSection?: 'Global' | 'User' | 'ItemGlobal' | 'ItemUser' | 'ItemTypeUser' | 'ItemType';
  }

  /**
   * Parameters for UpdateCommissionRateMultipleItems
   */
  export interface UpdateCommissionRateMultipleItemsParams {

    /**
     * The Commission Rate id.
     */
    commissionRateId: number;

    /**
     * The Commission Rate update request.
     */
    commissionRateUpdateRequest?: CommissionRateUpdateRequest;
  }
}

export { CommissionRatesService }
