/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseEligibility } from '../models/api-response-eligibility';
import { EligibilityRequest } from '../models/eligibility-request';
import { ApiResponseIEnumerableEligibility } from '../models/api-response-ienumerable-eligibility';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class EligibilityService extends __BaseService {
  static readonly EligibilityRequestPath = '/Eligibility/EligibilityRequest';
  static readonly GetEligibilityPath = '/Eligibility/Policies/{insuredPartyId}';
  static readonly DeleteEligibilityPath = '/Eligibility/Policies/{insuredPartyId}';
  static readonly GetEligibilityByIdPath = '/Eligibility/{eligibilityId}';
  static readonly GetLatestEligibilityPath = '/Eligibility/latest/{insuredPartyId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates the Eligibility request.
   * @param patientEligibilityRequest The Patient Eligibility Request.
   * @return Success
   */
  EligibilityRequestResponse(patientEligibilityRequest?: EligibilityRequest): __Observable<__StrictHttpResponse<ApiResponseEligibility>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patientEligibilityRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Eligibility/EligibilityRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEligibility>;
      })
    );
  }
  /**
   * Creates the Eligibility request.
   * @param patientEligibilityRequest The Patient Eligibility Request.
   * @return Success
   */
  EligibilityRequest(patientEligibilityRequest?: EligibilityRequest): __Observable<ApiResponseEligibility> {
    return this.EligibilityRequestResponse(patientEligibilityRequest).pipe(
      __map(_r => _r.body as ApiResponseEligibility)
    );
  }

  /**
   * The get eligibility async.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  GetEligibilityResponse(insuredPartyId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerableEligibility>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Eligibility/Policies/${encodeURIComponent(String(insuredPartyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableEligibility>;
      })
    );
  }
  /**
   * The get eligibility async.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  GetEligibility(insuredPartyId: number): __Observable<ApiResponseIEnumerableEligibility> {
    return this.GetEligibilityResponse(insuredPartyId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableEligibility)
    );
  }

  /**
   * The delete eligibility requests.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  DeleteEligibilityResponse(insuredPartyId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Eligibility/Policies/${encodeURIComponent(String(insuredPartyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The delete eligibility requests.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  DeleteEligibility(insuredPartyId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteEligibilityResponse(insuredPartyId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get eligibility by id async.
   * @param eligibilityId The eligibility id.
   * @return Success
   */
  GetEligibilityByIdResponse(eligibilityId: string): __Observable<__StrictHttpResponse<ApiResponseEligibility>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Eligibility/${encodeURIComponent(String(eligibilityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEligibility>;
      })
    );
  }
  /**
   * The get eligibility by id async.
   * @param eligibilityId The eligibility id.
   * @return Success
   */
  GetEligibilityById(eligibilityId: string): __Observable<ApiResponseEligibility> {
    return this.GetEligibilityByIdResponse(eligibilityId).pipe(
      __map(_r => _r.body as ApiResponseEligibility)
    );
  }

  /**
   * The get latest eligibility async.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  GetLatestEligibilityResponse(insuredPartyId: number): __Observable<__StrictHttpResponse<ApiResponseEligibility>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Eligibility/latest/${encodeURIComponent(String(insuredPartyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEligibility>;
      })
    );
  }
  /**
   * The get latest eligibility async.
   * @param insuredPartyId The insured party id.
   * @return Success
   */
  GetLatestEligibility(insuredPartyId: number): __Observable<ApiResponseEligibility> {
    return this.GetLatestEligibilityResponse(insuredPartyId).pipe(
      __map(_r => _r.body as ApiResponseEligibility)
    );
  }
}

module EligibilityService {
}

export { EligibilityService }
