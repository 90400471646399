import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { WYSIWYG_EDITOR_SCRIPT_URL } from './wisywig-editor.token';
import { WysiwygEditorConfiguration } from './wysiwyg-editor.configuration';
import { ControlValueAccessorBaseDirective } from '../common/control-value-accessor.base';
import { distinctUntilChanged, pairwise, skip, startWith } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Editor } from 'tinymce';

@UntilDestroy()
@Component({
    selector: 'symplast-wysiwyg-editor',
    standalone: true,
    imports: [CommonModule, EditorModule, ReactiveFormsModule],
    templateUrl: './wysiwyg-editor.component.html',
    styleUrls: ['./wysiwyg-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useExisting: WYSIWYG_EDITOR_SCRIPT_URL },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: WysiwygEditorComponent,
            multi: true,
        },
    ],
})
export class WysiwygEditorComponent extends ControlValueAccessorBaseDirective implements OnInit {
    private static nextId = 0;

    @Input() initialValue?: string;
    @Input() configuration!: WysiwygEditorConfiguration;
    @Input() disabled = false;
    @Output() init = new EventEmitter<Editor>();
    @Output() valueChange = new EventEmitter<string>();

    public readonly id = `symplast-wysiwyg-editor-${WysiwygEditorComponent.nextId++}`;

    constructor(public readonly cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.setupUpdatingOnFormControlChanges();
        this.settupValueChange();

        if (this.initialValue) {
            this.control.setValue(this.initialValue, { emitEvent: false });
        }
    }

    private setupUpdatingOnFormControlChanges(): void {
        this.control.statusChanges.pipe(startWith(null), pairwise(), untilDestroyed(this)).subscribe(([prev, current]) => {
            if (prev !== current) {
                this.cdr.markForCheck();
            }
        });
    }

    private settupValueChange(): void {
        this.control.valueChanges.pipe(skip(1), distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
            this.cdr.markForCheck();
            this.valueChange.next(value);
        });
    }
}
