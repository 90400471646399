/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Function to generate a WebGL fingerprint.
 * This fingerprint is based on rendering a simple shape onto a canvas and extracting the pixel data.
 * @returns {string | null} The WebGL fingerprint or null if WebGL is not supported.
 */
export function getWebglFingerprint(): string | null {
    try {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        // Get the webgl rendering context
        const context = canvas.getContext('webgl') as WebGLRenderingContext;

        // Set canvas sizes
        canvas.width = 256;
        canvas.height = 128;

        // Define vertex and fragment shader source code
        const vertexShaderSource =
            'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}';
        const fragmentShaderSource =
            'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}';

        // Create and bind buffuer
        const buffer = context.createBuffer() as WebGLBuffer;

        context.bindBuffer(context.ARRAY_BUFFER, buffer);

        // Define vertices
        const vertices = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.7321, 0]);

        // Fill buffer with vertex data
        context.bufferData(context.ARRAY_BUFFER, vertices, context.STATIC_DRAW);
        (buffer as any).itemSize = 3;
        (buffer as any).numItems = 3;

        // Create a shader program
        const program = context.createProgram() as WebGLProgram;
        // Create a vertex shader
        const vertexShader = context.createShader(context.VERTEX_SHADER) as WebGLShader;

        // Set vertex shader source and compile it
        context.shaderSource(vertexShader, vertexShaderSource);
        context.compileShader(vertexShader);

        // Create a fragment shader
        const fragmentShader = context.createShader(context.FRAGMENT_SHADER) as WebGLShader;

        // Set fragment shader source and compile it
        context.shaderSource(fragmentShader, fragmentShaderSource);
        context.compileShader(fragmentShader);
        // Attach shaders to the program
        context.attachShader(program, vertexShader);
        context.attachShader(program, fragmentShader);
        // Link and use the program
        context.linkProgram(program);
        context.useProgram(program);

        // Get attribute and uniform locations
        // Get the location of the attribute "attrVertex" in the vertex shader
        (program as any).vertexPosAttrib = context.getAttribLocation(program, 'attrVertex');
        // Get the location of the uniform "uniformOffset" in the vertex shader
        (program as any).offsetUniform = context.getUniformLocation(program, 'uniformOffset');

        // Enable the attribute for the vertex position
        context.enableVertexAttribArray((program as any).vertexPosArray);
        // Specify how to interpret the vertex data in the buffer
        context.vertexAttribPointer((program as any).vertexPosAttrib, (buffer as any).itemSize, context.FLOAT, false, 0, 0);
        // Set uniform value
        context.uniform2f((program as any).offsetUniform, 1, 1);
        // Draw vertices
        context.drawArrays(context.TRIANGLE_STRIP, 0, (buffer as any).numItems);

        // Read pixel data from canvas
        const pixelData = new Uint8Array(canvas.width * canvas.height * 4);

        context.readPixels(0, 0, canvas.width, canvas.height, context.RGBA, context.UNSIGNED_BYTE, pixelData);

        // Convert pixel data to JSON and remove numeric keys
        const result = JSON.stringify(pixelData).replace(/,?"[0-9]+":/g, '');

        // Clear buffers
        // eslint-disable-next-line no-bitwise
        context.clear(context.COLOR_BUFFER_BIT | context.DEPTH_BUFFER_BIT | context.STENCIL_BUFFER_BIT);

        return result;
    } catch {
        return null;
    }
}
