import { Selector } from '@ngxs/store';
import { LocationItemTypeModel } from './location-item-type.model';
import { ITaxesStateModel } from './taxes.model';
import { TaxesState } from './taxes.state';

export class TaxesSelectors {
    @Selector([TaxesState])
    public static filters(state: ITaxesStateModel): any {
        return {
            searchString: state.searchString,
        };
    }

    @Selector([TaxesState])
    public static loading(state: ITaxesStateModel): boolean {
        return state.loading;
    }

    @Selector([TaxesState])
    public static taxes(state: ITaxesStateModel): ITaxesStateModel['taxes'] {
        return state.taxes;
    }

    @Selector([TaxesState])
    public static filteredLocationTaxDefaults(state: ITaxesStateModel): LocationItemTypeModel[] {
        return state.filteredLocationItemTypes;
    }
}
