import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';

export class WysiwygBlankFieldAddon implements WysiwygAddon {
    public static button = 'symplastBlankField';
    public name = 'blank-field';

    public init(config: WysiwygEditorConfiguration): void {
        config.options = {
            ...config.options,
            noneditable_regexp: [...(config.options?.noneditable_regexp || []), new RegExp(/_{4}/g)],
            format_noneditable_selector: 'span,p',
        };

        config.registerSetupCallback((editor) => {
            editor.ui.registry.addButton(WysiwygBlankFieldAddon.button, {
                icon: 'line',
                tooltip: 'Insert blank field',
                onAction: () => {
                    editor.insertContent('____');
                },
            });
        });
    }
}
