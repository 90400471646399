import { Injectable } from '@angular/core';
import { ConfigurationSettingsService, GaldermaAspireService, SimpayService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { IAppSettingsStateModel } from '.';
import { Device } from '@symplast/models/shared';
import { BrowserService } from '@symplast/utils';
import { LoadAppSettings, LoadTasksAppSettings, SetTenant, LoadIntegrationAppSettings } from './app-settings.actions';
import { TenantIntegrationsService } from '@symplast/generated-clients/tenants';
import { forkJoin, of } from 'rxjs';
// TODO: (a.vakhrushin) Circular file chain
import { environment } from 'apps/webportal/src/environments/environment';
import { IntegrationsIds } from '@symplast/app-constants';

@State<IAppSettingsStateModel>({
    name: 'AppSettings',
    defaults: {
        showAppMainMenu: true,
        showAppHeader: true,
        device: Device.Browser,
        isWebView: false,
        isTaskWorkflowEnabled: false,
        isGaldermaEnabled: false,
        isSymplastFormEnabled: false,
        tenantId: undefined,
        oarLink: '',
        tenantName: '',
        isApxEnabled: false,
        isSimpayFeatureEnabled: false,
        isSimpayEnabled: false,
        simpaySupportPhoneNumber: '',
        isSymplastPaymentsSettingsVisible: false,
        isFinancialsEnabled: false,
    },
})
@Injectable()
export class AppSettingsState {
    constructor(
        private browserService: BrowserService,
        private configurationService: ConfigurationSettingsService,
        private galdermaService: GaldermaAspireService,
        private integrationsService: TenantIntegrationsService,
        private simpayService: SimpayService,
    ) {}

    @Action(LoadAppSettings)
    public load(context: StateContext<IAppSettingsStateModel>, { isFinancialsEnabled }: LoadIntegrationAppSettings): void {
        const isWebView = this.browserService.IsWebView();
        const device = this.browserService.device;

        context.patchState({
            showAppHeader: !isWebView,
            showAppMainMenu: !isWebView,
            device,
            isWebView,
            isFinancialsEnabled,
        });
        context.dispatch(new LoadTasksAppSettings());
        context.dispatch(new LoadIntegrationAppSettings(isFinancialsEnabled));
    }

    @Action(LoadTasksAppSettings)
    public loadTaskSettings(context: StateContext<IAppSettingsStateModel>): void {
        this.configurationService.GetTaskSettings().subscribe((data) => {
            if (data && data.result) {
                context.patchState({
                    isTaskWorkflowEnabled:
                        data.result.ehrApprovalFlowEnabled || data.result.ehrOrdersFlowEnabled || data.result.ehrScribeFlowEnabled,
                });
            }
        });
    }

    @Action(LoadIntegrationAppSettings)
    public loadIntegrationSettings(
        context: StateContext<IAppSettingsStateModel>,
        { isFinancialsEnabled }: LoadIntegrationAppSettings,
    ): void {
        const galderma$ = isFinancialsEnabled ? this.galdermaService.GetGaldermaSettings() : of(null);
        const integrations$ = this.integrationsService.GetTenantIntegrations();
        const simpay$ = this.simpayService.GetSimpaySupportPhoneNumber();

        forkJoin([galderma$, integrations$, simpay$]).subscribe(([galdermaResponse, integrationsResponse, simpayResponse]) => {
            if (galdermaResponse && integrationsResponse && simpayResponse) {
                context.patchState({
                    isGaldermaEnabled:
                        !!galdermaResponse?.result &&
                        integrationsResponse.result?.find((integration) => integration.name === 'ASPIRE Galderma Rewards')
                            ?.integrationStatus === 'On',
                    isSymplastFormEnabled:
                        integrationsResponse.result?.find((integration) => integration.name === 'Symplast Forms')?.integrationStatus ===
                        'On',
                    isSimpayFeatureEnabled: !!integrationsResponse.result?.find(
                        (integration) => integration.integrationId === IntegrationsIds.SymplastPayments,
                    ),
                    isSimpayEnabled:
                        integrationsResponse.result?.find((integration) => integration.integrationId === IntegrationsIds.SymplastPayments)
                            ?.integrationStatus === 'On',
                    isSymplastPaymentsSettingsVisible: integrationsResponse.result?.some(
                        (integration) =>
                            integration.integrationId === IntegrationsIds.SymplastPayments &&
                            (integration.integrationStatus === 'On' || integration.integrationStatus === 'Pending'),
                    ),
                    simpaySupportPhoneNumber: simpayResponse.result,
                    isApxEnabled:
                        integrationsResponse.result?.find((integration) => integration.integrationId === IntegrationsIds.APX)
                            ?.integrationStatus === 'On',
                });
            }
        });
    }

    @Action(SetTenant)
    public setTenantId(context: StateContext<IAppSettingsStateModel>, { tenantId, tenantName }: SetTenant): void {
        const state = context.getState();

        if (state.tenantId !== tenantId) {
            context.patchState({
                tenantId,
                tenantName,
                oarLink: `${environment.onlineAppointmentRequestsApp}?tenant=${tenantId}`,
            });
        }
    }
}
