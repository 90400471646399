import { Selector } from '@ngxs/store';
import { LocationPaymentSettings } from '@symplast/generated-clients/web-portal';
import { IOarSettingsStateModel } from './oar-settings.model';
import { OarSettingsState } from './oar-settings.state';

export class OarSettingsSelectors {
    @Selector([OarSettingsState])
    public static loading(state: IOarSettingsStateModel): boolean {
        return state.loading;
    }

    @Selector([OarSettingsState])
    public static availableProviders(state: IOarSettingsStateModel): number[] {
        return state.providers;
    }

    @Selector([OarSettingsState])
    public static availableServices(state: IOarSettingsStateModel): number[] {
        return state.services;
    }

    @Selector([OarSettingsState])
    public static availableLocations(state: IOarSettingsStateModel): number[] {
        return state.locations;
    }

    @Selector([OarSettingsState])
    public static availableUsersForNotify(state: IOarSettingsStateModel): number[] {
        return state.usersForNotify;
    }

    @Selector([OarSettingsState])
    public static isDateOfBirthInputVisible(state: IOarSettingsStateModel): boolean {
        return state.isDateOfBirthInputVisible;
    }

    @Selector([OarSettingsState])
    public static isEmailAddressInputVisible(state: IOarSettingsStateModel): boolean {
        return state.isEmailAddressInputVisible;
    }

    @Selector([OarSettingsState])
    public static isGenderInputVisible(state: IOarSettingsStateModel): boolean {
        return state.isGenderInputVisible;
    }

    @Selector([OarSettingsState])
    public static enabled(state: IOarSettingsStateModel): boolean {
        return state.enabled;
    }

    @Selector([OarSettingsState])
    public static initialEnabled(state: IOarSettingsStateModel): boolean {
        return (
            state.enabled ||
            (state.locations && state.locations.length > 0) ||
            (state.providers && state.providers.length > 0) ||
            (state.services && state.services.length > 0) ||
            (state.usersForNotify && state.usersForNotify.length > 0)
        );
    }

    @Selector([OarSettingsState])
    public static locationsSetupCompleted(state: IOarSettingsStateModel): boolean {
        return state.locations && state.locations.length > 0;
    }

    @Selector([OarSettingsState])
    public static providersSetupCompleted(state: IOarSettingsStateModel): boolean {
        return state.providers && state.providers.length > 0;
    }

    @Selector([OarSettingsState])
    public static servicesSetupCompleted(state: IOarSettingsStateModel): boolean {
        return state.services && state.services.length > 0;
    }

    @Selector([OarSettingsState])
    public static usersForNotifySetupCompleted(state: IOarSettingsStateModel): boolean {
        return state.usersForNotify && state.usersForNotify.length > 0;
    }

    @Selector([OarSettingsState])
    public static init(state: IOarSettingsStateModel): boolean {
        return state.init;
    }

    @Selector([OarSettingsState])
    public static enableBookingDeposits(state: IOarSettingsStateModel): boolean {
        return state.enableBookingDeposits;
    }

    @Selector([OarSettingsState])
    public static locationSettings(state: IOarSettingsStateModel): LocationPaymentSettings[] {
        return state.locationSettings;
    }
}
