import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import { FilterSet, FiltersService } from '@symplast/generated-clients/web-portal';
import {
    CreateFilterSet,
    CreateFilterSetFailure,
    CreateFilterSetSuccess,
    DeleteFilterSet,
    DeleteFilterSetFailure,
    DeleteFilterSetSuccess,
    LoadFilterSets,
    LoadFilterSetsFailure,
    LoadFilterSetsSuccess,
    UpdateFilterSet,
    UpdateFilterSetFailure,
    UpdateFilterSetSuccess,
} from './filter-sets.actions';
import { iif, of, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export interface FilterSetsStateModel {
    ids: string[];
    entities: Record<string, FilterSet>;
    sections: string[];
}

@State<FilterSetsStateModel>({
    name: 'FilterSets',
    defaults: { ids: [], entities: {}, sections: [] },
})
@Injectable()
export class FilterSetsState {
    private filterService = inject(FiltersService);

    @Action(LoadFilterSets)
    public loadFilterSets(context: StateContext<FilterSetsStateModel>, { payload: { section } }: LoadFilterSets): void {
        const state = context.getState();
        const sections = state.sections;

        if (!sections.includes(section)) {
            this.filterService.GetFilterSetsForSection(section).subscribe({
                next: (response) => context.dispatch(new LoadFilterSetsSuccess({ section, filterSets: response.result || [] })),
                error: (error) => context.dispatch(new LoadFilterSetsFailure({ section, error })),
            });
        }
    }

    @Action(LoadFilterSetsSuccess)
    public loadFilterSetsSuccess(
        context: StateContext<FilterSetsStateModel>,
        { payload: { section, filterSets } }: LoadFilterSetsSuccess,
    ): void {
        const state = context.getState();

        context.patchState({
            ids: [...state.ids, ...filterSets.map((filterSet) => filterSet.id)],
            entities: {
                ...state.entities,
                ...filterSets.reduce((acc, filterSet) => ({ ...acc, [filterSet.id]: filterSet }), {}),
            },
            sections: [...state.sections, section],
        });
    }

    @Action(CreateFilterSet)
    public createFilterSet(context: StateContext<FilterSetsStateModel>, { payload: { filterSet } }: CreateFilterSet): void {
        this.filterService
            .CreateFilterSet({ filterSet })
            .pipe(
                switchMap(({ result }) =>
                    iif(
                        () => !!result?.id,
                        of(result),
                        throwError(() => new HttpErrorResponse({ error: { message: 'FilterSet was not created' } })),
                    ),
                ),
            )
            .subscribe({
                next: (result) => context.dispatch(new CreateFilterSetSuccess({ filterSet: result as FilterSet })),
                error: (error) => context.dispatch(new CreateFilterSetFailure({ error })),
            });
    }

    @Action(CreateFilterSetSuccess)
    public createFilterSetSuccess(context: StateContext<FilterSetsStateModel>, { payload: { filterSet } }: CreateFilterSetSuccess): void {
        const state = context.getState();
        const sectionsSet = new Set(state.sections);

        sectionsSet.add(filterSet.section);

        context.patchState({
            ids: [...state.ids, filterSet.id],
            entities: { ...state.entities, [filterSet.id]: filterSet },
            sections: Array.from(sectionsSet),
        });
    }

    @Action(UpdateFilterSet)
    public updateFilterSet(context: StateContext<FilterSetsStateModel>, { payload: { id, filterSet } }: UpdateFilterSet): void {
        this.filterService
            .UpdateFilterSet({ id, updateRequest: { filterSet } })
            .pipe(
                switchMap(({ result }) =>
                    iif(
                        () => !!result?.id,
                        of(result),
                        throwError(() => new HttpErrorResponse({ error: { message: 'FilterSet was not updated' } })),
                    ),
                ),
            )
            .subscribe({
                next: (result) => context.dispatch(new UpdateFilterSetSuccess({ id, filterSet: result as FilterSet })),
                error: (error) => context.dispatch(new UpdateFilterSetFailure({ id, filterSet, error })),
            });
    }

    @Action(UpdateFilterSetSuccess)
    public updateFilterSetSuccess(
        context: StateContext<FilterSetsStateModel>,
        { payload: { id, filterSet } }: UpdateFilterSetSuccess,
    ): void {
        const state = context.getState();

        context.patchState({
            entities: { ...state.entities, [id]: filterSet },
        });
    }

    @Action(DeleteFilterSet)
    public deleteFilterSet(context: StateContext<FilterSetsStateModel>, { payload: { id } }: DeleteFilterSet): void {
        this.filterService.DeleteFilterSet(id).subscribe({
            next: () => context.dispatch(new DeleteFilterSetSuccess({ id })),
            error: (error) => context.dispatch(new DeleteFilterSetFailure({ id, error })),
        });
    }

    @Action(DeleteFilterSetSuccess)
    public deleteFilterSetSuccess(context: StateContext<FilterSetsStateModel>, { payload: { id } }: DeleteFilterSetSuccess): void {
        const state = context.getState();

        context.patchState({
            ids: state.ids.filter((filterSetId) => filterSetId !== id),
            entities: Object.keys(state.entities).reduce((acc, key) => (key === id ? acc : { ...acc, [key]: state.entities[key] }), {}),
        });
    }
}
