/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseAppointmentRequestsSettings } from '../models/api-response-appointment-requests-settings';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { AppointmentRequestsSettingsUpdateRequest } from '../models/appointment-requests-settings-update-request';
import { ApiResponseIListLocationPaymentSettings } from '../models/api-response-ilist-location-payment-settings';
import { LocationPaymentSettings } from '../models/location-payment-settings';
@Injectable({
  providedIn: 'root',
})
class OnlineAppointmentRequestsService extends __BaseService {
  static readonly GetOnlineAppointmentRequestSettingPath = '/OnlineAppointmentRequests/Settings';
  static readonly UpdateOnlineAppointmentRequestSettingPath = '/OnlineAppointmentRequests/Settings';
  static readonly GetLocationPaymentSettingsPath = '/OnlineAppointmentRequests/Settings/Locations';
  static readonly UpdateLocationPaymentSettingsPath = '/OnlineAppointmentRequests/Settings/Locations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets Appointment Requests Settings.
   * @return Success
   */
  GetOnlineAppointmentRequestSettingResponse(): __Observable<__StrictHttpResponse<ApiResponseAppointmentRequestsSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/OnlineAppointmentRequests/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentRequestsSettings>;
      })
    );
  }
  /**
   * Gets Appointment Requests Settings.
   * @return Success
   */
  GetOnlineAppointmentRequestSetting(): __Observable<ApiResponseAppointmentRequestsSettings> {
    return this.GetOnlineAppointmentRequestSettingResponse().pipe(
      __map(_r => _r.body as ApiResponseAppointmentRequestsSettings)
    );
  }

  /**
   * The update settings.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateOnlineAppointmentRequestSettingResponse(updateRequest?: AppointmentRequestsSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/OnlineAppointmentRequests/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update settings.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateOnlineAppointmentRequestSetting(updateRequest?: AppointmentRequestsSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateOnlineAppointmentRequestSettingResponse(updateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the location payment settings
   * @return Success
   */
  GetLocationPaymentSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseIListLocationPaymentSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/OnlineAppointmentRequests/Settings/Locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIListLocationPaymentSettings>;
      })
    );
  }
  /**
   * Get the location payment settings
   * @return Success
   */
  GetLocationPaymentSettings(): __Observable<ApiResponseIListLocationPaymentSettings> {
    return this.GetLocationPaymentSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseIListLocationPaymentSettings)
    );
  }

  /**
   * Updates the location payment settings
   * @param request The list of Symplast.WebPortal.Service.OnlineAppointmentRequests.Models.LocationPaymentSettings
   * @return Success
   */
  UpdateLocationPaymentSettingsResponse(request?: Array<LocationPaymentSettings>): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/OnlineAppointmentRequests/Settings/Locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates the location payment settings
   * @param request The list of Symplast.WebPortal.Service.OnlineAppointmentRequests.Models.LocationPaymentSettings
   * @return Success
   */
  UpdateLocationPaymentSettings(request?: Array<LocationPaymentSettings>): __Observable<ApiResponseOkResponse> {
    return this.UpdateLocationPaymentSettingsResponse(request).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module OnlineAppointmentRequestsService {
}

export { OnlineAppointmentRequestsService }
