import { Injectable, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { InactivityDialogComponent } from '../components/inactivity-dialog/inactivity-dialog.component';
import { finalize } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InactivityDialogService {
    private inactivityDialogRef: MatDialogRef<InactivityDialogComponent> | null;

    constructor(private dialog: MatDialog, private ngZone: NgZone) {}

    public open(): void {
        this.ngZone.run(() => {
            this.inactivityDialogRef = this.dialog.open(InactivityDialogComponent, {
                panelClass: 'dialog-modal',
            });

            this.inactivityDialogRef
                .afterClosed()
                .pipe(finalize(() => (this.inactivityDialogRef = null)))
                .subscribe();
        });
    }

    public close(): void {
        if (this.inactivityDialogRef) {
            this.ngZone.run(() => this.inactivityDialogRef.close());
        }
    }
}
