import { ErrorHandler, Injectable, inject } from '@angular/core';
import { ERROR_HANDLER } from './error-handlers.token';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandlerManager extends ErrorHandler {
    private handlers = inject<ErrorHandler[]>(ERROR_HANDLER);

    public handleError(error: unknown): void {
        this.handlers.forEach((handler) => handler.handleError(error));
    }
}
