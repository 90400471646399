import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { PatientsService } from '@symplast/generated-clients/web-portal';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ApplicationConstants } from '@symplast/app-constants';
import { PatientInfo } from '@symplast/generated-clients/legacy';
import { SearchExplanationDialogComponent } from '../../shared/search-explanation-dialog/search-explanation-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class PatientsHelperService {
    patientSearchFC: FormControl;
    selectedPatient: PatientInfo;
    patients: PatientInfo[] = [];
    currentSearch = '';
    params: PatientsService.GetPatientsParams = {
        PageSize: 50,
        PageIndex: 1,
        Sorting: 'FullName',
        SortAscending: true,
        IncludeOnlyActive: false,
    };
    private loading$ = new BehaviorSubject(false);
    private _isLoading = false;
    private _isLoaderShowing = false;

    constructor(private patientsService: PatientsService, public dialog: MatDialog, private router: Router) {}

    public set isLoaderShowing(val) {
        if (!val) {
            this.loading$.next(this._isLoading);
        }
        this._isLoaderShowing = val;
    }

    public get isLoaderShowing(): boolean {
        return this._isLoaderShowing;
    }

    public set isLoading(val: boolean) {
        this._isLoading = val;

        if (!this.isLoaderShowing || !val) {
            this.loading$.next(val);
        }
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public get isLoading$(): Observable<boolean> {
        return this.loading$.asObservable().pipe(distinctUntilChanged());
    }

    defaultPayload(): PatientsService.GetPatientsParams {
        return {
            PageSize: 50,
            PageIndex: 1,
            Sorting: 'FullName',
            SortAscending: true,
            IncludeOnlyActive: false,
        } as PatientsService.GetPatientsParams;
    }

    subscribeToSearchChanges(patientSearchFC: FormControl): void {
        this.patientSearchFC = patientSearchFC;

        this.patientSearchFC.valueChanges
            .pipe(
                debounceTime(ApplicationConstants.DebounceTime),
                switchMap((value: string) => {
                    this.currentSearch = value.trim();
                    this.isLoading = true;
                    this.params = {
                        ...this.defaultPayload(),
                        Sorting: value.trim() ? 'Rank' : 'FullName',
                        Query: value.trim(),
                    };

                    return this.patientsService.GetPatients(this.params).pipe(
                        catchError((err) => {
                            this.isLoading = false;

                            return throwError(err);
                        }),
                        untilDestroyed(this),
                    );
                }),
            )
            .subscribe((patients) => {
                this.patients = patients.result;

                if (this.currentSearch && this.router.url !== '/Patients') {
                    this.router.navigate(['/Patients']);
                }

                this.isLoading = false;
            });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    resetHeaderSearch(): void {
        this.currentSearch = '';
        this.patients = [];
        this.isLoading = false;

        if (this.patientSearchFC) {
            this.patientSearchFC.patchValue('', { emitEvent: false, onlySelf: true });
        }
    }

    public showTips(): void {
        this.dialog.open(SearchExplanationDialogComponent, {
            width: '700px',
            panelClass: 'dialog-modal',
            autoFocus: false,
        });
    }
}
