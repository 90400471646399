export enum InsuranceLicenseType {
    NONE = 'none',
    READ_ONLY = 'readonly',
    FULL_ACCESS = 'fullaccess',
}

export enum InsuranceContractType {
    NOT_CONTRACTED = 'not-contracted',
    CONTRACT_PROCESSING = 'contract-processing',
    CONTRACTED = 'contracted',
}

export interface InsuranceAccessConfiguration {
    contract?: InsuranceContractType | InsuranceContractType[];
    license?: InsuranceLicenseType | InsuranceLicenseType[];
}
