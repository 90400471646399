import { NgFor } from '@angular/common';
import {
    Component,
    ChangeDetectionStrategy,
    Input,
    numberAttribute,
    ViewChildren,
    QueryList,
    ElementRef,
    AfterViewInit,
    booleanAttribute,
    Output,
    EventEmitter,
    inject,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IS_DEV_MODE } from '@symplast/utils';
import isNil from 'lodash-es/isNil';

export const DEFAULT_OTP_INPUT_SIZE = 6;

function buildFormArray(size: number): FormArray<FormControl<string>> {
    const arr: Array<FormControl<string>> = [];

    for (let i = 0; i < size; i++) {
        arr.push(new FormControl<string>('') as FormControl<string>);
    }

    return new FormArray(arr);
}

@Component({
    standalone: true,
    imports: [NgFor, ReactiveFormsModule, MatInputModule, MatFormFieldModule],
    selector: 'symplast-otp-input',
    templateUrl: './otp-input.component.html',
    styleUrls: ['./otp-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: OtpInputComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: OtpInputComponent,
            multi: true,
        },
    ],
})
export class OtpInputComponent implements AfterViewInit, ControlValueAccessor, Validator {
    @Input({ transform: booleanAttribute }) public autofocus = true;
    @Output() public valueChange = new EventEmitter<string>();

    @ViewChildren('inputElement') inputElements!: QueryList<ElementRef<HTMLInputElement>>;

    public isDevMode = inject(IS_DEV_MODE);
    public focusedIdx: number | null = null;
    public inputs = buildFormArray(DEFAULT_OTP_INPUT_SIZE);

    private size = DEFAULT_OTP_INPUT_SIZE;
    private scheduledFocusIdx: number | null = null;

    public get value(): string {
        return this.inputs.value.join('');
    }

    public get valid(): boolean {
        return this.value.length === this.size;
    }

    @Input({ alias: 'size', transform: numberAttribute }) public set _size(value: number) {
        if (!value || value < 1) {
            throw new Error('Size must be a positive number');
        }

        this.size = value;
        this.inputs = buildFormArray(value);
    }

    public onChange: (value: string) => void = () => {};
    public onTouched: () => void = () => {};

    public ngAfterViewInit(): void {
        if (this.autofocus) {
            setTimeout(() => this.focusInput(0));
        }
    }

    public writeValue(value: string): void {
        if (this.isDevMode && value?.length) {
            throw new Error('OTP Input is not supposed to be prefilled with data');
        }

        this.inputs.setValue(new Array(this.size).fill(''));
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.inputs[isDisabled ? 'disable' : 'enable']();
    }

    public validate(control: AbstractControl<string, string>): ValidationErrors | null {
        if (!control.value || control.value.length < this.size) {
            return {
                otpInput: `The value must be a ${this.size}-digit code`,
            };
        }

        return null;
    }

    public handleKeyDown(event: KeyboardEvent, idx: number): void {
        const isDeleteEvent = event.key === 'Backspace' || event.key === 'Delete';
        const indexIsPositive = idx > 0;

        if (isDeleteEvent && indexIsPositive) {
            this.scheduledFocusIdx = idx - 1;
        }
    }

    public handleKeyPress(event: KeyboardEvent, idx: number): void {
        const isDigit = /\d/.test(event.key);

        // Safari fires Cmd + V through keyPress event as well
        // so we need to handle it here and let it through
        if (event.key === 'v' && event.metaKey) {
            return;
        }

        if (!isDigit) {
            event.preventDefault();
        }

        if (isDigit && idx + 1 < this.size) {
            this.scheduledFocusIdx = idx + 1;
        }

        if (isDigit && this.inputs.controls[idx].value) {
            // If user deselects an input which already has a value
            // we want to clear it so that it doesn't have more than 1 digit
            // New digit will replace the old one in input event
            this.inputs.controls[idx].setValue('');
        }
    }

    public handlePaste(event: ClipboardEvent, idx: number): void {
        event.preventDefault();

        if (idx !== 0) {
            // If the target input is not the first one - ignore
            return;
        }

        const pasteData = event.clipboardData?.getData('text');
        const regex = new RegExp(`\\d{${this.size}}`);

        if (!pasteData || !regex.test(pasteData)) {
            // If there is nothing to be pasted or the pasted data does not
            // comply with the required format - ignore the event
            return;
        }

        for (let i = 0; i < pasteData.length; i++) {
            this.inputs.controls[i].setValue(pasteData[i]);
        }

        const inputToFocus: number = this.inputElements.length - 1;

        this.focusInput(inputToFocus);
        this.emitChangeEvent();
        this.onTouched();
    }

    public handleFocus(event: FocusEvent, idx: number): void {
        this.focusedIdx = idx;

        // Select previously entered value to replace with a new input
        (event.target as HTMLInputElement).select();
    }

    // Due to iOS/iPadOS Safari bug/special behavior we are forced to
    // schedule focus transition during keypress/keydown event and only
    // after input event happened - execute the transition
    // otherwise inputs don't get their values filled
    public handleInput(): void {
        this.emitChangeEvent();

        if (isNil(this.scheduledFocusIdx) === false) {
            this.focusInput(this.scheduledFocusIdx as number);
            this.scheduledFocusIdx = null;
        }
    }

    public handleBlur(): void {
        this.focusedIdx = null;
        this.onTouched();
    }

    private focusInput(idx: number): void {
        this.inputElements.get(idx)?.nativeElement.focus();
    }

    private emitChangeEvent(): void {
        this.onChange(this.value);
        this.valueChange.emit(this.value);
    }
}
