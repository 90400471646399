/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionPaymentMode } from '../models/api-response-icollection-payment-mode';
import { ApiResponsePaymentMode } from '../models/api-response-payment-mode';
import { PaymentModeCreateRequest } from '../models/payment-mode-create-request';
import { ApiResponseIOrderedEnumerableFattMerchantTerminal } from '../models/api-response-iordered-enumerable-fatt-merchant-terminal';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PaymentModeUpdateRequest } from '../models/payment-mode-update-request';
@Injectable({
    providedIn: 'root',
})
class PaymentModesService extends __BaseService {
    static readonly GetAllPaymentModesPath = '/PaymentModes';
    static readonly CreatePaymentModePath = '/PaymentModes';
    static readonly GetDefaultPaymentModePath = '/Tenants/{tenantId}/PaymentModes/Default';
    static readonly GetPaymentModeTerminalsPath = '/PaymentModes/{paymentModeId}/Terminals';
    static readonly DeletePaymentModePath = '/PaymentModes/{paymentModeId}';
    static readonly UpdatePaymentModePath = '/PaymentModes/{paymentModeId}';
    static readonly BulkDeletePaymentModesPath = '/Tenants/{tenantId}/PaymentModes/BulkDelete';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Get all the available payment modes.
     * @param legacyMode The legacy Mode flag (for old apps).
     * @return Success
     */
    GetAllPaymentModesResponse(legacyMode?: boolean): __Observable<__StrictHttpResponse<ApiResponseICollectionPaymentMode>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (legacyMode != null) __params = __params.set('legacyMode', legacyMode.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PaymentModes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionPaymentMode>;
            }),
        );
    }
    /**
     * Get all the available payment modes.
     * @param legacyMode The legacy Mode flag (for old apps).
     * @return Success
     */
    GetAllPaymentModes(legacyMode?: boolean): __Observable<ApiResponseICollectionPaymentMode> {
        return this.GetAllPaymentModesResponse(legacyMode).pipe(__map((_r) => _r.body as ApiResponseICollectionPaymentMode));
    }

    /**
     * Creates the payment mode.
     * @param paymentModeCreationRequest The paymentMode creation request.
     * @return Success or Created
     */
    CreatePaymentModeResponse(
        paymentModeCreationRequest?: PaymentModeCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponsePaymentMode | ApiResponsePaymentMode>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = paymentModeCreationRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/PaymentModes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePaymentMode | ApiResponsePaymentMode>;
            }),
        );
    }
    /**
     * Creates the payment mode.
     * @param paymentModeCreationRequest The paymentMode creation request.
     * @return Success or Created
     */
    CreatePaymentMode(
        paymentModeCreationRequest?: PaymentModeCreateRequest,
    ): __Observable<ApiResponsePaymentMode | ApiResponsePaymentMode> {
        return this.CreatePaymentModeResponse(paymentModeCreationRequest).pipe(
            __map((_r) => _r.body as ApiResponsePaymentMode | ApiResponsePaymentMode),
        );
    }

    /**
     * Get the default payment mode.
     * @param tenantId The tenant Id.
     * @return Success
     */
    GetDefaultPaymentModeResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponsePaymentMode>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(tenantId))}/PaymentModes/Default`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePaymentMode>;
            }),
        );
    }
    /**
     * Get the default payment mode.
     * @param tenantId The tenant Id.
     * @return Success
     */
    GetDefaultPaymentMode(tenantId: number): __Observable<ApiResponsePaymentMode> {
        return this.GetDefaultPaymentModeResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponsePaymentMode));
    }

    /**
     * Get all Fatt Merchant terminals.
     * @param params The `PaymentModesService.GetPaymentModeTerminalsParams` containing the following parameters:
     *
     * - `paymentModeId`: The payment mode identifier.
     *
     * - `includeDeletedPaymentModes`: Whether to include soft deleted payment modes.
     *
     * @return Success
     */
    GetPaymentModeTerminalsResponse(
        params: PaymentModesService.GetPaymentModeTerminalsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIOrderedEnumerableFattMerchantTerminal>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeDeletedPaymentModes != null)
            __params = __params.set('includeDeletedPaymentModes', params.includeDeletedPaymentModes.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/PaymentModes/${encodeURIComponent(String(params.paymentModeId))}/Terminals`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIOrderedEnumerableFattMerchantTerminal>;
            }),
        );
    }
    /**
     * Get all Fatt Merchant terminals.
     * @param params The `PaymentModesService.GetPaymentModeTerminalsParams` containing the following parameters:
     *
     * - `paymentModeId`: The payment mode identifier.
     *
     * - `includeDeletedPaymentModes`: Whether to include soft deleted payment modes.
     *
     * @return Success
     */
    GetPaymentModeTerminals(
        params: PaymentModesService.GetPaymentModeTerminalsParams,
    ): __Observable<ApiResponseIOrderedEnumerableFattMerchantTerminal> {
        return this.GetPaymentModeTerminalsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIOrderedEnumerableFattMerchantTerminal),
        );
    }

    /**
     * Deletes the payment mode.
     * @param paymentModeId The paymentMode identifier.
     * @return Success
     */
    DeletePaymentModeResponse(paymentModeId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/PaymentModes/${encodeURIComponent(String(paymentModeId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the payment mode.
     * @param paymentModeId The paymentMode identifier.
     * @return Success
     */
    DeletePaymentMode(paymentModeId: number): __Observable<ApiResponseOkResponse> {
        return this.DeletePaymentModeResponse(paymentModeId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Modifies the payment mode.
     * @param params The `PaymentModesService.UpdatePaymentModeParams` containing the following parameters:
     *
     * - `paymentModeId`: The payment mode identifier.
     *
     * - `paymentModeUpdateRequest`: The payment mode update request.
     *
     * @return Success
     */
    UpdatePaymentModeResponse(
        params: PaymentModesService.UpdatePaymentModeParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.paymentModeUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/PaymentModes/${encodeURIComponent(String(params.paymentModeId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies the payment mode.
     * @param params The `PaymentModesService.UpdatePaymentModeParams` containing the following parameters:
     *
     * - `paymentModeId`: The payment mode identifier.
     *
     * - `paymentModeUpdateRequest`: The payment mode update request.
     *
     * @return Success
     */
    UpdatePaymentMode(params: PaymentModesService.UpdatePaymentModeParams): __Observable<ApiResponseOkResponse> {
        return this.UpdatePaymentModeResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Deletes payment modes.
     * @param params The `PaymentModesService.BulkDeletePaymentModesParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `paymentModeIds`: The paymentMode identifiers.
     *
     * @return Success
     */
    BulkDeletePaymentModesResponse(
        params: PaymentModesService.BulkDeletePaymentModesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        (params.paymentModeIds || []).forEach((val) => {
            if (val != null) __params = __params.append('paymentModeIds', val.toString());
        });
        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/PaymentModes/BulkDelete`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes payment modes.
     * @param params The `PaymentModesService.BulkDeletePaymentModesParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `paymentModeIds`: The paymentMode identifiers.
     *
     * @return Success
     */
    BulkDeletePaymentModes(params: PaymentModesService.BulkDeletePaymentModesParams): __Observable<ApiResponseOkResponse> {
        return this.BulkDeletePaymentModesResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module PaymentModesService {
    /**
     * Parameters for GetPaymentModeTerminals
     */
    export interface GetPaymentModeTerminalsParams {
        /**
         * The payment mode identifier.
         */
        paymentModeId: number;

        /**
         * Whether to include soft deleted payment modes.
         */
        includeDeletedPaymentModes?: boolean;
    }

    /**
     * Parameters for UpdatePaymentMode
     */
    export interface UpdatePaymentModeParams {
        /**
         * The payment mode identifier.
         */
        paymentModeId: number;

        /**
         * The payment mode update request.
         */
        paymentModeUpdateRequest?: PaymentModeUpdateRequest;
    }

    /**
     * Parameters for BulkDeletePaymentModes
     */
    export interface BulkDeletePaymentModesParams {
        /**
         * The tenant Id.
         */
        tenantId: number;

        /**
         * The paymentMode identifiers.
         */
        paymentModeIds?: Array<number>;
    }
}

export { PaymentModesService };
