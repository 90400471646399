import isNil from 'lodash-es/isNil';

export function throwErrorIfDefined(value: unknown, message: string): void {
    if (!isNil(value)) {
        throw new Error(message);
    }
}

export function throwErrorIfNotDefined(value: unknown, undefinedMessage: string): void {
    if (isNil(value)) {
        throw new Error(undefinedMessage);
    }
}
