export function getScreenFingerprint(): string | null {
    try {
        const { width, height, colorDepth, pixelDepth } = window.screen;
        const touchSupport = 'ontouchstart' in window;
        const devicePixelRatio = window.devicePixelRatio;

        return JSON.stringify({ width, height, colorDepth, pixelDepth, touchSupport, devicePixelRatio });
    } catch {
        return null;
    }
}
