/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponsePowerBIEmbedInfo } from '../models/api-response-power-biembed-info';
import { ApiResponsePowerBIUserPreset } from '../models/api-response-power-biuser-preset';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PowerBIUserPreset } from '../models/power-biuser-preset';
@Injectable({
  providedIn: 'root',
})
class PowerBIService extends __BaseService {
  static readonly EmbedReportPath = '/PowerBI/Reports/{reportName}/Embed';
  static readonly GetDefaultPresetPath = '/PowerBI/Presets/Default';
  static readonly UpdateDefaultPresetPath = '/PowerBI/Presets/Default';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the PowerBI embed information for a report.
   * @param reportName The report name.
   * @return Success
   */
  EmbedReportResponse(reportName: string): __Observable<__StrictHttpResponse<ApiResponsePowerBIEmbedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PowerBI/Reports/${encodeURIComponent(String(reportName))}/Embed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePowerBIEmbedInfo>;
      })
    );
  }
  /**
   * Gets the PowerBI embed information for a report.
   * @param reportName The report name.
   * @return Success
   */
  EmbedReport(reportName: string): __Observable<ApiResponsePowerBIEmbedInfo> {
    return this.EmbedReportResponse(reportName).pipe(
      __map(_r => _r.body as ApiResponsePowerBIEmbedInfo)
    );
  }

  /**
   * Gets the default report preset for current user.
   * @return Success
   */
  GetDefaultPresetResponse(): __Observable<__StrictHttpResponse<ApiResponsePowerBIUserPreset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PowerBI/Presets/Default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePowerBIUserPreset>;
      })
    );
  }
  /**
   * Gets the default report preset for current user.
   * @return Success
   */
  GetDefaultPreset(): __Observable<ApiResponsePowerBIUserPreset> {
    return this.GetDefaultPresetResponse().pipe(
      __map(_r => _r.body as ApiResponsePowerBIUserPreset)
    );
  }

  /**
   * Updates the default report preset for current user.
   * @param preset The preset.
   * @return Success
   */
  UpdateDefaultPresetResponse(preset?: PowerBIUserPreset): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = preset;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/PowerBI/Presets/Default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates the default report preset for current user.
   * @param preset The preset.
   * @return Success
   */
  UpdateDefaultPreset(preset?: PowerBIUserPreset): __Observable<ApiResponseOkResponse> {
    return this.UpdateDefaultPresetResponse(preset).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module PowerBIService {
}

export { PowerBIService }
