/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableDisclaimer } from '../models/api-response-ienumerable-disclaimer';
import { ApiResponseDisclaimer } from '../models/api-response-disclaimer';
import { DisclaimerPostRequest } from '../models/disclaimer-post-request';
import { DisclaimerPutRequest } from '../models/disclaimer-put-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class DisclaimersService extends __BaseService {
  static readonly GetDisclaimersPath = '/Disclaimers';
  static readonly CreateDisclaimerPath = '/Disclaimers';
  static readonly GetDisclaimerByIdPath = '/Disclaimers/{id}';
  static readonly UpdateDisclaimerPath = '/Disclaimers/{id}';
  static readonly DeleteDisclaimerPath = '/Disclaimers/{id}';
  static readonly SetStatusDisclaimerPath = '/Disclaimers/{id}/SetStatus';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all the Disclaimers for user's tenant ID.
   * @return Success
   */
  GetDisclaimersResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableDisclaimer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Disclaimers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableDisclaimer>;
      })
    );
  }
  /**
   * Gets all the Disclaimers for user's tenant ID.
   * @return Success
   */
  GetDisclaimers(): __Observable<ApiResponseIEnumerableDisclaimer> {
    return this.GetDisclaimersResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableDisclaimer)
    );
  }

  /**
   * Creates new disclaimer.
   * @param disclaimerCreateRequest The disclaimer create request.
   * @return Success or Created
   */
  CreateDisclaimerResponse(disclaimerCreateRequest?: DisclaimerPostRequest): __Observable<__StrictHttpResponse<ApiResponseDisclaimer | ApiResponseDisclaimer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = disclaimerCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Disclaimers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDisclaimer | ApiResponseDisclaimer>;
      })
    );
  }
  /**
   * Creates new disclaimer.
   * @param disclaimerCreateRequest The disclaimer create request.
   * @return Success or Created
   */
  CreateDisclaimer(disclaimerCreateRequest?: DisclaimerPostRequest): __Observable<ApiResponseDisclaimer | ApiResponseDisclaimer> {
    return this.CreateDisclaimerResponse(disclaimerCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseDisclaimer | ApiResponseDisclaimer)
    );
  }

  /**
   * Gets disclaimer by it's identifier.
   * @param id The identifier.
   * @return Success
   */
  GetDisclaimerByIdResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseDisclaimer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Disclaimers/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDisclaimer>;
      })
    );
  }
  /**
   * Gets disclaimer by it's identifier.
   * @param id The identifier.
   * @return Success
   */
  GetDisclaimerById(id: string): __Observable<ApiResponseDisclaimer> {
    return this.GetDisclaimerByIdResponse(id).pipe(
      __map(_r => _r.body as ApiResponseDisclaimer)
    );
  }

  /**
   * Updates the specified disclaimer.
   * @param params The `DisclaimersService.UpdateDisclaimerParams` containing the following parameters:
   *
   * - `id`: The disclaimer identifier.
   *
   * - `disclaimerUpdateRequest`: The disclaimer update request.
   *
   * @return Success
   */
  UpdateDisclaimerResponse(params: DisclaimersService.UpdateDisclaimerParams): __Observable<__StrictHttpResponse<ApiResponseDisclaimer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.disclaimerUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Disclaimers/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDisclaimer>;
      })
    );
  }
  /**
   * Updates the specified disclaimer.
   * @param params The `DisclaimersService.UpdateDisclaimerParams` containing the following parameters:
   *
   * - `id`: The disclaimer identifier.
   *
   * - `disclaimerUpdateRequest`: The disclaimer update request.
   *
   * @return Success
   */
  UpdateDisclaimer(params: DisclaimersService.UpdateDisclaimerParams): __Observable<ApiResponseDisclaimer> {
    return this.UpdateDisclaimerResponse(params).pipe(
      __map(_r => _r.body as ApiResponseDisclaimer)
    );
  }

  /**
   * Delete the specified disclaimer.
   * @param id The disclaimer identifier.
   * @return Success
   */
  DeleteDisclaimerResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Disclaimers/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete the specified disclaimer.
   * @param id The disclaimer identifier.
   * @return Success
   */
  DeleteDisclaimer(id: string): __Observable<ApiResponseOkResponse> {
    return this.DeleteDisclaimerResponse(id).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Sets the status disclaimer.
   * @param params The `DisclaimersService.SetStatusDisclaimerParams` containing the following parameters:
   *
   * - `id`: The identifier.
   *
   * - `isActive`: if set to `true` [is active].
   *
   * @return Success
   */
  SetStatusDisclaimerResponse(params: DisclaimersService.SetStatusDisclaimerParams): __Observable<__StrictHttpResponse<ApiResponseDisclaimer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Disclaimers/${encodeURIComponent(String(params.id))}/SetStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseDisclaimer>;
      })
    );
  }
  /**
   * Sets the status disclaimer.
   * @param params The `DisclaimersService.SetStatusDisclaimerParams` containing the following parameters:
   *
   * - `id`: The identifier.
   *
   * - `isActive`: if set to `true` [is active].
   *
   * @return Success
   */
  SetStatusDisclaimer(params: DisclaimersService.SetStatusDisclaimerParams): __Observable<ApiResponseDisclaimer> {
    return this.SetStatusDisclaimerResponse(params).pipe(
      __map(_r => _r.body as ApiResponseDisclaimer)
    );
  }
}

module DisclaimersService {

  /**
   * Parameters for UpdateDisclaimer
   */
  export interface UpdateDisclaimerParams {

    /**
     * The disclaimer identifier.
     */
    id: string;

    /**
     * The disclaimer update request.
     */
    disclaimerUpdateRequest?: DisclaimerPutRequest;
  }

  /**
   * Parameters for SetStatusDisclaimer
   */
  export interface SetStatusDisclaimerParams {

    /**
     * The identifier.
     */
    id: string;

    /**
     * if set to `true` [is active].
     */
    isActive?: boolean;
  }
}

export { DisclaimersService }
