import { Selector } from '@ngxs/store';
import { IMaritalStatusesStateModel } from './marital-statuses.model';
import { MaritalStatusesState } from './marital-statuses.state';

export class MaritalStatusesSelectors {
    @Selector([MaritalStatusesState])
    public static loading(state: IMaritalStatusesStateModel): boolean {
        return state.loading;
    }

    @Selector([MaritalStatusesState])
    public static maritalStatuses(state: IMaritalStatusesStateModel): IMaritalStatusesStateModel['maritalStatuses'] {
        return state.maritalStatuses;
    }
}
