import { Injectable } from '@angular/core';
import { BaseEnumService } from './base-enum.service';

@Injectable({
    providedIn: 'root',
})
export class ServiceTypesEnumService extends BaseEnumService {
    constructor() {
        super();
        this.data = [
            {
                key: 'Undefined',
                name: 'Undefined',
            },
            {
                key: 'NonRetail',
                name: 'Non-Retail',
            },
            {
                key: 'NonSurgical',
                name: 'Non-Surgical',
            },
            {
                key: 'Retail',
                name: 'Retail',
            },
            {
                key: 'Surgical',
                name: 'Surgical',
            },
        ];
    }
}
