import { Selector } from '@ngxs/store';
import { User } from '@symplast/generated-clients/web-portal';
import { IProvider } from '@symplast/models/shared';
import { IProvidersStateModel } from './providers.model';
import { ProvidersState } from './providers.state';

export class ProvidersSelectors {
    @Selector([ProvidersState])
    public static loading(state: IProvidersStateModel): boolean {
        return state.loading;
    }

    @Selector([ProvidersState])
    public static providers(state: IProvidersStateModel): User[] {
        return state.providers;
    }

    @Selector([ProvidersState])
    public static providerSelectionList(state: IProvidersStateModel): IProvider[] {
        return state.providerSelectionList;
    }
}
