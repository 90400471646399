import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
    MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { TipsEnum } from '../../core/services/tips/tips.enum';
import { TipsService } from '../../core/services/tips/tips.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
    standalone: true,
    imports: [MatLegacyDialogModule, MatButtonModule],
    selector: 'app-search-explanation-dialog',
    templateUrl: './search-explanation-dialog.component.html',
    styleUrls: ['./search-explanation-dialog.component.scss'],
})
export class SearchExplanationDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<SearchExplanationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private tipsService: TipsService,
    ) {}

    public ngOnInit(): void {
        this.tipsService.setTip(TipsEnum.PATIENT_SEARCH_TIP_STATUS, true);
    }
}
