import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApplicationInsightsService, RouterService } from '@symplast/services/shared';
import { filter, map, mergeMap, Observable } from 'rxjs';
import { LoaderHelper } from './core/helpers/loader.helper';
import { ServerErrorHandlerHelper } from './core/helpers/server-error-handler.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public title = 'webportal';
    public appLoading$: Observable<boolean>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        public applicationInsightsService: ApplicationInsightsService,
        public routerService: RouterService,
        snackbar: MatSnackBar,
    ) {
        ServerErrorHandlerHelper.init(snackbar);
        LoaderHelper.init();

        this.appLoading$ = LoaderHelper.loading$;
    }

    public ngOnInit(): void {
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!!event.url && event.url.match(/^\/#/)) {
                    this.router.navigateByUrl(event.url.replace('/#', ''));
                }
            }
        });
        // Set title for page
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data),
                untilDestroyed(this),
            )
            .subscribe((event) => {
                if (event) {
                    if (event['title']) {
                        this.titleService.setTitle(event['title']);
                    } else {
                        this.titleService.setTitle('Symplast Portal');
                    }
                }
            });
    }
}
