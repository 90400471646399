import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'andXOthersPipe',
    standalone: true,
})
/** Concatenates an array of strings and truncates it based on the desired
 amount of maxWords, adding "and {X} others." at the end. */
export class AndXOthersPipe implements PipeTransform {
    transform(words: string[], maxWords?: number): string {
        const defaultMaxWords = 5;
        let andOthersText = '';

        maxWords = maxWords || defaultMaxWords;

        if (words.length > maxWords) {
            const quantityLeft = words.length - maxWords;
            const pluralSuffix = quantityLeft > 1 ? 's' : '';

            andOthersText = ` and ${quantityLeft} other${pluralSuffix}.`;
        }

        return `${words.slice(0, maxWords).join(', ')} ${andOthersText}`;
    }
}
