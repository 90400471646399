import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    standalone: true,
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
    public transform(value: number): string {
        if (!value) {
            return 'n/a';
        }

        const exponentSize = 1024;
        const units = ['B', 'KB', 'MB'];

        let exponent = 0;

        do {
            value /= exponentSize;
            ++exponent;
        } while (Math.round(Math.abs(value) * 100) / 100 >= exponentSize && exponent < units.length - 1);

        return value.toFixed(2) + ' ' + units[exponent];
    }
}
