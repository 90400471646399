import { Selector } from '@ngxs/store';
import { IBrandsStateModel } from './brands.model';
import { BrandsState } from './brands.state';

export class BrandsSelectors {
    @Selector([BrandsState])
    public static filters(state: IBrandsStateModel): any {
        return {
            searchString: state.searchString,
        };
    }

    @Selector([BrandsState])
    public static loading(state: IBrandsStateModel): boolean {
        return state.loading;
    }

    @Selector([BrandsState])
    public static errorMessage(state: IBrandsStateModel): string {
        return state.errorMessage;
    }

    @Selector([BrandsState])
    public static brands(state: IBrandsStateModel): IBrandsStateModel['brands'] {
        return state.brands;
    }

    @Selector([BrandsState])
    public static filteredBrands(state: IBrandsStateModel): IBrandsStateModel['filteredBrands'] {
        return state.filteredBrands;
    }
}
