/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseCreditNotesGetResponse } from '../models/api-response-credit-notes-get-response';
import { CreditNotesGetRequest } from '../models/credit-notes-get-request';
@Injectable({
    providedIn: 'root',
})
class CreditNotesService extends __BaseService {
    static readonly GetCreditNotesForSingleTenantPath = '/CreditNotes';
    static readonly GetCreditNotesExcelFilePath = '/CreditNotesExcelFile';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets credit note information.
     * @param params The `CreditNotesService.GetCreditNotesForSingleTenantParams` containing the following parameters:
     *
     * - `ToDate`: Gets or sets the to date.
     *
     * - `IncludedFields`: Gets or sets the included fields.
     *
     * - `FromDate`: Gets or sets the from date.
     *
     * @return Success
     */
    GetCreditNotesForSingleTenantResponse(
        params: CreditNotesService.GetCreditNotesForSingleTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNotesGetResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
        (params.IncludedFields || []).forEach((val) => {
            if (val != null) __params = __params.append('IncludedFields', val.toString());
        });
        if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/CreditNotes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNotesGetResponse>;
            }),
        );
    }
    /**
     * Gets credit note information.
     * @param params The `CreditNotesService.GetCreditNotesForSingleTenantParams` containing the following parameters:
     *
     * - `ToDate`: Gets or sets the to date.
     *
     * - `IncludedFields`: Gets or sets the included fields.
     *
     * - `FromDate`: Gets or sets the from date.
     *
     * @return Success
     */
    GetCreditNotesForSingleTenant(
        params: CreditNotesService.GetCreditNotesForSingleTenantParams,
    ): __Observable<ApiResponseCreditNotesGetResponse> {
        return this.GetCreditNotesForSingleTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNotesGetResponse));
    }

    /**
     * Gets the credit notes excel file.
     * @param request The request.
     * @return Success
     */
    GetCreditNotesExcelFileResponse(request?: CreditNotesGetRequest): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/CreditNotesExcelFile`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Gets the credit notes excel file.
     * @param request The request.
     * @return Success
     */
    GetCreditNotesExcelFile(request?: CreditNotesGetRequest): __Observable<Blob> {
        return this.GetCreditNotesExcelFileResponse(request).pipe(__map((_r) => _r.body as Blob));
    }
}

module CreditNotesService {
    /**
     * Parameters for GetCreditNotesForSingleTenant
     */
    export interface GetCreditNotesForSingleTenantParams {
        /**
         * Gets or sets the to date.
         */
        ToDate?: string;

        /**
         * Gets or sets the included fields.
         */
        IncludedFields?: Array<string>;

        /**
         * Gets or sets the from date.
         */
        FromDate?: string;
    }
}

export { CreditNotesService };
