import { EditorOptions } from 'tinymce';
import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';
import { Observable } from 'rxjs';
import { toMBFixed } from '@symplast/utils';

type BlobInfo = Parameters<NonNullable<EditorOptions['images_upload_handler']>>[0];

export interface WysiwygImageAddonOptions {
    /** Accepted file extensions */
    accept: string | string[];
    /** Maximum file size in bytes */
    maxFileSize: number;
    /**
     * Uploads the image to the server
     * @param blobInfo The image blob
     * @returns The URL of the uploaded image
     */
    upload: (blobInfo: BlobInfo) => Observable<string>;
}

/**
 * image plugin must be enabled
 */
export class WysiwygImageAddon implements WysiwygAddon {
    public name = 'symplast-image';
    private accept: string;

    constructor(private options: WysiwygImageAddonOptions) {
        this.accept = Array.isArray(options.accept) ? options.accept.join(',') : options.accept;
    }

    public init(config: WysiwygEditorConfiguration): void {
        config.options = {
            ...config.options,
            file_picker_types: 'image',
            image_title: true,
            images_file_types: this.accept,
            block_unsupported_drop: true,
            images_upload_handler: (blobInfo) =>
                new Promise((resolve, reject) => {
                    const type = blobInfo.blob().type;
                    const size = blobInfo.blob().size;
                    const isImage = type.startsWith('image/');
                    const extension = type.split('/')[1];

                    if (typeof this.options.maxFileSize === 'number' && size > this.options.maxFileSize) {
                        return reject({
                            message: `File size exceeds the limit of ${toMBFixed(this.options.maxFileSize || 0)} MB`,
                            remove: true,
                        });
                    }

                    if (!isImage || !this.accept.includes(extension)) {
                        return reject({ message: 'Invalid file type selected', remove: true });
                    }

                    this.options.upload(blobInfo).subscribe({
                        next: (url) => resolve(url),
                        error: (error) => reject({ message: error.message || error.toString(), remove: true }),
                    });
                }),
        };
    }
}
