/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseMembershipProgram } from '../models/api-response-membership-program';
import { MembershipProgramCreateRequest } from '../models/membership-program-create-request';
import { ApiResponseIEnumerableMembershipProgram } from '../models/api-response-ienumerable-membership-program';
import { MembershipProgramUpdateRequest } from '../models/membership-program-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
    providedIn: 'root',
})
class MembershipProgramsService extends __BaseService {
    static readonly CreateMembershipProgramPath = '/MembershipPrograms';
    static readonly GetMembershipProgramsPath = '/MembershipPrograms';
    static readonly CreatePartnerMembershipProgramPath = '/Tenants/{tenantId}/CreatePartnerMembershipProgram';
    static readonly GetMembershipProgramByIdPath = '/MembershipPrograms/{membershipProgramId}';
    static readonly UpdateMembershipProgramPath = '/MembershipPrograms/{membershipProgramId}';
    static readonly UpdateMembershipProgramStatusPath = '/MembershipPrograms/{membershipProgramId}/Enabled';
    static readonly GetAvailableMembershipProgramsPath = '/MembershipPrograms/Patients/{patientId}/AvailableMembershipPrograms';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Creates the membership program.
     * @param request The membership program creation request.
     * @return Success or Created
     */
    CreateMembershipProgramResponse(
        request?: MembershipProgramCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseMembershipProgram | ApiResponseMembershipProgram>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/MembershipPrograms`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseMembershipProgram | ApiResponseMembershipProgram>;
            }),
        );
    }
    /**
     * Creates the membership program.
     * @param request The membership program creation request.
     * @return Success or Created
     */
    CreateMembershipProgram(
        request?: MembershipProgramCreateRequest,
    ): __Observable<ApiResponseMembershipProgram | ApiResponseMembershipProgram> {
        return this.CreateMembershipProgramResponse(request).pipe(
            __map((_r) => _r.body as ApiResponseMembershipProgram | ApiResponseMembershipProgram),
        );
    }

    /**
     * Gets the list of available membership programs.
     * @param params The `MembershipProgramsService.GetMembershipProgramsParams` containing the following parameters:
     *
     * - `Type`: Gets or sets the type.
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets the status.
     *
     * - `SortingDirection`: Gets or sets the sorting direction.
     *
     * - `Sorting`: Gets or sets the sorting.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `SearchString`: Gets or sets the search string.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * @return Success
     */
    GetMembershipProgramsResponse(
        params: MembershipProgramsService.GetMembershipProgramsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableMembershipProgram>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.Type != null) __params = __params.set('Type', params.Type.toString());
        if (params.Take != null) __params = __params.set('Take', params.Take.toString());
        if (params.Status != null) __params = __params.set('Status', params.Status.toString());
        if (params.SortingDirection != null) __params = __params.set('SortingDirection', params.SortingDirection.toString());
        if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
        if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
        if (params.SearchString != null) __params = __params.set('SearchString', params.SearchString.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/MembershipPrograms`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableMembershipProgram>;
            }),
        );
    }
    /**
     * Gets the list of available membership programs.
     * @param params The `MembershipProgramsService.GetMembershipProgramsParams` containing the following parameters:
     *
     * - `Type`: Gets or sets the type.
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets the status.
     *
     * - `SortingDirection`: Gets or sets the sorting direction.
     *
     * - `Sorting`: Gets or sets the sorting.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `SearchString`: Gets or sets the search string.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * @return Success
     */
    GetMembershipPrograms(
        params: MembershipProgramsService.GetMembershipProgramsParams,
    ): __Observable<ApiResponseIEnumerableMembershipProgram> {
        return this.GetMembershipProgramsResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableMembershipProgram));
    }

    /**
     * Creates the membership program.
     * @param params The `MembershipProgramsService.CreatePartnerMembershipProgramParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `userId`: The user Id.
     *
     * - `request`: The membership program creation request.
     *
     * @return Success or Created
     */
    CreatePartnerMembershipProgramResponse(
        params: MembershipProgramsService.CreatePartnerMembershipProgramParams,
    ): __Observable<__StrictHttpResponse<ApiResponseMembershipProgram | ApiResponseMembershipProgram>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        __body = params.request;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/CreatePartnerMembershipProgram`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseMembershipProgram | ApiResponseMembershipProgram>;
            }),
        );
    }
    /**
     * Creates the membership program.
     * @param params The `MembershipProgramsService.CreatePartnerMembershipProgramParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `userId`: The user Id.
     *
     * - `request`: The membership program creation request.
     *
     * @return Success or Created
     */
    CreatePartnerMembershipProgram(
        params: MembershipProgramsService.CreatePartnerMembershipProgramParams,
    ): __Observable<ApiResponseMembershipProgram | ApiResponseMembershipProgram> {
        return this.CreatePartnerMembershipProgramResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseMembershipProgram | ApiResponseMembershipProgram),
        );
    }

    /**
     * Gets the membership program by identifier.
     * @param membershipProgramId The membership program identifier.
     * @return Success
     */
    GetMembershipProgramByIdResponse(membershipProgramId: number): __Observable<__StrictHttpResponse<ApiResponseMembershipProgram>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/MembershipPrograms/${encodeURIComponent(String(membershipProgramId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseMembershipProgram>;
            }),
        );
    }
    /**
     * Gets the membership program by identifier.
     * @param membershipProgramId The membership program identifier.
     * @return Success
     */
    GetMembershipProgramById(membershipProgramId: number): __Observable<ApiResponseMembershipProgram> {
        return this.GetMembershipProgramByIdResponse(membershipProgramId).pipe(__map((_r) => _r.body as ApiResponseMembershipProgram));
    }

    /**
     * Updates the membership program.
     * @param params The `MembershipProgramsService.UpdateMembershipProgramParams` containing the following parameters:
     *
     * - `membershipProgramId`: The membership program identifier.
     *
     * - `membershipProgramUpdateRequest`: The membership program update request.
     *
     * @return Success
     */
    UpdateMembershipProgramResponse(
        params: MembershipProgramsService.UpdateMembershipProgramParams,
    ): __Observable<__StrictHttpResponse<ApiResponseMembershipProgram>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.membershipProgramUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/MembershipPrograms/${encodeURIComponent(String(params.membershipProgramId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseMembershipProgram>;
            }),
        );
    }
    /**
     * Updates the membership program.
     * @param params The `MembershipProgramsService.UpdateMembershipProgramParams` containing the following parameters:
     *
     * - `membershipProgramId`: The membership program identifier.
     *
     * - `membershipProgramUpdateRequest`: The membership program update request.
     *
     * @return Success
     */
    UpdateMembershipProgram(params: MembershipProgramsService.UpdateMembershipProgramParams): __Observable<ApiResponseMembershipProgram> {
        return this.UpdateMembershipProgramResponse(params).pipe(__map((_r) => _r.body as ApiResponseMembershipProgram));
    }

    /**
     * Modifies the MembershipProgram status.
     * @param params The `MembershipProgramsService.UpdateMembershipProgramStatusParams` containing the following parameters:
     *
     * - `membershipProgramId`: The MembershipProgram identifier.
     *
     * - `isEnabled`: if set to `true` [is enabled].
     *
     * @return Success
     */
    UpdateMembershipProgramStatusResponse(
        params: MembershipProgramsService.UpdateMembershipProgramStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/MembershipPrograms/${encodeURIComponent(String(params.membershipProgramId))}/Enabled`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies the MembershipProgram status.
     * @param params The `MembershipProgramsService.UpdateMembershipProgramStatusParams` containing the following parameters:
     *
     * - `membershipProgramId`: The MembershipProgram identifier.
     *
     * - `isEnabled`: if set to `true` [is enabled].
     *
     * @return Success
     */
    UpdateMembershipProgramStatus(
        params: MembershipProgramsService.UpdateMembershipProgramStatusParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdateMembershipProgramStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the list of available membership programs.
     * @param params The `MembershipProgramsService.GetAvailableMembershipProgramsParams` containing the following parameters:
     *
     * - `patientId`: The patient Id.
     *
     * - `Type`: Gets or sets the type.
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets the status.
     *
     * - `SortingDirection`: Gets or sets the sorting direction.
     *
     * - `Sorting`: Gets or sets the sorting.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `SearchString`: Gets or sets the search string.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * @return Success
     */
    GetAvailableMembershipProgramsResponse(
        params: MembershipProgramsService.GetAvailableMembershipProgramsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableMembershipProgram>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.Type != null) __params = __params.set('Type', params.Type.toString());
        if (params.Take != null) __params = __params.set('Take', params.Take.toString());
        if (params.Status != null) __params = __params.set('Status', params.Status.toString());
        if (params.SortingDirection != null) __params = __params.set('SortingDirection', params.SortingDirection.toString());
        if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
        if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
        if (params.SearchString != null) __params = __params.set('SearchString', params.SearchString.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/MembershipPrograms/Patients/${encodeURIComponent(String(params.patientId))}/AvailableMembershipPrograms`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableMembershipProgram>;
            }),
        );
    }
    /**
     * Gets the list of available membership programs.
     * @param params The `MembershipProgramsService.GetAvailableMembershipProgramsParams` containing the following parameters:
     *
     * - `patientId`: The patient Id.
     *
     * - `Type`: Gets or sets the type.
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets the status.
     *
     * - `SortingDirection`: Gets or sets the sorting direction.
     *
     * - `Sorting`: Gets or sets the sorting.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `SearchString`: Gets or sets the search string.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * @return Success
     */
    GetAvailableMembershipPrograms(
        params: MembershipProgramsService.GetAvailableMembershipProgramsParams,
    ): __Observable<ApiResponseIEnumerableMembershipProgram> {
        return this.GetAvailableMembershipProgramsResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableMembershipProgram));
    }
}

module MembershipProgramsService {
    /**
     * Parameters for GetMembershipPrograms
     */
    export interface GetMembershipProgramsParams {
        /**
         * Gets or sets the type.
         */
        Type?: 'Basic' | 'Vip' | 'Partner';

        /**
         * Gets or sets the take.
         */
        Take?: number;

        /**
         * Gets or sets the status.
         */
        Status?: 'Active' | 'Inactive';

        /**
         * Gets or sets the sorting direction.
         */
        SortingDirection?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the sorting.
         */
        Sorting?: 'Date' | 'Amount' | 'Name';

        /**
         * Gets or sets the skip.
         */
        Skip?: number;

        /**
         * Gets or sets the search string.
         */
        SearchString?: string;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;
    }

    /**
     * Parameters for CreatePartnerMembershipProgram
     */
    export interface CreatePartnerMembershipProgramParams {
        /**
         * The tenant Id.
         */
        tenantId: number;

        /**
         * The user Id.
         */
        userId?: number;

        /**
         * The membership program creation request.
         */
        request?: MembershipProgramCreateRequest;
    }

    /**
     * Parameters for UpdateMembershipProgram
     */
    export interface UpdateMembershipProgramParams {
        /**
         * The membership program identifier.
         */
        membershipProgramId: number;

        /**
         * The membership program update request.
         */
        membershipProgramUpdateRequest?: MembershipProgramUpdateRequest;
    }

    /**
     * Parameters for UpdateMembershipProgramStatus
     */
    export interface UpdateMembershipProgramStatusParams {
        /**
         * The MembershipProgram identifier.
         */
        membershipProgramId: number;

        /**
         * if set to `true` [is enabled].
         */
        isEnabled?: boolean;
    }

    /**
     * Parameters for GetAvailableMembershipPrograms
     */
    export interface GetAvailableMembershipProgramsParams {
        /**
         * The patient Id.
         */
        patientId: number;

        /**
         * Gets or sets the type.
         */
        Type?: 'Basic' | 'Vip' | 'Partner';

        /**
         * Gets or sets the take.
         */
        Take?: number;

        /**
         * Gets or sets the status.
         */
        Status?: 'Active' | 'Inactive';

        /**
         * Gets or sets the sorting direction.
         */
        SortingDirection?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the sorting.
         */
        Sorting?: 'Date' | 'Amount' | 'Name';

        /**
         * Gets or sets the skip.
         */
        Skip?: number;

        /**
         * Gets or sets the search string.
         */
        SearchString?: string;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;
    }
}

export { MembershipProgramsService };
