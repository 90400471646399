import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { IPaymentReasonsStateModel } from './payment-reasons.model';
import { LoadPaymentReasons, ReloadPaymentReasons } from './payment-reasons.actions';
import { PaymentReasonsService } from '@symplast/generated-clients/web-portal';

@State<IPaymentReasonsStateModel>({
    name: 'paymentReasons',
    defaults: {
        paymentReasons: [],
        loading: false,
    },
})
@Injectable()
export class PaymentReasonsState {
    constructor(private paymentReasonsService: PaymentReasonsService) {}

    @Action(LoadPaymentReasons)
    public load(context: StateContext<IPaymentReasonsStateModel>, { refresh }: LoadPaymentReasons) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.paymentReasons.length) || refresh) {
            context.patchState({ loading: true });
            this.paymentReasonsService
                .GetAllPaymentReasons()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result = [] }) => {
                    context.patchState({
                        paymentReasons: [...result],
                    });
                });
        }
    }

    @Action(ReloadPaymentReasons)
    public reload(context: StateContext<IPaymentReasonsStateModel>) {
        context.dispatch(new LoadPaymentReasons(true));
    }
}
