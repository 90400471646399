/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseLocationItemInventory } from '../models/api-response-location-item-inventory';
import { LocationItemInventoryCreateRequest } from '../models/location-item-inventory-create-request';
import { ApiResponseIEnumerableLocationItemInventory } from '../models/api-response-ienumerable-location-item-inventory';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { LocationItemInventoryUpdateRequest } from '../models/location-item-inventory-update-request';
@Injectable({
    providedIn: 'root',
})
class LocationItemInventoryService extends __BaseService {
    static readonly CreateLocalItemInventoryPath = '/Items/{itemId}/LocationInventory';
    static readonly GetLocationItemInventoryPath = '/Items/{itemId}/LocationInventory';
    static readonly UpdateLocationItemInventoryForItemPath = '/Items/{itemId}/LocationInventory';
    static readonly CreateLocalItemInventoryBulkPath = '/Items/{itemId}/LocationInventory/bulk';
    static readonly DeleteLocationItemInventoryPath = '/LocationInventory/{locationItemInventoryToDeleteId}';
    static readonly GetLocationItemInventoryByIdPath = '/LocationInventory/{locationItemInventoryId}';
    static readonly UpdateLocationItemInventoryPath = '/LocationInventory/{locationItemInventoryId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Creates a LocationItemInventory.
     * @param params The `LocationItemInventoryService.CreateLocalItemInventoryParams` containing the following parameters:
     *
     * - `itemId`: The  item identifier.
     *
     * - `locationItemInventoryRequest`: The  LocationItemInventory creation request.
     *
     * @return Success
     */
    CreateLocalItemInventoryResponse(
        params: LocationItemInventoryService.CreateLocalItemInventoryParams,
    ): __Observable<__StrictHttpResponse<ApiResponseLocationItemInventory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.locationItemInventoryRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/LocationInventory`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocationItemInventory>;
            }),
        );
    }
    /**
     * Creates a LocationItemInventory.
     * @param params The `LocationItemInventoryService.CreateLocalItemInventoryParams` containing the following parameters:
     *
     * - `itemId`: The  item identifier.
     *
     * - `locationItemInventoryRequest`: The  LocationItemInventory creation request.
     *
     * @return Success
     */
    CreateLocalItemInventory(
        params: LocationItemInventoryService.CreateLocalItemInventoryParams,
    ): __Observable<ApiResponseLocationItemInventory> {
        return this.CreateLocalItemInventoryResponse(params).pipe(__map((_r) => _r.body as ApiResponseLocationItemInventory));
    }

    /**
     * Gets all location item inventory.
     * @param params The `LocationItemInventoryService.GetLocationItemInventoryParams` containing the following parameters:
     *
     * - `itemId`: the item identifier.
     *
     * - `locationId`: the location identifier.
     *
     * - `isForSale`: Optional parameter to filter items by flag isForSale.
     *
     * @return Success
     */
    GetLocationItemInventoryResponse(
        params: LocationItemInventoryService.GetLocationItemInventoryParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableLocationItemInventory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.isForSale != null) __params = __params.set('isForSale', params.isForSale.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/LocationInventory`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableLocationItemInventory>;
            }),
        );
    }
    /**
     * Gets all location item inventory.
     * @param params The `LocationItemInventoryService.GetLocationItemInventoryParams` containing the following parameters:
     *
     * - `itemId`: the item identifier.
     *
     * - `locationId`: the location identifier.
     *
     * - `isForSale`: Optional parameter to filter items by flag isForSale.
     *
     * @return Success
     */
    GetLocationItemInventory(
        params: LocationItemInventoryService.GetLocationItemInventoryParams,
    ): __Observable<ApiResponseIEnumerableLocationItemInventory> {
        return this.GetLocationItemInventoryResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableLocationItemInventory));
    }

    /**
     * Modify a Location Item Inventory.
     * @param params The `LocationItemInventoryService.UpdateLocationItemInventoryForItemParams` containing the following parameters:
     *
     * - `itemId`: the item identifier.
     *
     * - `updateLocationItemInventoryRequest`: the object container to update.
     *
     * - `locationId`: the location identifier.
     *
     * @return Success
     */
    UpdateLocationItemInventoryForItemResponse(
        params: LocationItemInventoryService.UpdateLocationItemInventoryForItemParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.updateLocationItemInventoryRequest;
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/LocationInventory`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modify a Location Item Inventory.
     * @param params The `LocationItemInventoryService.UpdateLocationItemInventoryForItemParams` containing the following parameters:
     *
     * - `itemId`: the item identifier.
     *
     * - `updateLocationItemInventoryRequest`: the object container to update.
     *
     * - `locationId`: the location identifier.
     *
     * @return Success
     */
    UpdateLocationItemInventoryForItem(
        params: LocationItemInventoryService.UpdateLocationItemInventoryForItemParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdateLocationItemInventoryForItemResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Creates a LocationItemInventory.
     * @param params The `LocationItemInventoryService.CreateLocalItemInventoryBulkParams` containing the following parameters:
     *
     * - `itemId`: The item identifier.
     *
     * - `locationItemInventoryRequest`: The  LocationItemInventory creation requests.
     */
    CreateLocalItemInventoryBulkResponse(
        params: LocationItemInventoryService.CreateLocalItemInventoryBulkParams,
    ): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.locationItemInventoryRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/LocationInventory/bulk`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            }),
        );
    }
    /**
     * Creates a LocationItemInventory.
     * @param params The `LocationItemInventoryService.CreateLocalItemInventoryBulkParams` containing the following parameters:
     *
     * - `itemId`: The item identifier.
     *
     * - `locationItemInventoryRequest`: The  LocationItemInventory creation requests.
     */
    CreateLocalItemInventoryBulk(params: LocationItemInventoryService.CreateLocalItemInventoryBulkParams): __Observable<null> {
        return this.CreateLocalItemInventoryBulkResponse(params).pipe(__map((_r) => _r.body as null));
    }

    /**
     * Disables a LocationItemType.
     * @param locationItemInventoryToDeleteId The location Item Inventory Id to soft delete.
     * @return Success
     */
    DeleteLocationItemInventoryResponse(
        locationItemInventoryToDeleteId: number,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/LocationInventory/${encodeURIComponent(String(locationItemInventoryToDeleteId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Disables a LocationItemType.
     * @param locationItemInventoryToDeleteId The location Item Inventory Id to soft delete.
     * @return Success
     */
    DeleteLocationItemInventory(locationItemInventoryToDeleteId: number): __Observable<ApiResponseOkResponse> {
        return this.DeleteLocationItemInventoryResponse(locationItemInventoryToDeleteId).pipe(
            __map((_r) => _r.body as ApiResponseOkResponse),
        );
    }

    /**
     * Gets a location item inventory.
     * @param locationItemInventoryId the location item inventory id to modify.
     * @return Success
     */
    GetLocationItemInventoryByIdResponse(
        locationItemInventoryId: number,
    ): __Observable<__StrictHttpResponse<ApiResponseLocationItemInventory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/LocationInventory/${encodeURIComponent(String(locationItemInventoryId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocationItemInventory>;
            }),
        );
    }
    /**
     * Gets a location item inventory.
     * @param locationItemInventoryId the location item inventory id to modify.
     * @return Success
     */
    GetLocationItemInventoryById(locationItemInventoryId: number): __Observable<ApiResponseLocationItemInventory> {
        return this.GetLocationItemInventoryByIdResponse(locationItemInventoryId).pipe(
            __map((_r) => _r.body as ApiResponseLocationItemInventory),
        );
    }

    /**
     * Modify a Location Item Inventory.
     * @param params The `LocationItemInventoryService.UpdateLocationItemInventoryParams` containing the following parameters:
     *
     * - `locationItemInventoryId`: the location item inventory id to modify.
     *
     * - `updateLocationItemInventoryRequest`: the object container to update.
     *
     * @return Success
     */
    UpdateLocationItemInventoryResponse(
        params: LocationItemInventoryService.UpdateLocationItemInventoryParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.updateLocationItemInventoryRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/LocationInventory/${encodeURIComponent(String(params.locationItemInventoryId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modify a Location Item Inventory.
     * @param params The `LocationItemInventoryService.UpdateLocationItemInventoryParams` containing the following parameters:
     *
     * - `locationItemInventoryId`: the location item inventory id to modify.
     *
     * - `updateLocationItemInventoryRequest`: the object container to update.
     *
     * @return Success
     */
    UpdateLocationItemInventory(
        params: LocationItemInventoryService.UpdateLocationItemInventoryParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdateLocationItemInventoryResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module LocationItemInventoryService {
    /**
     * Parameters for CreateLocalItemInventory
     */
    export interface CreateLocalItemInventoryParams {
        /**
         * The  item identifier.
         */
        itemId: number;

        /**
         * The  LocationItemInventory creation request.
         */
        locationItemInventoryRequest?: LocationItemInventoryCreateRequest;
    }

    /**
     * Parameters for GetLocationItemInventory
     */
    export interface GetLocationItemInventoryParams {
        /**
         * the item identifier.
         */
        itemId: number;

        /**
         * the location identifier.
         */
        locationId?: number;

        /**
         * Optional parameter to filter items by flag isForSale.
         */
        isForSale?: 'False' | 'True' | 'Null';
    }

    /**
     * Parameters for UpdateLocationItemInventoryForItem
     */
    export interface UpdateLocationItemInventoryForItemParams {
        /**
         * the item identifier.
         */
        itemId: number;

        /**
         * the object container to update.
         */
        updateLocationItemInventoryRequest?: LocationItemInventoryUpdateRequest;

        /**
         * the location identifier.
         */
        locationId?: number;
    }

    /**
     * Parameters for CreateLocalItemInventoryBulk
     */
    export interface CreateLocalItemInventoryBulkParams {
        /**
         * The item identifier.
         */
        itemId: number;

        /**
         * The  LocationItemInventory creation requests.
         */
        locationItemInventoryRequest?: Array<LocationItemInventoryCreateRequest>;
    }

    /**
     * Parameters for UpdateLocationItemInventory
     */
    export interface UpdateLocationItemInventoryParams {
        /**
         * the location item inventory id to modify.
         */
        locationItemInventoryId: number;

        /**
         * the object container to update.
         */
        updateLocationItemInventoryRequest?: LocationItemInventoryUpdateRequest;
    }
}

export { LocationItemInventoryService };
