import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, booleanAttribute } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

const DEFAULT_WIDTH = 90;
const THUMB_SIZE = 28;
const THUMB_PADDING = 2;

@Component({
    selector: 'symplast-toggle',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ToggleComponent,
            multi: true,
        },
    ],
})
export class ToggleComponent implements ControlValueAccessor {
    @Input() public checked = false;
    @Input() public readonly = false;
    @Input() public disabled = false;
    @Input() public switchOnLabel?: string;
    @Input() public switchOffLabel?: string;
    @Input() public width: string | number = DEFAULT_WIDTH;
    @Input() public labelPosition: 'start' | 'end' = 'end';
    /** If emitChangeOnManualChangeOnly is true, checkedChange will be emitted only when user manually changes toggle state (not using formControl.setValue) */
    @Input({ transform: booleanAttribute }) public emitChangeOnManualChangeOnly = false;

    @Output() public checkedChange = new EventEmitter<boolean>();

    // Check if accessor is initialized to prevent emitting checkedChange on initialization of formControl
    private accessorInitialized = false;

    constructor(private cdr: ChangeDetectorRef) {}

    public get thumbContainerWidth(): number {
        if (typeof this.width === 'number') {
            return this.width;
        } else if (typeof this.width === 'string') {
            const parsedWidth = parseInt(this.width);

            if (!isNaN(parsedWidth)) {
                return parsedWidth;
            }
        }

        return DEFAULT_WIDTH;
    }

    public get thumbXPosition(): string {
        if (!this.checked) {
            return 'translateX(0)';
        }

        const actualPosition = this.thumbContainerWidth - THUMB_SIZE - 2 * THUMB_PADDING;

        return `translateX(${actualPosition}px)`;
    }

    // Initializing with empty function to prevent errors when using component without control value accessor
    public onChange: (value: any) => void = () => {};
    public onTouched: () => void = () => {};

    public writeValue(value: any): void {
        this.checked = !!value;
        this.cdr.detectChanges();

        if (this.accessorInitialized && !this.emitChangeOnManualChangeOnly) {
            this.checkedChange.emit(value);
        } else {
            this.accessorInitialized = true;
        }
    }

    public registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public onKeyPressHandler(event: KeyboardEvent): void {
        if (event.code === 'Space') {
            this.checked = !this.checked;
            this.onChange(this.checked);
            this.checkedChange.emit(this.checked);
        }
    }

    public onChangeHandler(event: Event): void {
        this.checked = !!(event.target as HTMLInputElement).checked;
        this.onChange(this.checked);
        this.checkedChange.emit(this.checked);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdr.detectChanges();
    }
}
