import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'expiredPipe' })
export class ExpiredPipe implements PipeTransform {
    transform(value: any, expirationTime: Date): string {
        if (!expirationTime || new Date(expirationTime) > new Date()) {
            return value;
        } else {
            return `<b style='color: red;'>${value}</b>`;
        }
    }
}
