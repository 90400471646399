import { Selector } from '@ngxs/store';
import { IPatientBalancesStateModel } from './patient-balances.model';
import { PatientBalancesState } from './patient-balances.state';

export class PatientBalancesSelectors {
    @Selector([PatientBalancesState])
    public static filters(state: IPatientBalancesStateModel): any {
        return {
            patientSearch: state.patientSearch,
            ageBracket: state.ageBracket,
            balanceBracket: state.balanceBracket,
            paymentAgeBracket: state.paymentAgeBracket,
            insuranceRelated: state.insuranceRelated,
            sortBy: state.sortBy,
            sortDirection: state.sortDirection,
            pageIndex: state.pageIndex,
            pageSize: state.pageSize,
        };
    }

    @Selector([PatientBalancesState])
    public static loading(state: IPatientBalancesStateModel): boolean {
        return state.loading;
    }

    @Selector([PatientBalancesState])
    public static balances(state: IPatientBalancesStateModel) {
        return state.patientBalances;
    }

    @Selector([PatientBalancesState])
    public static patientSearch(state: IPatientBalancesStateModel): string | undefined {
        return state.patientSearch;
    }

    @Selector([PatientBalancesState])
    public static ageBracket(state: IPatientBalancesStateModel): string {
        return state.ageBracket;
    }

    @Selector([PatientBalancesState])
    public static balanceBracket(state: IPatientBalancesStateModel): string {
        return state.balanceBracket;
    }

    @Selector([PatientBalancesState])
    public static insuranceRelated(state: IPatientBalancesStateModel): string | undefined {
        return state.insuranceRelated;
    }

    @Selector([PatientBalancesState])
    public static pageIndex(state: IPatientBalancesStateModel): number {
        return state.pageIndex;
    }

    @Selector([PatientBalancesState])
    public static pageSize(state: IPatientBalancesStateModel): number {
        return state.pageSize;
    }

    @Selector([PatientBalancesState])
    public static paymentAgeBracket(state: IPatientBalancesStateModel): string {
        return state.paymentAgeBracket;
    }

    @Selector([PatientBalancesState])
    public static sortBy(state: IPatientBalancesStateModel): string {
        return state.sortBy;
    }

    @Selector([PatientBalancesState])
    public static sortDirection(state: IPatientBalancesStateModel): string {
        return state.sortDirection;
    }

    @Selector([PatientBalancesState])
    public static totalCount(state: IPatientBalancesStateModel): number {
        return state.totalCount;
    }
}
