export function getWebglInfoFingerprint(): string | null {
    try {
        const context = document.createElement('canvas').getContext('webgl') as WebGLRenderingContext;

        const result = {
            VERSION: String(context.getParameter(context.VERSION)),
            SHADING_LANGUAGE_VERSION: String(context.getParameter(context.SHADING_LANGUAGE_VERSION)),
            VENDOR: String(context.getParameter(context.VENDOR)),
            SUPORTED_EXTENSIONS: String(context.getSupportedExtensions()),
        };

        return JSON.stringify(result);
    } catch {
        return null;
    }
}
