/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiPagedResponseICollectionFormTemplate } from '../models/api-paged-response-icollection-form-template';
import { ApiResponseForm } from '../models/api-response-form';
import { Form } from '../models/form';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class FormsService extends __BaseService {
  static readonly GetFormsPath = '/SymplastForms/Forms';
  static readonly CreateFormPath = '/SymplastForms/Forms';
  static readonly UpdateFormPath = '/SymplastForms/Forms';
  static readonly GetFormByIdPath = '/SymplastForms/Forms/{formId}';
  static readonly SetFormAsDefaultPath = '/SymplastForms/Forms/default';
  static readonly CloneFormPath = '/SymplastForms/Forms/{formId}/clone';
  static readonly ArchiveFormPath = '/SymplastForms/Forms/{formId}/archive';
  static readonly RestoreFormPath = '/SymplastForms/Forms/{formId}/restore';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of Form Templates.
   * @param params The `FormsService.GetFormsParams` containing the following parameters:
   *
   * - `search`: The search string.
   *
   * - `active`: The active flag.
   *
   * @return Success
   */
  GetFormsResponse(params: FormsService.GetFormsParams): __Observable<__StrictHttpResponse<ApiPagedResponseICollectionFormTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.active != null) __params = __params.set('active', params.active.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SymplastForms/Forms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseICollectionFormTemplate>;
      })
    );
  }
  /**
   * Gets a list of Form Templates.
   * @param params The `FormsService.GetFormsParams` containing the following parameters:
   *
   * - `search`: The search string.
   *
   * - `active`: The active flag.
   *
   * @return Success
   */
  GetForms(params: FormsService.GetFormsParams): __Observable<ApiPagedResponseICollectionFormTemplate> {
    return this.GetFormsResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseICollectionFormTemplate)
    );
  }

  /**
   * Creates the Form Template.
   * @param form The form.
   * @return Success
   */
  CreateFormResponse(form?: Form): __Observable<__StrictHttpResponse<ApiResponseForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/SymplastForms/Forms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseForm>;
      })
    );
  }
  /**
   * Creates the Form Template.
   * @param form The form.
   * @return Success
   */
  CreateForm(form?: Form): __Observable<ApiResponseForm> {
    return this.CreateFormResponse(form).pipe(
      __map(_r => _r.body as ApiResponseForm)
    );
  }

  /**
   * Updates the Form Template.
   * @param form The form.
   * @return Success
   */
  UpdateFormResponse(form?: Form): __Observable<__StrictHttpResponse<ApiResponseForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = form;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SymplastForms/Forms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseForm>;
      })
    );
  }
  /**
   * Updates the Form Template.
   * @param form The form.
   * @return Success
   */
  UpdateForm(form?: Form): __Observable<ApiResponseForm> {
    return this.UpdateFormResponse(form).pipe(
      __map(_r => _r.body as ApiResponseForm)
    );
  }

  /**
   * Gets a Form Template by Id.
   * @param formId The form id.
   * @return Success
   */
  GetFormByIdResponse(formId: string): __Observable<__StrictHttpResponse<ApiResponseForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SymplastForms/Forms/${encodeURIComponent(String(formId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseForm>;
      })
    );
  }
  /**
   * Gets a Form Template by Id.
   * @param formId The form id.
   * @return Success
   */
  GetFormById(formId: string): __Observable<ApiResponseForm> {
    return this.GetFormByIdResponse(formId).pipe(
      __map(_r => _r.body as ApiResponseForm)
    );
  }

  /**
   * Sets the Form Template as default.
   * @param formId The form id.
   * @return Success
   */
  SetFormAsDefaultResponse(formId?: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (formId != null) __params = __params.set('formId', formId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SymplastForms/Forms/default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Sets the Form Template as default.
   * @param formId The form id.
   * @return Success
   */
  SetFormAsDefault(formId?: string): __Observable<ApiResponseOkResponse> {
    return this.SetFormAsDefaultResponse(formId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Clones the Form Template.
   * @param params The `FormsService.CloneFormParams` containing the following parameters:
   *
   * - `formId`: The form id.
   *
   * - `name`: The name.
   *
   * @return Success
   */
  CloneFormResponse(params: FormsService.CloneFormParams): __Observable<__StrictHttpResponse<ApiResponseForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SymplastForms/Forms/${encodeURIComponent(String(params.formId))}/clone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseForm>;
      })
    );
  }
  /**
   * Clones the Form Template.
   * @param params The `FormsService.CloneFormParams` containing the following parameters:
   *
   * - `formId`: The form id.
   *
   * - `name`: The name.
   *
   * @return Success
   */
  CloneForm(params: FormsService.CloneFormParams): __Observable<ApiResponseForm> {
    return this.CloneFormResponse(params).pipe(
      __map(_r => _r.body as ApiResponseForm)
    );
  }

  /**
   * Archives the Form Template.
   * @param formId The form id.
   * @return Success
   */
  ArchiveFormResponse(formId: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SymplastForms/Forms/${encodeURIComponent(String(formId))}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Archives the Form Template.
   * @param formId The form id.
   * @return Success
   */
  ArchiveForm(formId: string): __Observable<ApiResponseOkResponse> {
    return this.ArchiveFormResponse(formId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Restores the Form Template.
   * @param formId The form id.
   * @return Success
   */
  RestoreFormResponse(formId: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SymplastForms/Forms/${encodeURIComponent(String(formId))}/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Restores the Form Template.
   * @param formId The form id.
   * @return Success
   */
  RestoreForm(formId: string): __Observable<ApiResponseOkResponse> {
    return this.RestoreFormResponse(formId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module FormsService {

  /**
   * Parameters for GetForms
   */
  export interface GetFormsParams {

    /**
     * The search string.
     */
    search?: string;

    /**
     * The active flag.
     */
    active?: boolean;
  }

  /**
   * Parameters for CloneForm
   */
  export interface CloneFormParams {

    /**
     * The form id.
     */
    formId: string;

    /**
     * The name.
     */
    name?: string;
  }
}

export { FormsService }
