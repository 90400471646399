import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadTypePurposes, ReloadTypePurposes } from './type-purposes.actions';
import { ITypePurposesStateModel } from './type-purposes.model';
import { UserDictionariesService } from '@symplast/generated-clients/web-portal';

@State<ITypePurposesStateModel>({
    name: 'typePurposes',
    defaults: {
        typePurposes: [],
        loading: false,
    },
})
@Injectable()
export class TypePurposesState {
    constructor(private userDictionaryService: UserDictionariesService) {}

    @Action(LoadTypePurposes)
    public load(context: StateContext<ITypePurposesStateModel>, { refresh }: LoadTypePurposes) {
        if (!context.getState().typePurposes.length || refresh) {
            context.patchState({ loading: true });
            this.userDictionaryService
                .GetTypePurposes()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    context.patchState({
                        typePurposes: [...response.result].sort((a, b) =>
                            a.appointmentTypePurposeName.localeCompare(b.appointmentTypePurposeName),
                        ),
                    });
                });
        }
    }

    @Action(ReloadTypePurposes)
    public reload(context: StateContext<ITypePurposesStateModel>) {
        context.dispatch(new LoadTypePurposes(true));
    }
}
