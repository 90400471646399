import { TemplateVariable } from '@symplast/generated-clients/web-portal';
import { WysiwygTemplateVariablesAddon } from './template-variables.addon';
import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';

/**
 * Replaces variables with span elements for better visual representation, displaying the variable description instead of the variable code.
 * @param input The input html string to process.
 * @param dict The dictionary of variables to use for replacement.
 * @returns The processed html string.
 */
function replaceVariablesWithSpans(input: string, dict: Record<string, TemplateVariable>): string {
    // Step 1: Find all existing span elements and replace them with unique markers
    const spanRegex = /<span class="symplast-tinymce-noneditable"[^>]*>[^<]*<\/span>/g;
    const spans: string[] = [];
    let tempInput = input.replace(spanRegex, (match) => {
        spans.push(match);

        return `##SPAN${spans.length - 1}##`;
    });

    // Step 2: Replace variables with span elements
    tempInput = tempInput.replace(/{(\w+)}/g, (_, variable) => {
        const variableInfo = dict[variable];

        if (variableInfo) {
            return `<span class="symplast-tinymce-noneditable" data-mce-content="{${variableInfo.code}}" contenteditable="false">{${variableInfo.description}}</span>`;
        }

        // Leave as is if the variable is not found in the dictionary
        return `{${variable}}`;
    });

    // Step 3: Restore the original span elements
    tempInput = tempInput.replace(/##SPAN(\d+)##/g, (_, index) => spans[parseInt(index, 10)]);

    return tempInput;
}

export interface WysiwygTemplateVariablePrettifierOptions {
    variablesDictionary?: Record<string, TemplateVariable>;
}

/**
 * An addon for the WYSIWYG editor that replaces variables with span elements for better visual representation,
 * displaying the variable description instead of the variable code.
 *
 * !Important. After the content is prettified, you need to use `editor.getContent({ format: 'html' })` instead of `editor.getContent({ format: 'text' })` to get the right variable code.
 */
export class WysiwygTemplateVariablePrettifierAddon extends WysiwygTemplateVariablesAddon {
    public name = 'template-variable-prettifier';
    public dictionary: Record<string, TemplateVariable>;

    constructor(options?: WysiwygTemplateVariablePrettifierOptions) {
        super();

        this.dictionary = options?.variablesDictionary || {};
    }

    public init(config: WysiwygEditorConfiguration): void {
        config.registerSetupCallback((editor) => {
            editor.on('BeforeSetContent', (event) => {
                const content = event.content;

                event.content = replaceVariablesWithSpans(content, this.dictionary);
            });
        });
    }
}
