<div class="container">
    <div #backdrop class="backdrop">
        <div
            #highlightElement
            class="symplast-highlights-element"
            [ngStyle]="highlightElementContainerStyle"
            [innerHTML]="highlightedText"></div>
    </div>
    <ng-content select="[symplastTextareaHighlightElement]"></ng-content>
</div>
