export enum Device {
    Windows = 'Windows',
    Android = 'Android',
    Mac = 'Mac',
    iPad = 'iPad',
    iPhone = 'iPhone',
    iOS = 'iOS',
    Browser = 'Browser',
    EmbeddedView = 'EmbeddedView',
}
