import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Provider, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApiInterceptor } from './app.api-interceptor';
import { AppComponent } from './app.component';
import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ConfigurationApplicationService } from '@symplast/services/shared';
import { NgxsStoreModule } from './store/store.module';
import { SidebarModule, SidebarPositionEnum, UiSharedModule, WYSIWYG_EDITOR_SCRIPT_URL } from '@symplast/ui/shared';
import { LoaderComponent } from './shared/loader/loader.component';
import { ERROR_HANDLERS_PROVIDER, BUGSNAG_PROVIDER } from './core/providers';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

export const API_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: ApiInterceptor,
    multi: true,
};

const ngxMaskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        LoaderComponent,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxsStoreModule,
        UiSharedModule,
        SidebarModule.forRoot({
            position: SidebarPositionEnum.RIGHT,
            hasBackdrop: false,
            closeOnBackdropClick: true,
            closeOnNavigation: true,
            width: '500px',
        }),
        AngularSvgIconModule.forRoot(),
    ],
    providers: [
        importProvidersFrom([MatNativeDateModule, MatLegacySnackBarModule, MatLegacyDialogModule, MatLegacyTooltipModule]),
        provideEnvironmentNgxMask(ngxMaskOptions),
        BUGSNAG_PROVIDER,
        ERROR_HANDLERS_PROVIDER,
        {
            provide: WYSIWYG_EDITOR_SCRIPT_URL,
            useValue: '/assets/tinymce/tinymce.min.js',
        },
        API_INTERCEPTOR_PROVIDER,
        CurrencyPipe,
        DatePipe,
        PercentPipe,
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigurationApplicationService.webAppFactory,
            deps: [ConfigurationApplicationService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
