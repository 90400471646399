import { Pipe, PipeTransform } from '@angular/core';
import { HighlightService } from '../services/highlight.service';

@Pipe({
    name: 'highlightBirthday',
    standalone: true,
})
export class HighlightBirthdayPipe implements PipeTransform {
    constructor(private highlightService: HighlightService) {}
    public transform(value: string, args: string): string {
        return this.highlightService.highlightDob(value, args);
    }
}
