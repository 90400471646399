<div class="symplast-dialog" [class.symplast-dialog--fixed-footer]="footer?.fixed">
    <div class="symplast-dialog__header" [class.symplast-dialog__header--full-width]="header?.fullWidth">
        <ng-content select="symplast-dialog-header, [symplast-dialog-header], [symplastDialogHeader]"></ng-content>
    </div>
    <div class="symplast-dialog__body">
        <div class="symplast-dialog__content" [ngClass]="'symplast-dialog__content--' + content?.paddings">
            <ng-content select="symplast-dialog-content, [symplast-dialog-content], [symplastDialogContent]"></ng-content>
        </div>
        <div class="symplast-dialog__footer">
            <ng-content select="symplast-dialog-footer, [symplast-dialog-footer], [symplastDialogFooter]"></ng-content>
        </div>
    </div>
</div>
