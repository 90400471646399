/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseGaldermaSettings } from '../models/api-response-galderma-settings';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { GaldermaSettingsUpdateRequest } from '../models/galderma-settings-update-request';
import { ApiResponseIEnumerableGaldermaProduct } from '../models/api-response-ienumerable-galderma-product';
import { GaldermaProductLinksUpdateRequest } from '../models/galderma-product-links-update-request';
@Injectable({
  providedIn: 'root',
})
class GaldermaAspireService extends __BaseService {
  static readonly GetGaldermaSettingsPath = '/GaldermaAspire/Settings';
  static readonly UpdateGaldermaSettingsPath = '/GaldermaAspire/Settings';
  static readonly GetGaldermaProductsPath = '/GaldermaAspire/Products';
  static readonly UpdateGaldermaProductLinksPath = '/GaldermaAspire/Products/{productId}/Links';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get Galderma settings.
   * @return Success
   */
  GetGaldermaSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseGaldermaSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GaldermaAspire/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseGaldermaSettings>;
      })
    );
  }
  /**
   * The get Galderma settings.
   * @return Success
   */
  GetGaldermaSettings(): __Observable<ApiResponseGaldermaSettings> {
    return this.GetGaldermaSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseGaldermaSettings)
    );
  }

  /**
   * The update settings.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateGaldermaSettingsResponse(updateRequest?: GaldermaSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/GaldermaAspire/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update settings.
   * @param updateRequest The update request.
   * @return Success
   */
  UpdateGaldermaSettings(updateRequest?: GaldermaSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateGaldermaSettingsResponse(updateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get Galderma products.
   * @return Success
   */
  GetGaldermaProductsResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableGaldermaProduct>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GaldermaAspire/Products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableGaldermaProduct>;
      })
    );
  }
  /**
   * The get Galderma products.
   * @return Success
   */
  GetGaldermaProducts(): __Observable<ApiResponseIEnumerableGaldermaProduct> {
    return this.GetGaldermaProductsResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableGaldermaProduct)
    );
  }

  /**
   * The get Galderma products.
   * @param params The `GaldermaAspireService.UpdateGaldermaProductLinksParams` containing the following parameters:
   *
   * - `productId`: The product Id.
   *
   * - `updateRequest`: The update Request.
   *
   * @return Success
   */
  UpdateGaldermaProductLinksResponse(params: GaldermaAspireService.UpdateGaldermaProductLinksParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/GaldermaAspire/Products/${encodeURIComponent(String(params.productId))}/Links`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The get Galderma products.
   * @param params The `GaldermaAspireService.UpdateGaldermaProductLinksParams` containing the following parameters:
   *
   * - `productId`: The product Id.
   *
   * - `updateRequest`: The update Request.
   *
   * @return Success
   */
  UpdateGaldermaProductLinks(params: GaldermaAspireService.UpdateGaldermaProductLinksParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateGaldermaProductLinksResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module GaldermaAspireService {

  /**
   * Parameters for UpdateGaldermaProductLinks
   */
  export interface UpdateGaldermaProductLinksParams {

    /**
     * The product Id.
     */
    productId: number;

    /**
     * The update Request.
     */
    updateRequest?: GaldermaProductLinksUpdateRequest;
  }
}

export { GaldermaAspireService }
