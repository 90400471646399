import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UserTabsService } from '../../../services/user-tabs.service';
import { environment } from '../../../../environments/environment';

export enum MenuState {
    OPEN = 'open',
    COLLAPSED = 'collapsed',
}

@Component({
    selector: 'app-menu',
    standalone: true,
    imports: [CommonModule, RouterModule, AngularSvgIconModule],
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    @Output() public changeState = new EventEmitter<MenuState>();
    public isOpen = false;
    public readonly version = environment.version;

    constructor(public readonly userTabsService: UserTabsService) {}

    ngOnInit(): void {}

    public toggle(): void {
        this.isOpen = !this.isOpen;
        this.changeState.emit(this.isOpen ? MenuState.OPEN : MenuState.COLLAPSED);
    }
}
