/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableReferralSource } from '../models/api-response-ienumerable-referral-source';
import { ApiResponseReferralSource } from '../models/api-response-referral-source';
import { ReferralSourceCreateRequest } from '../models/referral-source-create-request';
import { ReferralSourceUpdateRequest } from '../models/referral-source-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class ReferralSourcesService extends __BaseService {
  static readonly ReferralSourcesPath = '/ReferralSources';
  static readonly CreateReferralSourcePath = '/ReferralSources';
  static readonly GetReferralSourceByIdPath = '/ReferralSources/{id}';
  static readonly UpdateReferralSourcePath = '/ReferralSources/{id}';
  static readonly DeleteReferralSourcePath = '/ReferralSources/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets referral sources list.
   * @param includeDeleted If true, include deleted referral sources.
   * @return Success
   */
  ReferralSourcesResponse(includeDeleted?: boolean): __Observable<__StrictHttpResponse<ApiResponseIEnumerableReferralSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (includeDeleted != null) __params = __params.set('includeDeleted', includeDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ReferralSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableReferralSource>;
      })
    );
  }
  /**
   * Gets referral sources list.
   * @param includeDeleted If true, include deleted referral sources.
   * @return Success
   */
  ReferralSources(includeDeleted?: boolean): __Observable<ApiResponseIEnumerableReferralSource> {
    return this.ReferralSourcesResponse(includeDeleted).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableReferralSource)
    );
  }

  /**
   * Creates new referral source.
   * @param referralSourceCreateRequest The referral source create request.
   * @return Success or Created
   */
  CreateReferralSourceResponse(referralSourceCreateRequest?: ReferralSourceCreateRequest): __Observable<__StrictHttpResponse<ApiResponseReferralSource | ApiResponseReferralSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = referralSourceCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ReferralSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseReferralSource | ApiResponseReferralSource>;
      })
    );
  }
  /**
   * Creates new referral source.
   * @param referralSourceCreateRequest The referral source create request.
   * @return Success or Created
   */
  CreateReferralSource(referralSourceCreateRequest?: ReferralSourceCreateRequest): __Observable<ApiResponseReferralSource | ApiResponseReferralSource> {
    return this.CreateReferralSourceResponse(referralSourceCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseReferralSource | ApiResponseReferralSource)
    );
  }

  /**
   * Gets detailed referral source info.
   * @param params The `ReferralSourcesService.GetReferralSourceByIdParams` containing the following parameters:
   *
   * - `id`: referral source id.
   *
   * - `includeDeletedSubReferrals`: Include deleted sub-sources.
   *
   * @return Success
   */
  GetReferralSourceByIdResponse(params: ReferralSourcesService.GetReferralSourceByIdParams): __Observable<__StrictHttpResponse<ApiResponseReferralSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeDeletedSubReferrals != null) __params = __params.set('includeDeletedSubReferrals', params.includeDeletedSubReferrals.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ReferralSources/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseReferralSource>;
      })
    );
  }
  /**
   * Gets detailed referral source info.
   * @param params The `ReferralSourcesService.GetReferralSourceByIdParams` containing the following parameters:
   *
   * - `id`: referral source id.
   *
   * - `includeDeletedSubReferrals`: Include deleted sub-sources.
   *
   * @return Success
   */
  GetReferralSourceById(params: ReferralSourcesService.GetReferralSourceByIdParams): __Observable<ApiResponseReferralSource> {
    return this.GetReferralSourceByIdResponse(params).pipe(
      __map(_r => _r.body as ApiResponseReferralSource)
    );
  }

  /**
   * Updates referral source info.
   * @param params The `ReferralSourcesService.UpdateReferralSourceParams` containing the following parameters:
   *
   * - `id`: referral source id.
   *
   * - `referralSourceUpdateRequest`: The referral source update request.
   *
   * @return Success
   */
  UpdateReferralSourceResponse(params: ReferralSourcesService.UpdateReferralSourceParams): __Observable<__StrictHttpResponse<ApiResponseReferralSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.referralSourceUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ReferralSources/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseReferralSource>;
      })
    );
  }
  /**
   * Updates referral source info.
   * @param params The `ReferralSourcesService.UpdateReferralSourceParams` containing the following parameters:
   *
   * - `id`: referral source id.
   *
   * - `referralSourceUpdateRequest`: The referral source update request.
   *
   * @return Success
   */
  UpdateReferralSource(params: ReferralSourcesService.UpdateReferralSourceParams): __Observable<ApiResponseReferralSource> {
    return this.UpdateReferralSourceResponse(params).pipe(
      __map(_r => _r.body as ApiResponseReferralSource)
    );
  }

  /**
   * Deletes a referral source.
   * @param id the referral source id.
   * @return Success
   */
  DeleteReferralSourceResponse(id: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ReferralSources/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a referral source.
   * @param id the referral source id.
   * @return Success
   */
  DeleteReferralSource(id: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteReferralSourceResponse(id).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module ReferralSourcesService {

  /**
   * Parameters for GetReferralSourceById
   */
  export interface GetReferralSourceByIdParams {

    /**
     * referral source id.
     */
    id: number;

    /**
     * Include deleted sub-sources.
     */
    includeDeletedSubReferrals?: boolean;
  }

  /**
   * Parameters for UpdateReferralSource
   */
  export interface UpdateReferralSourceParams {

    /**
     * referral source id.
     */
    id: number;

    /**
     * The referral source update request.
     */
    referralSourceUpdateRequest?: ReferralSourceUpdateRequest;
  }
}

export { ReferralSourcesService }
