import { Injectable } from '@angular/core';
import { TenantPublicResponse } from '@symplast/generated-clients/tenants';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationUserData } from './authentication.model';

/**
 * Service for storing user authentication details. Required for pass-through authorization with legacy webportal
 */
@Injectable({
    providedIn: 'root',
})
export class AuthenticationDataStorage {
    private readonly currentUserKeyID = 'CurrentUser';
    private readonly maxAgeCookieLimit = 1 / (24 * 3); // it is 20 minutes
    private readonly domain = window.location.href.includes('localhost') ? 'localhost' : '.symplast.com';

    constructor(private readonly cookieService: CookieService) {}

    public get data(): AuthenticationUserData | null {
        const storedData = this.cookieService.get(this.currentUserKeyID);

        return !!storedData ? JSON.parse(decodeURIComponent(storedData)) : null;
    }

    public saveData(access_token: string, tenant: TenantPublicResponse): void {
        const cookieToSave: AuthenticationUserData = {
            AccessToken: access_token,
            TenantId: tenant.tenantId.toString(),
            TenantApiUrl: `${tenant.webPortalUrl}/service`,
            TenantSettingsApi: tenant.tenantConfiguration.url,
            Environment: tenant.type,
        };

        this.cookieService.set(this.currentUserKeyID, JSON.stringify(cookieToSave), this.maxAgeCookieLimit, '/', this.domain, true);
    }

    public keepDataAlive(): void {
        const currentData = this.data;

        if (!currentData) {
            return;
        }

        this.cookieService.set(this.currentUserKeyID, JSON.stringify(currentData), this.maxAgeCookieLimit, '/', this.domain, true);
    }

    public clearData(): void {
        this.cookieService.delete(this.currentUserKeyID, '/', this.domain);
    }
}
