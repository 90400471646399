/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { LocationPricesUpdateRequest } from '../models/location-prices-update-request';
@Injectable({
  providedIn: 'root',
})
class ItemsService extends __BaseService {
  static readonly UpdateLocationPricesPath = '/Items/{itemId}/LocationPrice';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Update location prices
   * @param params The `ItemsService.UpdateLocationPricesParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateLocationPricesResponse(params: ItemsService.UpdateLocationPricesParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/LocationPrice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update location prices
   * @param params The `ItemsService.UpdateLocationPricesParams` containing the following parameters:
   *
   * - `itemId`: The item id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateLocationPrices(params: ItemsService.UpdateLocationPricesParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateLocationPricesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module ItemsService {

  /**
   * Parameters for UpdateLocationPrices
   */
  export interface UpdateLocationPricesParams {

    /**
     * The item id.
     */
    itemId: number;

    /**
     * The update request.
     */
    updateRequest?: LocationPricesUpdateRequest;
  }
}

export { ItemsService }
