import { Injectable } from '@angular/core';
import { ReferralSourcesService, ReferralSource } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadReferralSources, ReloadReferralSources } from './referral-sources.actions';
import { IReferralSourcesStateModel, ReferralSourcesFlat } from './referral-sources.model';

@State<IReferralSourcesStateModel>({
    name: 'ReferralSources',
    defaults: {
        referralSources: [],
        referralSourcesFlat: [],
        loading: false,
    },
})
@Injectable()
export class ReferralSourcesState {
    constructor(private referralSourcesService: ReferralSourcesService) {}

    @Action(LoadReferralSources)
    public load(context: StateContext<IReferralSourcesStateModel>, { refresh }: LoadReferralSources) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.referralSources.length) || refresh) {
            context.patchState({ loading: true });
            this.referralSourcesService
                .ReferralSources()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result = [] }) => {
                    const referralSources = result.sort((a, b) => (a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase() ? 1 : -1));

                    context.patchState({
                        referralSources,
                        referralSourcesFlat: this.mapReferralSources([...referralSources]),
                    });
                });
        }
    }

    @Action(ReloadReferralSources)
    public reload(context: StateContext<IReferralSourcesStateModel>) {
        context.dispatch(new LoadReferralSources(true));
    }

    private mapReferralSources(referralSources: ReferralSource[]) {
        const flatReferralSource = [];

        referralSources.forEach((refSource) => {
            const newRefSource = { ...refSource };
            const subRef = refSource.subReferrals
                .sort((a, b) => (a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase() ? 1 : -1))
                .map((subR) => {
                    const newSubR = { ...subR } as ReferralSourcesFlat;

                    newSubR.level = 1;

                    return newSubR;
                });

            delete newRefSource.subReferrals;
            flatReferralSource.push(newRefSource);
            flatReferralSource.push(...subRef);
        });

        return flatReferralSource;
    }
}
