/**
 * Extracts text content from HTML
 * @param html HTML content
 * @returns Text content
 */
export function extractTextFromHTML(html: string): string {
    const tempDiv = document.createElement('div');

    tempDiv.innerHTML = html;

    return tempDiv.textContent || tempDiv.innerText || '';
}
