/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { LocationItemTypeCreateRequest } from '../models/location-item-type-create-request';
import { ApiResponseIEnumerableLocationItemType } from '../models/api-response-ienumerable-location-item-type';
import { ApiResponseLocationItemType } from '../models/api-response-location-item-type';
@Injectable({
  providedIn: 'root',
})
class GlobalTaxDefaultsService extends __BaseService {
  static readonly CreateGlobalTaxPath = '/GlobalTaxDefaults';
  static readonly GetGlobalTaxPath = '/GlobalTaxDefaults';
  static readonly DeleteGlobalTaxPath = '/GlobalTaxDefaults/{locationItemTypeToDeleteId}';
  static readonly GetGlobalTaxByIdPath = '/GlobalTaxDefaults/{locationItemTypeId}';
  static readonly UpdateGlobalTaxPath = '/GlobalTaxDefaults/{locationItemTypeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates a LocationItemType
   * @param locationItemTypeCreationRequest The  LocationItemType creation requests.
   * @return Success
   */
  CreateGlobalTaxResponse(locationItemTypeCreationRequest?: LocationItemTypeCreateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = locationItemTypeCreationRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/GlobalTaxDefaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Creates a LocationItemType
   * @param locationItemTypeCreationRequest The  LocationItemType creation requests.
   * @return Success
   */
  CreateGlobalTax(locationItemTypeCreationRequest?: LocationItemTypeCreateRequest): __Observable<ApiResponseOkResponse> {
    return this.CreateGlobalTaxResponse(locationItemTypeCreationRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets all location item types
   * @return Success
   */
  GetGlobalTaxResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableLocationItemType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GlobalTaxDefaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableLocationItemType>;
      })
    );
  }
  /**
   * Gets all location item types
   * @return Success
   */
  GetGlobalTax(): __Observable<ApiResponseIEnumerableLocationItemType> {
    return this.GetGlobalTaxResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableLocationItemType)
    );
  }

  /**
   * Disables a LocationItemType
   * @param locationItemTypeToDeleteId The location Item Type Id to soft delete.
   * @return Success
   */
  DeleteGlobalTaxResponse(locationItemTypeToDeleteId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/GlobalTaxDefaults/${encodeURIComponent(String(locationItemTypeToDeleteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Disables a LocationItemType
   * @param locationItemTypeToDeleteId The location Item Type Id to soft delete.
   * @return Success
   */
  DeleteGlobalTax(locationItemTypeToDeleteId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteGlobalTaxResponse(locationItemTypeToDeleteId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets a location item types
   * @param locationItemTypeId the location item type id to modify.
   * @return Success
   */
  GetGlobalTaxByIdResponse(locationItemTypeId: number): __Observable<__StrictHttpResponse<ApiResponseLocationItemType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GlobalTaxDefaults/${encodeURIComponent(String(locationItemTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseLocationItemType>;
      })
    );
  }
  /**
   * Gets a location item types
   * @param locationItemTypeId the location item type id to modify.
   * @return Success
   */
  GetGlobalTaxById(locationItemTypeId: number): __Observable<ApiResponseLocationItemType> {
    return this.GetGlobalTaxByIdResponse(locationItemTypeId).pipe(
      __map(_r => _r.body as ApiResponseLocationItemType)
    );
  }

  /**
   * Modify an Account
   * @param params The `GlobalTaxDefaultsService.UpdateGlobalTaxParams` containing the following parameters:
   *
   * - `locationItemTypeId`: the location item type id to modify.
   *
   * - `defaultTaxId`: the new value for default tax.
   *
   * @return Success
   */
  UpdateGlobalTaxResponse(params: GlobalTaxDefaultsService.UpdateGlobalTaxParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.defaultTaxId != null) __params = __params.set('defaultTaxId', params.defaultTaxId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/GlobalTaxDefaults/${encodeURIComponent(String(params.locationItemTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Modify an Account
   * @param params The `GlobalTaxDefaultsService.UpdateGlobalTaxParams` containing the following parameters:
   *
   * - `locationItemTypeId`: the location item type id to modify.
   *
   * - `defaultTaxId`: the new value for default tax.
   *
   * @return Success
   */
  UpdateGlobalTax(params: GlobalTaxDefaultsService.UpdateGlobalTaxParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateGlobalTaxResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module GlobalTaxDefaultsService {

  /**
   * Parameters for UpdateGlobalTax
   */
  export interface UpdateGlobalTaxParams {

    /**
     * the location item type id to modify.
     */
    locationItemTypeId: number;

    /**
     * the new value for default tax.
     */
    defaultTaxId?: number;
  }
}

export { GlobalTaxDefaultsService }
