<mat-form-field *ngFor="let input of inputs.controls; let i = index" data-e2e="otp-field">
    <input
        #inputElement
        matInput
        data-e2e="otp-field__input"
        [placeholder]="focusedIdx === i ? '' : '•'"
        [formControl]="input"
        [attr.autocomplete]="i === 0 ? 'one-time-code' : null"
        type="text"
        inputmode="numeric"
        (keydown)="handleKeyDown($event, i)"
        (keypress)="handleKeyPress($event, i)"
        (input)="handleInput()"
        (paste)="handlePaste($event, i)"
        (focus)="handleFocus($event, i)"
        (blur)="handleBlur()" />
</mat-form-field>
