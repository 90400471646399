/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIReadOnlyCollectionAppointmentTypePurposeResponse } from '../models/api-response-iread-only-collection-appointment-type-purpose-response';
@Injectable({
  providedIn: 'root',
})
class AppointmentTypesPurposesService extends __BaseService {
  static readonly GetAppointmentTypesAndPurposesPath = '/AppointmentTypesPurposes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all appointment types and purposes.
   * @return Success
   */
  GetAppointmentTypesAndPurposesResponse(): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentTypePurposeResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentTypesPurposes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentTypePurposeResponse>;
      })
    );
  }
  /**
   * Get all appointment types and purposes.
   * @return Success
   */
  GetAppointmentTypesAndPurposes(): __Observable<ApiResponseIReadOnlyCollectionAppointmentTypePurposeResponse> {
    return this.GetAppointmentTypesAndPurposesResponse().pipe(
      __map(_r => _r.body as ApiResponseIReadOnlyCollectionAppointmentTypePurposeResponse)
    );
  }
}

module AppointmentTypesPurposesService {
}

export { AppointmentTypesPurposesService }
