import { InjectionToken } from '@angular/core';
import { environment } from './../../../environments/environment';

export interface IEnvironment {
    production: boolean;
    version: string;
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('A reference to the environment object', {
    providedIn: 'root',
    factory: () => environment,
});
