import { Pipe, PipeTransform } from '@angular/core';
import { HighlightPipe } from './highlight.pipe';

@Pipe({
    standalone: true,
    name: 'highlightPhone',
})
export class HighlightPhonePipe implements PipeTransform {
    constructor(private highlightPipe: HighlightPipe) {}

    transform(value: string[], args: string): string {
        if (!value || value.length !== 2) {
            return '';
        }

        const searchText = args?.replace(/"/g, '').replace(/-/g, '');

        if (!searchText) {
            return value[1];
        }

        const phone = value[0];
        const displayPhone = value[1];

        if (searchText) {
            if (phone !== displayPhone) {
                const index = phone.indexOf(searchText);

                if (index !== -1) {
                    const initIndex = this.offsetDisplayPhone(index);
                    const endIndex = this.offsetDisplayPhone(index + searchText.length);

                    return (
                        displayPhone.slice(0, initIndex) +
                        '<span class="highlight">' +
                        displayPhone.slice(initIndex, endIndex) +
                        '</span>' +
                        displayPhone.slice(endIndex)
                    );
                }
            } else {
                return this.highlightPipe.transform(displayPhone, searchText);
            }
        }

        return displayPhone;
    }

    private offsetDisplayPhone(index: number): number {
        if (index === 0) {
            return index;
        }
        if (index < 3) {
            return index + 1;
        }

        if (index < 7) {
            return index + 3;
        }

        return index + 4;
    }
}
