import { Selector } from '@ngxs/store';
import { ITimezonesStateModel } from './timezones.model';
import { TimezonesState } from './timezones.state';

export class TimezonesSelectors {
    @Selector([TimezonesState])
    public static loading(state: ITimezonesStateModel): boolean {
        return state.loading;
    }

    @Selector([TimezonesState])
    public static timezones(state: ITimezonesStateModel): ITimezonesStateModel['timezones'] {
        return state.timezones;
    }
}
