import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

type DurationType = 'days' | 'hours' | 'minutes' | 'seconds';

@Pipe({ name: 'duration', standalone: true })
export class DurationPipe implements PipeTransform {
    public transform(value: number, valueType: DurationType = 'minutes', precision: DurationType = 'minutes'): string {
        const duration = moment.duration(value, valueType);

        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const milliseconds = duration.milliseconds();

        switch (precision) {
            case 'days':
                return this.formatDuration(days);
            case 'hours':
                return this.formatDuration(days, hours);
            case 'minutes':
                return this.formatDuration(days, hours, minutes);
            case 'seconds':
                return this.formatDuration(days, hours, minutes, seconds);
            default:
                return this.formatDuration(days, hours, minutes, seconds, milliseconds);
        }
    }

    private formatDuration(days?: number, hours?: number, minutes?: number, seconds?: number, milliseconds?: number): string {
        const formattedDuration = [];

        if (days) {
            formattedDuration.push(`${days}d`);
        }

        if (hours) {
            formattedDuration.push(`${hours}h`);
        }

        if (minutes) {
            formattedDuration.push(`${minutes}m`);
        }

        if (seconds) {
            formattedDuration.push(`${seconds}s`);
        }

        if (milliseconds) {
            formattedDuration.push(`${milliseconds}ms`);
        }

        return formattedDuration.join(' ');
    }
}
