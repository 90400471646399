import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppSettingsSelectors } from '@symplast/services/shared';
import { Observable } from 'rxjs';

@Directive({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hiddenInWebView]',
})
export class HiddenInWebViewDirective implements OnInit {
    @Select(AppSettingsSelectors.isWebView)
    public isWebView$: Observable<boolean>;

    private isHidden = true;

    constructor(private _templateRef: TemplateRef<any>, private _viewContainer: ViewContainerRef) {}

    ngOnInit(): void {
        this.isWebView$.subscribe((isWebView) => {
            this.updateView(!isWebView);
        });
    }

    private updateView(needToShow: boolean): void {
        if (needToShow) {
            if (this.isHidden) {
                this._viewContainer.createEmbeddedView(this._templateRef);
                this.isHidden = false;
            }
        } else {
            this._viewContainer.clear();
            this.isHidden = true;
        }
    }
}
