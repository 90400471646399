import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'refund' })
export class RefundPipe implements PipeTransform {
    transform(value: number): string | null {
        if (value < 0) {
            return `(${this.currencyPipe.transform(-value)})`;
        } else {
            return this.currencyPipe.transform(value);
        }
    }

    constructor(private currencyPipe: CurrencyPipe) {}
}
