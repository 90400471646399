import { AppSettingsState } from './app-settings';
import { BrandsState } from './brands';
import { CountriesState } from './countries';
import { GendersState } from './genders';
import { InsuranceSettingsState } from './insurance-settings';
import { ItemTypesState } from './item-types';
import { LocationTypesState } from './location-types';
import { LocationsState } from './locations';
import { ProvidersState } from './providers';
import { ReferralSourcesState } from './referral-sources';
import { StatesState } from './states';
import { TimezonesState } from './timezones';
import { TypePurposesState } from './type-purposes';
import { UserRolesState } from './user-roles';
import { UserTypesState } from './user-types';
import { EmploymentTypesState } from './employment-types';
import { EthnicitiesState } from './ethnicities';
import { LanguagesState } from './languages';
import { RacesState } from './races';
import { MaritalStatusesState } from './marital-statuses';
import { TaxesState } from './taxes';
import { PaymentReasonsState } from './payment-reasons';
import { ItemsState } from './items';
import { PatientBalancesState } from './financials/patient-balances';
import { PracticeUsersState } from './practice-users';
import { OccasionsState } from './occasions';
import { GeneralSettingsState } from './general-settings';
import { OarSettingsState } from './oar-settings';
import { PaymentModesState } from './payment-modes';
import { FilterSetsState } from './filter-sets';

export * from './insurance-settings';
export * from './locations';
export * from './user-roles';
export * from './user-types';
export * from './genders';
export * from './type-purposes';
export * from './providers';
export * from './app-settings';
export * from './brands';
export * from './location-types';
export * from './countries';
export * from './timezones';
export * from './referral-sources';
export * from './states';
export * from './item-types';
export * from './employment-types';
export * from './ethnicities';
export * from './languages';
export * from './races';
export * from './marital-statuses';
export * from './taxes';
export * from './payment-reasons';
export * from './items';
export * from './financials/patient-balances';
export * from './practice-users';
export * from './occasions';
export * from './general-settings';
export * from './oar-settings';
export * from './payment-modes';
export * from './filter-sets';

export const rootState = [
    InsuranceSettingsState,
    LocationsState,
    UserRolesState,
    UserTypesState,
    GendersState,
    TypePurposesState,
    ProvidersState,
    AppSettingsState,
    BrandsState,
    ItemTypesState,
    LocationTypesState,
    CountriesState,
    TimezonesState,
    StatesState,
    EmploymentTypesState,
    ReferralSourcesState,
    EthnicitiesState,
    LanguagesState,
    RacesState,
    MaritalStatusesState,
    TaxesState,
    PaymentReasonsState,
    ItemsState,
    PatientBalancesState,
    PracticeUsersState,
    OccasionsState,
    GeneralSettingsState,
    OarSettingsState,
    PaymentModesState,
    FilterSetsState,
];
