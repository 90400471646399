/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseCompany } from '../models/api-response-company';
import { CompanyContactModifyRequest } from '../models/company-contact-modify-request';
import { CompanyAddressModifyRequest } from '../models/company-address-modify-request';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly GetCompanyPath = '/Company';
  static readonly UpdateCompanyBasicInfoPath = '/Company/Basic';
  static readonly UpdateCompanyContactInfoPath = '/Company/Contact';
  static readonly UpdateCompanyAddressInfoPath = '/Company/Address';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets company info.
   * @return Success
   */
  GetCompanyResponse(): __Observable<__StrictHttpResponse<ApiResponseCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCompany>;
      })
    );
  }
  /**
   * Gets company info.
   * @return Success
   */
  GetCompany(): __Observable<ApiResponseCompany> {
    return this.GetCompanyResponse().pipe(
      __map(_r => _r.body as ApiResponseCompany)
    );
  }

  /**
   * Updates basic company info.
   * @param params The `CompanyService.UpdateCompanyBasicInfoParams` containing the following parameters:
   *
   * - `Name`: Gets or sets company name.
   *
   * - `EIN`: Gets or sets company EIN.
   *
   * - `logo`:
   *
   * @return Success
   */
  UpdateCompanyBasicInfoResponse(params: CompanyService.UpdateCompanyBasicInfoParams): __Observable<__StrictHttpResponse<ApiResponseCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.EIN != null) __params = __params.set('EIN', params.EIN.toString());
    if (params.logo != null) { __formData.append('logo', params.logo as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Company/Basic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCompany>;
      })
    );
  }
  /**
   * Updates basic company info.
   * @param params The `CompanyService.UpdateCompanyBasicInfoParams` containing the following parameters:
   *
   * - `Name`: Gets or sets company name.
   *
   * - `EIN`: Gets or sets company EIN.
   *
   * - `logo`:
   *
   * @return Success
   */
  UpdateCompanyBasicInfo(params: CompanyService.UpdateCompanyBasicInfoParams): __Observable<ApiResponseCompany> {
    return this.UpdateCompanyBasicInfoResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCompany)
    );
  }

  /**
   * Updates company contact info.
   * @param companyContactModifyRequest The company contact info Modify Request.
   * @return Success
   */
  UpdateCompanyContactInfoResponse(companyContactModifyRequest?: CompanyContactModifyRequest): __Observable<__StrictHttpResponse<ApiResponseCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = companyContactModifyRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Company/Contact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCompany>;
      })
    );
  }
  /**
   * Updates company contact info.
   * @param companyContactModifyRequest The company contact info Modify Request.
   * @return Success
   */
  UpdateCompanyContactInfo(companyContactModifyRequest?: CompanyContactModifyRequest): __Observable<ApiResponseCompany> {
    return this.UpdateCompanyContactInfoResponse(companyContactModifyRequest).pipe(
      __map(_r => _r.body as ApiResponseCompany)
    );
  }

  /**
   * Updates company address info.
   * @param companyAddressModifyRequest The company address Modify Request.
   * @return Success
   */
  UpdateCompanyAddressInfoResponse(companyAddressModifyRequest?: CompanyAddressModifyRequest): __Observable<__StrictHttpResponse<ApiResponseCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = companyAddressModifyRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Company/Address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCompany>;
      })
    );
  }
  /**
   * Updates company address info.
   * @param companyAddressModifyRequest The company address Modify Request.
   * @return Success
   */
  UpdateCompanyAddressInfo(companyAddressModifyRequest?: CompanyAddressModifyRequest): __Observable<ApiResponseCompany> {
    return this.UpdateCompanyAddressInfoResponse(companyAddressModifyRequest).pipe(
      __map(_r => _r.body as ApiResponseCompany)
    );
  }
}

module CompanyService {

  /**
   * Parameters for UpdateCompanyBasicInfo
   */
  export interface UpdateCompanyBasicInfoParams {

    /**
     * Gets or sets company name.
     */
    Name: string;

    /**
     * Gets or sets company EIN.
     */
    EIN: string;
    logo?: Blob;
  }
}

export { CompanyService }
