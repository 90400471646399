import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { SYMPLAST_DIALOG_MODULE, SYMPLAST_DIALOG_PRESET } from '@symplast/ui/shared';
import { ConfirmationDialogData } from './confirmation-dialog.model';
import { PolymorphContentComponent } from '../../../../shared/polymorph-content/polymorph-content.component';
import { CommonModule } from '@angular/common';
import { TipsService } from '../../tips';
import { MatCheckboxModule } from '@angular/material/checkbox';

/** Do not use directly. Use only with ConfirmationDialogService */
@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        PolymorphContentComponent,
        MatCheckboxModule,
        ...SYMPLAST_DIALOG_MODULE,
    ],
    template: `
        <symplast-dialog data-e2e="symplast-confirmation-dialog">
            <symplast-dialog-header>
                <symplast-dialog-title data-e2e="symplast-confirmation-dialog__title">{{ data.title }}</symplast-dialog-title>

                <symplast-dialog-header-actions>
                    <button mat-icon-button [mat-dialog-close]="{ confirmed: false }" data-e2e="symplast-confirmation-dialog__close-btn">
                        <mat-icon>close</mat-icon>
                    </button>
                </symplast-dialog-header-actions>
            </symplast-dialog-header>

            <symplast-dialog-content class="text-center">
                <app-polymorph-content [content]="data.content" data-e2e="symplast-confirmation-dialog__content"></app-polymorph-content>

                <div class="checkbox-wrapper" *ngIf="this.data.checkbox?.tip">
                    <mat-checkbox color="primary" [checked]="tipChecked" (change)="tipsService.setTip(data.checkbox.tip, $event.checked)">
                        {{ data.checkbox.label || 'Do not show this message again' }}
                    </mat-checkbox>
                </div>
            </symplast-dialog-content>

            <symplast-dialog-footer>
                <button
                    *ngIf="data.deny"
                    mat-stroked-button
                    [mat-dialog-close]="{ confirmed: false }"
                    data-e2e="symplast-confirmation-dialog__deny-btn">
                    {{ data.deny.label }}
                </button>
                <button
                    *ngIf="data.confirm"
                    mat-flat-button
                    color="primary"
                    [mat-dialog-close]="{ confirmed: true }"
                    data-e2e="symplast-confirmation-dialog__confirm-btn">
                    {{ data.confirm.label }}
                </button>
            </symplast-dialog-footer>
        </symplast-dialog>
    `,
    styles: [
        `
            .checkbox-wrapper {
                margin-top: 16px;
            }
            button {
                margin-right: 0;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: SYMPLAST_DIALOG_PRESET, useValue: 'primary' }],
})
export class ConfirmationDialogComponent implements OnInit {
    public data: ConfirmationDialogData = inject(MAT_DIALOG_DATA);
    public tipsService = inject(TipsService);
    public tipChecked = false;

    public ngOnInit(): void {
        if (this.data.checkbox?.tip) {
            this.tipChecked = this.tipsService.checkTip(this.data.checkbox.tip);
        }
    }
}
