/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseCreditNote } from '../models/api-response-credit-note';
import { CreditNoteCreateRequest } from '../models/credit-note-create-request';
import { ApiResponseICollectionCreditNote } from '../models/api-response-icollection-credit-note';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { CreditNoteUpdateRequest } from '../models/credit-note-update-request';
import { ApiResponseICollectionAuditEntryHeader } from '../models/api-response-icollection-audit-entry-header';
import { ActionResult } from '../models/action-result';
import { ApiResponseString } from '../models/api-response-string';
import { ApiResponseIEnumerablePaidInvoice } from '../models/api-response-ienumerable-paid-invoice';
import { ItemGroupRequest } from '../models/item-group-request';
import { ApiResponseItemDiscountRate } from '../models/api-response-item-discount-rate';
@Injectable({
    providedIn: 'root',
})
class CreditNotesService extends __BaseService {
    static readonly CreateCreditNotePath = '/CreditNotes';
    static readonly GetCreditNotesPath = '/CreditNotes';
    static readonly DeleteCreditNotePath = '/CreditNotes/{creditNoteId}';
    static readonly GetCreditNoteByIdPath = '/CreditNotes/{creditNoteId}';
    static readonly UpdateCreditNotePath = '/CreditNotes/{creditNoteId}';
    static readonly GetCreditNoteHistoryPath = '/CreditNotes/{creditNoteId}/History';
    static readonly GetCreditNoteHtmlByIdPath = '/CreditNotes/{creditNoteId}/Html';
    static readonly GetCreditNotePdfByIdPath = '/CreditNotes/{creditNoteId}/Pdf';
    static readonly GetNextCreditNoteNumberPath = '/CreditNotes/NextCreditNoteNumber';
    static readonly GetPaidInvoicesByCreditNoteIdPath = '/CreditNotes/{creditNoteId}/PaidInvoices';
    static readonly UpdateCreditNoteStatusPath = '/CreditNotes/{creditNoteId}/Status';
    static readonly UpdateSharedWithPatientFlagForCreditNotePath = '/CreditNotes/{creditNoteId}/SharedWithPatient';
    static readonly AddItemGroupToCreditNotePath = '/CreditNotes/CreditNotes/{creditNoteId}/ItemGroups';
    static readonly AddMembershipToCreditNotePath = '/CreditNotes/CreditNotes/{creditNoteId}/Memberships/{membershipId}';
    static readonly DeleteCreditNoteMembershipPath = '/CreditNotes/CreditNotes/{creditNoteId}/Memberships/{creditNoteMembershipId}';
    static readonly GetCreditNoteItemDiscountPath =
        '/CreditNotes/CreditNotes/{creditNoteId}/CreditNoteItems/{creditNoteItemId}/DiscountRate';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Creates a credit note.
     * @param creditNoteCreateRequest The creditNote create request.
     * @return Success or Created
     */
    CreateCreditNoteResponse(
        creditNoteCreateRequest?: CreditNoteCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNote | ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = creditNoteCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/CreditNotes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote | ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * Creates a credit note.
     * @param creditNoteCreateRequest The creditNote create request.
     * @return Success or Created
     */
    CreateCreditNote(creditNoteCreateRequest?: CreditNoteCreateRequest): __Observable<ApiResponseCreditNote | ApiResponseCreditNote> {
        return this.CreateCreditNoteResponse(creditNoteCreateRequest).pipe(
            __map((_r) => _r.body as ApiResponseCreditNote | ApiResponseCreditNote),
        );
    }

    /**
     * Gets the creditNotes by the search criteria.
     * @param params The `CreditNotesService.GetCreditNotesParams` containing the following parameters:
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether creditNotes need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `InvoiceId`: Gets or sets the invoice identifier.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeCreditNoteItems`: Gets or sets a value indicating whether to include creditNote items or not.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CreditNoteNumberQuery`: Gets or sets the creditNote number query for creditNote number to contain.
     *
     * @return Success
     */
    GetCreditNotesResponse(
        params: CreditNotesService.GetCreditNotesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.Take != null) __params = __params.set('Take', params.Take.toString());
        if (params.Status != null) __params = __params.set('Status', params.Status.toString());
        if (params.SortingRequest != null) __params = __params.set('SortingRequest', params.SortingRequest.toString());
        if (params.SortingDirectionRequest != null)
            __params = __params.set('SortingDirectionRequest', params.SortingDirectionRequest.toString());
        if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
        if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
        if (params.ModifiedAfterTimeInUtc != null)
            __params = __params.set('ModifiedAfterTimeInUtc', params.ModifiedAfterTimeInUtc.toString());
        if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
        if (params.InvoiceId != null) __params = __params.set('InvoiceId', params.InvoiceId.toString());
        if (params.IncludePatientName != null) __params = __params.set('IncludePatientName', params.IncludePatientName.toString());
        if (params.IncludeCreditNoteItems != null)
            __params = __params.set('IncludeCreditNoteItems', params.IncludeCreditNoteItems.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        if (params.CreditNoteNumberQuery != null) __params = __params.set('CreditNoteNumberQuery', params.CreditNoteNumberQuery.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/CreditNotes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionCreditNote>;
            }),
        );
    }
    /**
     * Gets the creditNotes by the search criteria.
     * @param params The `CreditNotesService.GetCreditNotesParams` containing the following parameters:
     *
     * - `Take`: Gets or sets the take.
     *
     * - `Status`: Gets or sets a value in indicating whether creditNotes need to be filtered by their status or group of statuses (like All).
     *
     * - `SortingRequest`: Gets or sets the sorting request.
     *
     * - `SortingDirectionRequest`: Gets or sets the sorting direction request.
     *
     * - `Skip`: Gets or sets the skip.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `ModifiedAfterTimeInUtc`: Gets or sets the modified after time in UTC.
     *
     * - `LocationId`: Gets or sets the location identifier.
     *
     * - `InvoiceId`: Gets or sets the invoice identifier.
     *
     * - `IncludePatientName`: Gets or sets a value indicating whether [include patient name].
     *
     * - `IncludeCreditNoteItems`: Gets or sets a value indicating whether to include creditNote items or not.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CreditNoteNumberQuery`: Gets or sets the creditNote number query for creditNote number to contain.
     *
     * @return Success
     */
    GetCreditNotes(params: CreditNotesService.GetCreditNotesParams): __Observable<ApiResponseICollectionCreditNote> {
        return this.GetCreditNotesResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionCreditNote));
    }

    /**
     * Deletes the credit note.
     * @param params The `CreditNotesService.DeleteCreditNoteParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `reason`: Optional reason to add when deleting.
     *
     * @return Success
     */
    DeleteCreditNoteResponse(params: CreditNotesService.DeleteCreditNoteParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the credit note.
     * @param params The `CreditNotesService.DeleteCreditNoteParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `reason`: Optional reason to add when deleting.
     *
     * @return Success
     */
    DeleteCreditNote(params: CreditNotesService.DeleteCreditNoteParams): __Observable<ApiResponseOkResponse> {
        return this.DeleteCreditNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the creditNote by identifier.
     * @param params The `CreditNotesService.GetCreditNoteByIdParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `includeProviderName`: if set to `true` [include provider name].
     *
     * @return Success
     */
    GetCreditNoteByIdResponse(
        params: CreditNotesService.GetCreditNoteByIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeProviderName != null) __params = __params.set('includeProviderName', params.includeProviderName.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * Gets the creditNote by identifier.
     * @param params The `CreditNotesService.GetCreditNoteByIdParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `includeProviderName`: if set to `true` [include provider name].
     *
     * @return Success
     */
    GetCreditNoteById(params: CreditNotesService.GetCreditNoteByIdParams): __Observable<ApiResponseCreditNote> {
        return this.GetCreditNoteByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNote));
    }

    /**
     * Updates the CreditNote.
     * @param params The `CreditNotesService.UpdateCreditNoteParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `creditNoteUpdateRequest`: The creditNote update request.
     *
     * @return Success
     */
    UpdateCreditNoteResponse(params: CreditNotesService.UpdateCreditNoteParams): __Observable<__StrictHttpResponse<ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.creditNoteUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * Updates the CreditNote.
     * @param params The `CreditNotesService.UpdateCreditNoteParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `creditNoteUpdateRequest`: The creditNote update request.
     *
     * @return Success
     */
    UpdateCreditNote(params: CreditNotesService.UpdateCreditNoteParams): __Observable<ApiResponseCreditNote> {
        return this.UpdateCreditNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNote));
    }

    /**
     * Gets the credit note history.
     * @param creditNoteId The creditNote identifier.
     * @return Success
     */
    GetCreditNoteHistoryResponse(creditNoteId: number): __Observable<__StrictHttpResponse<ApiResponseICollectionAuditEntryHeader>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/CreditNotes/${encodeURIComponent(String(creditNoteId))}/History`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionAuditEntryHeader>;
            }),
        );
    }
    /**
     * Gets the credit note history.
     * @param creditNoteId The creditNote identifier.
     * @return Success
     */
    GetCreditNoteHistory(creditNoteId: number): __Observable<ApiResponseICollectionAuditEntryHeader> {
        return this.GetCreditNoteHistoryResponse(creditNoteId).pipe(__map((_r) => _r.body as ApiResponseICollectionAuditEntryHeader));
    }

    /**
     * Gets the html view for printing an credit note.
     * @param creditNoteId the credit note identifier.
     * @return Success
     */
    GetCreditNoteHtmlByIdResponse(creditNoteId: number): __Observable<__StrictHttpResponse<ActionResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/CreditNotes/${encodeURIComponent(String(creditNoteId))}/Html`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ActionResult>;
            }),
        );
    }
    /**
     * Gets the html view for printing an credit note.
     * @param creditNoteId the credit note identifier.
     * @return Success
     */
    GetCreditNoteHtmlById(creditNoteId: number): __Observable<ActionResult> {
        return this.GetCreditNoteHtmlByIdResponse(creditNoteId).pipe(__map((_r) => _r.body as ActionResult));
    }

    /**
     * Gets the credit note PDF by identifier.
     * @param creditNoteId The credit note identifier.
     * @return Success
     */
    GetCreditNotePdfByIdResponse(creditNoteId: number): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/CreditNotes/${encodeURIComponent(String(creditNoteId))}/Pdf`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Gets the credit note PDF by identifier.
     * @param creditNoteId The credit note identifier.
     * @return Success
     */
    GetCreditNotePdfById(creditNoteId: number): __Observable<Blob> {
        return this.GetCreditNotePdfByIdResponse(creditNoteId).pipe(__map((_r) => _r.body as Blob));
    }

    /**
     * Gets the next creditNote number.
     * @return Success
     */
    GetNextCreditNoteNumberResponse(): __Observable<__StrictHttpResponse<ApiResponseString>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/CreditNotes/NextCreditNoteNumber`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseString>;
            }),
        );
    }
    /**
     * Gets the next creditNote number.
     * @return Success
     */
    GetNextCreditNoteNumber(): __Observable<ApiResponseString> {
        return this.GetNextCreditNoteNumberResponse().pipe(__map((_r) => _r.body as ApiResponseString));
    }

    /**
     * Gets the paid invoices with patient credit from a credit note.
     * @param creditNoteId The creditNote identifier.
     * @return Success
     */
    GetPaidInvoicesByCreditNoteIdResponse(creditNoteId: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePaidInvoice>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/CreditNotes/${encodeURIComponent(String(creditNoteId))}/PaidInvoices`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePaidInvoice>;
            }),
        );
    }
    /**
     * Gets the paid invoices with patient credit from a credit note.
     * @param creditNoteId The creditNote identifier.
     * @return Success
     */
    GetPaidInvoicesByCreditNoteId(creditNoteId: number): __Observable<ApiResponseIEnumerablePaidInvoice> {
        return this.GetPaidInvoicesByCreditNoteIdResponse(creditNoteId).pipe(__map((_r) => _r.body as ApiResponseIEnumerablePaidInvoice));
    }

    /**
     * Updates the CreditNote's Status.
     * @param params The `CreditNotesService.UpdateCreditNoteStatusParams` containing the following parameters:
     *
     * - `newStatus`: The new status.
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `reason`: The reason to add when changing status.
     *
     * @return Success
     */
    UpdateCreditNoteStatusResponse(
        params: CreditNotesService.UpdateCreditNoteStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.newStatus != null) __params = __params.set('newStatus', params.newStatus.toString());

        if (params.reason != null) __params = __params.set('reason', params.reason.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}/Status`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * Updates the CreditNote's Status.
     * @param params The `CreditNotesService.UpdateCreditNoteStatusParams` containing the following parameters:
     *
     * - `newStatus`: The new status.
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `reason`: The reason to add when changing status.
     *
     * @return Success
     */
    UpdateCreditNoteStatus(params: CreditNotesService.UpdateCreditNoteStatusParams): __Observable<ApiResponseCreditNote> {
        return this.UpdateCreditNoteStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNote));
    }

    /**
     * Updates the CreditNote's SharedWithPatient parameter.
     * @param params The `CreditNotesService.UpdateSharedWithPatientFlagForCreditNoteParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `creditNoteId`: The credit note identifier.
     *
     * @return Success
     */
    UpdateSharedWithPatientFlagForCreditNoteResponse(
        params: CreditNotesService.UpdateSharedWithPatientFlagForCreditNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.newValue != null) __params = __params.set('newValue', params.newValue.toString());

        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}/SharedWithPatient`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * Updates the CreditNote's SharedWithPatient parameter.
     * @param params The `CreditNotesService.UpdateSharedWithPatientFlagForCreditNoteParams` containing the following parameters:
     *
     * - `newValue`: if set to `true` [new value].
     *
     * - `creditNoteId`: The credit note identifier.
     *
     * @return Success
     */
    UpdateSharedWithPatientFlagForCreditNote(
        params: CreditNotesService.UpdateSharedWithPatientFlagForCreditNoteParams,
    ): __Observable<ApiResponseCreditNote> {
        return this.UpdateSharedWithPatientFlagForCreditNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNote));
    }

    /**
     * Add ItemGroup items to CreditNote.
     * @param params The `CreditNotesService.AddItemGroupToCreditNoteParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToCreditNoteResponse(
        params: CreditNotesService.AddItemGroupToCreditNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.itemGroupRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/CreditNotes/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}/ItemGroups`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * Add ItemGroup items to CreditNote.
     * @param params The `CreditNotesService.AddItemGroupToCreditNoteParams` containing the following parameters:
     *
     * - `creditNoteId`: The creditNote identifier.
     *
     * - `itemGroupRequest`: The item group request.
     *
     * @return Success
     */
    AddItemGroupToCreditNote(params: CreditNotesService.AddItemGroupToCreditNoteParams): __Observable<ApiResponseCreditNote> {
        return this.AddItemGroupToCreditNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNote));
    }

    /**
     * The add membership async.
     * @param params The `CreditNotesService.AddMembershipToCreditNoteParams` containing the following parameters:
     *
     * - `membershipId`: The membership id.
     *
     * - `creditNoteId`: The creditNote id.
     *
     * @return Success
     */
    AddMembershipToCreditNoteResponse(
        params: CreditNotesService.AddMembershipToCreditNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl +
                `/CreditNotes/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}/Memberships/${encodeURIComponent(
                    String(params.membershipId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * The add membership async.
     * @param params The `CreditNotesService.AddMembershipToCreditNoteParams` containing the following parameters:
     *
     * - `membershipId`: The membership id.
     *
     * - `creditNoteId`: The creditNote id.
     *
     * @return Success
     */
    AddMembershipToCreditNote(params: CreditNotesService.AddMembershipToCreditNoteParams): __Observable<ApiResponseCreditNote> {
        return this.AddMembershipToCreditNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNote));
    }

    /**
     * The delete creditNote membership.
     * @param params The `CreditNotesService.DeleteCreditNoteMembershipParams` containing the following parameters:
     *
     * - `creditNoteMembershipId`: The creditNote membership id.
     *
     * - `creditNoteId`: The creditNote id.
     *
     * @return Success
     */
    DeleteCreditNoteMembershipResponse(
        params: CreditNotesService.DeleteCreditNoteMembershipParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCreditNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl +
                `/CreditNotes/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}/Memberships/${encodeURIComponent(
                    String(params.creditNoteMembershipId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCreditNote>;
            }),
        );
    }
    /**
     * The delete creditNote membership.
     * @param params The `CreditNotesService.DeleteCreditNoteMembershipParams` containing the following parameters:
     *
     * - `creditNoteMembershipId`: The creditNote membership id.
     *
     * - `creditNoteId`: The creditNote id.
     *
     * @return Success
     */
    DeleteCreditNoteMembership(params: CreditNotesService.DeleteCreditNoteMembershipParams): __Observable<ApiResponseCreditNote> {
        return this.DeleteCreditNoteMembershipResponse(params).pipe(__map((_r) => _r.body as ApiResponseCreditNote));
    }

    /**
     * Gets the item discount.
     * @param params The `CreditNotesService.GetCreditNoteItemDiscountParams` containing the following parameters:
     *
     * - `creditNoteItemId`: The credit note item identifier.
     *
     * - `creditNoteId`: The credit note identifier.
     *
     * @return Success
     */
    GetCreditNoteItemDiscountResponse(
        params: CreditNotesService.GetCreditNoteItemDiscountParams,
    ): __Observable<__StrictHttpResponse<ApiResponseItemDiscountRate>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl +
                `/CreditNotes/CreditNotes/${encodeURIComponent(String(params.creditNoteId))}/CreditNoteItems/${encodeURIComponent(
                    String(params.creditNoteItemId),
                )}/DiscountRate`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemDiscountRate>;
            }),
        );
    }
    /**
     * Gets the item discount.
     * @param params The `CreditNotesService.GetCreditNoteItemDiscountParams` containing the following parameters:
     *
     * - `creditNoteItemId`: The credit note item identifier.
     *
     * - `creditNoteId`: The credit note identifier.
     *
     * @return Success
     */
    GetCreditNoteItemDiscount(params: CreditNotesService.GetCreditNoteItemDiscountParams): __Observable<ApiResponseItemDiscountRate> {
        return this.GetCreditNoteItemDiscountResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemDiscountRate));
    }
}

module CreditNotesService {
    /**
     * Parameters for GetCreditNotes
     */
    export interface GetCreditNotesParams {
        /**
         * Gets or sets the take.
         */
        Take?: number;

        /**
         * Gets or sets a value in indicating whether creditNotes need to be filtered by their status or group of statuses (like All).
         */
        Status?:
            | 'Draft'
            | 'Open'
            | 'Void'
            | 'Deleted'
            | 'Closed'
            | 'AllActive'
            | 'AllIncludingDeletedAndVoid'
            | 'AllInactive'
            | 'AllExcludingDeleted';

        /**
         * Gets or sets the sorting request.
         */
        SortingRequest?: 'Id' | 'Date' | 'Number' | 'PatientName' | 'ModifiedDate';

        /**
         * Gets or sets the sorting direction request.
         */
        SortingDirectionRequest?: 'Ascending' | 'Descending';

        /**
         * Gets or sets the skip.
         */
        Skip?: number;

        /**
         * Gets or sets the patient identifier.
         */
        PatientId?: number;

        /**
         * Gets or sets the modified after time in UTC.
         */
        ModifiedAfterTimeInUtc?: string;

        /**
         * Gets or sets the location identifier.
         */
        LocationId?: number;

        /**
         * Gets or sets the invoice identifier.
         */
        InvoiceId?: number;

        /**
         * Gets or sets a value indicating whether [include patient name].
         */
        IncludePatientName?: boolean;

        /**
         * Gets or sets a value indicating whether to include creditNote items or not.
         */
        IncludeCreditNoteItems?: boolean;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets the creditNote number query for creditNote number to contain.
         */
        CreditNoteNumberQuery?: string;
    }

    /**
     * Parameters for DeleteCreditNote
     */
    export interface DeleteCreditNoteParams {
        /**
         * The creditNote identifier.
         */
        creditNoteId: number;

        /**
         * Optional reason to add when deleting.
         */
        reason?: string;
    }

    /**
     * Parameters for GetCreditNoteById
     */
    export interface GetCreditNoteByIdParams {
        /**
         * The creditNote identifier.
         */
        creditNoteId: number;

        /**
         * if set to `true` [include provider name].
         */
        includeProviderName?: boolean;
    }

    /**
     * Parameters for UpdateCreditNote
     */
    export interface UpdateCreditNoteParams {
        /**
         * The creditNote identifier.
         */
        creditNoteId: number;

        /**
         * The creditNote update request.
         */
        creditNoteUpdateRequest?: CreditNoteUpdateRequest;
    }

    /**
     * Parameters for UpdateCreditNoteStatus
     */
    export interface UpdateCreditNoteStatusParams {
        /**
         * The new status.
         */
        newStatus: 'Draft' | 'Open' | 'Void' | 'Closed';

        /**
         * The creditNote identifier.
         */
        creditNoteId: number;

        /**
         * The reason to add when changing status.
         */
        reason?: string;
    }

    /**
     * Parameters for UpdateSharedWithPatientFlagForCreditNote
     */
    export interface UpdateSharedWithPatientFlagForCreditNoteParams {
        /**
         * if set to `true` [new value].
         */
        newValue: boolean;

        /**
         * The credit note identifier.
         */
        creditNoteId: number;
    }

    /**
     * Parameters for AddItemGroupToCreditNote
     */
    export interface AddItemGroupToCreditNoteParams {
        /**
         * The creditNote identifier.
         */
        creditNoteId: number;

        /**
         * The item group request.
         */
        itemGroupRequest?: ItemGroupRequest;
    }

    /**
     * Parameters for AddMembershipToCreditNote
     */
    export interface AddMembershipToCreditNoteParams {
        /**
         * The membership id.
         */
        membershipId: number;

        /**
         * The creditNote id.
         */
        creditNoteId: number;
    }

    /**
     * Parameters for DeleteCreditNoteMembership
     */
    export interface DeleteCreditNoteMembershipParams {
        /**
         * The creditNote membership id.
         */
        creditNoteMembershipId: number;

        /**
         * The creditNote id.
         */
        creditNoteId: number;
    }

    /**
     * Parameters for GetCreditNoteItemDiscount
     */
    export interface GetCreditNoteItemDiscountParams {
        /**
         * The credit note item identifier.
         */
        creditNoteItemId: number;

        /**
         * The credit note identifier.
         */
        creditNoteId: number;
    }
}

export { CreditNotesService };
