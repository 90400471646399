import { Injectable, inject } from '@angular/core';
import { LocalWebStorage } from './local.webstorage';
import { CookieWebStorage } from './cookie.storage';

/**
 * Service for single access to browser storages
 */
@Injectable({ providedIn: 'root' })
export class WebStorageService {
    public readonly local = new LocalWebStorage();
    public readonly cookie = inject(CookieWebStorage);
    // Add SessionWebStorage
}
