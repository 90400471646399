/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionInvoice } from '../models/api-response-icollection-invoice';
@Injectable({
  providedIn: 'root',
})
class InvoicesService extends __BaseService {
  static readonly GetInvoicesPath = '/Invoices';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the list of invoices.
   * @param params The `InvoicesService.GetInvoicesParams` containing the following parameters:
   *
   * - `take`: The take.
   *
   * - `skip`: The skip.
   *
   * - `invoiceItemIds`: The invoice Item Ids comma separated list.
   *
   * - `invoiceIds`: The Invoice Ids comma separated list.
   *
   * - `includeInvoiceItems`: The include Invoice Items.
   *
   * @return Success
   */
  GetInvoicesResponse(params: InvoicesService.GetInvoicesParams): __Observable<__StrictHttpResponse<ApiResponseICollectionInvoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.invoiceItemIds != null) __params = __params.set('invoiceItemIds', params.invoiceItemIds.toString());
    if (params.invoiceIds != null) __params = __params.set('invoiceIds', params.invoiceIds.toString());
    if (params.includeInvoiceItems != null) __params = __params.set('includeInvoiceItems', params.includeInvoiceItems.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Invoices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionInvoice>;
      })
    );
  }
  /**
   * Gets the list of invoices.
   * @param params The `InvoicesService.GetInvoicesParams` containing the following parameters:
   *
   * - `take`: The take.
   *
   * - `skip`: The skip.
   *
   * - `invoiceItemIds`: The invoice Item Ids comma separated list.
   *
   * - `invoiceIds`: The Invoice Ids comma separated list.
   *
   * - `includeInvoiceItems`: The include Invoice Items.
   *
   * @return Success
   */
  GetInvoices(params: InvoicesService.GetInvoicesParams): __Observable<ApiResponseICollectionInvoice> {
    return this.GetInvoicesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionInvoice)
    );
  }
}

module InvoicesService {

  /**
   * Parameters for GetInvoices
   */
  export interface GetInvoicesParams {

    /**
     * The take.
     */
    take?: number;

    /**
     * The skip.
     */
    skip?: number;

    /**
     * The invoice Item Ids comma separated list.
     */
    invoiceItemIds?: string;

    /**
     * The Invoice Ids comma separated list.
     */
    invoiceIds?: string;

    /**
     * The include Invoice Items.
     */
    includeInvoiceItems?: boolean;
  }
}

export { InvoicesService }
