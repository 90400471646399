export enum TipsEnum {
    PATIENT_SEARCH_TIP_STATUS = 'SearchTipsWasShowed',
    PATIENT_FILE_FINANCIALS_SETTINGS_TOOLTIP = 'PatientFileFinancialsSettingsHide',
    SECURE_MESSAGING_DISCLAIMERS_TOOLTIP = 'SecureMessagingDisclaimersTooltipShowed',
    SECURE_MESSAGING_TEAMS_TOOLTIP = 'SecureMessagingTeamsTooltipShowed',
    REMITTANCE_PATIENT_CREDIT_APPLY_STATUS = 'RemittancePatientCreditApplyShowed',
    STATUS_TOOLTIP = 'isStatusTooltipShown',
    OAR_TOOLTIP = 'isOarTooltipShown',
    APPLY_PATIENT_CREDITS_TOOLTIP = 'ApplyPatientCreditsTooltip',
    CLAIM_NOTE_TOOLTIP = 'ClaimNoteTooltipShowed',
    CLAIMS_AGING_REPORT_TOOLTIP = 'ClaimsAginReportTooltip',
    ACTIVITY_TYPES_SETTINGS_TOOLTIP = 'ActivityTypesSettingsTooltip',
    UNDERSTANDING_CLAIMS_FEATURES_TOOLTIP = 'UnderstandingClaimsFeaturesTooltip',
    LICENSE_MANAGEMENT_TOOLTIP = 'LicenseManagementTooltip',
    ADMIN_COMMUNICATIONS_TOOLTIP = 'CommunicationsTabTooltip',
    APPOINTMENT_NOTIFICATION_ACTIVITY_CHANGE = 'AppointmentNotificationActivityChange',
    APPOINTMENT_NOTIFICATIONS_TOOLTIP = 'AppointmentNotificationsTooltip',
    SECURITY_CONFIGURATIONS_TOOLTIP = 'SecurityConfigurationsTooltip',
    LEGAL_FORM_ACTIVITY_CHANGE = 'LegalFormActivityChange',
    RECURRING_PAYMENTS_TOOLTIP = 'RecurringPaymentsTooltip',
}
