import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Service for lazy-loading styles
 * @see https://angular.io/guide/workspace-config#styles-and-scripts-configuration
 */
@Injectable({
    providedIn: 'root',
})
export class StylesService {
    constructor(@Inject(DOCUMENT) private readonly document: Document) {}

    /***
     * @param styleName - fullname of bundled style file
     * (should be equal to "bundleName" from angular.json + ".css" extension)
     */
    public loadStyle(styleName: string): void {
        const head = this.document.getElementsByTagName('head')[0];
        const linkElement = this.document.getElementById(styleName) as HTMLLinkElement;

        if (!linkElement) {
            const style = this.document.createElement('link');

            style.id = styleName;
            style.rel = 'stylesheet';
            style.href = `${styleName}`;

            head.appendChild(style);
        }
    }
}
