import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InvoicesService } from '@symplast/generated-clients/financial';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InvoicePdfService {
    public rootUrl!: string;

    constructor(private sanitizer: DomSanitizer, private invoicesService: InvoicesService) {}

    public GetInvoicePdfUrl(invoiceId: number): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl(invoiceId));
    }

    public GetInvoicePdfContent(invoiceId: number): Observable<string> {
        return this.invoicesService.GetInvoicePdfById(invoiceId).pipe(
            map((d) => {
                return URL.createObjectURL(d);
            }),
        );
    }

    private pdfUrl(invoiceId: number) {
        return `${this.rootUrl}/Invoices/${invoiceId}/Pdf`;
    }
}
