import { InjectionToken } from '@angular/core';

export type SymplastDialogPreset = 'primary' | 'error' | 'info';

type SymplastPresetConfig = Partial<{
    headerColor: 'primary' | 'error' | 'inherit';
    headerFullWidth: boolean;
    titlePosition: 'left' | 'center';
    backgroundColor: string;
}>;

const SYMPLAST_DIALOG_PRESET_PRIMARY = {
    headerColor: 'primary',
    headerFullWidth: true,
    titlePosition: 'left',
} as const;

const SYMPLAST_DIALOG_PRESET_ERROR = {
    headerColor: 'error',
    headerFullWidth: true,
    titlePosition: 'center',
} as const;

const SYMPLAST_DIALOG_PRESET_INFO = {
    backgroundColor: 'var(--color-light-blue)',
    headerColor: 'inherit',
    headerFullWidth: false,
    titlePosition: 'center',
} as const;

export const SYMPLAST_DIALOG_PRESET_MAP: Record<SymplastDialogPreset, SymplastPresetConfig> = {
    primary: SYMPLAST_DIALOG_PRESET_PRIMARY,
    error: SYMPLAST_DIALOG_PRESET_ERROR,
    info: SYMPLAST_DIALOG_PRESET_INFO,
};

/**
 * @description Injection token to be used to provide the preset for the symplast-dialog.
 * Valid only if the properties were not passed directly in the code
 *
 * @example
 * providers: [{ provide: SYMPLAST_DIALOG_PRESET, useValue: 'primary' }]
 * Affects the following:
 * - SymplastDialogHeaderComponent.color = 'primary'
 * - SymplastDialogHeaderComponent.fullWidth = true
 * - SymplastDialogTitleDirective.position = 'left'
 *
 * @example
 * providers: [{ provide: SYMPLAST_DIALOG_PRESET, useValue: 'error' }]
 * Affects the following:
 * - SymplastDialogHeaderComponent.color = 'error'
 * - SymplastDialogHeaderComponent.fullWidth = true
 * - SymplastDialogTitleDirective.position = 'left'
 *
 * @example
 * providers: [{ provide: SYMPLAST_DIALOG_PRESET, useValue: 'info' }]
 * Affects the following:
 * - SymplastDialogComponent.backgroundColor = 'light-blue'
 * - SymplastDialogHeaderComponent.color = 'inherit'
 * - SymplastDialogHeaderComponent.fullWidth = false
 * - SymplastDialogTitleDirective.position = 'center'
 */
export const SYMPLAST_DIALOG_PRESET = new InjectionToken<SymplastDialogPreset>('SYMPLAST_DIALOG_PRESET');
