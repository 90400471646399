import { AfterContentInit, AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, QueryList } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Swiper, { Pagination, SwiperOptions } from 'swiper';
import { SwiperSlideDirective } from './swiper-slide.directive';

const SWIPER_FAMILY = 'symplast-swiper';
let swiperUniqueId = 0;

@Directive()
@UntilDestroy()
export abstract class SwiperBaseDirective implements AfterContentInit, AfterViewInit, OnDestroy {
    public abstract slides: QueryList<SwiperSlideDirective>;
    public abstract swiperPaginationElRef: ElementRef<HTMLDivElement>;

    public abstract id?: string;
    public abstract initialSlide: number;
    public abstract direction: 'horizontal' | 'vertical';
    public abstract spaceBetween: number;
    public abstract slidesPerView: number | 'auto';
    public abstract centeredSlides: boolean;
    public abstract slidesOffsetBefore: number;
    public abstract slidesOffsetAfter: number;
    public abstract speed: number;
    public abstract autoHeight: boolean;
    public abstract effect: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip';
    public abstract preventClicks: boolean;
    public abstract preventClicksPropagation: boolean;
    public abstract preloadImages: boolean;
    public abstract updateOnImagesReady: boolean;
    public abstract loop: boolean;
    public abstract loopPreventsSlide: boolean;
    public abstract slideActiveClass: string;
    public abstract slideNextClass: string;
    public abstract slidePrevClass: string;
    public abstract breakpoints?: {
        [width: number]: SwiperOptions;
    };
    public abstract preventInteractionOnTransition: boolean;
    public abstract pagination: boolean;

    public abstract init: EventEmitter<SwiperOptions>;
    public abstract slideChange: EventEmitter<number>;
    public abstract beforeDestroy: EventEmitter<void>;
    public abstract reachBeginning: EventEmitter<void>;
    public abstract reachEnd: EventEmitter<void>;
    public abstract toEdge: EventEmitter<void>;
    public abstract fromEdge: EventEmitter<void>;
    public abstract breakpointChange: EventEmitter<SwiperOptions>;
    public abstract slideNextTransitionEnd: EventEmitter<void>;
    public abstract slidePrevTransitionEnd: EventEmitter<void>;

    protected swiper?: Swiper;

    private readonly swiperUniqueId = ++swiperUniqueId;

    public get uniqueID(): string {
        return this.id ? this.id : `${SWIPER_FAMILY}-${this.swiperUniqueId}`;
    }

    public get containerClass(): string {
        return this.id ? `${SWIPER_FAMILY}-container-${this.id}` : `${SWIPER_FAMILY}-container-${this.swiperUniqueId}`;
    }

    public get wrapperClass(): string {
        return this.id ? `${SWIPER_FAMILY}-wrapper-${this.id}` : `${SWIPER_FAMILY}-wrapper-${this.swiperUniqueId}`;
    }

    public get slideClass(): string {
        return this.id ? `${SWIPER_FAMILY}-slide-${this.id}` : `${SWIPER_FAMILY}-slide-${this.swiperUniqueId}`;
    }

    public ngAfterContentInit(): void {
        this.setSlidesClass();

        this.slides.changes.pipe(untilDestroyed(this)).subscribe(() => {
            this.setSlidesClass();

            if (this.swiper) {
                setTimeout(() => {
                    this.swiper?.update();
                }, 0);
            }
        });
    }

    public ngAfterViewInit(): void {
        // Should use setTimeout to initialize swiper after parent's ngAfterViewInit
        setTimeout(() => {
            if (this.pagination) {
                Swiper.use([Pagination]);
            }

            this.swiper = new Swiper(`#${this.uniqueID}`, {
                wrapperClass: this.wrapperClass,
                slideClass: this.slideClass,
                slideActiveClass: this.slideActiveClass,
                slideNextClass: this.slideNextClass,
                slidePrevClass: this.slidePrevClass,
                initialSlide: this.initialSlide,
                direction: this.direction,
                spaceBetween: this.spaceBetween,
                slidesPerView: this.slidesPerView,
                centeredSlides: this.centeredSlides,
                slidesOffsetBefore: this.slidesOffsetBefore,
                slidesOffsetAfter: this.slidesOffsetAfter,
                speed: this.speed,
                autoHeight: this.autoHeight,
                effect: this.effect,
                preventClicks: this.preventClicks,
                preventClicksPropagation: this.preventClicksPropagation,
                preloadImages: this.preloadImages,
                updateOnImagesReady: this.updateOnImagesReady,
                loop: this.loop,
                loopPreventsSlide: this.loopPreventsSlide,
                breakpoints: this.breakpoints,
                preventInteractionOnTransition: this.preventInteractionOnTransition,
                pagination: this.pagination && {
                    el: this.swiperPaginationElRef.nativeElement,
                    type: 'bullets',
                    clickable: true,
                },
                on: {
                    init: (swiper) => this.init.emit(swiper.params),
                    beforeDestroy: () => this.beforeDestroy.emit(),
                    slideChange: (swiper) => this.slideChange.emit(swiper.activeIndex),
                    reachBeginning: () => this.reachBeginning.emit(),
                    reachEnd: () => this.reachEnd.emit(),
                    toEdge: () => this.toEdge.emit(),
                    fromEdge: () => this.fromEdge.emit(),
                    breakpoint: (_swiper: Swiper, params: SwiperOptions) => this.breakpointChange.emit(params),
                    slideNextTransitionEnd: () => this.slideNextTransitionEnd.emit(),
                    slidePrevTransitionEnd: () => this.slidePrevTransitionEnd.emit(),
                },
            });
        }, 0);
    }

    public ngOnDestroy(): void {
        this.swiper?.destroy();
    }

    private setSlidesClass(): void {
        this.slides.forEach((slide) => {
            slide.setSlideUniqueClass(this.slideClass);
        });
    }
}
