import { BasicTax, LocationItemType } from '@symplast/generated-clients/web-portal';
import { ItemTypeEnum } from '@symplast/utils';

export class LocationItemTypeModel {
    public locationId: number;
    public isEditMode: boolean;
    public locationName: string;
    public feesLocationItem: LocationItemType | null;
    public proceduresLocationItem: LocationItemType | null;
    public productsLocationItem: LocationItemType | null;

    public get fees() {
        return this.feesLocationItem?.tax ? this.getTaxDisplayName(this.feesLocationItem.tax) : null;
    }

    public get procedures() {
        return this.proceduresLocationItem?.tax ? this.getTaxDisplayName(this.proceduresLocationItem.tax) : null;
    }

    public get products() {
        return this.productsLocationItem?.tax ? this.getTaxDisplayName(this.productsLocationItem.tax) : null;
    }

    constructor(locationItemTypes: LocationItemType[], isEditMode = false, model: LocationItemTypeModel | null = null) {
        const getLocationItemByType = function (items: LocationItemType[], itemTypeId: number): LocationItemType | null {
            const filteredItems = items.filter((itemType) => itemType && itemType.itemTypeId === itemTypeId);

            if (!filteredItems.length) {
                return null;
            }

            return filteredItems[0];
        };

        const notNullItems = locationItemTypes.filter((itemType) => itemType);

        if (model) {
            this.locationId = model.locationId;
            this.locationName = model.locationName;
        } else {
            this.locationId = notNullItems[0].locationId;
            this.locationName = notNullItems[0].locationName || '';
        }

        this.isEditMode = isEditMode;
        this.feesLocationItem = getLocationItemByType(notNullItems, ItemTypeEnum.fees);
        this.proceduresLocationItem = getLocationItemByType(notNullItems, ItemTypeEnum.procedures);
        this.productsLocationItem = getLocationItemByType(notNullItems, ItemTypeEnum.products);
    }

    private getTaxDisplayName(tax: BasicTax) {
        const groupPart = tax.isTaxGroup ? ' - Group' : '';

        return `${tax.name} (${tax.taxRate}%)${groupPart}`;
    }
}
