import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, isObservable, of, switchMap } from 'rxjs';

type GuardResult = boolean | UrlTree;
type GuardObservableResult = Observable<GuardResult>;

/**
 * Guard, which is designed to execute the guards passed to it along the chain in the order they are listed.
 * And in the case when one of the guards **does not allow** the activation or loading of the route,
 * it prevents the execution of the subsequent logic.
 */
@Injectable({
    providedIn: 'root',
})
export class CompositeRouteGuard implements CanLoad, CanActivate {
    constructor(private readonly injector: Injector) {}

    public canLoad(route: Route, segments: UrlSegment[]): GuardObservableResult {
        let compositeCanLoadObservable$: GuardObservableResult = of(true);

        const routeGuards = route.data?.routeGuards;

        if (routeGuards) {
            for (const routeGuardsItem of routeGuards) {
                const routeGuard = this.injector.get<CanLoad>(routeGuardsItem);
                const guardFn = () => routeGuard.canLoad(route, segments) as GuardObservableResult | GuardResult;

                compositeCanLoadObservable$ = this.createCompositeObservable(compositeCanLoadObservable$, guardFn);
            }
        }

        return compositeCanLoadObservable$;
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardObservableResult {
        let compositeCanActivateObservable$: GuardObservableResult = of(true);

        const routeGuards = route.data?.routeGuards;

        if (routeGuards) {
            for (const routeGuardsItem of routeGuards) {
                const routeGuard = this.injector.get<CanActivate>(routeGuardsItem);
                const guardFn = () => routeGuard.canActivate(route, state) as GuardObservableResult | GuardResult;

                compositeCanActivateObservable$ = this.createCompositeObservable(compositeCanActivateObservable$, guardFn);
            }
        }

        return compositeCanActivateObservable$;
    }

    private createCompositeObservable(
        compositeObservable$: GuardObservableResult,
        guardFn: () => GuardObservableResult | GuardResult,
    ): GuardObservableResult {
        return compositeObservable$.pipe(
            switchMap((result) => {
                if (result instanceof UrlTree) {
                    return of(result);
                }

                if (!result) {
                    return of(false);
                }

                // eslint-disable-next-line rxjs/finnish
                const value = guardFn();

                return isObservable(value) ? value : of(value);
            }),
        );
    }
}
