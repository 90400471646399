import { Directive, ElementRef } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[symplastOverflowElement]',
    exportAs: 'symplastOverflowElement',
})
export class OverflowElementDirective {
    constructor(public elementRef: ElementRef) {}
}
