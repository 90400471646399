/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseBusinessIntelligenceSettings } from '../models/api-response-business-intelligence-settings';
import { BusinessIntelligenceSettingsPatchRequest } from '../models/business-intelligence-settings-patch-request';
import { ApiResponseInsuranceSettings } from '../models/api-response-insurance-settings';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { InsuranceSettingsUpdateRequest } from '../models/insurance-settings-update-request';
import { ApiResponseInsurancePrintSettings } from '../models/api-response-insurance-print-settings';
import { InsurancePrintSettingsUpdateRequest } from '../models/insurance-print-settings-update-request';
import { ApiResponseSystemSettings } from '../models/api-response-system-settings';
import { SystemSettingsUpdateRequest } from '../models/system-settings-update-request';
import { IntakesSettingsRequest } from '../models/intakes-settings-request';
import { ApiResponseICollectionIntakeProvider } from '../models/api-response-icollection-intake-provider';
import { ApiResponseSchedulingSettings } from '../models/api-response-scheduling-settings';
import { SchedulingSettingsUpdateRequest } from '../models/scheduling-settings-update-request';
import { ApiResponseTasksSettings } from '../models/api-response-tasks-settings';
import { TasksSettingsUpdateRequest } from '../models/tasks-settings-update-request';
import { ApiResponseFinancialsSettings } from '../models/api-response-financials-settings';
import { FinancialsSettingsUpdateRequest } from '../models/financials-settings-update-request';
import { ApiResponseSecureMessagingSettings } from '../models/api-response-secure-messaging-settings';
import { SecureMessagingSettingsUpdateRequest } from '../models/secure-messaging-settings-update-request';
import { ApiResponseEmailInfrastructureSettings } from '../models/api-response-email-infrastructure-settings';
import { ApiResponseSecurityConfigurationSettings } from '../models/api-response-security-configuration-settings';
import { SecurityConfigurationPatchRequest } from '../models/security-configuration-patch-request';
@Injectable({
  providedIn: 'root',
})
class ConfigurationSettingsService extends __BaseService {
  static readonly GetBusinessIntelligenceSettingsPath = '/BusinessIntelligenceSettings';
  static readonly UpdateBusinessIntelligenceSettingsPath = '/BusinessIntelligenceSettings';
  static readonly GetInsuranceSettingsPath = '/InsuranceSettings';
  static readonly UpdateInsuranceSettingsPath = '/InsuranceSettings';
  static readonly GetInsurancePrintSettingsPath = '/InsurancePrintSettings';
  static readonly UpdateInsurancePrintSettingsPath = '/InsurancePrintSettings';
  static readonly GetSystemSettingsPath = '/SystemSettings';
  static readonly UpdateSystemSettingsPath = '/SystemSettings';
  static readonly UpdateIntakesSettingsPath = '/IntakesSettings';
  static readonly GetIntakeProvidersPath = '/IntakeProviders';
  static readonly GetSchedulingSettingsPath = '/SchedulingSettings';
  static readonly UpdateSchedulingSettingsPath = '/SchedulingSettings';
  static readonly GetTaskSettingsPath = '/TasksSettings';
  static readonly UpdateTasksSettingsPath = '/TasksSettings';
  static readonly GetFinancialSettingsPath = '/FinancialSettings';
  static readonly UpdateFinancialSettingsPath = '/FinancialSettings';
  static readonly ActivateCalendarBlocksSettingsPath = '/CalendarBlocksSettings';
  static readonly GetSecureMessagingSettingsPath = '/SecureMessaging';
  static readonly UpdateSecureMessagingSettingsPath = '/SecureMessaging';
  static readonly GetEmailSettingsPath = '/Email';
  static readonly GetSecurityConfigurationSettingsPath = '/SecurityConfigurationSettings';
  static readonly UpdateSecurityConfigurationSettingsPath = '/SecurityConfigurationSettings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets settings information.
   * @return Success
   */
  GetBusinessIntelligenceSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/BusinessIntelligenceSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>;
      })
    );
  }
  /**
   * Gets settings information.
   * @return Success
   */
  GetBusinessIntelligenceSettings(): __Observable<ApiResponseBusinessIntelligenceSettings> {
    return this.GetBusinessIntelligenceSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseBusinessIntelligenceSettings)
    );
  }

  /**
   * Updates the business intelligence settings.
   * @param patchRequest The patch request with BI settings.
   * @return Success
   */
  UpdateBusinessIntelligenceSettingsResponse(patchRequest?: BusinessIntelligenceSettingsPatchRequest): __Observable<__StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patchRequest;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/BusinessIntelligenceSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseBusinessIntelligenceSettings>;
      })
    );
  }
  /**
   * Updates the business intelligence settings.
   * @param patchRequest The patch request with BI settings.
   * @return Success
   */
  UpdateBusinessIntelligenceSettings(patchRequest?: BusinessIntelligenceSettingsPatchRequest): __Observable<ApiResponseBusinessIntelligenceSettings> {
    return this.UpdateBusinessIntelligenceSettingsResponse(patchRequest).pipe(
      __map(_r => _r.body as ApiResponseBusinessIntelligenceSettings)
    );
  }

  /**
   * Get the insurance settings.
   * @return Success
   */
  GetInsuranceSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseInsuranceSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/InsuranceSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseInsuranceSettings>;
      })
    );
  }
  /**
   * Get the insurance settings.
   * @return Success
   */
  GetInsuranceSettings(): __Observable<ApiResponseInsuranceSettings> {
    return this.GetInsuranceSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseInsuranceSettings)
    );
  }

  /**
   * Update the insurance settings flag.
   * @param insuranceSettingsUpdateRequest The insurance Private Settings Request.
   * @return Success
   */
  UpdateInsuranceSettingsResponse(insuranceSettingsUpdateRequest?: InsuranceSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = insuranceSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/InsuranceSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the insurance settings flag.
   * @param insuranceSettingsUpdateRequest The insurance Private Settings Request.
   * @return Success
   */
  UpdateInsuranceSettings(insuranceSettingsUpdateRequest?: InsuranceSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateInsuranceSettingsResponse(insuranceSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the insurance print settings.
   * @return Success
   */
  GetInsurancePrintSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseInsurancePrintSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/InsurancePrintSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseInsurancePrintSettings>;
      })
    );
  }
  /**
   * Get the insurance print settings.
   * @return Success
   */
  GetInsurancePrintSettings(): __Observable<ApiResponseInsurancePrintSettings> {
    return this.GetInsurancePrintSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseInsurancePrintSettings)
    );
  }

  /**
   * Update the insurance print settings.
   * @param insurancePrintSettingsUpdateRequest The insurance print Settings Request.
   * @return Success
   */
  UpdateInsurancePrintSettingsResponse(insurancePrintSettingsUpdateRequest?: InsurancePrintSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = insurancePrintSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/InsurancePrintSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the insurance print settings.
   * @param insurancePrintSettingsUpdateRequest The insurance print Settings Request.
   * @return Success
   */
  UpdateInsurancePrintSettings(insurancePrintSettingsUpdateRequest?: InsurancePrintSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateInsurancePrintSettingsResponse(insurancePrintSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the system settings.
   * @return Success
   */
  GetSystemSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseSystemSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SystemSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSystemSettings>;
      })
    );
  }
  /**
   * Get the system settings.
   * @return Success
   */
  GetSystemSettings(): __Observable<ApiResponseSystemSettings> {
    return this.GetSystemSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseSystemSettings)
    );
  }

  /**
   * Update the system settings flag.
   * @param systemSettingsUpdateRequest The general Private Settings Request.
   * @return Success
   */
  UpdateSystemSettingsResponse(systemSettingsUpdateRequest?: SystemSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = systemSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SystemSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the system settings flag.
   * @param systemSettingsUpdateRequest The general Private Settings Request.
   * @return Success
   */
  UpdateSystemSettings(systemSettingsUpdateRequest?: SystemSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateSystemSettingsResponse(systemSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update the intakes settings.
   * @param intakesSettingsUpdateRequest The intakes settings update request.
   * @return Success
   */
  UpdateIntakesSettingsResponse(intakesSettingsUpdateRequest?: IntakesSettingsRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = intakesSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/IntakesSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the intakes settings.
   * @param intakesSettingsUpdateRequest The intakes settings update request.
   * @return Success
   */
  UpdateIntakesSettings(intakesSettingsUpdateRequest?: IntakesSettingsRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateIntakesSettingsResponse(intakesSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the available intake providers.
   * @return Success
   */
  GetIntakeProvidersResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionIntakeProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/IntakeProviders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionIntakeProvider>;
      })
    );
  }
  /**
   * Gets the available intake providers.
   * @return Success
   */
  GetIntakeProviders(): __Observable<ApiResponseICollectionIntakeProvider> {
    return this.GetIntakeProvidersResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionIntakeProvider)
    );
  }

  /**
   * Get the scheduling settings.
   * @return Success
   */
  GetSchedulingSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseSchedulingSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SchedulingSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSchedulingSettings>;
      })
    );
  }
  /**
   * Get the scheduling settings.
   * @return Success
   */
  GetSchedulingSettings(): __Observable<ApiResponseSchedulingSettings> {
    return this.GetSchedulingSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseSchedulingSettings)
    );
  }

  /**
   * Update the scheduling Settings.
   * @param schedulingSettingsUpdateRequest The scheduling settings update request.
   * @return Success
   */
  UpdateSchedulingSettingsResponse(schedulingSettingsUpdateRequest?: SchedulingSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = schedulingSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SchedulingSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the scheduling Settings.
   * @param schedulingSettingsUpdateRequest The scheduling settings update request.
   * @return Success
   */
  UpdateSchedulingSettings(schedulingSettingsUpdateRequest?: SchedulingSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateSchedulingSettingsResponse(schedulingSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the tasks settings.
   * @return Success
   */
  GetTaskSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseTasksSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/TasksSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseTasksSettings>;
      })
    );
  }
  /**
   * Get the tasks settings.
   * @return Success
   */
  GetTaskSettings(): __Observable<ApiResponseTasksSettings> {
    return this.GetTaskSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseTasksSettings)
    );
  }

  /**
   * Update the tasks Settings.
   * @param tasksSettingsUpdateRequest The tasks settings update request.
   * @return Success
   */
  UpdateTasksSettingsResponse(tasksSettingsUpdateRequest?: TasksSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tasksSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/TasksSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the tasks Settings.
   * @param tasksSettingsUpdateRequest The tasks settings update request.
   * @return Success
   */
  UpdateTasksSettings(tasksSettingsUpdateRequest?: TasksSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateTasksSettingsResponse(tasksSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the financials settings.
   * @return Success
   */
  GetFinancialSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseFinancialsSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/FinancialSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFinancialsSettings>;
      })
    );
  }
  /**
   * Get the financials settings.
   * @return Success
   */
  GetFinancialSettings(): __Observable<ApiResponseFinancialsSettings> {
    return this.GetFinancialSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseFinancialsSettings)
    );
  }

  /**
   * Update the financials settings flag.
   * @param financialSettingsUpdateRequest The financials settings Request.
   * @return Success
   */
  UpdateFinancialSettingsResponse(financialSettingsUpdateRequest?: FinancialsSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = financialSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/FinancialSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the financials settings flag.
   * @param financialSettingsUpdateRequest The financials settings Request.
   * @return Success
   */
  UpdateFinancialSettings(financialSettingsUpdateRequest?: FinancialsSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateFinancialSettingsResponse(financialSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Activates calendar blocks.
   * @return Success
   */
  ActivateCalendarBlocksSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/CalendarBlocksSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Activates calendar blocks.
   * @return Success
   */
  ActivateCalendarBlocksSettings(): __Observable<ApiResponseOkResponse> {
    return this.ActivateCalendarBlocksSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the secure messaging settings.
   * @return Success
   */
  GetSecureMessagingSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseSecureMessagingSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SecureMessaging`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSecureMessagingSettings>;
      })
    );
  }
  /**
   * Get the secure messaging settings.
   * @return Success
   */
  GetSecureMessagingSettings(): __Observable<ApiResponseSecureMessagingSettings> {
    return this.GetSecureMessagingSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseSecureMessagingSettings)
    );
  }

  /**
   * Update the secure messaging Settings.
   * @param secureMessagingSettingsUpdateRequest The secure messaging settings update request.
   * @return Success
   */
  UpdateSecureMessagingSettingsResponse(secureMessagingSettingsUpdateRequest?: SecureMessagingSettingsUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = secureMessagingSettingsUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SecureMessaging`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update the secure messaging Settings.
   * @param secureMessagingSettingsUpdateRequest The secure messaging settings update request.
   * @return Success
   */
  UpdateSecureMessagingSettings(secureMessagingSettingsUpdateRequest?: SecureMessagingSettingsUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.UpdateSecureMessagingSettingsResponse(secureMessagingSettingsUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the email settings for the tenant.
   * @return Success
   */
  GetEmailSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseEmailInfrastructureSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEmailInfrastructureSettings>;
      })
    );
  }
  /**
   * Gets the email settings for the tenant.
   * @return Success
   */
  GetEmailSettings(): __Observable<ApiResponseEmailInfrastructureSettings> {
    return this.GetEmailSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseEmailInfrastructureSettings)
    );
  }

  /**
   * Gets the email infrastructure settings.
   * @return Success
   */
  GetSecurityConfigurationSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponseSecurityConfigurationSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SecurityConfigurationSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSecurityConfigurationSettings>;
      })
    );
  }
  /**
   * Gets the email infrastructure settings.
   * @return Success
   */
  GetSecurityConfigurationSettings(): __Observable<ApiResponseSecurityConfigurationSettings> {
    return this.GetSecurityConfigurationSettingsResponse().pipe(
      __map(_r => _r.body as ApiResponseSecurityConfigurationSettings)
    );
  }

  /**
   * Updates the security configuration settings.
   * @param patchRequest The patch request with security settings.
   * @return Success
   */
  UpdateSecurityConfigurationSettingsResponse(patchRequest?: SecurityConfigurationPatchRequest): __Observable<__StrictHttpResponse<ApiResponseSecurityConfigurationSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patchRequest;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/SecurityConfigurationSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSecurityConfigurationSettings>;
      })
    );
  }
  /**
   * Updates the security configuration settings.
   * @param patchRequest The patch request with security settings.
   * @return Success
   */
  UpdateSecurityConfigurationSettings(patchRequest?: SecurityConfigurationPatchRequest): __Observable<ApiResponseSecurityConfigurationSettings> {
    return this.UpdateSecurityConfigurationSettingsResponse(patchRequest).pipe(
      __map(_r => _r.body as ApiResponseSecurityConfigurationSettings)
    );
  }
}

module ConfigurationSettingsService {
}

export { ConfigurationSettingsService }
