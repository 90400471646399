import { Injectable } from '@angular/core';
import moment from 'moment';
import { BaseEnumService } from './base-enum.service';

@Injectable({
    providedIn: 'root',
})
export class AgeBracketsEnumService extends BaseEnumService {
    constructor() {
        super();
        this.data = [
            {
                key: '',
                name: 'All',
            },
            {
                key: 'From0To30Days',
                name: '0 - 30 Days',
            },
            {
                key: 'From31To60Days',
                name: '31 - 60 Days',
            },
            {
                key: 'From61To90Days',
                name: '61 - 90 Days',
            },
            {
                key: 'From91To120Days',
                name: '91 - 120 Days',
            },
            {
                key: 'From121To180Days',
                name: '121 - 180 Days',
            },
            {
                key: 'MoreThan180Days',
                name: 'More 180 Days',
            },
        ];
    }

    public getFormattedStartDate(key: string): string | null {
        const currentDate = moment();

        switch (key) {
            case 'From0To30Days': {
                return currentDate.add(-30, 'days').format('YYYY-MM-DD');
            }
            case 'From31To60Days': {
                return currentDate.add(-60, 'days').format('YYYY-MM-DD');
            }
            case 'From61To90Days': {
                return currentDate.add(-90, 'days').format('YYYY-MM-DD');
            }
            case 'From91To120Days': {
                return currentDate.add(-120, 'days').format('YYYY-MM-DD');
            }
            case 'From121To180Days': {
                return currentDate.add(-180, 'days').format('YYYY-MM-DD');
            }
            default:
                return null;
        }
    }

    public getFormattedEndDate(key: string): string | null {
        const currentDate = moment();

        switch (key) {
            case 'From0To30Days': {
                return currentDate.format('YYYY-MM-DD');
            }
            case 'From31To60Days': {
                return currentDate.add(-31, 'days').format('YYYY-MM-DD');
            }
            case 'From61To90Days': {
                return currentDate.add(-61, 'days').format('YYYY-MM-DD');
            }
            case 'From91To120Days': {
                return currentDate.add(-91, 'days').format('YYYY-MM-DD');
            }
            case 'From121To180Days': {
                return currentDate.add(-121, 'days').format('YYYY-MM-DD');
            }
            case 'MoreThan180Days': {
                return currentDate.add(-181, 'days').format('YYYY-MM-DD');
            }
            default:
                return null;
        }
    }
}
