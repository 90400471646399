/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { UpdatePasswordByCodeRequest } from '../models/update-password-by-code-request';
import { ApiResponsePasswordCode } from '../models/api-response-password-code';
@Injectable({
  providedIn: 'root',
})
class UserPasswordManagementService extends __BaseService {
  static readonly SendForgottenPasswordPath = '/UserPasswordManagement';
  static readonly ChangePasswordByCodePath = '/UserPasswordManagement/Password';
  static readonly GetCodePath = '/UserPasswordManagement/{code}';
  static readonly ConfirmMobilePhoneByCodePath = '/UserPasswordManagement/MobilePhone';
  static readonly GetConfirmMobilePhoneCodePath = '/UserPasswordManagement/MobilePhone';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get users information.
   * @param params The `UserPasswordManagementService.SendForgottenPasswordParams` containing the following parameters:
   *
   * - `userName`: The user Name.
   *
   * - `tenantId`: The tenant Id.
   *
   * @return Success
   */
  SendForgottenPasswordResponse(params: UserPasswordManagementService.SendForgottenPasswordParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userName != null) __params = __params.set('userName', params.userName.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/UserPasswordManagement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Get users information.
   * @param params The `UserPasswordManagementService.SendForgottenPasswordParams` containing the following parameters:
   *
   * - `userName`: The user Name.
   *
   * - `tenantId`: The tenant Id.
   *
   * @return Success
   */
  SendForgottenPassword(params: UserPasswordManagementService.SendForgottenPasswordParams): __Observable<ApiResponseOkResponse> {
    return this.SendForgottenPasswordResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Change password by code.
   * @param request The request.
   * @return Success
   */
  ChangePasswordByCodeResponse(request?: UpdatePasswordByCodeRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/UserPasswordManagement/Password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Change password by code.
   * @param request The request.
   * @return Success
   */
  ChangePasswordByCode(request?: UpdatePasswordByCodeRequest): __Observable<ApiResponseOkResponse> {
    return this.ChangePasswordByCodeResponse(request).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get password code.
   * @param params The `UserPasswordManagementService.GetCodeParams` containing the following parameters:
   *
   * - `code`: The code.
   *
   * - `includeEula`: The include Eula.
   *
   * @return Success
   */
  GetCodeResponse(params: UserPasswordManagementService.GetCodeParams): __Observable<__StrictHttpResponse<ApiResponsePasswordCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeEula != null) __params = __params.set('includeEula', params.includeEula.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/UserPasswordManagement/${encodeURIComponent(String(params.code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePasswordCode>;
      })
    );
  }
  /**
   * Get password code.
   * @param params The `UserPasswordManagementService.GetCodeParams` containing the following parameters:
   *
   * - `code`: The code.
   *
   * - `includeEula`: The include Eula.
   *
   * @return Success
   */
  GetCode(params: UserPasswordManagementService.GetCodeParams): __Observable<ApiResponsePasswordCode> {
    return this.GetCodeResponse(params).pipe(
      __map(_r => _r.body as ApiResponsePasswordCode)
    );
  }

  /**
   * Confirm mobile phone by code.
   * @param code The code.
   * @return Success
   */
  ConfirmMobilePhoneByCodeResponse(code: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (code != null) __params = __params.set('code', code.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/UserPasswordManagement/MobilePhone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Confirm mobile phone by code.
   * @param code The code.
   * @return Success
   */
  ConfirmMobilePhoneByCode(code: string): __Observable<ApiResponseOkResponse> {
    return this.ConfirmMobilePhoneByCodeResponse(code).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get confirm mobile phone code.
   * @param code The code.
   * @return Success
   */
  GetConfirmMobilePhoneCodeResponse(code: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (code != null) __params = __params.set('code', code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/UserPasswordManagement/MobilePhone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Get confirm mobile phone code.
   * @param code The code.
   * @return Success
   */
  GetConfirmMobilePhoneCode(code: string): __Observable<ApiResponseOkResponse> {
    return this.GetConfirmMobilePhoneCodeResponse(code).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module UserPasswordManagementService {

  /**
   * Parameters for SendForgottenPassword
   */
  export interface SendForgottenPasswordParams {

    /**
     * The user Name.
     */
    userName?: string;

    /**
     * The tenant Id.
     */
    tenantId?: number;
  }

  /**
   * Parameters for GetCode
   */
  export interface GetCodeParams {

    /**
     * The code.
     */
    code: string;

    /**
     * The include Eula.
     */
    includeEula?: boolean;
  }
}

export { UserPasswordManagementService }
