import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { LogoutService } from '../../../core/services/logout/logout.service';

@Component({
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule],
    templateUrl: './inactivity-dialog.component.html',
    styleUrls: ['./inactivity-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InactivityDialogComponent {
    constructor(public logoutService: LogoutService) {}
}
