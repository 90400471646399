/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponsePatientRegistrationResponse } from '../models/api-response-patient-registration-response';
import { PatientRegistrationRequest } from '../models/patient-registration-request';
import { ApiResponseVerifyConfirmationCodeResponse } from '../models/api-response-verify-confirmation-code-response';
import { ConfirmationCodeVerificationModel } from '../models/confirmation-code-verification-model';
import { ApiResponseVerifyUserNameAvailabilityResponse } from '../models/api-response-verify-user-name-availability-response';
import { ApiResponseSetPasswordResult } from '../models/api-response-set-password-result';
import { SetPasswordModel } from '../models/set-password-model';
import { SetUserPasswordModel } from '../models/set-user-password-model';
import { ApiResponseLoginResult } from '../models/api-response-login-result';
import { LegacyLoginRequest } from '../models/legacy-login-request';
import { ApiResponseLoginResponse } from '../models/api-response-login-response';
import { GrantResourceOwnerCredentialsRequest } from '../models/grant-resource-owner-credentials-request';
import { ApiResponseTermsOfUse } from '../models/api-response-terms-of-use';
import { ApiResponseIReadOnlyCollectionDeviceType } from '../models/api-response-iread-only-collection-device-type';
import { ApiResponseMessagingDisclaimer } from '../models/api-response-messaging-disclaimer';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseIEnumerableRole } from '../models/api-response-ienumerable-role';
import { ApiResponseIEnumerableUserType } from '../models/api-response-ienumerable-user-type';
import { ApiResponseIEnumerableSpeciality } from '../models/api-response-ienumerable-speciality';
import { ApiResponseIEnumerableUserModel } from '../models/api-response-ienumerable-user-model';
import { ApiResponseUserModel } from '../models/api-response-user-model';
import { CreateUserRequest } from '../models/create-user-request';
import { ApiResponseIEnumerableUserCommission } from '../models/api-response-ienumerable-user-commission';
import { ApiResponseIEnumerableUserRolePermission } from '../models/api-response-ienumerable-user-role-permission';
import { ApiResponseCanMergePatientsResponse } from '../models/api-response-can-merge-patients-response';
import { UpdateUserRequest } from '../models/update-user-request';
import { ChangeUserPasswordRequest } from '../models/change-user-password-request';
import { ApiResponseIEnumerableOfficeModel } from '../models/api-response-ienumerable-office-model';
import { ApiResponseOfficeModel } from '../models/api-response-office-model';
import { ApiResponseIEnumerableTenantBranchLocation } from '../models/api-response-ienumerable-tenant-branch-location';
@Injectable({
    providedIn: 'root',
})
class UsersService extends __BaseService {
    static readonly UsersSendConfirmationCodePath = '/api/Users/SendConfirmationCode';
    static readonly UsersVerifyConfirmationCodePath = '/api/Users/VerifyConfirmationCode';
    static readonly UsersVerifyUserNameAvailabilityPath = '/api/Users/VerifyUserNameAvailability';
    static readonly UsersSetPasswordPath = '/api/Users/SetPassword';
    static readonly UsersSetUserPasswordPath = '/api/Users/SetUserPassword';
    static readonly UsersLoginPath = '/api/Users/Login';
    static readonly UsersLegacyLoginPath = '/oauth2/token';
    static readonly UsersLoginResourceOwnerAsyncPath = '/api/Users/LoginResourceOwner';
    static readonly UsersTermsOfUsePath = '/api/Users/TermsOfUse';
    static readonly UsersDeviceTypesPath = '/api/DeviceTypes';
    static readonly UsersDeviceTypeTermsOfUsePath = '/api/Users/TermsOfUse/{deviceTypeName}';
    static readonly UsersMessagingDisclaimerPath = '/api/Users/MessagingDisclaimer/{deviceTypeName}';
    static readonly UsersVerifyAuthenticationPath = '/api/Users/{userId}/VerifyAuthentication';
    static readonly UsersVerifyAuthenticationForCurrentUserPath = '/api/Users/Me/VerifyAuthentication';
    static readonly UsersGetUserRolesPath = '/api/Users/Roles';
    static readonly UsersGetUserTypesPath = '/api/Users/Types';
    static readonly UsersGetSpecialtiesPath = '/api/Users/Specialties';
    static readonly GetUsersPath = '/api/Users';
    static readonly UsersCreateUserPath = '/api/Users';
    static readonly GetAllUserCommissionPath = '/api/Users/Commissions';
    static readonly UsersGetCurrentAsyncPath = '/api/Users/Me';
    static readonly UsersGetUserPermissionsAsyncPath = '/api/Users/Me/Permissions';
    static readonly UsersCanMergePatientsAsyncPath = '/api/Users/{id}/CanMergePatients';
    static readonly UsersUpdateDoseSpotObsoletePath = '/api/Users/Me/DoseSpotId';
    static readonly UsersUpdateDoseSpotPath = '/api/Users/{userId}/DoseSpotId';
    static readonly UsersUpdateIncludeMissingEhrNotesPath = '/api/Users/{userId}/IncludeMissingEhrNotes';
    static readonly UsersUpdatePath = '/api/Users/{userId}';
    static readonly UsersDeleteUserByIdPath = '/api/Users/{userId}';
    static readonly UsersChangePasswordPath = '/api/Users/{userId}/ChangePassword';
    static readonly UsersSetOfficesPath = '/api/Users/{userId}/Offices';
    static readonly UsersGetCurrentUserOfficesAsyncPath = '/api/Users/Me/Offices';
    static readonly UsersGetCurrentUserOfficeByIdAsyncPath = '/api/Users/Me/Offices/{id}';
    static readonly UsersGetCurrentUserLocationsAsyncPath = '/api/Users/Me/Locations';
    static readonly UsersUploadUserImagePath = '/api/Users/{userId}/Image';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * First condition is finding one and only one patient with the provided four parameters(First Name, Last Name, DoB and Gender).
     * Second condition is having a cell phone which is not an empty string and is less than 10 digits long (we add country code of 1 in front of every number).
     * Confirmation code that is sent to patient's registered cell phone number is valid for 10 minutes.
     * @param request Object containing First Name, Last Name, DoB and Gender of the patient user to register.
     * @return OK.
     */
    UsersSendConfirmationCodeResponse(
        request: PatientRegistrationRequest,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientRegistrationResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users/SendConfirmationCode`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientRegistrationResponse>;
            }),
        );
    }
    /**
     * First condition is finding one and only one patient with the provided four parameters(First Name, Last Name, DoB and Gender).
     * Second condition is having a cell phone which is not an empty string and is less than 10 digits long (we add country code of 1 in front of every number).
     * Confirmation code that is sent to patient's registered cell phone number is valid for 10 minutes.
     * @param request Object containing First Name, Last Name, DoB and Gender of the patient user to register.
     * @return OK.
     */
    UsersSendConfirmationCode(request: PatientRegistrationRequest): __Observable<ApiResponsePatientRegistrationResponse> {
        return this.UsersSendConfirmationCodeResponse(request).pipe(__map((_r) => _r.body as ApiResponsePatientRegistrationResponse));
    }

    /**
     * If confirmation code is valid for provided patient and is not expired then code's expiration date is moved to 10 minutes in the future.
     * @param model Object containing Patient ID and Confirmation Code.
     * @return OK.
     */
    UsersVerifyConfirmationCodeResponse(
        model: ConfirmationCodeVerificationModel,
    ): __Observable<__StrictHttpResponse<ApiResponseVerifyConfirmationCodeResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = model;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users/VerifyConfirmationCode`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseVerifyConfirmationCodeResponse>;
            }),
        );
    }
    /**
     * If confirmation code is valid for provided patient and is not expired then code's expiration date is moved to 10 minutes in the future.
     * @param model Object containing Patient ID and Confirmation Code.
     * @return OK.
     */
    UsersVerifyConfirmationCode(model: ConfirmationCodeVerificationModel): __Observable<ApiResponseVerifyConfirmationCodeResponse> {
        return this.UsersVerifyConfirmationCodeResponse(model).pipe(__map((_r) => _r.body as ApiResponseVerifyConfirmationCodeResponse));
    }

    /**
     * If there is no username in DB matching with the one provided and is a valid username, will return an object with Available property true.
     * @param userName parameter containing the username to validate.
     * @return UserName Availability returned
     */
    UsersVerifyUserNameAvailabilityResponse(
        userName: string,
    ): __Observable<__StrictHttpResponse<ApiResponseVerifyUserNameAvailabilityResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (userName != null) __params = __params.set('userName', userName.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users/VerifyUserNameAvailability`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseVerifyUserNameAvailabilityResponse>;
            }),
        );
    }
    /**
     * If there is no username in DB matching with the one provided and is a valid username, will return an object with Available property true.
     * @param userName parameter containing the username to validate.
     * @return UserName Availability returned
     */
    UsersVerifyUserNameAvailability(userName: string): __Observable<ApiResponseVerifyUserNameAvailabilityResponse> {
        return this.UsersVerifyUserNameAvailabilityResponse(userName).pipe(
            __map((_r) => _r.body as ApiResponseVerifyUserNameAvailabilityResponse),
        );
    }

    /**
     * If {PlastEPortalServices.Models.PatientRegistration.SetPasswordModel} contains valid confirmation code and patient ID combination then if patient doesn't have a user account system creates one and sets a password.
     * If patient's account was disabled then account is re-enabled. Same with user's location.
     * @param model Object containing Patient ID, Password and Confirmation Code.
     * @return OK.
     */
    UsersSetPasswordResponse(model: SetPasswordModel): __Observable<__StrictHttpResponse<ApiResponseSetPasswordResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = model;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users/SetPassword`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseSetPasswordResult>;
            }),
        );
    }
    /**
     * If {PlastEPortalServices.Models.PatientRegistration.SetPasswordModel} contains valid confirmation code and patient ID combination then if patient doesn't have a user account system creates one and sets a password.
     * If patient's account was disabled then account is re-enabled. Same with user's location.
     * @param model Object containing Patient ID, Password and Confirmation Code.
     * @return OK.
     */
    UsersSetPassword(model: SetPasswordModel): __Observable<ApiResponseSetPasswordResult> {
        return this.UsersSetPasswordResponse(model).pipe(__map((_r) => _r.body as ApiResponseSetPasswordResult));
    }

    /**
     * If {PlastEPortalServices.Models.PatientRegistration.SetUserPasswordModel} contains patient ID combination then if patient doesn't have a user account system creates one and sets a password.
     * If patient's account was disabled then account is re-enabled. Same with user's location.
     * @param model Object containing Patient ID, Password.
     * @return OK.
     */
    UsersSetUserPasswordResponse(model: SetUserPasswordModel): __Observable<__StrictHttpResponse<ApiResponseSetPasswordResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = model;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users/SetUserPassword`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseSetPasswordResult>;
            }),
        );
    }
    /**
     * If {PlastEPortalServices.Models.PatientRegistration.SetUserPasswordModel} contains patient ID combination then if patient doesn't have a user account system creates one and sets a password.
     * If patient's account was disabled then account is re-enabled. Same with user's location.
     * @param model Object containing Patient ID, Password.
     * @return OK.
     */
    UsersSetUserPassword(model: SetUserPasswordModel): __Observable<ApiResponseSetPasswordResult> {
        return this.UsersSetUserPasswordResponse(model).pipe(__map((_r) => _r.body as ApiResponseSetPasswordResult));
    }

    /**
     * We also audit every single login
     * @param params The `UsersService.UsersLoginParams` containing the following parameters:
     *
     * - `username`: User name
     *
     * - `password`: Matching password
     *
     * - `pushToken`: Token of the device for push notification registration
     *
     * - `deviceType`: Type of the device for push notification registration
     *
     * - `applicationVersion`: The application Version.
     *
     * - `applicationType`: Type of the application.
     *
     * @return OK.
     */
    UsersLoginResponse(params: UsersService.UsersLoginParams): __Observable<__StrictHttpResponse<ApiResponseLoginResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.username != null) __params = __params.set('username', params.username.toString());
        if (params.password != null) __params = __params.set('password', params.password.toString());
        if (params.pushToken != null) __params = __params.set('pushToken', params.pushToken.toString());
        if (params.deviceType != null) __params = __params.set('deviceType', params.deviceType.toString());
        if (params.applicationVersion != null) __params = __params.set('applicationVersion', params.applicationVersion.toString());
        if (params.applicationType != null) __params = __params.set('applicationType', params.applicationType.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users/Login`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLoginResult>;
            }),
        );
    }
    /**
     * We also audit every single login
     * @param params The `UsersService.UsersLoginParams` containing the following parameters:
     *
     * - `username`: User name
     *
     * - `password`: Matching password
     *
     * - `pushToken`: Token of the device for push notification registration
     *
     * - `deviceType`: Type of the device for push notification registration
     *
     * - `applicationVersion`: The application Version.
     *
     * - `applicationType`: Type of the application.
     *
     * @return OK.
     */
    UsersLogin(params: UsersService.UsersLoginParams): __Observable<ApiResponseLoginResult> {
        return this.UsersLoginResponse(params).pipe(__map((_r) => _r.body as ApiResponseLoginResult));
    }

    /**
     * @param request The request.
     * @return OK
     */
    UsersLegacyLoginResponse(request: LegacyLoginRequest): __Observable<__StrictHttpResponse<{}>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/oauth2/token`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<{}>;
            }),
        );
    }
    /**
     * @param request The request.
     * @return OK
     */
    UsersLegacyLogin(request: LegacyLoginRequest): __Observable<{}> {
        return this.UsersLegacyLoginResponse(request).pipe(__map((_r) => _r.body as {}));
    }

    /**
     * We also audit every single login
     * @param request Object containing user credentials.
     * @return OK.
     */
    UsersLoginResourceOwnerAsyncResponse(
        request: GrantResourceOwnerCredentialsRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseLoginResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users/LoginResourceOwner`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLoginResponse>;
            }),
        );
    }
    /**
     * We also audit every single login
     * @param request Object containing user credentials.
     * @return OK.
     */
    UsersLoginResourceOwnerAsync(request: GrantResourceOwnerCredentialsRequest): __Observable<ApiResponseLoginResponse> {
        return this.UsersLoginResourceOwnerAsyncResponse(request).pipe(__map((_r) => _r.body as ApiResponseLoginResponse));
    }

    /**
     * @return OK
     */
    UsersTermsOfUseResponse(): __Observable<__StrictHttpResponse<ApiResponseTermsOfUse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/TermsOfUse`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseTermsOfUse>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersTermsOfUse(): __Observable<ApiResponseTermsOfUse> {
        return this.UsersTermsOfUseResponse().pipe(__map((_r) => _r.body as ApiResponseTermsOfUse));
    }

    /**
     * @return OK
     */
    UsersDeviceTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionDeviceType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/DeviceTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionDeviceType>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersDeviceTypes(): __Observable<ApiResponseIReadOnlyCollectionDeviceType> {
        return this.UsersDeviceTypesResponse().pipe(__map((_r) => _r.body as ApiResponseIReadOnlyCollectionDeviceType));
    }

    /**
     * @param deviceTypeName Name of the device type. See [API/DeviceTypes] to get a full list of device types.
     * @return OK
     */
    UsersDeviceTypeTermsOfUseResponse(deviceTypeName: string): __Observable<__StrictHttpResponse<ApiResponseTermsOfUse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/TermsOfUse/${deviceTypeName}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseTermsOfUse>;
            }),
        );
    }
    /**
     * @param deviceTypeName Name of the device type. See [API/DeviceTypes] to get a full list of device types.
     * @return OK
     */
    UsersDeviceTypeTermsOfUse(deviceTypeName: string): __Observable<ApiResponseTermsOfUse> {
        return this.UsersDeviceTypeTermsOfUseResponse(deviceTypeName).pipe(__map((_r) => _r.body as ApiResponseTermsOfUse));
    }

    /**
     * @param deviceTypeName Name of the device type. See [API/DeviceTypes] to get a full list of device types.
     * @return OK
     */
    UsersMessagingDisclaimerResponse(deviceTypeName: string): __Observable<__StrictHttpResponse<ApiResponseMessagingDisclaimer>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/MessagingDisclaimer/${deviceTypeName}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseMessagingDisclaimer>;
            }),
        );
    }
    /**
     * @param deviceTypeName Name of the device type. See [API/DeviceTypes] to get a full list of device types.
     * @return OK
     */
    UsersMessagingDisclaimer(deviceTypeName: string): __Observable<ApiResponseMessagingDisclaimer> {
        return this.UsersMessagingDisclaimerResponse(deviceTypeName).pipe(__map((_r) => _r.body as ApiResponseMessagingDisclaimer));
    }

    /**
     * @param params The `UsersService.UsersVerifyAuthenticationParams` containing the following parameters:
     *
     * - `userId`: ID of the user for whom to get the details
     *
     * - `token`: Authentication token matching userId
     *
     * @return OK.
     */
    UsersVerifyAuthenticationResponse(
        params: UsersService.UsersVerifyAuthenticationParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/${params.userId}/VerifyAuthentication`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersVerifyAuthenticationParams` containing the following parameters:
     *
     * - `userId`: ID of the user for whom to get the details
     *
     * - `token`: Authentication token matching userId
     *
     * @return OK.
     */
    UsersVerifyAuthentication(params: UsersService.UsersVerifyAuthenticationParams): __Observable<ApiResponseOkResponse> {
        return this.UsersVerifyAuthenticationResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @return OK.
     */
    UsersVerifyAuthenticationForCurrentUserResponse(): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Me/VerifyAuthentication`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @return OK.
     */
    UsersVerifyAuthenticationForCurrentUser(): __Observable<ApiResponseOkResponse> {
        return this.UsersVerifyAuthenticationForCurrentUserResponse().pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @return OK
     */
    UsersGetUserRolesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableRole>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Roles`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableRole>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersGetUserRoles(): __Observable<ApiResponseIEnumerableRole> {
        return this.UsersGetUserRolesResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableRole));
    }

    /**
     * @return OK
     */
    UsersGetUserTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableUserType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Types`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableUserType>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersGetUserTypes(): __Observable<ApiResponseIEnumerableUserType> {
        return this.UsersGetUserTypesResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableUserType));
    }

    /**
     * @return OK
     */
    UsersGetSpecialtiesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableSpeciality>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Specialties`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableSpeciality>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersGetSpecialties(): __Observable<ApiResponseIEnumerableSpeciality> {
        return this.UsersGetSpecialtiesResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableSpeciality));
    }

    /**
     * @param params The `UsersService.GetUsersParams` containing the following parameters:
     *
     * - `userType`: Type of the user.
     *
     * - `userName`: Login of the user.
     *
     * - `userId`: The user identifier.
     *
     * - `query`: The query that filters list of users by DisplayName, UserName, FirstName, LastName, MobilePhone, Email or User ID.
     *
     * - `hasFlags`: Flags that the user must possess. If 0 - mean the users must have no flags at all. If null - do not filter by flags.
     *
     * - `globalUserId`: The global user identifier to filter list of results.
     *
     * - `excludeDeleted`: a flag to exclude deleted users.
     *
     * @return Users returned
     */
    GetUsersResponse(params: UsersService.GetUsersParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableUserModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userType != null) __params = __params.set('userType', params.userType.toString());
        if (params.userName != null) __params = __params.set('userName', params.userName.toString());
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.query != null) __params = __params.set('query', params.query.toString());
        if (params.hasFlags != null) __params = __params.set('hasFlags', params.hasFlags.toString());
        if (params.globalUserId != null) __params = __params.set('globalUserId', params.globalUserId.toString());
        if (params.excludeDeleted != null) __params = __params.set('excludeDeleted', params.excludeDeleted.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableUserModel>;
            }),
        );
    }
    /**
     * @param params The `UsersService.GetUsersParams` containing the following parameters:
     *
     * - `userType`: Type of the user.
     *
     * - `userName`: Login of the user.
     *
     * - `userId`: The user identifier.
     *
     * - `query`: The query that filters list of users by DisplayName, UserName, FirstName, LastName, MobilePhone, Email or User ID.
     *
     * - `hasFlags`: Flags that the user must possess. If 0 - mean the users must have no flags at all. If null - do not filter by flags.
     *
     * - `globalUserId`: The global user identifier to filter list of results.
     *
     * - `excludeDeleted`: a flag to exclude deleted users.
     *
     * @return Users returned
     */
    GetUsers(params: UsersService.GetUsersParams): __Observable<ApiResponseIEnumerableUserModel> {
        return this.GetUsersResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableUserModel));
    }

    /**
     * @param createUserRequest The user create request
     * @return OK.
     */
    UsersCreateUserResponse(createUserRequest: CreateUserRequest): __Observable<__StrictHttpResponse<ApiResponseUserModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = createUserRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Users`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseUserModel>;
            }),
        );
    }
    /**
     * @param createUserRequest The user create request
     * @return OK.
     */
    UsersCreateUser(createUserRequest: CreateUserRequest): __Observable<ApiResponseUserModel> {
        return this.UsersCreateUserResponse(createUserRequest).pipe(__map((_r) => _r.body as ApiResponseUserModel));
    }

    /**
     * @return OK
     */
    GetAllUserCommissionResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableUserCommission>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Commissions`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableUserCommission>;
            }),
        );
    }
    /**
     * @return OK
     */
    GetAllUserCommission(): __Observable<ApiResponseIEnumerableUserCommission> {
        return this.GetAllUserCommissionResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableUserCommission));
    }

    /**
     * @return OK
     */
    UsersGetCurrentAsyncResponse(): __Observable<__StrictHttpResponse<ApiResponseUserModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Me`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseUserModel>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersGetCurrentAsync(): __Observable<ApiResponseUserModel> {
        return this.UsersGetCurrentAsyncResponse().pipe(__map((_r) => _r.body as ApiResponseUserModel));
    }

    /**
     * @return OK
     */
    UsersGetUserPermissionsAsyncResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableUserRolePermission>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Me/Permissions`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableUserRolePermission>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersGetUserPermissionsAsync(): __Observable<ApiResponseIEnumerableUserRolePermission> {
        return this.UsersGetUserPermissionsAsyncResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableUserRolePermission));
    }

    /**
     * @param id The identifier.
     * @return OK
     */
    UsersCanMergePatientsAsyncResponse(id: number): __Observable<__StrictHttpResponse<ApiResponseCanMergePatientsResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/${id}/CanMergePatients`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCanMergePatientsResponse>;
            }),
        );
    }
    /**
     * @param id The identifier.
     * @return OK
     */
    UsersCanMergePatientsAsync(id: number): __Observable<ApiResponseCanMergePatientsResponse> {
        return this.UsersCanMergePatientsAsyncResponse(id).pipe(__map((_r) => _r.body as ApiResponseCanMergePatientsResponse));
    }

    /**
     * @param doseSpotId The string for update doseSpotId
     * @return OK
     */
    UsersUpdateDoseSpotObsoleteResponse(doseSpotId: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (doseSpotId != null) __params = __params.set('doseSpotId', doseSpotId.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Users/Me/DoseSpotId`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param doseSpotId The string for update doseSpotId
     * @return OK
     */
    UsersUpdateDoseSpotObsolete(doseSpotId: string): __Observable<ApiResponseOkResponse> {
        return this.UsersUpdateDoseSpotObsoleteResponse(doseSpotId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `UsersService.UsersUpdateDoseSpotParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `doseSpotId`: The string for update doseSpotId
     *
     * @return OK
     */
    UsersUpdateDoseSpotResponse(params: UsersService.UsersUpdateDoseSpotParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.doseSpotId != null) __params = __params.set('doseSpotId', params.doseSpotId.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Users/${params.userId}/DoseSpotId`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersUpdateDoseSpotParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `doseSpotId`: The string for update doseSpotId
     *
     * @return OK
     */
    UsersUpdateDoseSpot(params: UsersService.UsersUpdateDoseSpotParams): __Observable<ApiResponseOkResponse> {
        return this.UsersUpdateDoseSpotResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `UsersService.UsersUpdateIncludeMissingEhrNotesParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `includeMissingEhrNotes`: Whether to include missing EHR notes in Action Center
     *
     * @return OK
     */
    UsersUpdateIncludeMissingEhrNotesResponse(
        params: UsersService.UsersUpdateIncludeMissingEhrNotesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeMissingEhrNotes != null)
            __params = __params.set('includeMissingEhrNotes', params.includeMissingEhrNotes.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Users/${params.userId}/IncludeMissingEhrNotes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersUpdateIncludeMissingEhrNotesParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `includeMissingEhrNotes`: Whether to include missing EHR notes in Action Center
     *
     * @return OK
     */
    UsersUpdateIncludeMissingEhrNotes(params: UsersService.UsersUpdateIncludeMissingEhrNotesParams): __Observable<ApiResponseOkResponse> {
        return this.UsersUpdateIncludeMissingEhrNotesResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `UsersService.UsersUpdateParams` containing the following parameters:
     *
     * - `userId`: The user identifier
     *
     * - `updateUserRequest`: The data for update request
     *
     * @return OK
     */
    UsersUpdateResponse(params: UsersService.UsersUpdateParams): __Observable<__StrictHttpResponse<ApiResponseUserModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.updateUserRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Users/${params.userId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseUserModel>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersUpdateParams` containing the following parameters:
     *
     * - `userId`: The user identifier
     *
     * - `updateUserRequest`: The data for update request
     *
     * @return OK
     */
    UsersUpdate(params: UsersService.UsersUpdateParams): __Observable<ApiResponseUserModel> {
        return this.UsersUpdateResponse(params).pipe(__map((_r) => _r.body as ApiResponseUserModel));
    }

    /**
     * @param params The `UsersService.UsersDeleteUserByIdParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `modifiedByUserId`: The modified by user identifier.
     *
     * - `hardDelete`: if set to true [hard delete].
     *
     * @return OK
     */
    UsersDeleteUserByIdResponse(params: UsersService.UsersDeleteUserByIdParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.modifiedByUserId != null) __params = __params.set('modifiedByUserId', params.modifiedByUserId.toString());
        if (params.hardDelete != null) __params = __params.set('hardDelete', params.hardDelete.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/Users/${params.userId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersDeleteUserByIdParams` containing the following parameters:
     *
     * - `userId`: The user identifier.
     *
     * - `modifiedByUserId`: The modified by user identifier.
     *
     * - `hardDelete`: if set to true [hard delete].
     *
     * @return OK
     */
    UsersDeleteUserById(params: UsersService.UsersDeleteUserByIdParams): __Observable<ApiResponseOkResponse> {
        return this.UsersDeleteUserByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `UsersService.UsersChangePasswordParams` containing the following parameters:
     *
     * - `userId`: The user identifier
     *
     * - `changeUserPasswordRequest`: User's old and new passwords
     *
     * @return OK
     */
    UsersChangePasswordResponse(params: UsersService.UsersChangePasswordParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.changeUserPasswordRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Users/${params.userId}/ChangePassword`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersChangePasswordParams` containing the following parameters:
     *
     * - `userId`: The user identifier
     *
     * - `changeUserPasswordRequest`: User's old and new passwords
     *
     * @return OK
     */
    UsersChangePassword(params: UsersService.UsersChangePasswordParams): __Observable<ApiResponseOkResponse> {
        return this.UsersChangePasswordResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `UsersService.UsersSetOfficesParams` containing the following parameters:
     *
     * - `userId`: The user identifier
     *
     * - `officeIds`: The list of office (tenant branch) identifiers
     *
     * @return User offices updated
     */
    UsersSetOfficesResponse(params: UsersService.UsersSetOfficesParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.officeIds;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Users/${params.userId}/Offices`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersSetOfficesParams` containing the following parameters:
     *
     * - `userId`: The user identifier
     *
     * - `officeIds`: The list of office (tenant branch) identifiers
     *
     * @return User offices updated
     */
    UsersSetOffices(params: UsersService.UsersSetOfficesParams): __Observable<ApiResponseOkResponse> {
        return this.UsersSetOfficesResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Offices are also known as TenantBranches.
     * @return OK
     */
    UsersGetCurrentUserOfficesAsyncResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableOfficeModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Me/Offices`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableOfficeModel>;
            }),
        );
    }
    /**
     * Offices are also known as TenantBranches.
     * @return OK
     */
    UsersGetCurrentUserOfficesAsync(): __Observable<ApiResponseIEnumerableOfficeModel> {
        return this.UsersGetCurrentUserOfficesAsyncResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableOfficeModel));
    }

    /**
     * Office Location are also known as TenantBranches.
     * @param id The Office identifier.
     * @return OK
     */
    UsersGetCurrentUserOfficeByIdAsyncResponse(id: number): __Observable<__StrictHttpResponse<ApiResponseOfficeModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Me/Offices/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOfficeModel>;
            }),
        );
    }
    /**
     * Office Location are also known as TenantBranches.
     * @param id The Office identifier.
     * @return OK
     */
    UsersGetCurrentUserOfficeByIdAsync(id: number): __Observable<ApiResponseOfficeModel> {
        return this.UsersGetCurrentUserOfficeByIdAsyncResponse(id).pipe(__map((_r) => _r.body as ApiResponseOfficeModel));
    }

    /**
     * @return OK
     */
    UsersGetCurrentUserLocationsAsyncResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTenantBranchLocation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Users/Me/Locations`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableTenantBranchLocation>;
            }),
        );
    }
    /**
     * @return OK
     */
    UsersGetCurrentUserLocationsAsync(): __Observable<ApiResponseIEnumerableTenantBranchLocation> {
        return this.UsersGetCurrentUserLocationsAsyncResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableTenantBranchLocation));
    }

    /**
     * @param params The `UsersService.UsersUploadUserImageParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `ImportImage`: Upload image file
     *
     * @return User
     */
    UsersUploadUserImageResponse(
        params: UsersService.UsersUploadUserImageParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.ImportImage != null) {
            __formData.append('ImportImage', params.ImportImage as string | Blob);
        }
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Users/${params.userId}/Image`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `UsersService.UsersUploadUserImageParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `ImportImage`: Upload image file
     *
     * @return User
     */
    UsersUploadUserImage(params: UsersService.UsersUploadUserImageParams): __Observable<ApiResponseOkResponse> {
        return this.UsersUploadUserImageResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module UsersService {
    /**
     * Parameters for UsersLogin
     */
    export interface UsersLoginParams {
        /**
         * User name
         */
        username: string;

        /**
         * Matching password
         */
        password: string;

        /**
         * Token of the device for push notification registration
         */
        pushToken?: string;

        /**
         * Type of the device for push notification registration
         */
        deviceType?: 'NotSet' | 'IOS' | 'Android' | 'Windows' | 'MacOs';

        /**
         * The application Version.
         */
        applicationVersion?: string;

        /**
         * Type of the application.
         */
        applicationType?: 'NotSet' | 'Patient' | 'Practice' | 'SecureMessaging';
    }

    /**
     * Parameters for UsersVerifyAuthentication
     */
    export interface UsersVerifyAuthenticationParams {
        /**
         * ID of the user for whom to get the details
         */
        userId: number;

        /**
         * Authentication token matching userId
         */
        token: string;
    }

    /**
     * Parameters for GetUsers
     */
    export interface GetUsersParams {
        /**
         * Type of the user.
         */
        userType?:
            | 'All'
            | 'Physician'
            | 'Nurse'
            | 'Admin'
            | 'Staff'
            | 'Patient'
            | 'RefByPhysician'
            | 'CosmeticCoordinator'
            | 'NursePractitioner'
            | 'AllPractice';

        /**
         * Login of the user.
         */
        userName?: string;

        /**
         * The user identifier.
         */
        userId?: number;

        /**
         * The query that filters list of users by DisplayName, UserName, FirstName, LastName, MobilePhone, Email or User ID.
         */
        query?: string;

        /**
         * Flags that the user must possess. If 0 - mean the users must have no flags at all. If null - do not filter by flags.
         */
        hasFlags?: 'None' | 'IsSymplastUser';

        /**
         * The global user identifier to filter list of results.
         */
        globalUserId?: string;

        /**
         * a flag to exclude deleted users.
         */
        excludeDeleted?: boolean;
    }

    /**
     * Parameters for UsersUpdateDoseSpot
     */
    export interface UsersUpdateDoseSpotParams {
        /**
         * The user Id.
         */
        userId: number;

        /**
         * The string for update doseSpotId
         */
        doseSpotId: string;
    }

    /**
     * Parameters for UsersUpdateIncludeMissingEhrNotes
     */
    export interface UsersUpdateIncludeMissingEhrNotesParams {
        /**
         * The user Id.
         */
        userId: number;

        /**
         * Whether to include missing EHR notes in Action Center
         */
        includeMissingEhrNotes: boolean;
    }

    /**
     * Parameters for UsersUpdate
     */
    export interface UsersUpdateParams {
        /**
         * The user identifier
         */
        userId: number;

        /**
         * The data for update request
         */
        updateUserRequest: UpdateUserRequest;
    }

    /**
     * Parameters for UsersDeleteUserById
     */
    export interface UsersDeleteUserByIdParams {
        /**
         * The user identifier.
         */
        userId: number;

        /**
         * The modified by user identifier.
         */
        modifiedByUserId?: number;

        /**
         * if set to true [hard delete].
         */
        hardDelete?: boolean;
    }

    /**
     * Parameters for UsersChangePassword
     */
    export interface UsersChangePasswordParams {
        /**
         * The user identifier
         */
        userId: number;

        /**
         * User's old and new passwords
         */
        changeUserPasswordRequest: ChangeUserPasswordRequest;
    }

    /**
     * Parameters for UsersSetOffices
     */
    export interface UsersSetOfficesParams {
        /**
         * The user identifier
         */
        userId: number;

        /**
         * The list of office (tenant branch) identifiers
         */
        officeIds: Array<number>;
    }

    /**
     * Parameters for UsersUploadUserImage
     */
    export interface UsersUploadUserImageParams {
        /**
         * The user Id.
         */
        userId: number;

        /**
         * Upload image file
         */
        ImportImage: Blob;
    }
}

export { UsersService };
