/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseListOccasion } from '../models/api-response-list-occasion';
import { ApiResponseOccasion } from '../models/api-response-occasion';
import { OccasionCreateRequest } from '../models/occasion-create-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { OccasionUpdateRequest } from '../models/occasion-update-request';
@Injectable({
  providedIn: 'root',
})
class OccasionsService extends __BaseService {
  static readonly GetOccasionsPath = '/Occasions';
  static readonly CreateOccasionPath = '/Occasions';
  static readonly UpdateOccasionPath = '/Occasions/{occasionId}';
  static readonly DeleteOccasionPath = '/Occasions/{occasionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get.
   * @param params The `OccasionsService.GetOccasionsParams` containing the following parameters:
   *
   * - `take`: The take.
   *
   * - `skip`: The skip.
   *
   * - `query`: The query.
   *
   * - `includeNotActive`: The include not active.
   *
   * @return Success
   */
  GetOccasionsResponse(params: OccasionsService.GetOccasionsParams): __Observable<__StrictHttpResponse<ApiResponseListOccasion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.includeNotActive != null) __params = __params.set('includeNotActive', params.includeNotActive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Occasions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseListOccasion>;
      })
    );
  }
  /**
   * The get.
   * @param params The `OccasionsService.GetOccasionsParams` containing the following parameters:
   *
   * - `take`: The take.
   *
   * - `skip`: The skip.
   *
   * - `query`: The query.
   *
   * - `includeNotActive`: The include not active.
   *
   * @return Success
   */
  GetOccasions(params: OccasionsService.GetOccasionsParams): __Observable<ApiResponseListOccasion> {
    return this.GetOccasionsResponse(params).pipe(
      __map(_r => _r.body as ApiResponseListOccasion)
    );
  }

  /**
   * The create.
   * @param createRequest The create request.
   * @return Success
   */
  CreateOccasionResponse(createRequest?: OccasionCreateRequest): __Observable<__StrictHttpResponse<ApiResponseOccasion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Occasions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOccasion>;
      })
    );
  }
  /**
   * The create.
   * @param createRequest The create request.
   * @return Success
   */
  CreateOccasion(createRequest?: OccasionCreateRequest): __Observable<ApiResponseOccasion> {
    return this.CreateOccasionResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseOccasion)
    );
  }

  /**
   * The update.
   * @param params The `OccasionsService.UpdateOccasionParams` containing the following parameters:
   *
   * - `occasionId`: The occasion id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateOccasionResponse(params: OccasionsService.UpdateOccasionParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Occasions/${encodeURIComponent(String(params.occasionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update.
   * @param params The `OccasionsService.UpdateOccasionParams` containing the following parameters:
   *
   * - `occasionId`: The occasion id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateOccasion(params: OccasionsService.UpdateOccasionParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateOccasionResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The delete.
   * @param occasionId The occasion id.
   * @return Success
   */
  DeleteOccasionResponse(occasionId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Occasions/${encodeURIComponent(String(occasionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The delete.
   * @param occasionId The occasion id.
   * @return Success
   */
  DeleteOccasion(occasionId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteOccasionResponse(occasionId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module OccasionsService {

  /**
   * Parameters for GetOccasions
   */
  export interface GetOccasionsParams {

    /**
     * The take.
     */
    take?: number;

    /**
     * The skip.
     */
    skip?: number;

    /**
     * The query.
     */
    query?: string;

    /**
     * The include not active.
     */
    includeNotActive?: boolean;
  }

  /**
   * Parameters for UpdateOccasion
   */
  export interface UpdateOccasionParams {

    /**
     * The occasion id.
     */
    occasionId: number;

    /**
     * The update request.
     */
    updateRequest?: OccasionUpdateRequest;
  }
}

export { OccasionsService }
