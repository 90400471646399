import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { AppointmentRequestsSettingsUpdateRequest, OnlineAppointmentRequestsService } from '@symplast/generated-clients/web-portal';
import { SaveDepositSettings } from '.';
import {
    LoadOarSettings,
    SaveOarGeneralSettings,
    SaveOarLocations,
    SaveOarProviders,
    SaveOarRequiredFields,
    SaveOarServices,
    SaveOarUsersForNotify,
} from './oar-settings.actions';
import { IOarSettingsStateModel } from './oar-settings.model';

@State<IOarSettingsStateModel>({
    name: 'oarSettings',
    defaults: {
        loading: true,
        enabled: false,
        enableBookingDeposits: false,
        isDateOfBirthInputVisible: false,
        isEmailAddressInputVisible: false,
        isGenderInputVisible: false,
        locations: [],
        providers: [],
        services: [],
        usersForNotify: [],
        init: false,
        locationSettings: [],
    },
})
@Injectable()
export class OarSettingsState {
    constructor(private oarService: OnlineAppointmentRequestsService) {}

    @Action(LoadOarSettings)
    public load(context: StateContext<IOarSettingsStateModel>, { refresh }: LoadOarSettings) {
        const state = context.getState();

        if (refresh || !state.init) {
            context.patchState({ loading: true });
            this.oarService.GetOnlineAppointmentRequestSetting().subscribe((response) => {
                if (response.result) {
                    context.patchState({
                        enabled: response.result.enableForOnlineSchedule,
                        enableBookingDeposits: response.result.enableBookingDeposits,
                        locations: response.result.availableLocations,
                        providers: response.result.availableProviders,
                        services: response.result.availableAppointmentTypesPurposes,
                        usersForNotify: response.result.availableUsersToNotify,
                        isDateOfBirthInputVisible: response.result.isDateOfBirthInputVisible,
                        isEmailAddressInputVisible: response.result.isEmailAddressInputVisible,
                        isGenderInputVisible: response.result.isGenderInputVisible,
                        loading: false,
                        init: true,
                        locationSettings: response.result.locationPaymentSettings,
                    });
                }
            });
        }
    }

    @Action(SaveOarLocations)
    public saveLocations(context: StateContext<IOarSettingsStateModel>, { locations }: SaveOarLocations) {
        const state = context.getState();

        context.patchState({ loading: true });
        this.oarService
            .UpdateOnlineAppointmentRequestSetting({
                ...this.getRequest(state),
                availableLocations: locations,
            })
            .subscribe({
                next: (response) => {
                    if (response.result) {
                        context.patchState({ locations, loading: false });
                    }
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    @Action(SaveOarProviders)
    public saveProviders(context: StateContext<IOarSettingsStateModel>, { providers }: SaveOarProviders) {
        const state = context.getState();

        context.patchState({ loading: true });
        this.oarService
            .UpdateOnlineAppointmentRequestSetting({
                ...this.getRequest(state),
                availableProviders: providers,
            })
            .subscribe({
                next: (response) => {
                    if (response.result) {
                        context.patchState({ providers, loading: false });
                    }
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    @Action(SaveOarServices)
    public saveServices(context: StateContext<IOarSettingsStateModel>, { services }: SaveOarServices) {
        const state = context.getState();

        context.patchState({ loading: true });
        this.oarService
            .UpdateOnlineAppointmentRequestSetting({
                ...this.getRequest(state),
                availableAppointmentTypesPurposes: services,
            })
            .subscribe({
                next: (response) => {
                    if (response.result) {
                        context.patchState({ services, loading: false });
                    }
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    @Action(SaveOarUsersForNotify)
    public saveUsers(context: StateContext<IOarSettingsStateModel>, { usersForNotify }: SaveOarUsersForNotify) {
        const state = context.getState();

        context.patchState({ loading: true });
        this.oarService
            .UpdateOnlineAppointmentRequestSetting({
                ...this.getRequest(state),
                availableUsersToNotify: usersForNotify,
            })
            .subscribe({
                next: (response) => {
                    if (response.result) {
                        context.patchState({ usersForNotify, loading: false });
                    }
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    @Action(SaveOarRequiredFields)
    public saveRequiredFields(
        context: StateContext<IOarSettingsStateModel>,
        { isDateOfBirthInputVisible, isEmailAddressInputVisible, isGenderInputVisible }: SaveOarRequiredFields,
    ) {
        const state = context.getState();

        context.patchState({ loading: true });
        this.oarService
            .UpdateOnlineAppointmentRequestSetting({
                ...this.getRequest(state),
                isDateOfBirthInputVisible,
                isEmailAddressInputVisible,
                isGenderInputVisible,
            })
            .subscribe({
                next: (response) => {
                    if (response.result) {
                        context.patchState({
                            isDateOfBirthInputVisible,
                            isEmailAddressInputVisible,
                            isGenderInputVisible,
                            loading: false,
                        });
                    }
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    @Action(SaveOarGeneralSettings)
    public saveGeneralSettings(context: StateContext<IOarSettingsStateModel>, { enabled }: SaveOarGeneralSettings) {
        const state = context.getState();

        context.patchState({ loading: true });
        this.oarService
            .UpdateOnlineAppointmentRequestSetting({
                ...this.getRequest(state),
                enableForOnlineSchedule: enabled,
            })
            .subscribe({
                next: (response) => {
                    if (response.result) {
                        context.patchState({
                            enabled,
                            loading: false,
                        });
                    }
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    @Action(SaveDepositSettings)
    public saveDepositSettings(
        context: StateContext<IOarSettingsStateModel>,
        { enableBookingDeposits, locationPaymentSettings }: SaveDepositSettings,
    ) {
        const state = context.getState();

        context.patchState({ loading: true });
        this.oarService
            .UpdateOnlineAppointmentRequestSetting({
                ...this.getRequest(state),
                enableBookingDeposits: enableBookingDeposits,
                locationPaymentSettings: locationPaymentSettings,
            })
            .subscribe({
                next: (response) => {
                    if (response.result) {
                        context.patchState({
                            enableBookingDeposits: enableBookingDeposits,
                            locationSettings: locationPaymentSettings,
                            loading: false,
                        });
                    }
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    private getRequest(state: IOarSettingsStateModel): AppointmentRequestsSettingsUpdateRequest {
        return {
            enableForOnlineSchedule: state.enabled,
            enableBookingDeposits: state.enableBookingDeposits,
            availableAppointmentTypesPurposes: state.services,
            availableLocations: state.locations,
            availableUsersToNotify: state.usersForNotify,
            availableProviders: state.providers,
            isDateOfBirthInputVisible: state.isDateOfBirthInputVisible,
            isEmailAddressInputVisible: state.isEmailAddressInputVisible,
            isGenderInputVisible: state.isGenderInputVisible,
            locationPaymentSettings: state.locationSettings,
        };
    }
}
