<mat-progress-bar *ngIf="processInProgress$ | async" mode="indeterminate"></mat-progress-bar>

<!-- TODO: (a.vakhrushin) Move from LayoutComponent -->
<app-file-upload></app-file-upload>

<div
    class="container"
    [class.container--web-view]="isWebView"
    [class.container--print-view]="(layoutService.layoutView$ | async) === layoutViewEnum.PRINT_VIEW"
    data-e2e="layout">
    <app-header *hiddenInWebView class="container__header"></app-header>
    <app-menu
        *hiddenInWebView
        class="container__menu"
        [class.container__menu--open]="isMenuOpen"
        (changeState)="onMenuToggle($event)"></app-menu>

    <div #content class="container__content" cdkScrollable data-e2e="layout__content">
        <app-notifications></app-notifications>
        <router-outlet></router-outlet>
    </div>

    <app-footer class="container__footer"></app-footer>
</div>

<app-loader *ngIf="browserService.isLoading$ | async"></app-loader>
