/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerablePatientInfo } from '../models/api-response-ienumerable-patient-info';
import { ApiResponsePatientExtendedInfo } from '../models/api-response-patient-extended-info';
import { PatientCreateRequest } from '../models/patient-create-request';
import { ApiResponsePatientInfo } from '../models/api-response-patient-info';
import { ApiResponseContactInfoModel } from '../models/api-response-contact-info-model';
import { UpdateContactInfoRequest } from '../models/update-contact-info-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PatientUpdateRequest } from '../models/patient-update-request';
import { ApiResponseIEnumerablePatientExtendedInfo } from '../models/api-response-ienumerable-patient-extended-info';
import { ApiResponseIEnumerablePatientDemographicInfo } from '../models/api-response-ienumerable-patient-demographic-info';
import { UpdateContactMobilePhoneRequest } from '../models/update-contact-mobile-phone-request';
import { AddressUpdateRequest } from '../models/address-update-request';
import { ApiResponseICollectionAuditEntryHeaderResponse } from '../models/api-response-icollection-audit-entry-header-response';
import { ApiResponseICollectionLanguage } from '../models/api-response-icollection-language';
import { ApiResponsePatientEmergencyContact } from '../models/api-response-patient-emergency-contact';
import { PatientEmergencyContactRequest } from '../models/patient-emergency-contact-request';
import { ApiResponsePatientOtherDetails } from '../models/api-response-patient-other-details';
import { PatientOtherDetailsUpdateRequest } from '../models/patient-other-details-update-request';
import { ApiResponseIEnumerablePatientIntegrationAccount } from '../models/api-response-ienumerable-patient-integration-account';
import { ApiResponsePatientIntegrationAccount } from '../models/api-response-patient-integration-account';
import { ApiResponsePatientEmploymentInformation } from '../models/api-response-patient-employment-information';
import { PatientEmploymentUpdateRequest } from '../models/patient-employment-update-request';
import { ApiResponseICollectionPatientType } from '../models/api-response-icollection-patient-type';
import { ApiResponseICollectionEthnicity } from '../models/api-response-icollection-ethnicity';
import { ApiResponseICollectionRace } from '../models/api-response-icollection-race';
import { ApiResponsePatientReferralInfo } from '../models/api-response-patient-referral-info';
import { PatientReferralInfoRequest } from '../models/patient-referral-info-request';
import { ApiResponseICollectionContactType } from '../models/api-response-icollection-contact-type';
import { ApiResponseICollectionEmploymentType } from '../models/api-response-icollection-employment-type';
import { ApiResponseICollectionPatientReferralType } from '../models/api-response-icollection-patient-referral-type';
import { ApiResponseInsuranceDetails } from '../models/api-response-insurance-details';
import { InsuranceDetailsRequest } from '../models/insurance-details-request';
import { ApiResponseInsuranceFullDetails } from '../models/api-response-insurance-full-details';
import { ApiResponseICollectionInsuranceDetails } from '../models/api-response-icollection-insurance-details';
import { ApiResponseICollectionGender } from '../models/api-response-icollection-gender';
import { ApiResponseIEnumerablePatientDenormalizedDetails } from '../models/api-response-ienumerable-patient-denormalized-details';
import { ApiResponseMergeResponse } from '../models/api-response-merge-response';
import { MergeRequest } from '../models/merge-request';
@Injectable({
    providedIn: 'root',
})
class PatientsService extends __BaseService {
    static readonly PatientsGetPath = '/api/Patients';
    static readonly PatientsPostPath = '/api/Patients';
    static readonly PatientsGetByIdPath = '/api/Patients/{patientId}/BaseInfo';
    static readonly PatientsGetContactInfoPath = '/api/Patients/{patientId}/ContactInfo';
    static readonly PatientsUpdateContactInfoPath = '/api/Patients/{patientId}/ContactInfo';
    static readonly PatientsGetExtendedInfoByIdPath = '/api/Patients/{patientId}';
    static readonly PatientsUpdateBaseInfoPath = '/api/Patients/{patientId}';
    static readonly PatientsDeletePatientPath = '/api/Patients/{patientId}';
    static readonly PatientsGetMultipleExtendedInfoPath = '/api/Patients/ExtendedInfo';
    static readonly PatientsGetMultipleDemographicInfoPath = '/api/PatientDemographicInfos';
    static readonly PatientsSendWelcomeMessagePath = '/api/Patients/{patientId}/WelcomeMessages';
    static readonly PatientsCanSendWelcomeMessagePath = '/api/Patients/{patientId}/CanSendWelcomeMessage';
    static readonly PatientsUpdateMobilePhonePath = '/api/Patients/{patientId}/MobilePhone';
    static readonly PatientsUpdateAddressPath = '/api/Patients/{patientId}/Address';
    static readonly PatientsUpdateDoseSpotPath = '/api/Patients/{patientId}/DoseSpotId';
    static readonly PatientsUpdateCanfieldPath = '/api/Patients/{patientId}/CanfieldId';
    static readonly PatientsUpdateTouchMdIdPath = '/api/Patients/{patientId}/TouchMdId';
    static readonly PatientsGetPatientHistoryPath = '/api/Patients/{patientId}/History';
    static readonly PatientsGetLanguagesPath = '/api/Languages';
    static readonly PatientsGetEmergencyContactByIdPath = '/api/Patients/{patientId}/EmergencyContact';
    static readonly PatientsUpdateEmergencyContactInfoPath = '/api/Patients/{patientId}/EmergencyContact';
    static readonly PatientsGetPatientOtherDetailsPath = '/api/Patients/{patientId}/OtherDetails';
    static readonly PatientsUpdatePatientOtherDetailsPath = '/api/Patients/{patientId}/OtherDetails';
    static readonly PatientsGetPatientIntegrationAccountsPath = '/api/Patients/{patientId}/PatientIntegrationAccounts';
    static readonly PatientsGetIntegrationAccountsPath = '/api/Integration/{integrationId}/IntegrationAccounts';
    static readonly PatientsUpdatePatientIntegrationAccountPath = '/api/Patients/{patientId}/IntegrationAccount';
    static readonly PatientsGetEmploymentPath = '/api/Patients/{patientId}/Employment';
    static readonly PatientsUpdatePatientEmploymentPath = '/api/Patients/{patientId}/Employment';
    static readonly PatientsGetPatientTypesPath = '/api/PatientTypes';
    static readonly PatientsGetEthnicitiesPath = '/api/Ethnicities';
    static readonly PatientsGetRacesPath = '/api/Races';
    static readonly PatientsGetReferralInfoByIdPath = '/api/Patients/{patientId}/ReferralInfo';
    static readonly PatientsUpdateReferralInfoInfoPath = '/api/Patients/{patientId}/ReferralInfo';
    static readonly PatientsUploadPatientImagePath = '/api/Patients/{patientId}/Image';
    static readonly PatientsGetContactTypesPath = '/api/ContactTypes';
    static readonly PatientsGetEmploymentTypesPath = '/api/EmploymentTypes';
    static readonly PatientsGetReferralTypesPath = '/api/ReferralTypes';
    static readonly PatientsGetInsuranceDetailsPath = '/api/Patients/{patientId}/InsuranceDetails/{insuranceTypeId}';
    static readonly PatientsUpdateInsuranceDetailsPath = '/api/Patients/{patientId}/InsuranceDetails/{insuranceTypeId}';
    static readonly PatientsGetPatientInsuranceDetailsByInsuranceIdPath =
        '/api/Patients/{patientId}/Insurances/{insuranceId}/InsuranceDetails';
    static readonly PatientsGetPatientInsuranceDetailsByPolicyNumberPath = '/api/Patients/Insurances/{policyNumber}';
    static readonly PatientsGetGendersPath = '/api/Genders';
    static readonly PatientsGetDenormalizedPatientsPath = '/api/PatientsDenormalized';
    static readonly PatientsMergeMergePatientsPath = '/api/Patients/{targetPatientID}/MergeRequest';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param params The `PatientsService.PatientsGetParams` containing the following parameters:
     *
     * - `sorting`: The {PlastEPortal.DataObjects.Patients.PatientSorting}
     *
     * - `sortAscending`: Determines whether the sorting is ascending (0-10, A-Z) or descending (10-0, Z-A)
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * - `query`: The query to filter patients by first name, last name, full name (first plus last or vice versa), phone, email, date of birth and ID.
     *
     * - `physicianId`: The physician identifier.
     *
     * - `patientIds`: The patient identifiers, a comma-delimited string.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC. When provided returns only patients created or modified after specified time.
     *
     * - `locationId`: The location identifier.
     *
     * - `isAvailableForSecureMessaging`: The is Available For Secure Messaging.
     *
     * - `includeOnlyPatientsUsingApp`: if set to true [include only patients using application].
     *
     * - `includeOnlyActivePatients`: if set to true [include only active patients].
     *
     * - `includeImageUrl`: If set true include image url.
     *
     * - `globalUserId`: The global user identifier.
     *
     * - `dateOfBirthTo`: The ending date of birth (checking only month and day).
     *
     * - `dateOfBirthFrom`: The starting date of birth (checking only month and day).
     *
     * - `dateOfBirthExact`: The exact date of birth (year, month, day).
     *
     * @return Patients returned
     */
    PatientsGetResponse(params: PatientsService.PatientsGetParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.sorting != null) __params = __params.set('sorting', params.sorting.toString());
        if (params.sortAscending != null) __params = __params.set('sortAscending', params.sortAscending.toString());
        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());
        if (params.query != null) __params = __params.set('query', params.query.toString());
        if (params.physicianId != null) __params = __params.set('physicianId', params.physicianId.toString());
        if (params.patientIds != null) __params = __params.set('patientIds', params.patientIds.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
        if (params.modifiedAfterTimeInUtc != null)
            __params = __params.set('modifiedAfterTimeInUtc', params.modifiedAfterTimeInUtc.toString());
        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        if (params.isAvailableForSecureMessaging != null)
            __params = __params.set('isAvailableForSecureMessaging', params.isAvailableForSecureMessaging.toString());
        if (params.includeOnlyPatientsUsingApp != null)
            __params = __params.set('includeOnlyPatientsUsingApp', params.includeOnlyPatientsUsingApp.toString());
        if (params.includeOnlyActivePatients != null)
            __params = __params.set('includeOnlyActivePatients', params.includeOnlyActivePatients.toString());
        if (params.includeImageUrl != null) __params = __params.set('includeImageUrl', params.includeImageUrl.toString());
        if (params.globalUserId != null) __params = __params.set('globalUserId', params.globalUserId.toString());
        if (params.dateOfBirthTo != null) __params = __params.set('dateOfBirthTo', params.dateOfBirthTo.toString());
        if (params.dateOfBirthFrom != null) __params = __params.set('dateOfBirthFrom', params.dateOfBirthFrom.toString());
        if (params.dateOfBirthExact != null) __params = __params.set('dateOfBirthExact', params.dateOfBirthExact.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientInfo>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetParams` containing the following parameters:
     *
     * - `sorting`: The {PlastEPortal.DataObjects.Patients.PatientSorting}
     *
     * - `sortAscending`: Determines whether the sorting is ascending (0-10, A-Z) or descending (10-0, Z-A)
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * - `query`: The query to filter patients by first name, last name, full name (first plus last or vice versa), phone, email, date of birth and ID.
     *
     * - `physicianId`: The physician identifier.
     *
     * - `patientIds`: The patient identifiers, a comma-delimited string.
     *
     * - `pageSize`: Size of the page, must be greater than zero.
     *
     * - `pageIndex`: Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC. When provided returns only patients created or modified after specified time.
     *
     * - `locationId`: The location identifier.
     *
     * - `isAvailableForSecureMessaging`: The is Available For Secure Messaging.
     *
     * - `includeOnlyPatientsUsingApp`: if set to true [include only patients using application].
     *
     * - `includeOnlyActivePatients`: if set to true [include only active patients].
     *
     * - `includeImageUrl`: If set true include image url.
     *
     * - `globalUserId`: The global user identifier.
     *
     * - `dateOfBirthTo`: The ending date of birth (checking only month and day).
     *
     * - `dateOfBirthFrom`: The starting date of birth (checking only month and day).
     *
     * - `dateOfBirthExact`: The exact date of birth (year, month, day).
     *
     * @return Patients returned
     */
    PatientsGet(params: PatientsService.PatientsGetParams): __Observable<ApiResponseIEnumerablePatientInfo> {
        return this.PatientsGetResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerablePatientInfo));
    }

    /**
     * @param patientCreateRequest The patient create request.
     * @return Patient Created
     */
    PatientsPostResponse(patientCreateRequest: PatientCreateRequest): __Observable<__StrictHttpResponse<ApiResponsePatientExtendedInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = patientCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Patients`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientExtendedInfo>;
            }),
        );
    }
    /**
     * @param patientCreateRequest The patient create request.
     * @return Patient Created
     */
    PatientsPost(patientCreateRequest: PatientCreateRequest): __Observable<ApiResponsePatientExtendedInfo> {
        return this.PatientsPostResponse(patientCreateRequest).pipe(__map((_r) => _r.body as ApiResponsePatientExtendedInfo));
    }

    /**
     * @param params The `PatientsService.PatientsGetByIdParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patient
     */
    PatientsGetByIdResponse(params: PatientsService.PatientsGetByIdParams): __Observable<__StrictHttpResponse<ApiResponsePatientInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${params.patientId}/BaseInfo`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientInfo>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetByIdParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patient
     */
    PatientsGetById(params: PatientsService.PatientsGetByIdParams): __Observable<ApiResponsePatientInfo> {
        return this.PatientsGetByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponsePatientInfo));
    }

    /**
     * @param patientId ID of the patient
     * @return Patient contact info returned
     */
    PatientsGetContactInfoResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponseContactInfoModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${patientId}/ContactInfo`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseContactInfoModel>;
            }),
        );
    }
    /**
     * @param patientId ID of the patient
     * @return Patient contact info returned
     */
    PatientsGetContactInfo(patientId: number): __Observable<ApiResponseContactInfoModel> {
        return this.PatientsGetContactInfoResponse(patientId).pipe(__map((_r) => _r.body as ApiResponseContactInfoModel));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateContactInfoParams` containing the following parameters:
     *
     * - `updateContactInfoRequest`: The patient create request.
     *
     * - `patientId`: The patient identifier.
     *
     * @return Patient Contact Info Updated
     */
    PatientsUpdateContactInfoResponse(
        params: PatientsService.PatientsUpdateContactInfoParams,
    ): __Observable<__StrictHttpResponse<ApiResponseContactInfoModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.updateContactInfoRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/ContactInfo`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseContactInfoModel>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateContactInfoParams` containing the following parameters:
     *
     * - `updateContactInfoRequest`: The patient create request.
     *
     * - `patientId`: The patient identifier.
     *
     * @return Patient Contact Info Updated
     */
    PatientsUpdateContactInfo(params: PatientsService.PatientsUpdateContactInfoParams): __Observable<ApiResponseContactInfoModel> {
        return this.PatientsUpdateContactInfoResponse(params).pipe(__map((_r) => _r.body as ApiResponseContactInfoModel));
    }

    /**
     * @param params The `PatientsService.PatientsGetExtendedInfoByIdParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patients returned
     */
    PatientsGetExtendedInfoByIdResponse(
        params: PatientsService.PatientsGetExtendedInfoByIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientExtendedInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${params.patientId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientExtendedInfo>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetExtendedInfoByIdParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patients returned
     */
    PatientsGetExtendedInfoById(params: PatientsService.PatientsGetExtendedInfoByIdParams): __Observable<ApiResponsePatientExtendedInfo> {
        return this.PatientsGetExtendedInfoByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponsePatientExtendedInfo));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateBaseInfoParams` containing the following parameters:
     *
     * - `patientUpdateRequest`: The data for update request
     *
     * - `patientId`: The patient identifier.
     *
     * @return Patient Updated
     */
    PatientsUpdateBaseInfoResponse(
        params: PatientsService.PatientsUpdateBaseInfoParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.patientUpdateRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateBaseInfoParams` containing the following parameters:
     *
     * - `patientUpdateRequest`: The data for update request
     *
     * - `patientId`: The patient identifier.
     *
     * @return Patient Updated
     */
    PatientsUpdateBaseInfo(params: PatientsService.PatientsUpdateBaseInfoParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsUpdateBaseInfoResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `PatientsService.PatientsDeletePatientParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patient is deleted
     */
    PatientsDeletePatientResponse(
        params: PatientsService.PatientsDeletePatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/Patients/${params.patientId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsDeletePatientParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patient is deleted
     */
    PatientsDeletePatient(params: PatientsService.PatientsDeletePatientParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsDeletePatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `PatientsService.PatientsGetMultipleExtendedInfoParams` containing the following parameters:
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * - `patientIds`: The patient identifiers, a comma-delimited string.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC.
     *
     * - `isAvailableForSecureMessaging`: The is Available For Secure Messaging.
     *
     * - `includeOnlyActivePatients`: Include only active patients.
     *
     * - `includeImageUrl`: If set true include image url.
     *
     * @return Patients returned
     */
    PatientsGetMultipleExtendedInfoResponse(
        params: PatientsService.PatientsGetMultipleExtendedInfoParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientExtendedInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());
        if (params.patientIds != null) __params = __params.set('patientIds', params.patientIds.toString());
        if (params.modifiedAfterTimeInUtc != null)
            __params = __params.set('modifiedAfterTimeInUtc', params.modifiedAfterTimeInUtc.toString());
        if (params.isAvailableForSecureMessaging != null)
            __params = __params.set('isAvailableForSecureMessaging', params.isAvailableForSecureMessaging.toString());
        if (params.includeOnlyActivePatients != null)
            __params = __params.set('includeOnlyActivePatients', params.includeOnlyActivePatients.toString());
        if (params.includeImageUrl != null) __params = __params.set('includeImageUrl', params.includeImageUrl.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/ExtendedInfo`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientExtendedInfo>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetMultipleExtendedInfoParams` containing the following parameters:
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * - `patientIds`: The patient identifiers, a comma-delimited string.
     *
     * - `modifiedAfterTimeInUtc`: The modified after time in UTC.
     *
     * - `isAvailableForSecureMessaging`: The is Available For Secure Messaging.
     *
     * - `includeOnlyActivePatients`: Include only active patients.
     *
     * - `includeImageUrl`: If set true include image url.
     *
     * @return Patients returned
     */
    PatientsGetMultipleExtendedInfo(
        params: PatientsService.PatientsGetMultipleExtendedInfoParams,
    ): __Observable<ApiResponseIEnumerablePatientExtendedInfo> {
        return this.PatientsGetMultipleExtendedInfoResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerablePatientExtendedInfo),
        );
    }

    /**
     * @param params The `PatientsService.PatientsGetMultipleDemographicInfoParams` containing the following parameters:
     *
     * - `patientIds`: The patient identifiers, a comma-delimited string.
     *
     * - `includeOnlyActivePatients`: Include only active patients.
     *
     * @return Patients returned
     */
    PatientsGetMultipleDemographicInfoResponse(
        params: PatientsService.PatientsGetMultipleDemographicInfoParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientDemographicInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.patientIds != null) __params = __params.set('patientIds', params.patientIds.toString());
        if (params.includeOnlyActivePatients != null)
            __params = __params.set('includeOnlyActivePatients', params.includeOnlyActivePatients.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/PatientDemographicInfos`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientDemographicInfo>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetMultipleDemographicInfoParams` containing the following parameters:
     *
     * - `patientIds`: The patient identifiers, a comma-delimited string.
     *
     * - `includeOnlyActivePatients`: Include only active patients.
     *
     * @return Patients returned
     */
    PatientsGetMultipleDemographicInfo(
        params: PatientsService.PatientsGetMultipleDemographicInfoParams,
    ): __Observable<ApiResponseIEnumerablePatientDemographicInfo> {
        return this.PatientsGetMultipleDemographicInfoResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerablePatientDemographicInfo),
        );
    }

    /**
     * @param params The `PatientsService.PatientsSendWelcomeMessageParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Welcome message sent
     */
    PatientsSendWelcomeMessageResponse(
        params: PatientsService.PatientsSendWelcomeMessageParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Patients/${params.patientId}/WelcomeMessages`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsSendWelcomeMessageParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Welcome message sent
     */
    PatientsSendWelcomeMessage(params: PatientsService.PatientsSendWelcomeMessageParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsSendWelcomeMessageResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `PatientsService.PatientsCanSendWelcomeMessageParams` containing the following parameters:
     *
     * - `patientId`: Patient id
     *
     * - `mobilePhone`: Mobile phone to check
     *
     * @return Can send welcome message
     */
    PatientsCanSendWelcomeMessageResponse(
        params: PatientsService.PatientsCanSendWelcomeMessageParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.mobilePhone != null) __params = __params.set('mobilePhone', params.mobilePhone.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${params.patientId}/CanSendWelcomeMessage`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsCanSendWelcomeMessageParams` containing the following parameters:
     *
     * - `patientId`: Patient id
     *
     * - `mobilePhone`: Mobile phone to check
     *
     * @return Can send welcome message
     */
    PatientsCanSendWelcomeMessage(params: PatientsService.PatientsCanSendWelcomeMessageParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsCanSendWelcomeMessageResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateMobilePhoneParams` containing the following parameters:
     *
     * - `updateMobilePhoneRequest`: The patient create request.
     *
     * - `patientId`: The patient identifier.
     *
     * @return Patient Contact Info Updated
     */
    PatientsUpdateMobilePhoneResponse(
        params: PatientsService.PatientsUpdateMobilePhoneParams,
    ): __Observable<__StrictHttpResponse<ApiResponseContactInfoModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.updateMobilePhoneRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/MobilePhone`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseContactInfoModel>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateMobilePhoneParams` containing the following parameters:
     *
     * - `updateMobilePhoneRequest`: The patient create request.
     *
     * - `patientId`: The patient identifier.
     *
     * @return Patient Contact Info Updated
     */
    PatientsUpdateMobilePhone(params: PatientsService.PatientsUpdateMobilePhoneParams): __Observable<ApiResponseContactInfoModel> {
        return this.PatientsUpdateMobilePhoneResponse(params).pipe(__map((_r) => _r.body as ApiResponseContactInfoModel));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateAddressParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `addressUpdateRequest`: The data for update request
     *
     * @return Patient Address Updated
     */
    PatientsUpdateAddressResponse(
        params: PatientsService.PatientsUpdateAddressParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.addressUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/Address`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateAddressParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `addressUpdateRequest`: The data for update request
     *
     * @return Patient Address Updated
     */
    PatientsUpdateAddress(params: PatientsService.PatientsUpdateAddressParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsUpdateAddressResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateDoseSpotParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `doseSpotId`: The string for update doseSpotId
     *
     * @return DoseSpotID updated
     */
    PatientsUpdateDoseSpotResponse(
        params: PatientsService.PatientsUpdateDoseSpotParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.doseSpotId != null) __params = __params.set('doseSpotId', params.doseSpotId.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/DoseSpotId`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateDoseSpotParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `doseSpotId`: The string for update doseSpotId
     *
     * @return DoseSpotID updated
     */
    PatientsUpdateDoseSpot(params: PatientsService.PatientsUpdateDoseSpotParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsUpdateDoseSpotResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateCanfieldParams` containing the following parameters:
     *
     * - `userId`: The user IdentifierS.
     *
     * - `patientId`: The patient identifier.
     *
     * - `canfieldId`: The string for update Canfield Id
     *
     * @return DoseSpotID updated
     */
    PatientsUpdateCanfieldResponse(
        params: PatientsService.PatientsUpdateCanfieldParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());

        if (params.canfieldId != null) __params = __params.set('canfieldId', params.canfieldId.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/CanfieldId`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateCanfieldParams` containing the following parameters:
     *
     * - `userId`: The user IdentifierS.
     *
     * - `patientId`: The patient identifier.
     *
     * - `canfieldId`: The string for update Canfield Id
     *
     * @return DoseSpotID updated
     */
    PatientsUpdateCanfield(params: PatientsService.PatientsUpdateCanfieldParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsUpdateCanfieldResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateTouchMdIdParams` containing the following parameters:
     *
     * - `touchMdId`: The integer for update touchMdId
     *
     * - `patientId`: The patient identifier.
     *
     * @return TouchMd identifier updated
     */
    PatientsUpdateTouchMdIdResponse(
        params: PatientsService.PatientsUpdateTouchMdIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.touchMdId;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/TouchMdId`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateTouchMdIdParams` containing the following parameters:
     *
     * - `touchMdId`: The integer for update touchMdId
     *
     * - `patientId`: The patient identifier.
     *
     * @return TouchMd identifier updated
     */
    PatientsUpdateTouchMdId(params: PatientsService.PatientsUpdateTouchMdIdParams): __Observable<ApiResponseOkResponse> {
        return this.PatientsUpdateTouchMdIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param patientId The patient identifier.
     * @return Patient History returned
     */
    PatientsGetPatientHistoryResponse(
        patientId: number,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionAuditEntryHeaderResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${patientId}/History`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionAuditEntryHeaderResponse>;
            }),
        );
    }
    /**
     * @param patientId The patient identifier.
     * @return Patient History returned
     */
    PatientsGetPatientHistory(patientId: number): __Observable<ApiResponseICollectionAuditEntryHeaderResponse> {
        return this.PatientsGetPatientHistoryResponse(patientId).pipe(
            __map((_r) => _r.body as ApiResponseICollectionAuditEntryHeaderResponse),
        );
    }

    /**
     * @return Languages returned
     */
    PatientsGetLanguagesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionLanguage>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Languages`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionLanguage>;
            }),
        );
    }
    /**
     * @return Languages returned
     */
    PatientsGetLanguages(): __Observable<ApiResponseICollectionLanguage> {
        return this.PatientsGetLanguagesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionLanguage));
    }

    /**
     * @param patientId ID of the patient
     * @return Emergency contact returned
     */
    PatientsGetEmergencyContactByIdResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientEmergencyContact>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${patientId}/EmergencyContact`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientEmergencyContact>;
            }),
        );
    }
    /**
     * @param patientId ID of the patient
     * @return Emergency contact returned
     */
    PatientsGetEmergencyContactById(patientId: number): __Observable<ApiResponsePatientEmergencyContact> {
        return this.PatientsGetEmergencyContactByIdResponse(patientId).pipe(__map((_r) => _r.body as ApiResponsePatientEmergencyContact));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateEmergencyContactInfoParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `emergencyContactRequest`: The data for update request
     *
     * @return Emergency contact updated
     */
    PatientsUpdateEmergencyContactInfoResponse(
        params: PatientsService.PatientsUpdateEmergencyContactInfoParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientEmergencyContact>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.emergencyContactRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/EmergencyContact`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientEmergencyContact>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateEmergencyContactInfoParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `emergencyContactRequest`: The data for update request
     *
     * @return Emergency contact updated
     */
    PatientsUpdateEmergencyContactInfo(
        params: PatientsService.PatientsUpdateEmergencyContactInfoParams,
    ): __Observable<ApiResponsePatientEmergencyContact> {
        return this.PatientsUpdateEmergencyContactInfoResponse(params).pipe(__map((_r) => _r.body as ApiResponsePatientEmergencyContact));
    }

    /**
     * @param patientId ID of the patient
     * @return Patient other details returned
     */
    PatientsGetPatientOtherDetailsResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientOtherDetails>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${patientId}/OtherDetails`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientOtherDetails>;
            }),
        );
    }
    /**
     * @param patientId ID of the patient
     * @return Patient other details returned
     */
    PatientsGetPatientOtherDetails(patientId: number): __Observable<ApiResponsePatientOtherDetails> {
        return this.PatientsGetPatientOtherDetailsResponse(patientId).pipe(__map((_r) => _r.body as ApiResponsePatientOtherDetails));
    }

    /**
     * @param params The `PatientsService.PatientsUpdatePatientOtherDetailsParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `otherDetailsUpdateRequest`: The other details update request.
     *
     * @return Patient other details returned
     */
    PatientsUpdatePatientOtherDetailsResponse(
        params: PatientsService.PatientsUpdatePatientOtherDetailsParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientOtherDetails>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.otherDetailsUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/OtherDetails`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientOtherDetails>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdatePatientOtherDetailsParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `otherDetailsUpdateRequest`: The other details update request.
     *
     * @return Patient other details returned
     */
    PatientsUpdatePatientOtherDetails(
        params: PatientsService.PatientsUpdatePatientOtherDetailsParams,
    ): __Observable<ApiResponsePatientOtherDetails> {
        return this.PatientsUpdatePatientOtherDetailsResponse(params).pipe(__map((_r) => _r.body as ApiResponsePatientOtherDetails));
    }

    /**
     * @param params The `PatientsService.PatientsGetPatientIntegrationAccountsParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `integrationId`: ID of the integration
     *
     * @return Patient other details returned
     */
    PatientsGetPatientIntegrationAccountsResponse(
        params: PatientsService.PatientsGetPatientIntegrationAccountsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientIntegrationAccount>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.integrationId != null) __params = __params.set('integrationId', params.integrationId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${params.patientId}/PatientIntegrationAccounts`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientIntegrationAccount>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetPatientIntegrationAccountsParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `integrationId`: ID of the integration
     *
     * @return Patient other details returned
     */
    PatientsGetPatientIntegrationAccounts(
        params: PatientsService.PatientsGetPatientIntegrationAccountsParams,
    ): __Observable<ApiResponseIEnumerablePatientIntegrationAccount> {
        return this.PatientsGetPatientIntegrationAccountsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerablePatientIntegrationAccount),
        );
    }

    /**
     * @param params The `PatientsService.PatientsGetIntegrationAccountsParams` containing the following parameters:
     *
     * - `integrationId`: ID of the integration
     *
     * - `integrationAccountId`: ID of the integration account
     *
     * @return Patient other details returned
     */
    PatientsGetIntegrationAccountsResponse(
        params: PatientsService.PatientsGetIntegrationAccountsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientIntegrationAccount>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.integrationAccountId != null) __params = __params.set('integrationAccountId', params.integrationAccountId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Integration/${params.integrationId}/IntegrationAccounts`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientIntegrationAccount>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetIntegrationAccountsParams` containing the following parameters:
     *
     * - `integrationId`: ID of the integration
     *
     * - `integrationAccountId`: ID of the integration account
     *
     * @return Patient other details returned
     */
    PatientsGetIntegrationAccounts(
        params: PatientsService.PatientsGetIntegrationAccountsParams,
    ): __Observable<ApiResponseIEnumerablePatientIntegrationAccount> {
        return this.PatientsGetIntegrationAccountsResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerablePatientIntegrationAccount),
        );
    }

    /**
     * @param params The `PatientsService.PatientsUpdatePatientIntegrationAccountParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `integrationId`: ID of the integration
     *
     * - `accountId`: ID of integration account
     *
     * - `userId`: ID of user
     *
     * @return Patient Integration Account created
     */
    PatientsUpdatePatientIntegrationAccountResponse(
        params: PatientsService.PatientsUpdatePatientIntegrationAccountParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientIntegrationAccount>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.integrationId != null) __params = __params.set('integrationId', params.integrationId.toString());
        if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/IntegrationAccount`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientIntegrationAccount>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdatePatientIntegrationAccountParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `integrationId`: ID of the integration
     *
     * - `accountId`: ID of integration account
     *
     * - `userId`: ID of user
     *
     * @return Patient Integration Account created
     */
    PatientsUpdatePatientIntegrationAccount(
        params: PatientsService.PatientsUpdatePatientIntegrationAccountParams,
    ): __Observable<ApiResponsePatientIntegrationAccount> {
        return this.PatientsUpdatePatientIntegrationAccountResponse(params).pipe(
            __map((_r) => _r.body as ApiResponsePatientIntegrationAccount),
        );
    }

    /**
     * @param patientId ID of the employment
     * @return Patient employment information returned
     */
    PatientsGetEmploymentResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientEmploymentInformation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${patientId}/Employment`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientEmploymentInformation>;
            }),
        );
    }
    /**
     * @param patientId ID of the employment
     * @return Patient employment information returned
     */
    PatientsGetEmployment(patientId: number): __Observable<ApiResponsePatientEmploymentInformation> {
        return this.PatientsGetEmploymentResponse(patientId).pipe(__map((_r) => _r.body as ApiResponsePatientEmploymentInformation));
    }

    /**
     * @param params The `PatientsService.PatientsUpdatePatientEmploymentParams` containing the following parameters:
     *
     * - `patientId`: ID of the employment
     *
     * - `patientEmploymentUpdateRequest`: Request for patient's employment update
     *
     * @return Patient Employment updated
     */
    PatientsUpdatePatientEmploymentResponse(
        params: PatientsService.PatientsUpdatePatientEmploymentParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientEmploymentInformation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.patientEmploymentUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/Employment`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientEmploymentInformation>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdatePatientEmploymentParams` containing the following parameters:
     *
     * - `patientId`: ID of the employment
     *
     * - `patientEmploymentUpdateRequest`: Request for patient's employment update
     *
     * @return Patient Employment updated
     */
    PatientsUpdatePatientEmployment(
        params: PatientsService.PatientsUpdatePatientEmploymentParams,
    ): __Observable<ApiResponsePatientEmploymentInformation> {
        return this.PatientsUpdatePatientEmploymentResponse(params).pipe(__map((_r) => _r.body as ApiResponsePatientEmploymentInformation));
    }

    /**
     * @return Patient types
     */
    PatientsGetPatientTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/PatientTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionPatientType>;
            }),
        );
    }
    /**
     * @return Patient types
     */
    PatientsGetPatientTypes(): __Observable<ApiResponseICollectionPatientType> {
        return this.PatientsGetPatientTypesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionPatientType));
    }

    /**
     * @return Ethnicities
     */
    PatientsGetEthnicitiesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionEthnicity>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Ethnicities`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionEthnicity>;
            }),
        );
    }
    /**
     * @return Ethnicities
     */
    PatientsGetEthnicities(): __Observable<ApiResponseICollectionEthnicity> {
        return this.PatientsGetEthnicitiesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionEthnicity));
    }

    /**
     * @return Races
     */
    PatientsGetRacesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionRace>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Races`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionRace>;
            }),
        );
    }
    /**
     * @return Races
     */
    PatientsGetRaces(): __Observable<ApiResponseICollectionRace> {
        return this.PatientsGetRacesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionRace));
    }

    /**
     * @param patientId ID of the patient
     * @return Referral info returned
     */
    PatientsGetReferralInfoByIdResponse(patientId: number): __Observable<__StrictHttpResponse<ApiResponsePatientReferralInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${patientId}/ReferralInfo`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientReferralInfo>;
            }),
        );
    }
    /**
     * @param patientId ID of the patient
     * @return Referral info returned
     */
    PatientsGetReferralInfoById(patientId: number): __Observable<ApiResponsePatientReferralInfo> {
        return this.PatientsGetReferralInfoByIdResponse(patientId).pipe(__map((_r) => _r.body as ApiResponsePatientReferralInfo));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateReferralInfoInfoParams` containing the following parameters:
     *
     * - `patientReferralInfoRequest`: The patient referral information request.
     *
     * - `patientId`: The patient identifier.
     *
     * @return Referral info updated
     */
    PatientsUpdateReferralInfoInfoResponse(
        params: PatientsService.PatientsUpdateReferralInfoInfoParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientReferralInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.patientReferralInfoRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/ReferralInfo`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientReferralInfo>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateReferralInfoInfoParams` containing the following parameters:
     *
     * - `patientReferralInfoRequest`: The patient referral information request.
     *
     * - `patientId`: The patient identifier.
     *
     * @return Referral info updated
     */
    PatientsUpdateReferralInfoInfo(
        params: PatientsService.PatientsUpdateReferralInfoInfoParams,
    ): __Observable<ApiResponsePatientReferralInfo> {
        return this.PatientsUpdateReferralInfoInfoResponse(params).pipe(__map((_r) => _r.body as ApiResponsePatientReferralInfo));
    }

    /**
     * @param params The `PatientsService.PatientsUploadPatientImageParams` containing the following parameters:
     *
     * - `patientId`: Note identifier
     *
     * - `ImportImage`: Upload image file
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patient
     */
    PatientsUploadPatientImageResponse(
        params: PatientsService.PatientsUploadPatientImageParams,
    ): __Observable<__StrictHttpResponse<ApiResponsePatientInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.ImportImage != null) {
            __formData.append('ImportImage', params.ImportImage as string | Blob);
        }
        if (params.requestedByUserId != null) __params = __params.set('requestedByUserId', params.requestedByUserId.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Patients/${params.patientId}/Image`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientInfo>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUploadPatientImageParams` containing the following parameters:
     *
     * - `patientId`: Note identifier
     *
     * - `ImportImage`: Upload image file
     *
     * - `requestedByUserId`: The requested by user identifier.
     *
     * @return Patient
     */
    PatientsUploadPatientImage(params: PatientsService.PatientsUploadPatientImageParams): __Observable<ApiResponsePatientInfo> {
        return this.PatientsUploadPatientImageResponse(params).pipe(__map((_r) => _r.body as ApiResponsePatientInfo));
    }

    /**
     * @return Contact types returned
     */
    PatientsGetContactTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionContactType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/ContactTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionContactType>;
            }),
        );
    }
    /**
     * @return Contact types returned
     */
    PatientsGetContactTypes(): __Observable<ApiResponseICollectionContactType> {
        return this.PatientsGetContactTypesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionContactType));
    }

    /**
     * @return Employment types returned
     */
    PatientsGetEmploymentTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionEmploymentType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/EmploymentTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionEmploymentType>;
            }),
        );
    }
    /**
     * @return Employment types returned
     */
    PatientsGetEmploymentTypes(): __Observable<ApiResponseICollectionEmploymentType> {
        return this.PatientsGetEmploymentTypesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionEmploymentType));
    }

    /**
     * @return Referral Types
     */
    PatientsGetReferralTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientReferralType>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/ReferralTypes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionPatientReferralType>;
            }),
        );
    }
    /**
     * @return Referral Types
     */
    PatientsGetReferralTypes(): __Observable<ApiResponseICollectionPatientReferralType> {
        return this.PatientsGetReferralTypesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionPatientReferralType));
    }

    /**
     * @param params The `PatientsService.PatientsGetInsuranceDetailsParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `insuranceTypeId`: The insurance type identifier.
     *
     * @return Insurance details returned
     */
    PatientsGetInsuranceDetailsResponse(
        params: PatientsService.PatientsGetInsuranceDetailsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInsuranceDetails>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Patients/${params.patientId}/InsuranceDetails/${params.insuranceTypeId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInsuranceDetails>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetInsuranceDetailsParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `insuranceTypeId`: The insurance type identifier.
     *
     * @return Insurance details returned
     */
    PatientsGetInsuranceDetails(params: PatientsService.PatientsGetInsuranceDetailsParams): __Observable<ApiResponseInsuranceDetails> {
        return this.PatientsGetInsuranceDetailsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInsuranceDetails));
    }

    /**
     * @param params The `PatientsService.PatientsUpdateInsuranceDetailsParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `insuranceTypeId`: The insurance type identifier.
     *
     * - `insuranceDetailsRequest`: The insurance details request.
     *
     * @return Patient Address Updated
     */
    PatientsUpdateInsuranceDetailsResponse(
        params: PatientsService.PatientsUpdateInsuranceDetailsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInsuranceDetails>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.insuranceDetailsRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/Patients/${params.patientId}/InsuranceDetails/${params.insuranceTypeId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInsuranceDetails>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsUpdateInsuranceDetailsParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `insuranceTypeId`: The insurance type identifier.
     *
     * - `insuranceDetailsRequest`: The insurance details request.
     *
     * @return Patient Address Updated
     */
    PatientsUpdateInsuranceDetails(
        params: PatientsService.PatientsUpdateInsuranceDetailsParams,
    ): __Observable<ApiResponseInsuranceDetails> {
        return this.PatientsUpdateInsuranceDetailsResponse(params).pipe(__map((_r) => _r.body as ApiResponseInsuranceDetails));
    }

    /**
     * @param params The `PatientsService.PatientsGetPatientInsuranceDetailsByInsuranceIdParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `insuranceId`: The insurance identifier.
     *
     * @return Insurance details returned
     */
    PatientsGetPatientInsuranceDetailsByInsuranceIdResponse(
        params: PatientsService.PatientsGetPatientInsuranceDetailsByInsuranceIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseInsuranceFullDetails>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Patients/${params.patientId}/Insurances/${params.insuranceId}/InsuranceDetails`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInsuranceFullDetails>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsGetPatientInsuranceDetailsByInsuranceIdParams` containing the following parameters:
     *
     * - `patientId`: ID of the patient
     *
     * - `insuranceId`: The insurance identifier.
     *
     * @return Insurance details returned
     */
    PatientsGetPatientInsuranceDetailsByInsuranceId(
        params: PatientsService.PatientsGetPatientInsuranceDetailsByInsuranceIdParams,
    ): __Observable<ApiResponseInsuranceFullDetails> {
        return this.PatientsGetPatientInsuranceDetailsByInsuranceIdResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseInsuranceFullDetails),
        );
    }

    /**
     * @param policyNumber Policy number to filter
     * @return Insurance details returned
     */
    PatientsGetPatientInsuranceDetailsByPolicyNumberResponse(
        policyNumber: string,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionInsuranceDetails>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/Insurances/${policyNumber}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionInsuranceDetails>;
            }),
        );
    }
    /**
     * @param policyNumber Policy number to filter
     * @return Insurance details returned
     */
    PatientsGetPatientInsuranceDetailsByPolicyNumber(policyNumber: string): __Observable<ApiResponseICollectionInsuranceDetails> {
        return this.PatientsGetPatientInsuranceDetailsByPolicyNumberResponse(policyNumber).pipe(
            __map((_r) => _r.body as ApiResponseICollectionInsuranceDetails),
        );
    }

    /**
     * @return Genders returned
     */
    PatientsGetGendersResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionGender>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Genders`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionGender>;
            }),
        );
    }
    /**
     * @return Genders returned
     */
    PatientsGetGenders(): __Observable<ApiResponseICollectionGender> {
        return this.PatientsGetGendersResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionGender));
    }

    /**
     * @param modifiedAfterTimeInUtc The modified after time in UTC.
     * @return Patients returned
     */
    PatientsGetDenormalizedPatientsResponse(
        modifiedAfterTimeInUtc?: string,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePatientDenormalizedDetails>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (modifiedAfterTimeInUtc != null) __params = __params.set('modifiedAfterTimeInUtc', modifiedAfterTimeInUtc.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/PatientsDenormalized`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerablePatientDenormalizedDetails>;
            }),
        );
    }
    /**
     * @param modifiedAfterTimeInUtc The modified after time in UTC.
     * @return Patients returned
     */
    PatientsGetDenormalizedPatients(modifiedAfterTimeInUtc?: string): __Observable<ApiResponseIEnumerablePatientDenormalizedDetails> {
        return this.PatientsGetDenormalizedPatientsResponse(modifiedAfterTimeInUtc).pipe(
            __map((_r) => _r.body as ApiResponseIEnumerablePatientDenormalizedDetails),
        );
    }

    /**
     * @param params The `PatientsService.PatientsMergeMergePatientsParams` containing the following parameters:
     *
     * - `targetPatientID`: The patient to move the information to
     *
     * - `mergeRequest`: The patient to get the information from
     *
     * @return Patients merged
     */
    PatientsMergeMergePatientsResponse(
        params: PatientsService.PatientsMergeMergePatientsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseMergeResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.mergeRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Patients/${params.targetPatientID}/MergeRequest`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseMergeResponse>;
            }),
        );
    }
    /**
     * @param params The `PatientsService.PatientsMergeMergePatientsParams` containing the following parameters:
     *
     * - `targetPatientID`: The patient to move the information to
     *
     * - `mergeRequest`: The patient to get the information from
     *
     * @return Patients merged
     */
    PatientsMergeMergePatients(params: PatientsService.PatientsMergeMergePatientsParams): __Observable<ApiResponseMergeResponse> {
        return this.PatientsMergeMergePatientsResponse(params).pipe(__map((_r) => _r.body as ApiResponseMergeResponse));
    }
}

module PatientsService {
    /**
     * Parameters for PatientsGet
     */
    export interface PatientsGetParams {
        /**
         * The {PlastEPortal.DataObjects.Patients.PatientSorting}
         */
        sorting?: 'Id' | 'FullName';

        /**
         * Determines whether the sorting is ascending (0-10, A-Z) or descending (10-0, Z-A)
         */
        sortAscending?: boolean;

        /**
         * The requested by user identifier.
         */
        requestedByUserId?: number;

        /**
         * The query to filter patients by first name, last name, full name (first plus last or vice versa), phone, email, date of birth and ID.
         */
        query?: string;

        /**
         * The physician identifier.
         */
        physicianId?: number;

        /**
         * The patient identifiers, a comma-delimited string.
         */
        patientIds?: string;

        /**
         * Size of the page, must be greater than zero.
         */
        pageSize?: number;

        /**
         * Index of the page, when value is greater than zero provided result is filtered by pageIndex and pageSize.
         */
        pageIndex?: number;

        /**
         * The modified after time in UTC. When provided returns only patients created or modified after specified time.
         */
        modifiedAfterTimeInUtc?: string;

        /**
         * The location identifier.
         */
        locationId?: number;

        /**
         * The is Available For Secure Messaging.
         */
        isAvailableForSecureMessaging?: boolean;

        /**
         * if set to true [include only patients using application].
         */
        includeOnlyPatientsUsingApp?: boolean;

        /**
         * if set to true [include only active patients].
         */
        includeOnlyActivePatients?: boolean;

        /**
         * If set true include image url.
         */
        includeImageUrl?: boolean;

        /**
         * The global user identifier.
         */
        globalUserId?: string;

        /**
         * The ending date of birth (checking only month and day).
         */
        dateOfBirthTo?: string;

        /**
         * The starting date of birth (checking only month and day).
         */
        dateOfBirthFrom?: string;

        /**
         * The exact date of birth (year, month, day).
         */
        dateOfBirthExact?: string;
    }

    /**
     * Parameters for PatientsGetById
     */
    export interface PatientsGetByIdParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * The requested by user identifier.
         */
        requestedByUserId?: number;
    }

    /**
     * Parameters for PatientsUpdateContactInfo
     */
    export interface PatientsUpdateContactInfoParams {
        /**
         * The patient create request.
         */
        updateContactInfoRequest: UpdateContactInfoRequest;

        /**
         * The patient identifier.
         */
        patientId: number;
    }

    /**
     * Parameters for PatientsGetExtendedInfoById
     */
    export interface PatientsGetExtendedInfoByIdParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * The requested by user identifier.
         */
        requestedByUserId?: number;
    }

    /**
     * Parameters for PatientsUpdateBaseInfo
     */
    export interface PatientsUpdateBaseInfoParams {
        /**
         * The data for update request
         */
        patientUpdateRequest: PatientUpdateRequest;

        /**
         * The patient identifier.
         */
        patientId: number;
    }

    /**
     * Parameters for PatientsDeletePatient
     */
    export interface PatientsDeletePatientParams {
        /**
         * The patient identifier.
         */
        patientId: number;

        /**
         * The requested by user identifier.
         */
        requestedByUserId?: number;
    }

    /**
     * Parameters for PatientsGetMultipleExtendedInfo
     */
    export interface PatientsGetMultipleExtendedInfoParams {
        /**
         * The requested by user identifier.
         */
        requestedByUserId?: number;

        /**
         * The patient identifiers, a comma-delimited string.
         */
        patientIds?: string;

        /**
         * The modified after time in UTC.
         */
        modifiedAfterTimeInUtc?: string;

        /**
         * The is Available For Secure Messaging.
         */
        isAvailableForSecureMessaging?: boolean;

        /**
         * Include only active patients.
         */
        includeOnlyActivePatients?: boolean;

        /**
         * If set true include image url.
         */
        includeImageUrl?: boolean;
    }

    /**
     * Parameters for PatientsGetMultipleDemographicInfo
     */
    export interface PatientsGetMultipleDemographicInfoParams {
        /**
         * The patient identifiers, a comma-delimited string.
         */
        patientIds?: string;

        /**
         * Include only active patients.
         */
        includeOnlyActivePatients?: boolean;
    }

    /**
     * Parameters for PatientsSendWelcomeMessage
     */
    export interface PatientsSendWelcomeMessageParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * The requested by user identifier.
         */
        requestedByUserId?: number;
    }

    /**
     * Parameters for PatientsCanSendWelcomeMessage
     */
    export interface PatientsCanSendWelcomeMessageParams {
        /**
         * Patient id
         */
        patientId: number;

        /**
         * Mobile phone to check
         */
        mobilePhone?: string;
    }

    /**
     * Parameters for PatientsUpdateMobilePhone
     */
    export interface PatientsUpdateMobilePhoneParams {
        /**
         * The patient create request.
         */
        updateMobilePhoneRequest: UpdateContactMobilePhoneRequest;

        /**
         * The patient identifier.
         */
        patientId: number;
    }

    /**
     * Parameters for PatientsUpdateAddress
     */
    export interface PatientsUpdateAddressParams {
        /**
         * The patient identifier.
         */
        patientId: number;

        /**
         * The data for update request
         */
        addressUpdateRequest: AddressUpdateRequest;
    }

    /**
     * Parameters for PatientsUpdateDoseSpot
     */
    export interface PatientsUpdateDoseSpotParams {
        /**
         * The patient identifier.
         */
        patientId: number;

        /**
         * The string for update doseSpotId
         */
        doseSpotId: string;
    }

    /**
     * Parameters for PatientsUpdateCanfield
     */
    export interface PatientsUpdateCanfieldParams {
        /**
         * The user IdentifierS.
         */
        userId: number;

        /**
         * The patient identifier.
         */
        patientId: number;

        /**
         * The string for update Canfield Id
         */
        canfieldId: string;
    }

    /**
     * Parameters for PatientsUpdateTouchMdId
     */
    export interface PatientsUpdateTouchMdIdParams {
        /**
         * The integer for update touchMdId
         */
        touchMdId: number;

        /**
         * The patient identifier.
         */
        patientId: number;
    }

    /**
     * Parameters for PatientsUpdateEmergencyContactInfo
     */
    export interface PatientsUpdateEmergencyContactInfoParams {
        /**
         * The patient identifier.
         */
        patientId: number;

        /**
         * The data for update request
         */
        emergencyContactRequest: PatientEmergencyContactRequest;
    }

    /**
     * Parameters for PatientsUpdatePatientOtherDetails
     */
    export interface PatientsUpdatePatientOtherDetailsParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * The other details update request.
         */
        otherDetailsUpdateRequest: PatientOtherDetailsUpdateRequest;
    }

    /**
     * Parameters for PatientsGetPatientIntegrationAccounts
     */
    export interface PatientsGetPatientIntegrationAccountsParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * ID of the integration
         */
        integrationId?: string;
    }

    /**
     * Parameters for PatientsGetIntegrationAccounts
     */
    export interface PatientsGetIntegrationAccountsParams {
        /**
         * ID of the integration
         */
        integrationId: string;

        /**
         * ID of the integration account
         */
        integrationAccountId: string;
    }

    /**
     * Parameters for PatientsUpdatePatientIntegrationAccount
     */
    export interface PatientsUpdatePatientIntegrationAccountParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * ID of the integration
         */
        integrationId: string;

        /**
         * ID of integration account
         */
        accountId: string;

        /**
         * ID of user
         */
        userId?: number;
    }

    /**
     * Parameters for PatientsUpdatePatientEmployment
     */
    export interface PatientsUpdatePatientEmploymentParams {
        /**
         * ID of the employment
         */
        patientId: number;

        /**
         * Request for patient's employment update
         */
        patientEmploymentUpdateRequest: PatientEmploymentUpdateRequest;
    }

    /**
     * Parameters for PatientsUpdateReferralInfoInfo
     */
    export interface PatientsUpdateReferralInfoInfoParams {
        /**
         * The patient referral information request.
         */
        patientReferralInfoRequest: PatientReferralInfoRequest;

        /**
         * The patient identifier.
         */
        patientId: number;
    }

    /**
     * Parameters for PatientsUploadPatientImage
     */
    export interface PatientsUploadPatientImageParams {
        /**
         * Note identifier
         */
        patientId: number;

        /**
         * Upload image file
         */
        ImportImage: Blob;

        /**
         * The requested by user identifier.
         */
        requestedByUserId?: number;
    }

    /**
     * Parameters for PatientsGetInsuranceDetails
     */
    export interface PatientsGetInsuranceDetailsParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * The insurance type identifier.
         */
        insuranceTypeId: number;
    }

    /**
     * Parameters for PatientsUpdateInsuranceDetails
     */
    export interface PatientsUpdateInsuranceDetailsParams {
        /**
         * The patient identifier.
         */
        patientId: number;

        /**
         * The insurance type identifier.
         */
        insuranceTypeId: number;

        /**
         * The insurance details request.
         */
        insuranceDetailsRequest: InsuranceDetailsRequest;
    }

    /**
     * Parameters for PatientsGetPatientInsuranceDetailsByInsuranceId
     */
    export interface PatientsGetPatientInsuranceDetailsByInsuranceIdParams {
        /**
         * ID of the patient
         */
        patientId: number;

        /**
         * The insurance identifier.
         */
        insuranceId: number;
    }

    /**
     * Parameters for PatientsMergeMergePatients
     */
    export interface PatientsMergeMergePatientsParams {
        /**
         * The patient to move the information to
         */
        targetPatientID: number;

        /**
         * The patient to get the information from
         */
        mergeRequest: MergeRequest;
    }
}

export { PatientsService };
