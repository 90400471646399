import { Injectable } from '@angular/core';
import { ConfigurationSettingsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { LoadGeneralSettings, SaveGeneralSettingRequest, SavePatientFileSettingRequest } from './general-settings.actions';
import { IGeneralSettingsStateModel } from './general-settings.model';

@State<IGeneralSettingsStateModel>({
    name: 'generalSettings',
    defaults: {
        autoLogoutPeriodMinutes: 20,
        isAutomaticLogOutExitingESign: true,
        isReviewAfterSignedRequired: true,
        isShowFinancialSummaryView: false,
        patientFileFinancialViewMode: 'Grid',
        sendPatientAppInvitation: true,
        initLoadingFinished: false,
        loading: false,
    },
})
@Injectable()
export class GeneralSettingsState {
    constructor(private configurationSettingsService: ConfigurationSettingsService, private store: Store) {}

    @Action(LoadGeneralSettings)
    public load(context: StateContext<IGeneralSettingsStateModel>, { refresh }: LoadGeneralSettings): void {
        if (!context.getState().initLoadingFinished || refresh) {
            context.patchState({ loading: true });
            this.configurationSettingsService.GetSystemSettings().subscribe((response) => {
                context.patchState({
                    autoLogoutPeriodMinutes: response.result?.autoLogoutPeriodMinutes,
                    isAutomaticLogOutExitingESign: response.result?.isAutomaticLogOutExitingESign,
                    isReviewAfterSignedRequired: response.result?.isReviewAfterSignedRequired,
                    isShowFinancialSummaryView: response.result?.isShowFinancialSummaryView,
                    patientFileFinancialViewMode: response.result?.patientFileFinancialViewMode,
                    sendPatientAppInvitation: response.result?.sendPatientAppInvitation,
                    loading: false,
                    initLoadingFinished: true,
                });
            });
        }
    }

    @Action(SaveGeneralSettingRequest)
    public saveGeneral(
        context: StateContext<IGeneralSettingsStateModel>,
        { isAutomaticLogOutExitingESign, isReviewAfterSignedRequired, sendPatientAppInvitation }: SaveGeneralSettingRequest,
    ): Subscription {
        context.patchState({ loading: true });
        const state = context.getState();

        return this.configurationSettingsService
            .UpdateSystemSettings({
                autoLogoutPeriodMinutes: state.autoLogoutPeriodMinutes,
                isAutomaticLogOutExitingESign,
                isReviewAfterSignedRequired,
                sendPatientAppInvitation,
                isShowFinancialSummaryView: state.isShowFinancialSummaryView,
                patientFileFinancialViewMode: state.patientFileFinancialViewMode,
            })
            .subscribe({
                next: (_) => {
                    context.patchState({
                        isAutomaticLogOutExitingESign,
                        isReviewAfterSignedRequired,
                        sendPatientAppInvitation,
                        loading: false,
                    });
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }

    @Action(SavePatientFileSettingRequest)
    public saveTrizetto(
        context: StateContext<IGeneralSettingsStateModel>,
        { patientFileFinancialViewMode }: SavePatientFileSettingRequest,
    ): Subscription {
        context.patchState({ loading: true });
        const state = context.getState();

        return this.configurationSettingsService
            .UpdateSystemSettings({
                autoLogoutPeriodMinutes: state.autoLogoutPeriodMinutes,
                isAutomaticLogOutExitingESign: state.isAutomaticLogOutExitingESign,
                isReviewAfterSignedRequired: state.isReviewAfterSignedRequired,
                sendPatientAppInvitation: state.sendPatientAppInvitation,
                isShowFinancialSummaryView: state.isShowFinancialSummaryView,
                patientFileFinancialViewMode,
            })
            .subscribe({
                next: (_) => {
                    context.patchState({
                        patientFileFinancialViewMode,
                        loading: false,
                    });
                },
                error: () => {
                    context.patchState({ loading: false });
                },
            });
    }
}
