/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionPracticeLicenseDetails } from '../models/api-response-icollection-practice-license-details';
import { ApiResponseICollectionBaseUserWithLicences } from '../models/api-response-icollection-base-user-with-licences';
import { ApiResponseICollectionOkResponse } from '../models/api-response-icollection-ok-response';
import { BulkUpdateUserLicensesRequest } from '../models/bulk-update-user-licenses-request';
@Injectable({
  providedIn: 'root',
})
class PracticeLicensesService extends __BaseService {
  static readonly GetPracticeLicensesPath = '/PracticeLicenses';
  static readonly getPracticeLicensesUsersPath = '/PracticeLicenses/Users';
  static readonly patchPracticeLicensesUsersPath = '/PracticeLicenses/Users';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the practice licenses.
   * @return Success
   */
  GetPracticeLicensesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionPracticeLicenseDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PracticeLicenses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPracticeLicenseDetails>;
      })
    );
  }
  /**
   * Gets the practice licenses.
   * @return Success
   */
  GetPracticeLicenses(): __Observable<ApiResponseICollectionPracticeLicenseDetails> {
    return this.GetPracticeLicensesResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionPracticeLicenseDetails)
    );
  }

  /**
   * Gets the users with licenses.
   * @return Success
   */
  getPracticeLicensesUsersResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionBaseUserWithLicences>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PracticeLicenses/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionBaseUserWithLicences>;
      })
    );
  }
  /**
   * Gets the users with licenses.
   * @return Success
   */
  getPracticeLicensesUsers(): __Observable<ApiResponseICollectionBaseUserWithLicences> {
    return this.getPracticeLicensesUsersResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionBaseUserWithLicences)
    );
  }

  /**
   * The update user licenses.
   * @param request Symplast.WebPortal.Service.Licenses.Models.BulkUpdateUserLicensesRequest.
   * @return Success
   */
  patchPracticeLicensesUsersResponse(request?: BulkUpdateUserLicensesRequest): __Observable<__StrictHttpResponse<ApiResponseICollectionOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/PracticeLicenses/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionOkResponse>;
      })
    );
  }
  /**
   * The update user licenses.
   * @param request Symplast.WebPortal.Service.Licenses.Models.BulkUpdateUserLicensesRequest.
   * @return Success
   */
  patchPracticeLicensesUsers(request?: BulkUpdateUserLicensesRequest): __Observable<ApiResponseICollectionOkResponse> {
    return this.patchPracticeLicensesUsersResponse(request).pipe(
      __map(_r => _r.body as ApiResponseICollectionOkResponse)
    );
  }
}

module PracticeLicensesService {
}

export { PracticeLicensesService }
