import { Injectable } from '@angular/core';
import { AddressService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadTimezones, ReloadTimezones } from './timezones.actions';
import { ITimezonesStateModel } from './timezones.model';

@State<ITimezonesStateModel>({
    name: 'Timezones',
    defaults: {
        timezones: [],
        loading: false,
    },
})
@Injectable()
export class TimezonesState {
    constructor(private addressService: AddressService) {}

    @Action(LoadTimezones)
    public load(context: StateContext<ITimezonesStateModel>, { refresh }: LoadTimezones) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.timezones.length) || refresh) {
            context.patchState({ loading: true });
            this.addressService
                .GetTimeZonesList()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    context.patchState({
                        timezones: [...response.result],
                    });
                });
        }
    }

    @Action(ReloadTimezones)
    public reload(context: StateContext<ITimezonesStateModel>) {
        context.dispatch(new LoadTimezones(true));
    }
}
