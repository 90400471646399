import { Component } from '@angular/core';
import { AppSettingsSelectors, PrivateSettingsService } from '@symplast/services/shared';
import { CurrentUserService } from '../core/services/current-user.service';
import { UserTabsService } from '../services/user-tabs.service';
import { Store } from '@ngxs/store';
import { AppRoutePath } from '../app.route-path';
import { AdminRoutePath } from '../admin/admin.route-path';
import { UserEditSectionsEnum } from '@symplast/models/admin';
import { MatDividerModule } from '@angular/material/divider';
import { AnnouncementComponent } from './announcement/announcement.component';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterLink } from '@angular/router';

@Component({
    standalone: true,
    imports: [CommonModule, RouterLink, MatDividerModule, AnnouncementComponent, AngularSvgIconModule],
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
    public securityNotificationsUrl = `${AppRoutePath.SETTINGS}/${AdminRoutePath.PROFILE}`;
    public securityNotificationsQueryParams = { section: UserEditSectionsEnum.security };

    public multiFactorAuthenticationUrl = `${AppRoutePath.SETTINGS}/${AdminRoutePath.SECURITY_CONFIGURATIONS}`;

    constructor(
        public readonly userTabsService: UserTabsService,
        private privateSettingsService: PrivateSettingsService,
        private currentUserService: CurrentUserService,
        private store: Store,
    ) {}

    public get showMultiFactorAuthenticationAnnouncement(): boolean {
        return (
            this.privateSettingsService.multiFactorAuthenticationFeatureAvailable &&
            this.currentUserService.user?.hasPermission('permission-admin')
        );
    }

    public get showSymplastPaymentsAnnouncement(): boolean {
        return (
            this.currentUserService.user?.hasPermission('nwp.admin.system.integrations') &&
            this.store.selectSnapshot(AppSettingsSelectors.showSimpayBanner)
        );
    }

    public get showCalendarBlocksAnnouncement(): boolean {
        return (
            this.currentUserService.user?.hasPermission('nwp.admin.scheduling.calendarBlocks') &&
            this.privateSettingsService.calendarBlocksFeatureEnabled &&
            this.privateSettingsService.calendarBlockStatus === 'Inactive'
        );
    }
}
