/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseFinancialTag } from '../models/api-response-financial-tag';
import { FinancialTagCreateUpdateRequest } from '../models/financial-tag-create-update-request';
import { ApiResponseICollectionFinancialTag } from '../models/api-response-icollection-financial-tag';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class FinancialTagsService extends __BaseService {
  static readonly CreateFinancialTagPath = '/FinancialTags';
  static readonly GetFinancialTagsPath = '/FinancialTags';
  static readonly DeleteFinancialTagPath = '/FinancialTags/{tagId}';
  static readonly GetFinancialTagByIdPath = '/FinancialTags/{tagId}';
  static readonly UpdateFinancialTagPath = '/FinancialTags/{tagId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates the tag for specific tenant.
   * @param financialTagCreateRequest The tag creation request.
   * @return Success or Created
   */
  CreateFinancialTagResponse(financialTagCreateRequest?: FinancialTagCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseFinancialTag | ApiResponseFinancialTag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = financialTagCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/FinancialTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFinancialTag | ApiResponseFinancialTag>;
      })
    );
  }
  /**
   * Creates the tag for specific tenant.
   * @param financialTagCreateRequest The tag creation request.
   * @return Success or Created
   */
  CreateFinancialTag(financialTagCreateRequest?: FinancialTagCreateUpdateRequest): __Observable<ApiResponseFinancialTag | ApiResponseFinancialTag> {
    return this.CreateFinancialTagResponse(financialTagCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseFinancialTag | ApiResponseFinancialTag)
    );
  }

  /**
   * Get all the tags for specific tenant.
   * @return Success
   */
  GetFinancialTagsResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionFinancialTag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/FinancialTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionFinancialTag>;
      })
    );
  }
  /**
   * Get all the tags for specific tenant.
   * @return Success
   */
  GetFinancialTags(): __Observable<ApiResponseICollectionFinancialTag> {
    return this.GetFinancialTagsResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionFinancialTag)
    );
  }

  /**
   * Deletes the tag for specific tenant.
   * @param tagId The tag identifier.
   * @return Success
   */
  DeleteFinancialTagResponse(tagId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/FinancialTags/${encodeURIComponent(String(tagId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes the tag for specific tenant.
   * @param tagId The tag identifier.
   * @return Success
   */
  DeleteFinancialTag(tagId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteFinancialTagResponse(tagId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the tag by identifier for specific tenant .
   * @param tagId The tag identifier.
   * @return Success
   */
  GetFinancialTagByIdResponse(tagId: number): __Observable<__StrictHttpResponse<ApiResponseFinancialTag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/FinancialTags/${encodeURIComponent(String(tagId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFinancialTag>;
      })
    );
  }
  /**
   * Gets the tag by identifier for specific tenant .
   * @param tagId The tag identifier.
   * @return Success
   */
  GetFinancialTagById(tagId: number): __Observable<ApiResponseFinancialTag> {
    return this.GetFinancialTagByIdResponse(tagId).pipe(
      __map(_r => _r.body as ApiResponseFinancialTag)
    );
  }

  /**
   * Modifies the tag for specific tenant.
   * @param params The `FinancialTagsService.UpdateFinancialTagParams` containing the following parameters:
   *
   * - `tagId`: The tag identifier.
   *
   * - `financialTagUpdateRequest`: The tag update request.
   *
   * @return Success
   */
  UpdateFinancialTagResponse(params: FinancialTagsService.UpdateFinancialTagParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.financialTagUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/FinancialTags/${encodeURIComponent(String(params.tagId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Modifies the tag for specific tenant.
   * @param params The `FinancialTagsService.UpdateFinancialTagParams` containing the following parameters:
   *
   * - `tagId`: The tag identifier.
   *
   * - `financialTagUpdateRequest`: The tag update request.
   *
   * @return Success
   */
  UpdateFinancialTag(params: FinancialTagsService.UpdateFinancialTagParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateFinancialTagResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module FinancialTagsService {

  /**
   * Parameters for UpdateFinancialTag
   */
  export interface UpdateFinancialTagParams {

    /**
     * The tag identifier.
     */
    tagId: number;

    /**
     * The tag update request.
     */
    financialTagUpdateRequest?: FinancialTagCreateUpdateRequest;
  }
}

export { FinancialTagsService }
