import { ActivatedRouteSnapshot } from '@angular/router';

export function findInRouteData<T>(routeSnapshot: ActivatedRouteSnapshot, fieldName: string): T | null {
    if (routeSnapshot.data && routeSnapshot.data.hasOwnProperty(fieldName)) {
        return routeSnapshot.data[fieldName];
    }

    if (routeSnapshot.parent) {
        return findInRouteData(routeSnapshot.parent, fieldName);
    }

    return null;
}
