import { Injectable } from '@angular/core';
import { WebStorageService } from '@symplast/services/shared';

@Injectable({ providedIn: 'root' })
export class PasswordResetRequiredStorage {
    public readonly passwordResetRequiredKey = 'passwordResetRequired';

    constructor(private webstorage: WebStorageService) {}

    public getPasswordResetRequired(): boolean {
        return this.webstorage.local.getItem(this.passwordResetRequiredKey) === 'true';
    }

    public setPasswordResetRequired(value: boolean): void {
        this.webstorage.local.setItem(this.passwordResetRequiredKey, value ? 'true' : 'false');
    }

    public removePasswordResetRequired(): void {
        this.webstorage.local.removeItem(this.passwordResetRequiredKey);
    }
}
