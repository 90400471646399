import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'flatten', standalone: true })
export class FlattenArrayPipe implements PipeTransform {
    transform(value: any[] = [], keyName: string, useSorting = true): string {
        const flattenArray = value.map((elem) => (keyName ? elem[keyName] : elem));

        // FIXME flatten pipe shouldn't use sorting. Use sortBy instead. Added sorting by usage for backward compatibility
        if (!useSorting) {
            return flattenArray.join(', ');
        }

        return flattenArray.sort().join(', ');
    }

    constructor() {}
}
