/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableCampaignFileResponse } from '../models/api-response-ienumerable-campaign-file-response';
import { ApiResponseCampaignFileResponse } from '../models/api-response-campaign-file-response';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
    providedIn: 'root',
})
class CampaignFilesService extends __BaseService {
    static readonly GetAllFilesPath = '/campaigns/{campaignId}/files';
    static readonly AddFilePath = '/campaigns/{campaignId}/files';
    static readonly GetFileByIdPath = '/campaigns/{campaignId}/files/{id}';
    static readonly DeleteFilePath = '/campaigns/{campaignId}/files/{id}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * <code> GET api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files </code>
     * @param campaignId The campaign identifier.
     * @return Success
     */
    GetAllFilesResponse(campaignId: string): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCampaignFileResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/campaigns/${campaignId}/files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableCampaignFileResponse>;
            }),
        );
    }
    /**
     * <code> GET api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files </code>
     * @param campaignId The campaign identifier.
     * @return Success
     */
    GetAllFiles(campaignId: string): __Observable<ApiResponseIEnumerableCampaignFileResponse> {
        return this.GetAllFilesResponse(campaignId).pipe(__map((_r) => _r.body as ApiResponseIEnumerableCampaignFileResponse));
    }

    /**
     * <code> POST api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files </code>
     * @param params The `CampaignFilesService.AddFileParams` containing the following parameters:
     *
     * - `campaignId`: The campaign identifier.
     *
     * - `file`:
     *
     * @return Success
     */
    AddFileResponse(params: CampaignFilesService.AddFileParams): __Observable<__StrictHttpResponse<ApiResponseCampaignFileResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.file != null) {
            __formData.append('file', params.file as string | Blob);
        }
        let req = new HttpRequest<any>('POST', this.rootUrl + `/campaigns/${params.campaignId}/files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCampaignFileResponse>;
            }),
        );
    }
    /**
     * <code> POST api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files </code>
     * @param params The `CampaignFilesService.AddFileParams` containing the following parameters:
     *
     * - `campaignId`: The campaign identifier.
     *
     * - `file`:
     *
     * @return Success
     */
    AddFile(params: CampaignFilesService.AddFileParams): __Observable<ApiResponseCampaignFileResponse> {
        return this.AddFileResponse(params).pipe(__map((_r) => _r.body as ApiResponseCampaignFileResponse));
    }

    /**
     * <code> GET api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files/d4e0cef2-8059-4400-8c3c-06cf6a78f4a0 </code>
     * @param params The `CampaignFilesService.GetFileByIdParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `campaignId`: The campaign identifier.
     *
     * @return Success
     */
    GetFileByIdResponse(
        params: CampaignFilesService.GetFileByIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCampaignFileResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/campaigns/${params.campaignId}/files/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCampaignFileResponse>;
            }),
        );
    }
    /**
     * <code> GET api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files/d4e0cef2-8059-4400-8c3c-06cf6a78f4a0 </code>
     * @param params The `CampaignFilesService.GetFileByIdParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `campaignId`: The campaign identifier.
     *
     * @return Success
     */
    GetFileById(params: CampaignFilesService.GetFileByIdParams): __Observable<ApiResponseCampaignFileResponse> {
        return this.GetFileByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseCampaignFileResponse));
    }

    /**
     * <code> DELETE api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files/d4e0cef2-8059-4400-8c3c-06cf6a78f4a0 </code>
     * @param params The `CampaignFilesService.DeleteFileParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `campaignId`: The campaign identifier.
     *
     * @return Success
     */
    DeleteFileResponse(params: CampaignFilesService.DeleteFileParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/campaigns/${params.campaignId}/files/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * <code> DELETE api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/files/d4e0cef2-8059-4400-8c3c-06cf6a78f4a0 </code>
     * @param params The `CampaignFilesService.DeleteFileParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `campaignId`: The campaign identifier.
     *
     * @return Success
     */
    DeleteFile(params: CampaignFilesService.DeleteFileParams): __Observable<ApiResponseOkResponse> {
        return this.DeleteFileResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module CampaignFilesService {
    /**
     * Parameters for AddFile
     */
    export interface AddFileParams {
        /**
         * The campaign identifier.
         */
        campaignId: string;
        file?: Blob;
    }

    /**
     * Parameters for GetFileById
     */
    export interface GetFileByIdParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The campaign identifier.
         */
        campaignId: string;
    }

    /**
     * Parameters for DeleteFile
     */
    export interface DeleteFileParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The campaign identifier.
         */
        campaignId: string;
    }
}

export { CampaignFilesService };
