import { ErrorHandler, Provider } from '@angular/core';
import { CustomizedErrorHandler, ERROR_HANDLER, GlobalErrorHandlerManager } from '../error-handlers';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

export function bugsnagErrorHandlerFactory(): ErrorHandler {
    return new BugsnagErrorHandler();
}

export const ERROR_HANDLERS_PROVIDER: Provider[] = [
    { provide: ERROR_HANDLER, useExisting: CustomizedErrorHandler, multi: true },
    { provide: ERROR_HANDLER, useFactory: bugsnagErrorHandlerFactory, multi: true },
    { provide: ErrorHandler, useExisting: GlobalErrorHandlerManager },
];
