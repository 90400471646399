/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseReferringProvider } from '../models/api-response-referring-provider';
import { ReferringProviderCreateUpdateRequest } from '../models/referring-provider-create-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseICollectionReferringProvider } from '../models/api-response-icollection-referring-provider';
@Injectable({
  providedIn: 'root',
})
class ReferringProvidersService extends __BaseService {
  static readonly CreateReferringProviderPath = '/Insurance/ReferringProvider';
  static readonly DeleteReferringProviderPath = '/Insurance/ReferringProvider/{referringProviderId}';
  static readonly GetReferringProviderByIdPath = '/Insurance/ReferringProvider/{referringProviderId}';
  static readonly GetReferringProvidersPath = '/Insurance/ReferringProviders';
  static readonly UpdateReferringProviderPath = '/Insurance/ReferringProviders/{referringProviderId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create the referring provider.
   * @param referringProviderCreateUpdateRequest The referringProvider Create Update Request.
   * @return Success
   */
  CreateReferringProviderResponse(referringProviderCreateUpdateRequest?: ReferringProviderCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseReferringProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = referringProviderCreateUpdateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/ReferringProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseReferringProvider>;
      })
    );
  }
  /**
   * Create the referring provider.
   * @param referringProviderCreateUpdateRequest The referringProvider Create Update Request.
   * @return Success
   */
  CreateReferringProvider(referringProviderCreateUpdateRequest?: ReferringProviderCreateUpdateRequest): __Observable<ApiResponseReferringProvider> {
    return this.CreateReferringProviderResponse(referringProviderCreateUpdateRequest).pipe(
      __map(_r => _r.body as ApiResponseReferringProvider)
    );
  }

  /**
   * Delete the referring provider.
   * @param referringProviderId The referringProvider Id.
   * @return Success
   */
  DeleteReferringProviderResponse(referringProviderId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Insurance/ReferringProvider/${encodeURIComponent(String(referringProviderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete the referring provider.
   * @param referringProviderId The referringProvider Id.
   * @return Success
   */
  DeleteReferringProvider(referringProviderId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteReferringProviderResponse(referringProviderId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the referring provider by id.
   * @param referringProviderId The referringProvider Id.
   * @return Success
   */
  GetReferringProviderByIdResponse(referringProviderId: number): __Observable<__StrictHttpResponse<ApiResponseReferringProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/ReferringProvider/${encodeURIComponent(String(referringProviderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseReferringProvider>;
      })
    );
  }
  /**
   * Get the referring provider by id.
   * @param referringProviderId The referringProvider Id.
   * @return Success
   */
  GetReferringProviderById(referringProviderId: number): __Observable<ApiResponseReferringProvider> {
    return this.GetReferringProviderByIdResponse(referringProviderId).pipe(
      __map(_r => _r.body as ApiResponseReferringProvider)
    );
  }

  /**
   * Get referring providers.
   * @param searchName Name for searching.
   * @return Success
   */
  GetReferringProvidersResponse(searchName?: string): __Observable<__StrictHttpResponse<ApiResponseICollectionReferringProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (searchName != null) __params = __params.set('searchName', searchName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/ReferringProviders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionReferringProvider>;
      })
    );
  }
  /**
   * Get referring providers.
   * @param searchName Name for searching.
   * @return Success
   */
  GetReferringProviders(searchName?: string): __Observable<ApiResponseICollectionReferringProvider> {
    return this.GetReferringProvidersResponse(searchName).pipe(
      __map(_r => _r.body as ApiResponseICollectionReferringProvider)
    );
  }

  /**
   * Update the referring provider.
   * @param params The `ReferringProvidersService.UpdateReferringProviderParams` containing the following parameters:
   *
   * - `referringProviderId`: The referringProvider Id.
   *
   * - `referringProviderCreateUpdateRequest`: The referringProvider Create Update Request.
   *
   * @return Success
   */
  UpdateReferringProviderResponse(params: ReferringProvidersService.UpdateReferringProviderParams): __Observable<__StrictHttpResponse<ApiResponseReferringProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.referringProviderCreateUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/ReferringProviders/${encodeURIComponent(String(params.referringProviderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseReferringProvider>;
      })
    );
  }
  /**
   * Update the referring provider.
   * @param params The `ReferringProvidersService.UpdateReferringProviderParams` containing the following parameters:
   *
   * - `referringProviderId`: The referringProvider Id.
   *
   * - `referringProviderCreateUpdateRequest`: The referringProvider Create Update Request.
   *
   * @return Success
   */
  UpdateReferringProvider(params: ReferringProvidersService.UpdateReferringProviderParams): __Observable<ApiResponseReferringProvider> {
    return this.UpdateReferringProviderResponse(params).pipe(
      __map(_r => _r.body as ApiResponseReferringProvider)
    );
  }
}

module ReferringProvidersService {

  /**
   * Parameters for UpdateReferringProvider
   */
  export interface UpdateReferringProviderParams {

    /**
     * The referringProvider Id.
     */
    referringProviderId: number;

    /**
     * The referringProvider Create Update Request.
     */
    referringProviderCreateUpdateRequest?: ReferringProviderCreateUpdateRequest;
  }
}

export { ReferringProvidersService }
