import { Injectable } from '@angular/core';
import { BaseEnumService } from './base-enum.service';

@Injectable({
    providedIn: 'root',
})
export class FeeCollectionEnumService extends BaseEnumService {
    constructor() {
        super();
        this.data = [
            {
                name: 'Collect',
                key: 'Collect',
            },
            {
                name: 'Collect & Pay Out',
                key: 'CollectAndPayOut',
            },
            {
                name: 'Not Collect',
                key: 'NotCollect',
            },
        ];
    }
}
