import { Selector } from '@ngxs/store';
import { IPaymentModesStateModel } from './payment-modes.model';
import { PaymentModesState } from './payment-modes.state';

export class PaymentModesSelectors {
    @Selector([PaymentModesState])
    public static loading(state: IPaymentModesStateModel): boolean {
        return state.loading;
    }

    @Selector([PaymentModesState])
    public static paymentModes(state: IPaymentModesStateModel): IPaymentModesStateModel['paymentModes'] {
        return state.paymentModes;
    }

    @Selector([PaymentModesState])
    public static paymentModesExcludeInternal(state: IPaymentModesStateModel): IPaymentModesStateModel['paymentModes'] {
        return state.paymentModes.filter(
            (paymentMode) => paymentMode.paymentTypeName !== 'GiftCard' && paymentMode.paymentTypeName !== 'PatientCredit',
        );
    }

    @Selector([PaymentModesState])
    public static paymentModesIncludeDeleted(state: IPaymentModesStateModel): IPaymentModesStateModel['paymentModesIncludeDeleted'] {
        return state.paymentModesIncludeDeleted;
    }
}
