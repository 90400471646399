export const INDICATOR_RED = 'red';
export const INDICATOR_BLUE = 'blue';
export const INDICATOR_GREEN = 'green';
export const INDICATOR_ORANGE = 'orange';
export const INDICATOR_GREY = 'grey';
export const INDICATOR_PURPLE = 'purple';

export const INDICATORS_COLOR_MAP = {
    [INDICATOR_PURPLE]: 'var(--color-purple)',
    [INDICATOR_GREEN]: 'var(--color-dark-green)',
    [INDICATOR_BLUE]: 'var(--color-blue)',
    [INDICATOR_RED]: 'var(--color-red)',
    [INDICATOR_ORANGE]: 'var(--color-base-orange)',
    [INDICATOR_GREY]: 'var(--color-gray)',
};
