import { Injectable } from '@angular/core';
import { WebStorageService } from '@symplast/services/shared';

@Injectable({ providedIn: 'root' })
export class MobilePhoneConfirmStorage {
    public readonly mobilePhoneConfirmDeclined = 'mobilePhoneConfirmCanceled';

    constructor(private webStorage: WebStorageService) {}

    public getMobilePhoneConfirmCanceled(): boolean {
        return this.webStorage.local.getItem(this.mobilePhoneConfirmDeclined) === 'true';
    }

    public setMobilePhoneConfirmCanceled(): void {
        this.webStorage.local.setItem(this.mobilePhoneConfirmDeclined, 'true');
    }

    public removePhoneConfirmCanceled(): void {
        this.webStorage.local.removeItem(this.mobilePhoneConfirmDeclined);
    }
}
