import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, fromEvent, debounceTime, delay, distinctUntilChanged, Observable, map } from 'rxjs';
import { OverflowElementDirective } from './overflow-element.directive';

@UntilDestroy()
@Directive({
    standalone: true,
    selector: '[symplastOverflowContainerBy]',
    exportAs: 'symplastOverflowContainerBy',
})
/**
 * A directive that determines whether the content fits into the container
 * The value of whether the container is full is immediately emited via containerOverflow,
 * and also emits when the overflow changes
 *
 * You can also get a directive via ViewChild and subscribe to the overflow$ stream
 *
 * Restriction: to get the result, you need to use 2 directives together:
 * symplastOverflowContainerBy and symplastOverflowElement
 *
 * Therefore, to check whether the text fits into the container area, you need to wrap the text in span or div
 * Important: in such a case, you do not need to add styles like border, padding or margin to the node with text,
 * since they are taken into account when measuring the dimensions
 */
export class OverflowContainerDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input('symplastOverflowContainerBy') overflowElement!: OverflowElementDirective;
    @Output() containerOverflow = new EventEmitter<boolean>();

    public overflow$: Observable<boolean>;
    public noOverflow$: Observable<boolean>;
    private _overflow$ = new ReplaySubject<boolean>(1);
    private mutationObserver$!: MutationObserver;

    constructor(private readonly elementRef: ElementRef) {
        this.overflow$ = this._overflow$.asObservable().pipe(distinctUntilChanged(), delay(0));
        this.noOverflow$ = this.overflow$.pipe(map((hasOverflow) => !hasOverflow));
    }

    public ngOnInit(): void {
        this.overflow$.pipe(untilDestroyed(this)).subscribe((hasOverflow) => this.containerOverflow.emit(hasOverflow));

        fromEvent(window, 'resize')
            .pipe(debounceTime(50), untilDestroyed(this))
            .subscribe(() => this.notify());
    }

    public ngAfterViewInit(): void {
        if (!!(window as any).MutationObserver) {
            this.mutationObserver$ = new MutationObserver(() => this.notify());
            this.mutationObserver$.observe(this.elementRef.nativeElement, {
                characterData: true,
                subtree: true,
            });
        }

        this.notify();
    }

    public ngOnDestroy(): void {
        if (!!(window as any).MutationObserver) {
            this.mutationObserver$.disconnect();
        }
    }

    public notify(): void {
        this._overflow$.next(this.isContainerOverfilled());
    }

    private isContainerOverfilled(): boolean {
        let displayChanged = false;
        const containerElement = this.elementRef.nativeElement;
        const contentElement = this.overflowElement.elementRef.nativeElement;
        const containerStyles = getComputedStyle(containerElement);
        const contentStyles = getComputedStyle(contentElement);

        // https://stackoverflow.com/a/13227435
        if (contentStyles.display === 'inline') {
            displayChanged = true;
            contentElement.style.display = 'block';
        }

        let realContainerWidth = containerElement.offsetWidth;
        let realContainerHeight = containerElement.offsetHeight;
        let realContentWidth = contentElement.scrollWidth;
        let realContentHeight = contentElement.scrollHeight;

        switch (containerStyles.boxSizing) {
            case 'padding-box':
                realContainerWidth -= parseFloat(containerStyles.borderLeftWidth) + parseFloat(containerStyles.borderRightWidth);
                realContainerHeight -= parseFloat(containerStyles.borderTopWidth) + parseFloat(containerStyles.borderBottomWidth);
                break;
            case 'border-box':
                realContainerWidth -= parseFloat(containerStyles.borderLeftWidth) + parseFloat(containerStyles.borderRightWidth);
                realContainerWidth -= parseFloat(containerStyles.paddingLeft) + parseFloat(containerStyles.paddingRight);
                realContainerHeight -= parseFloat(containerStyles.borderTopWidth) + parseFloat(containerStyles.borderBottomWidth);
                realContainerHeight -= parseFloat(containerStyles.paddingTop) + parseFloat(containerStyles.paddingBottom);
                break;
            case 'content-box':
            default:
                break;
        }

        realContentWidth += parseFloat(contentStyles.marginLeft) + parseFloat(contentStyles.marginRight);
        realContentHeight += parseFloat(contentStyles.marginTop) + parseFloat(contentStyles.marginBottom);

        if (displayChanged) {
            contentElement.style.display = 'inline';
        }

        return realContainerWidth < realContentWidth || realContainerHeight < realContentHeight;
    }
}

export const OVERFLOW_CONTAINER_DIRECTIVES = [OverflowContainerDirective, OverflowElementDirective] as const;
