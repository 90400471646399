import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, Observable } from 'rxjs';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
    public readonly currentYear = new Date().getFullYear().toString();
    public showCptCopyright$: Observable<boolean>;

    constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.showCptCopyright$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }

                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data),
            map((data) => data?.isShowCptCopyright ?? false),
        );
    }
}
