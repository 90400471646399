import { Selector } from '@ngxs/store';
import { IAppSettingsStateModel } from './app-settings.model';
import { AppSettingsState } from './app-settings.state';

export class AppSettingsSelectors {
    @Selector([AppSettingsState])
    public static showAppMainMenu(state: IAppSettingsStateModel): boolean {
        return state.showAppMainMenu;
    }

    @Selector([AppSettingsState])
    public static enable(state: IAppSettingsStateModel): boolean {
        return state.showAppHeader;
    }

    @Selector([AppSettingsState])
    public static device(state: IAppSettingsStateModel): string {
        return state.device;
    }

    @Selector([AppSettingsState])
    public static isWebView(state: IAppSettingsStateModel): boolean {
        return state.isWebView;
    }

    @Selector([AppSettingsState])
    public static isTaskWorkflowEnabled(state: IAppSettingsStateModel): boolean {
        return state.isTaskWorkflowEnabled;
    }

    @Selector([AppSettingsState])
    public static tenantId(state: IAppSettingsStateModel): number | undefined {
        return state.tenantId;
    }

    @Selector([AppSettingsState])
    public static tenantName(state: IAppSettingsStateModel): string | undefined {
        return state.tenantName;
    }

    @Selector([AppSettingsState])
    public static isGaldermaEnabled(state: IAppSettingsStateModel): boolean {
        return state.isGaldermaEnabled;
    }

    @Selector([AppSettingsState])
    public static isSymplastFormsEnabled(state: IAppSettingsStateModel): boolean {
        return state.isSymplastFormEnabled;
    }

    @Selector([AppSettingsState])
    public static isSimpayFeatureEnabled(state: IAppSettingsStateModel): boolean {
        return state.isSimpayFeatureEnabled;
    }

    @Selector([AppSettingsState])
    public static showSimpayBanner(state: IAppSettingsStateModel): boolean {
        return state.isSimpayFeatureEnabled && !state.isSimpayEnabled;
    }

    @Selector([AppSettingsState])
    public static simpaySupportPhoneNumber(state: IAppSettingsStateModel): string {
        return state.simpaySupportPhoneNumber;
    }

    @Selector([AppSettingsState])
    public static oarLink(state: IAppSettingsStateModel): string {
        return state.oarLink;
    }

    @Selector([AppSettingsState])
    public static isApxEnabled(state: IAppSettingsStateModel): boolean {
        return state.isApxEnabled;
    }

    @Selector([AppSettingsState])
    public static isSymplastPaymentsSettingsVisible(state: IAppSettingsStateModel): boolean {
        return state.isSymplastPaymentsSettingsVisible;
    }

    @Selector([AppSettingsState])
    public static isFinancialsEnabled(state: IAppSettingsStateModel): boolean {
        return state.isFinancialsEnabled;
    }
}
