import { Selector } from '@ngxs/store';
import { ITypePurposesStateModel } from './type-purposes.model';
import { TypePurposesState } from './type-purposes.state';

export class TypePurposesSelectors {
    @Selector([TypePurposesState])
    public static loading(state: ITypePurposesStateModel): boolean {
        return state.loading;
    }

    @Selector([TypePurposesState])
    public static typePurposes(state: ITypePurposesStateModel): ITypePurposesStateModel['typePurposes'] {
        return state.typePurposes;
    }
}
