import { LocationPaymentSettings } from '@symplast/generated-clients/web-portal';

export class LoadOarSettings {
    public static readonly type = '[OarSettings] Load';
    constructor(public refresh?: boolean) {}
}

export class SaveOarSettingsRequest {
    public static readonly type = '[OarSettings] Save';
    constructor(
        public enabled: boolean,
        public providers: number[],
        public locations: number[],
        public usersForNotify: number[],
        public services: number[],
        public isDateOfBirthInputVisible: boolean,
        public isEmailAddressInputVisible: boolean,
        public isGenderInputVisible: boolean,
    ) {}
}

export class SaveOarLocations {
    public static readonly type = '[OarSettings] Save Locations';
    constructor(public locations: number[]) {}
}

export class SaveOarProviders {
    public static readonly type = '[OarSettings] Save Providers';
    constructor(public providers: number[]) {}
}

export class SaveOarServices {
    public static readonly type = '[OarSettings] Save Services';
    constructor(public services: number[]) {}
}

export class SaveOarUsersForNotify {
    public static readonly type = '[OarSettings] Save Users for Notify';
    constructor(public usersForNotify: number[]) {}
}

export class SaveOarRequiredFields {
    public static readonly type = '[OarSettings] Save Required Fields';
    constructor(
        public isDateOfBirthInputVisible: boolean,
        public isEmailAddressInputVisible: boolean,
        public isGenderInputVisible: boolean,
    ) {}
}

export class SaveOarGeneralSettings {
    public static readonly type = '[OarSettings] Save General Settings';
    constructor(public enabled: boolean) {}
}

export class SaveDepositSettings {
    public static readonly type = '[OarSettings] Save Deposit Settings';
    constructor(public enableBookingDeposits: boolean, public locationPaymentSettings: LocationPaymentSettings[]) {}
}
