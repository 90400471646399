/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableCptCode } from '../models/api-response-ienumerable-cpt-code';
import { ApiResponseIEnumerableCptModifier } from '../models/api-response-ienumerable-cpt-modifier';
import { ApiResponseIEnumerableIcdCode } from '../models/api-response-ienumerable-icd-code';
import { HealthInsuranceClaimForm } from '../models/health-insurance-claim-form';
import { ApiResponseHealthInsuranceClaimForm } from '../models/api-response-health-insurance-claim-form';
import { ApiResponseCharge } from '../models/api-response-charge';
import { ChargeUpdateRequest } from '../models/charge-update-request';
import { ApiResponseListFailedClaimUpdate } from '../models/api-response-list-failed-claim-update';
import { ClaimMultipleStatusRequest } from '../models/claim-multiple-status-request';
import { ApiResponseClaimWithError } from '../models/api-response-claim-with-error';
@Injectable({
  providedIn: 'root',
})
class InsuranceService extends __BaseService {
  static readonly GetCptCodesPath = '/Insurance/CptCodes';
  static readonly GetCptModifiersPath = '/Insurance/CptModifiers';
  static readonly GetIcdCodesPath = '/Insurance/IcdCodes';
  static readonly GetPdfClaimPath = '/Insurance/Claims/{claimId}/Forms/Pdf';
  static readonly GetClaimFormsPath = '/Insurance/Claims/{claimId}/Forms';
  static readonly GetForm837Path = '/Insurance/Claims/{claimId}/Form837/File';
  static readonly UpdateChargePath = '/Insurance/Charges/{chargeId}';
  static readonly UpdateMultipleStatusClaimPath = '/Insurance/MultipleStatus';
  static readonly SendToTrizettoPath = '/Insurance/Claims/{claimId}/SendToTrizetto';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of CPT codes by text.
   * @param params The `InsuranceService.GetCptCodesParams` containing the following parameters:
   *
   * - `Take`: Gets or sets the take.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `Search`: Gets or sets the search text.
   *
   * - `Codes`: Gets or sets the search codes.
   *
   * @return Success
   */
  GetCptCodesResponse(params: InsuranceService.GetCptCodesParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Take != null) __params = __params.set('Take', params.Take.toString());
    if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Codes != null) __params = __params.set('Codes', params.Codes.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/CptCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptCode>;
      })
    );
  }
  /**
   * Gets a list of CPT codes by text.
   * @param params The `InsuranceService.GetCptCodesParams` containing the following parameters:
   *
   * - `Take`: Gets or sets the take.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `Search`: Gets or sets the search text.
   *
   * - `Codes`: Gets or sets the search codes.
   *
   * @return Success
   */
  GetCptCodes(params: InsuranceService.GetCptCodesParams): __Observable<ApiResponseIEnumerableCptCode> {
    return this.GetCptCodesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptCode)
    );
  }

  /**
   * Gets a list of CPT modifier by text.
   * @param params The `InsuranceService.GetCptModifiersParams` containing the following parameters:
   *
   * - `Take`: Gets or sets the take.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `Search`: Gets or sets the search text.
   *
   * - `Codes`: Gets or sets the search codes.
   *
   * @return Success
   */
  GetCptModifiersResponse(params: InsuranceService.GetCptModifiersParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCptModifier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Take != null) __params = __params.set('Take', params.Take.toString());
    if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Codes != null) __params = __params.set('Codes', params.Codes.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/CptModifiers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCptModifier>;
      })
    );
  }
  /**
   * Gets a list of CPT modifier by text.
   * @param params The `InsuranceService.GetCptModifiersParams` containing the following parameters:
   *
   * - `Take`: Gets or sets the take.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `Search`: Gets or sets the search text.
   *
   * - `Codes`: Gets or sets the search codes.
   *
   * @return Success
   */
  GetCptModifiers(params: InsuranceService.GetCptModifiersParams): __Observable<ApiResponseIEnumerableCptModifier> {
    return this.GetCptModifiersResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCptModifier)
    );
  }

  /**
   * Gets a list of ICD codes by text.
   * @param params The `InsuranceService.GetIcdCodesParams` containing the following parameters:
   *
   * - `Take`: Gets or sets the take.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `Search`: Gets or sets the search text.
   *
   * - `Codes`: Gets or sets the search codes.
   *
   * @return Success
   */
  GetIcdCodesResponse(params: InsuranceService.GetIcdCodesParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableIcdCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Take != null) __params = __params.set('Take', params.Take.toString());
    if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Codes != null) __params = __params.set('Codes', params.Codes.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/IcdCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableIcdCode>;
      })
    );
  }
  /**
   * Gets a list of ICD codes by text.
   * @param params The `InsuranceService.GetIcdCodesParams` containing the following parameters:
   *
   * - `Take`: Gets or sets the take.
   *
   * - `Skip`: Gets or sets the skip.
   *
   * - `Search`: Gets or sets the search text.
   *
   * - `Codes`: Gets or sets the search codes.
   *
   * @return Success
   */
  GetIcdCodes(params: InsuranceService.GetIcdCodesParams): __Observable<ApiResponseIEnumerableIcdCode> {
    return this.GetIcdCodesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableIcdCode)
    );
  }

  /**
   * Get the PDF form.
   * @param params The `InsuranceService.GetPdfClaimParams` containing the following parameters:
   *
   * - `claimId`: The claim id.
   *
   * - `printType`: The print Type.
   *
   * - `healthInsuranceClaimForm`: The health Insurance Claim Form.
   *
   * @return Success
   */
  GetPdfClaimResponse(params: InsuranceService.GetPdfClaimParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.printType != null) __params = __params.set('printType', params.printType.toString());
    __body = params.healthInsuranceClaimForm;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(params.claimId))}/Forms/Pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Get the PDF form.
   * @param params The `InsuranceService.GetPdfClaimParams` containing the following parameters:
   *
   * - `claimId`: The claim id.
   *
   * - `printType`: The print Type.
   *
   * - `healthInsuranceClaimForm`: The health Insurance Claim Form.
   *
   * @return Success
   */
  GetPdfClaim(params: InsuranceService.GetPdfClaimParams): __Observable<Blob> {
    return this.GetPdfClaimResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get the claim form.
   * @param claimId The claim id.
   * @return Success
   */
  GetClaimFormsResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseHealthInsuranceClaimForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}/Forms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseHealthInsuranceClaimForm>;
      })
    );
  }
  /**
   * Get the claim form.
   * @param claimId The claim id.
   * @return Success
   */
  GetClaimForms(claimId: number): __Observable<ApiResponseHealthInsuranceClaimForm> {
    return this.GetClaimFormsResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseHealthInsuranceClaimForm)
    );
  }

  /**
   * Get the claim form.
   * @param claimId The claim id.
   * @return Success
   */
  GetForm837Response(claimId: number): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}/Form837/File`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Get the claim form.
   * @param claimId The claim id.
   * @return Success
   */
  GetForm837(claimId: number): __Observable<Blob> {
    return this.GetForm837Response(claimId).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Update the charge.
   * @param params The `InsuranceService.UpdateChargeParams` containing the following parameters:
   *
   * - `chargeId`: The charge id.
   *
   * - `chargeUpdateRequest`: The charge to update.
   *
   * @return Success
   */
  UpdateChargeResponse(params: InsuranceService.UpdateChargeParams): __Observable<__StrictHttpResponse<ApiResponseCharge>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.chargeUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Charges/${encodeURIComponent(String(params.chargeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCharge>;
      })
    );
  }
  /**
   * Update the charge.
   * @param params The `InsuranceService.UpdateChargeParams` containing the following parameters:
   *
   * - `chargeId`: The charge id.
   *
   * - `chargeUpdateRequest`: The charge to update.
   *
   * @return Success
   */
  UpdateCharge(params: InsuranceService.UpdateChargeParams): __Observable<ApiResponseCharge> {
    return this.UpdateChargeResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCharge)
    );
  }

  /**
   * Update multiple claims status.
   * @param claimStatusRequest The submitted request.
   * @return Success
   */
  UpdateMultipleStatusClaimResponse(claimStatusRequest?: ClaimMultipleStatusRequest): __Observable<__StrictHttpResponse<ApiResponseListFailedClaimUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = claimStatusRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/MultipleStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseListFailedClaimUpdate>;
      })
    );
  }
  /**
   * Update multiple claims status.
   * @param claimStatusRequest The submitted request.
   * @return Success
   */
  UpdateMultipleStatusClaim(claimStatusRequest?: ClaimMultipleStatusRequest): __Observable<ApiResponseListFailedClaimUpdate> {
    return this.UpdateMultipleStatusClaimResponse(claimStatusRequest).pipe(
      __map(_r => _r.body as ApiResponseListFailedClaimUpdate)
    );
  }

  /**
   * Submit the claim to Trizetto.
   * @param claimId The claim id.
   * @return Success
   */
  SendToTrizettoResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseClaimWithError>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}/SendToTrizetto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseClaimWithError>;
      })
    );
  }
  /**
   * Submit the claim to Trizetto.
   * @param claimId The claim id.
   * @return Success
   */
  SendToTrizetto(claimId: number): __Observable<ApiResponseClaimWithError> {
    return this.SendToTrizettoResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseClaimWithError)
    );
  }
}

module InsuranceService {

  /**
   * Parameters for GetCptCodes
   */
  export interface GetCptCodesParams {

    /**
     * Gets or sets the take.
     */
    Take?: number;

    /**
     * Gets or sets the skip.
     */
    Skip?: number;

    /**
     * Gets or sets the search text.
     */
    Search?: string;

    /**
     * Gets or sets the search codes.
     */
    Codes?: string;
  }

  /**
   * Parameters for GetCptModifiers
   */
  export interface GetCptModifiersParams {

    /**
     * Gets or sets the take.
     */
    Take?: number;

    /**
     * Gets or sets the skip.
     */
    Skip?: number;

    /**
     * Gets or sets the search text.
     */
    Search?: string;

    /**
     * Gets or sets the search codes.
     */
    Codes?: string;
  }

  /**
   * Parameters for GetIcdCodes
   */
  export interface GetIcdCodesParams {

    /**
     * Gets or sets the take.
     */
    Take?: number;

    /**
     * Gets or sets the skip.
     */
    Skip?: number;

    /**
     * Gets or sets the search text.
     */
    Search?: string;

    /**
     * Gets or sets the search codes.
     */
    Codes?: string;
  }

  /**
   * Parameters for GetPdfClaim
   */
  export interface GetPdfClaimParams {

    /**
     * The claim id.
     */
    claimId: number;

    /**
     * The print Type.
     */
    printType?: 'Filled' | 'Blank';

    /**
     * The health Insurance Claim Form.
     */
    healthInsuranceClaimForm?: HealthInsuranceClaimForm;
  }

  /**
   * Parameters for UpdateCharge
   */
  export interface UpdateChargeParams {

    /**
     * The charge id.
     */
    chargeId: number;

    /**
     * The charge to update.
     */
    chargeUpdateRequest?: ChargeUpdateRequest;
  }
}

export { InsuranceService }
