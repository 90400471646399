import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { CurrentUserService } from '../../core/services/current-user.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
    constructor(private currentUserService: CurrentUserService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        return this.validatePermission(route);
    }
    canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | UrlTree {
        return this.validatePermission(childRoute);
    }

    private validatePermission(route: ActivatedRouteSnapshot): boolean | UrlTree {
        if (!route.data.permission) {
            return true;
        }

        const permissions = route.data.permission instanceof Array ? route.data.permission : [route.data.permission];

        return this.currentUserService.user?.hasPermissions(permissions) || this.router.createUrlTree(['/']);
    }
}
