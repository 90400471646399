/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseClaimExtendedInfo } from '../models/api-response-claim-extended-info';
import { ClaimCreateUpdateRequest } from '../models/claim-create-update-request';
import { ApiPagedResponseICollectionClaim } from '../models/api-paged-response-icollection-claim';
import { ApiResponseIListGetDuplicatedClaimsResponse } from '../models/api-response-ilist-get-duplicated-claims-response';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseClaimWithError } from '../models/api-response-claim-with-error';
import { ClaimStatusRequest } from '../models/claim-status-request';
import { ApiResponseICollectionClaimRevision } from '../models/api-response-icollection-claim-revision';
import { ApiResponseClaimExtendedRevision } from '../models/api-response-claim-extended-revision';
import { ApiResponseIListString } from '../models/api-response-ilist-string';
import { ApiResponseIEnumerableResubmissionQualifierCode } from '../models/api-response-ienumerable-resubmission-qualifier-code';
import { ApiResponseListString } from '../models/api-response-list-string';
@Injectable({
  providedIn: 'root',
})
class ClaimsService extends __BaseService {
  static readonly CreateClaimPath = '/Insurance/Claims';
  static readonly GetClaimsPath = '/Insurance/Claims';
  static readonly GetDuplicatedClaimsPath = '/Insurance/Claims/Duplicates';
  static readonly CreateSecondaryClaimPath = '/Insurance/Claims/{originalClaimId}/Secondary';
  static readonly UpdateClaimPath = '/Insurance/Claims/{claimId}';
  static readonly GetClaimByIdPath = '/Insurance/Claims/{claimId}';
  static readonly DeleteClaimPath = '/Insurance/Claims/{claimId}';
  static readonly UpdateStatusClaimPath = '/Insurance/Claims/{claimId}/Status';
  static readonly ExportedClaimPath = '/Insurance/Claims/{claimId}/Exported';
  static readonly GetClaimRevisionsPath = '/Insurance/Claims/{claimId}/Revisions';
  static readonly GetClaimRevisionByIdPath = '/Insurance/Claims/{claimId}/Revisions/{revisionId}';
  static readonly GetFormValidationPath = '/Claims/FormValidation';
  static readonly GetIncidentQualifierCodesPath = '/Claims/ResubmissionQualifierCodes';
  static readonly DeleteClaimValidationPath = '/Insurance/Claims/{claimId}/Validation';
  static readonly UpdateInvoiceLegalTextPath = '/Insurance/Claims/{claimId}/InvoiceLegalText';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates a new claim.
   * @param claimCreateRequest The claim Create Request.
   * @return Success
   */
  CreateClaimResponse(claimCreateRequest?: ClaimCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseClaimExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = claimCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Claims`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseClaimExtendedInfo>;
      })
    );
  }
  /**
   * Creates a new claim.
   * @param claimCreateRequest The claim Create Request.
   * @return Success
   */
  CreateClaim(claimCreateRequest?: ClaimCreateUpdateRequest): __Observable<ApiResponseClaimExtendedInfo> {
    return this.CreateClaimResponse(claimCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseClaimExtendedInfo)
    );
  }

  /**
   * Get the claims.
   * @param params The `ClaimsService.GetClaimsParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `policyNumber`: the policy number to filter.
   *
   * - `patientName`: The patient name.
   *
   * - `patientId`: The patient.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * - `insuranceId`: The insurance id.
   *
   * - `includeRemittanceAmount`: The include Remittance Amount.
   *
   * - `includeInsurance`: Value indicate include insurance information.
   *
   * - `followUpOnly`: Value to filter flagged charges only.
   *
   * - `dateTo`: The date to.
   *
   * - `dateFrom`: The date from.
   *
   * - `claimOrder`: The claim Order.
   *
   * - `claimNumber`: The claim Number.
   *
   * - `caseId`: The case id.
   *
   * @return Success
   */
  GetClaimsResponse(params: ClaimsService.GetClaimsParams): __Observable<__StrictHttpResponse<ApiPagedResponseICollectionClaim>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.policyNumber != null) __params = __params.set('policyNumber', params.policyNumber.toString());
    if (params.patientName != null) __params = __params.set('patientName', params.patientName.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.isAscendingOrder != null) __params = __params.set('isAscendingOrder', params.isAscendingOrder.toString());
    if (params.insuranceId != null) __params = __params.set('insuranceId', params.insuranceId.toString());
    if (params.includeRemittanceAmount != null) __params = __params.set('includeRemittanceAmount', params.includeRemittanceAmount.toString());
    if (params.includeInsurance != null) __params = __params.set('includeInsurance', params.includeInsurance.toString());
    if (params.followUpOnly != null) __params = __params.set('followUpOnly', params.followUpOnly.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.claimOrder != null) __params = __params.set('claimOrder', params.claimOrder.toString());
    if (params.claimNumber != null) __params = __params.set('claimNumber', params.claimNumber.toString());
    if (params.caseId != null) __params = __params.set('caseId', params.caseId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Claims`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseICollectionClaim>;
      })
    );
  }
  /**
   * Get the claims.
   * @param params The `ClaimsService.GetClaimsParams` containing the following parameters:
   *
   * - `status`: The status.
   *
   * - `policyNumber`: the policy number to filter.
   *
   * - `patientName`: The patient name.
   *
   * - `patientId`: The patient.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * - `insuranceId`: The insurance id.
   *
   * - `includeRemittanceAmount`: The include Remittance Amount.
   *
   * - `includeInsurance`: Value indicate include insurance information.
   *
   * - `followUpOnly`: Value to filter flagged charges only.
   *
   * - `dateTo`: The date to.
   *
   * - `dateFrom`: The date from.
   *
   * - `claimOrder`: The claim Order.
   *
   * - `claimNumber`: The claim Number.
   *
   * - `caseId`: The case id.
   *
   * @return Success
   */
  GetClaims(params: ClaimsService.GetClaimsParams): __Observable<ApiPagedResponseICollectionClaim> {
    return this.GetClaimsResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseICollectionClaim)
    );
  }

  /**
   * Finds a duplicated claim.
   * @param claimCreateRequest The claim for create.
   * @return Success
   */
  GetDuplicatedClaimsResponse(claimCreateRequest?: ClaimCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseIListGetDuplicatedClaimsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = claimCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Claims/Duplicates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIListGetDuplicatedClaimsResponse>;
      })
    );
  }
  /**
   * Finds a duplicated claim.
   * @param claimCreateRequest The claim for create.
   * @return Success
   */
  GetDuplicatedClaims(claimCreateRequest?: ClaimCreateUpdateRequest): __Observable<ApiResponseIListGetDuplicatedClaimsResponse> {
    return this.GetDuplicatedClaimsResponse(claimCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseIListGetDuplicatedClaimsResponse)
    );
  }

  /**
   * Creates a secondary claim.
   * @param originalClaimId The original claim id.
   * @return Success
   */
  CreateSecondaryClaimResponse(originalClaimId: number): __Observable<__StrictHttpResponse<ApiResponseClaimExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(originalClaimId))}/Secondary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseClaimExtendedInfo>;
      })
    );
  }
  /**
   * Creates a secondary claim.
   * @param originalClaimId The original claim id.
   * @return Success
   */
  CreateSecondaryClaim(originalClaimId: number): __Observable<ApiResponseClaimExtendedInfo> {
    return this.CreateSecondaryClaimResponse(originalClaimId).pipe(
      __map(_r => _r.body as ApiResponseClaimExtendedInfo)
    );
  }

  /**
   * Update a claim.
   * @param params The `ClaimsService.UpdateClaimParams` containing the following parameters:
   *
   * - `claimId`: The claim Id.
   *
   * - `claimUpdateRequest`: The claim Update Request.
   *
   * @return Success
   */
  UpdateClaimResponse(params: ClaimsService.UpdateClaimParams): __Observable<__StrictHttpResponse<ApiResponseClaimExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.claimUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(params.claimId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseClaimExtendedInfo>;
      })
    );
  }
  /**
   * Update a claim.
   * @param params The `ClaimsService.UpdateClaimParams` containing the following parameters:
   *
   * - `claimId`: The claim Id.
   *
   * - `claimUpdateRequest`: The claim Update Request.
   *
   * @return Success
   */
  UpdateClaim(params: ClaimsService.UpdateClaimParams): __Observable<ApiResponseClaimExtendedInfo> {
    return this.UpdateClaimResponse(params).pipe(
      __map(_r => _r.body as ApiResponseClaimExtendedInfo)
    );
  }

  /**
   * Get the claim by id.
   * @param claimId The claim id.
   * @return Success
   */
  GetClaimByIdResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseClaimExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseClaimExtendedInfo>;
      })
    );
  }
  /**
   * Get the claim by id.
   * @param claimId The claim id.
   * @return Success
   */
  GetClaimById(claimId: number): __Observable<ApiResponseClaimExtendedInfo> {
    return this.GetClaimByIdResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseClaimExtendedInfo)
    );
  }

  /**
   * Deletes a claim.
   * @param claimId The claim identifier.
   * @return Success
   */
  DeleteClaimResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a claim.
   * @param claimId The claim identifier.
   * @return Success
   */
  DeleteClaim(claimId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteClaimResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Submit the claim.
   * @param params The `ClaimsService.UpdateStatusClaimParams` containing the following parameters:
   *
   * - `claimId`: The claim id.
   *
   * - `claimStatusRequest`: The submitted request.
   *
   * @return Success
   */
  UpdateStatusClaimResponse(params: ClaimsService.UpdateStatusClaimParams): __Observable<__StrictHttpResponse<ApiResponseClaimWithError>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.claimStatusRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(params.claimId))}/Status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseClaimWithError>;
      })
    );
  }
  /**
   * Submit the claim.
   * @param params The `ClaimsService.UpdateStatusClaimParams` containing the following parameters:
   *
   * - `claimId`: The claim id.
   *
   * - `claimStatusRequest`: The submitted request.
   *
   * @return Success
   */
  UpdateStatusClaim(params: ClaimsService.UpdateStatusClaimParams): __Observable<ApiResponseClaimWithError> {
    return this.UpdateStatusClaimResponse(params).pipe(
      __map(_r => _r.body as ApiResponseClaimWithError)
    );
  }

  /**
   * Export claim to status.
   * @param claimId The claim id.
   * @return Success
   */
  ExportedClaimResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}/Exported`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Export claim to status.
   * @param claimId The claim id.
   * @return Success
   */
  ExportedClaim(claimId: number): __Observable<ApiResponseOkResponse> {
    return this.ExportedClaimResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the claim revisions.
   * @param claimId The claim id.
   * @return Success
   */
  GetClaimRevisionsResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseICollectionClaimRevision>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}/Revisions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionClaimRevision>;
      })
    );
  }
  /**
   * Get the claim revisions.
   * @param claimId The claim id.
   * @return Success
   */
  GetClaimRevisions(claimId: number): __Observable<ApiResponseICollectionClaimRevision> {
    return this.GetClaimRevisionsResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseICollectionClaimRevision)
    );
  }

  /**
   * Get the claim revision by id.
   * @param params The `ClaimsService.GetClaimRevisionByIdParams` containing the following parameters:
   *
   * - `revisionId`: The revision id.
   *
   * - `claimId`: The claim id.
   *
   * @return Success
   */
  GetClaimRevisionByIdResponse(params: ClaimsService.GetClaimRevisionByIdParams): __Observable<__StrictHttpResponse<ApiResponseClaimExtendedRevision>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(params.claimId))}/Revisions/${encodeURIComponent(String(params.revisionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseClaimExtendedRevision>;
      })
    );
  }
  /**
   * Get the claim revision by id.
   * @param params The `ClaimsService.GetClaimRevisionByIdParams` containing the following parameters:
   *
   * - `revisionId`: The revision id.
   *
   * - `claimId`: The claim id.
   *
   * @return Success
   */
  GetClaimRevisionById(params: ClaimsService.GetClaimRevisionByIdParams): __Observable<ApiResponseClaimExtendedRevision> {
    return this.GetClaimRevisionByIdResponse(params).pipe(
      __map(_r => _r.body as ApiResponseClaimExtendedRevision)
    );
  }

  /**
   * Validates the submission of a claim.
   * @param claimId The claim Id.
   * @return Success
   */
  GetFormValidationResponse(claimId?: number): __Observable<__StrictHttpResponse<ApiResponseIListString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (claimId != null) __params = __params.set('claimId', claimId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Claims/FormValidation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIListString>;
      })
    );
  }
  /**
   * Validates the submission of a claim.
   * @param claimId The claim Id.
   * @return Success
   */
  GetFormValidation(claimId?: number): __Observable<ApiResponseIListString> {
    return this.GetFormValidationResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseIListString)
    );
  }

  /**
   * Get resubmission qualifier codes.
   * @return Success
   */
  GetIncidentQualifierCodesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableResubmissionQualifierCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Claims/ResubmissionQualifierCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableResubmissionQualifierCode>;
      })
    );
  }
  /**
   * Get resubmission qualifier codes.
   * @return Success
   */
  GetIncidentQualifierCodes(): __Observable<ApiResponseIEnumerableResubmissionQualifierCode> {
    return this.GetIncidentQualifierCodesResponse().pipe(
      __map(_r => _r.body as ApiResponseIEnumerableResubmissionQualifierCode)
    );
  }

  /**
   * Validates if a claim can be deleted.
   * @param claimId The claim identifier.
   * @return Success
   */
  DeleteClaimValidationResponse(claimId: number): __Observable<__StrictHttpResponse<ApiResponseListString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(claimId))}/Validation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseListString>;
      })
    );
  }
  /**
   * Validates if a claim can be deleted.
   * @param claimId The claim identifier.
   * @return Success
   */
  DeleteClaimValidation(claimId: number): __Observable<ApiResponseListString> {
    return this.DeleteClaimValidationResponse(claimId).pipe(
      __map(_r => _r.body as ApiResponseListString)
    );
  }

  /**
   * Updates the invoice legal text based on a claim
   * @param params The `ClaimsService.UpdateInvoiceLegalTextParams` containing the following parameters:
   *
   * - `claimId`: the claim identifier.
   *
   * - `invoiceLegalTextId`: the legal text identifier.
   *
   * @return Success
   */
  UpdateInvoiceLegalTextResponse(params: ClaimsService.UpdateInvoiceLegalTextParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.invoiceLegalTextId != null) __params = __params.set('invoiceLegalTextId', params.invoiceLegalTextId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Claims/${encodeURIComponent(String(params.claimId))}/InvoiceLegalText`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates the invoice legal text based on a claim
   * @param params The `ClaimsService.UpdateInvoiceLegalTextParams` containing the following parameters:
   *
   * - `claimId`: the claim identifier.
   *
   * - `invoiceLegalTextId`: the legal text identifier.
   *
   * @return Success
   */
  UpdateInvoiceLegalText(params: ClaimsService.UpdateInvoiceLegalTextParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateInvoiceLegalTextResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module ClaimsService {

  /**
   * Parameters for GetClaims
   */
  export interface GetClaimsParams {

    /**
     * The status.
     */
    status?: 'Active' | 'Closed' | 'Submitted' | 'Rejected' | 'Approved' | 'Deleted' | 'Draft' | 'Queued' | 'Transmitted' | 'Exported' | 'Denied' | 'PaidPatientDue' | 'PaymentReceivedNeedsReview' | 'PaymentReceivedAppealing' | 'PaymentReceivedReadyForSecondary' | 'PaymentReceivedSecondaryCreated' | 'ClosedSecondaryCreated' | 'TransmittingFailed';

    /**
     * the policy number to filter.
     */
    policyNumber?: string;

    /**
     * The patient name.
     */
    patientName?: string;

    /**
     * The patient.
     */
    patientId?: number;

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * The is Ascending Order.
     */
    isAscendingOrder?: boolean;

    /**
     * The insurance id.
     */
    insuranceId?: number;

    /**
     * The include Remittance Amount.
     */
    includeRemittanceAmount?: boolean;

    /**
     * Value indicate include insurance information.
     */
    includeInsurance?: boolean;

    /**
     * Value to filter flagged charges only.
     */
    followUpOnly?: boolean;

    /**
     * The date to.
     */
    dateTo?: string;

    /**
     * The date from.
     */
    dateFrom?: string;

    /**
     * The claim Order.
     */
    claimOrder?: 'None' | 'DateOfService' | 'PatientName' | 'InsuranceCompanyName' | 'ClaimAmount' | 'RemainingBalance' | 'AgingBracket';

    /**
     * The claim Number.
     */
    claimNumber?: string;

    /**
     * The case id.
     */
    caseId?: number;
  }

  /**
   * Parameters for UpdateClaim
   */
  export interface UpdateClaimParams {

    /**
     * The claim Id.
     */
    claimId: number;

    /**
     * The claim Update Request.
     */
    claimUpdateRequest?: ClaimCreateUpdateRequest;
  }

  /**
   * Parameters for UpdateStatusClaim
   */
  export interface UpdateStatusClaimParams {

    /**
     * The claim id.
     */
    claimId: number;

    /**
     * The submitted request.
     */
    claimStatusRequest?: ClaimStatusRequest;
  }

  /**
   * Parameters for GetClaimRevisionById
   */
  export interface GetClaimRevisionByIdParams {

    /**
     * The revision id.
     */
    revisionId: number;

    /**
     * The claim id.
     */
    claimId: number;
  }

  /**
   * Parameters for UpdateInvoiceLegalText
   */
  export interface UpdateInvoiceLegalTextParams {

    /**
     * the claim identifier.
     */
    claimId: number;

    /**
     * the legal text identifier.
     */
    invoiceLegalTextId?: number;
  }
}

export { ClaimsService }
