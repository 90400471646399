/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIReadOnlyCollectionLocation } from '../models/api-response-iread-only-collection-location';
import { ApiResponseLocation } from '../models/api-response-location';
import { LocationModifyRequest } from '../models/location-modify-request';
import { LocationCreateRequest } from '../models/location-create-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseIReadOnlyCollectionTenantBranchLocation } from '../models/api-response-iread-only-collection-tenant-branch-location';
import { ApiResponseIReadOnlyCollectionLocationTypeItem } from '../models/api-response-iread-only-collection-location-type-item';
@Injectable({
    providedIn: 'root',
})
class LocationsService extends __BaseService {
    static readonly LocationsGetLocationsPath = '/api/Locations';
    static readonly LocationsPutPath = '/api/Locations';
    static readonly LocationsPostPath = '/api/Locations';
    static readonly LocationsGetLocationByIdPath = '/api/Locations/{locationId}';
    static readonly LocationsDeleteLocationByIdPath = '/api/Locations/{locationId}';
    static readonly LocationsGetTenantBranchLocationsPath = '/api/TenantBranchLocations';
    static readonly LocationsGetLocationTypesPath = '/api/Locations/Types';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param includeDeleted if set to true [include deleted].
     * @return OK
     */
    LocationsGetLocationsResponse(includeDeleted?: boolean): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionLocation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (includeDeleted != null) __params = __params.set('includeDeleted', includeDeleted.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Locations`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionLocation>;
            }),
        );
    }
    /**
     * @param includeDeleted if set to true [include deleted].
     * @return OK
     */
    LocationsGetLocations(includeDeleted?: boolean): __Observable<ApiResponseIReadOnlyCollectionLocation> {
        return this.LocationsGetLocationsResponse(includeDeleted).pipe(__map((_r) => _r.body as ApiResponseIReadOnlyCollectionLocation));
    }

    /**
     * @param locationModifyRequest The location Modify Request.
     * @return Location Modified
     */
    LocationsPutResponse(locationModifyRequest: LocationModifyRequest): __Observable<__StrictHttpResponse<ApiResponseLocation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = locationModifyRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Locations`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocation>;
            }),
        );
    }
    /**
     * @param locationModifyRequest The location Modify Request.
     * @return Location Modified
     */
    LocationsPut(locationModifyRequest: LocationModifyRequest): __Observable<ApiResponseLocation> {
        return this.LocationsPutResponse(locationModifyRequest).pipe(__map((_r) => _r.body as ApiResponseLocation));
    }

    /**
     * @param locationCreateRequest The location Create Request.
     * @return Location Created
     */
    LocationsPostResponse(locationCreateRequest: LocationCreateRequest): __Observable<__StrictHttpResponse<ApiResponseLocation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = locationCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Locations`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocation>;
            }),
        );
    }
    /**
     * @param locationCreateRequest The location Create Request.
     * @return Location Created
     */
    LocationsPost(locationCreateRequest: LocationCreateRequest): __Observable<ApiResponseLocation> {
        return this.LocationsPostResponse(locationCreateRequest).pipe(__map((_r) => _r.body as ApiResponseLocation));
    }

    /**
     * @param locationId The location Id.
     * @return OK
     */
    LocationsGetLocationByIdResponse(locationId: number): __Observable<__StrictHttpResponse<ApiResponseLocation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Locations/${locationId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocation>;
            }),
        );
    }
    /**
     * @param locationId The location Id.
     * @return OK
     */
    LocationsGetLocationById(locationId: number): __Observable<ApiResponseLocation> {
        return this.LocationsGetLocationByIdResponse(locationId).pipe(__map((_r) => _r.body as ApiResponseLocation));
    }

    /**
     * @param params The `LocationsService.LocationsDeleteLocationByIdParams` containing the following parameters:
     *
     * - `locationId`: The location identifier.
     *
     * - `modifiedByUserId`: The modified by user identifier.
     *
     * - `hardDelete`: if set to true [hard delete].
     *
     * @return OK
     */
    LocationsDeleteLocationByIdResponse(
        params: LocationsService.LocationsDeleteLocationByIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.modifiedByUserId != null) __params = __params.set('modifiedByUserId', params.modifiedByUserId.toString());
        if (params.hardDelete != null) __params = __params.set('hardDelete', params.hardDelete.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/Locations/${params.locationId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `LocationsService.LocationsDeleteLocationByIdParams` containing the following parameters:
     *
     * - `locationId`: The location identifier.
     *
     * - `modifiedByUserId`: The modified by user identifier.
     *
     * - `hardDelete`: if set to true [hard delete].
     *
     * @return OK
     */
    LocationsDeleteLocationById(params: LocationsService.LocationsDeleteLocationByIdParams): __Observable<ApiResponseOkResponse> {
        return this.LocationsDeleteLocationByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * This is also known as a Location.
     * @param includeDeleted if set to true [include deleted].
     * @return OK
     */
    LocationsGetTenantBranchLocationsResponse(
        includeDeleted?: boolean,
    ): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionTenantBranchLocation>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (includeDeleted != null) __params = __params.set('includeDeleted', includeDeleted.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/TenantBranchLocations`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionTenantBranchLocation>;
            }),
        );
    }
    /**
     * This is also known as a Location.
     * @param includeDeleted if set to true [include deleted].
     * @return OK
     */
    LocationsGetTenantBranchLocations(includeDeleted?: boolean): __Observable<ApiResponseIReadOnlyCollectionTenantBranchLocation> {
        return this.LocationsGetTenantBranchLocationsResponse(includeDeleted).pipe(
            __map((_r) => _r.body as ApiResponseIReadOnlyCollectionTenantBranchLocation),
        );
    }

    /**
     * @return OK
     */
    LocationsGetLocationTypesResponse(): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionLocationTypeItem>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Locations/Types`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionLocationTypeItem>;
            }),
        );
    }
    /**
     * @return OK
     */
    LocationsGetLocationTypes(): __Observable<ApiResponseIReadOnlyCollectionLocationTypeItem> {
        return this.LocationsGetLocationTypesResponse().pipe(__map((_r) => _r.body as ApiResponseIReadOnlyCollectionLocationTypeItem));
    }
}

module LocationsService {
    /**
     * Parameters for LocationsDeleteLocationById
     */
    export interface LocationsDeleteLocationByIdParams {
        /**
         * The location identifier.
         */
        locationId: number;

        /**
         * The modified by user identifier.
         */
        modifiedByUserId?: number;

        /**
         * if set to true [hard delete].
         */
        hardDelete?: boolean;
    }
}

export { LocationsService };
