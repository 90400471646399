import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'booleanPipe',
    standalone: true,
})
export class BooleanPipe implements PipeTransform {
    transform(value: boolean, args?: any): string {
        return value === true ? 'Yes' : 'No';
    }
}
