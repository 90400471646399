/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseItemGroup } from '../models/api-response-item-group';
import { GroupRequest } from '../models/group-request';
import { ApiResponseIEnumerableItemGroup } from '../models/api-response-ienumerable-item-group';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { GroupItemCreateRequest } from '../models/group-item-create-request';
import { GroupItemUpdateRequest } from '../models/group-item-update-request';
@Injectable({
    providedIn: 'root',
})
class ItemGroupsService extends __BaseService {
    static readonly CreateItemGroupPath = '/ItemGroups';
    static readonly GetItemGroupsPath = '/ItemGroups';
    static readonly DeleteItemGroupPath = '/ItemGroups/{itemGroupId}';
    static readonly GetItemGroupByIdPath = '/ItemGroups/{itemGroupId}';
    static readonly UpdateItemGroupPath = '/ItemGroups/{itemGroupId}';
    static readonly CreateGroupItemPath = '/ItemGroups/{itemGroupId}/Items';
    static readonly CreateGroupItemLegacyPath = '/ItemGroups/ItemGroups/{itemGroupId}/Items';
    static readonly UpdateGroupItemPath = '/ItemGroups/{itemGroupId}/Items/{itemGroupDetailId}';
    static readonly DeleteGroupItemItemPath = '/ItemGroups/{itemGroupId}/Items/{itemGroupDetailId}';
    static readonly UpdateGroupItemLegacyPath = '/ItemGroups/ItemGroups/{itemGroupId}/Items/{itemGroupDetailId}';
    static readonly DeleteGroupItemItemLegacyPath = '/ItemGroups/ItemGroups/{itemGroupId}/Items/{itemGroupDetailId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Creates the item group.
     * @param itemGroupCreationRequest The itemGroup creation request.
     * @return Success or Created
     */
    CreateItemGroupResponse(
        itemGroupCreationRequest?: GroupRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = itemGroupCreationRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/ItemGroups`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>;
            }),
        );
    }
    /**
     * Creates the item group.
     * @param itemGroupCreationRequest The itemGroup creation request.
     * @return Success or Created
     */
    CreateItemGroup(itemGroupCreationRequest?: GroupRequest): __Observable<ApiResponseItemGroup | ApiResponseItemGroup> {
        return this.CreateItemGroupResponse(itemGroupCreationRequest).pipe(
            __map((_r) => _r.body as ApiResponseItemGroup | ApiResponseItemGroup),
        );
    }

    /**
     * Gets the list of available item groups.
     * @return Success
     */
    GetItemGroupsResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/ItemGroups`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableItemGroup>;
            }),
        );
    }
    /**
     * Gets the list of available item groups.
     * @return Success
     */
    GetItemGroups(): __Observable<ApiResponseIEnumerableItemGroup> {
        return this.GetItemGroupsResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableItemGroup));
    }

    /**
     * Deletes the item group.
     * @param itemGroupId The itemGroup identifier.
     * @return Success
     */
    DeleteItemGroupResponse(itemGroupId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/ItemGroups/${encodeURIComponent(String(itemGroupId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the item group.
     * @param itemGroupId The itemGroup identifier.
     * @return Success
     */
    DeleteItemGroup(itemGroupId: number): __Observable<ApiResponseOkResponse> {
        return this.DeleteItemGroupResponse(itemGroupId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the item group by identifier.
     * @param itemGroupId The ItemGroup identifier.
     * @return Success
     */
    GetItemGroupByIdResponse(itemGroupId: number): __Observable<__StrictHttpResponse<ApiResponseItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/ItemGroups/${encodeURIComponent(String(itemGroupId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemGroup>;
            }),
        );
    }
    /**
     * Gets the item group by identifier.
     * @param itemGroupId The ItemGroup identifier.
     * @return Success
     */
    GetItemGroupById(itemGroupId: number): __Observable<ApiResponseItemGroup> {
        return this.GetItemGroupByIdResponse(itemGroupId).pipe(__map((_r) => _r.body as ApiResponseItemGroup));
    }

    /**
     * Modifies the item group.
     * @param params The `ItemGroupsService.UpdateItemGroupParams` containing the following parameters:
     *
     * - `itemGroupId`: The ItemGroup identifier.
     *
     * - `itemGroupUpdateRequest`: The ItemGroup update request.
     *
     * @return Success
     */
    UpdateItemGroupResponse(params: ItemGroupsService.UpdateItemGroupParams): __Observable<__StrictHttpResponse<ApiResponseItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.itemGroupUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/ItemGroups/${encodeURIComponent(String(params.itemGroupId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemGroup>;
            }),
        );
    }
    /**
     * Modifies the item group.
     * @param params The `ItemGroupsService.UpdateItemGroupParams` containing the following parameters:
     *
     * - `itemGroupId`: The ItemGroup identifier.
     *
     * - `itemGroupUpdateRequest`: The ItemGroup update request.
     *
     * @return Success
     */
    UpdateItemGroup(params: ItemGroupsService.UpdateItemGroupParams): __Observable<ApiResponseItemGroup> {
        return this.UpdateItemGroupResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemGroup));
    }

    /**
     * Creates the group item.
     * @param params The `ItemGroupsService.CreateGroupItemParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `groupItemCreateRequest`: The group item create request.
     *
     * @return Success or Created
     */
    CreateGroupItemResponse(
        params: ItemGroupsService.CreateGroupItemParams,
    ): __Observable<__StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.groupItemCreateRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/ItemGroups/${encodeURIComponent(String(params.itemGroupId))}/Items`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>;
            }),
        );
    }
    /**
     * Creates the group item.
     * @param params The `ItemGroupsService.CreateGroupItemParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `groupItemCreateRequest`: The group item create request.
     *
     * @return Success or Created
     */
    CreateGroupItem(params: ItemGroupsService.CreateGroupItemParams): __Observable<ApiResponseItemGroup | ApiResponseItemGroup> {
        return this.CreateGroupItemResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemGroup | ApiResponseItemGroup));
    }

    /**
     * Creates the group item (Legacy).
     * @param params The `ItemGroupsService.CreateGroupItemLegacyParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `groupItemCreateRequest`: The group item create request.
     *
     * @return Success or Created
     */
    CreateGroupItemLegacyResponse(
        params: ItemGroupsService.CreateGroupItemLegacyParams,
    ): __Observable<__StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.groupItemCreateRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/ItemGroups/ItemGroups/${encodeURIComponent(String(params.itemGroupId))}/Items`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>;
            }),
        );
    }
    /**
     * Creates the group item (Legacy).
     * @param params The `ItemGroupsService.CreateGroupItemLegacyParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `groupItemCreateRequest`: The group item create request.
     *
     * @return Success or Created
     */
    CreateGroupItemLegacy(
        params: ItemGroupsService.CreateGroupItemLegacyParams,
    ): __Observable<ApiResponseItemGroup | ApiResponseItemGroup> {
        return this.CreateGroupItemLegacyResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemGroup | ApiResponseItemGroup));
    }

    /**
     * Updates the group item.
     * @param params The `ItemGroupsService.UpdateGroupItemParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * - `groupItemUpdateRequest`: The group item update request.
     *
     * @return Success or Created
     */
    UpdateGroupItemResponse(
        params: ItemGroupsService.UpdateGroupItemParams,
    ): __Observable<__StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.groupItemUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/ItemGroups/${encodeURIComponent(String(params.itemGroupId))}/Items/${encodeURIComponent(
                    String(params.itemGroupDetailId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>;
            }),
        );
    }
    /**
     * Updates the group item.
     * @param params The `ItemGroupsService.UpdateGroupItemParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * - `groupItemUpdateRequest`: The group item update request.
     *
     * @return Success or Created
     */
    UpdateGroupItem(params: ItemGroupsService.UpdateGroupItemParams): __Observable<ApiResponseItemGroup | ApiResponseItemGroup> {
        return this.UpdateGroupItemResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemGroup | ApiResponseItemGroup));
    }

    /**
     * Deletes the group item.
     * @param params The `ItemGroupsService.DeleteGroupItemItemParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * @return Success or Created
     */
    DeleteGroupItemItemResponse(
        params: ItemGroupsService.DeleteGroupItemItemParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl +
                `/ItemGroups/${encodeURIComponent(String(params.itemGroupId))}/Items/${encodeURIComponent(
                    String(params.itemGroupDetailId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the group item.
     * @param params The `ItemGroupsService.DeleteGroupItemItemParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * @return Success or Created
     */
    DeleteGroupItemItem(params: ItemGroupsService.DeleteGroupItemItemParams): __Observable<ApiResponseOkResponse | ApiResponseOkResponse> {
        return this.DeleteGroupItemItemResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse | ApiResponseOkResponse));
    }

    /**
     * Updates the group item (Legacy).
     * @param params The `ItemGroupsService.UpdateGroupItemLegacyParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * - `groupItemUpdateRequest`: The group item update request.
     *
     * @return Success or Created
     */
    UpdateGroupItemLegacyResponse(
        params: ItemGroupsService.UpdateGroupItemLegacyParams,
    ): __Observable<__StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.groupItemUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl +
                `/ItemGroups/ItemGroups/${encodeURIComponent(String(params.itemGroupId))}/Items/${encodeURIComponent(
                    String(params.itemGroupDetailId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseItemGroup | ApiResponseItemGroup>;
            }),
        );
    }
    /**
     * Updates the group item (Legacy).
     * @param params The `ItemGroupsService.UpdateGroupItemLegacyParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * - `groupItemUpdateRequest`: The group item update request.
     *
     * @return Success or Created
     */
    UpdateGroupItemLegacy(
        params: ItemGroupsService.UpdateGroupItemLegacyParams,
    ): __Observable<ApiResponseItemGroup | ApiResponseItemGroup> {
        return this.UpdateGroupItemLegacyResponse(params).pipe(__map((_r) => _r.body as ApiResponseItemGroup | ApiResponseItemGroup));
    }

    /**
     * Deletes the group item (Legacy).
     * @param params The `ItemGroupsService.DeleteGroupItemItemLegacyParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * @return Success or Created
     */
    DeleteGroupItemItemLegacyResponse(
        params: ItemGroupsService.DeleteGroupItemItemLegacyParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl +
                `/ItemGroups/ItemGroups/${encodeURIComponent(String(params.itemGroupId))}/Items/${encodeURIComponent(
                    String(params.itemGroupDetailId),
                )}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the group item (Legacy).
     * @param params The `ItemGroupsService.DeleteGroupItemItemLegacyParams` containing the following parameters:
     *
     * - `itemGroupId`: The item group identifier.
     *
     * - `itemGroupDetailId`: The group item identifier.
     *
     * @return Success or Created
     */
    DeleteGroupItemItemLegacy(
        params: ItemGroupsService.DeleteGroupItemItemLegacyParams,
    ): __Observable<ApiResponseOkResponse | ApiResponseOkResponse> {
        return this.DeleteGroupItemItemLegacyResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse | ApiResponseOkResponse));
    }
}

module ItemGroupsService {
    /**
     * Parameters for UpdateItemGroup
     */
    export interface UpdateItemGroupParams {
        /**
         * The ItemGroup identifier.
         */
        itemGroupId: number;

        /**
         * The ItemGroup update request.
         */
        itemGroupUpdateRequest?: GroupRequest;
    }

    /**
     * Parameters for CreateGroupItem
     */
    export interface CreateGroupItemParams {
        /**
         * The item group identifier.
         */
        itemGroupId: number;

        /**
         * The group item create request.
         */
        groupItemCreateRequest?: GroupItemCreateRequest;
    }

    /**
     * Parameters for CreateGroupItemLegacy
     */
    export interface CreateGroupItemLegacyParams {
        /**
         * The item group identifier.
         */
        itemGroupId: number;

        /**
         * The group item create request.
         */
        groupItemCreateRequest?: GroupItemCreateRequest;
    }

    /**
     * Parameters for UpdateGroupItem
     */
    export interface UpdateGroupItemParams {
        /**
         * The item group identifier.
         */
        itemGroupId: number;

        /**
         * The group item identifier.
         */
        itemGroupDetailId: number;

        /**
         * The group item update request.
         */
        groupItemUpdateRequest?: GroupItemUpdateRequest;
    }

    /**
     * Parameters for DeleteGroupItemItem
     */
    export interface DeleteGroupItemItemParams {
        /**
         * The item group identifier.
         */
        itemGroupId: number;

        /**
         * The group item identifier.
         */
        itemGroupDetailId: number;
    }

    /**
     * Parameters for UpdateGroupItemLegacy
     */
    export interface UpdateGroupItemLegacyParams {
        /**
         * The item group identifier.
         */
        itemGroupId: number;

        /**
         * The group item identifier.
         */
        itemGroupDetailId: number;

        /**
         * The group item update request.
         */
        groupItemUpdateRequest?: GroupItemUpdateRequest;
    }

    /**
     * Parameters for DeleteGroupItemItemLegacy
     */
    export interface DeleteGroupItemItemLegacyParams {
        /**
         * The item group identifier.
         */
        itemGroupId: number;

        /**
         * The group item identifier.
         */
        itemGroupDetailId: number;
    }
}

export { ItemGroupsService };
