import { Injectable } from '@angular/core';
import { PatientsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadMaritalStatuses, ReloadMaritalStatuses } from './marital-statuses.actions';
import { IMaritalStatusesStateModel } from './marital-statuses.model';

@State<IMaritalStatusesStateModel>({
    name: 'maritalStatuses',
    defaults: {
        maritalStatuses: [],
        loading: false,
    },
})
@Injectable()
export class MaritalStatusesState {
    constructor(private patientService: PatientsService) {}

    @Action(LoadMaritalStatuses)
    public load(context: StateContext<IMaritalStatusesStateModel>, { refresh }: LoadMaritalStatuses) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.maritalStatuses.length) || refresh) {
            context.patchState({ loading: true });
            this.patientService
                .GetMaritalStatuses()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result = [] }) => {
                    context.patchState({
                        maritalStatuses: [...result],
                    });
                });
        }
    }

    @Action(ReloadMaritalStatuses)
    public reload(context: StateContext<IMaritalStatusesStateModel>) {
        context.dispatch(new LoadMaritalStatuses(true));
    }
}
