/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableDiscountReasonCode } from '../models/api-response-ienumerable-discount-reason-code';
import { ApiResponseIEnumerableDiscountReasonGroup } from '../models/api-response-ienumerable-discount-reason-group';
@Injectable({
  providedIn: 'root',
})
class DiscountReasonsService extends __BaseService {
  static readonly GetDiscountReasonCodesPath = '/Insurance/DiscountReasons/DiscountReasonCodes';
  static readonly GetDiscountReasonGroupsPath = '/Insurance/DiscountReasons/DiscountReasonGroups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get discount reason codes.
   * @param searchValue The search value.
   * @return Success
   */
  GetDiscountReasonCodesResponse(searchValue?: string): __Observable<__StrictHttpResponse<ApiResponseIEnumerableDiscountReasonCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (searchValue != null) __params = __params.set('searchValue', searchValue.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/DiscountReasons/DiscountReasonCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableDiscountReasonCode>;
      })
    );
  }
  /**
   * Get discount reason codes.
   * @param searchValue The search value.
   * @return Success
   */
  GetDiscountReasonCodes(searchValue?: string): __Observable<ApiResponseIEnumerableDiscountReasonCode> {
    return this.GetDiscountReasonCodesResponse(searchValue).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableDiscountReasonCode)
    );
  }

  /**
   * Get discount reason groups.
   * @param searchValue The search value.
   * @return Success
   */
  GetDiscountReasonGroupsResponse(searchValue?: string): __Observable<__StrictHttpResponse<ApiResponseIEnumerableDiscountReasonGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (searchValue != null) __params = __params.set('searchValue', searchValue.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/DiscountReasons/DiscountReasonGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableDiscountReasonGroup>;
      })
    );
  }
  /**
   * Get discount reason groups.
   * @param searchValue The search value.
   * @return Success
   */
  GetDiscountReasonGroups(searchValue?: string): __Observable<ApiResponseIEnumerableDiscountReasonGroup> {
    return this.GetDiscountReasonGroupsResponse(searchValue).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableDiscountReasonGroup)
    );
  }
}

module DiscountReasonsService {
}

export { DiscountReasonsService }
