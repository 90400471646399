import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class HighlightService {
    private dateFormats = [
        'D-M-YY',
        'M-D-YYYY',
        'MM-DD-YY',
        'MM-DD-YYYY',
        'D/M/YY',
        'M/D/YYYY',
        'MM/DD/YY',
        'MM/DD/YYYY',
        'MMM D, YY',
        'MMM D, YYYY',
        'MMMM D, YY',
        'MMMM D, YYYY',
    ];
    private readonly searchTwoDigitYearInDashFormat = /\-(\d{2})$/gi;
    private readonly searchTwoDigitYearInSlashFormat = /\/(\d{2})$/gi;
    constructor() {}

    public highlightDob(value: string, search: string): string {
        if (!value) {
            return '';
        }
        const dob = moment(value);
        const searchText = search?.replace(/"/g, '');

        if (dob.isValid()) {
            if (searchText) {
                const searchDates = this.parseDate(searchText);

                if (searchDates && searchDates.some((d) => d.format('MM/DD/YYYY') === dob.format('MM/DD/YYYY'))) {
                    return `<span class='highlight'>${dob.format('MMM D, YYYY')}</span>`;
                }
            }

            return dob.format('MMM D, YYYY');
        }

        return value;
    }

    private parseDate(value: string): moment.Moment[] {
        const result = [];
        const replacedValues = this.getFourDigitsYearDates(value);

        replacedValues.forEach((date) => {
            const parsedDate = moment(date, this.dateFormats, false);

            if (parsedDate.isValid()) {
                result.push(parsedDate);
            }
        });
        const parsedDate = moment(value, this.dateFormats, false);

        if (parsedDate.isValid()) {
            result.push(parsedDate);
        }

        return [...this.ambiguousParseDates(value), ...result];
    }

    private getFourDigitsYearDates(value: string): string[] {
        const dashMatch = value.match(this.searchTwoDigitYearInDashFormat);

        if (dashMatch) {
            return [
                value.replace(this.searchTwoDigitYearInDashFormat, '-19$1'),
                value.replace(this.searchTwoDigitYearInDashFormat, '-20$1'),
            ];
        }
        const slashMatch = value.match(this.searchTwoDigitYearInSlashFormat);

        if (slashMatch) {
            return [
                value.replace(this.searchTwoDigitYearInSlashFormat, '/19$1'),
                value.replace(this.searchTwoDigitYearInSlashFormat, '/20$1'),
            ];
        }

        return [value];
    }

    private ambiguousParseDates(value: string): moment.Moment[] {
        const formatedStrings = this.formatToObvious(value);

        if (formatedStrings) {
            return formatedStrings.map((d) => moment(d, ['MM/DD/YYYY', 'MM/DD/YY'])).filter((d) => d.isValid());
        }

        return [];
    }

    private formatToObvious(value: string): string[] | null {
        switch (value.length) {
            case 4: {
                return [value.replace(/(\d{1})(\d{1})(\d{2})/g, '$1/$2/$3'), value.replace(/(\d{1})(\d{1})(\d{2})/g, '$1/$2/19$3')];
            }
            case 5: {
                return [
                    value.replace(/(\d{1})(\d{2})(\d{2})/g, '$1/$2/$3'),
                    value.replace(/(\d{2})(\d{1})(\d{2})/g, '$1/$2/$3'),
                    value.replace(/(\d{1})(\d{2})(\d{2})/g, '$1/$2/19$3'),
                    value.replace(/(\d{2})(\d{1})(\d{2})/g, '$1/$2/19$3'),
                ];
            }
            case 6: {
                return [
                    value.replace(/(\d{2})(\d{2})(\d{2})/g, '$1/$2/$3'),
                    value.replace(/(\d{2})(\d{2})(\d{2})/g, '$1/$2/19$3'),
                    value.replace(/(\d{1})(\d{1})(\d{4})/g, '$1/$2/$3'),
                ];
            }
            case 7: {
                return [value.replace(/(\d{2})(\d{1})(\d{4})/g, '$1/$2/$3'), value.replace(/(\d{2})(\d{1})(\d{4})/g, '$1/$2/$3')];
            }
            case 8: {
                return [value.replace(/(\d{2})(\d{2})(\d{4})/g, '$1/$2/$3')];
            }
            default: {
                return null;
            }
        }
    }
}
