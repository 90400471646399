<!-- Slider main container -->
<div
    [id]="uniqueID"
    class="symplast-swiper-container swiper-container"
    [ngClass]="containerClass"
    [style.background-color]="backgroundColor">
    <div class="symplast-swiper-wrapper swiper-wrapper" [ngClass]="wrapperClass">
        <!-- Slides -->
        <ng-content select="symplast-swiper-slide, [symplast-swiper-slide], [symplastSwiperSlide]"></ng-content>
    </div>

    <div #swiperPaginationElRef class="swiper-pagination"></div>
</div>
