<div class="file-upload-container" *ngIf="fileUploadDetails.length > 0">
    <div class="wrap-collabsible">
        <div class="upload-info-header" [ngClass]="{ active: isActive }">
            {{ headerText }}
            <a class="toggler" (click)="toggle()"></a>
            <a class="close" (click)="close()"><mat-icon>close</mat-icon></a>
        </div>
        <div class="collapsible-content">
            <div class="content-inner">
                <div class="file-upload-list">
                    <div *ngFor="let detail of fileUploadDetails" class="file-info">
                        <div class="info-container">
                            <img [src]="detail.file.src" width="20px" height="20px" />
                            <p class="file-name">{{ detail.file.overridedName || detail.file.file.name }}</p>
                        </div>
                        <div class="status-container">
                            <p class="status-wrapper">
                                <button
                                    class="status"
                                    [ngClass]="{
                                        processing: !detail.isUploadCompleted,
                                        ok: detail.isUploadCompleted && !detail.isError,
                                        failed: detail.isUploadCompleted && detail.isError
                                    }"></button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
