import { InsuranceAccessConfiguration, InsuranceContractType, InsuranceLicenseType } from './insurance-access.model';

/** Preset of access to modules and functions related to insurance. */
export const InsuranceAccessPreset = {
    /** Gives the access only if: not-contracted OR contract-processing */
    NOT_CONTRACTED: {
        contract: [InsuranceContractType.NOT_CONTRACTED, InsuranceContractType.CONTRACT_PROCESSING],
    } as InsuranceAccessConfiguration,
    /** Gives the access only if: not-contracted OR contract-processing OR (contracted and read-only) OR (contracted and full-access) */
    ENABLED: [
        { contract: [InsuranceContractType.NOT_CONTRACTED, InsuranceContractType.CONTRACT_PROCESSING] },
        { contract: InsuranceContractType.CONTRACTED, license: [InsuranceLicenseType.READ_ONLY, InsuranceLicenseType.FULL_ACCESS] },
    ] as InsuranceAccessConfiguration[],
    /** Gives the access only if: (contracted and read-only) OR (contracted and full-access) */
    CONTRACTED_WITH_LICENSE: {
        contract: InsuranceContractType.CONTRACTED,
        license: [InsuranceLicenseType.READ_ONLY, InsuranceLicenseType.FULL_ACCESS],
    } as InsuranceAccessConfiguration,
    /** Gives the access only if: (contracted and read-only) */
    READ_ONLY: { contract: InsuranceContractType.CONTRACTED, license: InsuranceLicenseType.READ_ONLY } as InsuranceAccessConfiguration,
    /** Gives the access only if: (contracted and full-access) */
    FULL_ACCESS: { contract: InsuranceContractType.CONTRACTED, license: InsuranceLicenseType.FULL_ACCESS } as InsuranceAccessConfiguration,
};

export const INSURANCE_LICENSE_MAP: { [key: string]: string } = {
    [InsuranceLicenseType.READ_ONLY]: 'insurance.read-only',
    [InsuranceLicenseType.FULL_ACCESS]: 'insurance.full-access',
};

export const INSURANCE_CONTRACT_MAP: { [key: string]: InsuranceContractType } = {
    NotContracted: InsuranceContractType.NOT_CONTRACTED,
    ContractProcessing: InsuranceContractType.CONTRACT_PROCESSING,
    Contracted: InsuranceContractType.CONTRACTED,
    Legacy: InsuranceContractType.CONTRACTED,
};
