/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIReadOnlyCollectionAppointmentPurpose } from '../models/api-response-iread-only-collection-appointment-purpose';
import { ApiResponseAppointmentPurpose } from '../models/api-response-appointment-purpose';
import { AppointmentPurposeRequest } from '../models/appointment-purpose-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseIReadOnlyCollectionAppointmentPurposeResponse } from '../models/api-response-iread-only-collection-appointment-purpose-response';
import { ApiResponseAppointmentPurposeResponse } from '../models/api-response-appointment-purpose-response';
@Injectable({
  providedIn: 'root',
})
class AppointmentPurposesService extends __BaseService {
  static readonly GetAppointmentPurposesByTypeIdPath = '/Types/{typeId}/Purposes';
  static readonly CreateAppointmentPurposePath = '/AppointmentPurposes';
  static readonly DeleteAppointmentPurposePath = '/AppointmentPurposes';
  static readonly GetAppointmentPurposesPath = '/AppointmentPurposes';
  static readonly GetAppointmentPurposePath = '/AppointmentPurposes/{appointmentPurposesId}';
  static readonly UpdateAppointmentPurposePath = '/AppointmentPurposes/{appointmentPurposesId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get appointment types.
   * @param typeId The type identifier.
   * @return Success
   */
  GetAppointmentPurposesByTypeIdResponse(typeId: number): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPurpose>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Types/${encodeURIComponent(String(typeId))}/Purposes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPurpose>;
      })
    );
  }
  /**
   * The get appointment types.
   * @param typeId The type identifier.
   * @return Success
   */
  GetAppointmentPurposesByTypeId(typeId: number): __Observable<ApiResponseIReadOnlyCollectionAppointmentPurpose> {
    return this.GetAppointmentPurposesByTypeIdResponse(typeId).pipe(
      __map(_r => _r.body as ApiResponseIReadOnlyCollectionAppointmentPurpose)
    );
  }

  /**
   * Creates the appointment purpose.
   * @param appointmentPurposeRequest The add appointment purpose request.
   * @return Created
   */
  CreateAppointmentPurposeResponse(appointmentPurposeRequest?: AppointmentPurposeRequest): __Observable<__StrictHttpResponse<ApiResponseAppointmentPurpose>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = appointmentPurposeRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/AppointmentPurposes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentPurpose>;
      })
    );
  }
  /**
   * Creates the appointment purpose.
   * @param appointmentPurposeRequest The add appointment purpose request.
   * @return Created
   */
  CreateAppointmentPurpose(appointmentPurposeRequest?: AppointmentPurposeRequest): __Observable<ApiResponseAppointmentPurpose> {
    return this.CreateAppointmentPurposeResponse(appointmentPurposeRequest).pipe(
      __map(_r => _r.body as ApiResponseAppointmentPurpose)
    );
  }

  /**
   * Deletes the appointment purpose.
   * @param params The `AppointmentPurposesService.DeleteAppointmentPurposeParams` containing the following parameters:
   *
   * - `appointmentPurposesId`: The appointment purposes identifier.
   *
   * - `hardDelete`: if set to `true` [hard delete].
   *
   * @return Success
   */
  DeleteAppointmentPurposeResponse(params: AppointmentPurposesService.DeleteAppointmentPurposeParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.appointmentPurposesId != null) __params = __params.set('appointmentPurposesId', params.appointmentPurposesId.toString());
    if (params.hardDelete != null) __params = __params.set('hardDelete', params.hardDelete.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/AppointmentPurposes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes the appointment purpose.
   * @param params The `AppointmentPurposesService.DeleteAppointmentPurposeParams` containing the following parameters:
   *
   * - `appointmentPurposesId`: The appointment purposes identifier.
   *
   * - `hardDelete`: if set to `true` [hard delete].
   *
   * @return Success
   */
  DeleteAppointmentPurpose(params: AppointmentPurposesService.DeleteAppointmentPurposeParams): __Observable<ApiResponseOkResponse> {
    return this.DeleteAppointmentPurposeResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets the appointment purposes.
   * @param params The `AppointmentPurposesService.GetAppointmentPurposesParams` containing the following parameters:
   *
   * - `includeDeleted`: if set to `true` [include deleted].
   *
   * - `externalId`: The external identifier.
   *
   * - `appointmentTypeId`: The appointment type identifier.
   *
   * @return Success
   */
  GetAppointmentPurposesResponse(params: AppointmentPurposesService.GetAppointmentPurposesParams): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPurposeResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    if (params.externalId != null) __params = __params.set('externalId', params.externalId.toString());
    if (params.appointmentTypeId != null) __params = __params.set('appointmentTypeId', params.appointmentTypeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentPurposes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentPurposeResponse>;
      })
    );
  }
  /**
   * Gets the appointment purposes.
   * @param params The `AppointmentPurposesService.GetAppointmentPurposesParams` containing the following parameters:
   *
   * - `includeDeleted`: if set to `true` [include deleted].
   *
   * - `externalId`: The external identifier.
   *
   * - `appointmentTypeId`: The appointment type identifier.
   *
   * @return Success
   */
  GetAppointmentPurposes(params: AppointmentPurposesService.GetAppointmentPurposesParams): __Observable<ApiResponseIReadOnlyCollectionAppointmentPurposeResponse> {
    return this.GetAppointmentPurposesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIReadOnlyCollectionAppointmentPurposeResponse)
    );
  }

  /**
   * Gets the appointment purpose.
   * @param appointmentPurposesId The appointment purposes identifier.
   * @return Success
   */
  GetAppointmentPurposeResponse(appointmentPurposesId: number): __Observable<__StrictHttpResponse<ApiResponseAppointmentPurposeResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentPurposes/${encodeURIComponent(String(appointmentPurposesId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentPurposeResponse>;
      })
    );
  }
  /**
   * Gets the appointment purpose.
   * @param appointmentPurposesId The appointment purposes identifier.
   * @return Success
   */
  GetAppointmentPurpose(appointmentPurposesId: number): __Observable<ApiResponseAppointmentPurposeResponse> {
    return this.GetAppointmentPurposeResponse(appointmentPurposesId).pipe(
      __map(_r => _r.body as ApiResponseAppointmentPurposeResponse)
    );
  }

  /**
   * Updates the appointment purpose.
   * @param params The `AppointmentPurposesService.UpdateAppointmentPurposeParams` containing the following parameters:
   *
   * - `appointmentPurposesId`: The appointment purposes identifier.
   *
   * - `updateAppointmentPurposeRequest`: The update appointment purpose request.
   *
   * @return Success
   */
  UpdateAppointmentPurposeResponse(params: AppointmentPurposesService.UpdateAppointmentPurposeParams): __Observable<__StrictHttpResponse<ApiResponseAppointmentPurposeResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateAppointmentPurposeRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/AppointmentPurposes/${encodeURIComponent(String(params.appointmentPurposesId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentPurposeResponse>;
      })
    );
  }
  /**
   * Updates the appointment purpose.
   * @param params The `AppointmentPurposesService.UpdateAppointmentPurposeParams` containing the following parameters:
   *
   * - `appointmentPurposesId`: The appointment purposes identifier.
   *
   * - `updateAppointmentPurposeRequest`: The update appointment purpose request.
   *
   * @return Success
   */
  UpdateAppointmentPurpose(params: AppointmentPurposesService.UpdateAppointmentPurposeParams): __Observable<ApiResponseAppointmentPurposeResponse> {
    return this.UpdateAppointmentPurposeResponse(params).pipe(
      __map(_r => _r.body as ApiResponseAppointmentPurposeResponse)
    );
  }
}

module AppointmentPurposesService {

  /**
   * Parameters for DeleteAppointmentPurpose
   */
  export interface DeleteAppointmentPurposeParams {

    /**
     * The appointment purposes identifier.
     */
    appointmentPurposesId: number;

    /**
     * if set to `true` [hard delete].
     */
    hardDelete?: boolean;
  }

  /**
   * Parameters for GetAppointmentPurposes
   */
  export interface GetAppointmentPurposesParams {

    /**
     * if set to `true` [include deleted].
     */
    includeDeleted?: boolean;

    /**
     * The external identifier.
     */
    externalId?: number;

    /**
     * The appointment type identifier.
     */
    appointmentTypeId?: number;
  }

  /**
   * Parameters for UpdateAppointmentPurpose
   */
  export interface UpdateAppointmentPurposeParams {

    /**
     * The appointment purposes identifier.
     */
    appointmentPurposesId: number;

    /**
     * The update appointment purpose request.
     */
    updateAppointmentPurposeRequest?: AppointmentPurposeRequest;
  }
}

export { AppointmentPurposesService }
