// TODO: (a.vakhrushin) Circular file chain
import { Injectable } from '@angular/core';
import { environment } from 'apps/webportal/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
    private featureFlags: any;

    constructor() {
        this.featureFlags = environment && environment.featureFlags;
    }

    isFeatureFlagEnabled(flag: string) {
        return this.featureFlags && this.featureFlags[flag];
    }
}
