import { Pipe, PipeTransform } from '@angular/core';
import sortBy from 'lodash-es/sortBy';

@Pipe({
    name: 'sortBy',
    standalone: true,
})
export class SortByPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public transform(value: any[], iterator: string | string[] | Function): any {
        return sortBy(value, iterator);
    }
}
