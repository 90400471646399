export function getBrowserInfoFingerprint(): string | null {
    try {
        const { cookieEnabled, deviceMemory, hardwareConcurrency, language, maxTouchPoints, platform, userAgent, vendor } =
            window.navigator as any;

        return JSON.stringify({
            cookieEnabled,
            deviceMemory,
            hardwareConcurrency,
            language,
            maxTouchPoints,
            platform,
            userAgent,
            vendor,
        });
    } catch {
        return null;
    }
}
