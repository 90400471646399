import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';

export class WysiwygTemplateVariablesAddon implements WysiwygAddon {
    public name = 'template-variables';

    public init(config: WysiwygEditorConfiguration): void {
        config.options = {
            ...config.options,
            noneditable_regexp: [...(config.options?.noneditable_regexp || []), new RegExp(/({\w+}){1}/g)],
            format_noneditable_selector: 'span,p.special',
        };
    }
}
