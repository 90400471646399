export class ApplicationConstants {
    public static SnackBarDuration = 2000;
    public static SnackBarErrorDuration = 10000;
    public static DebounceTime = 500;
    public static DefaultCountyId = 7;
    public static SupportEmail = 'support@symplast.com';
    public static PaginationSizes = [10, 20, 50];
    public static PaginationDefaultSize = 20;
    public static MaxFileSize = 100 * 1024 * 1024; // in bytes
    public static MaxImageFileSize = 5 * 1024 * 1024; // in bytes
    public static GlobalMaxSurchargePercentage = 3;
    public static DefaultFilterSetId = 'defaultFilterSetId';
    public static FilterSetIdKey = 'filterSetId';
    public static MaxErrorLength = 1000;
    public static MaxBrowserUrlLength = 2000;
    public static DefaultErrorMessage = 'Something went wrong. Please try again later.';
}
