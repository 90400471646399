import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'munites' })
export class MinutesPipe implements PipeTransform {
    transform(value: number): string {
        if (!value || value <= 0) {
            return '00:00';
        } else {
            return `${Math.floor(value / 60)
                .toString()
                .padStart(2, '0')}:${(value % 60).toString().padStart(2, '0')}`;
        }
    }

    constructor() {}
}
