import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbDirective } from './breadcrumbs.directive';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'symplast-breadcrumbs',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
    @ContentChildren(BreadcrumbDirective) breadcrumbs!: QueryList<BreadcrumbDirective>;
}
