import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFileUploadDetial } from '@symplast/models/media';
import { FileUploadService } from '@symplast/services/media';

@UntilDestroy()
@Component({
    standalone: true,
    imports: [CommonModule, MatIconModule],
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
    fileUploadDetails: IFileUploadDetial[] = [];
    isActive = false;
    private _headerText = '';

    constructor(private fileUploadService: FileUploadService) {}

    public set headerText(text) {
        this._headerText = text;
    }

    public get headerText(): string {
        if (this._headerText) {
            return this._headerText;
        }

        return `Uploading ${this.fileUploadDetails.length} file${this.fileUploadDetails.length > 1 ? 's' : ''}`;
    }

    ngOnInit(): void {
        this.fileUploadService.currentUploadDetails$.pipe(untilDestroyed(this)).subscribe((x) => {
            this.fileUploadDetails = x;
            if (this.isUploadCompleted()) {
                this.updateProgessBarText();
            }
        });
    }

    isUploadCompleted(): boolean {
        return this.fileUploadDetails.length && !this.fileUploadDetails.some((file) => !file.isUploadCompleted);
    }

    updateProgessBarText(): void {
        const sucess = this.fileUploadDetails.filter((file) => !file.isError).length;

        this.headerText = sucess
            ? `${sucess} file${this.fileUploadDetails.length > 1 ? 's' : ''} uploaded successfully`
            : 'No file was uploaded';
    }

    toggle(): void {
        this.isActive = !this.isActive;
    }

    close(): void {
        this.fileUploadService.cancelUpload();
    }
}
