import { Injectable } from '@angular/core';
import { PatientsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadRaces, ReloadRaces } from './races.actions';
import { IRacesStateModel } from './races.model';

@State<IRacesStateModel>({
    name: 'races',
    defaults: {
        races: [],
        loading: false,
    },
})
@Injectable()
export class RacesState {
    constructor(private patientService: PatientsService) {}

    @Action(LoadRaces)
    public load(context: StateContext<IRacesStateModel>, { refresh }: LoadRaces) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.races.length) || refresh) {
            context.patchState({ loading: true });
            this.patientService
                .GetRaces()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result = [] }) => {
                    context.patchState({
                        races: [...result],
                    });
                });
        }
    }

    @Action(ReloadRaces)
    public reload(context: StateContext<IRacesStateModel>) {
        context.dispatch(new LoadRaces(true));
    }
}
