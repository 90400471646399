import { Pipe, PipeTransform } from '@angular/core';

/***
 * Prevent unnecessary change detection by caching the value
 * @param value {any} - value
 * @param handler {any} - method to call
 * @param context {any} - context
 * @example 1 | cache: numToString: this
 */
@Pipe({
    name: 'cache',
    standalone: true,
})
export class CachePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public transform(value: any, handler: (v: any) => any, context?: any): any {
        if (context) {
            return handler.call(context, value);
        }

        return handler(value);
    }
}
