/**
 * Check if a string contains Unicode characters. This is useful for checking if a string can be sent as a GSM 7-bit encoded SMS.
 * @param str The string to check
 * @returns True if the string contains Unicode characters, false otherwise
 */
export function containsUnicode(str: string): boolean {
    // GSM 7-bit character set (excluding special characters)
    // eslint-disable-next-line no-control-regex
    const gsmRegex = /^[\x00-\x7F]*$/;

    // Check if the string matches the GSM character set
    return !gsmRegex.test(str);
}
