/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableFileModel } from '../models/api-response-ienumerable-file-model';
import { ApiResponseFileModel } from '../models/api-response-file-model';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseICollectionFileStatisticModel } from '../models/api-response-icollection-file-statistic-model';
import { PatchFileRequest } from '../models/patch-file-request';
import { FilesRequest } from '../models/files-request';
@Injectable({
    providedIn: 'root',
})
class FilesService extends __BaseService {
    static readonly GetFilesPath = '/api/files';
    static readonly CreateFilePath = '/api/files';
    static readonly DeleteMultipleFilesPath = '/api/files';
    static readonly GetFileStatisticPath = '/api/files/statistics';
    static readonly GetFileByIdPath = '/api/files/{id}';
    static readonly UpdateFilePath = '/api/files/{id}';
    static readonly DeleteFilePath = '/api/files/{id}';
    static readonly GetFileContentPath = '/api/files/{id}/FileContent';
    static readonly RemoveFromAlbumPath = '/api/files/RemoveFromAlbum';
    static readonly InfrastructureDeleteFilePath = '/Tenants/{tenantId}/Files/{id}';
    static readonly InfrastructureGetFilesPath = '/Tenants/{tenantId}/Files';
    static readonly InfrastructureCreateFilePath = '/Tenants/{tenantId}/Files';
    static readonly InfrastructureGetSpecificFilesPath = '/Tenants/{tenantId}/MultipleFiles';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets files matching criteria.
     * @param params The `FilesService.GetFilesParams` containing the following parameters:
     *
     * - `useCache`: The use Cache.
     *
     * - `take`: How many records to take.
     *
     * - `tagFilter`: The tag filter.
     *
     * - `sortOrderDirection`: The sort Order Direction.
     *
     * - `skip`: How many records to skip.
     *
     * - `showHidden`: The show hidden.
     *
     * - `showDeleted`: The show deleted.
     *
     * - `patientId`: The patient identifier.
     *
     * - `includePatientName`: The include Patient Name.
     *
     * - `fileType`: Type of the file.
     *
     * - `fileSortBy`: The file Sort By.
     *
     * - `fileName`: The file name.
     *
     * - `createdTo`: The created to.
     *
     * - `createdFrom`: The created from.
     *
     * - `appointmentId`: The appointment identifier.
     *
     * @return Success
     */
    GetFilesResponse(params: FilesService.GetFilesParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.useCache != null) __params = __params.set('useCache', params.useCache.toString());
        if (params.take != null) __params = __params.set('take', params.take.toString());
        if (params.tagFilter != null) __params = __params.set('tagFilter', params.tagFilter.toString());
        if (params.sortOrderDirection != null) __params = __params.set('sortOrderDirection', params.sortOrderDirection.toString());
        if (params.skip != null) __params = __params.set('skip', params.skip.toString());
        if (params.showHidden != null) __params = __params.set('showHidden', params.showHidden.toString());
        if (params.showDeleted != null) __params = __params.set('showDeleted', params.showDeleted.toString());
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.includePatientName != null) __params = __params.set('includePatientName', params.includePatientName.toString());
        if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
        if (params.fileSortBy != null) __params = __params.set('fileSortBy', params.fileSortBy.toString());
        if (params.fileName != null) __params = __params.set('fileName', params.fileName.toString());
        if (params.createdTo != null) __params = __params.set('createdTo', params.createdTo.toString());
        if (params.createdFrom != null) __params = __params.set('createdFrom', params.createdFrom.toString());
        if (params.appointmentId != null) __params = __params.set('appointmentId', params.appointmentId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableFileModel>;
            }),
        );
    }
    /**
     * Gets files matching criteria.
     * @param params The `FilesService.GetFilesParams` containing the following parameters:
     *
     * - `useCache`: The use Cache.
     *
     * - `take`: How many records to take.
     *
     * - `tagFilter`: The tag filter.
     *
     * - `sortOrderDirection`: The sort Order Direction.
     *
     * - `skip`: How many records to skip.
     *
     * - `showHidden`: The show hidden.
     *
     * - `showDeleted`: The show deleted.
     *
     * - `patientId`: The patient identifier.
     *
     * - `includePatientName`: The include Patient Name.
     *
     * - `fileType`: Type of the file.
     *
     * - `fileSortBy`: The file Sort By.
     *
     * - `fileName`: The file name.
     *
     * - `createdTo`: The created to.
     *
     * - `createdFrom`: The created from.
     *
     * - `appointmentId`: The appointment identifier.
     *
     * @return Success
     */
    GetFiles(params: FilesService.GetFilesParams): __Observable<ApiResponseIEnumerableFileModel> {
        return this.GetFilesResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableFileModel));
    }

    /**
     * Adds a new file.
     * @param params The `FilesService.CreateFileParams` containing the following parameters:
     *
     * - `file`:
     *
     * - `UploadToAlbum`: Gets or sets the upload to album name
     *
     * - `UploadDateTimeUtc`: Gets or sets the upload date time in UTC.
     *
     * - `Tags`: Gets or sets the tags.
     *
     * - `SystemTags`: Gets or sets the system tags.
     *
     * - `SharedWithPatient`: Gets or sets the shared with patient.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OriginalCreatedDateTimeUtc`: Gets or sets the original created date time in UTC.
     *
     * - `HiddenFromCatalog`: Gets or sets a value indicating whether the file will be hidden or not.
     *
     * - `FileNameOverride`: Gets or sets the file name override.
     *
     * - `Description`: Gets or sets the description of the file.
     *
     * - `CreateDateTimeUtcOverride`: Gets or sets the create date of the file.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Created
     */
    CreateFileResponse(params: FilesService.CreateFileParams): __Observable<__StrictHttpResponse<ApiResponseFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;
        if (params.file != null) {
            __formData.append('file', params.file as string | Blob);
        }
        if (params.UploadToAlbum != null) __params = __params.set('UploadToAlbum', params.UploadToAlbum.toString());
        if (params.UploadDateTimeUtc != null) __params = __params.set('UploadDateTimeUtc', params.UploadDateTimeUtc.toString());
        (params.Tags || []).forEach((val) => {
            if (val != null) __params = __params.append('Tags', val.toString());
        });
        (params.SystemTags || []).forEach((val) => {
            if (val != null) __params = __params.append('SystemTags', val.toString());
        });
        if (params.SharedWithPatient != null) __params = __params.set('SharedWithPatient', params.SharedWithPatient.toString());
        if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
        if (params.OriginalCreatedDateTimeUtc != null)
            __params = __params.set('OriginalCreatedDateTimeUtc', params.OriginalCreatedDateTimeUtc.toString());
        if (params.HiddenFromCatalog != null) __params = __params.set('HiddenFromCatalog', params.HiddenFromCatalog.toString());
        if (params.FileNameOverride != null) __params = __params.set('FileNameOverride', params.FileNameOverride.toString());
        if (params.Description != null) __params = __params.set('Description', params.Description.toString());
        if (params.CreateDateTimeUtcOverride != null)
            __params = __params.set('CreateDateTimeUtcOverride', params.CreateDateTimeUtcOverride.toString());
        if (params.AppointmentId != null) __params = __params.set('AppointmentId', params.AppointmentId.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseFileModel>;
            }),
        );
    }
    /**
     * Adds a new file.
     * @param params The `FilesService.CreateFileParams` containing the following parameters:
     *
     * - `file`:
     *
     * - `UploadToAlbum`: Gets or sets the upload to album name
     *
     * - `UploadDateTimeUtc`: Gets or sets the upload date time in UTC.
     *
     * - `Tags`: Gets or sets the tags.
     *
     * - `SystemTags`: Gets or sets the system tags.
     *
     * - `SharedWithPatient`: Gets or sets the shared with patient.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OriginalCreatedDateTimeUtc`: Gets or sets the original created date time in UTC.
     *
     * - `HiddenFromCatalog`: Gets or sets a value indicating whether the file will be hidden or not.
     *
     * - `FileNameOverride`: Gets or sets the file name override.
     *
     * - `Description`: Gets or sets the description of the file.
     *
     * - `CreateDateTimeUtcOverride`: Gets or sets the create date of the file.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Created
     */
    CreateFile(params: FilesService.CreateFileParams): __Observable<ApiResponseFileModel> {
        return this.CreateFileResponse(params).pipe(__map((_r) => _r.body as ApiResponseFileModel));
    }

    /**
     * Deletes files.
     * @param ids The identifiers.
     * @return Success
     */
    DeleteMultipleFilesResponse(ids?: Array<string>): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = ids;
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes files.
     * @param ids The identifiers.
     * @return Success
     */
    DeleteMultipleFiles(ids?: Array<string>): __Observable<ApiResponseOkResponse> {
        return this.DeleteMultipleFilesResponse(ids).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets files statistic matching criteria.
     * @param params The `FilesService.GetFileStatisticParams` containing the following parameters:
     *
     * - `tagFilter`: The tag filter.
     *
     * - `showHidden`: The show hidden.
     *
     * - `showDeleted`: The show deleted.
     *
     * - `patientId`: The patient identifier.
     *
     * - `fileType`: Type of the file.
     *
     * - `createdTo`: The created to.
     *
     * - `createdFrom`: The created from.
     *
     * - `appointmentId`: The appointment identifier.
     *
     * @return Success
     */
    GetFileStatisticResponse(
        params: FilesService.GetFileStatisticParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionFileStatisticModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tagFilter != null) __params = __params.set('tagFilter', params.tagFilter.toString());
        if (params.showHidden != null) __params = __params.set('showHidden', params.showHidden.toString());
        if (params.showDeleted != null) __params = __params.set('showDeleted', params.showDeleted.toString());
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
        if (params.createdTo != null) __params = __params.set('createdTo', params.createdTo.toString());
        if (params.createdFrom != null) __params = __params.set('createdFrom', params.createdFrom.toString());
        if (params.appointmentId != null) __params = __params.set('appointmentId', params.appointmentId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/files/statistics`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionFileStatisticModel>;
            }),
        );
    }
    /**
     * Gets files statistic matching criteria.
     * @param params The `FilesService.GetFileStatisticParams` containing the following parameters:
     *
     * - `tagFilter`: The tag filter.
     *
     * - `showHidden`: The show hidden.
     *
     * - `showDeleted`: The show deleted.
     *
     * - `patientId`: The patient identifier.
     *
     * - `fileType`: Type of the file.
     *
     * - `createdTo`: The created to.
     *
     * - `createdFrom`: The created from.
     *
     * - `appointmentId`: The appointment identifier.
     *
     * @return Success
     */
    GetFileStatistic(params: FilesService.GetFileStatisticParams): __Observable<ApiResponseICollectionFileStatisticModel> {
        return this.GetFileStatisticResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionFileStatisticModel));
    }

    /**
     * Gets single file.
     * @param params The `FilesService.GetFileByIdParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `includeHidden`: if set to `true` [include hidden].
     *
     * - `includeDeleted`: if set to `true` [include deleted].
     *
     * @return Success
     */
    GetFileByIdResponse(params: FilesService.GetFileByIdParams): __Observable<__StrictHttpResponse<ApiResponseFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeHidden != null) __params = __params.set('includeHidden', params.includeHidden.toString());
        if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/files/${encodeURIComponent(String(params.id))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseFileModel>;
            }),
        );
    }
    /**
     * Gets single file.
     * @param params The `FilesService.GetFileByIdParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `includeHidden`: if set to `true` [include hidden].
     *
     * - `includeDeleted`: if set to `true` [include deleted].
     *
     * @return Success
     */
    GetFileById(params: FilesService.GetFileByIdParams): __Observable<ApiResponseFileModel> {
        return this.GetFileByIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseFileModel));
    }

    /**
     * Patches the specified file's metadata
     * @param params The `FilesService.UpdateFileParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The patch request.
     *
     * @return Success
     */
    UpdateFileResponse(params: FilesService.UpdateFileParams): __Observable<__StrictHttpResponse<ApiResponseFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>('PATCH', this.rootUrl + `/api/files/${encodeURIComponent(String(params.id))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseFileModel>;
            }),
        );
    }
    /**
     * Patches the specified file's metadata
     * @param params The `FilesService.UpdateFileParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The patch request.
     *
     * @return Success
     */
    UpdateFile(params: FilesService.UpdateFileParams): __Observable<ApiResponseFileModel> {
        return this.UpdateFileResponse(params).pipe(__map((_r) => _r.body as ApiResponseFileModel));
    }

    /**
     * Deletes file.
     * @param id The identifier.
     * @return Success
     */
    DeleteFileResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/files/${encodeURIComponent(String(id))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes file.
     * @param id The identifier.
     * @return Success
     */
    DeleteFile(id: string): __Observable<ApiResponseOkResponse> {
        return this.DeleteFileResponse(id).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets single file.
     * @param params The `FilesService.GetFileContentParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `includeHidden`: if set to `true` [include hidden].
     *
     * - `includeDeleted`: if set to `true` [include deleted].
     *
     * @return Success
     */
    GetFileContentResponse(params: FilesService.GetFileContentParams): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeHidden != null) __params = __params.set('includeHidden', params.includeHidden.toString());
        if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/files/${encodeURIComponent(String(params.id))}/FileContent`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Gets single file.
     * @param params The `FilesService.GetFileContentParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `includeHidden`: if set to `true` [include hidden].
     *
     * - `includeDeleted`: if set to `true` [include deleted].
     *
     * @return Success
     */
    GetFileContent(params: FilesService.GetFileContentParams): __Observable<Blob> {
        return this.GetFileContentResponse(params).pipe(__map((_r) => _r.body as Blob));
    }

    /**
     * Removes the specified files from a particular album.
     * @param params The `FilesService.RemoveFromAlbumParams` containing the following parameters:
     *
     * - `tag`: The tag.
     *
     * - `ids`: The identifiers.
     *
     * @return Success
     */
    RemoveFromAlbumResponse(params: FilesService.RemoveFromAlbumParams): __Observable<__StrictHttpResponse<ApiResponseFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tag != null) __params = __params.set('tag', params.tag.toString());
        __body = params.ids;
        let req = new HttpRequest<any>('PATCH', this.rootUrl + `/api/files/RemoveFromAlbum`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseFileModel>;
            }),
        );
    }
    /**
     * Removes the specified files from a particular album.
     * @param params The `FilesService.RemoveFromAlbumParams` containing the following parameters:
     *
     * - `tag`: The tag.
     *
     * - `ids`: The identifiers.
     *
     * @return Success
     */
    RemoveFromAlbum(params: FilesService.RemoveFromAlbumParams): __Observable<ApiResponseFileModel> {
        return this.RemoveFromAlbumResponse(params).pipe(__map((_r) => _r.body as ApiResponseFileModel));
    }

    /**
     * Deletes a file.
     * @param params The `FilesService.InfrastructureDeleteFileParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `id`: The file identifier.
     *
     * @return Success
     */
    InfrastructureDeleteFileResponse(
        params: FilesService.InfrastructureDeleteFileParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/Files/${encodeURIComponent(String(params.id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes a file.
     * @param params The `FilesService.InfrastructureDeleteFileParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `id`: The file identifier.
     *
     * @return Success
     */
    InfrastructureDeleteFile(params: FilesService.InfrastructureDeleteFileParams): __Observable<ApiResponseOkResponse> {
        return this.InfrastructureDeleteFileResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets files matching criteria.
     * @param params The `FilesService.InfrastructureGetFilesParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `userId`: The user identifier.
     *
     * - `take`: How many records to take.
     *
     * - `tagFilter`: The tag filter.
     *
     * - `sortOrderDirection`: The sort Order Direction.
     *
     * - `skip`: How many records to skip.
     *
     * - `showHidden`: The show hidden.
     *
     * - `showDeleted`: The show deleted.
     *
     * - `patientId`: The patient identifier.
     *
     * - `fileType`: Type of the file.
     *
     * - `fileSortBy`: The file Sort By.
     *
     * - `createdTo`: The created to.
     *
     * - `createdFrom`: The created from.
     *
     * - `appointmentId`: The appointment identifier.
     *
     * @return Success
     */
    InfrastructureGetFilesResponse(
        params: FilesService.InfrastructureGetFilesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.take != null) __params = __params.set('take', params.take.toString());
        if (params.tagFilter != null) __params = __params.set('tagFilter', params.tagFilter.toString());
        if (params.sortOrderDirection != null) __params = __params.set('sortOrderDirection', params.sortOrderDirection.toString());
        if (params.skip != null) __params = __params.set('skip', params.skip.toString());
        if (params.showHidden != null) __params = __params.set('showHidden', params.showHidden.toString());
        if (params.showDeleted != null) __params = __params.set('showDeleted', params.showDeleted.toString());
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
        if (params.fileSortBy != null) __params = __params.set('fileSortBy', params.fileSortBy.toString());
        if (params.createdTo != null) __params = __params.set('createdTo', params.createdTo.toString());
        if (params.createdFrom != null) __params = __params.set('createdFrom', params.createdFrom.toString());
        if (params.appointmentId != null) __params = __params.set('appointmentId', params.appointmentId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/Files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableFileModel>;
            }),
        );
    }
    /**
     * Gets files matching criteria.
     * @param params The `FilesService.InfrastructureGetFilesParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `userId`: The user identifier.
     *
     * - `take`: How many records to take.
     *
     * - `tagFilter`: The tag filter.
     *
     * - `sortOrderDirection`: The sort Order Direction.
     *
     * - `skip`: How many records to skip.
     *
     * - `showHidden`: The show hidden.
     *
     * - `showDeleted`: The show deleted.
     *
     * - `patientId`: The patient identifier.
     *
     * - `fileType`: Type of the file.
     *
     * - `fileSortBy`: The file Sort By.
     *
     * - `createdTo`: The created to.
     *
     * - `createdFrom`: The created from.
     *
     * - `appointmentId`: The appointment identifier.
     *
     * @return Success
     */
    InfrastructureGetFiles(params: FilesService.InfrastructureGetFilesParams): __Observable<ApiResponseIEnumerableFileModel> {
        return this.InfrastructureGetFilesResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableFileModel));
    }

    /**
     * Adds a new file.
     * @param params The `FilesService.InfrastructureCreateFileParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `file`:
     *
     * - `UserId`: Gets or sets the creator file.
     *
     * - `UploadToAlbum`: Gets or sets the upload to album name
     *
     * - `UploadDateTimeUtc`: Gets or sets the upload date time in UTC.
     *
     * - `Tags`: Gets or sets the tags.
     *
     * - `SystemTags`: Gets or sets the system tags.
     *
     * - `SharedWithPatient`: Gets or sets the shared with patient.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OriginalCreatedDateTimeUtc`: Gets or sets the original created date time in UTC.
     *
     * - `HiddenFromCatalog`: Gets or sets a value indicating whether the file will be hidden or not.
     *
     * - `FileNameOverride`: Gets or sets the file name override.
     *
     * - `Description`: Gets or sets the description of the file.
     *
     * - `CreateDateTimeUtcOverride`: Gets or sets the create date of the file.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Created
     */
    InfrastructureCreateFileResponse(
        params: FilesService.InfrastructureCreateFileParams,
    ): __Observable<__StrictHttpResponse<ApiResponseFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.file != null) {
            __formData.append('file', params.file as string | Blob);
        }
        if (params.UserId != null) __params = __params.set('UserId', params.UserId.toString());
        if (params.UploadToAlbum != null) __params = __params.set('UploadToAlbum', params.UploadToAlbum.toString());
        if (params.UploadDateTimeUtc != null) __params = __params.set('UploadDateTimeUtc', params.UploadDateTimeUtc.toString());
        (params.Tags || []).forEach((val) => {
            if (val != null) __params = __params.append('Tags', val.toString());
        });
        (params.SystemTags || []).forEach((val) => {
            if (val != null) __params = __params.append('SystemTags', val.toString());
        });
        if (params.SharedWithPatient != null) __params = __params.set('SharedWithPatient', params.SharedWithPatient.toString());
        if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
        if (params.OriginalCreatedDateTimeUtc != null)
            __params = __params.set('OriginalCreatedDateTimeUtc', params.OriginalCreatedDateTimeUtc.toString());
        if (params.HiddenFromCatalog != null) __params = __params.set('HiddenFromCatalog', params.HiddenFromCatalog.toString());
        if (params.FileNameOverride != null) __params = __params.set('FileNameOverride', params.FileNameOverride.toString());
        if (params.Description != null) __params = __params.set('Description', params.Description.toString());
        if (params.CreateDateTimeUtcOverride != null)
            __params = __params.set('CreateDateTimeUtcOverride', params.CreateDateTimeUtcOverride.toString());
        if (params.AppointmentId != null) __params = __params.set('AppointmentId', params.AppointmentId.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/Files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseFileModel>;
            }),
        );
    }
    /**
     * Adds a new file.
     * @param params The `FilesService.InfrastructureCreateFileParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `file`:
     *
     * - `UserId`: Gets or sets the creator file.
     *
     * - `UploadToAlbum`: Gets or sets the upload to album name
     *
     * - `UploadDateTimeUtc`: Gets or sets the upload date time in UTC.
     *
     * - `Tags`: Gets or sets the tags.
     *
     * - `SystemTags`: Gets or sets the system tags.
     *
     * - `SharedWithPatient`: Gets or sets the shared with patient.
     *
     * - `PatientId`: Gets or sets the patient identifier.
     *
     * - `OriginalCreatedDateTimeUtc`: Gets or sets the original created date time in UTC.
     *
     * - `HiddenFromCatalog`: Gets or sets a value indicating whether the file will be hidden or not.
     *
     * - `FileNameOverride`: Gets or sets the file name override.
     *
     * - `Description`: Gets or sets the description of the file.
     *
     * - `CreateDateTimeUtcOverride`: Gets or sets the create date of the file.
     *
     * - `AppointmentId`: Gets or sets the appointment identifier.
     *
     * @return Created
     */
    InfrastructureCreateFile(params: FilesService.InfrastructureCreateFileParams): __Observable<ApiResponseFileModel> {
        return this.InfrastructureCreateFileResponse(params).pipe(__map((_r) => _r.body as ApiResponseFileModel));
    }

    /**
     * Gets multiple files by ids.
     * @param params The `FilesService.InfrastructureGetSpecificFilesParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `userId`: The user identifier.
     *
     * - `filesRequest`: The files request.
     *
     * @return Success
     */
    InfrastructureGetSpecificFilesResponse(
        params: FilesService.InfrastructureGetSpecificFilesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        __body = params.filesRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/MultipleFiles`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableFileModel>;
            }),
        );
    }
    /**
     * Gets multiple files by ids.
     * @param params The `FilesService.InfrastructureGetSpecificFilesParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `userId`: The user identifier.
     *
     * - `filesRequest`: The files request.
     *
     * @return Success
     */
    InfrastructureGetSpecificFiles(
        params: FilesService.InfrastructureGetSpecificFilesParams,
    ): __Observable<ApiResponseIEnumerableFileModel> {
        return this.InfrastructureGetSpecificFilesResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableFileModel));
    }
}

module FilesService {
    /**
     * Parameters for GetFiles
     */
    export interface GetFilesParams {
        /**
         * The use Cache.
         */
        useCache?: boolean;

        /**
         * How many records to take.
         */
        take?: number;

        /**
         * The tag filter.
         */
        tagFilter?: string;

        /**
         * The sort Order Direction.
         */
        sortOrderDirection?: 'Ascending' | 'Descending';

        /**
         * How many records to skip.
         */
        skip?: number;

        /**
         * The show hidden.
         */
        showHidden?: boolean;

        /**
         * The show deleted.
         */
        showDeleted?: boolean;

        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * The include Patient Name.
         */
        includePatientName?: boolean;

        /**
         * Type of the file.
         */
        fileType?: 'Unknown' | 'Image' | 'Video' | 'Pdf' | 'Audio';

        /**
         * The file Sort By.
         */
        fileSortBy?: 'AppointmentDate' | 'UploadDate' | 'OriginalCreationDate' | 'LegacyCreationDate';

        /**
         * The file name.
         */
        fileName?: string;

        /**
         * The created to.
         */
        createdTo?: string;

        /**
         * The created from.
         */
        createdFrom?: string;

        /**
         * The appointment identifier.
         */
        appointmentId?: number;
    }

    /**
     * Parameters for CreateFile
     */
    export interface CreateFileParams {
        file?: Blob;

        /**
         * Gets or sets the upload to album name
         */
        UploadToAlbum?: string;

        /**
         * Gets or sets the upload date time in UTC.
         */
        UploadDateTimeUtc?: string;

        /**
         * Gets or sets the tags.
         */
        Tags?: Array<string>;

        /**
         * Gets or sets the system tags.
         */
        SystemTags?: Array<string>;

        /**
         * Gets or sets the shared with patient.
         */
        SharedWithPatient?: boolean;

        /**
         * Gets or sets the patient identifier.
         */
        PatientId?: number;

        /**
         * Gets or sets the original created date time in UTC.
         */
        OriginalCreatedDateTimeUtc?: string;

        /**
         * Gets or sets a value indicating whether the file will be hidden or not.
         */
        HiddenFromCatalog?: boolean;

        /**
         * Gets or sets the file name override.
         */
        FileNameOverride?: string;

        /**
         * Gets or sets the description of the file.
         */
        Description?: string;

        /**
         * Gets or sets the create date of the file.
         */
        CreateDateTimeUtcOverride?: string;

        /**
         * Gets or sets the appointment identifier.
         */
        AppointmentId?: number;
    }

    /**
     * Parameters for GetFileStatistic
     */
    export interface GetFileStatisticParams {
        /**
         * The tag filter.
         */
        tagFilter?: string;

        /**
         * The show hidden.
         */
        showHidden?: boolean;

        /**
         * The show deleted.
         */
        showDeleted?: boolean;

        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Type of the file.
         */
        fileType?: 'Unknown' | 'Image' | 'Video' | 'Pdf' | 'Audio';

        /**
         * The created to.
         */
        createdTo?: string;

        /**
         * The created from.
         */
        createdFrom?: string;

        /**
         * The appointment identifier.
         */
        appointmentId?: number;
    }

    /**
     * Parameters for GetFileById
     */
    export interface GetFileByIdParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * if set to `true` [include hidden].
         */
        includeHidden?: boolean;

        /**
         * if set to `true` [include deleted].
         */
        includeDeleted?: boolean;
    }

    /**
     * Parameters for UpdateFile
     */
    export interface UpdateFileParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The patch request.
         */
        request?: PatchFileRequest;
    }

    /**
     * Parameters for GetFileContent
     */
    export interface GetFileContentParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * if set to `true` [include hidden].
         */
        includeHidden?: boolean;

        /**
         * if set to `true` [include deleted].
         */
        includeDeleted?: boolean;
    }

    /**
     * Parameters for RemoveFromAlbum
     */
    export interface RemoveFromAlbumParams {
        /**
         * The tag.
         */
        tag?: string;

        /**
         * The identifiers.
         */
        ids?: Array<string>;
    }

    /**
     * Parameters for InfrastructureDeleteFile
     */
    export interface InfrastructureDeleteFileParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The file identifier.
         */
        id: string;
    }

    /**
     * Parameters for InfrastructureGetFiles
     */
    export interface InfrastructureGetFilesParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The user identifier.
         */
        userId?: number;

        /**
         * How many records to take.
         */
        take?: number;

        /**
         * The tag filter.
         */
        tagFilter?: string;

        /**
         * The sort Order Direction.
         */
        sortOrderDirection?: 'Ascending' | 'Descending';

        /**
         * How many records to skip.
         */
        skip?: number;

        /**
         * The show hidden.
         */
        showHidden?: boolean;

        /**
         * The show deleted.
         */
        showDeleted?: boolean;

        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * Type of the file.
         */
        fileType?: 'Unknown' | 'Image' | 'Video' | 'Pdf' | 'Audio';

        /**
         * The file Sort By.
         */
        fileSortBy?: 'AppointmentDate' | 'UploadDate' | 'OriginalCreationDate' | 'LegacyCreationDate';

        /**
         * The created to.
         */
        createdTo?: string;

        /**
         * The created from.
         */
        createdFrom?: string;

        /**
         * The appointment identifier.
         */
        appointmentId?: number;
    }

    /**
     * Parameters for InfrastructureCreateFile
     */
    export interface InfrastructureCreateFileParams {
        /**
         * The tenant id.
         */
        tenantId: number;
        file?: Blob;

        /**
         * Gets or sets the creator file.
         */
        UserId?: number;

        /**
         * Gets or sets the upload to album name
         */
        UploadToAlbum?: string;

        /**
         * Gets or sets the upload date time in UTC.
         */
        UploadDateTimeUtc?: string;

        /**
         * Gets or sets the tags.
         */
        Tags?: Array<string>;

        /**
         * Gets or sets the system tags.
         */
        SystemTags?: Array<string>;

        /**
         * Gets or sets the shared with patient.
         */
        SharedWithPatient?: boolean;

        /**
         * Gets or sets the patient identifier.
         */
        PatientId?: number;

        /**
         * Gets or sets the original created date time in UTC.
         */
        OriginalCreatedDateTimeUtc?: string;

        /**
         * Gets or sets a value indicating whether the file will be hidden or not.
         */
        HiddenFromCatalog?: boolean;

        /**
         * Gets or sets the file name override.
         */
        FileNameOverride?: string;

        /**
         * Gets or sets the description of the file.
         */
        Description?: string;

        /**
         * Gets or sets the create date of the file.
         */
        CreateDateTimeUtcOverride?: string;

        /**
         * Gets or sets the appointment identifier.
         */
        AppointmentId?: number;
    }

    /**
     * Parameters for InfrastructureGetSpecificFiles
     */
    export interface InfrastructureGetSpecificFilesParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The user identifier.
         */
        userId?: number;

        /**
         * The files request.
         */
        filesRequest?: FilesRequest;
    }
}

export { FilesService };
