<div class="notifications-container" *ngIf="errorList.length" data-e2e="notifications">
    <ul>
        <li *ngFor="let error of errorList">
            <mat-card class="card">
                <mat-card-content>
                    <div class="content">
                        <button data-e2e="notification__close-button" mat-icon-button (click)="closeNotification(error)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <span class="notification">{{ error }}</span>
                    </div>
                </mat-card-content>
            </mat-card>
        </li>
    </ul>
</div>
