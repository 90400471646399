/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { TieredPricingScheduleCreateRequest } from '../models/tiered-pricing-schedule-create-request';
import { ApiResponseIEnumerableTieredPricingSchedule } from '../models/api-response-ienumerable-tiered-pricing-schedule';
import { TieredPricingScheduleUpdateRequest } from '../models/tiered-pricing-schedule-update-request';
import { ApiResponseTieredPricingSchedule } from '../models/api-response-tiered-pricing-schedule';
@Injectable({
    providedIn: 'root',
})
class TieredPricingSchedulesService extends __BaseService {
    static readonly CreateTieredPricingSchedulePath = '/Items/{itemId}/TieredPricingSchedules';
    static readonly DeleteTieredPricingSchedulesPath = '/Items/{itemId}/TieredPricingSchedules';
    static readonly GetTieredPricingSchedulesPath = '/Items/{itemId}/TieredPricingSchedules';
    static readonly UpdateTieredPricingSchedulePath = '/Items/{itemId}/TieredPricingSchedules';
    static readonly DeleteTieredPricingSchedulePath = '/Items/TieredPricingSchedules/{priceScheduleId}';
    static readonly GetTieredPricingScheduleByIdPath = '/Items/TieredPricingSchedules/{priceScheduleId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Creates the price schedule.
     * @param params The `TieredPricingSchedulesService.CreateTieredPricingScheduleParams` containing the following parameters:
     *
     * - `itemId`: The item identifier.
     *
     * - `priceScheduleCreateRequest`: The price schedule create request.
     *
     * @return Success or Created
     */
    CreateTieredPricingScheduleResponse(
        params: TieredPricingSchedulesService.CreateTieredPricingScheduleParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.priceScheduleCreateRequest;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/TieredPricingSchedules`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Creates the price schedule.
     * @param params The `TieredPricingSchedulesService.CreateTieredPricingScheduleParams` containing the following parameters:
     *
     * - `itemId`: The item identifier.
     *
     * - `priceScheduleCreateRequest`: The price schedule create request.
     *
     * @return Success or Created
     */
    CreateTieredPricingSchedule(
        params: TieredPricingSchedulesService.CreateTieredPricingScheduleParams,
    ): __Observable<ApiResponseOkResponse | ApiResponseOkResponse> {
        return this.CreateTieredPricingScheduleResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseOkResponse | ApiResponseOkResponse),
        );
    }

    /**
     * Deletes the price schedule.
     * @param itemId The item identifier.
     * @return Success
     */
    DeleteTieredPricingSchedulesResponse(itemId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/Items/${encodeURIComponent(String(itemId))}/TieredPricingSchedules`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the price schedule.
     * @param itemId The item identifier.
     * @return Success
     */
    DeleteTieredPricingSchedules(itemId: number): __Observable<ApiResponseOkResponse> {
        return this.DeleteTieredPricingSchedulesResponse(itemId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the price schedules.
     * @param params The `TieredPricingSchedulesService.GetTieredPricingSchedulesParams` containing the following parameters:
     *
     * - `itemId`: The item identifier.
     *
     * - `locationId`: The location identifier.
     *
     * @return Success
     */
    GetTieredPricingSchedulesResponse(
        params: TieredPricingSchedulesService.GetTieredPricingSchedulesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTieredPricingSchedule>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/TieredPricingSchedules`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableTieredPricingSchedule>;
            }),
        );
    }
    /**
     * Gets the price schedules.
     * @param params The `TieredPricingSchedulesService.GetTieredPricingSchedulesParams` containing the following parameters:
     *
     * - `itemId`: The item identifier.
     *
     * - `locationId`: The location identifier.
     *
     * @return Success
     */
    GetTieredPricingSchedules(
        params: TieredPricingSchedulesService.GetTieredPricingSchedulesParams,
    ): __Observable<ApiResponseIEnumerableTieredPricingSchedule> {
        return this.GetTieredPricingSchedulesResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableTieredPricingSchedule));
    }

    /**
     * Modifies the price schedule.
     * @param params The `TieredPricingSchedulesService.UpdateTieredPricingScheduleParams` containing the following parameters:
     *
     * - `itemId`: The PriceSchedule identifier.
     *
     * - `priceScheduleUpdateRequest`: The PriceSchedule update request.
     *
     * @return Success
     */
    UpdateTieredPricingScheduleResponse(
        params: TieredPricingSchedulesService.UpdateTieredPricingScheduleParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.priceScheduleUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Items/${encodeURIComponent(String(params.itemId))}/TieredPricingSchedules`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies the price schedule.
     * @param params The `TieredPricingSchedulesService.UpdateTieredPricingScheduleParams` containing the following parameters:
     *
     * - `itemId`: The PriceSchedule identifier.
     *
     * - `priceScheduleUpdateRequest`: The PriceSchedule update request.
     *
     * @return Success
     */
    UpdateTieredPricingSchedule(
        params: TieredPricingSchedulesService.UpdateTieredPricingScheduleParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdateTieredPricingScheduleResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Deletes the price schedule.
     * @param priceScheduleId The price schedule identifier.
     * @return Success
     */
    DeleteTieredPricingScheduleResponse(priceScheduleId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/Items/TieredPricingSchedules/${encodeURIComponent(String(priceScheduleId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Deletes the price schedule.
     * @param priceScheduleId The price schedule identifier.
     * @return Success
     */
    DeleteTieredPricingSchedule(priceScheduleId: number): __Observable<ApiResponseOkResponse> {
        return this.DeleteTieredPricingScheduleResponse(priceScheduleId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the PriceSchedule asynchronous by identifier.
     * @param priceScheduleId The PriceSchedule identifier.
     * @return Success
     */
    GetTieredPricingScheduleByIdResponse(priceScheduleId: number): __Observable<__StrictHttpResponse<ApiResponseTieredPricingSchedule>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Items/TieredPricingSchedules/${encodeURIComponent(String(priceScheduleId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseTieredPricingSchedule>;
            }),
        );
    }
    /**
     * Gets the PriceSchedule asynchronous by identifier.
     * @param priceScheduleId The PriceSchedule identifier.
     * @return Success
     */
    GetTieredPricingScheduleById(priceScheduleId: number): __Observable<ApiResponseTieredPricingSchedule> {
        return this.GetTieredPricingScheduleByIdResponse(priceScheduleId).pipe(__map((_r) => _r.body as ApiResponseTieredPricingSchedule));
    }
}

module TieredPricingSchedulesService {
    /**
     * Parameters for CreateTieredPricingSchedule
     */
    export interface CreateTieredPricingScheduleParams {
        /**
         * The item identifier.
         */
        itemId: number;

        /**
         * The price schedule create request.
         */
        priceScheduleCreateRequest?: TieredPricingScheduleCreateRequest;
    }

    /**
     * Parameters for GetTieredPricingSchedules
     */
    export interface GetTieredPricingSchedulesParams {
        /**
         * The item identifier.
         */
        itemId: number;

        /**
         * The location identifier.
         */
        locationId?: number;
    }

    /**
     * Parameters for UpdateTieredPricingSchedule
     */
    export interface UpdateTieredPricingScheduleParams {
        /**
         * The PriceSchedule identifier.
         */
        itemId: number;

        /**
         * The PriceSchedule update request.
         */
        priceScheduleUpdateRequest?: TieredPricingScheduleUpdateRequest;
    }
}

export { TieredPricingSchedulesService };
