/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableCalendarBlockShort } from '../models/api-response-ienumerable-calendar-block-short';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { CalendarBlockCreateUpdateRequest } from '../models/calendar-block-create-update-request';
import { ApiResponseCalendarBlock } from '../models/api-response-calendar-block';
@Injectable({
  providedIn: 'root',
})
class CalendarBlocksService extends __BaseService {
  static readonly GetAllPath = '/CalendarBlocks';
  static readonly CreatePath = '/CalendarBlocks';
  static readonly GetPath = '/CalendarBlocks/{id}';
  static readonly UpdatePath = '/CalendarBlocks/{id}';
  static readonly DeleteCalendarBlockPath = '/CalendarBlocks/{id}';
  static readonly SetEnabledPath = '/CalendarBlocks/{id}/Enabled';
  static readonly CreateSkipForCalendarBlockPath = '/CalendarBlocks/{id}/Skip';
  static readonly DeleteSkipForCalendarBlockPath = '/CalendarBlocks/{id}/Skip';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all calendar blocks (short version, for listing)
   * @param providerIds undefined
   * @return Success
   */
  GetAllResponse(providerIds?: Array<number>): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCalendarBlockShort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (providerIds || []).forEach(val => {if (val != null) __params = __params.append('providerIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/CalendarBlocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableCalendarBlockShort>;
      })
    );
  }
  /**
   * Get all calendar blocks (short version, for listing)
   * @param providerIds undefined
   * @return Success
   */
  GetAll(providerIds?: Array<number>): __Observable<ApiResponseIEnumerableCalendarBlockShort> {
    return this.GetAllResponse(providerIds).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableCalendarBlockShort)
    );
  }

  /**
   * Create a calendar block.
   * @param createRequest The create request.
   * @return Success
   */
  CreateResponse(createRequest?: CalendarBlockCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/CalendarBlocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Create a calendar block.
   * @param createRequest The create request.
   * @return Success
   */
  Create(createRequest?: CalendarBlockCreateUpdateRequest): __Observable<ApiResponseOkResponse> {
    return this.CreateResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the specific calendar block
   * @param id The id.
   * @return Success or No Content
   */
  GetResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseCalendarBlock | ApiResponseCalendarBlock>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/CalendarBlocks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCalendarBlock | ApiResponseCalendarBlock>;
      })
    );
  }
  /**
   * Get the specific calendar block
   * @param id The id.
   * @return Success or No Content
   */
  Get(id: string): __Observable<ApiResponseCalendarBlock | ApiResponseCalendarBlock> {
    return this.GetResponse(id).pipe(
      __map(_r => _r.body as ApiResponseCalendarBlock | ApiResponseCalendarBlock)
    );
  }

  /**
   * Update a calendar block.
   * @param params The `CalendarBlocksService.UpdateParams` containing the following parameters:
   *
   * - `id`: The id.
   *
   * - `updateRequest`: The update Request.
   *
   * @return Success
   */
  UpdateResponse(params: CalendarBlocksService.UpdateParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/CalendarBlocks/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update a calendar block.
   * @param params The `CalendarBlocksService.UpdateParams` containing the following parameters:
   *
   * - `id`: The id.
   *
   * - `updateRequest`: The update Request.
   *
   * @return Success
   */
  Update(params: CalendarBlocksService.UpdateParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Deletes a calendar block.
   * @param id The id.
   * @return Success
   */
  DeleteCalendarBlockResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/CalendarBlocks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a calendar block.
   * @param id The id.
   * @return Success
   */
  DeleteCalendarBlock(id: string): __Observable<ApiResponseOkResponse> {
    return this.DeleteCalendarBlockResponse(id).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Changes the calendar block enabled status.
   * @param params The `CalendarBlocksService.SetEnabledParams` containing the following parameters:
   *
   * - `isEnabled`: The is Enabled.
   *
   * - `id`: The id.
   *
   * @return Success or No Content
   */
  SetEnabledResponse(params: CalendarBlocksService.SetEnabledParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/CalendarBlocks/${encodeURIComponent(String(params.id))}/Enabled`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse | ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Changes the calendar block enabled status.
   * @param params The `CalendarBlocksService.SetEnabledParams` containing the following parameters:
   *
   * - `isEnabled`: The is Enabled.
   *
   * - `id`: The id.
   *
   * @return Success or No Content
   */
  SetEnabled(params: CalendarBlocksService.SetEnabledParams): __Observable<ApiResponseOkResponse | ApiResponseOkResponse> {
    return this.SetEnabledResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse | ApiResponseOkResponse)
    );
  }

  /**
   * Creates a skip for a calendar block.
   * @param params The `CalendarBlocksService.CreateSkipForCalendarBlockParams` containing the following parameters:
   *
   * - `skipRule`: The skip Rule.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  CreateSkipForCalendarBlockResponse(params: CalendarBlocksService.CreateSkipForCalendarBlockParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skipRule != null) __params = __params.set('skipRule', params.skipRule.toString());

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/CalendarBlocks/${encodeURIComponent(String(params.id))}/Skip`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Creates a skip for a calendar block.
   * @param params The `CalendarBlocksService.CreateSkipForCalendarBlockParams` containing the following parameters:
   *
   * - `skipRule`: The skip Rule.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  CreateSkipForCalendarBlock(params: CalendarBlocksService.CreateSkipForCalendarBlockParams): __Observable<ApiResponseOkResponse> {
    return this.CreateSkipForCalendarBlockResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Deletes a skip for a calendar block.
   * @param params The `CalendarBlocksService.DeleteSkipForCalendarBlockParams` containing the following parameters:
   *
   * - `skipRule`: The skip Rule.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  DeleteSkipForCalendarBlockResponse(params: CalendarBlocksService.DeleteSkipForCalendarBlockParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skipRule != null) __params = __params.set('skipRule', params.skipRule.toString());

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/CalendarBlocks/${encodeURIComponent(String(params.id))}/Skip`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes a skip for a calendar block.
   * @param params The `CalendarBlocksService.DeleteSkipForCalendarBlockParams` containing the following parameters:
   *
   * - `skipRule`: The skip Rule.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  DeleteSkipForCalendarBlock(params: CalendarBlocksService.DeleteSkipForCalendarBlockParams): __Observable<ApiResponseOkResponse> {
    return this.DeleteSkipForCalendarBlockResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module CalendarBlocksService {

  /**
   * Parameters for Update
   */
  export interface UpdateParams {

    /**
     * The id.
     */
    id: string;

    /**
     * The update Request.
     */
    updateRequest?: CalendarBlockCreateUpdateRequest;
  }

  /**
   * Parameters for SetEnabled
   */
  export interface SetEnabledParams {

    /**
     * The is Enabled.
     */
    isEnabled: boolean;

    /**
     * The id.
     */
    id: string;
  }

  /**
   * Parameters for CreateSkipForCalendarBlock
   */
  export interface CreateSkipForCalendarBlockParams {

    /**
     * The skip Rule.
     */
    skipRule: string;

    /**
     * The id.
     */
    id: string;
  }

  /**
   * Parameters for DeleteSkipForCalendarBlock
   */
  export interface DeleteSkipForCalendarBlockParams {

    /**
     * The skip Rule.
     */
    skipRule: string;

    /**
     * The id.
     */
    id: string;
  }
}

export { CalendarBlocksService }
