import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { TooltipWrapperComponent } from './tooltip-wrapper.component';
import { ConnectedPosition, FlexibleConnectedPositionStrategyOrigin, Overlay } from '@angular/cdk/overlay';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@UntilDestroy()
@Directive({
    selector: '[symplastTooltip]',
    standalone: true,
})
export class TooltipDirective {
    @Input() symplastTooltip: ComponentType<any> | string = '';
    @Input() position: string | null = null;

    private positions = [
        {
            name: 'left-center',
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            panelClass: ['tooltip-container', 'arrow_left', 'arrow_center'],
        },
        {
            name: 'left-bottom',
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'top',
            panelClass: ['tooltip-container', 'arrow_left', 'arrow_start'],
            offsetY: -40,
        },
        {
            name: 'left-top',
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'bottom',
            panelClass: ['tooltip-container', 'arrow_left', 'arrow_end'],
            offsetY: 40,
        },
        {
            name: 'top-left',
            originX: 'center',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetX: -40,
            panelClass: ['tooltip-container', 'arrow_bottom', 'arrow_start'],
        },
        {
            name: 'bottom-left',
            originX: 'center',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetX: -40,
            panelClass: ['tooltip-container', 'arrow_top', 'arrow_start'],
        },
        {
            name: 'bottom-center',
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            panelClass: ['tooltip-container', 'arrow_top', 'arrow_center'],
        },
        {
            name: 'top-center',
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            panelClass: ['tooltip-container', 'arrow_bottom', 'arrow_center'],
        },
    ];

    constructor(private overlay: Overlay, private host: ElementRef, private readonly renderer: Renderer2, private router: Router) {}

    @HostListener('click', ['$event'])
    onClick() {
        if (this.position) {
            this.positions.sort((p1, p2) => {
                return p1.name === this.position ? -1 : p2.name === this.position ? 1 : 0;
            });
        }

        const origin: FlexibleConnectedPositionStrategyOrigin = this.host;
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(origin)
            .withViewportMargin(20)
            .withPositions(this.positions as ConnectedPosition[])
            .withPush(false);

        const overlayRef = this.overlay.create({ positionStrategy: positionStrategy });
        const tooltipWrapper = new ComponentPortal(TooltipWrapperComponent);
        const tooltip = overlayRef.attach(tooltipWrapper);

        if (typeof this.symplastTooltip === 'string') {
            tooltip.instance.content = this.symplastTooltip;
        } else {
            tooltip.instance.portal = new ComponentPortal(this.symplastTooltip);
        }

        this.renderer.setStyle(overlayRef.overlayElement, 'box-sizing', 'unset');
        this.renderer.setStyle(overlayRef.overlayElement, 'max-height', 'unset');

        tooltip.instance.closed.pipe(untilDestroyed(this)).subscribe((_) => overlayRef.detach());

        const element: HTMLElement = <HTMLElement>tooltip.location.nativeElement;

        this.renderer.setStyle(element, 'box-sizing', 'content-box');

        overlayRef
            .outsidePointerEvents()
            .pipe(untilDestroyed(this))
            .subscribe((_) => overlayRef.detach());

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .pipe(untilDestroyed(this))
            .subscribe((_) => {
                overlayRef.detach();
            });
    }
}

export const TOOLTIP_MODULES = [TooltipDirective, TooltipWrapperComponent];
