import { Injectable } from '@angular/core';
import { PatientsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadLanguages, ReloadLanguages } from './languages.actions';
import { ILanguagesStateModel } from './languages.model';

@State<ILanguagesStateModel>({
    name: 'languages',
    defaults: {
        languages: [],
        loading: false,
    },
})
@Injectable()
export class LanguagesState {
    constructor(private patientService: PatientsService) {}

    @Action(LoadLanguages)
    public load(context: StateContext<ILanguagesStateModel>, { refresh }: LoadLanguages) {
        const currentState = context.getState();

        if ((!currentState.loading && !currentState.languages.length) || refresh) {
            context.patchState({ loading: true });
            this.patientService
                .GetLanguages()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe(({ result = [] }) => {
                    context.patchState({
                        languages: [...result],
                    });
                });
        }
    }

    @Action(ReloadLanguages)
    public reload(context: StateContext<ILanguagesStateModel>) {
        context.dispatch(new LoadLanguages(true));
    }
}
