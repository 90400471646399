<div class="header-modal">
    <div>
        <span>Warning!</span>
    </div>
    <div>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
</div>
<div mat-dialog-content>
    <h1>Your session is about to expire.</h1>
    <div>
        <button mat-stroked-button color="warn" mat-dialog-close (click)="logoutService.logout()">Log Out Now</button>
        <button mat-stroked-button color="primary" mat-dialog-close>Stay Connected</button>
    </div>
</div>
