/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableCampaignResponse } from '../models/api-response-ienumerable-campaign-response';
import { ApiResponseCampaignResponse } from '../models/api-response-campaign-response';
import { NewCampaignRequest } from '../models/new-campaign-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { CampaignUpdateRequest } from '../models/campaign-update-request';
import { NewStatusRequest } from '../models/new-status-request';
@Injectable({
    providedIn: 'root',
})
class CampaignsService extends __BaseService {
    static readonly GetAllCampaignsPath = '/campaigns';
    static readonly AddCampaignPath = '/campaigns';
    static readonly GetCampaignByIdPath = '/campaigns/{id}';
    static readonly UpdateCampaignPath = '/campaigns/{id}';
    static readonly DeleteCampaignPath = '/campaigns/{id}';
    static readonly SetCampaignStatusPath = '/campaigns/{id}/status';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param includeArchived if set to {true} [include archived].
     * @return Success
     */
    GetAllCampaignsResponse(includeArchived?: boolean): __Observable<__StrictHttpResponse<ApiResponseIEnumerableCampaignResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (includeArchived != null) __params = __params.set('includeArchived', includeArchived.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/campaigns`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableCampaignResponse>;
            }),
        );
    }
    /**
     * @param includeArchived if set to {true} [include archived].
     * @return Success
     */
    GetAllCampaigns(includeArchived?: boolean): __Observable<ApiResponseIEnumerableCampaignResponse> {
        return this.GetAllCampaignsResponse(includeArchived).pipe(__map((_r) => _r.body as ApiResponseIEnumerableCampaignResponse));
    }

    /**
     * <code> POST api/campaigns </code>
     * @param request The request.
     * @return Success
     */
    AddCampaignResponse(request?: NewCampaignRequest): __Observable<__StrictHttpResponse<ApiResponseCampaignResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/campaigns`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCampaignResponse>;
            }),
        );
    }
    /**
     * <code> POST api/campaigns </code>
     * @param request The request.
     * @return Success
     */
    AddCampaign(request?: NewCampaignRequest): __Observable<ApiResponseCampaignResponse> {
        return this.AddCampaignResponse(request).pipe(__map((_r) => _r.body as ApiResponseCampaignResponse));
    }

    /**
     * <code> GET api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d </code>
     * @param id The identifier.
     * @return Success
     */
    GetCampaignByIdResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseCampaignResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/campaigns/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCampaignResponse>;
            }),
        );
    }
    /**
     * <code> GET api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d </code>
     * @param id The identifier.
     * @return Success
     */
    GetCampaignById(id: string): __Observable<ApiResponseCampaignResponse> {
        return this.GetCampaignByIdResponse(id).pipe(__map((_r) => _r.body as ApiResponseCampaignResponse));
    }

    /**
     * <code>PUT api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d</code>
     * @param params The `CampaignsService.UpdateCampaignParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateCampaignResponse(params: CampaignsService.UpdateCampaignParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/campaigns/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * <code>PUT api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d</code>
     * @param params The `CampaignsService.UpdateCampaignParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateCampaign(params: CampaignsService.UpdateCampaignParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateCampaignResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * <code>DELETE api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d</code>
     * @param params The `CampaignsService.DeleteCampaignParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `hardDelete`: if set to {true} [hard delete].
     *
     * @return Success
     */
    DeleteCampaignResponse(params: CampaignsService.DeleteCampaignParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.hardDelete != null) __params = __params.set('hardDelete', params.hardDelete.toString());
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/campaigns/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * <code>DELETE api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d</code>
     * @param params The `CampaignsService.DeleteCampaignParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `hardDelete`: if set to {true} [hard delete].
     *
     * @return Success
     */
    DeleteCampaign(params: CampaignsService.DeleteCampaignParams): __Observable<ApiResponseOkResponse> {
        return this.DeleteCampaignResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * <code> POST api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/status</code>
     * @param params The `CampaignsService.SetCampaignStatusParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    SetCampaignStatusResponse(
        params: CampaignsService.SetCampaignStatusParams,
    ): __Observable<__StrictHttpResponse<ApiResponseCampaignResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/campaigns/${params.id}/status`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseCampaignResponse>;
            }),
        );
    }
    /**
     * <code> POST api/campaigns/8602c9a6-6fb2-4225-b03d-84f90d20ec7d/status</code>
     * @param params The `CampaignsService.SetCampaignStatusParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    SetCampaignStatus(params: CampaignsService.SetCampaignStatusParams): __Observable<ApiResponseCampaignResponse> {
        return this.SetCampaignStatusResponse(params).pipe(__map((_r) => _r.body as ApiResponseCampaignResponse));
    }
}

module CampaignsService {
    /**
     * Parameters for UpdateCampaign
     */
    export interface UpdateCampaignParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The request.
         */
        request?: CampaignUpdateRequest;
    }

    /**
     * Parameters for DeleteCampaign
     */
    export interface DeleteCampaignParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * if set to {true} [hard delete].
         */
        hardDelete?: boolean;
    }

    /**
     * Parameters for SetCampaignStatus
     */
    export interface SetCampaignStatusParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The request.
         */
        request?: NewStatusRequest;
    }
}

export { CampaignsService };
