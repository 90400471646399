import { AppointmentPurposeSelection } from './appointment-purpose-selection';
import { AppointmentTypeSelection } from './appointment-type-selection';

export class AppointmentTypePurposeSelection {
    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(value: boolean) {
        this._isSelected = value;

        if (this.parentAppointmentType.appointmentTypesPurposes.every((typePurpose) => typePurpose.isSelected)) {
            this.parentAppointmentType.isSelected = true;
            this.parentAppointmentType.isPartiallySelected = false;
        } else if (this.parentAppointmentType.appointmentTypesPurposes.some((typePurpose) => typePurpose.isSelected)) {
            this.parentAppointmentType.isSelected = true;
            this.parentAppointmentType.isPartiallySelected = true;
        } else {
            this.parentAppointmentType.isSelected = false;
            this.parentAppointmentType.isPartiallySelected = false;
        }

        if (this.parentAppointmentPurpose.appointmentTypesPurposes.every((typePurpose) => typePurpose.isSelected)) {
            this.parentAppointmentPurpose.isSelected = true;
            this.parentAppointmentPurpose.isPartiallySelected = false;
        } else if (this.parentAppointmentPurpose.appointmentTypesPurposes.some((typePurpose) => typePurpose.isSelected)) {
            this.parentAppointmentPurpose.isSelected = true;
            this.parentAppointmentPurpose.isPartiallySelected = true;
        } else {
            this.parentAppointmentPurpose.isSelected = false;
            this.parentAppointmentPurpose.isPartiallySelected = false;
        }
    }

    public id: number;
    public description: string;
    public fullServiceName: string;

    public get color(): string {
        return this.parentAppointmentType.color;
    }

    private _isSelected = false;
    public isVisible = true;
    parentAppointmentType: AppointmentTypeSelection;
    parentAppointmentPurpose: AppointmentPurposeSelection;

    constructor(
        id: number,
        description: string,
        fullServiceName: string,
        parentAppointmentType: AppointmentTypeSelection,
        parentAppointmentPurpose: AppointmentPurposeSelection,
        isSelected = false,
    ) {
        this.id = id;
        this.description = description;
        this.parentAppointmentType = parentAppointmentType;
        this.parentAppointmentPurpose = parentAppointmentPurpose;
        this.fullServiceName = fullServiceName;
        this._isSelected = isSelected;

        this.parentAppointmentType.appointmentTypesPurposes.push(this);
        this.parentAppointmentPurpose.appointmentTypesPurposes.push(this);
    }
}
