import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationDataStorage } from '../../core/services/authentication/authentication-data.storage';
import { InactivityDialogService } from './inactivity-dialog.service';
import { fromEvent, merge, Subject, takeUntil, throttleTime } from 'rxjs';
import { LogoutService } from '../../core/services/logout/logout.service';

const IDLE_AFTER_SECONDS = 19 * 60; // 19 minutes
const EXPIRE_SESSION_AFTER_SECONDS = 60; // 1 minute;

/** Service for maintaining an active user session and deleting it when he's inactive */
@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private userActivityChannel: BroadcastChannel;
    private inactivityTimeout: ReturnType<typeof setTimeout>;
    private logoutTimeout: ReturnType<typeof setTimeout>;
    private destroy$ = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly authStorage: AuthenticationDataStorage,
        private inactivityDialog: InactivityDialogService,
        private logoutService: LogoutService,
    ) {}

    public init(): void {
        this.userActivityChannel = new BroadcastChannel('symplast-user-activity-channel');

        merge(fromEvent(this.document, 'click'), fromEvent(this.document, 'keyup'))
            .pipe(throttleTime(500), takeUntil(this.destroy$))
            .subscribe(() => this.invokeCustomInteraction());

        this.userActivityChannel.onmessage = () => this.handleActivity();

        this.invokeCustomInteraction();

        this.setupPageVisibilityCheck();
    }

    public destroy(): void {
        this.destroy$.next();

        clearTimeout(this.logoutTimeout);
        clearTimeout(this.inactivityTimeout);
        this.inactivityDialog.close();

        if (this.userActivityChannel) {
            this.userActivityChannel.close();
            this.userActivityChannel = null;
        }
    }

    /**
     * Method to invoke custom user interaction programmatically
     * and keep user active
     */
    public invokeCustomInteraction(): void {
        // notify other windows about action
        this.userActivityChannel?.postMessage('user interaction');
        this.handleActivity();
    }

    /**
     * The method checks the situation when the user opens the tab after the inactivity time
     * Use case: the user closed his laptop and returned to the page after the idle time
     * This method solves the problem with throttling events like setTimeout by browser
     */
    private setupPageVisibilityCheck(): void {
        fromEvent(this.document, 'visibilitychange')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                const pageVisible = !this.document.hidden;
                const sessionExpired = !this.authStorage.data;

                if (pageVisible && sessionExpired) {
                    this.logout();
                }
            });
    }

    private handleActivity(): void {
        this.authStorage.keepDataAlive();
        this.inactivityDialog.close();
        clearTimeout(this.logoutTimeout);
        this.runInactivityCountdown();
    }

    private handleInactivity(): void {
        this.inactivityDialog.open();
        this.runLogoutCountdown();
    }

    private runInactivityCountdown(): void {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => this.handleInactivity(), IDLE_AFTER_SECONDS * 1000);
    }

    private runLogoutCountdown(): void {
        clearTimeout(this.logoutTimeout);
        this.logoutTimeout = setTimeout(() => this.logout(), EXPIRE_SESSION_AFTER_SECONDS * 1000);
    }

    private logout(): void {
        clearTimeout(this.logoutTimeout);
        clearTimeout(this.inactivityTimeout);
        this.inactivityDialog.close();
        this.logoutService.logout();
    }
}
