/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionAllocatedPaymentOverview } from '../models/api-response-icollection-allocated-payment-overview';
@Injectable({
  providedIn: 'root',
})
class AllocatedPaymentsService extends __BaseService {
  static readonly GetInvoiceAllocatedPaymentsOverviewPath = '/AllocatedPayments/Invoices/AllocatedPaymentsOverview';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the list of allocated payments as overview.
   * @param invoiceIds The comma separated Invoice Ids.
   * @return Success
   */
  GetInvoiceAllocatedPaymentsOverviewResponse(invoiceIds?: string): __Observable<__StrictHttpResponse<ApiResponseICollectionAllocatedPaymentOverview>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (invoiceIds != null) __params = __params.set('invoiceIds', invoiceIds.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AllocatedPayments/Invoices/AllocatedPaymentsOverview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionAllocatedPaymentOverview>;
      })
    );
  }
  /**
   * Gets the list of allocated payments as overview.
   * @param invoiceIds The comma separated Invoice Ids.
   * @return Success
   */
  GetInvoiceAllocatedPaymentsOverview(invoiceIds?: string): __Observable<ApiResponseICollectionAllocatedPaymentOverview> {
    return this.GetInvoiceAllocatedPaymentsOverviewResponse(invoiceIds).pipe(
      __map(_r => _r.body as ApiResponseICollectionAllocatedPaymentOverview)
    );
  }
}

module AllocatedPaymentsService {
}

export { AllocatedPaymentsService }
