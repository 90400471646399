/**
 * Generates a canvas fingerprint by rendering text on a canvas and returning the data URL of the canvas.
 * @returns {string | null} The data URL of the canvas if successful, otherwise null.
 */
export function getCanvasFingerprint(): string | null {
    try {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d') as CanvasRenderingContext2D;
        const text = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~1!2@3#4$5%6^7&8*9(0)-_=+[{]}|;:',<.>/?";

        context.textBaseline = 'top';
        context.font = "14px 'Arial'";
        context.textBaseline = 'alphabetic';
        context.fillStyle = '#f60';
        context.fillRect(125, 1, 62, 20);
        context.fillStyle = '#069';
        context.fillText(text, 2, 15);
        context.fillStyle = 'rgba(102, 204, 0, 0.7)';
        context.fillText(text, 4, 17);

        return canvas.toDataURL();
    } catch {
        return null;
    }
}
