import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutePath } from '../../../app.route-path';
import { AuthenticationRoutePath } from '../../../authentication/routes/authentication.route-path';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthenticationDataStorage } from '../authentication/authentication-data.storage';
import { MobilePhoneConfirmStorage } from '../mobile-phone-confirm.storage';
import { PasswordResetRequiredStorage } from '../password-reset-required.storage';
import { CurrentUserService } from '../current-user.service';
import { NgxsLogoutPluginFacade } from '../../../store/plugins/logout-plugin.facade';

@Injectable({ providedIn: 'root' })
export class LogoutService {
    private userLoggedOutChannel: BroadcastChannel;

    constructor(
        private ngZone: NgZone,
        private router: Router,
        private authenticationStorage: AuthenticationDataStorage,
        private mobilePhoneConfirmStorage: MobilePhoneConfirmStorage,
        private passwordResetRequiredStorage: PasswordResetRequiredStorage,
        private readonly currentUserService: CurrentUserService,
        private readonly logoutPlugin: NgxsLogoutPluginFacade,
        private dialog: MatDialog,
    ) {
        this.userLoggedOutChannel = new BroadcastChannel('symplast-user-logged-out-channel');
        this.userLoggedOutChannel.onmessage = () => this.onLogout();
    }

    public logout(): void {
        this.userLoggedOutChannel.postMessage('Log out');
        this.onLogout();
    }

    private onLogout(): void {
        this.ngZone.run(() => {
            this.authenticationStorage.clearData();
            this.passwordResetRequiredStorage.removePasswordResetRequired();
            this.mobilePhoneConfirmStorage.removePhoneConfirmCanceled();

            this.router.navigate(['/', AppRoutePath.AUTHENTICATION, AuthenticationRoutePath.LOGIN]).then(() => {
                // Remove user after navigating to unauthorized zone
                // to prevent subscriptions change data in authorized zone
                this.currentUserService.removeUser();
                this.logoutPlugin.logout();
                this.dialog.closeAll();
            });
        });
    }
}
