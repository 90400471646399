import { IPatientBalancesStateModel } from './patient-balances.model';

export class LoadPatientBalances {
    public static readonly type = '[Patient Balances] Load';
}

export class ApplyFiltersForPawtientBalances {
    public static readonly type = '[Patient Balances] Apply Filters';
    public constructor(
        public patientSearch: string,
        public ageBracket: IPatientBalancesStateModel['ageBracket'],
        public balanceBracket: string,
        public paymentAgeBracket: IPatientBalancesStateModel['paymentAgeBracket'],
        public insuranceRelated: 'Include' | 'Exclude' | 'Only',
    ) {}
}

export class ApplySortForPatientBalances {
    public static readonly type = '[Patient Balances] Apply Sort';
    public constructor(
        public sortBy: 'PatientName' | 'OpenInvoicesCount' | 'OldestOpenInvoiceDate' | 'MostRecentPaymentDate' | 'TotalBalance',
        public sortDirection?: 'Ascending' | 'Descending',
    ) {}
}

export class ChangePageForPatientBalances {
    public static readonly type = '[Patient Balances] Change Page';
    public constructor(public pageIndex: number, public pageSize: number) {}
}

export class ResetFiltersForPatientBalances {
    public static readonly type = '[Patient Balances] Reset Filter';
}
