import { Injectable } from '@angular/core';
import { AppSettingsSelectors, FeatureFlagsService } from '@symplast/services/shared';
import { combineLatest, map, Observable, of } from 'rxjs';
import { AppRoutePath } from '../app.route-path';
import { Select } from '@ngxs/store';
import { CurrentUserService } from '../core/services/current-user.service';
import { InsuranceAccessService } from '../core/services/insurance-access/insurance-access.service';
import { InsuranceAccessPreset } from '../core/services/insurance-access/insurance-access.consts';

export interface IUserTab {
    name: string;
    iconSrc: string;
    link: string;
    color: string;
    // If not presented, visible by default
    visible$?: Observable<boolean>;
    e2e?: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserTabsService {
    @Select(AppSettingsSelectors.isSymplastFormsEnabled)
    public isSymplastFormsEnabled$!: Observable<boolean>;

    @Select(AppSettingsSelectors.isFinancialsEnabled)
    public isFinancialsEnabled$!: Observable<boolean>;

    public readonly userTabs: IUserTab[] = [
        {
            name: 'Price List',
            iconSrc: '/assets/icons/icon_financial.svg',
            link: AppRoutePath.FINANCIAL + '/Prices',
            color: '#27AE60',
            visible$: this.isFinancialsEnabled$,
            e2e: 'price-list',
        },
        {
            name: 'Dashboard',
            iconSrc: '/assets/icons/icon_dashboard.svg',
            link: AppRoutePath.DASHBOARD,
            color: '#B2B2B2',
            visible$: this.currentUserService.user$.pipe(map((user) => user?.isAdmin)),
            e2e: 'dashboard',
        },
        {
            name: 'Reports & Exports',
            iconSrc: '/assets/icons/icon_Reports.svg',
            link: AppRoutePath.REPORTS,
            color: '#C92B77',
            visible$: this.currentUserService.user$.pipe(map((user) => user?.hasPermission('nwp.reports'))),
            e2e: 'reports-exports',
        },
        {
            name: 'File Inbox',
            iconSrc: '/assets/icons/eFax-inbox-30.svg',
            link: AppRoutePath.FILE_INBOX,
            color: '#00a2ff',
            visible$: this.currentUserService.user$.pipe(map((user) => user?.hasPermission('nwp.file-inbox'))),
            e2e: 'file-inbox',
        },
        {
            name: 'Global Catalog',
            iconSrc: '/assets/icons/icon_media.svg',
            link: AppRoutePath.MEDIA,
            color: '#00AE5B',
            e2e: 'media',
        },
        {
            name: 'Insurance',
            iconSrc: '/assets/icons/icon_claims.svg',
            link: AppRoutePath.INSURANCE,
            color: '#F6AF57',
            visible$: combineLatest([
                this.insuranceAccessService.checkAccess$(InsuranceAccessPreset.ENABLED),
                this.insuranceAccessService.hasInsuranceRelatedRoles$,
            ]).pipe(map(([hasAccess, hasRoles]) => hasAccess && hasRoles)),
            e2e: 'insurance',
        },
        {
            name: 'Patient Balances',
            iconSrc: '/assets/icons/icon_patient-balances.svg',
            link: AppRoutePath.FINANCIAL + '/Balances',
            color: '#EB5757',
            visible$: this.isFinancialsEnabled$,
            e2e: 'patient-balances',
        },
        {
            name: 'Video Visits',
            iconSrc: '/assets/icons/icon-video-visit.svg',
            link: AppRoutePath.VIDEO_VISITS,
            color: '#00A3FF',
            visible$: this.currentUserService.user$.pipe(map((user) => user?.isAdmin)),
            e2e: 'video-visits',
        },
        {
            name: 'Symplast Forms',
            iconSrc: '/assets/icons/symplast-forms.svg',
            link: AppRoutePath.FORMS,
            color: '#8250F6',
            visible$: combineLatest([
                this.isSymplastFormsEnabled$,
                this.currentUserService.user$.pipe(map((user) => user?.isAdmin)),
                of(this.featureFlagsService.isFeatureFlagEnabled('symplast-forms')),
            ]).pipe(map(([isSymplastFormsEnabled, isAdmin, featureEnabled]) => isSymplastFormsEnabled && isAdmin && featureEnabled)),
            e2e: 'symplast-forms',
        },
        {
            name: 'Patients',
            iconSrc: '/assets/icons/search-account.svg',
            link: AppRoutePath.PATIENTS,
            color: '#F4B23E',
            e2e: 'patients',
        },
        {
            name: 'Admin Settings',
            iconSrc: '/assets/icons/icon_Settings.svg',
            link: AppRoutePath.SETTINGS,
            color: '#D1D1D1',
            visible$: this.currentUserService.user$.pipe(map((user) => user?.hasPermissions(['nwp.admin', 'permission-admin']))),
            e2e: 'admin-settings',
        },
    ];

    constructor(
        private readonly currentUserService: CurrentUserService,
        private readonly featureFlagsService: FeatureFlagsService,
        private readonly insuranceAccessService: InsuranceAccessService,
    ) {}
}
