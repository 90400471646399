/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponsePayment } from '../models/api-response-payment';
import { PaymentCreateUpdateRequest } from '../models/payment-create-update-request';
import { ApiResponseIEnumerablePayment } from '../models/api-response-ienumerable-payment';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiDetailedErrorResponseCreatePatientsInsurancePaymentsResponseIEnumerableCreatePatientsInsurancePaymentsException } from '../models/api-detailed-error-response-create-patients-insurance-payments-response-ienumerable-create-patients-insurance-payments-exception';
import { PatientInsurancePaymentCreateRequest } from '../models/patient-insurance-payment-create-request';
@Injectable({
  providedIn: 'root',
})
class PaymentsService extends __BaseService {
  static readonly CreatePaymentPath = '/Insurance/Payments';
  static readonly GetPaymentsPath = '/Insurance/Payments';
  static readonly DeletePaymentPath = '/Insurance/Payments/{paymentId}';
  static readonly UpdatePaymentPath = '/Insurance/Payments/{paymentId}';
  static readonly CreatePatientsInsurancePaymentsPath = '/Insurance/Patients/Payments';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create the payment.
   * @param paymentCreateRequest The payment to create.
   * @return Success
   */
  CreatePaymentResponse(paymentCreateRequest?: PaymentCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponsePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = paymentCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePayment>;
      })
    );
  }
  /**
   * Create the payment.
   * @param paymentCreateRequest The payment to create.
   * @return Success
   */
  CreatePayment(paymentCreateRequest?: PaymentCreateUpdateRequest): __Observable<ApiResponsePayment> {
    return this.CreatePaymentResponse(paymentCreateRequest).pipe(
      __map(_r => _r.body as ApiResponsePayment)
    );
  }

  /**
   * Get payments.
   * @param chargeId The charge id.
   * @return Success
   */
  GetPaymentsResponse(chargeId?: number): __Observable<__StrictHttpResponse<ApiResponseIEnumerablePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (chargeId != null) __params = __params.set('chargeId', chargeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerablePayment>;
      })
    );
  }
  /**
   * Get payments.
   * @param chargeId The charge id.
   * @return Success
   */
  GetPayments(chargeId?: number): __Observable<ApiResponseIEnumerablePayment> {
    return this.GetPaymentsResponse(chargeId).pipe(
      __map(_r => _r.body as ApiResponseIEnumerablePayment)
    );
  }

  /**
   * Delete the payment.
   * @param paymentId The payment id.
   * @return Success
   */
  DeletePaymentResponse(paymentId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Insurance/Payments/${encodeURIComponent(String(paymentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete the payment.
   * @param paymentId The payment id.
   * @return Success
   */
  DeletePayment(paymentId: number): __Observable<ApiResponseOkResponse> {
    return this.DeletePaymentResponse(paymentId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Update the payment.
   * @param params The `PaymentsService.UpdatePaymentParams` containing the following parameters:
   *
   * - `paymentId`: The payment id.
   *
   * - `paymentUpdateRequest`: The payment to update.
   *
   * @return Success
   */
  UpdatePaymentResponse(params: PaymentsService.UpdatePaymentParams): __Observable<__StrictHttpResponse<ApiResponsePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.paymentUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Payments/${encodeURIComponent(String(params.paymentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePayment>;
      })
    );
  }
  /**
   * Update the payment.
   * @param params The `PaymentsService.UpdatePaymentParams` containing the following parameters:
   *
   * - `paymentId`: The payment id.
   *
   * - `paymentUpdateRequest`: The payment to update.
   *
   * @return Success
   */
  UpdatePayment(params: PaymentsService.UpdatePaymentParams): __Observable<ApiResponsePayment> {
    return this.UpdatePaymentResponse(params).pipe(
      __map(_r => _r.body as ApiResponsePayment)
    );
  }

  /**
   * Creates a insurance payment.
   * @param patientsInsurancePaymentsCreateRequest The patients Insurance Payments Create Request.
   * @return Success
   */
  CreatePatientsInsurancePaymentsResponse(patientsInsurancePaymentsCreateRequest?: Array<PatientInsurancePaymentCreateRequest>): __Observable<__StrictHttpResponse<ApiDetailedErrorResponseCreatePatientsInsurancePaymentsResponseIEnumerableCreatePatientsInsurancePaymentsException>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patientsInsurancePaymentsCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Patients/Payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiDetailedErrorResponseCreatePatientsInsurancePaymentsResponseIEnumerableCreatePatientsInsurancePaymentsException>;
      })
    );
  }
  /**
   * Creates a insurance payment.
   * @param patientsInsurancePaymentsCreateRequest The patients Insurance Payments Create Request.
   * @return Success
   */
  CreatePatientsInsurancePayments(patientsInsurancePaymentsCreateRequest?: Array<PatientInsurancePaymentCreateRequest>): __Observable<ApiDetailedErrorResponseCreatePatientsInsurancePaymentsResponseIEnumerableCreatePatientsInsurancePaymentsException> {
    return this.CreatePatientsInsurancePaymentsResponse(patientsInsurancePaymentsCreateRequest).pipe(
      __map(_r => _r.body as ApiDetailedErrorResponseCreatePatientsInsurancePaymentsResponseIEnumerableCreatePatientsInsurancePaymentsException)
    );
  }
}

module PaymentsService {

  /**
   * Parameters for UpdatePayment
   */
  export interface UpdatePaymentParams {

    /**
     * The payment id.
     */
    paymentId: number;

    /**
     * The payment to update.
     */
    paymentUpdateRequest?: PaymentCreateUpdateRequest;
  }
}

export { PaymentsService }
