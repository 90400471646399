export class LoadFinancialItems {
    public static readonly type = '[Financial Items] Load';
    constructor(public refresh?: boolean) {}
}

export class FilterByTextFinancialItems {
    public static readonly type = '[Financial Items] Filter By Text';
    constructor(public text?: string) {}
}

export class ChangeLocationForFinancialItems {
    public static readonly type = '[Financial Items] Change Location';
    constructor(public locationId?: number) {}
}

export class FilterFinancialItems {
    public static readonly type = '[Financial Items] Filter';
    constructor(public brandId?: number, public itemType?: number, public releatedToInsurance?: boolean) {}
}

export class ResetFiltersForFinancialItems {
    public static readonly type = '[Financial Items] Reset Filters';
}
