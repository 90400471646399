import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { CurrentUserService } from '../../core/services/current-user.service';

@Injectable({
    providedIn: 'root',
})
export class RolesGuard implements CanActivate, CanActivateChild {
    constructor(private currentUserService: CurrentUserService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        return this.validateRole(route);
    }
    canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | UrlTree {
        return this.validateRole(childRoute);
    }

    private validateRole(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const role = route.data.role;

        return !role || this.currentUserService.user?.hasRole(role) || this.router.createUrlTree(['/']);
    }
}
