import { Selector } from '@ngxs/store';
import { ILocationTypesStateModel } from './location-types.model';
import { LocationTypesState } from './location-types.state';

export class LocationTypesSelectors {
    @Selector([LocationTypesState])
    public static loading(state: ILocationTypesStateModel): boolean {
        return state.loading;
    }

    @Selector([LocationTypesState])
    public static locationTypes(state: ILocationTypesStateModel): ILocationTypesStateModel['locationTypes'] {
        return state.locationTypes;
    }
}
