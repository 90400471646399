/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableInventoryAdjustment } from '../models/api-response-ienumerable-inventory-adjustment';
import { ApiResponseInventoryAdjustment } from '../models/api-response-inventory-adjustment';
import { InventoryAdjustmentCreateRequest } from '../models/inventory-adjustment-create-request';
@Injectable({
    providedIn: 'root',
})
class InventoryAdjustmentsService extends __BaseService {
    static readonly GetAllInventoryAdjustmentsPath = '/InventoryAdjustments';
    static readonly CreateInventoryAdjustmentPath = '/InventoryAdjustments';
    static readonly GetByIdPath = '/InventoryAdjustments/{inventoryAdjustmentId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets a list of inventory adjustments.
     * @param params The `InventoryAdjustmentsService.GetAllInventoryAdjustmentsParams` containing the following parameters:
     *
     * - `isInternal`: Optional parameter to filter inventory adjustments by flag isInternal.
     *
     * - `Reason`: Gets or sets the reason.
     *
     * - `LocationId`: Gets or sets the location id.
     *
     * - `ItemId`: Gets or sets the item id.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CreatedUserId`: Gets or sets the created user id.
     *
     * @return Success
     */
    GetAllInventoryAdjustmentsResponse(
        params: InventoryAdjustmentsService.GetAllInventoryAdjustmentsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInventoryAdjustment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.isInternal != null) __params = __params.set('isInternal', params.isInternal.toString());
        if (params.Reason != null) __params = __params.set('Reason', params.Reason.toString());
        if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
        if (params.ItemId != null) __params = __params.set('ItemId', params.ItemId.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        if (params.CreatedUserId != null) __params = __params.set('CreatedUserId', params.CreatedUserId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/InventoryAdjustments`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableInventoryAdjustment>;
            }),
        );
    }
    /**
     * Gets a list of inventory adjustments.
     * @param params The `InventoryAdjustmentsService.GetAllInventoryAdjustmentsParams` containing the following parameters:
     *
     * - `isInternal`: Optional parameter to filter inventory adjustments by flag isInternal.
     *
     * - `Reason`: Gets or sets the reason.
     *
     * - `LocationId`: Gets or sets the location id.
     *
     * - `ItemId`: Gets or sets the item id.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CreatedUserId`: Gets or sets the created user id.
     *
     * @return Success
     */
    GetAllInventoryAdjustments(
        params: InventoryAdjustmentsService.GetAllInventoryAdjustmentsParams,
    ): __Observable<ApiResponseIEnumerableInventoryAdjustment> {
        return this.GetAllInventoryAdjustmentsResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableInventoryAdjustment));
    }

    /**
     * Create an inventory adjustment.
     * @param inventoryAdjustment The inventory adjustment.
     * @return Success
     */
    CreateInventoryAdjustmentResponse(
        inventoryAdjustment?: InventoryAdjustmentCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseInventoryAdjustment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = inventoryAdjustment;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/InventoryAdjustments`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInventoryAdjustment>;
            }),
        );
    }
    /**
     * Create an inventory adjustment.
     * @param inventoryAdjustment The inventory adjustment.
     * @return Success
     */
    CreateInventoryAdjustment(inventoryAdjustment?: InventoryAdjustmentCreateRequest): __Observable<ApiResponseInventoryAdjustment> {
        return this.CreateInventoryAdjustmentResponse(inventoryAdjustment).pipe(__map((_r) => _r.body as ApiResponseInventoryAdjustment));
    }

    /**
     * Gets an inventory adjustment based by Id.
     * @param inventoryAdjustmentId The inventory adjustment id.
     * @return Success
     */
    GetByIdResponse(inventoryAdjustmentId: number): __Observable<__StrictHttpResponse<ApiResponseInventoryAdjustment>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/InventoryAdjustments/${encodeURIComponent(String(inventoryAdjustmentId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseInventoryAdjustment>;
            }),
        );
    }
    /**
     * Gets an inventory adjustment based by Id.
     * @param inventoryAdjustmentId The inventory adjustment id.
     * @return Success
     */
    GetById(inventoryAdjustmentId: number): __Observable<ApiResponseInventoryAdjustment> {
        return this.GetByIdResponse(inventoryAdjustmentId).pipe(__map((_r) => _r.body as ApiResponseInventoryAdjustment));
    }
}

module InventoryAdjustmentsService {
    /**
     * Parameters for GetAllInventoryAdjustments
     */
    export interface GetAllInventoryAdjustmentsParams {
        /**
         * Optional parameter to filter inventory adjustments by flag isInternal.
         */
        isInternal?: 'False' | 'True' | 'Null';

        /**
         * Gets or sets the reason.
         */
        Reason?: string;

        /**
         * Gets or sets the location id.
         */
        LocationId?: number;

        /**
         * Gets or sets the item id.
         */
        ItemId?: number;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets the created user id.
         */
        CreatedUserId?: number;
    }
}

export { InventoryAdjustmentsService };
