import { Injectable } from '@angular/core';
import { BaseEnumService } from './base-enum.service';

@Injectable({
    providedIn: 'root',
})
export class GendersEnumService extends BaseEnumService {
    constructor() {
        super();
        this.data = [
            {
                key: '',
                name: 'Any',
            },
            {
                key: 'Female',
                name: 'Female',
            },
            {
                key: 'Male',
                name: 'Male',
            },
            {
                key: 'Other',
                name: 'Other',
            },
        ];
    }
}
