import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PasswordResetRequiredStorage } from '../services/password-reset-required.storage';
import { AppRoutePath } from '../../app.route-path';
import { AdminRoutePath } from '../../admin/admin.route-path';
import { UserManagementEditComponent } from '../../admin/user-management-edit/user-management-edit.component';
import { BrowserService } from '@symplast/utils';

@Injectable({ providedIn: 'root' })
export class PasswordResetRequiredGuard implements CanActivate, CanDeactivate<UserManagementEditComponent> {
    constructor(
        private passwordResetRequiredStorage: PasswordResetRequiredStorage,
        private router: Router,
        private browserService: BrowserService,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const onSettingsProfileUrl = state.url === `/${AppRoutePath.SETTINGS}/${AdminRoutePath.PROFILE}`;
        const resetPasswordRequired = this.passwordResetRequiredStorage.getPasswordResetRequired();
        const isInWebView = this.browserService.IsWebView();

        if (!isInWebView && !onSettingsProfileUrl && resetPasswordRequired) {
            return this.router.createUrlTree(['/', AppRoutePath.SETTINGS, AdminRoutePath.PROFILE]);
        }

        return true;
    }

    canDeactivate(): boolean {
        return !this.passwordResetRequiredStorage.getPasswordResetRequired();
    }
}
