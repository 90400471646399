export enum AppRoutePath {
    DASHBOARD = 'Dashboard',
    REPORTS = 'Reports',
    MEDIA = 'Media',
    SETTINGS = 'Settings',
    INSURANCE = 'Insurance',
    VIDEO_VISITS = 'VideoVisits',
    FORMS = 'Forms',
    PATIENTS = 'Patients',
    FILE_INBOX = 'FileInbox',
    FINANCIAL = 'Financial',
    AUTHENTICATION = 'Auth',
    KIOSKS = 'Kiosks',
    ERROR = 'error',
    /** Rich Text Editor, embedded in native apps */
    RTE = 'RTEEmbedded',
}
