import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, finalize } from 'rxjs/operators';
import { AddBrand, DeleteBrand, LoadBrands, ReloadBrands, ShowHideAddBrand, UpdateBrand, UpdateIsEditMode } from './brands.actions';
import { IBrandsStateModel } from './brands.model';
import { BrandModel } from './brand.model';
import { Brand, BrandsService } from '@symplast/generated-clients/web-portal';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';

const defaultBrandsState = (): IBrandsStateModel => {
    return {
        searchString: '',
        brands: [],
        filteredBrands: [],
        loading: false,
        errorMessage: '',
    };
};

@State<IBrandsStateModel>({
    name: 'brands',
    defaults: defaultBrandsState(),
})
@Injectable()
export class BrandsState {
    constructor(private brandsService: BrandsService) {}

    @Receiver()
    public static setSearchString(context: StateContext<IBrandsStateModel>, { payload }: EmitterAction<string>): void {
        const rawBrands = context.getState().brands;

        context.patchState({
            searchString: payload,
            filteredBrands: this.filterBrands(rawBrands, payload).map((b) => new BrandModel(b)),
        });
    }

    private static sortBrands(rawBrands: Brand[]): Brand[] {
        return [...rawBrands.sort((brand_1, brand_2) => brand_1.name.localeCompare(brand_2.name))];
    }

    private static filterBrands(rawBrands: Brand[], searchString: string): Brand[] {
        const lower = searchString?.toLowerCase();

        return [...rawBrands.filter((b) => !lower || b.name?.toLowerCase()?.includes(lower))];
    }

    private static remove(items: Brand[], index: number): Brand[] {
        return [...items.slice(0, index), ...items.slice(index + 1, items.length)];
    }

    @Action(LoadBrands)
    public load(context: StateContext<IBrandsStateModel>, { refresh }: LoadBrands) {
        if (!context.getState().brands.length || refresh) {
            context.patchState({ loading: true, brands: [], filteredBrands: [] });
            this.brandsService
                .GetBrands()
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    const rawBrands = BrandsState.sortBrands(response.result);
                    const searchString = context.getState().searchString;

                    context.patchState({
                        brands: rawBrands,
                        filteredBrands: BrandsState.filterBrands(rawBrands, searchString).map((b) => new BrandModel(b)),
                    });
                });
        }
    }

    @Action(ReloadBrands)
    public reload(context: StateContext<IBrandsStateModel>) {
        context.dispatch(new LoadBrands(true));
    }

    @Action(ShowHideAddBrand)
    public showHideAddBrand(context: StateContext<IBrandsStateModel>, { showAddBrand }: ShowHideAddBrand) {
        let filteredBrands = context.getState().filteredBrands;

        if (showAddBrand) {
            const newBrand = new BrandModel({ brandId: '' } as Brand, true);

            filteredBrands = [newBrand].concat(filteredBrands);
        } else {
            filteredBrands = [...filteredBrands.slice(0, 0), ...filteredBrands.slice(1, filteredBrands.length)];
        }

        context.patchState({
            filteredBrands: filteredBrands,
        });
    }

    @Action(AddBrand)
    public addBrand(context: StateContext<IBrandsStateModel>, { brandName }: AddBrand) {
        context.patchState({ loading: true });

        const rawBrands = context.getState().brands;

        this.brandsService
            .CreateBrand({ name: brandName })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.error?.statusCode === 400) {
                        context.patchState({ errorMessage: err.error.errorMessage });
                        context.patchState({ errorMessage: '' });

                        return EMPTY;
                    }

                    return throwError(err);
                }),
                finalize(() => context.patchState({ loading: false })),
            )
            .subscribe((response) => {
                const brand = response.result;
                const searchString = context.getState().searchString;

                const newRawBrands = BrandsState.sortBrands([brand].concat(rawBrands));

                context.patchState({
                    brands: newRawBrands,
                    filteredBrands: BrandsState.filterBrands(newRawBrands, searchString).map((b) => new BrandModel(b)),
                });
            });
    }

    @Action(UpdateBrand)
    public updateBrand(context: StateContext<IBrandsStateModel>, { brandId, name }: UpdateBrand) {
        context.patchState({ loading: true });

        const rawBrands = context.getState().brands;

        this.brandsService
            .UpdateBrand({ brandId: Number(brandId), brandUpdateRequest: { name: name || '' } })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.error?.statusCode === 400) {
                        context.patchState({ errorMessage: err.error.errorMessage });
                        context.patchState({ errorMessage: '' });

                        return EMPTY;
                    }

                    return throwError(err);
                }),
                finalize(() => context.patchState({ loading: false })),
            )
            .subscribe(() => {
                const searchString = context.getState().searchString;

                const brandsAfterUpdate = rawBrands.map((b) => (b.brandId == brandId ? ({ brandId: brandId, name: name } as Brand) : b));

                context.patchState({
                    brands: brandsAfterUpdate,
                    filteredBrands: BrandsState.filterBrands(brandsAfterUpdate, searchString).map((b) => new BrandModel(b)),
                });
            });
    }

    @Action(UpdateIsEditMode)
    public updateIsEditMode(context: StateContext<IBrandsStateModel>, { brandId, isEditMode }: UpdateIsEditMode) {
        const filteredBrands = context.getState().filteredBrands;

        context.patchState({
            filteredBrands: filteredBrands.map((model) =>
                model.brand.brandId == brandId ? new BrandModel(model.brand, isEditMode) : model,
            ),
        });
    }

    @Action(DeleteBrand)
    public deleteBrand(context: StateContext<IBrandsStateModel>, { brand }: DeleteBrand) {
        context.patchState({ loading: true });

        let rawBrands = context.getState().brands;
        const id = brand.brandId;

        this.brandsService
            .DeleteBrand(Number(id))
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.error?.statusCode === 400) {
                        context.patchState({ errorMessage: err.error.errorMessage });
                        context.patchState({ errorMessage: '' });

                        return EMPTY;
                    }

                    return throwError(err);
                }),
                finalize(() => context.patchState({ loading: false })),
            )
            .subscribe(() => {
                const searchString = context.getState().searchString;

                rawBrands = BrandsState.remove(rawBrands, rawBrands.indexOf(brand));

                context.patchState({
                    brands: rawBrands,
                    filteredBrands: BrandsState.filterBrands(rawBrands, searchString).map((b) => new BrandModel(b)),
                });
            });
    }
}
