import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { SYMPLAST_DIALOG_MODULE, SYMPLAST_DIALOG_PRESET } from '@symplast/ui/shared';
import { PolymorphContentComponent } from '../../../../shared/polymorph-content/polymorph-content.component';
import { CommonModule } from '@angular/common';
import { ErrorDialogData } from './error-dialog.model';

/** Do not use directly. Use only with ErrorDialogService */
@Component({
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, PolymorphContentComponent, ...SYMPLAST_DIALOG_MODULE],
    template: `
        <symplast-dialog>
            <symplast-dialog-header>
                <symplast-dialog-title>{{ data.title }}</symplast-dialog-title>

                <symplast-dialog-header-actions>
                    <button *ngIf="!data.close?.hidden" mat-icon-button mat-dialog-close>
                        <mat-icon>close</mat-icon>
                    </button>
                </symplast-dialog-header-actions>
            </symplast-dialog-header>

            <symplast-dialog-content class="text-center">
                <app-polymorph-content [content]="data.content"></app-polymorph-content>
            </symplast-dialog-content>

            <symplast-dialog-footer alignContent="center">
                <button *ngIf="data.close && !data.close.hidden" mat-stroked-button mat-dialog-close>
                    {{ data.close.label }}
                </button>
            </symplast-dialog-footer>
        </symplast-dialog>
    `,
    styles: [
        `
            button {
                margin-right: 0;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: SYMPLAST_DIALOG_PRESET, useValue: 'error' }],
})
export class ErrorDialogComponent {
    public data: ErrorDialogData = inject(MAT_DIALOG_DATA);
}
