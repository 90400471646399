/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiPagedResponseIEnumerableAppointmentService } from '../models/api-paged-response-ienumerable-appointment-service';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { AppointmentServiceUpdateRequest } from '../models/appointment-service-update-request';
@Injectable({
  providedIn: 'root',
})
class AppointmentServicesService extends __BaseService {
  static readonly GetAppointmentServicesPath = '/AppointmentServices';
  static readonly UpdateAppointmentServicesPath = '/AppointmentServices/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get appointment services.
   * @param params The `AppointmentServicesService.GetAppointmentServicesParams` containing the following parameters:
   *
   * - `take`: The take.
   *
   * - `skip`: The skip.
   *
   * - `query`: The query.
   *
   * - `orderBy`: The order By.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * @return Success
   */
  GetAppointmentServicesResponse(params: AppointmentServicesService.GetAppointmentServicesParams): __Observable<__StrictHttpResponse<ApiPagedResponseIEnumerableAppointmentService>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.orderBy != null) __params = __params.set('orderBy', params.orderBy.toString());
    if (params.isAscendingOrder != null) __params = __params.set('isAscendingOrder', params.isAscendingOrder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentServices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseIEnumerableAppointmentService>;
      })
    );
  }
  /**
   * The get appointment services.
   * @param params The `AppointmentServicesService.GetAppointmentServicesParams` containing the following parameters:
   *
   * - `take`: The take.
   *
   * - `skip`: The skip.
   *
   * - `query`: The query.
   *
   * - `orderBy`: The order By.
   *
   * - `isAscendingOrder`: The is Ascending Order.
   *
   * @return Success
   */
  GetAppointmentServices(params: AppointmentServicesService.GetAppointmentServicesParams): __Observable<ApiPagedResponseIEnumerableAppointmentService> {
    return this.GetAppointmentServicesResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseIEnumerableAppointmentService)
    );
  }

  /**
   * The update appointment services.
   * @param params The `AppointmentServicesService.UpdateAppointmentServicesParams` containing the following parameters:
   *
   * - `id`: The id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateAppointmentServicesResponse(params: AppointmentServicesService.UpdateAppointmentServicesParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/AppointmentServices/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The update appointment services.
   * @param params The `AppointmentServicesService.UpdateAppointmentServicesParams` containing the following parameters:
   *
   * - `id`: The id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateAppointmentServices(params: AppointmentServicesService.UpdateAppointmentServicesParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateAppointmentServicesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module AppointmentServicesService {

  /**
   * Parameters for GetAppointmentServices
   */
  export interface GetAppointmentServicesParams {

    /**
     * The take.
     */
    take?: number;

    /**
     * The skip.
     */
    skip?: number;

    /**
     * The query.
     */
    query?: string;

    /**
     * The order By.
     */
    orderBy?: 'ServiceName' | 'TypeName' | 'PurposeName' | 'IsAvailableForOnlineAppointmentRequests' | 'Duration' | 'Deposit';

    /**
     * The is Ascending Order.
     */
    isAscendingOrder?: boolean;
  }

  /**
   * Parameters for UpdateAppointmentServices
   */
  export interface UpdateAppointmentServicesParams {

    /**
     * The id.
     */
    id: number;

    /**
     * The update request.
     */
    updateRequest?: AppointmentServiceUpdateRequest;
  }
}

export { AppointmentServicesService }
