/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PatientStatementsGenerateRequest } from '../models/patient-statements-generate-request';
import { ApiResponseICollectionDailyReconciliationReportRow } from '../models/api-response-icollection-daily-reconciliation-report-row';
import { ApiResponseICollectionInventoryReportRow } from '../models/api-response-icollection-inventory-report-row';
import { ApiResponseICollectionSalesReportRow } from '../models/api-response-icollection-sales-report-row';
import { SalesReportRequest } from '../models/sales-report-request';
import { ApiResponseICollectionCommissionsReportRow } from '../models/api-response-icollection-commissions-report-row';
import { ApiResponseICollectionTaxesReportRow } from '../models/api-response-icollection-taxes-report-row';
import { ApiResponseICollectionPatientCreditsReportRow } from '../models/api-response-icollection-patient-credits-report-row';
import { ApiResponseICollectionEstimateFundingRow } from '../models/api-response-icollection-estimate-funding-row';
import { ApiResponseICollectionReferralSourceReportRow } from '../models/api-response-icollection-referral-source-report-row';
import { ApiResponseICollectionEstimatePipelineReportRow } from '../models/api-response-icollection-estimate-pipeline-report-row';
import { ApiResponseICollectionGiftCardsReportRow } from '../models/api-response-icollection-gift-cards-report-row';
import { ApiResponsePatientStatement } from '../models/api-response-patient-statement';
import { ApiResponseICollectionInvoiceAgingReportRow } from '../models/api-response-icollection-invoice-aging-report-row';
import { ActionResult } from '../models/action-result';
import { ApiResponseICollectionCashFlowReportRow } from '../models/api-response-icollection-cash-flow-report-row';
import { ApiResponseICollectionTipsReportRow } from '../models/api-response-icollection-tips-report-row';
import { ApiResponseICollectionUpcomingPaymentsReportRow } from '../models/api-response-icollection-upcoming-payments-report-row';
import { ApiResponseICollectionPackagesReportRow } from '../models/api-response-icollection-packages-report-row';
import { ApiResponseICollectionSurchargesReportRow } from '../models/api-response-icollection-surcharges-report-row';
import { ApiResponseICollectionMembershipReportRow } from '../models/api-response-icollection-membership-report-row';
import { ApiResponseDocument } from '../models/api-response-document';
import { ApiResponseICollectionIncomingReportRow } from '../models/api-response-icollection-incoming-report-row';
import { ApiResponseHealthReportRow } from '../models/api-response-health-report-row';
@Injectable({
    providedIn: 'root',
})
class ReportsService extends __BaseService {
    static readonly UploadPatientStatementsPath = '/PatientStatements';
    static readonly GetDailyReconciliationReportPath = '/DailyReconciliationReport';
    static readonly GetInventoryReportPath = '/InventoryReport';
    static readonly GetSalesReportPath = '/SalesReport';
    static readonly GetCommissionsReportPath = '/CommissionsReport';
    static readonly GetTaxesReportPath = '/TaxesReport';
    static readonly GetPatientCreditsReportPath = '/PatientCreditsReport';
    static readonly GetEstimateFundingReportPath = '/EstimateFundingReport';
    static readonly GetReferralSourceReportPath = '/ReferralSourceReport';
    static readonly GetEstimatePipelineReportPath = '/EstimatePipeline';
    static readonly GetGiftCardsReportPath = '/GiftCardsReport';
    static readonly GetPatientStatementReportPath = '/PatientStatementReport';
    static readonly GetInvoiceAgingReportPath = '/InvoiceAging';
    static readonly GetPatientStatementReportHtmlPath = '/PatientStatementReport/Html';
    static readonly GetPatientStatementReportPdfPath = '/PatientStatementReport/Pdf';
    static readonly GetCashFlowReportPath = '/CashFlowReport';
    static readonly GetTipsReportPath = '/TipsReport';
    static readonly GetUpcomingPaymentsReportPath = '/Reports/Tenants/{tenantId}/Reports/UpcomingPayments';
    static readonly GetPackagesReportPath = '/Reports/Tenants/{tenantId}/Reports/Packages';
    static readonly GetSurchargesReportPath = '/SurchargesReport';
    static readonly GetMembershipReportPath = '/Reports/Tenants/{tenantId}/Reports/MembershipReport';
    static readonly UploadPatientStatementPath = '/PatientStatement';
    static readonly GetIncomingReportPath = '/Reports/Tenants/{tenantId}/Reports/IncomingReport';
    static readonly GetHealthReportPath = '/Reports/Tenants/{tenantId}/Reports/HealthReport';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * The upload patient statements.
     * @param generateRequest The generate request.
     * @return Success
     */
    UploadPatientStatementsResponse(
        generateRequest?: PatientStatementsGenerateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = generateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/PatientStatements`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * The upload patient statements.
     * @param generateRequest The generate request.
     * @return Success
     */
    UploadPatientStatements(generateRequest?: PatientStatementsGenerateRequest): __Observable<ApiResponseOkResponse> {
        return this.UploadPatientStatementsResponse(generateRequest).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * The get daily reconciliation report.
     * @param params The `ReportsService.GetDailyReconciliationReportParams` containing the following parameters:
     *
     * - `ProviderIds`: Gets or sets the provider ids.
     *
     * - `PaymentStatus`: Gets or sets the payment status request.
     *
     * - `PaymentReasonIds`: Gets or sets the payment reason.
     *
     * - `PaymentPayeeType`: Gets or sets the payment payee type.
     *
     * - `PaymentModeIds`: Gets or sets the payment mode ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `IsRefund`: Gets or sets the is refund flag (leave as null for all transaction types).
     *
     * - `GroupBy`: Gets or sets the group by mode.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetDailyReconciliationReportResponse(
        params: ReportsService.GetDailyReconciliationReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionDailyReconciliationReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (params.ProviderIds || []).forEach((val) => {
            if (val != null) __params = __params.append('ProviderIds', val.toString());
        });
        if (params.PaymentStatus != null) __params = __params.set('PaymentStatus', params.PaymentStatus.toString());
        (params.PaymentReasonIds || []).forEach((val) => {
            if (val != null) __params = __params.append('PaymentReasonIds', val.toString());
        });
        if (params.PaymentPayeeType != null) __params = __params.set('PaymentPayeeType', params.PaymentPayeeType.toString());
        (params.PaymentModeIds || []).forEach((val) => {
            if (val != null) __params = __params.append('PaymentModeIds', val.toString());
        });
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.IsRefund != null) __params = __params.set('IsRefund', params.IsRefund.toString());
        if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/DailyReconciliationReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionDailyReconciliationReportRow>;
            }),
        );
    }
    /**
     * The get daily reconciliation report.
     * @param params The `ReportsService.GetDailyReconciliationReportParams` containing the following parameters:
     *
     * - `ProviderIds`: Gets or sets the provider ids.
     *
     * - `PaymentStatus`: Gets or sets the payment status request.
     *
     * - `PaymentReasonIds`: Gets or sets the payment reason.
     *
     * - `PaymentPayeeType`: Gets or sets the payment payee type.
     *
     * - `PaymentModeIds`: Gets or sets the payment mode ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `IsRefund`: Gets or sets the is refund flag (leave as null for all transaction types).
     *
     * - `GroupBy`: Gets or sets the group by mode.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetDailyReconciliationReport(
        params: ReportsService.GetDailyReconciliationReportParams,
    ): __Observable<ApiResponseICollectionDailyReconciliationReportRow> {
        return this.GetDailyReconciliationReportResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseICollectionDailyReconciliationReportRow),
        );
    }

    /**
     * Get the Inventory Report based on the specified filters.
     * @param params The `ReportsService.GetInventoryReportParams` containing the following parameters:
     *
     * - `LowStockOnly`: Gets or sets a value indicating whether low stock only.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `ItemType`: Gets or sets the Item Type to filter the report.
     *
     * - `HierarchicalCategoryId`: Gets or sets the category id. If -1, we need to specifically return items without a category.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `BrandId`: Gets or sets the brand id.
     *
     * @return Success
     */
    GetInventoryReportResponse(
        params: ReportsService.GetInventoryReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionInventoryReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.LowStockOnly != null) __params = __params.set('LowStockOnly', params.LowStockOnly.toString());
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.ItemType != null) __params = __params.set('ItemType', params.ItemType.toString());
        if (params.HierarchicalCategoryId != null)
            __params = __params.set('HierarchicalCategoryId', params.HierarchicalCategoryId.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        if (params.BrandId != null) __params = __params.set('BrandId', params.BrandId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/InventoryReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionInventoryReportRow>;
            }),
        );
    }
    /**
     * Get the Inventory Report based on the specified filters.
     * @param params The `ReportsService.GetInventoryReportParams` containing the following parameters:
     *
     * - `LowStockOnly`: Gets or sets a value indicating whether low stock only.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `ItemType`: Gets or sets the Item Type to filter the report.
     *
     * - `HierarchicalCategoryId`: Gets or sets the category id. If -1, we need to specifically return items without a category.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `BrandId`: Gets or sets the brand id.
     *
     * @return Success
     */
    GetInventoryReport(params: ReportsService.GetInventoryReportParams): __Observable<ApiResponseICollectionInventoryReportRow> {
        return this.GetInventoryReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionInventoryReportRow));
    }

    /**
     * Get the sales report based on the specified filters and grouping option.
     * @param request The request.
     * @return Success
     */
    GetSalesReportResponse(request?: SalesReportRequest): __Observable<__StrictHttpResponse<ApiResponseICollectionSalesReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/SalesReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionSalesReportRow>;
            }),
        );
    }
    /**
     * Get the sales report based on the specified filters and grouping option.
     * @param request The request.
     * @return Success
     */
    GetSalesReport(request?: SalesReportRequest): __Observable<ApiResponseICollectionSalesReportRow> {
        return this.GetSalesReportResponse(request).pipe(__map((_r) => _r.body as ApiResponseICollectionSalesReportRow));
    }

    /**
     * Get the Commissions Report for the specified time frame.
     * @param params The `ReportsService.GetCommissionsReportParams` containing the following parameters:
     *
     * - `ProviderType`: Gets or sets the provider selection type.
     *
     * - `ProductTypeId`: Gets or sets the product type id.
     *
     * - `PracticeUserType`: Gets or sets the practice user type.
     *
     * - `PracticeUserIds`: Gets or sets the practice user ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
     *
     * - `GroupBy`: Gets or sets the group by criteria.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CategoryId`: Gets or sets the category id.
     *
     * - `BrandId`: Gets or sets the brand id.
     *
     * @return Success
     */
    GetCommissionsReportResponse(
        params: ReportsService.GetCommissionsReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionCommissionsReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ProviderType != null) __params = __params.set('ProviderType', params.ProviderType.toString());
        if (params.ProductTypeId != null) __params = __params.set('ProductTypeId', params.ProductTypeId.toString());
        if (params.PracticeUserType != null) __params = __params.set('PracticeUserType', params.PracticeUserType.toString());
        (params.PracticeUserIds || []).forEach((val) => {
            if (val != null) __params = __params.append('PracticeUserIds', val.toString());
        });
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.IsGroupByAllLocation != null) __params = __params.set('IsGroupByAllLocation', params.IsGroupByAllLocation.toString());
        if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        if (params.CategoryId != null) __params = __params.set('CategoryId', params.CategoryId.toString());
        if (params.BrandId != null) __params = __params.set('BrandId', params.BrandId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/CommissionsReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionCommissionsReportRow>;
            }),
        );
    }
    /**
     * Get the Commissions Report for the specified time frame.
     * @param params The `ReportsService.GetCommissionsReportParams` containing the following parameters:
     *
     * - `ProviderType`: Gets or sets the provider selection type.
     *
     * - `ProductTypeId`: Gets or sets the product type id.
     *
     * - `PracticeUserType`: Gets or sets the practice user type.
     *
     * - `PracticeUserIds`: Gets or sets the practice user ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
     *
     * - `GroupBy`: Gets or sets the group by criteria.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CategoryId`: Gets or sets the category id.
     *
     * - `BrandId`: Gets or sets the brand id.
     *
     * @return Success
     */
    GetCommissionsReport(params: ReportsService.GetCommissionsReportParams): __Observable<ApiResponseICollectionCommissionsReportRow> {
        return this.GetCommissionsReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionCommissionsReportRow));
    }

    /**
     * Get the Taxes Report for the specified time range.
     * @param params The `ReportsService.GetTaxesReportParams` containing the following parameters:
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetTaxesReportResponse(
        params: ReportsService.GetTaxesReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionTaxesReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/TaxesReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionTaxesReportRow>;
            }),
        );
    }
    /**
     * Get the Taxes Report for the specified time range.
     * @param params The `ReportsService.GetTaxesReportParams` containing the following parameters:
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetTaxesReport(params: ReportsService.GetTaxesReportParams): __Observable<ApiResponseICollectionTaxesReportRow> {
        return this.GetTaxesReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionTaxesReportRow));
    }

    /**
     * Get the Patient Credits Report.
     * @param params The `ReportsService.GetPatientCreditsReportParams` containing the following parameters:
     *
     * - `TransactionTypes`: Gets or sets a value indicating the transaction types.
     *
     * - `ProviderId`: Gets or sets a value indicating the provider ID.
     *
     * - `LocationIds`: Gets or sets a value indicating the location IDs.
     *
     * - `IsShowEmptyBalance`: Gets or sets a value indicating whether this instance is show empty balance.
     *
     * - `DateTo`: Gets or sets the date to.
     *
     * - `DateFrom`: Gets or sets the date from.
     *
     * - `CreditSourceType`: Gets or sets the type of the credit source.
     *
     * @return Success
     */
    GetPatientCreditsReportResponse(
        params: ReportsService.GetPatientCreditsReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionPatientCreditsReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (params.TransactionTypes || []).forEach((val) => {
            if (val != null) __params = __params.append('TransactionTypes', val.toString());
        });
        if (params.ProviderId != null) __params = __params.set('ProviderId', params.ProviderId.toString());
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.IsShowEmptyBalance != null) __params = __params.set('IsShowEmptyBalance', params.IsShowEmptyBalance.toString());
        if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
        if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
        if (params.CreditSourceType != null) __params = __params.set('CreditSourceType', params.CreditSourceType.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PatientCreditsReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionPatientCreditsReportRow>;
            }),
        );
    }
    /**
     * Get the Patient Credits Report.
     * @param params The `ReportsService.GetPatientCreditsReportParams` containing the following parameters:
     *
     * - `TransactionTypes`: Gets or sets a value indicating the transaction types.
     *
     * - `ProviderId`: Gets or sets a value indicating the provider ID.
     *
     * - `LocationIds`: Gets or sets a value indicating the location IDs.
     *
     * - `IsShowEmptyBalance`: Gets or sets a value indicating whether this instance is show empty balance.
     *
     * - `DateTo`: Gets or sets the date to.
     *
     * - `DateFrom`: Gets or sets the date from.
     *
     * - `CreditSourceType`: Gets or sets the type of the credit source.
     *
     * @return Success
     */
    GetPatientCreditsReport(
        params: ReportsService.GetPatientCreditsReportParams,
    ): __Observable<ApiResponseICollectionPatientCreditsReportRow> {
        return this.GetPatientCreditsReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionPatientCreditsReportRow));
    }

    /**
     * Get the Estimate Funding and Invoicing Report.
     * @param LocationIds Gets or sets the location ids.
     * @return Success
     */
    GetEstimateFundingReportResponse(
        LocationIds?: Array<number>,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionEstimateFundingRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        let req = new HttpRequest<any>('GET', this.rootUrl + `/EstimateFundingReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionEstimateFundingRow>;
            }),
        );
    }
    /**
     * Get the Estimate Funding and Invoicing Report.
     * @param LocationIds Gets or sets the location ids.
     * @return Success
     */
    GetEstimateFundingReport(LocationIds?: Array<number>): __Observable<ApiResponseICollectionEstimateFundingRow> {
        return this.GetEstimateFundingReportResponse(LocationIds).pipe(__map((_r) => _r.body as ApiResponseICollectionEstimateFundingRow));
    }

    /**
     * Get the Referral source ROI Report.
     * @param params The `ReportsService.GetReferralSourceReportParams` containing the following parameters:
     *
     * - `ServiceLocationIds`: Gets or sets the service location ids.
     *
     * - `PatientType`: Gets or sets the patient type.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `BillingLocationIds`: Gets or sets the billing location ids.
     *
     * @return Success
     */
    GetReferralSourceReportResponse(
        params: ReportsService.GetReferralSourceReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionReferralSourceReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (params.ServiceLocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('ServiceLocationIds', val.toString());
        });
        if (params.PatientType != null) __params = __params.set('PatientType', params.PatientType.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        (params.BillingLocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('BillingLocationIds', val.toString());
        });
        let req = new HttpRequest<any>('GET', this.rootUrl + `/ReferralSourceReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionReferralSourceReportRow>;
            }),
        );
    }
    /**
     * Get the Referral source ROI Report.
     * @param params The `ReportsService.GetReferralSourceReportParams` containing the following parameters:
     *
     * - `ServiceLocationIds`: Gets or sets the service location ids.
     *
     * - `PatientType`: Gets or sets the patient type.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `BillingLocationIds`: Gets or sets the billing location ids.
     *
     * @return Success
     */
    GetReferralSourceReport(
        params: ReportsService.GetReferralSourceReportParams,
    ): __Observable<ApiResponseICollectionReferralSourceReportRow> {
        return this.GetReferralSourceReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionReferralSourceReportRow));
    }

    /**
     * Get the Estimate pipeline Report.
     * @param params The `ReportsService.GetEstimatePipelineReportParams` containing the following parameters:
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetEstimatePipelineReportResponse(
        params: ReportsService.GetEstimatePipelineReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionEstimatePipelineReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/EstimatePipeline`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionEstimatePipelineReportRow>;
            }),
        );
    }
    /**
     * Get the Estimate pipeline Report.
     * @param params The `ReportsService.GetEstimatePipelineReportParams` containing the following parameters:
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetEstimatePipelineReport(
        params: ReportsService.GetEstimatePipelineReportParams,
    ): __Observable<ApiResponseICollectionEstimatePipelineReportRow> {
        return this.GetEstimatePipelineReportResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseICollectionEstimatePipelineReportRow),
        );
    }

    /**
     * Get the Gift Cards Report.
     * @param params The `ReportsService.GetGiftCardsReportParams` containing the following parameters:
     *
     * - `Status`: Gets or sets the status.
     *
     * - `OccasionIds`: Gets or sets the occasion ids.
     *
     * - `IssuedDateTo`: Gets or sets the effective date to.
     *
     * - `IssuedDateFrom`: Gets or sets the effective date from.
     *
     * - `CreatedByIds`: Gets or sets the created by ids.
     *
     * @return Success
     */
    GetGiftCardsReportResponse(
        params: ReportsService.GetGiftCardsReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionGiftCardsReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (params.Status || []).forEach((val) => {
            if (val != null) __params = __params.append('Status', val.toString());
        });
        (params.OccasionIds || []).forEach((val) => {
            if (val != null) __params = __params.append('OccasionIds', val.toString());
        });
        if (params.IssuedDateTo != null) __params = __params.set('IssuedDateTo', params.IssuedDateTo.toString());
        if (params.IssuedDateFrom != null) __params = __params.set('IssuedDateFrom', params.IssuedDateFrom.toString());
        (params.CreatedByIds || []).forEach((val) => {
            if (val != null) __params = __params.append('CreatedByIds', val.toString());
        });
        let req = new HttpRequest<any>('GET', this.rootUrl + `/GiftCardsReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionGiftCardsReportRow>;
            }),
        );
    }
    /**
     * Get the Gift Cards Report.
     * @param params The `ReportsService.GetGiftCardsReportParams` containing the following parameters:
     *
     * - `Status`: Gets or sets the status.
     *
     * - `OccasionIds`: Gets or sets the occasion ids.
     *
     * - `IssuedDateTo`: Gets or sets the effective date to.
     *
     * - `IssuedDateFrom`: Gets or sets the effective date from.
     *
     * - `CreatedByIds`: Gets or sets the created by ids.
     *
     * @return Success
     */
    GetGiftCardsReport(params: ReportsService.GetGiftCardsReportParams): __Observable<ApiResponseICollectionGiftCardsReportRow> {
        return this.GetGiftCardsReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionGiftCardsReportRow));
    }

    /**
     * Get the Patient Statement Report.
     * @param patientId The patient id.
     * @return Success
     */
    GetPatientStatementReportResponse(patientId?: number): __Observable<__StrictHttpResponse<ApiResponsePatientStatement>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (patientId != null) __params = __params.set('patientId', patientId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PatientStatementReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientStatement>;
            }),
        );
    }
    /**
     * Get the Patient Statement Report.
     * @param patientId The patient id.
     * @return Success
     */
    GetPatientStatementReport(patientId?: number): __Observable<ApiResponsePatientStatement> {
        return this.GetPatientStatementReportResponse(patientId).pipe(__map((_r) => _r.body as ApiResponsePatientStatement));
    }

    /**
     * Get the invoice aging report.
     * @param GroupBy Gets or sets the grouping.
     * @return Success
     */
    GetInvoiceAgingReportResponse(
        GroupBy?: 'AgeBracket' | 'Patient',
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionInvoiceAgingReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (GroupBy != null) __params = __params.set('GroupBy', GroupBy.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/InvoiceAging`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionInvoiceAgingReportRow>;
            }),
        );
    }
    /**
     * Get the invoice aging report.
     * @param GroupBy Gets or sets the grouping.
     * @return Success
     */
    GetInvoiceAgingReport(GroupBy?: 'AgeBracket' | 'Patient'): __Observable<ApiResponseICollectionInvoiceAgingReportRow> {
        return this.GetInvoiceAgingReportResponse(GroupBy).pipe(__map((_r) => _r.body as ApiResponseICollectionInvoiceAgingReportRow));
    }

    /**
     * Get the Patient Statement Report Html.
     * @param patientId The patient id.
     * @return Success
     */
    GetPatientStatementReportHtmlResponse(patientId?: number): __Observable<__StrictHttpResponse<ActionResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (patientId != null) __params = __params.set('patientId', patientId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PatientStatementReport/Html`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ActionResult>;
            }),
        );
    }
    /**
     * Get the Patient Statement Report Html.
     * @param patientId The patient id.
     * @return Success
     */
    GetPatientStatementReportHtml(patientId?: number): __Observable<ActionResult> {
        return this.GetPatientStatementReportHtmlResponse(patientId).pipe(__map((_r) => _r.body as ActionResult));
    }

    /**
     * Get the Patient Statement Report PDF.
     * @param patientId The patient id.
     * @return Success
     */
    GetPatientStatementReportPdfResponse(patientId?: number): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (patientId != null) __params = __params.set('patientId', patientId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PatientStatementReport/Pdf`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'blob',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            }),
        );
    }
    /**
     * Get the Patient Statement Report PDF.
     * @param patientId The patient id.
     * @return Success
     */
    GetPatientStatementReportPdf(patientId?: number): __Observable<Blob> {
        return this.GetPatientStatementReportPdfResponse(patientId).pipe(__map((_r) => _r.body as Blob));
    }

    /**
     * The get cash flow report.
     * @param params The `ReportsService.GetCashFlowReportParams` containing the following parameters:
     *
     * - `PaymentStatus`: Gets or sets the payment status request.
     *
     * - `PaymentModeIds`: Gets or sets the payment mode ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `IsRefund`: Gets or sets the is refund flag (leave as null for all transaction types).
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetCashFlowReportResponse(
        params: ReportsService.GetCashFlowReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionCashFlowReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.PaymentStatus != null) __params = __params.set('PaymentStatus', params.PaymentStatus.toString());
        (params.PaymentModeIds || []).forEach((val) => {
            if (val != null) __params = __params.append('PaymentModeIds', val.toString());
        });
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.IsRefund != null) __params = __params.set('IsRefund', params.IsRefund.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/CashFlowReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionCashFlowReportRow>;
            }),
        );
    }
    /**
     * The get cash flow report.
     * @param params The `ReportsService.GetCashFlowReportParams` containing the following parameters:
     *
     * - `PaymentStatus`: Gets or sets the payment status request.
     *
     * - `PaymentModeIds`: Gets or sets the payment mode ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `IsRefund`: Gets or sets the is refund flag (leave as null for all transaction types).
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetCashFlowReport(params: ReportsService.GetCashFlowReportParams): __Observable<ApiResponseICollectionCashFlowReportRow> {
        return this.GetCashFlowReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionCashFlowReportRow));
    }

    /**
     * Get the tips report.
     * @param params The `ReportsService.GetTipsReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
     *
     * - `RecipientIds`: Gets or sets the list of Recipient Ids to filter.
     *
     * - `LocationIds`: Gets or sets the list of Location Ids to filter.
     *
     * - `DateTo`: Gets or sets the date to.
     *
     * - `DateFrom`: Gets or sets the date from.
     *
     * @return Success
     */
    GetTipsReportResponse(
        params: ReportsService.GetTipsReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionTipsReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
        if (params.SplitByLocation != null) __params = __params.set('SplitByLocation', params.SplitByLocation.toString());
        (params.RecipientIds || []).forEach((val) => {
            if (val != null) __params = __params.append('RecipientIds', val.toString());
        });
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
        if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/TipsReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionTipsReportRow>;
            }),
        );
    }
    /**
     * Get the tips report.
     * @param params The `ReportsService.GetTipsReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
     *
     * - `RecipientIds`: Gets or sets the list of Recipient Ids to filter.
     *
     * - `LocationIds`: Gets or sets the list of Location Ids to filter.
     *
     * - `DateTo`: Gets or sets the date to.
     *
     * - `DateFrom`: Gets or sets the date from.
     *
     * @return Success
     */
    GetTipsReport(params: ReportsService.GetTipsReportParams): __Observable<ApiResponseICollectionTipsReportRow> {
        return this.GetTipsReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionTipsReportRow));
    }

    /**
     * Get the upcoming payments report.
     * @param params The `ReportsService.GetUpcomingPaymentsReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `TypeOfPayment`: Gets or sets the type of payment.
     *
     * - `SplitByLocation`: Gets or sets a value indicating whether to split by location.
     *
     * - `RequestedByUserId`: Gets or sets the requested by user identifier.
     *
     * - `PaymentReasonIds`: Gets or sets the payment reason identifiers.
     *
     * - `DateTo`: Gets or sets the end date.
     *
     * - `DateFrom`: Gets or sets the start date.
     *
     * @return Success
     */
    GetUpcomingPaymentsReportResponse(
        params: ReportsService.GetUpcomingPaymentsReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionUpcomingPaymentsReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.TypeOfPayment != null) __params = __params.set('TypeOfPayment', params.TypeOfPayment.toString());
        if (params.SplitByLocation != null) __params = __params.set('SplitByLocation', params.SplitByLocation.toString());
        if (params.RequestedByUserId != null) __params = __params.set('RequestedByUserId', params.RequestedByUserId.toString());
        (params.PaymentReasonIds || []).forEach((val) => {
            if (val != null) __params = __params.append('PaymentReasonIds', val.toString());
        });
        if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
        if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Reports/Tenants/${encodeURIComponent(String(params.tenantId))}/Reports/UpcomingPayments`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionUpcomingPaymentsReportRow>;
            }),
        );
    }
    /**
     * Get the upcoming payments report.
     * @param params The `ReportsService.GetUpcomingPaymentsReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `TypeOfPayment`: Gets or sets the type of payment.
     *
     * - `SplitByLocation`: Gets or sets a value indicating whether to split by location.
     *
     * - `RequestedByUserId`: Gets or sets the requested by user identifier.
     *
     * - `PaymentReasonIds`: Gets or sets the payment reason identifiers.
     *
     * - `DateTo`: Gets or sets the end date.
     *
     * - `DateFrom`: Gets or sets the start date.
     *
     * @return Success
     */
    GetUpcomingPaymentsReport(
        params: ReportsService.GetUpcomingPaymentsReportParams,
    ): __Observable<ApiResponseICollectionUpcomingPaymentsReportRow> {
        return this.GetUpcomingPaymentsReportResponse(params).pipe(
            __map((_r) => _r.body as ApiResponseICollectionUpcomingPaymentsReportRow),
        );
    }

    /**
     * Get the packages report.
     * @param params The `ReportsService.GetPackagesReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `Statuses`: Gets or sets the statuses.
     *
     * - `SalesPersonIds`: Gets or sets the sales person ids.
     *
     * - `RequestedByUserId`: Gets or sets the requested by user identifier.
     *
     * - `ProviderIds`: Gets or sets the provider ids.
     *
     * - `OwnerIds`: Gets or sets the owner ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `ItemIds`: Gets or sets the item ids.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * - `DateTo`: Gets or sets the end date.
     *
     * - `DateFrom`: Gets or sets the start date.
     *
     * @return Success
     */
    GetPackagesReportResponse(
        params: ReportsService.GetPackagesReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionPackagesReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        (params.Statuses || []).forEach((val) => {
            if (val != null) __params = __params.append('Statuses', val.toString());
        });
        (params.SalesPersonIds || []).forEach((val) => {
            if (val != null) __params = __params.append('SalesPersonIds', val.toString());
        });
        if (params.RequestedByUserId != null) __params = __params.set('RequestedByUserId', params.RequestedByUserId.toString());
        (params.ProviderIds || []).forEach((val) => {
            if (val != null) __params = __params.append('ProviderIds', val.toString());
        });
        (params.OwnerIds || []).forEach((val) => {
            if (val != null) __params = __params.append('OwnerIds', val.toString());
        });
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        (params.ItemIds || []).forEach((val) => {
            if (val != null) __params = __params.append('ItemIds', val.toString());
        });
        if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
        if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
        if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Reports/Tenants/${encodeURIComponent(String(params.tenantId))}/Reports/Packages`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionPackagesReportRow>;
            }),
        );
    }
    /**
     * Get the packages report.
     * @param params The `ReportsService.GetPackagesReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `Statuses`: Gets or sets the statuses.
     *
     * - `SalesPersonIds`: Gets or sets the sales person ids.
     *
     * - `RequestedByUserId`: Gets or sets the requested by user identifier.
     *
     * - `ProviderIds`: Gets or sets the provider ids.
     *
     * - `OwnerIds`: Gets or sets the owner ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `ItemIds`: Gets or sets the item ids.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * - `DateTo`: Gets or sets the end date.
     *
     * - `DateFrom`: Gets or sets the start date.
     *
     * @return Success
     */
    GetPackagesReport(params: ReportsService.GetPackagesReportParams): __Observable<ApiResponseICollectionPackagesReportRow> {
        return this.GetPackagesReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionPackagesReportRow));
    }

    /**
     * Gets the surcharges report.
     * @param params The `ReportsService.GetSurchargesReportParams` containing the following parameters:
     *
     * - `PaymentDateTo`: Gets or sets the payment date to.
     *
     * - `PaymentDateFrom`: Gets or sets the payment date from.
     *
     * - `MerchantAccountIds`: Gets or sets the merchant account ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * @return Success
     */
    GetSurchargesReportResponse(
        params: ReportsService.GetSurchargesReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionSurchargesReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.PaymentDateTo != null) __params = __params.set('PaymentDateTo', params.PaymentDateTo.toString());
        if (params.PaymentDateFrom != null) __params = __params.set('PaymentDateFrom', params.PaymentDateFrom.toString());
        (params.MerchantAccountIds || []).forEach((val) => {
            if (val != null) __params = __params.append('MerchantAccountIds', val.toString());
        });
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/SurchargesReport`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionSurchargesReportRow>;
            }),
        );
    }
    /**
     * Gets the surcharges report.
     * @param params The `ReportsService.GetSurchargesReportParams` containing the following parameters:
     *
     * - `PaymentDateTo`: Gets or sets the payment date to.
     *
     * - `PaymentDateFrom`: Gets or sets the payment date from.
     *
     * - `MerchantAccountIds`: Gets or sets the merchant account ids.
     *
     * - `LocationIds`: Gets or sets the location ids.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * @return Success
     */
    GetSurchargesReport(params: ReportsService.GetSurchargesReportParams): __Observable<ApiResponseICollectionSurchargesReportRow> {
        return this.GetSurchargesReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionSurchargesReportRow));
    }

    /**
     * Get the Membership Report.
     * @param params The `ReportsService.GetMembershipReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `Statuses`: Gets or sets the membership statuses.
     *
     * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
     *
     * - `MembershipProgramIds`: Gets or sets the membership program ids.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * - `DateTo`: Gets or sets the date to.
     *
     * - `DateFrom`: Gets or sets the date from.
     *
     * @return Success
     */
    GetMembershipReportResponse(
        params: ReportsService.GetMembershipReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionMembershipReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        (params.Statuses || []).forEach((val) => {
            if (val != null) __params = __params.append('Statuses', val.toString());
        });
        if (params.SplitByLocation != null) __params = __params.set('SplitByLocation', params.SplitByLocation.toString());
        (params.MembershipProgramIds || []).forEach((val) => {
            if (val != null) __params = __params.append('MembershipProgramIds', val.toString());
        });
        if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
        if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
        if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Reports/Tenants/${encodeURIComponent(String(params.tenantId))}/Reports/MembershipReport`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionMembershipReportRow>;
            }),
        );
    }
    /**
     * Get the Membership Report.
     * @param params The `ReportsService.GetMembershipReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `Statuses`: Gets or sets the membership statuses.
     *
     * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
     *
     * - `MembershipProgramIds`: Gets or sets the membership program ids.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * - `DateTo`: Gets or sets the date to.
     *
     * - `DateFrom`: Gets or sets the date from.
     *
     * @return Success
     */
    GetMembershipReport(params: ReportsService.GetMembershipReportParams): __Observable<ApiResponseICollectionMembershipReportRow> {
        return this.GetMembershipReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionMembershipReportRow));
    }

    /**
     * Uploads patient statement.
     * @param params The `ReportsService.UploadPatientStatementParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `generatedFrom`: The generated From.
     *
     * - `description`: The description.
     *
     * @return Success
     */
    UploadPatientStatementResponse(
        params: ReportsService.UploadPatientStatementParams,
    ): __Observable<__StrictHttpResponse<ApiResponseDocument>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
        if (params.generatedFrom != null) __params = __params.set('generatedFrom', params.generatedFrom.toString());
        if (params.description != null) __params = __params.set('description', params.description.toString());
        let req = new HttpRequest<any>('POST', this.rootUrl + `/PatientStatement`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseDocument>;
            }),
        );
    }
    /**
     * Uploads patient statement.
     * @param params The `ReportsService.UploadPatientStatementParams` containing the following parameters:
     *
     * - `patientId`: The patient identifier.
     *
     * - `generatedFrom`: The generated From.
     *
     * - `description`: The description.
     *
     * @return Success
     */
    UploadPatientStatement(params: ReportsService.UploadPatientStatementParams): __Observable<ApiResponseDocument> {
        return this.UploadPatientStatementResponse(params).pipe(__map((_r) => _r.body as ApiResponseDocument));
    }

    /**
     * Gets the incoming report asynchronous.
     * @param params The `ReportsService.GetIncomingReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `PaymentReasonIds`: Gets or sets the payment reason ids.
     *
     * - `PaymentModeIds`: Gets or sets the payment mode ids.
     *
     * - `LocationIds`: Gets or sets a value indicating the location IDs.
     *
     * - `IsRefund`: Gets or sets the is refund.
     *
     * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CreditSystemType`: Gets or sets a value indicating the credit type.
     *
     * - `AlsoGroupBy`: Gets or sets the also group by.
     *
     * @return Success
     */
    GetIncomingReportResponse(
        params: ReportsService.GetIncomingReportParams,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionIncomingReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        (params.PaymentReasonIds || []).forEach((val) => {
            if (val != null) __params = __params.append('PaymentReasonIds', val.toString());
        });
        (params.PaymentModeIds || []).forEach((val) => {
            if (val != null) __params = __params.append('PaymentModeIds', val.toString());
        });
        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.IsRefund != null) __params = __params.set('IsRefund', params.IsRefund.toString());
        if (params.IsGroupByAllLocation != null) __params = __params.set('IsGroupByAllLocation', params.IsGroupByAllLocation.toString());
        if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        (params.CreditSystemType || []).forEach((val) => {
            if (val != null) __params = __params.append('CreditSystemType', val.toString());
        });
        if (params.AlsoGroupBy != null) __params = __params.set('AlsoGroupBy', params.AlsoGroupBy.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Reports/Tenants/${encodeURIComponent(String(params.tenantId))}/Reports/IncomingReport`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionIncomingReportRow>;
            }),
        );
    }
    /**
     * Gets the incoming report asynchronous.
     * @param params The `ReportsService.GetIncomingReportParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `PaymentReasonIds`: Gets or sets the payment reason ids.
     *
     * - `PaymentModeIds`: Gets or sets the payment mode ids.
     *
     * - `LocationIds`: Gets or sets a value indicating the location IDs.
     *
     * - `IsRefund`: Gets or sets the is refund.
     *
     * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
     *
     * - `GroupBy`: Gets or sets the group by.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * - `CreditSystemType`: Gets or sets a value indicating the credit type.
     *
     * - `AlsoGroupBy`: Gets or sets the also group by.
     *
     * @return Success
     */
    GetIncomingReport(params: ReportsService.GetIncomingReportParams): __Observable<ApiResponseICollectionIncomingReportRow> {
        return this.GetIncomingReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseICollectionIncomingReportRow));
    }

    /**
     * @param params The `ReportsService.GetHealthReportParams` containing the following parameters:
     *
     * - `tenantId`:
     *
     * - `LocationIds`:
     *
     * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetHealthReportResponse(params: ReportsService.GetHealthReportParams): __Observable<__StrictHttpResponse<ApiResponseHealthReportRow>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        (params.LocationIds || []).forEach((val) => {
            if (val != null) __params = __params.append('LocationIds', val.toString());
        });
        if (params.IsGroupByAllLocation != null) __params = __params.set('IsGroupByAllLocation', params.IsGroupByAllLocation.toString());
        if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
        if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Reports/Tenants/${encodeURIComponent(String(params.tenantId))}/Reports/HealthReport`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseHealthReportRow>;
            }),
        );
    }
    /**
     * @param params The `ReportsService.GetHealthReportParams` containing the following parameters:
     *
     * - `tenantId`:
     *
     * - `LocationIds`:
     *
     * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
     *
     * - `EffectiveDateTo`: Gets or sets the effective date to.
     *
     * - `EffectiveDateFrom`: Gets or sets the effective date from.
     *
     * @return Success
     */
    GetHealthReport(params: ReportsService.GetHealthReportParams): __Observable<ApiResponseHealthReportRow> {
        return this.GetHealthReportResponse(params).pipe(__map((_r) => _r.body as ApiResponseHealthReportRow));
    }
}

module ReportsService {
    /**
     * Parameters for GetDailyReconciliationReport
     */
    export interface GetDailyReconciliationReportParams {
        /**
         * Gets or sets the provider ids.
         */
        ProviderIds?: Array<number>;

        /**
         * Gets or sets the payment status request.
         */
        PaymentStatus?: 'Completed' | 'Voided' | 'All';

        /**
         * Gets or sets the payment reason.
         */
        PaymentReasonIds?: Array<number>;

        /**
         * Gets or sets the payment payee type.
         */
        PaymentPayeeType?: 'Any' | 'Patient' | 'InsuranceCompany';

        /**
         * Gets or sets the payment mode ids.
         */
        PaymentModeIds?: Array<number>;

        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the is refund flag (leave as null for all transaction types).
         */
        IsRefund?: boolean;

        /**
         * Gets or sets the group by mode.
         */
        GroupBy?: 'PerDay' | 'ForDateRange';

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;
    }

    /**
     * Parameters for GetInventoryReport
     */
    export interface GetInventoryReportParams {
        /**
         * Gets or sets a value indicating whether low stock only.
         */
        LowStockOnly?: boolean;

        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the Item Type to filter the report.
         */
        ItemType?: 'Fee' | 'Procedure' | 'Product';

        /**
         * Gets or sets the category id. If -1, we need to specifically return items without a category.
         */
        HierarchicalCategoryId?: number;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets the brand id.
         */
        BrandId?: number;
    }

    /**
     * Parameters for GetCommissionsReport
     */
    export interface GetCommissionsReportParams {
        /**
         * Gets or sets the provider selection type.
         */
        ProviderType?: 'NotSelected' | 'None' | 'User';

        /**
         * Gets or sets the product type id.
         */
        ProductTypeId?: number;

        /**
         * Gets or sets the practice user type.
         */
        PracticeUserType?: 'AllUsers' | 'AllProviders' | 'AllSalesPeople';

        /**
         * Gets or sets the practice user ids.
         */
        PracticeUserIds?: Array<number>;

        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the is group by all location.
         */
        IsGroupByAllLocation?: boolean;

        /**
         * Gets or sets the group by criteria.
         */
        GroupBy?: 'PracticeUser' | 'Item' | 'Provider' | 'SalesPerson' | 'Day' | 'Week' | 'Quarter' | 'Month' | 'Year' | 'ItemType';

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets the category id.
         */
        CategoryId?: number;

        /**
         * Gets or sets the brand id.
         */
        BrandId?: number;
    }

    /**
     * Parameters for GetTaxesReport
     */
    export interface GetTaxesReportParams {
        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;
    }

    /**
     * Parameters for GetPatientCreditsReport
     */
    export interface GetPatientCreditsReportParams {
        /**
         * Gets or sets a value indicating the transaction types.
         */
        TransactionTypes?: Array<'PatientDeposit' | 'CreditNote' | 'ExcessPayment' | 'Payment' | 'Refund'>;

        /**
         * Gets or sets a value indicating the provider ID.
         */
        ProviderId?: number;

        /**
         * Gets or sets a value indicating the location IDs.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets a value indicating whether this instance is show empty balance.
         */
        IsShowEmptyBalance?: boolean;

        /**
         * Gets or sets the date to.
         */
        DateTo?: string;

        /**
         * Gets or sets the date from.
         */
        DateFrom?: string;

        /**
         * Gets or sets the type of the credit source.
         */
        CreditSourceType?: 'Estimate' | 'Package' | 'UnallocatedPatientCredit' | 'CreditNote';
    }

    /**
     * Parameters for GetReferralSourceReport
     */
    export interface GetReferralSourceReportParams {
        /**
         * Gets or sets the service location ids.
         */
        ServiceLocationIds?: Array<number>;

        /**
         * Gets or sets the patient type.
         */
        PatientType?: 'Patient' | 'Prospect';

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets the billing location ids.
         */
        BillingLocationIds?: Array<number>;
    }

    /**
     * Parameters for GetEstimatePipelineReport
     */
    export interface GetEstimatePipelineReportParams {
        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;
    }

    /**
     * Parameters for GetGiftCardsReport
     */
    export interface GetGiftCardsReportParams {
        /**
         * Gets or sets the status.
         */
        Status?: Array<'Inactive' | 'Active' | 'Draft' | 'Deleted' | 'Expired' | 'Redeemed'>;

        /**
         * Gets or sets the occasion ids.
         */
        OccasionIds?: Array<number>;

        /**
         * Gets or sets the effective date to.
         */
        IssuedDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        IssuedDateFrom?: string;

        /**
         * Gets or sets the created by ids.
         */
        CreatedByIds?: Array<number>;
    }

    /**
     * Parameters for GetCashFlowReport
     */
    export interface GetCashFlowReportParams {
        /**
         * Gets or sets the payment status request.
         */
        PaymentStatus?: 'Completed' | 'Voided' | 'All';

        /**
         * Gets or sets the payment mode ids.
         */
        PaymentModeIds?: Array<number>;

        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the is refund flag (leave as null for all transaction types).
         */
        IsRefund?: boolean;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;
    }

    /**
     * Parameters for GetTipsReport
     */
    export interface GetTipsReportParams {
        /**
         * The tenant Id.
         */
        tenantId: number;

        /**
         * Gets or sets a value indicating whether the data would be split by location.
         */
        SplitByLocation?: boolean;

        /**
         * Gets or sets the list of Recipient Ids to filter.
         */
        RecipientIds?: Array<number>;

        /**
         * Gets or sets the list of Location Ids to filter.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the date to.
         */
        DateTo?: string;

        /**
         * Gets or sets the date from.
         */
        DateFrom?: string;
    }

    /**
     * Parameters for GetUpcomingPaymentsReport
     */
    export interface GetUpcomingPaymentsReportParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * Gets or sets the type of payment.
         */
        TypeOfPayment?: 'All' | 'PaymentRequest' | 'RecurringPayment';

        /**
         * Gets or sets a value indicating whether to split by location.
         */
        SplitByLocation?: boolean;

        /**
         * Gets or sets the requested by user identifier.
         */
        RequestedByUserId?: number;

        /**
         * Gets or sets the payment reason identifiers.
         */
        PaymentReasonIds?: Array<number>;

        /**
         * Gets or sets the end date.
         */
        DateTo?: string;

        /**
         * Gets or sets the start date.
         */
        DateFrom?: string;
    }

    /**
     * Parameters for GetPackagesReport
     */
    export interface GetPackagesReportParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * Gets or sets the statuses.
         */
        Statuses?: Array<
            | 'Draft'
            | 'Open'
            | 'ReviewedByPatient'
            | 'Rejected'
            | 'ApprovedByPatient'
            | 'Delivered'
            | 'Cancelled'
            | 'Deleted'
            | 'Funded'
            | 'PartiallyFunded'
        >;

        /**
         * Gets or sets the sales person ids.
         */
        SalesPersonIds?: Array<number>;

        /**
         * Gets or sets the requested by user identifier.
         */
        RequestedByUserId?: number;

        /**
         * Gets or sets the provider ids.
         */
        ProviderIds?: Array<number>;

        /**
         * Gets or sets the owner ids.
         */
        OwnerIds?: Array<number>;

        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the item ids.
         */
        ItemIds?: Array<number>;

        /**
         * Gets or sets the group by.
         */
        GroupBy?: 'Location' | 'Owner' | 'Status';

        /**
         * Gets or sets the end date.
         */
        DateTo?: string;

        /**
         * Gets or sets the start date.
         */
        DateFrom?: string;
    }

    /**
     * Parameters for GetSurchargesReport
     */
    export interface GetSurchargesReportParams {
        /**
         * Gets or sets the payment date to.
         */
        PaymentDateTo?: string;

        /**
         * Gets or sets the payment date from.
         */
        PaymentDateFrom?: string;

        /**
         * Gets or sets the merchant account ids.
         */
        MerchantAccountIds?: Array<number>;

        /**
         * Gets or sets the location ids.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the group by.
         */
        GroupBy?: 'MerchantAccount' | 'Location';
    }

    /**
     * Parameters for GetMembershipReport
     */
    export interface GetMembershipReportParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * Gets or sets the membership statuses.
         */
        Statuses?: Array<'Active' | 'Expired' | 'Past' | 'Cancelled'>;

        /**
         * Gets or sets a value indicating whether the data would be split by location.
         */
        SplitByLocation?: boolean;

        /**
         * Gets or sets the membership program ids.
         */
        MembershipProgramIds?: Array<number>;

        /**
         * Gets or sets the group by.
         */
        GroupBy?: 'None' | 'Day' | 'Week' | 'Quarter' | 'Month' | 'Year';

        /**
         * Gets or sets the date to.
         */
        DateTo?: string;

        /**
         * Gets or sets the date from.
         */
        DateFrom?: string;
    }

    /**
     * Parameters for UploadPatientStatement
     */
    export interface UploadPatientStatementParams {
        /**
         * The patient identifier.
         */
        patientId?: number;

        /**
         * The generated From.
         */
        generatedFrom?: 'PatientFile' | 'PatientBalances';

        /**
         * The description.
         */
        description?: string;
    }

    /**
     * Parameters for GetIncomingReport
     */
    export interface GetIncomingReportParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * Gets or sets the payment reason ids.
         */
        PaymentReasonIds?: Array<number>;

        /**
         * Gets or sets the payment mode ids.
         */
        PaymentModeIds?: Array<number>;

        /**
         * Gets or sets a value indicating the location IDs.
         */
        LocationIds?: Array<number>;

        /**
         * Gets or sets the is refund.
         */
        IsRefund?: boolean;

        /**
         * Gets or sets the is group by all location.
         */
        IsGroupByAllLocation?: boolean;

        /**
         * Gets or sets the group by.
         */
        GroupBy?: 'CreditSystemType' | 'Patient' | 'PaymentAssignee' | 'Day' | 'Week' | 'Month' | 'Quarter' | 'Year';

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;

        /**
         * Gets or sets a value indicating the credit type.
         */
        CreditSystemType?: Array<'EstimateDeposit' | 'PatientCreditUnallocated' | 'GiftCardBalance' | 'PackageDeposit'>;

        /**
         * Gets or sets the also group by.
         */
        AlsoGroupBy?: 'CreditSystemType' | 'Patient' | 'PaymentAssignee' | 'Day' | 'Week' | 'Month' | 'Quarter' | 'Year';
    }

    /**
     * Parameters for GetHealthReport
     */
    export interface GetHealthReportParams {
        tenantId: number;
        LocationIds?: Array<number>;

        /**
         * Gets or sets the is group by all location.
         */
        IsGroupByAllLocation?: boolean;

        /**
         * Gets or sets the effective date to.
         */
        EffectiveDateTo?: string;

        /**
         * Gets or sets the effective date from.
         */
        EffectiveDateFrom?: string;
    }
}

export { ReportsService };
