import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncateByWords' })
export class TruncateByWordsPipe implements PipeTransform {
    transform(value: string, words: number): string {
        const wordsArray = value.split(/\s/g);
        let result = '';

        for (let i = 0; i < words; i++) {
            result += wordsArray[i] + ' ';
        }

        return result.replace(/\s$/, '') + '...';
    }

    constructor() {}
}
