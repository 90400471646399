/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionFilterSet } from '../models/api-response-icollection-filter-set';
import { ApiResponseFilterSet } from '../models/api-response-filter-set';
import { FilterSetCreateUpdateRequest } from '../models/filter-set-create-update-request';
import { OkResponse } from '../models/ok-response';
@Injectable({
  providedIn: 'root',
})
class FiltersService extends __BaseService {
  static readonly GetFilterSetsForSectionPath = '/Filters';
  static readonly CreateFilterSetPath = '/Filters';
  static readonly SaveDefaultFilterSetPath = '/Filters';
  static readonly GetFilterSetByIdPath = '/Filters/{id}';
  static readonly UpdateFilterSetPath = '/Filters/{id}';
  static readonly DeleteFilterSetPath = '/Filters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of the Filter Set.
   * @param section The section.
   * @return Success
   */
  GetFilterSetsForSectionResponse(section: string): __Observable<__StrictHttpResponse<ApiResponseICollectionFilterSet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (section != null) __params = __params.set('section', section.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Filters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionFilterSet>;
      })
    );
  }
  /**
   * Gets a list of the Filter Set.
   * @param section The section.
   * @return Success
   */
  GetFilterSetsForSection(section: string): __Observable<ApiResponseICollectionFilterSet> {
    return this.GetFilterSetsForSectionResponse(section).pipe(
      __map(_r => _r.body as ApiResponseICollectionFilterSet)
    );
  }

  /**
   * Creates the Filter Set.
   * @param createRequest The Symplast.WebPortal.Service.Filters.Models.FilterSetCreateUpdateRequest.
   * @return Success
   */
  CreateFilterSetResponse(createRequest: FilterSetCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseFilterSet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Filters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFilterSet>;
      })
    );
  }
  /**
   * Creates the Filter Set.
   * @param createRequest The Symplast.WebPortal.Service.Filters.Models.FilterSetCreateUpdateRequest.
   * @return Success
   */
  CreateFilterSet(createRequest: FilterSetCreateUpdateRequest): __Observable<ApiResponseFilterSet> {
    return this.CreateFilterSetResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseFilterSet)
    );
  }

  /**
   * Saves Filter Set as default.
   * @param params The `FiltersService.SaveDefaultFilterSetParams` containing the following parameters:
   *
   * - `section`: The section.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  SaveDefaultFilterSetResponse(params: FiltersService.SaveDefaultFilterSetParams): __Observable<__StrictHttpResponse<OkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.section != null) __params = __params.set('section', params.section.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/Filters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResponse>;
      })
    );
  }
  /**
   * Saves Filter Set as default.
   * @param params The `FiltersService.SaveDefaultFilterSetParams` containing the following parameters:
   *
   * - `section`: The section.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  SaveDefaultFilterSet(params: FiltersService.SaveDefaultFilterSetParams): __Observable<OkResponse> {
    return this.SaveDefaultFilterSetResponse(params).pipe(
      __map(_r => _r.body as OkResponse)
    );
  }

  /**
   * Gets the Filter Set.
   * @param id The id.
   * @return Success
   */
  GetFilterSetByIdResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseFilterSet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Filters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFilterSet>;
      })
    );
  }
  /**
   * Gets the Filter Set.
   * @param id The id.
   * @return Success
   */
  GetFilterSetById(id: string): __Observable<ApiResponseFilterSet> {
    return this.GetFilterSetByIdResponse(id).pipe(
      __map(_r => _r.body as ApiResponseFilterSet)
    );
  }

  /**
   * Updates the Filter Set.
   * @param params The `FiltersService.UpdateFilterSetParams` containing the following parameters:
   *
   * - `updateRequest`: The Symplast.WebPortal.Service.Filters.Models.FilterSetCreateUpdateRequest.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  UpdateFilterSetResponse(params: FiltersService.UpdateFilterSetParams): __Observable<__StrictHttpResponse<ApiResponseFilterSet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.updateRequest;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Filters/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFilterSet>;
      })
    );
  }
  /**
   * Updates the Filter Set.
   * @param params The `FiltersService.UpdateFilterSetParams` containing the following parameters:
   *
   * - `updateRequest`: The Symplast.WebPortal.Service.Filters.Models.FilterSetCreateUpdateRequest.
   *
   * - `id`: The id.
   *
   * @return Success
   */
  UpdateFilterSet(params: FiltersService.UpdateFilterSetParams): __Observable<ApiResponseFilterSet> {
    return this.UpdateFilterSetResponse(params).pipe(
      __map(_r => _r.body as ApiResponseFilterSet)
    );
  }

  /**
   * Deletes the Filter Set.
   * @param id The id.
   * @return Success
   */
  DeleteFilterSetResponse(id: string): __Observable<__StrictHttpResponse<OkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Filters/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResponse>;
      })
    );
  }
  /**
   * Deletes the Filter Set.
   * @param id The id.
   * @return Success
   */
  DeleteFilterSet(id: string): __Observable<OkResponse> {
    return this.DeleteFilterSetResponse(id).pipe(
      __map(_r => _r.body as OkResponse)
    );
  }
}

module FiltersService {

  /**
   * Parameters for SaveDefaultFilterSet
   */
  export interface SaveDefaultFilterSetParams {

    /**
     * The section.
     */
    section: string;

    /**
     * The id.
     */
    id?: string;
  }

  /**
   * Parameters for UpdateFilterSet
   */
  export interface UpdateFilterSetParams {

    /**
     * The Symplast.WebPortal.Service.Filters.Models.FilterSetCreateUpdateRequest.
     */
    updateRequest: FilterSetCreateUpdateRequest;

    /**
     * The id.
     */
    id: string;
  }
}

export { FiltersService }
