import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: 'symplast-breadcrumb, [symplast-breadcrumb], [symplastBreadcrumb]',
    standalone: true,
})
export class BreadcrumbDirective {
    @Input() link!: string | string[];

    constructor(private readonly elementRef: ElementRef) {}

    public get text(): string {
        return this.elementRef.nativeElement.textContent;
    }
}
