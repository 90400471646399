/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionUpcomingPaymentsReportRow } from '../models/api-response-icollection-upcoming-payments-report-row';
import { ApiResponseICollectionPackagesReportRow } from '../models/api-response-icollection-packages-report-row';
import { ApiResponseIEnumerableMembershipReportRow } from '../models/api-response-ienumerable-membership-report-row';
import { ApiResponseICollectionIncomingReportRow } from '../models/api-response-icollection-incoming-report-row';
import { ApiResponseHealthReportRow } from '../models/api-response-health-report-row';
@Injectable({
  providedIn: 'root',
})
class FinancialReportsService extends __BaseService {
  static readonly GetUpcomingPaymentsReportPath = '/FinancialReports/UpcomingPayments';
  static readonly GetPackagesReportPath = '/FinancialReports/Packages';
  static readonly GetMembershipReportPath = '/FinancialReports/MembershipReport';
  static readonly GetIncomingReportPath = '/IncomingReport';
  static readonly GetFinancialHealthReportPath = '/FinancialReports/HealthReport';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The upcoming payments report.
   * @param params The `FinancialReportsService.GetUpcomingPaymentsReportParams` containing the following parameters:
   *
   * - `TypeOfPayment`: Gets or sets the type of payment.
   *
   * - `SplitByLocation`: Gets or sets a value indicating whether to split by location.
   *
   * - `PaymentReasonIds`: Gets or sets the payment reason identifiers.
   *
   * - `DateTo`: Gets or sets the end date.
   *
   * - `DateFrom`: Gets or sets the start date.
   *
   * @return Success
   */
  GetUpcomingPaymentsReportResponse(params: FinancialReportsService.GetUpcomingPaymentsReportParams): __Observable<__StrictHttpResponse<ApiResponseICollectionUpcomingPaymentsReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.TypeOfPayment != null) __params = __params.set('TypeOfPayment', params.TypeOfPayment.toString());
    if (params.SplitByLocation != null) __params = __params.set('SplitByLocation', params.SplitByLocation.toString());
    (params.PaymentReasonIds || []).forEach(val => {if (val != null) __params = __params.append('PaymentReasonIds', val.toString())});
    if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
    if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/FinancialReports/UpcomingPayments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionUpcomingPaymentsReportRow>;
      })
    );
  }
  /**
   * The upcoming payments report.
   * @param params The `FinancialReportsService.GetUpcomingPaymentsReportParams` containing the following parameters:
   *
   * - `TypeOfPayment`: Gets or sets the type of payment.
   *
   * - `SplitByLocation`: Gets or sets a value indicating whether to split by location.
   *
   * - `PaymentReasonIds`: Gets or sets the payment reason identifiers.
   *
   * - `DateTo`: Gets or sets the end date.
   *
   * - `DateFrom`: Gets or sets the start date.
   *
   * @return Success
   */
  GetUpcomingPaymentsReport(params: FinancialReportsService.GetUpcomingPaymentsReportParams): __Observable<ApiResponseICollectionUpcomingPaymentsReportRow> {
    return this.GetUpcomingPaymentsReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionUpcomingPaymentsReportRow)
    );
  }

  /**
   * The packages report.
   * @param params The `FinancialReportsService.GetPackagesReportParams` containing the following parameters:
   *
   * - `Statuses`: Gets or sets the statuses.
   *
   * - `SalesPersonIds`: Gets or sets the sales person ids.
   *
   * - `ProviderIds`: Gets or sets the provider ids.
   *
   * - `OwnerIds`: Gets or sets the package owner ids.
   *
   * - `LocationIds`: Gets or sets the location ids.
   *
   * - `ItemIds`: Gets or sets the item ids.
   *
   * - `GroupBy`: Gets or sets the group by.
   *
   * - `DateTo`: Gets or sets the end date.
   *
   * - `DateFrom`: Gets or sets the start date.
   *
   * @return Success
   */
  GetPackagesReportResponse(params: FinancialReportsService.GetPackagesReportParams): __Observable<__StrictHttpResponse<ApiResponseICollectionPackagesReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.Statuses || []).forEach(val => {if (val != null) __params = __params.append('Statuses', val.toString())});
    (params.SalesPersonIds || []).forEach(val => {if (val != null) __params = __params.append('SalesPersonIds', val.toString())});
    (params.ProviderIds || []).forEach(val => {if (val != null) __params = __params.append('ProviderIds', val.toString())});
    (params.OwnerIds || []).forEach(val => {if (val != null) __params = __params.append('OwnerIds', val.toString())});
    (params.LocationIds || []).forEach(val => {if (val != null) __params = __params.append('LocationIds', val.toString())});
    (params.ItemIds || []).forEach(val => {if (val != null) __params = __params.append('ItemIds', val.toString())});
    if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
    if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
    if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/FinancialReports/Packages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionPackagesReportRow>;
      })
    );
  }
  /**
   * The packages report.
   * @param params The `FinancialReportsService.GetPackagesReportParams` containing the following parameters:
   *
   * - `Statuses`: Gets or sets the statuses.
   *
   * - `SalesPersonIds`: Gets or sets the sales person ids.
   *
   * - `ProviderIds`: Gets or sets the provider ids.
   *
   * - `OwnerIds`: Gets or sets the package owner ids.
   *
   * - `LocationIds`: Gets or sets the location ids.
   *
   * - `ItemIds`: Gets or sets the item ids.
   *
   * - `GroupBy`: Gets or sets the group by.
   *
   * - `DateTo`: Gets or sets the end date.
   *
   * - `DateFrom`: Gets or sets the start date.
   *
   * @return Success
   */
  GetPackagesReport(params: FinancialReportsService.GetPackagesReportParams): __Observable<ApiResponseICollectionPackagesReportRow> {
    return this.GetPackagesReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionPackagesReportRow)
    );
  }

  /**
   * Get the Membership Report.
   * @param params The `FinancialReportsService.GetMembershipReportParams` containing the following parameters:
   *
   * - `Statuses`: Gets or sets the membership statuses.
   *
   * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
   *
   * - `MembershipProgramIds`: Gets or sets the membership program ids.
   *
   * - `GroupBy`: Gets or sets the group by.
   *
   * - `DateTo`: Gets or sets the date to.
   *
   * - `DateFrom`: Gets or sets the date from.
   *
   * @return Success
   */
  GetMembershipReportResponse(params: FinancialReportsService.GetMembershipReportParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableMembershipReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.Statuses || []).forEach(val => {if (val != null) __params = __params.append('Statuses', val.toString())});
    if (params.SplitByLocation != null) __params = __params.set('SplitByLocation', params.SplitByLocation.toString());
    (params.MembershipProgramIds || []).forEach(val => {if (val != null) __params = __params.append('MembershipProgramIds', val.toString())});
    if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
    if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
    if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/FinancialReports/MembershipReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableMembershipReportRow>;
      })
    );
  }
  /**
   * Get the Membership Report.
   * @param params The `FinancialReportsService.GetMembershipReportParams` containing the following parameters:
   *
   * - `Statuses`: Gets or sets the membership statuses.
   *
   * - `SplitByLocation`: Gets or sets a value indicating whether the data would be split by location.
   *
   * - `MembershipProgramIds`: Gets or sets the membership program ids.
   *
   * - `GroupBy`: Gets or sets the group by.
   *
   * - `DateTo`: Gets or sets the date to.
   *
   * - `DateFrom`: Gets or sets the date from.
   *
   * @return Success
   */
  GetMembershipReport(params: FinancialReportsService.GetMembershipReportParams): __Observable<ApiResponseIEnumerableMembershipReportRow> {
    return this.GetMembershipReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableMembershipReportRow)
    );
  }

  /**
   * Gets the incoming report asynchronous.
   * @param params The `FinancialReportsService.GetIncomingReportParams` containing the following parameters:
   *
   * - `PaymentReasonIds`: Gets or sets the payment reason ids.
   *
   * - `PaymentModeIds`: Gets or sets the payment mode ids.
   *
   * - `LocationIds`: Gets or sets a value indicating the location IDs.
   *
   * - `IsRefund`: Gets or sets the is refund.
   *
   * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
   *
   * - `GroupBy`: Gets or sets the group by.
   *
   * - `EffectiveDateTo`: Gets or sets the effective date to.
   *
   * - `EffectiveDateFrom`: Gets or sets the effective date from.
   *
   * - `CreditSystemType`: Gets or sets a value indicating the credit type.
   *
   * - `AlsoGroupBy`: Gets or sets the also group by.
   *
   * @return Success
   */
  GetIncomingReportResponse(params: FinancialReportsService.GetIncomingReportParams): __Observable<__StrictHttpResponse<ApiResponseICollectionIncomingReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.PaymentReasonIds || []).forEach(val => {if (val != null) __params = __params.append('PaymentReasonIds', val.toString())});
    (params.PaymentModeIds || []).forEach(val => {if (val != null) __params = __params.append('PaymentModeIds', val.toString())});
    (params.LocationIds || []).forEach(val => {if (val != null) __params = __params.append('LocationIds', val.toString())});
    if (params.IsRefund != null) __params = __params.set('IsRefund', params.IsRefund.toString());
    if (params.IsGroupByAllLocation != null) __params = __params.set('IsGroupByAllLocation', params.IsGroupByAllLocation.toString());
    if (params.GroupBy != null) __params = __params.set('GroupBy', params.GroupBy.toString());
    if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
    if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
    (params.CreditSystemType || []).forEach(val => {if (val != null) __params = __params.append('CreditSystemType', val.toString())});
    if (params.AlsoGroupBy != null) __params = __params.set('AlsoGroupBy', params.AlsoGroupBy.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/IncomingReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionIncomingReportRow>;
      })
    );
  }
  /**
   * Gets the incoming report asynchronous.
   * @param params The `FinancialReportsService.GetIncomingReportParams` containing the following parameters:
   *
   * - `PaymentReasonIds`: Gets or sets the payment reason ids.
   *
   * - `PaymentModeIds`: Gets or sets the payment mode ids.
   *
   * - `LocationIds`: Gets or sets a value indicating the location IDs.
   *
   * - `IsRefund`: Gets or sets the is refund.
   *
   * - `IsGroupByAllLocation`: Gets or sets the is group by all location.
   *
   * - `GroupBy`: Gets or sets the group by.
   *
   * - `EffectiveDateTo`: Gets or sets the effective date to.
   *
   * - `EffectiveDateFrom`: Gets or sets the effective date from.
   *
   * - `CreditSystemType`: Gets or sets a value indicating the credit type.
   *
   * - `AlsoGroupBy`: Gets or sets the also group by.
   *
   * @return Success
   */
  GetIncomingReport(params: FinancialReportsService.GetIncomingReportParams): __Observable<ApiResponseICollectionIncomingReportRow> {
    return this.GetIncomingReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseICollectionIncomingReportRow)
    );
  }

  /**
   * Gets the financial health report asynchronous.
   * @param params The `FinancialReportsService.GetFinancialHealthReportParams` containing the following parameters:
   *
   * - `SplitByLocation`:
   *
   * - `LocationIds`:
   *
   * - `DateTo`:
   *
   * - `DateFrom`:
   *
   * @return Success
   */
  GetFinancialHealthReportResponse(params: FinancialReportsService.GetFinancialHealthReportParams): __Observable<__StrictHttpResponse<ApiResponseHealthReportRow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SplitByLocation != null) __params = __params.set('SplitByLocation', params.SplitByLocation.toString());
    (params.LocationIds || []).forEach(val => {if (val != null) __params = __params.append('LocationIds', val.toString())});
    if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
    if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/FinancialReports/HealthReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseHealthReportRow>;
      })
    );
  }
  /**
   * Gets the financial health report asynchronous.
   * @param params The `FinancialReportsService.GetFinancialHealthReportParams` containing the following parameters:
   *
   * - `SplitByLocation`:
   *
   * - `LocationIds`:
   *
   * - `DateTo`:
   *
   * - `DateFrom`:
   *
   * @return Success
   */
  GetFinancialHealthReport(params: FinancialReportsService.GetFinancialHealthReportParams): __Observable<ApiResponseHealthReportRow> {
    return this.GetFinancialHealthReportResponse(params).pipe(
      __map(_r => _r.body as ApiResponseHealthReportRow)
    );
  }
}

module FinancialReportsService {

  /**
   * Parameters for GetUpcomingPaymentsReport
   */
  export interface GetUpcomingPaymentsReportParams {

    /**
     * Gets or sets the type of payment.
     */
    TypeOfPayment?: 'All' | 'PaymentRequest' | 'RecurringPayment';

    /**
     * Gets or sets a value indicating whether to split by location.
     */
    SplitByLocation?: boolean;

    /**
     * Gets or sets the payment reason identifiers.
     */
    PaymentReasonIds?: Array<number>;

    /**
     * Gets or sets the end date.
     */
    DateTo?: string;

    /**
     * Gets or sets the start date.
     */
    DateFrom?: string;
  }

  /**
   * Parameters for GetPackagesReport
   */
  export interface GetPackagesReportParams {

    /**
     * Gets or sets the statuses.
     */
    Statuses?: Array<'Draft' | 'Open' | 'ReviewedByPatient' | 'Rejected' | 'ApprovedByPatient' | 'Delivered' | 'Cancelled' | 'Funded' | 'PartiallyFunded'>;

    /**
     * Gets or sets the sales person ids.
     */
    SalesPersonIds?: Array<number>;

    /**
     * Gets or sets the provider ids.
     */
    ProviderIds?: Array<number>;

    /**
     * Gets or sets the package owner ids.
     */
    OwnerIds?: Array<number>;

    /**
     * Gets or sets the location ids.
     */
    LocationIds?: Array<number>;

    /**
     * Gets or sets the item ids.
     */
    ItemIds?: Array<number>;

    /**
     * Gets or sets the group by.
     */
    GroupBy?: 'Location' | 'Owner' | 'Status';

    /**
     * Gets or sets the end date.
     */
    DateTo?: string;

    /**
     * Gets or sets the start date.
     */
    DateFrom?: string;
  }

  /**
   * Parameters for GetMembershipReport
   */
  export interface GetMembershipReportParams {

    /**
     * Gets or sets the membership statuses.
     */
    Statuses?: Array<string>;

    /**
     * Gets or sets a value indicating whether the data would be split by location.
     */
    SplitByLocation?: boolean;

    /**
     * Gets or sets the membership program ids.
     */
    MembershipProgramIds?: Array<number>;

    /**
     * Gets or sets the group by.
     */
    GroupBy?: 'None' | 'Day' | 'Week' | 'Quarter' | 'Month' | 'Year';

    /**
     * Gets or sets the date to.
     */
    DateTo?: string;

    /**
     * Gets or sets the date from.
     */
    DateFrom?: string;
  }

  /**
   * Parameters for GetIncomingReport
   */
  export interface GetIncomingReportParams {

    /**
     * Gets or sets the payment reason ids.
     */
    PaymentReasonIds?: Array<number>;

    /**
     * Gets or sets the payment mode ids.
     */
    PaymentModeIds?: Array<number>;

    /**
     * Gets or sets a value indicating the location IDs.
     */
    LocationIds?: Array<number>;

    /**
     * Gets or sets the is refund.
     */
    IsRefund?: boolean;

    /**
     * Gets or sets the is group by all location.
     */
    IsGroupByAllLocation?: boolean;

    /**
     * Gets or sets the group by.
     */
    GroupBy?: 'CreditSystemType' | 'Patient' | 'PaymentAssignee' | 'Day' | 'Week' | 'Month' | 'Quarter' | 'Year';

    /**
     * Gets or sets the effective date to.
     */
    EffectiveDateTo?: string;

    /**
     * Gets or sets the effective date from.
     */
    EffectiveDateFrom?: string;

    /**
     * Gets or sets a value indicating the credit type.
     */
    CreditSystemType?: Array<'EstimateDeposit' | 'PatientCreditUnallocated' | 'GiftCardBalance' | 'PackageDeposit' | 'InvoicePayment'>;

    /**
     * Gets or sets the also group by.
     */
    AlsoGroupBy?: 'CreditSystemType' | 'Patient' | 'PaymentAssignee' | 'Day' | 'Week' | 'Month' | 'Quarter' | 'Year';
  }

  /**
   * Parameters for GetFinancialHealthReport
   */
  export interface GetFinancialHealthReportParams {
    SplitByLocation?: boolean;
    LocationIds?: Array<number>;
    DateTo?: string;
    DateFrom?: string;
  }
}

export { FinancialReportsService }
