/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseNoteResponse } from '../models/api-response-note-response';
import { CreateNewNoteRequest } from '../models/create-new-note-request';
import { ApiResponseNote } from '../models/api-response-note';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseIEnumerableNote } from '../models/api-response-ienumerable-note';
import { Section } from '../models/section';
import { UpdateEhrLockRequest } from '../models/update-ehr-lock-request';
import { ApiResponseNoteImageResponse } from '../models/api-response-note-image-response';
import { ApiResponseIEnumerableDraftNote } from '../models/api-response-ienumerable-draft-note';
@Injectable({
    providedIn: 'root',
})
class EmrNotesService extends __BaseService {
    static readonly EmrNotesCreateNewNoteForPatientPath = '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/CreateNew';
    static readonly EmrNotesCreateNewNotePath = '/api/Appointments/{appointmentId}/EmrNotes/CreateNew';
    static readonly EmrNotesGetNoteForPatientPath = '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/{noteId}';
    static readonly EmrNotesGetNoteForAppointmentPath = '/api/Appointments/{appointmentId}/EmrNotes/{noteId}';
    static readonly EmrNotesGetNotePath = '/api/EmrNotes/{noteId}';
    static readonly EmrNotesDeleteForPatientPath = '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/{noteId}/Delete';
    static readonly EmrNotesDeleteForAppointmentPath = '/api/Appointments/{appointmentId}/EmrNotes/{noteId}/Delete';
    static readonly EmrNotesDeletePath = '/api/EmrNotes/{noteId}/Delete';
    static readonly EmrNotesCreateRevisionForPatientPath =
        '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/{noteId}/CreateRevision';
    static readonly EmrNotesCreateRevisionForAppointmentPath = '/api/Appointments/{appointmentId}/EmrNotes/{noteId}/CreateRevision';
    static readonly EmrNotesCreateRevisionPath = '/api/EmrNotes/{noteId}/CreateRevision';
    static readonly EmrNotesSubmitForPatientPath = '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/{noteId}/Submit';
    static readonly EmrNotesSubmitForAppointmentPath = '/api/Appointments/{appointmentId}/EmrNotes/{noteId}/Submit';
    static readonly EmrNotesSubmitPath = '/api/EmrNotes/{noteId}/Submit';
    static readonly EmrNotesGetLatestNoteForPatientPath = '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/Latest';
    static readonly EmrNotesGetLatestNotePath = '/api/Appointments/{appointmentId}/EmrNotes/Latest';
    static readonly EmrNotesGetLatestDraftForPatientPath = '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrDraftNotes/Latest';
    static readonly EmrNotesGetLatestDraftPath = '/api/Appointments/{appointmentId}/EmrDraftNotes/Latest';
    static readonly EmrNotesGetHistoryNotesForPatientPath = '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/History';
    static readonly EmrNotesGetHistoryNotesPath = '/api/Appointments/{appointmentId}/EmrNotes/History';
    static readonly EmrNotesGetAllHistoryNotesForPatientPath = '/api/Patients/{patientId}/EmrNotes/History';
    static readonly EmrNotesUpdateSectionForPatientPath =
        '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/{noteId}/UpdateSection';
    static readonly EmrNotesUpdateLockForEhrNotePath = '/api/EmrNotes/{noteId}/Lock';
    static readonly EmrNotesUpdateIsSharedWithPatientForEhrNotePath = '/api/EhrNotes/{noteId}/IsSharedWithPatient';
    static readonly EmrNotesUpdateIsViewedByPatientForEhrNotePath = '/api/EhrNotes/{noteId}/IsViewedByPatient';
    static readonly EmrNotesUpdateSectionForAppointmentPath = '/api/Appointments/{appointmentId}/EmrNotes/{noteId}/UpdateSection';
    static readonly EmrNotesUpdateSectionPath = '/api/EmrNotes/{noteId}/UpdateSection';
    static readonly EmrNotesUploadImagesForPatientPath =
        '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/{noteId}/UploadImages';
    static readonly EmrNotesUploadImagesForAppointmentPath = '/api/Appointments/{appointmentId}/EmrNotes/{noteId}/UploadImages';
    static readonly EmrNotesUploadImagesPath = '/api/EmrNotes/{noteId}/UploadImages';
    static readonly EmrNotesDeleteImageForPatientPath =
        '/api/Patients/{patientId}/Appointments/{appointmentId}/EmrNotes/{noteId}/Images/{imageId}/Delete';
    static readonly EmrNotesDeleteImageForAppointmentPath = '/api/Appointments/{appointmentId}/EmrNotes/{noteId}/Images/{imageId}/Delete';
    static readonly EmrNotesDeleteImagePath = '/api/EmrNotes/{noteId}/Images/{imageId}/Delete';
    static readonly EmrNotesDeleteMultimediaFilePath = '/api/EmrNotes/{noteId}/Multimedia/{fileId}';
    static readonly EmrNotesGetDraftNotesObsoletePath = '/api/Patients/EmrNotes/UserDraft';
    static readonly EmrNotesGetDraftNotesPath = '/api/EmrNotes/UserDraftNotes';
    static readonly EmrNotesPostPath = '/api/EmrNotes/{noteId}/Post';
    static readonly EmrNotesSubmitNotePath = '/api/EmrNotes/{noteId}/SubmitNote';
    static readonly EmrNotesRevisePath = '/api/EmrNotes/{noteId}/Revise';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param params The `EmrNotesService.EmrNotesCreateNewNoteForPatientParams` containing the following parameters:
     *
     * - `request`: Contains additional information
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment ID
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK
     */
    EmrNotesCreateNewNoteForPatientResponse(
        params: EmrNotesService.EmrNotesCreateNewNoteForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.request;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/CreateNew`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesCreateNewNoteForPatientParams` containing the following parameters:
     *
     * - `request`: Contains additional information
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment ID
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK
     */
    EmrNotesCreateNewNoteForPatient(params: EmrNotesService.EmrNotesCreateNewNoteForPatientParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesCreateNewNoteForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesCreateNewNoteParams` containing the following parameters:
     *
     * - `request`: Contains additional information
     *
     * - `appointmentId`: Appointment ID
     *
     * @return OK
     */
    EmrNotesCreateNewNoteResponse(
        params: EmrNotesService.EmrNotesCreateNewNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.request;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/CreateNew`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesCreateNewNoteParams` containing the following parameters:
     *
     * - `request`: Contains additional information
     *
     * - `appointmentId`: Appointment ID
     *
     * @return OK
     */
    EmrNotesCreateNewNote(params: EmrNotesService.EmrNotesCreateNewNoteParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesCreateNewNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesGetNoteForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetNoteForPatientResponse(
        params: EmrNotesService.EmrNotesGetNoteForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNote>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesGetNoteForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetNoteForPatient(params: EmrNotesService.EmrNotesGetNoteForPatientParams): __Observable<ApiResponseNote> {
        return this.EmrNotesGetNoteForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNote));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesGetNoteForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetNoteForAppointmentResponse(
        params: EmrNotesService.EmrNotesGetNoteForAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNote>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesGetNoteForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetNoteForAppointment(params: EmrNotesService.EmrNotesGetNoteForAppointmentParams): __Observable<ApiResponseNote> {
        return this.EmrNotesGetNoteForAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseNote));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesGetNoteParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * - `includeAllowedActions`: The include Allowed Actions.
     *
     * @return OK.
     */
    EmrNotesGetNoteResponse(params: EmrNotesService.EmrNotesGetNoteParams): __Observable<__StrictHttpResponse<ApiResponseNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeMultimedia != null) __params = __params.set('includeMultimedia', params.includeMultimedia.toString());
        if (params.includeAllowedActions != null) __params = __params.set('includeAllowedActions', params.includeAllowedActions.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/EmrNotes/${params.noteId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNote>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesGetNoteParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * - `includeAllowedActions`: The include Allowed Actions.
     *
     * @return OK.
     */
    EmrNotesGetNote(params: EmrNotesService.EmrNotesGetNoteParams): __Observable<ApiResponseNote> {
        return this.EmrNotesGetNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseNote));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesDeleteForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteForPatientResponse(
        params: EmrNotesService.EmrNotesDeleteForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/Delete`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesDeleteForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteForPatient(params: EmrNotesService.EmrNotesDeleteForPatientParams): __Observable<ApiResponseOkResponse> {
        return this.EmrNotesDeleteForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesDeleteForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteForAppointmentResponse(
        params: EmrNotesService.EmrNotesDeleteForAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/Delete`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesDeleteForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteForAppointment(params: EmrNotesService.EmrNotesDeleteForAppointmentParams): __Observable<ApiResponseOkResponse> {
        return this.EmrNotesDeleteForAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * @param noteId Note identifier
     * @return OK.
     */
    EmrNotesDeleteResponse(noteId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${noteId}/Delete`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * @param noteId Note identifier
     * @return OK.
     */
    EmrNotesDelete(noteId: number): __Observable<ApiResponseOkResponse> {
        return this.EmrNotesDeleteResponse(noteId).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * A new Note record will be a draft note with all the data copied from the provided note. Essentially it is a draft "clone" of the Note.
     * @param params The `EmrNotesService.EmrNotesCreateRevisionForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesCreateRevisionForPatientResponse(
        params: EmrNotesService.EmrNotesCreateRevisionForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl +
                `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/CreateRevision`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * A new Note record will be a draft note with all the data copied from the provided note. Essentially it is a draft "clone" of the Note.
     * @param params The `EmrNotesService.EmrNotesCreateRevisionForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesCreateRevisionForPatient(
        params: EmrNotesService.EmrNotesCreateRevisionForPatientParams,
    ): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesCreateRevisionForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * A new Note record will be a draft note with all the data copied from the provided note. Essentially it is a draft "clone" of the Note.
     * @param params The `EmrNotesService.EmrNotesCreateRevisionForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesCreateRevisionForAppointmentResponse(
        params: EmrNotesService.EmrNotesCreateRevisionForAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/CreateRevision`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * A new Note record will be a draft note with all the data copied from the provided note. Essentially it is a draft "clone" of the Note.
     * @param params The `EmrNotesService.EmrNotesCreateRevisionForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesCreateRevisionForAppointment(
        params: EmrNotesService.EmrNotesCreateRevisionForAppointmentParams,
    ): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesCreateRevisionForAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * A new Note record will be a draft note with all the data copied from the provided note. Essentially it is a draft "clone" of the Note.
     * @param noteId Note identifier
     * @return OK.
     */
    EmrNotesCreateRevisionResponse(noteId: number): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${noteId}/CreateRevision`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * A new Note record will be a draft note with all the data copied from the provided note. Essentially it is a draft "clone" of the Note.
     * @param noteId Note identifier
     * @return OK.
     */
    EmrNotesCreateRevision(noteId: number): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesCreateRevisionResponse(noteId).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * Submitting means that the status of the Note is changed from Draft to Submitted and Note becomes visible to all users.
     * Draft notes are only visible to user who created them.
     * @param params The `EmrNotesService.EmrNotesSubmitForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesSubmitForPatientResponse(
        params: EmrNotesService.EmrNotesSubmitForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/Submit`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * Submitting means that the status of the Note is changed from Draft to Submitted and Note becomes visible to all users.
     * Draft notes are only visible to user who created them.
     * @param params The `EmrNotesService.EmrNotesSubmitForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesSubmitForPatient(params: EmrNotesService.EmrNotesSubmitForPatientParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesSubmitForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * Submitting means that the status of the Note is changed from Draft to Submitted and Note becomes visible to all users.
     * Draft notes are only visible to user who created them.
     * @param params The `EmrNotesService.EmrNotesSubmitForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesSubmitForAppointmentResponse(
        params: EmrNotesService.EmrNotesSubmitForAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/Submit`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * Submitting means that the status of the Note is changed from Draft to Submitted and Note becomes visible to all users.
     * Draft notes are only visible to user who created them.
     * @param params The `EmrNotesService.EmrNotesSubmitForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesSubmitForAppointment(params: EmrNotesService.EmrNotesSubmitForAppointmentParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesSubmitForAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * Submitting means that the status of the Note is changed from Draft to Submitted and Note becomes visible to all users.
     * Draft notes are only visible to user who created them.
     * @param noteId Note identifier
     * @return OK.
     */
    EmrNotesSubmitResponse(noteId: number): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${noteId}/Submit`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * Submitting means that the status of the Note is changed from Draft to Submitted and Note becomes visible to all users.
     * Draft notes are only visible to user who created them.
     * @param noteId Note identifier
     * @return OK.
     */
    EmrNotesSubmit(noteId: number): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesSubmitResponse(noteId).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * Latest Note is note that has either Submitted status or belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestNoteForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetLatestNoteForPatientResponse(
        params: EmrNotesService.EmrNotesGetLatestNoteForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/Latest`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNote>;
            }),
        );
    }
    /**
     * Latest Note is note that has either Submitted status or belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestNoteForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetLatestNoteForPatient(params: EmrNotesService.EmrNotesGetLatestNoteForPatientParams): __Observable<ApiResponseNote> {
        return this.EmrNotesGetLatestNoteForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNote));
    }

    /**
     * Latest Note is note that has either Submitted status or belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestNoteParams` containing the following parameters:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: The user identifier.
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * - `includeAllowedActions`: The include Allowed Actions.
     *
     * @return OK.
     */
    EmrNotesGetLatestNoteResponse(
        params: EmrNotesService.EmrNotesGetLatestNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.includeMultimedia != null) __params = __params.set('includeMultimedia', params.includeMultimedia.toString());
        if (params.includeAllowedActions != null) __params = __params.set('includeAllowedActions', params.includeAllowedActions.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/Latest`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNote>;
            }),
        );
    }
    /**
     * Latest Note is note that has either Submitted status or belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestNoteParams` containing the following parameters:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: The user identifier.
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * - `includeAllowedActions`: The include Allowed Actions.
     *
     * @return OK.
     */
    EmrNotesGetLatestNote(params: EmrNotesService.EmrNotesGetLatestNoteParams): __Observable<ApiResponseNote> {
        return this.EmrNotesGetLatestNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseNote));
    }

    /**
     * Latest Draft Note is note that has Draft status and belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestDraftForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetLatestDraftForPatientResponse(
        params: EmrNotesService.EmrNotesGetLatestDraftForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrDraftNotes/Latest`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNote>;
            }),
        );
    }
    /**
     * Latest Draft Note is note that has Draft status and belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestDraftForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetLatestDraftForPatient(params: EmrNotesService.EmrNotesGetLatestDraftForPatientParams): __Observable<ApiResponseNote> {
        return this.EmrNotesGetLatestDraftForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNote));
    }

    /**
     * Latest Draft Note is note that has Draft status and belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestDraftParams` containing the following parameters:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: The user Id.
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * - `includeAllowedActions`: The include Allowed Actions.
     *
     * @return OK.
     */
    EmrNotesGetLatestDraftResponse(
        params: EmrNotesService.EmrNotesGetLatestDraftParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.includeMultimedia != null) __params = __params.set('includeMultimedia', params.includeMultimedia.toString());
        if (params.includeAllowedActions != null) __params = __params.set('includeAllowedActions', params.includeAllowedActions.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrDraftNotes/Latest`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNote>;
            }),
        );
    }
    /**
     * Latest Draft Note is note that has Draft status and belongs to the requesting user plus which has the largest ID among notes that match conditions.
     * We don't yet have a way to track modification dates and thus return a truly latest note meaning note that was created or modified the last.
     * @param params The `EmrNotesService.EmrNotesGetLatestDraftParams` containing the following parameters:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: The user Id.
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * - `includeAllowedActions`: The include Allowed Actions.
     *
     * @return OK.
     */
    EmrNotesGetLatestDraft(params: EmrNotesService.EmrNotesGetLatestDraftParams): __Observable<ApiResponseNote> {
        return this.EmrNotesGetLatestDraftResponse(params).pipe(__map((_r) => _r.body as ApiResponseNote));
    }

    /**
     * We don't yet have a way to track modification dates so we sort notes by their IDs.
     * @param params The `EmrNotesService.EmrNotesGetHistoryNotesForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetHistoryNotesForPatientResponse(
        params: EmrNotesService.EmrNotesGetHistoryNotesForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/History`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableNote>;
            }),
        );
    }
    /**
     * We don't yet have a way to track modification dates so we sort notes by their IDs.
     * @param params The `EmrNotesService.EmrNotesGetHistoryNotesForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesGetHistoryNotesForPatient(
        params: EmrNotesService.EmrNotesGetHistoryNotesForPatientParams,
    ): __Observable<ApiResponseIEnumerableNote> {
        return this.EmrNotesGetHistoryNotesForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableNote));
    }

    /**
     * We don't yet have a way to track modification dates so we sort notes by their IDs.
     * @param params The `EmrNotesService.EmrNotesGetHistoryNotesParams` containing the following parameters:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User identifier
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * @return OK.
     */
    EmrNotesGetHistoryNotesResponse(
        params: EmrNotesService.EmrNotesGetHistoryNotesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.includeMultimedia != null) __params = __params.set('includeMultimedia', params.includeMultimedia.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/History`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableNote>;
            }),
        );
    }
    /**
     * We don't yet have a way to track modification dates so we sort notes by their IDs.
     * @param params The `EmrNotesService.EmrNotesGetHistoryNotesParams` containing the following parameters:
     *
     * - `appointmentId`: Appointment identifier
     *
     * - `userId`: User identifier
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * @return OK.
     */
    EmrNotesGetHistoryNotes(params: EmrNotesService.EmrNotesGetHistoryNotesParams): __Observable<ApiResponseIEnumerableNote> {
        return this.EmrNotesGetHistoryNotesResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableNote));
    }

    /**
     * We don't yet have a way to track modification dates so we sort notes by their IDs.
     * @param params The `EmrNotesService.EmrNotesGetAllHistoryNotesForPatientParams` containing the following parameters:
     *
     * - `patientId`: Patient identifier
     *
     * - `includeOnlySubmitted`: if set to true [include only submitted].
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * @return OK.
     */
    EmrNotesGetAllHistoryNotesForPatientResponse(
        params: EmrNotesService.EmrNotesGetAllHistoryNotesForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeOnlySubmitted != null) __params = __params.set('includeOnlySubmitted', params.includeOnlySubmitted.toString());
        if (params.includeMultimedia != null) __params = __params.set('includeMultimedia', params.includeMultimedia.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/${params.patientId}/EmrNotes/History`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableNote>;
            }),
        );
    }
    /**
     * We don't yet have a way to track modification dates so we sort notes by their IDs.
     * @param params The `EmrNotesService.EmrNotesGetAllHistoryNotesForPatientParams` containing the following parameters:
     *
     * - `patientId`: Patient identifier
     *
     * - `includeOnlySubmitted`: if set to true [include only submitted].
     *
     * - `includeMultimedia`: Include multimedia?
     *
     * @return OK.
     */
    EmrNotesGetAllHistoryNotesForPatient(
        params: EmrNotesService.EmrNotesGetAllHistoryNotesForPatientParams,
    ): __Observable<ApiResponseIEnumerableNote> {
        return this.EmrNotesGetAllHistoryNotesForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableNote));
    }

    /**
     * User can only update sections of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUpdateSectionForPatientParams` containing the following parameters:
     *
     * - `section`: Section to update
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUpdateSectionForPatientResponse(
        params: EmrNotesService.EmrNotesUpdateSectionForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.section;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/UpdateSection`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * User can only update sections of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUpdateSectionForPatientParams` containing the following parameters:
     *
     * - `section`: Section to update
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUpdateSectionForPatient(params: EmrNotesService.EmrNotesUpdateSectionForPatientParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesUpdateSectionForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesUpdateLockForEhrNoteParams` containing the following parameters:
     *
     * - `updateEhrLockRequest`: the update EHR note request
     *
     * - `noteId`: the note identifier
     *
     * @return OK
     */
    EmrNotesUpdateLockForEhrNoteResponse(
        params: EmrNotesService.EmrNotesUpdateLockForEhrNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.updateEhrLockRequest;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/EmrNotes/${params.noteId}/Lock`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesUpdateLockForEhrNoteParams` containing the following parameters:
     *
     * - `updateEhrLockRequest`: the update EHR note request
     *
     * - `noteId`: the note identifier
     *
     * @return OK
     */
    EmrNotesUpdateLockForEhrNote(params: EmrNotesService.EmrNotesUpdateLockForEhrNoteParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesUpdateLockForEhrNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesUpdateIsSharedWithPatientForEhrNoteParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `noteId`: the note identifier
     *
     * - `isSharedWithPatient`: The is Shared With Patient.
     *
     * @return OK
     */
    EmrNotesUpdateIsSharedWithPatientForEhrNoteResponse(
        params: EmrNotesService.EmrNotesUpdateIsSharedWithPatientForEhrNoteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());

        if (params.isSharedWithPatient != null) __params = __params.set('isSharedWithPatient', params.isSharedWithPatient.toString());
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/EhrNotes/${params.noteId}/IsSharedWithPatient`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesUpdateIsSharedWithPatientForEhrNoteParams` containing the following parameters:
     *
     * - `userId`: The user Id.
     *
     * - `noteId`: the note identifier
     *
     * - `isSharedWithPatient`: The is Shared With Patient.
     *
     * @return OK
     */
    EmrNotesUpdateIsSharedWithPatientForEhrNote(
        params: EmrNotesService.EmrNotesUpdateIsSharedWithPatientForEhrNoteParams,
    ): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesUpdateIsSharedWithPatientForEhrNoteResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param noteId the note identifier
     * @return OK
     */
    EmrNotesUpdateIsViewedByPatientForEhrNoteResponse(noteId: number): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/EhrNotes/${noteId}/IsViewedByPatient`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param noteId the note identifier
     * @return OK
     */
    EmrNotesUpdateIsViewedByPatientForEhrNote(noteId: number): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesUpdateIsViewedByPatientForEhrNoteResponse(noteId).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * User can only update sections of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUpdateSectionForAppointmentParams` containing the following parameters:
     *
     * - `section`: Section to update
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUpdateSectionForAppointmentResponse(
        params: EmrNotesService.EmrNotesUpdateSectionForAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.section;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/UpdateSection`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * User can only update sections of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUpdateSectionForAppointmentParams` containing the following parameters:
     *
     * - `section`: Section to update
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUpdateSectionForAppointment(
        params: EmrNotesService.EmrNotesUpdateSectionForAppointmentParams,
    ): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesUpdateSectionForAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * User can only update sections of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUpdateSectionParams` containing the following parameters:
     *
     * - `section`: Section to update
     *
     * - `noteId`: Note identifier
     *
     * @return OK.
     */
    EmrNotesUpdateSectionResponse(
        params: EmrNotesService.EmrNotesUpdateSectionParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = params.section;

        let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/EmrNotes/${params.noteId}/UpdateSection`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * User can only update sections of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUpdateSectionParams` containing the following parameters:
     *
     * - `section`: Section to update
     *
     * - `noteId`: Note identifier
     *
     * @return OK.
     */
    EmrNotesUpdateSection(params: EmrNotesService.EmrNotesUpdateSectionParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesUpdateSectionResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * User can only upload images to the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUploadImagesForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `ImportImage`: Upload image file
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUploadImagesForPatientResponse(
        params: EmrNotesService.EmrNotesUploadImagesForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteImageResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.ImportImage != null) {
            __formData.append('ImportImage', params.ImportImage as string | Blob);
        }
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/UploadImages`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteImageResponse>;
            }),
        );
    }
    /**
     * User can only upload images to the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUploadImagesForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `ImportImage`: Upload image file
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUploadImagesForPatient(
        params: EmrNotesService.EmrNotesUploadImagesForPatientParams,
    ): __Observable<ApiResponseNoteImageResponse> {
        return this.EmrNotesUploadImagesForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteImageResponse));
    }

    /**
     * User can only upload images to the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUploadImagesForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `ImportImage`: Upload image file
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUploadImagesForAppointmentResponse(
        params: EmrNotesService.EmrNotesUploadImagesForAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteImageResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.ImportImage != null) {
            __formData.append('ImportImage', params.ImportImage as string | Blob);
        }
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/UploadImages`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteImageResponse>;
            }),
        );
    }
    /**
     * User can only upload images to the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUploadImagesForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `appointmentId`:
     *
     * - `ImportImage`: Upload image file
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesUploadImagesForAppointment(
        params: EmrNotesService.EmrNotesUploadImagesForAppointmentParams,
    ): __Observable<ApiResponseNoteImageResponse> {
        return this.EmrNotesUploadImagesForAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteImageResponse));
    }

    /**
     * User can only upload images to the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUploadImagesParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `ImportImage`: Upload image file
     *
     * @return OK.
     */
    EmrNotesUploadImagesResponse(
        params: EmrNotesService.EmrNotesUploadImagesParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteImageResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.ImportImage != null) {
            __formData.append('ImportImage', params.ImportImage as string | Blob);
        }
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${params.noteId}/UploadImages`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteImageResponse>;
            }),
        );
    }
    /**
     * User can only upload images to the Draft Note.
     * @param params The `EmrNotesService.EmrNotesUploadImagesParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `ImportImage`: Upload image file
     *
     * @return OK.
     */
    EmrNotesUploadImages(params: EmrNotesService.EmrNotesUploadImagesParams): __Observable<ApiResponseNoteImageResponse> {
        return this.EmrNotesUploadImagesResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteImageResponse));
    }

    /**
     * User can only delete images of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesDeleteImageForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `imageId`: Image identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteImageForPatientResponse(
        params: EmrNotesService.EmrNotesDeleteImageForPatientParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl +
                `/api/Patients/${params.patientId}/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/Images/${params.imageId}/Delete`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * User can only delete images of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesDeleteImageForPatientParams` containing the following parameters:
     *
     * - `patientId`:
     *
     * - `noteId`: Note identifier
     *
     * - `imageId`: Image identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteImageForPatient(params: EmrNotesService.EmrNotesDeleteImageForPatientParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesDeleteImageForPatientResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * User can only delete images of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesDeleteImageForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `imageId`: Image identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteImageForAppointmentResponse(
        params: EmrNotesService.EmrNotesDeleteImageForAppointmentParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Appointments/${params.appointmentId}/EmrNotes/${params.noteId}/Images/${params.imageId}/Delete`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * User can only delete images of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesDeleteImageForAppointmentParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `imageId`: Image identifier
     *
     * - `appointmentId`:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK.
     */
    EmrNotesDeleteImageForAppointment(
        params: EmrNotesService.EmrNotesDeleteImageForAppointmentParams,
    ): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesDeleteImageForAppointmentResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * User can only delete images of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesDeleteImageParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `imageId`: Image identifier
     *
     * @return OK.
     */
    EmrNotesDeleteImageResponse(
        params: EmrNotesService.EmrNotesDeleteImageParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${params.noteId}/Images/${params.imageId}/Delete`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * User can only delete images of the Draft Note.
     * @param params The `EmrNotesService.EmrNotesDeleteImageParams` containing the following parameters:
     *
     * - `noteId`: Note identifier
     *
     * - `imageId`: Image identifier
     *
     * @return OK.
     */
    EmrNotesDeleteImage(params: EmrNotesService.EmrNotesDeleteImageParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesDeleteImageResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesDeleteMultimediaFileParams` containing the following parameters:
     *
     * - `noteId`: The note identifier.
     *
     * - `fileId`: The file identifier.
     *
     * @return OK
     */
    EmrNotesDeleteMultimediaFileResponse(
        params: EmrNotesService.EmrNotesDeleteMultimediaFileParams,
    ): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/EmrNotes/${params.noteId}/Multimedia/${params.fileId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesDeleteMultimediaFileParams` containing the following parameters:
     *
     * - `noteId`: The note identifier.
     *
     * - `fileId`: The file identifier.
     *
     * @return OK
     */
    EmrNotesDeleteMultimediaFile(params: EmrNotesService.EmrNotesDeleteMultimediaFileParams): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesDeleteMultimediaFileResponse(params).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param params The `EmrNotesService.EmrNotesGetDraftNotesObsoleteParams` containing the following parameters:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK
     */
    EmrNotesGetDraftNotesObsoleteResponse(
        params: EmrNotesService.EmrNotesGetDraftNotesObsoleteParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableDraftNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.userId != null) __params = __params.set('userId', params.userId.toString());
        if (params.token != null) __params = __params.set('token', params.token.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Patients/EmrNotes/UserDraft`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableDraftNote>;
            }),
        );
    }
    /**
     * @param params The `EmrNotesService.EmrNotesGetDraftNotesObsoleteParams` containing the following parameters:
     *
     * - `userId`: User ID[Obsolete]
     *
     * - `token`: Authentication token matching User ID[Obsolete]
     *
     * @return OK
     */
    EmrNotesGetDraftNotesObsolete(
        params: EmrNotesService.EmrNotesGetDraftNotesObsoleteParams,
    ): __Observable<ApiResponseIEnumerableDraftNote> {
        return this.EmrNotesGetDraftNotesObsoleteResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableDraftNote));
    }

    /**
     * @return OK
     */
    EmrNotesGetDraftNotesResponse(): __Observable<__StrictHttpResponse<ApiResponseIEnumerableDraftNote>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/EmrNotes/UserDraftNotes`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableDraftNote>;
            }),
        );
    }
    /**
     * @return OK
     */
    EmrNotesGetDraftNotes(): __Observable<ApiResponseIEnumerableDraftNote> {
        return this.EmrNotesGetDraftNotesResponse().pipe(__map((_r) => _r.body as ApiResponseIEnumerableDraftNote));
    }

    /**
     * @param noteId The note identifier.
     * @return OK
     */
    EmrNotesPostResponse(noteId: number): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${noteId}/Post`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param noteId The note identifier.
     * @return OK
     */
    EmrNotesPost(noteId: number): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesPostResponse(noteId).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param noteId The note identifier.
     * @return OK
     */
    EmrNotesSubmitNoteResponse(noteId: number): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${noteId}/SubmitNote`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param noteId The note identifier.
     * @return OK
     */
    EmrNotesSubmitNote(noteId: number): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesSubmitNoteResponse(noteId).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }

    /**
     * @param noteId The note identifier.
     * @return OK
     */
    EmrNotesReviseResponse(noteId: number): __Observable<__StrictHttpResponse<ApiResponseNoteResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/EmrNotes/${noteId}/Revise`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseNoteResponse>;
            }),
        );
    }
    /**
     * @param noteId The note identifier.
     * @return OK
     */
    EmrNotesRevise(noteId: number): __Observable<ApiResponseNoteResponse> {
        return this.EmrNotesReviseResponse(noteId).pipe(__map((_r) => _r.body as ApiResponseNoteResponse));
    }
}

module EmrNotesService {
    /**
     * Parameters for EmrNotesCreateNewNoteForPatient
     */
    export interface EmrNotesCreateNewNoteForPatientParams {
        /**
         * Contains additional information
         */
        request: CreateNewNoteRequest;
        patientId: string;

        /**
         * Appointment ID
         */
        appointmentId: number;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesCreateNewNote
     */
    export interface EmrNotesCreateNewNoteParams {
        /**
         * Contains additional information
         */
        request: CreateNewNoteRequest;

        /**
         * Appointment ID
         */
        appointmentId: number;
    }

    /**
     * Parameters for EmrNotesGetNoteForPatient
     */
    export interface EmrNotesGetNoteForPatientParams {
        patientId: string;

        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesGetNoteForAppointment
     */
    export interface EmrNotesGetNoteForAppointmentParams {
        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesGetNote
     */
    export interface EmrNotesGetNoteParams {
        /**
         * Note identifier
         */
        noteId: number;

        /**
         * Include multimedia?
         */
        includeMultimedia?: boolean;

        /**
         * The include Allowed Actions.
         */
        includeAllowedActions?: boolean;
    }

    /**
     * Parameters for EmrNotesDeleteForPatient
     */
    export interface EmrNotesDeleteForPatientParams {
        patientId: string;

        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesDeleteForAppointment
     */
    export interface EmrNotesDeleteForAppointmentParams {
        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesCreateRevisionForPatient
     */
    export interface EmrNotesCreateRevisionForPatientParams {
        patientId: string;

        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesCreateRevisionForAppointment
     */
    export interface EmrNotesCreateRevisionForAppointmentParams {
        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesSubmitForPatient
     */
    export interface EmrNotesSubmitForPatientParams {
        patientId: string;

        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesSubmitForAppointment
     */
    export interface EmrNotesSubmitForAppointmentParams {
        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesGetLatestNoteForPatient
     */
    export interface EmrNotesGetLatestNoteForPatientParams {
        patientId: string;

        /**
         * Appointment identifier
         */
        appointmentId: number;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesGetLatestNote
     */
    export interface EmrNotesGetLatestNoteParams {
        /**
         * Appointment identifier
         */
        appointmentId: number;

        /**
         * The user identifier.
         */
        userId?: number;

        /**
         * Include multimedia?
         */
        includeMultimedia?: boolean;

        /**
         * The include Allowed Actions.
         */
        includeAllowedActions?: boolean;
    }

    /**
     * Parameters for EmrNotesGetLatestDraftForPatient
     */
    export interface EmrNotesGetLatestDraftForPatientParams {
        patientId: string;

        /**
         * Appointment identifier
         */
        appointmentId: number;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesGetLatestDraft
     */
    export interface EmrNotesGetLatestDraftParams {
        /**
         * Appointment identifier
         */
        appointmentId: number;

        /**
         * The user Id.
         */
        userId?: number;

        /**
         * Include multimedia?
         */
        includeMultimedia?: boolean;

        /**
         * The include Allowed Actions.
         */
        includeAllowedActions?: boolean;
    }

    /**
     * Parameters for EmrNotesGetHistoryNotesForPatient
     */
    export interface EmrNotesGetHistoryNotesForPatientParams {
        patientId: string;

        /**
         * Appointment identifier
         */
        appointmentId: number;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesGetHistoryNotes
     */
    export interface EmrNotesGetHistoryNotesParams {
        /**
         * Appointment identifier
         */
        appointmentId: number;

        /**
         * User identifier
         */
        userId?: number;

        /**
         * Include multimedia?
         */
        includeMultimedia?: boolean;
    }

    /**
     * Parameters for EmrNotesGetAllHistoryNotesForPatient
     */
    export interface EmrNotesGetAllHistoryNotesForPatientParams {
        /**
         * Patient identifier
         */
        patientId: number;

        /**
         * if set to true [include only submitted].
         */
        includeOnlySubmitted?: boolean;

        /**
         * Include multimedia?
         */
        includeMultimedia?: boolean;
    }

    /**
     * Parameters for EmrNotesUpdateSectionForPatient
     */
    export interface EmrNotesUpdateSectionForPatientParams {
        /**
         * Section to update
         */
        section: Section;
        patientId: string;

        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesUpdateLockForEhrNote
     */
    export interface EmrNotesUpdateLockForEhrNoteParams {
        /**
         * the update EHR note request
         */
        updateEhrLockRequest: UpdateEhrLockRequest;

        /**
         * the note identifier
         */
        noteId: number;
    }

    /**
     * Parameters for EmrNotesUpdateIsSharedWithPatientForEhrNote
     */
    export interface EmrNotesUpdateIsSharedWithPatientForEhrNoteParams {
        /**
         * The user Id.
         */
        userId: number;

        /**
         * the note identifier
         */
        noteId: number;

        /**
         * The is Shared With Patient.
         */
        isSharedWithPatient: boolean;
    }

    /**
     * Parameters for EmrNotesUpdateSectionForAppointment
     */
    export interface EmrNotesUpdateSectionForAppointmentParams {
        /**
         * Section to update
         */
        section: Section;

        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesUpdateSection
     */
    export interface EmrNotesUpdateSectionParams {
        /**
         * Section to update
         */
        section: Section;

        /**
         * Note identifier
         */
        noteId: number;
    }

    /**
     * Parameters for EmrNotesUploadImagesForPatient
     */
    export interface EmrNotesUploadImagesForPatientParams {
        patientId: string;

        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * Upload image file
         */
        ImportImage: Blob;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesUploadImagesForAppointment
     */
    export interface EmrNotesUploadImagesForAppointmentParams {
        /**
         * Note identifier
         */
        noteId: number;
        appointmentId: string;

        /**
         * Upload image file
         */
        ImportImage: Blob;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesUploadImages
     */
    export interface EmrNotesUploadImagesParams {
        /**
         * Note identifier
         */
        noteId: number;

        /**
         * Upload image file
         */
        ImportImage: Blob;
    }

    /**
     * Parameters for EmrNotesDeleteImageForPatient
     */
    export interface EmrNotesDeleteImageForPatientParams {
        patientId: string;

        /**
         * Note identifier
         */
        noteId: number;

        /**
         * Image identifier
         */
        imageId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesDeleteImageForAppointment
     */
    export interface EmrNotesDeleteImageForAppointmentParams {
        /**
         * Note identifier
         */
        noteId: number;

        /**
         * Image identifier
         */
        imageId: number;
        appointmentId: string;

        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }

    /**
     * Parameters for EmrNotesDeleteImage
     */
    export interface EmrNotesDeleteImageParams {
        /**
         * Note identifier
         */
        noteId: number;

        /**
         * Image identifier
         */
        imageId: number;
    }

    /**
     * Parameters for EmrNotesDeleteMultimediaFile
     */
    export interface EmrNotesDeleteMultimediaFileParams {
        /**
         * The note identifier.
         */
        noteId: number;

        /**
         * The file identifier.
         */
        fileId: string;
    }

    /**
     * Parameters for EmrNotesGetDraftNotesObsolete
     */
    export interface EmrNotesGetDraftNotesObsoleteParams {
        /**
         * User ID[Obsolete]
         */
        userId?: number;

        /**
         * Authentication token matching User ID[Obsolete]
         */
        token?: string;
    }
}

export { EmrNotesService };
