/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponsePracticeSettings } from '../models/api-response-practice-settings';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { PracticeSettingsRequest } from '../models/practice-settings-request';
import { TippingSettingsRequest } from '../models/tipping-settings-request';
import { ApiResponsePatientSettings } from '../models/api-response-patient-settings';
import { ApiResponseLocationPaymentSettings } from '../models/api-response-location-payment-settings';
import { LocationPaymentSettingsRequest } from '../models/location-payment-settings-request';
import { ApiResponsePaymentIntegrationSettings } from '../models/api-response-payment-integration-settings';
import { PaymentIntegrationSettingsRequest } from '../models/payment-integration-settings-request';
@Injectable({
    providedIn: 'root',
})
class PracticeSettingsService extends __BaseService {
    static readonly GetPracticeSettingsPath = '/PracticeSettings';
    static readonly UpdatePracticeSettingsPath = '/PracticeSettings';
    static readonly GetPracticeSettingsByTenantPath = '/Tenants/{tenantId}/PracticeSettings';
    static readonly UpdatePracticeSettingsByTenantPath = '/Tenants/{tenantId}/PracticeSettings';
    static readonly UpdateTippingSettingsPath = '/TippingSettings';
    static readonly UpdateTippingSettingsByTenantPath = '/Tenants/{tenantId}/TippingSettings';
    static readonly GetPatientSettingsPath = '/PatientSettings';
    static readonly GetLocationPaymentSettingsPath = '/Tenants/{tenantId}/PracticeSettings/LocationPaymentSettings';
    static readonly ResetLocationPaymentSettingsPath = '/Tenants/{tenantId}/PracticeSettings/LocationPaymentSettings';
    static readonly GetPaymentIntegrationSettingsPath = '/Tenants/{tenantId}/PracticeSettings/PaymentIntegrationSettings';
    static readonly UpdatePaymentIntegrationSettingsAsyncPath = '/Tenants/{tenantId}/PracticeSettings/PaymentIntegrationSettings';
    static readonly CreatePaymentIntegrationSettingsAsyncPath = '/Tenants/{tenantId}/PracticeSettings/PaymentIntegrationSettings';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Gets the practice settings.
     * @return Success
     */
    GetPracticeSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponsePracticeSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PracticeSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePracticeSettings>;
            }),
        );
    }
    /**
     * Gets the practice settings.
     * @return Success
     */
    GetPracticeSettings(): __Observable<ApiResponsePracticeSettings> {
        return this.GetPracticeSettingsResponse().pipe(__map((_r) => _r.body as ApiResponsePracticeSettings));
    }

    /**
     * Modifies the PracticeSettings.
     * @param practiceSettingsUpdateRequest The PracticeSettings update request.
     * @return Success
     */
    UpdatePracticeSettingsResponse(
        practiceSettingsUpdateRequest?: PracticeSettingsRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = practiceSettingsUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/PracticeSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies the PracticeSettings.
     * @param practiceSettingsUpdateRequest The PracticeSettings update request.
     * @return Success
     */
    UpdatePracticeSettings(practiceSettingsUpdateRequest?: PracticeSettingsRequest): __Observable<ApiResponseOkResponse> {
        return this.UpdatePracticeSettingsResponse(practiceSettingsUpdateRequest).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the practice settings by tenant ID.
     * @param tenantId Tenant id.
     * @return Success
     */
    GetPracticeSettingsByTenantResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponsePracticeSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Tenants/${encodeURIComponent(String(tenantId))}/PracticeSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePracticeSettings>;
            }),
        );
    }
    /**
     * Gets the practice settings by tenant ID.
     * @param tenantId Tenant id.
     * @return Success
     */
    GetPracticeSettingsByTenant(tenantId: number): __Observable<ApiResponsePracticeSettings> {
        return this.GetPracticeSettingsByTenantResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponsePracticeSettings));
    }

    /**
     * Modifies the PracticeSettings.
     * @param params The `PracticeSettingsService.UpdatePracticeSettingsByTenantParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `practiceSettingsUpdateRequest`: The PracticeSettings update request.
     *
     * @return Success
     */
    UpdatePracticeSettingsByTenantResponse(
        params: PracticeSettingsService.UpdatePracticeSettingsByTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.practiceSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/PracticeSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modifies the PracticeSettings.
     * @param params The `PracticeSettingsService.UpdatePracticeSettingsByTenantParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `practiceSettingsUpdateRequest`: The PracticeSettings update request.
     *
     * @return Success
     */
    UpdatePracticeSettingsByTenant(
        params: PracticeSettingsService.UpdatePracticeSettingsByTenantParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdatePracticeSettingsByTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Updates the Tipping Settings.
     * @param tippingSettingsUpdateRequest The Tipping Settings update request.
     * @return Success
     */
    UpdateTippingSettingsResponse(
        tippingSettingsUpdateRequest?: TippingSettingsRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = tippingSettingsUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/TippingSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Updates the Tipping Settings.
     * @param tippingSettingsUpdateRequest The Tipping Settings update request.
     * @return Success
     */
    UpdateTippingSettings(tippingSettingsUpdateRequest?: TippingSettingsRequest): __Observable<ApiResponseOkResponse> {
        return this.UpdateTippingSettingsResponse(tippingSettingsUpdateRequest).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Updates the tipping settings by tenant asynchronous.
     * @param params The `PracticeSettingsService.UpdateTippingSettingsByTenantParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `tippingSettingsUpdateRequest`: The tipping settings update request.
     *
     * @return Success
     */
    UpdateTippingSettingsByTenantResponse(
        params: PracticeSettingsService.UpdateTippingSettingsByTenantParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.tippingSettingsUpdateRequest;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/TippingSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Updates the tipping settings by tenant asynchronous.
     * @param params The `PracticeSettingsService.UpdateTippingSettingsByTenantParams` containing the following parameters:
     *
     * - `tenantId`: The tenant identifier.
     *
     * - `tippingSettingsUpdateRequest`: The tipping settings update request.
     *
     * @return Success
     */
    UpdateTippingSettingsByTenant(
        params: PracticeSettingsService.UpdateTippingSettingsByTenantParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdateTippingSettingsByTenantResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets the patient settings.
     * @return Success
     */
    GetPatientSettingsResponse(): __Observable<__StrictHttpResponse<ApiResponsePatientSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/PatientSettings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePatientSettings>;
            }),
        );
    }
    /**
     * Gets the patient settings.
     * @return Success
     */
    GetPatientSettings(): __Observable<ApiResponsePatientSettings> {
        return this.GetPatientSettingsResponse().pipe(__map((_r) => _r.body as ApiResponsePatientSettings));
    }

    /**
     * Get the payment settings for all locations.
     * @param tenantId The tenant Id.
     * @return Success
     */
    GetLocationPaymentSettingsResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponseLocationPaymentSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(tenantId))}/PracticeSettings/LocationPaymentSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocationPaymentSettings>;
            }),
        );
    }
    /**
     * Get the payment settings for all locations.
     * @param tenantId The tenant Id.
     * @return Success
     */
    GetLocationPaymentSettings(tenantId: number): __Observable<ApiResponseLocationPaymentSettings> {
        return this.GetLocationPaymentSettingsResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponseLocationPaymentSettings));
    }

    /**
     * Completely reset the location payment settings to the specified value.
     * @param params The `PracticeSettingsService.ResetLocationPaymentSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * - `modifiedByUserId`: The modified By User Id.
     *
     * @return Success
     */
    ResetLocationPaymentSettingsResponse(
        params: PracticeSettingsService.ResetLocationPaymentSettingsParams,
    ): __Observable<__StrictHttpResponse<ApiResponseLocationPaymentSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        if (params.modifiedByUserId != null) __params = __params.set('modifiedByUserId', params.modifiedByUserId.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/PracticeSettings/LocationPaymentSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseLocationPaymentSettings>;
            }),
        );
    }
    /**
     * Completely reset the location payment settings to the specified value.
     * @param params The `PracticeSettingsService.ResetLocationPaymentSettingsParams` containing the following parameters:
     *
     * - `tenantId`: The tenant id.
     *
     * - `request`: The request.
     *
     * - `modifiedByUserId`: The modified By User Id.
     *
     * @return Success
     */
    ResetLocationPaymentSettings(
        params: PracticeSettingsService.ResetLocationPaymentSettingsParams,
    ): __Observable<ApiResponseLocationPaymentSettings> {
        return this.ResetLocationPaymentSettingsResponse(params).pipe(__map((_r) => _r.body as ApiResponseLocationPaymentSettings));
    }

    /**
     * Get the payment integration settings.
     * @param tenantId The tenant Id.
     * @return Success
     */
    GetPaymentIntegrationSettingsResponse(tenantId: number): __Observable<__StrictHttpResponse<ApiResponsePaymentIntegrationSettings>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(tenantId))}/PracticeSettings/PaymentIntegrationSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponsePaymentIntegrationSettings>;
            }),
        );
    }
    /**
     * Get the payment integration settings.
     * @param tenantId The tenant Id.
     * @return Success
     */
    GetPaymentIntegrationSettings(tenantId: number): __Observable<ApiResponsePaymentIntegrationSettings> {
        return this.GetPaymentIntegrationSettingsResponse(tenantId).pipe(__map((_r) => _r.body as ApiResponsePaymentIntegrationSettings));
    }

    /**
     * Update payment integration settings.
     * @param params The `PracticeSettingsService.UpdatePaymentIntegrationSettingsAsyncParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `request`: The payment integration settings request.
     *
     * - `modifiedByUserId`: The modified By User Id.
     *
     * @return Success
     */
    UpdatePaymentIntegrationSettingsAsyncResponse(
        params: PracticeSettingsService.UpdatePaymentIntegrationSettingsAsyncParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        if (params.modifiedByUserId != null) __params = __params.set('modifiedByUserId', params.modifiedByUserId.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/PracticeSettings/PaymentIntegrationSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Update payment integration settings.
     * @param params The `PracticeSettingsService.UpdatePaymentIntegrationSettingsAsyncParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `request`: The payment integration settings request.
     *
     * - `modifiedByUserId`: The modified By User Id.
     *
     * @return Success
     */
    UpdatePaymentIntegrationSettingsAsync(
        params: PracticeSettingsService.UpdatePaymentIntegrationSettingsAsyncParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.UpdatePaymentIntegrationSettingsAsyncResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Create payment integration settings.
     * @param params The `PracticeSettingsService.CreatePaymentIntegrationSettingsAsyncParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `request`: The payment integration settings request.
     *
     * - `modifiedByUserId`: The modified By User Id.
     *
     * @return Success
     */
    CreatePaymentIntegrationSettingsAsyncResponse(
        params: PracticeSettingsService.CreatePaymentIntegrationSettingsAsyncParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        if (params.modifiedByUserId != null) __params = __params.set('modifiedByUserId', params.modifiedByUserId.toString());
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/Tenants/${encodeURIComponent(String(params.tenantId))}/PracticeSettings/PaymentIntegrationSettings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Create payment integration settings.
     * @param params The `PracticeSettingsService.CreatePaymentIntegrationSettingsAsyncParams` containing the following parameters:
     *
     * - `tenantId`: The tenant Id.
     *
     * - `request`: The payment integration settings request.
     *
     * - `modifiedByUserId`: The modified By User Id.
     *
     * @return Success
     */
    CreatePaymentIntegrationSettingsAsync(
        params: PracticeSettingsService.CreatePaymentIntegrationSettingsAsyncParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.CreatePaymentIntegrationSettingsAsyncResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module PracticeSettingsService {
    /**
     * Parameters for UpdatePracticeSettingsByTenant
     */
    export interface UpdatePracticeSettingsByTenantParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The PracticeSettings update request.
         */
        practiceSettingsUpdateRequest?: PracticeSettingsRequest;
    }

    /**
     * Parameters for UpdateTippingSettingsByTenant
     */
    export interface UpdateTippingSettingsByTenantParams {
        /**
         * The tenant identifier.
         */
        tenantId: number;

        /**
         * The tipping settings update request.
         */
        tippingSettingsUpdateRequest?: TippingSettingsRequest;
    }

    /**
     * Parameters for ResetLocationPaymentSettings
     */
    export interface ResetLocationPaymentSettingsParams {
        /**
         * The tenant id.
         */
        tenantId: number;

        /**
         * The request.
         */
        request?: Array<LocationPaymentSettingsRequest>;

        /**
         * The modified By User Id.
         */
        modifiedByUserId?: number;
    }

    /**
     * Parameters for UpdatePaymentIntegrationSettingsAsync
     */
    export interface UpdatePaymentIntegrationSettingsAsyncParams {
        /**
         * The tenant Id.
         */
        tenantId: number;

        /**
         * The payment integration settings request.
         */
        request?: Array<PaymentIntegrationSettingsRequest>;

        /**
         * The modified By User Id.
         */
        modifiedByUserId?: number;
    }

    /**
     * Parameters for CreatePaymentIntegrationSettingsAsync
     */
    export interface CreatePaymentIntegrationSettingsAsyncParams {
        /**
         * The tenant Id.
         */
        tenantId: number;

        /**
         * The payment integration settings request.
         */
        request?: PaymentIntegrationSettingsRequest;

        /**
         * The modified By User Id.
         */
        modifiedByUserId?: number;
    }
}

export { PracticeSettingsService };
