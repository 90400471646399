/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseUnit } from '../models/api-response-unit';
import { UnitCreateRequest } from '../models/unit-create-request';
import { ApiResponseICollectionUnit } from '../models/api-response-icollection-unit';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { UnitUpdateRequest } from '../models/unit-update-request';
@Injectable({
    providedIn: 'root',
})
class UnitsService extends __BaseService {
    static readonly CreateUnitPath = '/Units';
    static readonly GetUnitsPath = '/Units';
    static readonly DeleteUnitPath = '/Units/{unitIdToDelete}';
    static readonly GetUnitByIdPath = '/Units/{unitId}';
    static readonly UpdateUnitPath = '/Units/{unitId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Creates a new Unit.
     * @param unitCreateRequest The  Unit create request.
     * @return Success
     */
    CreateUnitResponse(unitCreateRequest?: UnitCreateRequest): __Observable<__StrictHttpResponse<ApiResponseUnit>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = unitCreateRequest;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/Units`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseUnit>;
            }),
        );
    }
    /**
     * Creates a new Unit.
     * @param unitCreateRequest The  Unit create request.
     * @return Success
     */
    CreateUnit(unitCreateRequest?: UnitCreateRequest): __Observable<ApiResponseUnit> {
        return this.CreateUnitResponse(unitCreateRequest).pipe(__map((_r) => _r.body as ApiResponseUnit));
    }

    /**
     * Get all units.
     * @return Success
     */
    GetUnitsResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionUnit>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/Units`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionUnit>;
            }),
        );
    }
    /**
     * Get all units.
     * @return Success
     */
    GetUnits(): __Observable<ApiResponseICollectionUnit> {
        return this.GetUnitsResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionUnit));
    }

    /**
     * Disables a unit.
     * @param unitIdToDelete The unit ID to soft delete.
     * @return Success
     */
    DeleteUnitResponse(unitIdToDelete: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/Units/${encodeURIComponent(String(unitIdToDelete))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Disables a unit.
     * @param unitIdToDelete The unit ID to soft delete.
     * @return Success
     */
    DeleteUnit(unitIdToDelete: number): __Observable<ApiResponseOkResponse> {
        return this.DeleteUnitResponse(unitIdToDelete).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Get a particular unit ID.
     * @param unitId The unit id.
     * @return Success
     */
    GetUnitByIdResponse(unitId: number): __Observable<__StrictHttpResponse<ApiResponseUnit>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/Units/${encodeURIComponent(String(unitId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseUnit>;
            }),
        );
    }
    /**
     * Get a particular unit ID.
     * @param unitId The unit id.
     * @return Success
     */
    GetUnitById(unitId: number): __Observable<ApiResponseUnit> {
        return this.GetUnitByIdResponse(unitId).pipe(__map((_r) => _r.body as ApiResponseUnit));
    }

    /**
     * Modify a Unit.
     * @param params The `UnitsService.UpdateUnitParams` containing the following parameters:
     *
     * - `unitId`: Unit id to modify.
     *
     * - `unitUpdateRequest`: Unit name to update.
     *
     * @return Success
     */
    UpdateUnitResponse(params: UnitsService.UpdateUnitParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.unitUpdateRequest;
        let req = new HttpRequest<any>('PUT', this.rootUrl + `/Units/${encodeURIComponent(String(params.unitId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Modify a Unit.
     * @param params The `UnitsService.UpdateUnitParams` containing the following parameters:
     *
     * - `unitId`: Unit id to modify.
     *
     * - `unitUpdateRequest`: Unit name to update.
     *
     * @return Success
     */
    UpdateUnit(params: UnitsService.UpdateUnitParams): __Observable<ApiResponseOkResponse> {
        return this.UpdateUnitResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module UnitsService {
    /**
     * Parameters for UpdateUnit
     */
    export interface UpdateUnitParams {
        /**
         * Unit id to modify.
         */
        unitId: number;

        /**
         * Unit name to update.
         */
        unitUpdateRequest?: UnitUpdateRequest;
    }
}

export { UnitsService };
