/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for Api services
 */
@Injectable({
    providedIn: 'root',
})
export class ApiConfiguration {
    rootUrl: string = 'https://dev1.symplast.com/service';
}

export interface ApiConfigurationInterface {
    rootUrl?: string;
}
