import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'ageTitle',
    standalone: true,
})
export class AgeTitlePipe implements PipeTransform {
    transform(birthDate: Date): string {
        const years = moment().diff(birthDate, 'years');

        if (years > 1) {
            return `${years} y.o.`;
        }

        const months = moment().diff(birthDate, 'months');

        if (months > 0) {
            return `${months} m.o.`;
        }

        const weeks = moment().diff(birthDate, 'week');
        const days = moment().diff(birthDate, 'days');

        if (weeks > 0) {
            return `${weeks}w${days % 7}d`;
        }

        return `${days}d`;
    }
}
