<div>
    <div mat-dialog-content>
        <div>
            <h4>Patient Search Tips:</h4>
            <p>You can search for a patient by using any of the following criteria:</p>
            <ul>
                <li>A combination of their First and/or Last Name or "nickname".</li>
                <li>Email or Mobile Phone Number (xxx-xxx-xxxx or xxxxxxxxxx)</li>
                <li>Date of Birth entering (MMDDYYYY or MM/DD/YYYY)</li>
            </ul>
            <h4>Examples:</h4>
            <ul class="examples">
                <li><span>Rob</span>Will find any name that contains 'Rob' such as Robert or Roberta</li>
                <li><span>"Rob"</span>Will find any name matching 'Rob' exactly</li>
                <li><span>04031980</span>Will find anyone whose birthday is April 03, 1980</li>
            </ul>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button color="primary" class="button" [mat-dialog-close]="null">Got It</button>
    </div>
</div>
