import { Selector } from '@ngxs/store';
import { ItemType } from '@symplast/generated-clients/financial';
import { IItemTypesStateModel } from './item-types.model';
import { ItemTypesState } from './item-types.state';

export class ItemTypesSelectors {
    @Selector([ItemTypesState])
    public static loading(state: IItemTypesStateModel): boolean {
        return state.loading;
    }

    @Selector([ItemTypesState])
    public static itemTypes(state: IItemTypesStateModel): ItemType[] {
        return state.itemTypes;
    }

    @Selector([ItemTypesState])
    public static itemTypesIncludeGroup(state: IItemTypesStateModel): ItemType[] {
        return state.itemTypesIncludeGroup;
    }
}
