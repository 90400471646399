/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIReadOnlyCollectionAppointmentType } from '../models/api-response-iread-only-collection-appointment-type';
import { ApiResponseAppointmentType } from '../models/api-response-appointment-type';
import { AppointmentTypeRequest } from '../models/appointment-type-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class AppointmentTypesService extends __BaseService {
  static readonly GetAppointmentTypesPath = '/AppointmentTypes';
  static readonly AddAppointmentTypePath = '/AppointmentTypes';
  static readonly DeleteAppointmentTypePath = '/AppointmentTypes';
  static readonly UpdateAppointmentTypePath = '/AppointmentTypes';
  static readonly GetAppointmentTypeByIdPath = '/AppointmentTypes/{appointmentTypeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get appointment types.
   * @param params The `AppointmentTypesService.GetAppointmentTypesParams` containing the following parameters:
   *
   * - `searchByText`:
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `includedDeleted`: The included Deleted.
   *
   * @return Success
   */
  GetAppointmentTypesResponse(params: AppointmentTypesService.GetAppointmentTypesParams): __Observable<__StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchByText != null) __params = __params.set('searchByText', params.searchByText.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.includedDeleted != null) __params = __params.set('includedDeleted', params.includedDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIReadOnlyCollectionAppointmentType>;
      })
    );
  }
  /**
   * The get appointment types.
   * @param params The `AppointmentTypesService.GetAppointmentTypesParams` containing the following parameters:
   *
   * - `searchByText`:
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `includedDeleted`: The included Deleted.
   *
   * @return Success
   */
  GetAppointmentTypes(params: AppointmentTypesService.GetAppointmentTypesParams): __Observable<ApiResponseIReadOnlyCollectionAppointmentType> {
    return this.GetAppointmentTypesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIReadOnlyCollectionAppointmentType)
    );
  }

  /**
   * Adds the appointment type.
   * @param appointmentTypeRequest The add appointment type request.
   * @return Created
   */
  AddAppointmentTypeResponse(appointmentTypeRequest?: AppointmentTypeRequest): __Observable<__StrictHttpResponse<ApiResponseAppointmentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = appointmentTypeRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/AppointmentTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentType>;
      })
    );
  }
  /**
   * Adds the appointment type.
   * @param appointmentTypeRequest The add appointment type request.
   * @return Created
   */
  AddAppointmentType(appointmentTypeRequest?: AppointmentTypeRequest): __Observable<ApiResponseAppointmentType> {
    return this.AddAppointmentTypeResponse(appointmentTypeRequest).pipe(
      __map(_r => _r.body as ApiResponseAppointmentType)
    );
  }

  /**
   * Deletes the appointment type.
   * @param params The `AppointmentTypesService.DeleteAppointmentTypeParams` containing the following parameters:
   *
   * - `appointmentTypeId`: The appointment type identifier.
   *
   * - `hardDelete`: if set to `true` [hard delete].
   *
   * @return Success
   */
  DeleteAppointmentTypeResponse(params: AppointmentTypesService.DeleteAppointmentTypeParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.appointmentTypeId != null) __params = __params.set('appointmentTypeId', params.appointmentTypeId.toString());
    if (params.hardDelete != null) __params = __params.set('hardDelete', params.hardDelete.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/AppointmentTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Deletes the appointment type.
   * @param params The `AppointmentTypesService.DeleteAppointmentTypeParams` containing the following parameters:
   *
   * - `appointmentTypeId`: The appointment type identifier.
   *
   * - `hardDelete`: if set to `true` [hard delete].
   *
   * @return Success
   */
  DeleteAppointmentType(params: AppointmentTypesService.DeleteAppointmentTypeParams): __Observable<ApiResponseOkResponse> {
    return this.DeleteAppointmentTypeResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates the appointment purpose asynchronous.
   * @param params The `AppointmentTypesService.UpdateAppointmentTypeParams` containing the following parameters:
   *
   * - `appointmentTypeId`: The appointment type identifier.
   *
   * - `updateAppointmentTypeRequest`: The update appointment type request.
   *
   * @return Success
   */
  UpdateAppointmentTypeResponse(params: AppointmentTypesService.UpdateAppointmentTypeParams): __Observable<__StrictHttpResponse<ApiResponseAppointmentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.appointmentTypeId != null) __params = __params.set('appointmentTypeId', params.appointmentTypeId.toString());
    __body = params.updateAppointmentTypeRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/AppointmentTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentType>;
      })
    );
  }
  /**
   * Updates the appointment purpose asynchronous.
   * @param params The `AppointmentTypesService.UpdateAppointmentTypeParams` containing the following parameters:
   *
   * - `appointmentTypeId`: The appointment type identifier.
   *
   * - `updateAppointmentTypeRequest`: The update appointment type request.
   *
   * @return Success
   */
  UpdateAppointmentType(params: AppointmentTypesService.UpdateAppointmentTypeParams): __Observable<ApiResponseAppointmentType> {
    return this.UpdateAppointmentTypeResponse(params).pipe(
      __map(_r => _r.body as ApiResponseAppointmentType)
    );
  }

  /**
   * Gets the appointment type by identifier.
   * @param appointmentTypeId The appointment type identifier.
   * @return Success
   */
  GetAppointmentTypeByIdResponse(appointmentTypeId: number): __Observable<__StrictHttpResponse<ApiResponseAppointmentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/AppointmentTypes/${encodeURIComponent(String(appointmentTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseAppointmentType>;
      })
    );
  }
  /**
   * Gets the appointment type by identifier.
   * @param appointmentTypeId The appointment type identifier.
   * @return Success
   */
  GetAppointmentTypeById(appointmentTypeId: number): __Observable<ApiResponseAppointmentType> {
    return this.GetAppointmentTypeByIdResponse(appointmentTypeId).pipe(
      __map(_r => _r.body as ApiResponseAppointmentType)
    );
  }
}

module AppointmentTypesService {

  /**
   * Parameters for GetAppointmentTypes
   */
  export interface GetAppointmentTypesParams {
    searchByText?: string;

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * The included Deleted.
     */
    includedDeleted?: boolean;
  }

  /**
   * Parameters for DeleteAppointmentType
   */
  export interface DeleteAppointmentTypeParams {

    /**
     * The appointment type identifier.
     */
    appointmentTypeId: number;

    /**
     * if set to `true` [hard delete].
     */
    hardDelete?: boolean;
  }

  /**
   * Parameters for UpdateAppointmentType
   */
  export interface UpdateAppointmentTypeParams {

    /**
     * The appointment type identifier.
     */
    appointmentTypeId: number;

    /**
     * The update appointment type request.
     */
    updateAppointmentTypeRequest?: AppointmentTypeRequest;
  }
}

export { AppointmentTypesService }
