/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseActivityNote } from '../models/api-response-activity-note';
import { ActivityNoteCreateRequest } from '../models/activity-note-create-request';
import { ApiResponseIEnumerableActivityNote } from '../models/api-response-ienumerable-activity-note';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ActivityNoteUpdateRequest } from '../models/activity-note-update-request';
@Injectable({
  providedIn: 'root',
})
class ActivityNotesService extends __BaseService {
  static readonly CreateActivityNotePath = '/ActivityNotes';
  static readonly GetActivityNotesPath = '/ActivityNotes';
  static readonly putActivityNotesActivityNoteIdPath = '/ActivityNotes/{activityNoteId}';
  static readonly GetActivityNoteByIdPath = '/ActivityNotes/{activityNoteId}';
  static readonly CreateActivityNoteMultimediaPath = '/ActivityNotes/{activityNoteId}/Multimedia';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates an activity note.
   * @param activityNoteCreateRequest The activity Note Create Request.
   * @return Success
   */
  CreateActivityNoteResponse(activityNoteCreateRequest?: ActivityNoteCreateRequest): __Observable<__StrictHttpResponse<ApiResponseActivityNote>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = activityNoteCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ActivityNotes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseActivityNote>;
      })
    );
  }
  /**
   * Creates an activity note.
   * @param activityNoteCreateRequest The activity Note Create Request.
   * @return Success
   */
  CreateActivityNote(activityNoteCreateRequest?: ActivityNoteCreateRequest): __Observable<ApiResponseActivityNote> {
    return this.CreateActivityNoteResponse(activityNoteCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseActivityNote)
    );
  }

  /**
   * Get list of activity notes.
   * @param params The `ActivityNotesService.GetActivityNotesParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `textToSearch`: The text to search.
   *
   * - `linkedEntity`: The linked Entity.
   *
   * - `includeUser`: The value indicating whether include user name.
   *
   * - `includePatient`: The value indicating whether include patient name.
   *
   * - `includeExtendedData`: The value indicating whether include extended data.
   *
   * - `entityId`: The entity Id.
   *
   * - `effectiveDateTo`: The to date
   *
   * - `effectiveDateFrom`: The from date.
   *
   * - `activityTypes`: The activity list.
   *
   * @return Success
   */
  GetActivityNotesResponse(params: ActivityNotesService.GetActivityNotesParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableActivityNote>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.textToSearch != null) __params = __params.set('textToSearch', params.textToSearch.toString());
    if (params.linkedEntity != null) __params = __params.set('linkedEntity', params.linkedEntity.toString());
    if (params.includeUser != null) __params = __params.set('includeUser', params.includeUser.toString());
    if (params.includePatient != null) __params = __params.set('includePatient', params.includePatient.toString());
    if (params.includeExtendedData != null) __params = __params.set('includeExtendedData', params.includeExtendedData.toString());
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    if (params.effectiveDateTo != null) __params = __params.set('effectiveDateTo', params.effectiveDateTo.toString());
    if (params.effectiveDateFrom != null) __params = __params.set('effectiveDateFrom', params.effectiveDateFrom.toString());
    (params.activityTypes || []).forEach(val => {if (val != null) __params = __params.append('activityTypes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ActivityNotes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableActivityNote>;
      })
    );
  }
  /**
   * Get list of activity notes.
   * @param params The `ActivityNotesService.GetActivityNotesParams` containing the following parameters:
   *
   * - `userId`: The user id.
   *
   * - `textToSearch`: The text to search.
   *
   * - `linkedEntity`: The linked Entity.
   *
   * - `includeUser`: The value indicating whether include user name.
   *
   * - `includePatient`: The value indicating whether include patient name.
   *
   * - `includeExtendedData`: The value indicating whether include extended data.
   *
   * - `entityId`: The entity Id.
   *
   * - `effectiveDateTo`: The to date
   *
   * - `effectiveDateFrom`: The from date.
   *
   * - `activityTypes`: The activity list.
   *
   * @return Success
   */
  GetActivityNotes(params: ActivityNotesService.GetActivityNotesParams): __Observable<ApiResponseIEnumerableActivityNote> {
    return this.GetActivityNotesResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableActivityNote)
    );
  }

  /**
   * Creates an activity note.
   * @param params The `ActivityNotesService.PutActivityNotesActivityNoteIdParams` containing the following parameters:
   *
   * - `activityNoteId`: The activity Note Id.
   *
   * - `activityNoteUpdateRequest`: The activity Note Update Request.
   *
   * @return Success
   */
  putActivityNotesActivityNoteIdResponse(params: ActivityNotesService.PutActivityNotesActivityNoteIdParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.activityNoteUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ActivityNotes/${encodeURIComponent(String(params.activityNoteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Creates an activity note.
   * @param params The `ActivityNotesService.PutActivityNotesActivityNoteIdParams` containing the following parameters:
   *
   * - `activityNoteId`: The activity Note Id.
   *
   * - `activityNoteUpdateRequest`: The activity Note Update Request.
   *
   * @return Success
   */
  putActivityNotesActivityNoteId(params: ActivityNotesService.PutActivityNotesActivityNoteIdParams): __Observable<ApiResponseOkResponse> {
    return this.putActivityNotesActivityNoteIdResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Get the activity note by id.
   * @param activityNoteId The activity note id.
   * @return Success
   */
  GetActivityNoteByIdResponse(activityNoteId: number): __Observable<__StrictHttpResponse<ApiResponseActivityNote>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ActivityNotes/${encodeURIComponent(String(activityNoteId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseActivityNote>;
      })
    );
  }
  /**
   * Get the activity note by id.
   * @param activityNoteId The activity note id.
   * @return Success
   */
  GetActivityNoteById(activityNoteId: number): __Observable<ApiResponseActivityNote> {
    return this.GetActivityNoteByIdResponse(activityNoteId).pipe(
      __map(_r => _r.body as ApiResponseActivityNote)
    );
  }

  /**
   * The create activity note multimedia.
   * @param params The `ActivityNotesService.CreateActivityNoteMultimediaParams` containing the following parameters:
   *
   * - `activityNoteId`: The activity note id.
   *
   * - `fileNameOverride`: The file.
   *
   * - `file`:
   *
   * @return Success
   */
  CreateActivityNoteMultimediaResponse(params: ActivityNotesService.CreateActivityNoteMultimediaParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.fileNameOverride != null) __params = __params.set('fileNameOverride', params.fileNameOverride.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ActivityNotes/${encodeURIComponent(String(params.activityNoteId))}/Multimedia`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The create activity note multimedia.
   * @param params The `ActivityNotesService.CreateActivityNoteMultimediaParams` containing the following parameters:
   *
   * - `activityNoteId`: The activity note id.
   *
   * - `fileNameOverride`: The file.
   *
   * - `file`:
   *
   * @return Success
   */
  CreateActivityNoteMultimedia(params: ActivityNotesService.CreateActivityNoteMultimediaParams): __Observable<ApiResponseOkResponse> {
    return this.CreateActivityNoteMultimediaResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module ActivityNotesService {

  /**
   * Parameters for GetActivityNotes
   */
  export interface GetActivityNotesParams {

    /**
     * The user id.
     */
    userId?: number;

    /**
     * The text to search.
     */
    textToSearch?: string;

    /**
     * The linked Entity.
     */
    linkedEntity?: 'Patient' | 'Invoice' | 'Estimate' | 'Document' | 'Claim';

    /**
     * The value indicating whether include user name.
     */
    includeUser?: boolean;

    /**
     * The value indicating whether include patient name.
     */
    includePatient?: boolean;

    /**
     * The value indicating whether include extended data.
     */
    includeExtendedData?: boolean;

    /**
     * The entity Id.
     */
    entityId?: number;

    /**
     * The to date
     */
    effectiveDateTo?: string;

    /**
     * The from date.
     */
    effectiveDateFrom?: string;

    /**
     * The activity list.
     */
    activityTypes?: Array<number>;
  }

  /**
   * Parameters for putActivityNotesActivityNoteId
   */
  export interface PutActivityNotesActivityNoteIdParams {

    /**
     * The activity Note Id.
     */
    activityNoteId: number;

    /**
     * The activity Note Update Request.
     */
    activityNoteUpdateRequest?: ActivityNoteUpdateRequest;
  }

  /**
   * Parameters for CreateActivityNoteMultimedia
   */
  export interface CreateActivityNoteMultimediaParams {

    /**
     * The activity note id.
     */
    activityNoteId: number;

    /**
     * The file.
     */
    fileNameOverride?: string;
    file?: Blob;
  }
}

export { ActivityNotesService }
