export const environment = {
    production: true,
    appInsights: {
        instrumentationKey: '86a34a54-086c-4cec-9183-1936372b7d79',
    },
    bugsnag: {
        apiKey: '6a094809eb8dcc2856513d5cb4aab6c5',
    },
    tenantType: 'prod',
    apiTenantsUrlNoAuth: 'https://tenantsapi.symplast.com',
    nwpApiUrlNoAuth: 'https://webportalapi.symplast.com',
    onlineAppointmentRequestsApp: 'https://appointmentrequestsapp.symplast.com',
    signalrUrl: 'https://financialsapi.symplast.com/kiosk',
    version: require('../../../../package.json').version,
    featureFlags: {
        'symplast-forms': false,
    },
    suncfusionKey: 'ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdkZjUHxbcXJURGJb',
};
