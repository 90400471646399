import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Inject, OnInit, Optional, Renderer2 } from '@angular/core';
import { MatLegacyDialogContainer as MatDialogContainer } from '@angular/material/legacy-dialog';
import { SymplastDialogHeaderComponent } from './components/header/symplast-dialog-header.component';
import { SymplastDialogFooterComponent } from './components/footer/symplast-dialog-footer.component';
import { SYMPLAST_DIALOG_PRESET, SYMPLAST_DIALOG_PRESET_MAP, SymplastDialogPreset } from './symplast-dialog.preset';
import { DOCUMENT, NgClass } from '@angular/common';
import { SymplastDialogContentDirective } from './symplast-dialog.directive';

@Component({
    imports: [NgClass],
    selector: 'symplast-dialog',
    standalone: true,
    templateUrl: './symplast-dialog.component.html',
    styleUrls: ['./symplast-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymplastDialogComponent implements OnInit {
    @ContentChild(SymplastDialogFooterComponent) public footer?: SymplastDialogFooterComponent;
    @ContentChild(SymplastDialogHeaderComponent) public header?: SymplastDialogHeaderComponent;
    @ContentChild(SymplastDialogContentDirective) public content?: SymplastDialogContentDirective;

    constructor(
        @Optional() @Inject(SYMPLAST_DIALOG_PRESET) public preset: SymplastDialogPreset,
        @Inject(DOCUMENT) private document: Document,
        @Optional() private matDialogContainer: MatDialogContainer,
        private renderer: Renderer2,
    ) {}

    @HostBinding('style.background-color') public get backgroundColor(): string {
        return SYMPLAST_DIALOG_PRESET_MAP[this.preset]?.backgroundColor || 'var(--color-white)';
    }

    public ngOnInit(): void {
        if ((this.matDialogContainer as any)?._elementRef?.nativeElement) {
            this.renderer.setStyle((this.matDialogContainer as any)._elementRef.nativeElement, 'padding', '0');
            this.renderer.setStyle((this.matDialogContainer as any)._elementRef.nativeElement, 'max-height', '90vh');
        }

        if (this.document.querySelector('.cdk-overlay-pane')) {
            this.renderer.setStyle(this.document.querySelector('.cdk-overlay-pane'), 'max-height', '90vh');
        }
    }
}
