import { Route } from '@angular/router';
import { CombinableRouteConfig } from '../types/combinable-route-config';

/**
 * Combines multiple route configurations into a single route configuration.
 * @param route The base route configuration.
 * @param extraConfigs Additional route configurations to combine.
 * @returns The combined route configuration.
 */
export function combineRouteConfig(route: Route, ...extraConfigs: CombinableRouteConfig[]): Route {
    return extraConfigs.reduce((acc, config) => {
        return {
            ...acc,
            ...config,
            canActivate: [...(acc.canActivate || []), ...(config.canActivate || [])],
            canActivateChild: [...(acc.canActivateChild || []), ...(config.canActivateChild || [])],
            canDeactivate: [...(acc.canDeactivate || []), ...(config.canDeactivate || [])],
            canLoad: [...(acc.canLoad || []), ...(config.canLoad || [])],
            canMatch: [...(acc.canMatch || []), ...(config.canMatch || [])],
            data: { ...acc.data, ...config.data },
            providers: [...(acc.providers || []), ...(config.providers || [])],
            resolve: { ...acc.resolve, ...config.resolve },
        };
    }, route);
}
