<div class="header-container" data-e2e="header">
    <mat-toolbar color="white" class="header-toolbar">
        <div class="patient-search-toolbar">
            <ng-container *ngIf="browserService.showPatientSearch$ | async">
                <mat-form-field class="full-width" floatLabel="never">
                    <input
                        type="text"
                        matInput
                        color="primary"
                        placeholder="Search for Patients...."
                        data-e2e="header__patient-search"
                        [formControl]="patientSearchControl"
                        #patientInput />
                    <mat-icon
                        *ngIf="patientInput.value?.length"
                        class="pointer"
                        matSuffix
                        (click)="patientInput.value = ''"
                        data-e2e="header__patient-search__clear">
                        close
                    </mat-icon>
                    <mat-icon
                        class="pointer"
                        matSuffix
                        (click)="patientHelperSvc.showTips()"
                        title="How it's work?"
                        data-e2e="header__patient-search__info">
                        info_outline
                    </mat-icon>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="logo">
            <div><img *ngIf="currentTenant != null" [src]="currentTenant.Logo" data-e2e="header__tenant-logo" /></div>
        </div>
        <div class="user-info-wrapper">
            <div>
                <button class="help-button hidden" mat-button [matMenuTriggerFor]="questionMenu" data-e2e="header__help-trigger">
                    <img id="help-icon" src="/assets/icons/icon_Help.png" />
                </button>
                <mat-menu #questionMenu="matMenu">
                    <button mat-menu-item>
                        <a href="https://help.symplast.com" target="_blank" data-e2e="header__help-button">Help Center</a>
                    </button>
                </mat-menu>

                <mat-divider [vertical]="true"></mat-divider>
                <div class="user-info">
                    <button mat-button [matMenuTriggerFor]="menu" data-e2e="header__profile-trigger">
                        <span id="top-user-name" *ngIf="currentUser$ | async as currentUser"
                            >{{ currentUser.title }} {{ currentUser.displayName }}
                        </span>
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" data-e2e="header__profile-menu">
                        <button mat-menu-item (click)="openProfile()" data-e2e="header__profile-menu__profile-button">
                            <div class="user-menu-item">
                                <i><svg-icon [applyClass]="true" src="/assets/icons/icon_Settings.svg"></svg-icon></i>
                                <p>My Profile</p>
                            </div>
                        </button>
                        <button mat-menu-item class="help-menu-item hidden">
                            <div class="user-menu-item">
                                <img src="/assets/icons/icon_Help.png" />
                                <a href="https://help.symplast.com" target="_blank" data-e2e="header__profile-menu__help-button"
                                    >Help Center</a
                                >
                            </div>
                        </button>
                        <button mat-menu-item (click)="signOut()" data-e2e="header__profile-menu__sign-out-button">
                            <div class="user-menu-item">
                                <img src="/assets/icons/icon_signout_red_128.png" />
                                <p class="red">Sign Out</p>
                            </div>
                        </button>
                    </mat-menu>
                    <div
                        class="user-avatar"
                        data-e2e="header__user-logo"
                        *ngIf="currentUser$ | async as currentUser"
                        [style.background-image]="
                            'url(' +
                            (currentUser.userImageThumbnailUrl || 'https://symplastdevelopment.s3.amazonaws.com/Images/NoPic.jpg') +
                            ')'
                        "></div>
                </div>
            </div>
        </div>
    </mat-toolbar>
</div>
<app-loader *ngIf="patientHelperSvc?.isLoading$ | async"></app-loader>
