import { Directive, ElementRef, HostBinding, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    standalone: true,
    selector: 'textarea[symplastTextareaHighlightElement]',
})
export class TextareaHighlightElementDirective {
    @HostBinding('class') classes = 'symplast-textarea-element';

    constructor(public elementRef: ElementRef, @Self() @Optional() public ngControl: NgControl) {}
}
