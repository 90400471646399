import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { toMBFixed } from '@symplast/utils';
import { Observable, fromEvent, map, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileSelectionService {
    private document = inject(DOCUMENT);

    public selectFiles(options: { multiple: false; accept?: string; maxBytes?: number }): Observable<File | undefined>;
    public selectFiles(options: { multiple: true; accept?: string; maxBytes?: number }): Observable<File[]>;
    public selectFiles(options: { multiple?: boolean; accept?: string; maxBytes?: number } = {}): Observable<File | undefined | File[]> {
        const input = this.document.createElement('input');

        input.type = 'file';
        input.style.display = 'none';

        if (options.multiple) {
            input.multiple = options.multiple;
        }
        if (options.accept) {
            input.accept = options.accept;
        }

        const files$ = fromEvent(input, 'change').pipe(
            take(1),
            map((event) => {
                const files = (event.target as HTMLInputElement).files;

                if (!files || files.length === 0) {
                    return options.multiple ? [] : undefined;
                }

                const invalidFileSelected = options.accept
                    ? Array.from(files).some((file) => !this.isValidFileType(file, options.accept as string))
                    : false;

                const invalidFileSize = options.maxBytes
                    ? Array.from(files).some((file) => !this.isValidFileSize(file, options.maxBytes as number))
                    : false;

                if (invalidFileSelected) {
                    throw new Error('Invalid file type selected');
                }

                if (invalidFileSize) {
                    throw new Error(`File size exceeds the limit of ${toMBFixed(options.maxBytes || 0)} MB`);
                }

                return options.multiple ? Array.from(files) : files[0];
            }),
        );

        input.click();

        return files$;
    }

    public isValidFileType(file: File, accept: string): boolean {
        const acceptedTypes = accept.split(',').map((type) => type.trim());

        if (!acceptedTypes?.length) {
            return true;
        }

        return acceptedTypes.some((type) => {
            if (type.startsWith('.')) {
                return file.name.endsWith(type);
            } else {
                return file.type === type;
            }
        });
    }

    public isValidFileSize(file: File, maxBytes: number): boolean {
        return file.size <= maxBytes;
    }
}
