/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseAlbum } from '../models/api-response-album';
import { NewAlbumRequest } from '../models/new-album-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseIEnumerableAlbumStatistics } from '../models/api-response-ienumerable-album-statistics';
import { PatchAlbumRequest } from '../models/patch-album-request';
import { ApiResponseAlbumStatistics } from '../models/api-response-album-statistics';
import { AddAudienceMembersRequest } from '../models/add-audience-members-request';
import { RemoveAudienceMembersRequest } from '../models/remove-audience-members-request';
import { ApiResponseIEnumerableFileModel } from '../models/api-response-ienumerable-file-model';
@Injectable({
    providedIn: 'root',
})
class AlbumsService extends __BaseService {
    static readonly CreateAlbumPath = '/api/albums';
    static readonly DeleteMultipleAlbumsPath = '/api/albums';
    static readonly GetAlbumsPath = '/api/albums';
    static readonly UpdateAlbumPath = '/api/albums/{id}';
    static readonly DeleteAlbumPath = '/api/albums/{id}';
    static readonly GetAlbumByIdPath = '/api/albums/{id}';
    static readonly AddAudienceMembersPath = '/api/albums/{id}/audience';
    static readonly RemoveAudienceMembersPath = '/api/albums/{id}/audience';
    static readonly GetFilesByAlbumIdPath = '/api/albums/{id}/files';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Adds a new Multimedia Album.
     * @param request The request.
     * @return Created
     */
    CreateAlbumResponse(request?: NewAlbumRequest): __Observable<__StrictHttpResponse<ApiResponseAlbum>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/albums`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAlbum>;
            }),
        );
    }
    /**
     * Adds a new Multimedia Album.
     * @param request The request.
     * @return Created
     */
    CreateAlbum(request?: NewAlbumRequest): __Observable<ApiResponseAlbum> {
        return this.CreateAlbumResponse(request).pipe(__map((_r) => _r.body as ApiResponseAlbum));
    }

    /**
     * Soft deletes a list of albums.
     * @param ids The identifiers.
     * @return Success
     */
    DeleteMultipleAlbumsResponse(ids?: Array<string>): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = ids;
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/albums`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Soft deletes a list of albums.
     * @param ids The identifiers.
     * @return Success
     */
    DeleteMultipleAlbums(ids?: Array<string>): __Observable<ApiResponseOkResponse> {
        return this.DeleteMultipleAlbumsResponse(ids).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets multimedia albums. Practice users get all albums for their tenant/practice while patient users get only albums that were shared with them.
     * @param includeInactiveAlbums The include inactive albums.
     * @return Success
     */
    GetAlbumsResponse(includeInactiveAlbums?: boolean): __Observable<__StrictHttpResponse<ApiResponseIEnumerableAlbumStatistics>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (includeInactiveAlbums != null) __params = __params.set('includeInactiveAlbums', includeInactiveAlbums.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/albums`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableAlbumStatistics>;
            }),
        );
    }
    /**
     * Gets multimedia albums. Practice users get all albums for their tenant/practice while patient users get only albums that were shared with them.
     * @param includeInactiveAlbums The include inactive albums.
     * @return Success
     */
    GetAlbums(includeInactiveAlbums?: boolean): __Observable<ApiResponseIEnumerableAlbumStatistics> {
        return this.GetAlbumsResponse(includeInactiveAlbums).pipe(__map((_r) => _r.body as ApiResponseIEnumerableAlbumStatistics));
    }

    /**
     * Patches the album matching provided identifier.
     * @param params The `AlbumsService.UpdateAlbumParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateAlbumResponse(params: AlbumsService.UpdateAlbumParams): __Observable<__StrictHttpResponse<ApiResponseAlbum>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>('PATCH', this.rootUrl + `/api/albums/${encodeURIComponent(String(params.id))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAlbum>;
            }),
        );
    }
    /**
     * Patches the album matching provided identifier.
     * @param params The `AlbumsService.UpdateAlbumParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    UpdateAlbum(params: AlbumsService.UpdateAlbumParams): __Observable<ApiResponseAlbum> {
        return this.UpdateAlbumResponse(params).pipe(__map((_r) => _r.body as ApiResponseAlbum));
    }

    /**
     * Soft deletes specified album.
     * @param id The identifier.
     * @return Success
     */
    DeleteAlbumResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/albums/${encodeURIComponent(String(id))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Soft deletes specified album.
     * @param id The identifier.
     * @return Success
     */
    DeleteAlbum(id: string): __Observable<ApiResponseOkResponse> {
        return this.DeleteAlbumResponse(id).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }

    /**
     * Gets specified multimedia album. Practice users have access to all albums for their tenant/practice while patient users have access only to albums that were shared with them.
     * @param id The album identifier.
     * @return Success
     */
    GetAlbumByIdResponse(id: string): __Observable<__StrictHttpResponse<ApiResponseAlbumStatistics>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/albums/${encodeURIComponent(String(id))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAlbumStatistics>;
            }),
        );
    }
    /**
     * Gets specified multimedia album. Practice users have access to all albums for their tenant/practice while patient users have access only to albums that were shared with them.
     * @param id The album identifier.
     * @return Success
     */
    GetAlbumById(id: string): __Observable<ApiResponseAlbumStatistics> {
        return this.GetAlbumByIdResponse(id).pipe(__map((_r) => _r.body as ApiResponseAlbumStatistics));
    }

    /**
     * Adds the audience members to the album.
     * @param params The `AlbumsService.AddAudienceMembersParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    AddAudienceMembersResponse(params: AlbumsService.AddAudienceMembersParams): __Observable<__StrictHttpResponse<ApiResponseAlbum>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/api/albums/${encodeURIComponent(String(params.id))}/audience`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAlbum>;
            }),
        );
    }
    /**
     * Adds the audience members to the album.
     * @param params The `AlbumsService.AddAudienceMembersParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    AddAudienceMembers(params: AlbumsService.AddAudienceMembersParams): __Observable<ApiResponseAlbum> {
        return this.AddAudienceMembersResponse(params).pipe(__map((_r) => _r.body as ApiResponseAlbum));
    }

    /**
     * Removes specified audience members from the album.
     * @param params The `AlbumsService.RemoveAudienceMembersParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    RemoveAudienceMembersResponse(params: AlbumsService.RemoveAudienceMembersParams): __Observable<__StrictHttpResponse<ApiResponseAlbum>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.request;
        let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/albums/${encodeURIComponent(String(params.id))}/audience`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseAlbum>;
            }),
        );
    }
    /**
     * Removes specified audience members from the album.
     * @param params The `AlbumsService.RemoveAudienceMembersParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `request`: The request.
     *
     * @return Success
     */
    RemoveAudienceMembers(params: AlbumsService.RemoveAudienceMembersParams): __Observable<ApiResponseAlbum> {
        return this.RemoveAudienceMembersResponse(params).pipe(__map((_r) => _r.body as ApiResponseAlbum));
    }

    /**
     * Gets the files for the specified multimedia album.
     * @param params The `AlbumsService.GetFilesByAlbumIdParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `take`: The take.
     *
     * - `sortOrderDirection`: The sort Order Direction.
     *
     * - `skip`: The skip.
     *
     * - `fileSortBy`: The file Sort By.
     *
     * @return Success
     */
    GetFilesByAlbumIdResponse(
        params: AlbumsService.GetFilesByAlbumIdParams,
    ): __Observable<__StrictHttpResponse<ApiResponseIEnumerableFileModel>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.take != null) __params = __params.set('take', params.take.toString());
        if (params.sortOrderDirection != null) __params = __params.set('sortOrderDirection', params.sortOrderDirection.toString());
        if (params.skip != null) __params = __params.set('skip', params.skip.toString());
        if (params.fileSortBy != null) __params = __params.set('fileSortBy', params.fileSortBy.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/albums/${encodeURIComponent(String(params.id))}/files`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableFileModel>;
            }),
        );
    }
    /**
     * Gets the files for the specified multimedia album.
     * @param params The `AlbumsService.GetFilesByAlbumIdParams` containing the following parameters:
     *
     * - `id`: The identifier.
     *
     * - `take`: The take.
     *
     * - `sortOrderDirection`: The sort Order Direction.
     *
     * - `skip`: The skip.
     *
     * - `fileSortBy`: The file Sort By.
     *
     * @return Success
     */
    GetFilesByAlbumId(params: AlbumsService.GetFilesByAlbumIdParams): __Observable<ApiResponseIEnumerableFileModel> {
        return this.GetFilesByAlbumIdResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableFileModel));
    }
}

module AlbumsService {
    /**
     * Parameters for UpdateAlbum
     */
    export interface UpdateAlbumParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The request.
         */
        request?: PatchAlbumRequest;
    }

    /**
     * Parameters for AddAudienceMembers
     */
    export interface AddAudienceMembersParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The request.
         */
        request?: AddAudienceMembersRequest;
    }

    /**
     * Parameters for RemoveAudienceMembers
     */
    export interface RemoveAudienceMembersParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The request.
         */
        request?: RemoveAudienceMembersRequest;
    }

    /**
     * Parameters for GetFilesByAlbumId
     */
    export interface GetFilesByAlbumIdParams {
        /**
         * The identifier.
         */
        id: string;

        /**
         * The take.
         */
        take?: number;

        /**
         * The sort Order Direction.
         */
        sortOrderDirection?: 'Ascending' | 'Descending';

        /**
         * The skip.
         */
        skip?: number;

        /**
         * The file Sort By.
         */
        fileSortBy?: 'AppointmentDate' | 'UploadDate' | 'OriginalCreationDate' | 'LegacyCreationDate';
    }
}

export { AlbumsService };
