import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationDataStorage } from './core/services/authentication/authentication-data.storage';
import { LogoutService } from './core/services/logout/logout.service';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {
    constructor(private authDataStorage: AuthenticationDataStorage, private logoutService: LogoutService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.authDataStorage.data?.AccessToken;

        // To prevent adding auth token to the cross-origin requests
        if (!req.url.startsWith('/') && !req.url.includes('.symplast.com/') && !req.url.includes('://localhost:')) {
            return next.handle(req);
        }

        if (!!accessToken) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json, */*',
                },
            });
        }

        // Also handle errors globally
        return next.handle(req).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    this.logoutService.logout();
                }

                return throwError(() => error);
            }),
            tap({ error: (err) => console.error(`Error performing request, status code = ${err.status}`) }),
        );
    }
}
