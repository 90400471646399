/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseFormInstanceData } from '../models/api-response-form-instance-data';
import { ApiResponseString } from '../models/api-response-string';
@Injectable({
  providedIn: 'root',
})
class SubmissionsService extends __BaseService {
  static readonly GetSubmissionPath = '/SymplastForms/Submissions/{instanceId}';
  static readonly GetSubmissionHtmlPath = '/SymplastForms/Submissions/{instanceId}/Html';
  static readonly GetSubmissionPdfPath = '/SymplastForms/Submissions/{instanceId}/Pdf';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the submission instance data.
   * @param instanceId The submission instance id.
   * @return Success
   */
  GetSubmissionResponse(instanceId: string): __Observable<__StrictHttpResponse<ApiResponseFormInstanceData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SymplastForms/Submissions/${encodeURIComponent(String(instanceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseFormInstanceData>;
      })
    );
  }
  /**
   * Gets the submission instance data.
   * @param instanceId The submission instance id.
   * @return Success
   */
  GetSubmission(instanceId: string): __Observable<ApiResponseFormInstanceData> {
    return this.GetSubmissionResponse(instanceId).pipe(
      __map(_r => _r.body as ApiResponseFormInstanceData)
    );
  }

  /**
   * Gets the submission instance data HTML.
   * @param instanceId The submission instance id.
   * @return Success
   */
  GetSubmissionHtmlResponse(instanceId: string): __Observable<__StrictHttpResponse<ApiResponseString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SymplastForms/Submissions/${encodeURIComponent(String(instanceId))}/Html`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseString>;
      })
    );
  }
  /**
   * Gets the submission instance data HTML.
   * @param instanceId The submission instance id.
   * @return Success
   */
  GetSubmissionHtml(instanceId: string): __Observable<ApiResponseString> {
    return this.GetSubmissionHtmlResponse(instanceId).pipe(
      __map(_r => _r.body as ApiResponseString)
    );
  }

  /**
   * Gets the submission instance data PDF link.
   * @param instanceId The submission instance id.
   * @return Success
   */
  GetSubmissionPdfResponse(instanceId: string): __Observable<__StrictHttpResponse<ApiResponseString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SymplastForms/Submissions/${encodeURIComponent(String(instanceId))}/Pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseString>;
      })
    );
  }
  /**
   * Gets the submission instance data PDF link.
   * @param instanceId The submission instance id.
   * @return Success
   */
  GetSubmissionPdf(instanceId: string): __Observable<ApiResponseString> {
    return this.GetSubmissionPdfResponse(instanceId).pipe(
      __map(_r => _r.body as ApiResponseString)
    );
  }
}

module SubmissionsService {
}

export { SubmissionsService }
