import { Selector } from '@ngxs/store';
import { IUserTypesStateModel } from './user-types.model';
import { UserTypesState } from './user-types.state';

export class UserTypesSelectors {
    @Selector([UserTypesState])
    public static loading(state: IUserTypesStateModel): boolean {
        return state.loading;
    }

    @Selector([UserTypesState])
    public static userTypes(state: IUserTypesStateModel): IUserTypesStateModel['userTypes'] {
        return state.userTypes;
    }
}
