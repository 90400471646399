import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { User } from '@symplast/generated-clients/web-portal';
import { Observable } from 'rxjs';
import { LoadPracticeUsers } from './practice-users.actions';
import { PracticeUsersSelectors } from './practice-users.selectors';

@Injectable({
    providedIn: 'root',
})
export class PracticeUsersFacade {
    @Select(PracticeUsersSelectors.practiceUsers)
    public practiceUsers$!: Observable<User[]>;

    @Select(PracticeUsersSelectors.loading)
    public loading$!: Observable<boolean>;

    constructor(private store: Store) {}

    public loadPracticeUsers(): void {
        this.store.dispatch(new LoadPracticeUsers());
    }
}
