import { Injectable } from '@angular/core';
import { ConfigurationSettingsService } from '@symplast/generated-clients/web-portal';
import { Action, State, StateContext } from '@ngxs/store';
import { LoadInsuranceSettings, SaveGeneralRequest, SaveTrizettoRequest } from './insurance-settings.actions';
import { IInsuranceSettingsStateModel } from './insurance-settings.model';
import { PrivateSettingsService } from '../../services/private-settings.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApplicationConstants } from '@symplast/app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs';

@State<IInsuranceSettingsStateModel>({
    name: 'insuranceSettings',
    defaults: {
        enabled: false,
        enabledReceiving835File: false,
        acceptAssignmentsByDefault: false,
        loading: false,
    },
})
@Injectable()
export class InsuranceSettingsState {
    constructor(
        private configurationSettingsService: ConfigurationSettingsService,
        private privateSettingService: PrivateSettingsService,
        private snackbar: MatSnackBar,
    ) {}

    @Action(LoadInsuranceSettings)
    public load(context: StateContext<IInsuranceSettingsStateModel>): void {
        context.patchState({ loading: true });
        this.configurationSettingsService.GetInsuranceSettings().subscribe((response) => {
            context.patchState({
                enabled: response.result?.enabled,
                enabledReceiving835File: response.result?.enabledReceiving835File,
                acceptAssignmentsByDefault: response.result?.acceptAssignmentsByDefault,
                defaultBillingLocationId: response.result?.defaultBillingLocationId,
                loading: false,
            });
        });
    }

    @Action(SaveGeneralRequest)
    public saveGeneral(
        context: StateContext<IInsuranceSettingsStateModel>,
        { enabled, acceptAssignmentsByDefault, defaultBillingLocationId }: SaveGeneralRequest,
    ): void {
        context.patchState({ loading: true });
        const state = context.getState();

        this.configurationSettingsService
            .UpdateInsuranceSettings({
                enabled,
                enabledReceiving835File: state.enabledReceiving835File,
                acceptAssignmentsByDefault: acceptAssignmentsByDefault,
                defaultBillingLocationId,
            })
            .pipe(finalize(() => context.patchState({ loading: false })))
            .subscribe({
                next: () => {
                    context.patchState({
                        enabled,
                        acceptAssignmentsByDefault,
                        defaultBillingLocationId,
                    });

                    this.privateSettingService.insuranceEnabled = enabled;
                    this.snackbar.open('The settings have been applied', undefined, { duration: ApplicationConstants.SnackBarDuration });
                },
                error: (error: HttpErrorResponse) => {
                    const message = error.error?.errorMessage || error.message || 'Something went wrong. Please try again later';

                    this.snackbar.open(message, undefined, { duration: ApplicationConstants.SnackBarDuration });
                },
            });
    }

    @Action(SaveTrizettoRequest)
    public saveTrizetto(context: StateContext<IInsuranceSettingsStateModel>, { enabledReceiving835File }: SaveTrizettoRequest): void {
        context.patchState({ loading: true });
        const state = context.getState();

        this.configurationSettingsService
            .UpdateInsuranceSettings({
                enabledReceiving835File,
                enabled: state.enabled,
                acceptAssignmentsByDefault: state.acceptAssignmentsByDefault,
                defaultBillingLocationId: state.defaultBillingLocationId,
            })
            .subscribe((_) => {
                context.patchState({
                    enabledReceiving835File,
                    loading: false,
                });
            });
    }
}
