/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableTaskDto } from '../models/api-response-ienumerable-task-dto';
@Injectable({
  providedIn: 'root',
})
class TasksService extends __BaseService {
  static readonly GetTasksPath = '/Tasks';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the list of tasks.
   * @param params The `TasksService.GetTasksParams` containing the following parameters:
   *
   * - `tasksSortBy`: Order direction.
   *
   * - `statuses`: The statuses.
   *
   * - `skipValue`: The skip Value.
   *
   * - `query`: The query.
   *
   * - `batchSize`: The batch Size.
   *
   * @return Success
   */
  GetTasksResponse(params: TasksService.GetTasksParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableTaskDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tasksSortBy != null) __params = __params.set('tasksSortBy', params.tasksSortBy.toString());
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.skipValue != null) __params = __params.set('skipValue', params.skipValue.toString());
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.batchSize != null) __params = __params.set('batchSize', params.batchSize.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableTaskDto>;
      })
    );
  }
  /**
   * Gets the list of tasks.
   * @param params The `TasksService.GetTasksParams` containing the following parameters:
   *
   * - `tasksSortBy`: Order direction.
   *
   * - `statuses`: The statuses.
   *
   * - `skipValue`: The skip Value.
   *
   * - `query`: The query.
   *
   * - `batchSize`: The batch Size.
   *
   * @return Success
   */
  GetTasks(params: TasksService.GetTasksParams): __Observable<ApiResponseIEnumerableTaskDto> {
    return this.GetTasksResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableTaskDto)
    );
  }
}

module TasksService {

  /**
   * Parameters for GetTasks
   */
  export interface GetTasksParams {

    /**
     * Order direction.
     */
    tasksSortBy?: 'CreationDateNewest' | 'CreationDateOldest' | 'TaskNumberAscending' | 'TaskNumberDescending' | 'CreatorNameAscending' | 'CreatorNameDescending' | 'AssignedNameAscending' | 'AssignedNameDescending';

    /**
     * The statuses.
     */
    statuses?: Array<'Draft' | 'Open' | 'InProgress' | 'Cancel' | 'Completed'>;

    /**
     * The skip Value.
     */
    skipValue?: number;

    /**
     * The query.
     */
    query?: string;

    /**
     * The batch Size.
     */
    batchSize?: number;
  }
}

export { TasksService }
