/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableLocationListInfo } from '../models/api-response-ienumerable-location-list-info';
import { ApiResponseICollectionCountry } from '../models/api-response-icollection-country';
import { ApiResponseICollectionState } from '../models/api-response-icollection-state';
@Injectable({
    providedIn: 'root',
})
class CitiesService extends __BaseService {
    static readonly CitiesGetPath = '/api/Cities';
    static readonly CitiesGetCountriesPath = '/api/Countries';
    static readonly CitiesGetStatesPath = '/api/States';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * @param params The `CitiesService.CitiesGetParams` containing the following parameters:
     *
     * - `zipCode`: The location zip code
     *
     * - `stateName`: Name of the state.
     *
     * - `stateId`: The state identifier.
     *
     * - `cityName`: Name of the city.
     *
     * @return OK
     */
    CitiesGetResponse(params: CitiesService.CitiesGetParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableLocationListInfo>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.zipCode != null) __params = __params.set('zipCode', params.zipCode.toString());
        if (params.stateName != null) __params = __params.set('stateName', params.stateName.toString());
        if (params.stateId != null) __params = __params.set('stateId', params.stateId.toString());
        if (params.cityName != null) __params = __params.set('cityName', params.cityName.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Cities`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseIEnumerableLocationListInfo>;
            }),
        );
    }
    /**
     * @param params The `CitiesService.CitiesGetParams` containing the following parameters:
     *
     * - `zipCode`: The location zip code
     *
     * - `stateName`: Name of the state.
     *
     * - `stateId`: The state identifier.
     *
     * - `cityName`: Name of the city.
     *
     * @return OK
     */
    CitiesGet(params: CitiesService.CitiesGetParams): __Observable<ApiResponseIEnumerableLocationListInfo> {
        return this.CitiesGetResponse(params).pipe(__map((_r) => _r.body as ApiResponseIEnumerableLocationListInfo));
    }

    /**
     * @return Countries returned
     */
    CitiesGetCountriesResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionCountry>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Countries`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionCountry>;
            }),
        );
    }
    /**
     * @return Countries returned
     */
    CitiesGetCountries(): __Observable<ApiResponseICollectionCountry> {
        return this.CitiesGetCountriesResponse().pipe(__map((_r) => _r.body as ApiResponseICollectionCountry));
    }

    /**
     * @param countryId undefined
     * @return States returned
     */
    CitiesGetStatesResponse(countryId?: number): __Observable<__StrictHttpResponse<ApiResponseICollectionState>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (countryId != null) __params = __params.set('countryId', countryId.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/api/States`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionState>;
            }),
        );
    }
    /**
     * @param countryId undefined
     * @return States returned
     */
    CitiesGetStates(countryId?: number): __Observable<ApiResponseICollectionState> {
        return this.CitiesGetStatesResponse(countryId).pipe(__map((_r) => _r.body as ApiResponseICollectionState));
    }
}

module CitiesService {
    /**
     * Parameters for CitiesGet
     */
    export interface CitiesGetParams {
        /**
         * The location zip code
         */
        zipCode?: string;

        /**
         * Name of the state.
         */
        stateName?: string;

        /**
         * The state identifier.
         */
        stateId?: number;

        /**
         * Name of the city.
         */
        cityName?: string;
    }
}

export { CitiesService };
