import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { LoadItemTypes, ReloadItemTypes } from './item-types.actions';
import { IItemTypesStateModel } from './item-types.model';
import { ItemType, ItemTypesService } from '@symplast/generated-clients/financial';

@State<IItemTypesStateModel>({
    name: 'itemTypes',
    defaults: {
        itemTypes: [],
        itemTypesIncludeGroup: [],
        loading: false,
    },
})
@Injectable()
export class ItemTypesState {
    constructor(private itemTypesService: ItemTypesService) {}

    @Action(LoadItemTypes)
    public load(context: StateContext<IItemTypesStateModel>, { refresh }: LoadItemTypes) {
        if (!context.getState().itemTypes.length || refresh) {
            context.patchState({ loading: true });
            this.itemTypesService
                .GetAllItemTypes({})
                .pipe(finalize(() => context.patchState({ loading: false })))
                .subscribe((response) => {
                    context.patchState({
                        itemTypes: [...response.result].sort((itemType_1, itemType_2) => itemType_1.name.localeCompare(itemType_2.name)),
                        itemTypesIncludeGroup: [...response.result, { itemTypeId: 0, name: 'Item Group' } as ItemType].sort(
                            (itemType_1, itemType_2) => itemType_1.name.localeCompare(itemType_2.name),
                        ),
                    });
                });
        }
    }

    @Action(ReloadItemTypes)
    public reload(context: StateContext<IItemTypesStateModel>) {
        context.dispatch(new LoadItemTypes(true));
    }
}
