import { Selector } from '@ngxs/store';
import { ILanguagesStateModel } from './languages.model';
import { LanguagesState } from './languages.state';

export class LanguagesSelectors {
    @Selector([LanguagesState])
    public static loading(state: ILanguagesStateModel): boolean {
        return state.loading;
    }

    @Selector([LanguagesState])
    public static languages(state: ILanguagesStateModel): ILanguagesStateModel['languages'] {
        return state.languages;
    }
}
