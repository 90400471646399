import { HealthInsuranceClaimForm } from '@symplast/generated-clients/web-portal';

export const HcfaFormExample: HealthInsuranceClaimForm = {
    claimId: 0,
    acceptAssignment: true,
    billingProviderLocation: 'PROVIDER LOCATION',
    billingProviderName: 'PROVIDER NAME',
    billingProviderNpi: '1111111111',
    billingProviderStreet: 'PROVIDER STREET',
    diagnosis: ['A01.1', 'A01.2', 'A01.3'],
    diagnosisIcd: '0',
    ein: true,
    federalTaxNumber: '12345',
    healthInsuranceClaimFormServices: [
        {
            charges: 100,
            cptCode: '0003M',
            dateOfServiceFrom: '2020-01-01T00:00:00',
            dateOfServiceTo: '2020-01-01T00:00:00',
            daysOrUnits: '1',
            diagnosisPointer: 'A',
            modifiers: [],
            npi: '1111111111',
            placeOfService: '11',
            itemName: 'PLACEHOLDER',
        },
    ],
    insuranceCityStateZip: 'INSURANCE CITY AND STATE',
    insuranceName: 'INSURANCE NAME PLACEHOLDER',
    insuredAddress: 'INSURANCE ADDRESS',
    insuredDoB: '2020-09-09T11:10:00Z',
    insuredName: 'INSURED NAME',
    insuredNumber: '000',
    insuredPolicyGroupOrFecaNumber: '',
    insuredSignature: 'Signature on File',
    isAutoAccidentCondition: false,
    isEmploymentCondition: false,
    isFemalePatient: false,
    isInsuredFemalePatient: false,
    isOtherAccidentCondition: false,
    isOtherInsurance: true,
    isOtherPatientRelationship: true,
    patientAccountNumber: '34370',
    patientCity: 'PATIENT CITY',
    patientDoB: '1960-05-10T00:00:00Z',
    patientName: 'PATIENT NAME',
    patientPhone: '9542838535',
    patientSignature: 'Signature on File',
    patientSignatureDate: '2020-09-07T00:00:00',
    patientState: 'CO',
    patientStreet: 'PATIENT STREET PLACEHOLDER',
    patientZipCode: '80478',
    physicianSignature: 'Signature on File',
    physicianSignatureDate: '2020-09-07T00:00:00',
    referringProviderName: 'REFERING PROVIDER',
    referringProviderNamePrefix: 'DN',
    referringProviderNpi: '1111111111',
    serviceFacilityLocation: 'PLACEHOLDER',
    serviceFacilityLocationInformationNpi: '',
    serviceFacilityLocationName: 'PLACEHOLDER',
    serviceFacilityLocationStreet: 'PLACEHOLDER',
    ssn: false,
    totalCharge: 100,
};
