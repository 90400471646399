import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject, from } from 'rxjs';
import { AuthenticationDataStorage } from './authentication/authentication-data.storage';
import { ErrorDialogRef, ErrorDialogService } from './dialogs';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    public signalrUrl: string;

    private hubConnection: signalR.HubConnection;

    private configSection: string;
    private errorDialogRef: ErrorDialogRef;

    private manualClose: boolean;

    constructor(private readonly authDataStorage: AuthenticationDataStorage, private errorDialog: ErrorDialogService) {}

    public startConnection(): Observable<void> {
        this.manualClose = false;
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.signalrUrl, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.authDataStorage.data?.AccessToken}`,
                },
            })
            .withServerTimeout(30000)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => {
                    // Randomize the next retry delay to prevent clients from reconnecting at the same time.
                    if (retryContext.elapsedMilliseconds < 60000) {
                        return Math.random() * 10000;
                    } else {
                        return null;
                    }
                },
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.hubConnection.onreconnecting((error) => {
            console.error('Connection to the server is lost', error);

            this.validateHubConnection('Please wait a moment while we try to reconnect or refresh your browser, and try again.');
        });

        this.hubConnection.onreconnected(() => {
            this.errorDialogRef?.close();
        });

        this.hubConnection.onclose((error) => {
            if (this.manualClose) {
                return;
            }

            console.error('Connection to the server has been lost', error);

            this.validateHubConnection('Please wait a moment, refresh your browser, and try again.');
        });

        return from(this.hubConnection.start());
    }

    public stopConnection(): void {
        this.manualClose = true;
        if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
            this.hubConnection.stop();
        }
    }

    public getConnectionState(): signalR.HubConnectionState {
        return this.hubConnection.state;
    }

    public setConfigSection(section: string): void {
        this.configSection = section;
    }

    public subscribeToEvent<T>(eventName: string): Observable<T> {
        const subject$ = new Subject<T>();

        this.hubConnection.on(eventName, (response: T) => {
            subject$.next(response);
        });

        return subject$.asObservable();
    }

    public registerEventCallback<T>(eventName: string, callback: (response: T) => void): void {
        this.hubConnection.on(eventName, callback);
    }

    public invokeHubMethod(hubMethod: string, message: any): Observable<any> {
        this.validateHubConnection('Please wait a moment, refresh your browser, and try again.');

        return from(this.hubConnection.invoke(hubMethod, message));
    }

    public sendMessage(hubMethod: string, message: any): Observable<any> {
        this.validateHubConnection('Please wait a moment, refresh your browser, and try again.');

        return from(this.hubConnection.send(hubMethod, message));
    }

    private getConfigSectionError(): string {
        return this.configSection ? `${this.configSection} was unable to update. ` : '';
    }

    private validateHubConnection(errorContent: string): void {
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            return;
        }

        this.errorDialogRef?.close();
        this.errorDialogRef = this.errorDialog.open({
            content: `${this.getConfigSectionError()}${errorContent}`,
            close: { hidden: true },
        });
    }
}
