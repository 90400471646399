import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

const SWIPER_SLIDE_CLASS = 'swiper-slide';

@Directive({
    selector: 'symplast-swiper-slide, [symplast-swiper-slide], [symplastSwiperSlide]',
    exportAs: 'symplast-swiper-Slide',
})
export class SwiperSlideDirective implements AfterViewInit {
    constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

    public ngAfterViewInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, SWIPER_SLIDE_CLASS);
    }

    public setSlideUniqueClass(slideClass: string): void {
        this.renderer.addClass(this.elementRef.nativeElement, slideClass);
    }
}
