import { AnimationEvent, trigger } from '@angular/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Type, TemplateRef, Injector, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IAnimationParams, IAnimationState } from '../../../common/interfaces/animation.interface';
import { SidebarPositionEnum } from '../sidebar.interface';
import { SidebarRef } from '../sidebar.reference';
import { AnimationStateEnum, backdropFade, panelFade } from './sidebar-container.animation';
import { SidebarContainerRef } from './sidebar-container.reference';

export interface ISidebarContainer {
    backdropClick$: Observable<MouseEvent>;
    startLeaveAnimation(): void;
}

enum ContentTypeEnum {
    COMPONENT = 'component',
    TEMPLATE = 'template',
}

type ContentType = ContentTypeEnum.COMPONENT | ContentTypeEnum.TEMPLATE;

@Component({
    standalone: true,
    imports: [CommonModule, ScrollingModule],
    templateUrl: './sidebar-container.component.html',
    styleUrls: ['./sidebar-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [trigger('backdropFade', backdropFade), trigger('panelFade', panelFade)],
})
export class SidebarContainerComponent implements ISidebarContainer, AfterViewInit {
    public readonly sidebarPositionEnum = SidebarPositionEnum;
    public readonly contentTypeEnum = ContentTypeEnum;
    public readonly backdropClick$: Observable<MouseEvent>;
    public animationState = AnimationStateEnum.LEAVE;
    private readonly _backdropClick$: Subject<MouseEvent>;

    constructor(
        public readonly injector: Injector,
        public readonly sidebarRef: SidebarRef,
        private readonly sidebarContainerRef: SidebarContainerRef,
        private readonly cdr: ChangeDetectorRef,
    ) {
        this._backdropClick$ = new Subject<MouseEvent>();
        this.backdropClick$ = this._backdropClick$.asObservable();
    }

    public get panelWidth(): string | undefined {
        if (typeof this.sidebarRef.width === 'number') {
            return `${this.sidebarRef.width}px`;
        }

        return this.sidebarRef.width;
    }

    public get contentType(): ContentType {
        if (this.sidebarRef.content instanceof Type) {
            return ContentTypeEnum.COMPONENT;
        } else if ((this.sidebarRef.content as any) instanceof TemplateRef) {
            return ContentTypeEnum.TEMPLATE;
        }

        throw new Error('Sidebar: Invalid content');
    }

    public get panelFadeInAnimation(): IAnimationState {
        return this.animationState === AnimationStateEnum.ENTER
            ? { value: AnimationStateEnum.ENTER }
            : { value: AnimationStateEnum.LEAVE, params: this.panelFadeInAnimationParams };
    }

    private get panelFadeInAnimationParams(): IAnimationParams {
        if (this.sidebarRef.position === SidebarPositionEnum.LEFT) {
            return { translate: 'translate3d(-100%, 0, 0)' };
        }

        return { translate: 'translate3d(100%, 0, 0)' };
    }

    public ngAfterViewInit(): void {
        this.startEnterAnimation();
    }

    public startEnterAnimation(): void {
        this.animationState = AnimationStateEnum.ENTER;
        this.cdr.detectChanges();
    }

    public startLeaveAnimation(): void {
        this.animationState = AnimationStateEnum.LEAVE;
        this.cdr.detectChanges();
    }

    public leaveAnimationDone(event: AnimationEvent): void {
        if (event.fromState === AnimationStateEnum.ENTER && event.toState === AnimationStateEnum.LEAVE) {
            this.sidebarContainerRef.finishClosing();
        }
    }

    public onBackdropClick(event: MouseEvent): void {
        this._backdropClick$.next(event);
    }
}
