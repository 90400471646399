/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionHierarchicalCategory } from '../models/api-response-icollection-hierarchical-category';
import { ApiResponseHierarchicalCategory } from '../models/api-response-hierarchical-category';
import { HierarchicalCategoryCreateRequest } from '../models/hierarchical-category-create-request';
import { HierarchicalCategoryUpdateRequest } from '../models/hierarchical-category-update-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
    providedIn: 'root',
})
class HierarchicalCategoriesService extends __BaseService {
    static readonly GetHierarchicalCategoriesPath = '/HierarchicalCategories';
    static readonly CreateHierarchicalCategoryPath = '/HierarchicalCategories';
    static readonly GetHierarchicalCategoryPath = '/HierarchicalCategories/{categoryId}';
    static readonly UpdateHierarchicalCategoryPath = '/HierarchicalCategories/{categoryId}';
    static readonly DeleteHierarchicalCategoryPath = '/HierarchicalCategories/{categoryId}';

    constructor(config: __Configuration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Get all the hierarchical categories.
     * @param includeDeleted Flag to include in the response categories marked as deleted.
     * @return Success
     */
    GetHierarchicalCategoriesResponse(
        includeDeleted?: boolean,
    ): __Observable<__StrictHttpResponse<ApiResponseICollectionHierarchicalCategory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (includeDeleted != null) __params = __params.set('includeDeleted', includeDeleted.toString());
        let req = new HttpRequest<any>('GET', this.rootUrl + `/HierarchicalCategories`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseICollectionHierarchicalCategory>;
            }),
        );
    }
    /**
     * Get all the hierarchical categories.
     * @param includeDeleted Flag to include in the response categories marked as deleted.
     * @return Success
     */
    GetHierarchicalCategories(includeDeleted?: boolean): __Observable<ApiResponseICollectionHierarchicalCategory> {
        return this.GetHierarchicalCategoriesResponse(includeDeleted).pipe(
            __map((_r) => _r.body as ApiResponseICollectionHierarchicalCategory),
        );
    }

    /**
     * Create a hierarchical category.
     * @param hierarchicalCategory The category.
     * @return Success
     */
    CreateHierarchicalCategoryResponse(
        hierarchicalCategory?: HierarchicalCategoryCreateRequest,
    ): __Observable<__StrictHttpResponse<ApiResponseHierarchicalCategory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = hierarchicalCategory;
        let req = new HttpRequest<any>('POST', this.rootUrl + `/HierarchicalCategories`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseHierarchicalCategory>;
            }),
        );
    }
    /**
     * Create a hierarchical category.
     * @param hierarchicalCategory The category.
     * @return Success
     */
    CreateHierarchicalCategory(hierarchicalCategory?: HierarchicalCategoryCreateRequest): __Observable<ApiResponseHierarchicalCategory> {
        return this.CreateHierarchicalCategoryResponse(hierarchicalCategory).pipe(
            __map((_r) => _r.body as ApiResponseHierarchicalCategory),
        );
    }

    /**
     * Get a hierarchical category by Id.
     * @param categoryId The hierarchical category id.
     * @return Success
     */
    GetHierarchicalCategoryResponse(categoryId: number): __Observable<__StrictHttpResponse<ApiResponseHierarchicalCategory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>('GET', this.rootUrl + `/HierarchicalCategories/${encodeURIComponent(String(categoryId))}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseHierarchicalCategory>;
            }),
        );
    }
    /**
     * Get a hierarchical category by Id.
     * @param categoryId The hierarchical category id.
     * @return Success
     */
    GetHierarchicalCategory(categoryId: number): __Observable<ApiResponseHierarchicalCategory> {
        return this.GetHierarchicalCategoryResponse(categoryId).pipe(__map((_r) => _r.body as ApiResponseHierarchicalCategory));
    }

    /**
     * Update a hierarchical category.
     * @param params The `HierarchicalCategoriesService.UpdateHierarchicalCategoryParams` containing the following parameters:
     *
     * - `categoryId`: The hierarchical category id.
     *
     * - `hierarchicalCategory`: The hierarchical category.
     *
     * @return Success
     */
    UpdateHierarchicalCategoryResponse(
        params: HierarchicalCategoriesService.UpdateHierarchicalCategoryParams,
    ): __Observable<__StrictHttpResponse<ApiResponseHierarchicalCategory>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.hierarchicalCategory;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/HierarchicalCategories/${encodeURIComponent(String(params.categoryId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseHierarchicalCategory>;
            }),
        );
    }
    /**
     * Update a hierarchical category.
     * @param params The `HierarchicalCategoriesService.UpdateHierarchicalCategoryParams` containing the following parameters:
     *
     * - `categoryId`: The hierarchical category id.
     *
     * - `hierarchicalCategory`: The hierarchical category.
     *
     * @return Success
     */
    UpdateHierarchicalCategory(
        params: HierarchicalCategoriesService.UpdateHierarchicalCategoryParams,
    ): __Observable<ApiResponseHierarchicalCategory> {
        return this.UpdateHierarchicalCategoryResponse(params).pipe(__map((_r) => _r.body as ApiResponseHierarchicalCategory));
    }

    /**
     * Delete a hierarchical category by Id.
     * @param params The `HierarchicalCategoriesService.DeleteHierarchicalCategoryParams` containing the following parameters:
     *
     * - `categoryId`: The hierarchical category id.
     *
     * - `includeChildren`: The include Children.
     *
     * @return Success
     */
    DeleteHierarchicalCategoryResponse(
        params: HierarchicalCategoriesService.DeleteHierarchicalCategoryParams,
    ): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.includeChildren != null) __params = __params.set('includeChildren', params.includeChildren.toString());
        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/HierarchicalCategories/${encodeURIComponent(String(params.categoryId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ApiResponseOkResponse>;
            }),
        );
    }
    /**
     * Delete a hierarchical category by Id.
     * @param params The `HierarchicalCategoriesService.DeleteHierarchicalCategoryParams` containing the following parameters:
     *
     * - `categoryId`: The hierarchical category id.
     *
     * - `includeChildren`: The include Children.
     *
     * @return Success
     */
    DeleteHierarchicalCategory(
        params: HierarchicalCategoriesService.DeleteHierarchicalCategoryParams,
    ): __Observable<ApiResponseOkResponse> {
        return this.DeleteHierarchicalCategoryResponse(params).pipe(__map((_r) => _r.body as ApiResponseOkResponse));
    }
}

module HierarchicalCategoriesService {
    /**
     * Parameters for UpdateHierarchicalCategory
     */
    export interface UpdateHierarchicalCategoryParams {
        /**
         * The hierarchical category id.
         */
        categoryId: number;

        /**
         * The hierarchical category.
         */
        hierarchicalCategory?: HierarchicalCategoryUpdateRequest;
    }

    /**
     * Parameters for DeleteHierarchicalCategory
     */
    export interface DeleteHierarchicalCategoryParams {
        /**
         * The hierarchical category id.
         */
        categoryId: number;

        /**
         * The include Children.
         */
        includeChildren?: boolean;
    }
}

export { HierarchicalCategoriesService };
