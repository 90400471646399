/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiPagedResponseICollectionIncomingDocument } from '../models/api-paged-response-icollection-incoming-document';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { IncomingDocumentUpdateRequest } from '../models/incoming-document-update-request';
@Injectable({
  providedIn: 'root',
})
class IncomingDocumentsService extends __BaseService {
  static readonly GetIncomingDocumentsPath = '/Documents/IncomingDocuments';
  static readonly UpdateIncomingDocumentPath = '/Documents/IncomingDocuments/{incomingDocumentId}';
  static readonly DeleteIncomingDocumentPath = '/Documents/IncomingDocuments/{incomingDocumentId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the documents.
   * @param params The `IncomingDocumentsService.GetIncomingDocumentsParams` containing the following parameters:
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `orderDirection`: The order Direction.
   *
   * - `orderBy`: The order By.
   *
   * @return Success
   */
  GetIncomingDocumentsResponse(params: IncomingDocumentsService.GetIncomingDocumentsParams): __Observable<__StrictHttpResponse<ApiPagedResponseICollectionIncomingDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.orderDirection != null) __params = __params.set('orderDirection', params.orderDirection.toString());
    if (params.orderBy != null) __params = __params.set('orderBy', params.orderBy.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Documents/IncomingDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiPagedResponseICollectionIncomingDocument>;
      })
    );
  }
  /**
   * Get the documents.
   * @param params The `IncomingDocumentsService.GetIncomingDocumentsParams` containing the following parameters:
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `orderDirection`: The order Direction.
   *
   * - `orderBy`: The order By.
   *
   * @return Success
   */
  GetIncomingDocuments(params: IncomingDocumentsService.GetIncomingDocumentsParams): __Observable<ApiPagedResponseICollectionIncomingDocument> {
    return this.GetIncomingDocumentsResponse(params).pipe(
      __map(_r => _r.body as ApiPagedResponseICollectionIncomingDocument)
    );
  }

  /**
   * Update incoming document.
   * @param params The `IncomingDocumentsService.UpdateIncomingDocumentParams` containing the following parameters:
   *
   * - `incomingDocumentId`: The document id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  UpdateIncomingDocumentResponse(params: IncomingDocumentsService.UpdateIncomingDocumentParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Documents/IncomingDocuments/${encodeURIComponent(String(params.incomingDocumentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update incoming document.
   * @param params The `IncomingDocumentsService.UpdateIncomingDocumentParams` containing the following parameters:
   *
   * - `incomingDocumentId`: The document id.
   *
   * - `request`: The request.
   *
   * @return Success
   */
  UpdateIncomingDocument(params: IncomingDocumentsService.UpdateIncomingDocumentParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateIncomingDocumentResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Delete incoming document.
   * @param incomingDocumentId The document id.
   * @return Success
   */
  DeleteIncomingDocumentResponse(incomingDocumentId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Documents/IncomingDocuments/${encodeURIComponent(String(incomingDocumentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete incoming document.
   * @param incomingDocumentId The document id.
   * @return Success
   */
  DeleteIncomingDocument(incomingDocumentId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteIncomingDocumentResponse(incomingDocumentId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module IncomingDocumentsService {

  /**
   * Parameters for GetIncomingDocuments
   */
  export interface GetIncomingDocumentsParams {

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * The order Direction.
     */
    orderDirection?: 'Ascending' | 'Descending';

    /**
     * The order By.
     */
    orderBy?: 'ReceivedDate' | 'FileName' | 'Sender';
  }

  /**
   * Parameters for UpdateIncomingDocument
   */
  export interface UpdateIncomingDocumentParams {

    /**
     * The document id.
     */
    incomingDocumentId: number;

    /**
     * The request.
     */
    request?: IncomingDocumentUpdateRequest;
  }
}

export { IncomingDocumentsService }
