import { HttpErrorResponse } from '@angular/common/http';
import { FilterSet } from '@symplast/generated-clients/web-portal';

export class LoadFilterSets {
    static readonly type = '[FilterSets] Load FilterSets';

    constructor(public payload: { section: string }) {}
}

export class LoadFilterSetsSuccess {
    static readonly type = '[FilterSets] Load FilterSets Success';

    constructor(public payload: { section: string; filterSets: FilterSet[] }) {}
}

export class LoadFilterSetsFailure {
    static readonly type = '[FilterSets] Load FilterSets Failure';

    constructor(public payload: { section: string; error: HttpErrorResponse }) {}
}

export class CreateFilterSet {
    static readonly type = '[FilterSets] Create FilterSet';

    constructor(public payload: { filterSet: FilterSet }) {}
}

export class CreateFilterSetSuccess {
    static readonly type = '[FilterSets] Create FilterSet Success';

    constructor(public payload: { filterSet: FilterSet }) {}
}

export class CreateFilterSetFailure {
    static readonly type = '[FilterSets] Create FilterSet Failure';

    constructor(public payload: { error: HttpErrorResponse }) {}
}

export class UpdateFilterSet {
    static readonly type = '[FilterSets] Update FilterSet';

    constructor(public payload: { id: string; filterSet: FilterSet }) {}
}

export class UpdateFilterSetSuccess {
    static readonly type = '[FilterSets] Update FilterSet Success';

    constructor(public payload: { id: string; filterSet: FilterSet }) {}
}

export class UpdateFilterSetFailure {
    static readonly type = '[FilterSets] Update FilterSet Failure';

    constructor(public payload: { id: string; filterSet: FilterSet; error: HttpErrorResponse }) {}
}

export class DeleteFilterSet {
    static readonly type = '[FilterSets] Delete FilterSet';

    constructor(public payload: { id: string }) {}
}

export class DeleteFilterSetSuccess {
    static readonly type = '[FilterSets] Delete FilterSet Success';

    constructor(public payload: { id: string }) {}
}

export class DeleteFilterSetFailure {
    static readonly type = '[FilterSets] Delete FilterSet Failure';

    constructor(public payload: { id: string; error: HttpErrorResponse }) {}
}
