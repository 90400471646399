import { Component, OnDestroy, OnInit } from '@angular/core';
import { PatientsHelperService } from '../../services/patients/patients-helper.service';

@Component({
    standalone: true,
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit, OnDestroy {
    constructor(private patientHelperSvc: PatientsHelperService) {}

    ngOnInit() {
        this.patientHelperSvc.isLoaderShowing = true;
    }

    ngOnDestroy() {
        this.patientHelperSvc.isLoaderShowing = false;
    }
}
