import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ItemDetailsService, ItemGroupsService } from '@symplast/generated-clients/financial';
import { ItemSettingsService } from '@symplast/generated-clients/web-portal';
import { ItemOrItemGroup } from '@symplast/models/shared';
import { EMPTY, forkJoin } from 'rxjs';
import {
    ChangeLocationForFinancialItems,
    FilterByTextFinancialItems,
    FilterFinancialItems,
    LoadFinancialItems,
    ResetFiltersForFinancialItems,
} from './items.actions';
import { IItemsStateModel } from './items.model';

const defaultItemsState = (): IItemsStateModel => {
    return {
        filteredItems: [],
        items: [],
        searchText: '',
        isRelatedToInsurance: undefined,
        selectedBrandId: undefined,
        selectedLocationId: undefined,
        loading: false,
        selectedType: undefined,
        insuranceItemSettings: [],
    };
};

@State<IItemsStateModel>({
    name: 'items',
    defaults: defaultItemsState(),
})
@Injectable()
export class ItemsState {
    private noBrand = -2;
    private readonly availableItemType = [1, 2, 3];

    constructor(
        private itemDetailsService: ItemDetailsService,
        private itemsSettingService: ItemSettingsService,
        private itemGroupsService: ItemGroupsService,
    ) {}

    private static getFilteredItems(context: StateContext<IItemsStateModel>, loadedItems?: ItemOrItemGroup[]): ItemOrItemGroup[] {
        const state = context.getState();

        let items = loadedItems ?? state.items;

        if (state.selectedBrandId) {
            items = items.filter((d) => d.brand?.brandId === state.selectedBrandId?.toString());
        }

        if (state.selectedType !== undefined) {
            items = items.filter((d) => d.itemTypeId === state.selectedType);
        }

        if (state.isRelatedToInsurance !== undefined) {
            items = items.filter((d) => d.hasCptSettings === state.isRelatedToInsurance);
        }

        if (state.searchText && state.searchText !== '') {
            const searchString = state.searchText.toLocaleLowerCase();

            items = items.filter(
                (d) =>
                    (d.description?.toLocaleLowerCase().indexOf(searchString) ?? -1) !== -1 ||
                    (d.name?.toLocaleLowerCase().indexOf(searchString) ?? -1) !== -1 ||
                    d.sku?.toLocaleLowerCase() === searchString ||
                    d.upcCode?.toLocaleLowerCase() === searchString,
            );
        }

        return items;
    }

    @Action(FilterByTextFinancialItems)
    public filterByText(context: StateContext<IItemsStateModel>, { text }: FilterByTextFinancialItems): void {
        const state = context.getState();

        context.patchState({
            searchText: text?.trim(),
        });
        if (state.items.length > 0) {
            context.patchState({
                filteredItems: ItemsState.getFilteredItems(context),
            });
        }
    }

    @Action(ChangeLocationForFinancialItems)
    public changeLocationForFinancialItems(context: StateContext<IItemsStateModel>, { locationId }: ChangeLocationForFinancialItems): void {
        context.patchState({
            selectedLocationId: locationId,
        });

        context.dispatch(new LoadFinancialItems(true));
    }

    @Action(FilterFinancialItems)
    public filterFinancialItems(
        context: StateContext<IItemsStateModel>,
        { brandId, itemType, releatedToInsurance }: FilterFinancialItems,
    ): void {
        context.patchState({
            selectedBrandId: brandId,
            selectedType: itemType,
            isRelatedToInsurance: releatedToInsurance,
        });

        if (context.getState().items.length > 0) {
            context.patchState({
                filteredItems: ItemsState.getFilteredItems(context),
            });
        }
    }

    @Action(ResetFiltersForFinancialItems)
    public resetFiltersForFinancialItems(context: StateContext<IItemsStateModel>): void {
        const needReload = context.getState().selectedLocationId !== undefined;

        context.patchState({
            selectedBrandId: undefined,
            selectedType: undefined,
            isRelatedToInsurance: undefined,
            searchText: '',
            selectedLocationId: undefined,
        });

        if (needReload) {
            context.dispatch(new LoadFinancialItems(needReload));
        } else {
            context.patchState({ filteredItems: ItemsState.getFilteredItems(context) });
        }
    }

    @Action(LoadFinancialItems)
    public load(context: StateContext<IItemsStateModel>, { refresh }: LoadFinancialItems): void {
        const state = context.getState();

        if (state.items.length > 0 && !refresh) {
            return;
        }

        context.patchState({ loading: true });

        const itemsRequest$ = this.itemDetailsService.GetItemDetails({
            IncludePreferredProviderName: false,
            isDeleted: 'False',
            isForSale: 'True',
            locationId: state.selectedLocationId,
            includeTieredPrices: 'True',
        });

        const itemGroupsRequest$ = this.itemGroupsService.GetItemGroups();

        const cptSettingsRequest$ = refresh || state.insuranceItemSettings.length === 0 ? this.itemsSettingService.GetSettings() : EMPTY;

        forkJoin({
            itemsRequest$: itemsRequest$,
            cptSettingsRequest$: cptSettingsRequest$,
            itemGroupsRequest$: itemGroupsRequest$,
        }).subscribe(
            (responses) => {
                if (responses.cptSettingsRequest$?.result) {
                    context.patchState({
                        insuranceItemSettings: responses.cptSettingsRequest$.result,
                    });
                }

                let onlyActiveItems: ItemOrItemGroup[] = (
                    responses?.itemsRequest$?.result?.filter(
                        (d) => d.itemType != null && this.availableItemType.includes(d.itemType.itemTypeId) && d.isForSale,
                    ) ?? []
                ).map(
                    (item) =>
                        ({
                            actualQoH: item.stock,
                            brand: item.brand,
                            description: item.description,
                            flatPrice: item.flatPrice,
                            isForSale: item.isForSale,
                            itemId: item.itemId,
                            itemType: item.itemType?.name,
                            itemTypeId: item.itemType?.itemTypeId,
                            name: item.name,
                            type: 'Item',
                            unit: item.unit,
                            sku: item.sku,
                            upcCode: item.purchaseInfo?.upcCode,
                            minStock: item.minStock,
                        } as ItemOrItemGroup),
                );

                onlyActiveItems = onlyActiveItems
                    .concat(
                        (responses.itemGroupsRequest$?.result ?? [])
                            .filter((d) => d.isForSale)
                            .map((group) => {
                                const description = group.itemGroupDetails?.map((detail) => detail.item?.name).join(', ');

                                return {
                                    brand: { brandId: `${this.noBrand}`, name: 'No Brand' },
                                    description,
                                    flatPrice: group.totalPrice,
                                    itemId: +(group.itemGroupId ?? 0),
                                    items: group.itemGroupDetails,
                                    name: group.name,
                                    unit: 'Group',
                                    type: 'ItemGroup',
                                    itemTypeId: 0,
                                    itemType: 'Item Group',
                                } as ItemOrItemGroup;
                            }),
                    )
                    .sort((a, b) => a.name?.localeCompare(b.name));

                const settings =
                    state.insuranceItemSettings && state.insuranceItemSettings.length > 0
                        ? state.insuranceItemSettings
                        : responses.cptSettingsRequest$.result;

                onlyActiveItems?.forEach((d) => {
                    d.hasCptSettings = settings?.find((setting) => setting.itemId === d.itemId && d.type === 'Item') != null;
                });

                context.patchState({
                    items: onlyActiveItems,
                    loading: false,
                    filteredItems: ItemsState.getFilteredItems(context, onlyActiveItems),
                });
            },
            () => {
                context.patchState({
                    loading: false,
                });
            },
        );
    }
}
