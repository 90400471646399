import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { SwiperBaseDirective } from './swiper-base.directive';
import { SwiperSlideDirective } from './swiper-slide.directive';
import { SwiperOptions } from 'swiper';

const SWIPER_SLIDE_ACTIVE_CLASS = 'symplast-swiper-slide-active';
const SWIPER_SLIDE_NEXT_CLASS = 'symplast-swiper-slide-next';
const SWIPER_SLIDE_PREV_CLASS = 'symplast-swiper-slide-prev';

@Component({
    selector: 'symplast-swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'symplast-swiper',
})
export class SwiperComponent extends SwiperBaseDirective {
    @ContentChildren(SwiperSlideDirective, { descendants: true })
    public slides!: QueryList<SwiperSlideDirective>;

    @ViewChild('swiperPaginationElRef') public swiperPaginationElRef!: ElementRef<HTMLDivElement>;

    // #region public Input properties
    @Input() public id?: string;
    /**
     * Index number of initial slide.
     * @default 0
     */
    @Input() public initialSlide = 0;
    /**
     * Could be 'horizontal' or 'vertical' (for vertical slider).
     * @default 'horizontal'
     */
    @Input() public direction: 'horizontal' | 'vertical' = 'horizontal';
    /**
     * Distance between slides in px.
     * @default 0
     */
    @Input() public spaceBetween = 0;
    /**
     * Background color of swiper
     * @default 'initial'
     */
    @Input() public backgroundColor = 'initial';
    /**
     * Number of slides per view (slides visible at the same time on slider's container).
     * If you use it with "auto" value and along with loop:
     * true then you need to specify loopedSlides parameter with amount of slides to loop (duplicate)
     * slidesPerView: 'auto' is currently not compatible with multirow mode, when slidesPerColumn > 1
     *
     * @default auto
     */
    @Input() public slidesPerView: number | 'auto' = 'auto';
    /**
     * If true, then active slide will be centered, not always on the left side.
     * @default false
     */
    @Input() public centeredSlides = false;
    /**
     * Add (in px) additional slide offset in the beginning of the container (before all slides)
     * @default 0
     */
    @Input() public slidesOffsetBefore = 0;
    /**
     * Add (in px) additional slide offset in the end of the container (after all slides)
     * * @default 0
     */
    @Input() public slidesOffsetAfter = 0;
    /**
     * Duration of transition between slides (in ms)
     * @default 300
     */
    @Input() public speed = 300;
    /**
     * Set to true and slider wrapper will adopt its height to the height of the currently active slide
     * @default false
     */
    @Input() public autoHeight = false;
    /**
     * Transition effect. Could be "slide", "fade", "cube", "coverflow" or "flip"
     * @default 'slide'
     */
    @Input() public effect: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip' = 'slide';
    /**
     * Register event handlers.
     */
    /**
     * Set to true to prevent accidental unwanted clicks on links during swiping
     * @default true
     */
    @Input() public preventClicks = true;

    /**
     * Set to true to stop clicks event propagation on links during swiping
     * @default true
     */
    @Input() public preventClicksPropagation = true;
    /**
     * When enabled Swiper will force to load all images
     * @default true
     */
    @Input() public preloadImages = true;
    /**
     * When enabled Swiper will be reinitialized after all inner images (<img> tags) are loaded.
     * Required preloadImages: true
     * @default true
     */
    @Input() public updateOnImagesReady = true;
    /**
     * Enables continuous loop mode
     * @default false
     */
    @Input() public loop = false;
    /**
     * When enabled it prevents Swiper slide prev/next transitions when transitions is already in progress
     * (has effect when `loop` enabled)
     * @default true
     */
    @Input() public loopPreventsSlide = true;
    /** Class for active slide */
    @Input() public slideActiveClass = SWIPER_SLIDE_ACTIVE_CLASS;
    /** Class for next slide after active */
    @Input() public slideNextClass = SWIPER_SLIDE_NEXT_CLASS;
    /** Class for previous slide after active */
    @Input() public slidePrevClass = SWIPER_SLIDE_PREV_CLASS;
    /**
     * Allows to set different parameter for different responsive breakpoints (screen sizes).
     * Not all parameters can be changed in breakpoints, only those which are not required different layout and logic,
     * like slidesPerView, spaceBetween. Such parameters like loop and effect won't work
     */
    @Input() public breakpoints?: {
        [width: number]: SwiperOptions;
    };
    /**
     * When enabled it won't allow to change slides by swiping or navigation/pagination buttons during transition
     * @default false
     */
    @Input() public preventInteractionOnTransition = false;
    /**
     * Enables pagination bullets
     * @default false
     */
    @Input() public pagination = false;
    // #endregion

    // #region public Output properties
    /** Fired right after Swiper initialization **/
    @Output() public init = new EventEmitter<SwiperOptions>();
    /** Event will be fired right before Swiper destroyed */
    @Output() public beforeDestroy = new EventEmitter<void>();
    /** Event will be fired when currently active slide is changed */
    @Output() public slideChange = new EventEmitter<number>();
    /** Event will be fired when Swiper reach its beginning (initial position) */
    @Output() public reachBeginning = new EventEmitter<void>();
    /** Event will be fired when Swiper reach last slide */
    @Output() public reachEnd = new EventEmitter<void>();
    /** Event will be fired when Swiper goes to beginning or end position */
    @Output() public toEdge = new EventEmitter<void>();
    /** Event will be fired when Swiper goes from beginning or end position */
    @Output() public fromEdge = new EventEmitter<void>();
    /** Event will be fired on breakpoint change **/
    @Output() public breakpointChange = new EventEmitter<SwiperOptions>();
    /** Same as "slideChangeTransitionEnd" but for "forward" direction only */
    @Output() public slideNextTransitionEnd = new EventEmitter<void>();
    /** Same as "slideChangeTransitionEnd" but for "backward" direction only */
    @Output() public slidePrevTransitionEnd = new EventEmitter<void>();

    // #endregion

    //region public Properties
    public get activeIndex(): number {
        return this.swiper?.activeIndex as number;
    }

    public get realIndex(): number | undefined {
        return this.swiper?.realIndex;
    }

    public get isBeginning(): boolean {
        return this.swiper?.isBeginning ?? true;
    }

    public get isEnd(): boolean {
        return this.swiper?.isEnd as boolean;
    }

    public get currentOptions(): SwiperOptions | null {
        return this.swiper?.params || null;
    }
    //endregion

    // #region public Methods
    /**
     * Run transition to next slide.
     *
     * @param speed Transition duration (in ms).
     * @param runCallbacks Set it to false (by default it is true) and transition will
     *  not produce transition events.
     */
    public slideNext(speed?: number, runCallbacks?: boolean): void {
        this.swiper?.slideNext(speed, runCallbacks);
    }

    /**
     * Run transition to previous slide.
     *
     * @param speed Transition duration (in ms).
     * @param runCallbacks Set it to false (by default it is true) and transition will
     *  not produce transition events.
     */
    public slidePrev(speed?: number, runCallbacks?: boolean): void {
        this.swiper?.slidePrev(speed, runCallbacks);
    }

    /**
     * Run transition to the slide with index number equal to 'index' parameter for the
     *  duration equal to 'speed' parameter.
     *
     * @param index Index number of slide.
     * @param speed Transition duration (in ms).
     * @param runCallbacks Set it to false (by default it is true) and transition will
     *  not produce transition events.
     */
    public slideTo(index: number, speed?: number, runCallbacks?: boolean): void {
        this.swiper?.slideTo(index, speed, runCallbacks);
    }

    /**
     * Does the same as .slideTo but for the case when used with enabled loop. So this
     * method will slide to slides with realIndex matching to passed index.
     *
     * @param index Index number of slide.
     * @param speed Transition duration (in ms).
     * @param runCallbacks Set it to false (by default it is true) and transition will
     *  not produce transition events.
     */
    public slideToLoop(index: number, speed?: number, runCallbacks?: boolean): void {
        this.swiper?.slideToLoop(index, speed, runCallbacks);
    }
    // #endregion
}
