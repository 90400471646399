/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionEhrNoteTemplateShort } from '../models/api-response-icollection-ehr-note-template-short';
import { ApiResponseEhrNoteTemplate } from '../models/api-response-ehr-note-template';
import { EhrNoteTemplateCreateOrUpdateRequest } from '../models/ehr-note-template-create-or-update-request';
import { ApiResponseICollectionEhrNoteTemplate } from '../models/api-response-icollection-ehr-note-template';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
@Injectable({
  providedIn: 'root',
})
class EhrNoteTemplatesService extends __BaseService {
  static readonly GetEhrNoteTemplatesPath = '/EhrNoteTemplates';
  static readonly CreateEhrNoteTemplatePath = '/EhrNoteTemplates';
  static readonly GetDetailedEhrNoteTemplatesPath = '/EhrNoteTemplates/Details';
  static readonly GetEhrNoteTemplatePath = '/EhrNoteTemplates/{ehrNoteTemplateId}';
  static readonly UpdateEhrNoteTemplatePath = '/EhrNoteTemplates/{ehrNoteTemplateId}';
  static readonly DeleteEhrNoteTemplatePath = '/EhrNoteTemplates/{ehrNoteTemplateId}';
  static readonly UpdateEhrTemplateActiveStatusPath = '/EhrNoteTemplates/{ehrNoteTemplateId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the list of EHR note templates.
   * @param isActive undefined
   * @return Success
   */
  GetEhrNoteTemplatesResponse(isActive?: boolean): __Observable<__StrictHttpResponse<ApiResponseICollectionEhrNoteTemplateShort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (isActive != null) __params = __params.set('isActive', isActive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/EhrNoteTemplates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionEhrNoteTemplateShort>;
      })
    );
  }
  /**
   * Gets the list of EHR note templates.
   * @param isActive undefined
   * @return Success
   */
  GetEhrNoteTemplates(isActive?: boolean): __Observable<ApiResponseICollectionEhrNoteTemplateShort> {
    return this.GetEhrNoteTemplatesResponse(isActive).pipe(
      __map(_r => _r.body as ApiResponseICollectionEhrNoteTemplateShort)
    );
  }

  /**
   * Create EHR template.
   * @param createRequest The request.
   * @return Success
   */
  CreateEhrNoteTemplateResponse(createRequest?: EhrNoteTemplateCreateOrUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseEhrNoteTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/EhrNoteTemplates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEhrNoteTemplate>;
      })
    );
  }
  /**
   * Create EHR template.
   * @param createRequest The request.
   * @return Success
   */
  CreateEhrNoteTemplate(createRequest?: EhrNoteTemplateCreateOrUpdateRequest): __Observable<ApiResponseEhrNoteTemplate> {
    return this.CreateEhrNoteTemplateResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseEhrNoteTemplate)
    );
  }

  /**
   * Gets the list of detailed EHR note templates.
   * @param isActive undefined
   * @return Success
   */
  GetDetailedEhrNoteTemplatesResponse(isActive?: boolean): __Observable<__StrictHttpResponse<ApiResponseICollectionEhrNoteTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (isActive != null) __params = __params.set('isActive', isActive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/EhrNoteTemplates/Details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionEhrNoteTemplate>;
      })
    );
  }
  /**
   * Gets the list of detailed EHR note templates.
   * @param isActive undefined
   * @return Success
   */
  GetDetailedEhrNoteTemplates(isActive?: boolean): __Observable<ApiResponseICollectionEhrNoteTemplate> {
    return this.GetDetailedEhrNoteTemplatesResponse(isActive).pipe(
      __map(_r => _r.body as ApiResponseICollectionEhrNoteTemplate)
    );
  }

  /**
   * Gets a single EHR note.
   * @param ehrNoteTemplateId The EHR note template id.
   * @return Success
   */
  GetEhrNoteTemplateResponse(ehrNoteTemplateId: number): __Observable<__StrictHttpResponse<ApiResponseEhrNoteTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/EhrNoteTemplates/${encodeURIComponent(String(ehrNoteTemplateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEhrNoteTemplate>;
      })
    );
  }
  /**
   * Gets a single EHR note.
   * @param ehrNoteTemplateId The EHR note template id.
   * @return Success
   */
  GetEhrNoteTemplate(ehrNoteTemplateId: number): __Observable<ApiResponseEhrNoteTemplate> {
    return this.GetEhrNoteTemplateResponse(ehrNoteTemplateId).pipe(
      __map(_r => _r.body as ApiResponseEhrNoteTemplate)
    );
  }

  /**
   * Update EHR template.
   * @param params The `EhrNoteTemplatesService.UpdateEhrNoteTemplateParams` containing the following parameters:
   *
   * - `ehrNoteTemplateId`: The EHR note template id.
   *
   * - `updateRequest`: The request.
   *
   * @return Success
   */
  UpdateEhrNoteTemplateResponse(params: EhrNoteTemplatesService.UpdateEhrNoteTemplateParams): __Observable<__StrictHttpResponse<ApiResponseEhrNoteTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/EhrNoteTemplates/${encodeURIComponent(String(params.ehrNoteTemplateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseEhrNoteTemplate>;
      })
    );
  }
  /**
   * Update EHR template.
   * @param params The `EhrNoteTemplatesService.UpdateEhrNoteTemplateParams` containing the following parameters:
   *
   * - `ehrNoteTemplateId`: The EHR note template id.
   *
   * - `updateRequest`: The request.
   *
   * @return Success
   */
  UpdateEhrNoteTemplate(params: EhrNoteTemplatesService.UpdateEhrNoteTemplateParams): __Observable<ApiResponseEhrNoteTemplate> {
    return this.UpdateEhrNoteTemplateResponse(params).pipe(
      __map(_r => _r.body as ApiResponseEhrNoteTemplate)
    );
  }

  /**
   * Delete EHR template.
   * @param ehrNoteTemplateId The EHR note template id.
   * @return Success
   */
  DeleteEhrNoteTemplateResponse(ehrNoteTemplateId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/EhrNoteTemplates/${encodeURIComponent(String(ehrNoteTemplateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Delete EHR template.
   * @param ehrNoteTemplateId The EHR note template id.
   * @return Success
   */
  DeleteEhrNoteTemplate(ehrNoteTemplateId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteEhrNoteTemplateResponse(ehrNoteTemplateId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Updates an EHR Note Template's IsActive flag.
   * @param params The `EhrNoteTemplatesService.UpdateEhrTemplateActiveStatusParams` containing the following parameters:
   *
   * - `isActive`: The IsActive flag.
   *
   * - `ehrNoteTemplateId`: The template Id.
   *
   * @return Success
   */
  UpdateEhrTemplateActiveStatusResponse(params: EhrNoteTemplatesService.UpdateEhrTemplateActiveStatusParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/EhrNoteTemplates/${encodeURIComponent(String(params.ehrNoteTemplateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Updates an EHR Note Template's IsActive flag.
   * @param params The `EhrNoteTemplatesService.UpdateEhrTemplateActiveStatusParams` containing the following parameters:
   *
   * - `isActive`: The IsActive flag.
   *
   * - `ehrNoteTemplateId`: The template Id.
   *
   * @return Success
   */
  UpdateEhrTemplateActiveStatus(params: EhrNoteTemplatesService.UpdateEhrTemplateActiveStatusParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateEhrTemplateActiveStatusResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }
}

module EhrNoteTemplatesService {

  /**
   * Parameters for UpdateEhrNoteTemplate
   */
  export interface UpdateEhrNoteTemplateParams {

    /**
     * The EHR note template id.
     */
    ehrNoteTemplateId: number;

    /**
     * The request.
     */
    updateRequest?: EhrNoteTemplateCreateOrUpdateRequest;
  }

  /**
   * Parameters for UpdateEhrTemplateActiveStatus
   */
  export interface UpdateEhrTemplateActiveStatusParams {

    /**
     * The IsActive flag.
     */
    isActive: boolean;

    /**
     * The template Id.
     */
    ehrNoteTemplateId: number;
  }
}

export { EhrNoteTemplatesService }
