/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseIEnumerableInsuranceCompany } from '../models/api-response-ienumerable-insurance-company';
import { ApiResponseInsuranceCompany } from '../models/api-response-insurance-company';
import { InsuranceCompanyCreateUpdateRequest } from '../models/insurance-company-create-update-request';
import { ApiResponseInsuranceCompanyExtendedInfo } from '../models/api-response-insurance-company-extended-info';
import { ApiResponseCountResponse } from '../models/api-response-count-response';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponseIEnumerableInsuranceCompanyShortInfo } from '../models/api-response-ienumerable-insurance-company-short-info';
@Injectable({
  providedIn: 'root',
})
class CompaniesService extends __BaseService {
  static readonly GetInsuranceCompanyListPath = '/Insurance/Companies';
  static readonly CreateInsuranceCompanyPath = '/Insurance/Companies';
  static readonly GetInsuranceCompaniesMultiplePath = '/Insurance/Companies/Multiple';
  static readonly GetInsuranceCompanyByIdPath = '/Insurance/Companies/{insuranceCompanyId}';
  static readonly UpdateInsuranceCompanyPath = '/Insurance/Companies/{insuranceCompanyId}';
  static readonly GetInsuranceCompanyCountPath = '/Insurance/Companies/Count';
  static readonly UpdateStatusInsuranceCompanyPath = '/Insurance/Companies/{insuranceCompanyId}/Status';
  static readonly GetInsuranceCompanyByOriginalIdPath = '/Insurance/Companies/Legacy/{originalId}';
  static readonly GetInsuranceCompaniesShortInfoPath = '/Insurance/Companies/Short';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets insurance company list.
   * @param params The `CompaniesService.GetInsuranceCompanyListParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `orderBy`: The field for sort.
   *
   * - `includeDeleted`: If true, include deleted companies.
   *
   * - `direction`: The direction of sort.
   *
   * @return Success
   */
  GetInsuranceCompanyListResponse(params: CompaniesService.GetInsuranceCompanyListParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInsuranceCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.orderBy != null) __params = __params.set('orderBy', params.orderBy.toString());
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    if (params.direction != null) __params = __params.set('direction', params.direction.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableInsuranceCompany>;
      })
    );
  }
  /**
   * Gets insurance company list.
   * @param params The `CompaniesService.GetInsuranceCompanyListParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `pageSize`: The page Size.
   *
   * - `pageIndex`: The page Index.
   *
   * - `orderBy`: The field for sort.
   *
   * - `includeDeleted`: If true, include deleted companies.
   *
   * - `direction`: The direction of sort.
   *
   * @return Success
   */
  GetInsuranceCompanyList(params: CompaniesService.GetInsuranceCompanyListParams): __Observable<ApiResponseIEnumerableInsuranceCompany> {
    return this.GetInsuranceCompanyListResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableInsuranceCompany)
    );
  }

  /**
   * Create the company.
   * @param companyCreateRequest The company to create.
   * @return Success
   */
  CreateInsuranceCompanyResponse(companyCreateRequest?: InsuranceCompanyCreateUpdateRequest): __Observable<__StrictHttpResponse<ApiResponseInsuranceCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = companyCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Insurance/Companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseInsuranceCompany>;
      })
    );
  }
  /**
   * Create the company.
   * @param companyCreateRequest The company to create.
   * @return Success
   */
  CreateInsuranceCompany(companyCreateRequest?: InsuranceCompanyCreateUpdateRequest): __Observable<ApiResponseInsuranceCompany> {
    return this.CreateInsuranceCompanyResponse(companyCreateRequest).pipe(
      __map(_r => _r.body as ApiResponseInsuranceCompany)
    );
  }

  /**
   * Gets insurance company list by a set of Ids.
   * @param insuranceIds The list of company Ids to get.
   * @return Success
   */
  GetInsuranceCompaniesMultipleResponse(insuranceIds?: Array<number>): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInsuranceCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (insuranceIds || []).forEach(val => {if (val != null) __params = __params.append('insuranceIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Companies/Multiple`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableInsuranceCompany>;
      })
    );
  }
  /**
   * Gets insurance company list by a set of Ids.
   * @param insuranceIds The list of company Ids to get.
   * @return Success
   */
  GetInsuranceCompaniesMultiple(insuranceIds?: Array<number>): __Observable<ApiResponseIEnumerableInsuranceCompany> {
    return this.GetInsuranceCompaniesMultipleResponse(insuranceIds).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableInsuranceCompany)
    );
  }

  /**
   * Gets insurance company list.
   * @param insuranceCompanyId The insurance Company Id.
   * @return Success
   */
  GetInsuranceCompanyByIdResponse(insuranceCompanyId: number): __Observable<__StrictHttpResponse<ApiResponseInsuranceCompanyExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Companies/${encodeURIComponent(String(insuranceCompanyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseInsuranceCompanyExtendedInfo>;
      })
    );
  }
  /**
   * Gets insurance company list.
   * @param insuranceCompanyId The insurance Company Id.
   * @return Success
   */
  GetInsuranceCompanyById(insuranceCompanyId: number): __Observable<ApiResponseInsuranceCompanyExtendedInfo> {
    return this.GetInsuranceCompanyByIdResponse(insuranceCompanyId).pipe(
      __map(_r => _r.body as ApiResponseInsuranceCompanyExtendedInfo)
    );
  }

  /**
   * Update the company.
   * @param params The `CompaniesService.UpdateInsuranceCompanyParams` containing the following parameters:
   *
   * - `insuranceCompanyId`: The insurance Company Id.
   *
   * - `companyUpdateRequest`: The company to create.
   *
   * @return Success
   */
  UpdateInsuranceCompanyResponse(params: CompaniesService.UpdateInsuranceCompanyParams): __Observable<__StrictHttpResponse<ApiResponseInsuranceCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.companyUpdateRequest;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Insurance/Companies/${encodeURIComponent(String(params.insuranceCompanyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseInsuranceCompany>;
      })
    );
  }
  /**
   * Update the company.
   * @param params The `CompaniesService.UpdateInsuranceCompanyParams` containing the following parameters:
   *
   * - `insuranceCompanyId`: The insurance Company Id.
   *
   * - `companyUpdateRequest`: The company to create.
   *
   * @return Success
   */
  UpdateInsuranceCompany(params: CompaniesService.UpdateInsuranceCompanyParams): __Observable<ApiResponseInsuranceCompany> {
    return this.UpdateInsuranceCompanyResponse(params).pipe(
      __map(_r => _r.body as ApiResponseInsuranceCompany)
    );
  }

  /**
   * Gets insurance company list.
   * @param params The `CompaniesService.GetInsuranceCompanyCountParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `includeDeleted`: If true, include deleted companies.
   *
   * @return Success
   */
  GetInsuranceCompanyCountResponse(params: CompaniesService.GetInsuranceCompanyCountParams): __Observable<__StrictHttpResponse<ApiResponseCountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Companies/Count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseCountResponse>;
      })
    );
  }
  /**
   * Gets insurance company list.
   * @param params The `CompaniesService.GetInsuranceCompanyCountParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `includeDeleted`: If true, include deleted companies.
   *
   * @return Success
   */
  GetInsuranceCompanyCount(params: CompaniesService.GetInsuranceCompanyCountParams): __Observable<ApiResponseCountResponse> {
    return this.GetInsuranceCompanyCountResponse(params).pipe(
      __map(_r => _r.body as ApiResponseCountResponse)
    );
  }

  /**
   * Update status the company.
   * @param params The `CompaniesService.UpdateStatusInsuranceCompanyParams` containing the following parameters:
   *
   * - `isActive`: The flag to set status.
   *
   * - `insuranceCompanyId`: The insurance Company Id.
   *
   * @return Success
   */
  UpdateStatusInsuranceCompanyResponse(params: CompaniesService.UpdateStatusInsuranceCompanyParams): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/Insurance/Companies/${encodeURIComponent(String(params.insuranceCompanyId))}/Status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * Update status the company.
   * @param params The `CompaniesService.UpdateStatusInsuranceCompanyParams` containing the following parameters:
   *
   * - `isActive`: The flag to set status.
   *
   * - `insuranceCompanyId`: The insurance Company Id.
   *
   * @return Success
   */
  UpdateStatusInsuranceCompany(params: CompaniesService.UpdateStatusInsuranceCompanyParams): __Observable<ApiResponseOkResponse> {
    return this.UpdateStatusInsuranceCompanyResponse(params).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * Gets insurance company list.
   * @param originalId The original Id.
   * @return Success
   */
  GetInsuranceCompanyByOriginalIdResponse(originalId: number): __Observable<__StrictHttpResponse<ApiResponseInsuranceCompanyExtendedInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Companies/Legacy/${encodeURIComponent(String(originalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseInsuranceCompanyExtendedInfo>;
      })
    );
  }
  /**
   * Gets insurance company list.
   * @param originalId The original Id.
   * @return Success
   */
  GetInsuranceCompanyByOriginalId(originalId: number): __Observable<ApiResponseInsuranceCompanyExtendedInfo> {
    return this.GetInsuranceCompanyByOriginalIdResponse(originalId).pipe(
      __map(_r => _r.body as ApiResponseInsuranceCompanyExtendedInfo)
    );
  }

  /**
   * Gets insurance company list.
   * @param params The `CompaniesService.GetInsuranceCompaniesShortInfoParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `pageSize`: The page size.
   *
   * - `pageIndex`: The page index.
   *
   * - `onlyUsed`: The only used.
   *
   * @return Success
   */
  GetInsuranceCompaniesShortInfoResponse(params: CompaniesService.GetInsuranceCompaniesShortInfoParams): __Observable<__StrictHttpResponse<ApiResponseIEnumerableInsuranceCompanyShortInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageIndex != null) __params = __params.set('pageIndex', params.pageIndex.toString());
    if (params.onlyUsed != null) __params = __params.set('onlyUsed', params.onlyUsed.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Insurance/Companies/Short`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseIEnumerableInsuranceCompanyShortInfo>;
      })
    );
  }
  /**
   * Gets insurance company list.
   * @param params The `CompaniesService.GetInsuranceCompaniesShortInfoParams` containing the following parameters:
   *
   * - `query`: The query.
   *
   * - `pageSize`: The page size.
   *
   * - `pageIndex`: The page index.
   *
   * - `onlyUsed`: The only used.
   *
   * @return Success
   */
  GetInsuranceCompaniesShortInfo(params: CompaniesService.GetInsuranceCompaniesShortInfoParams): __Observable<ApiResponseIEnumerableInsuranceCompanyShortInfo> {
    return this.GetInsuranceCompaniesShortInfoResponse(params).pipe(
      __map(_r => _r.body as ApiResponseIEnumerableInsuranceCompanyShortInfo)
    );
  }
}

module CompaniesService {

  /**
   * Parameters for GetInsuranceCompanyList
   */
  export interface GetInsuranceCompanyListParams {

    /**
     * The query.
     */
    query?: string;

    /**
     * The page Size.
     */
    pageSize?: number;

    /**
     * The page Index.
     */
    pageIndex?: number;

    /**
     * The field for sort.
     */
    orderBy?: 'Name' | 'PayerId';

    /**
     * If true, include deleted companies.
     */
    includeDeleted?: boolean;

    /**
     * The direction of sort.
     */
    direction?: 'Asc' | 'Desc';
  }

  /**
   * Parameters for UpdateInsuranceCompany
   */
  export interface UpdateInsuranceCompanyParams {

    /**
     * The insurance Company Id.
     */
    insuranceCompanyId: number;

    /**
     * The company to create.
     */
    companyUpdateRequest?: InsuranceCompanyCreateUpdateRequest;
  }

  /**
   * Parameters for GetInsuranceCompanyCount
   */
  export interface GetInsuranceCompanyCountParams {

    /**
     * The query.
     */
    query?: string;

    /**
     * If true, include deleted companies.
     */
    includeDeleted?: boolean;
  }

  /**
   * Parameters for UpdateStatusInsuranceCompany
   */
  export interface UpdateStatusInsuranceCompanyParams {

    /**
     * The flag to set status.
     */
    isActive: boolean;

    /**
     * The insurance Company Id.
     */
    insuranceCompanyId: number;
  }

  /**
   * Parameters for GetInsuranceCompaniesShortInfo
   */
  export interface GetInsuranceCompaniesShortInfoParams {

    /**
     * The query.
     */
    query?: string;

    /**
     * The page size.
     */
    pageSize?: number;

    /**
     * The page index.
     */
    pageIndex?: number;

    /**
     * The only used.
     */
    onlyUsed?: boolean;
  }
}

export { CompaniesService }
