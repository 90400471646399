import { Subject, filter, map, pairwise, takeUntil } from 'rxjs';
import { WysiwygEditorConfiguration } from '../wysiwyg-editor.configuration';
import { WysiwygAddon } from './wysiwyg.addon';
import { Editor } from 'tinymce';

const CHECKLIST_CLASS = 'symplast-tinymce-checklist';
const CHECKLIST_DATA_ID = 'symplast-tinymce-checklist';
const CHECKLIST_TAGNAME = 'DL';
const CHECKLIST_ITEM_TAGNAME = 'DT';

interface NodeChangeEvent {
    element: Element;
    parents: Node[] | Element[];
    selectionChange?: boolean;
    initial?: boolean;
}

interface NewBlockEvent {
    newBlock: Element;
}

type ChecklistEventName = 'NodeChange' | 'ListMutation' | 'NewBlock';
type ChecklistEventPayload = {
    NodeChange: NodeChangeEvent;
    ListMutation: Event;
    NewBlock: NewBlockEvent;
};

type ChecklistEvent<TEventName extends ChecklistEventName> = {
    eventName: TEventName;
    payload: ChecklistEventPayload[TEventName];
};

export class WysiwygChecklistAddon implements WysiwygAddon {
    public static button = 'symplastChecklist';
    public name = 'symplast-checklist';
    private eventBus$ = new Subject<ChecklistEvent<ChecklistEventName>>();
    private destroyed$ = new Subject<void>();

    public init(config: WysiwygEditorConfiguration): void {
        config.registerSetupCallback((editor) => {
            editor.ui.registry.addToggleButton(WysiwygChecklistAddon.button, {
                icon: 'checklist',
                tooltip: 'Insert check list',
                onAction: (api) => {
                    if (!api.isActive()) {
                        editor.execCommand('InsertDefinitionList', false, {
                            'list-attributes': { class: CHECKLIST_CLASS, 'data-id': CHECKLIST_DATA_ID },
                        });
                    } else {
                        const selectedNode = editor.selection.getNode();
                        const checkbox = selectedNode.querySelector('input[type="checkbox"]');

                        editor.execCommand('RemoveList', false);
                        checkbox?.remove();
                    }
                },
                onSetup: (api) => {
                    const onSetup = this.onSetupCallback(api);
                    const onNodeChange = this.onNodeChangeCallback;
                    const onListMutation = this.onListMutationCallback;
                    const onNewBlock = this.onNewBlockCallback;
                    const onClick = this.onCheckboxClickCallback;

                    editor.on('NodeChange', onSetup);
                    editor.on('NodeChange', onNodeChange);
                    editor.on('ListMutation', onListMutation);
                    editor.on('NewBlock', onNewBlock);
                    editor.on('click', onClick);

                    this.setupOnAddCheckboxListener(editor);
                    this.setupOnRemoveChecklistListener(editor);
                    this.setupOnRemoveCheckboxListener(editor);

                    return () => {
                        editor.off('NodeChange', onSetup);
                        editor.off('NodeChange', onNodeChange);
                        editor.off('ListMutation', onListMutation);
                        editor.off('NewBlock', onNewBlock);
                        editor.off('click', onClick);

                        this.destroyed$.next();
                    };
                },
            });
        });
    }

    private onNodeChangeCallback = (event: NodeChangeEvent): void => this.eventBus$.next({ eventName: 'NodeChange', payload: event });
    private onListMutationCallback = (event: any): void => this.eventBus$.next({ eventName: 'ListMutation', payload: event });
    private onNewBlockCallback = (event: NewBlockEvent): void => this.eventBus$.next({ eventName: 'NewBlock', payload: event });
    // FIXME: (a.vakhrushin) in Firefox event is not fired when checkbox is clicked
    private onCheckboxClickCallback = (event: any): void => {
        if (event.target?.tagName === 'INPUT' && event.target?.type === 'checkbox') {
            // Toggle checkbox state
            if (event.target.checked) {
                event.target.setAttribute('checked', 'checked');
            } else {
                event.target.removeAttribute('checked');
            }
        }
    };

    private onSetupCallback = (api: any) => {
        return ({ parents }: NodeChangeEvent) => {
            api.setActive(this.isInsideChecklist(parents as Element[]));
        };
    };

    private setupOnAddCheckboxListener(editor: Editor): void {
        this.eventBus$
            .pipe(
                pairwise(),
                filter(([prevEvent, currentEvent]) => {
                    const prevEventIsListCreation = this.isEvent<'ListMutation'>(prevEvent, 'ListMutation');
                    const prevEventIsNewBlock = this.isCheckListNewBlock(prevEvent);
                    const currentEventIsNodeChange = this.isCheckListNodeChange(currentEvent);

                    return (prevEventIsListCreation || prevEventIsNewBlock) && currentEventIsNodeChange;
                }),
                map(([_, currentEvent]) => currentEvent as ChecklistEvent<'NodeChange'>),
                takeUntil(this.destroyed$),
            )
            .subscribe((event) => {
                const element = this.getCheckListItemElement(event);

                if (element && !this.hasCheckbox(element)) {
                    const checkbox = document.createElement('input');

                    checkbox.type = 'checkbox';
                    checkbox.style.width = '15px';
                    checkbox.style.height = '15px';

                    element.insertAdjacentElement('afterbegin', checkbox);
                    editor.selection.setCursorLocation(element, 1);
                }
            });
    }

    private setupOnRemoveChecklistListener(editor: Editor): void {
        this.eventBus$
            .pipe(
                pairwise(),
                filter(([prevEvent, currentEvent]) => {
                    const prevEventIsNotListCreation = !this.isEvent<'ListMutation'>(prevEvent, 'ListMutation');
                    const prevEventIsNotNewBlock = !this.isCheckListNewBlock(prevEvent);
                    const currentEventIsNodeChange = this.isCheckListNodeChange(currentEvent);
                    const doesntHasCheckbox = !this.hasCheckbox(this.getCheckListItemElement(currentEvent as ChecklistEvent<'NodeChange'>));

                    return prevEventIsNotListCreation && prevEventIsNotNewBlock && currentEventIsNodeChange && doesntHasCheckbox;
                }),
                map(([_, currentEvent]) => currentEvent as ChecklistEvent<'NodeChange'>),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                editor.execCommand('RemoveList', false);
            });
    }

    private setupOnRemoveCheckboxListener(editor: Editor): void {
        this.eventBus$
            .pipe(
                pairwise(),
                filter(([prevEvent, currentEvent]) => {
                    const prevEventIsChecklistNodeChange = this.isCheckListNodeChange(prevEvent);
                    const currentEventIsNotChecklistNodeChange =
                        !this.isCheckListNodeChange(currentEvent) && this.isEvent<'NodeChange'>(currentEvent, 'NodeChange');
                    const hasCheckbox = this.hasCheckbox(this.getCheckListItemElement(currentEvent as ChecklistEvent<'NodeChange'>));

                    return prevEventIsChecklistNodeChange && currentEventIsNotChecklistNodeChange && hasCheckbox;
                }),
                map(([_, currentEvent]) => currentEvent as ChecklistEvent<'NodeChange'>),
                takeUntil(this.destroyed$),
            )
            .subscribe((event) => {
                const element = this.getCheckListItemElement(event);

                if (element) {
                    const checkbox = element.querySelector('input[type="checkbox"]');

                    checkbox?.remove();
                }
            });
    }

    private isCheckListNewBlock(event: ChecklistEvent<any>): event is ChecklistEvent<'NewBlock'> {
        return (
            this.isEvent<'NewBlock'>(event, 'NewBlock') &&
            this.isCheckListItem(event.payload.newBlock) &&
            this.isCheckList(event.payload.newBlock?.parentElement)
        );
    }

    private isCheckListNodeChange(event: ChecklistEvent<any>): event is ChecklistEvent<'NodeChange'> {
        return (
            this.isEvent<'NodeChange'>(event, 'NodeChange') &&
            this.isCheckListItem(this.getCheckListItemElement(event)) &&
            this.isInsideChecklist(event.payload.parents as Element[])
        );
    }

    private isCheckListItem(element: Element | null): boolean {
        if (!element) {
            return false;
        }

        return element.nodeName === CHECKLIST_ITEM_TAGNAME;
    }

    private isCheckList(element: Element | null): boolean {
        if (!element) {
            return false;
        }

        return element.nodeName === CHECKLIST_TAGNAME;
    }

    private isInsideChecklist(parents: Element[]): boolean {
        return (parents || []).some(
            (parent) => parent.nodeName === CHECKLIST_TAGNAME && parent.getAttribute('data-id') === CHECKLIST_DATA_ID,
        );
    }

    private hasCheckbox(element: Element | null): boolean {
        if (!element) {
            return false;
        }

        return element.querySelector('input[type="checkbox"]') !== null;
    }

    private getCheckListItemElement(event: ChecklistEvent<'NodeChange'>): Element | null {
        return (event.payload.parents || []).find((parent) => parent.nodeName === CHECKLIST_ITEM_TAGNAME) as Element;
    }

    private isEvent<T extends ChecklistEventName>(event: ChecklistEvent<any>, eventName: T): event is ChecklistEvent<T> {
        if (eventName === 'NodeChange') {
            return (
                event.eventName === eventName &&
                event.payload.selectionChange !== true &&
                (event as ChecklistEvent<'NodeChange'>).payload.element.nodeName !== 'BODY'
            );
        }

        return event.eventName === eventName;
    }
}
