import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { FilterSetsState, FilterSetsStateModel } from './filter-sets.state';
import { FilterSet } from '@symplast/generated-clients/web-portal';

@Injectable()
export class FilterSetsSelectors {
    @Selector([FilterSetsState])
    public static filterSets(state: FilterSetsStateModel): (section: string) => FilterSet[] | undefined {
        return (section: string) => {
            if (!state.sections.includes(section)) {
                return undefined;
            }

            return state.ids
                .map((id) => state.entities[id])
                .filter((filterSet) => filterSet.section === section)
                .sort((a, b) => a.name.localeCompare(b.name));
        };
    }
}
