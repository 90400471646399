export interface IRegExpMatch {
    match: string;
    startPosition: number;
    endPosition: number;
}

export function regexpFindAll(inputString: string, regexp: RegExp): IRegExpMatch[] {
    const matches: IRegExpMatch[] = [];
    const regexpGlobal = new RegExp(regexp, 'g');
    let match;

    while ((match = regexpGlobal.exec(inputString)) !== null) {
        const matchedString = match[0];
        const startPosition = match.index;
        const endPosition = match.index + matchedString.length;

        matches.push({ match: matchedString, startPosition, endPosition });
    }

    return matches;
}

/**
 * Replaces all matches of a regular expression with a replacement string.
 * @param {string} replacement - The replacement string.
 * @returns {string} - The input string with all matches replaced with the replacement string.
 */
export function regexpReplaceAll(inputString: string, regexp: RegExp, replacement: string): string {
    return inputString.replace(new RegExp(regexp, 'g'), replacement);
}

/**
 * Wraps all matches of a regular expression with a wrapper function.
 * @param {Function} wrapper - The wrapper function.
 * @returns {string} - The input string with all matches wrapped with the wrapper function.
 */
export function regexpWrapAll(inputString: string, regexp: RegExp, wrapper: (match: string) => string): string {
    return regexpReplaceAll(inputString, regexp, wrapper('$&'));
}
