import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import {
    HierarchicalCategory,
    HierarchicalCategoryCreateRequest,
    HierarchicalCategoryTree,
    HierarchicalCategoriesService,
    ApiResponseHierarchicalCategory,
} from '@symplast/generated-clients/web-portal';
import { Observable, Subscription } from 'rxjs';
import { ICategoryFlatNode } from '@symplast/models/admin';
import { IdValue } from '@symplast/models/shared';
import { NotificationService } from './notification.service';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class CategoriesService {
    public notificationSubscription = new Subscription();

    loading = false;
    selectedCatNode: HierarchicalCategoryTree;
    categoriesTree: HierarchicalCategoryTree[] = [];
    allParentNodes: IdValue[] = [];
    zeroLevelNodes: ICategoryFlatNode[] = [];
    emptyId = -1;

    treeControl = new FlatTreeControl<ICategoryFlatNode>(
        (node) => node.level,
        (node) => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.subCategories,
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl as any, this.treeFlattener);

    constructor(
        private categoriesAPIService: HierarchicalCategoriesService,
        private notificationService: NotificationService,
        private router: Router,
    ) {}

    get categoriesTreeNodes(): ICategoryFlatNode[] {
        return this.treeControl?.dataNodes || [];
    }

    hasChild = (_: number, node: ICategoryFlatNode) => node.expandable;

    getCategoriesTree(): Subscription {
        this.loading = true;

        return this.categoriesAPIService.GetHierarchicalCategories().subscribe((res) => {
            if (res.statusCode === 200) {
                this.categoriesTree = res.result ?? [];
                this.dataSource.data = this.categoriesTree;
                this.treeControl.expandAll();
                this.allParentNodes = this.allNodesInTreeWithPathNames();
                this.setUpZeroLevelCategories();
            }
            this.loading = false;
        });
    }

    setUpZeroLevelCategories(): void {
        const zeroLevelCategoryNodes = this.categoriesTreeNodes
            .filter((cat) => cat.level === 0)
            .map((cat) => {
                cat.rootCategoryId = cat.id;

                return cat;
            });

        zeroLevelCategoryNodes.push({
            id: this.emptyId,
            name: 'Uncategorized',
            parentId: undefined,
            rootCategoryId: this.emptyId,
            expandable: false,
            level: 0,
        });
        this.zeroLevelNodes = zeroLevelCategoryNodes;
    }

    toggleNodeOnClick(node: ICategoryFlatNode): void {
        this.treeControl.toggle(node);
    }

    getCategoryById(id: number): ICategoryFlatNode {
        return (this.dataSource as any)._flattenedData.value.find((category: any) => category.id === id);
    }

    getCategoryBackendById(id: number): Observable<ApiResponseHierarchicalCategory> {
        // needs this list to edit
        return this.categoriesAPIService.GetHierarchicalCategoryById(id);
    }

    createCategory(payload: HierarchicalCategoryCreateRequest, formGroup: FormGroup): void {
        this.loading = true;
        this.categoriesAPIService.CreateHierarchicalCategories(payload).subscribe((res) => {
            if (res.statusCode === 201) {
                formGroup.markAsPristine();
                this.router.navigate(['/Settings/ItemCategories']);
            }
            this.loading = false;
        });
    }

    updateCategory(category: HierarchicalCategory, formGroup: FormGroup): void {
        this.loading = true;
        const payload: HierarchicalCategoriesService.UpdateHierarchicalCategoryParams = {
            id: category.id,
            hierarchicalCategoryUpdateRequest: {
                name: category.name,
                parentId: category.parentId,
            },
        };

        this.categoriesAPIService.UpdateHierarchicalCategory(payload).subscribe((res) => {
            if (res.statusCode) {
                formGroup.markAsPristine();
                this.router.navigate(['/Settings/ItemCategories']);
            }
            this.loading = false;
        });
    }

    allNodesInTreeWithPathNames(): IdValue[] {
        const hierarchyByLevel = {};
        let lastLevel = 0;

        return (this.dataSource as any)._flattenedData.value.map((cat: ICategoryFlatNode) => {
            let pathName = '';
            let currentDepthArray = Object.keys(hierarchyByLevel);

            if (cat.level < lastLevel) {
                for (let i = cat.level; i < currentDepthArray.length; i++) {
                    delete (hierarchyByLevel as any)[i];
                }
            }
            (hierarchyByLevel as any)[cat.level] = cat.name;
            currentDepthArray = Object.keys(hierarchyByLevel);
            currentDepthArray.forEach((level, index) => {
                const pathCharacter = index + 1 === currentDepthArray.length ? '' : ' / ';

                pathName = pathName + (hierarchyByLevel as any)[level] + pathCharacter;
            });

            lastLevel = cat.level;

            return { id: cat.id, name: pathName };
        });
    }

    deleteCategoryById(id: number): void {
        this.loading = true;
        this.categoriesAPIService.DeleteHierarchicalCategory(id).subscribe((res) => {
            if (res.statusCode === 200) {
                this.getCategoriesTree();
            }
            this.loading = false;
        });
    }

    subscribeToApiErrors(): void {
        this.notificationSubscription = this.notificationService.currentNotifications$.subscribe((notification) => {
            if (notification.length) {
                this.loading = false;
            }
        });
    }

    unsubscribeApiErrors(): void {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
    }

    getFullCategoryLabel(id?: number): string {
        if (!id) {
            return '';
        }

        if (id === this.emptyId) {
            return 'Uncategorized';
        }

        const categoriesMap = this.categoriesTreeNodes.reduce((map: any, item) => {
            map[item.id] = item;

            return map;
        }, {});
        const hierarchyNames: string[] = [];
        let currentCategory = categoriesMap[id];

        if (!currentCategory) {
            return '';
        }
        hierarchyNames.push(currentCategory.name);
        while (currentCategory) {
            currentCategory = categoriesMap[currentCategory.parentId];
            if (currentCategory) {
                hierarchyNames.push(currentCategory.name);
            }
        }

        return hierarchyNames.reverse().join(' / ');
    }

    getFullCategoryLabelByName(name?: string): string {
        if (!name) {
            return '';
        }
        const values = this.categoriesTreeNodes.filter((item) => item.name === name);

        if (values.length !== 1) {
            return '';
        }
        const category = values[0];

        return this.getFullCategoryLabel(category.id);
    }

    private _transformer(node: HierarchicalCategoryTree, level: number): ICategoryFlatNode {
        return {
            expandable: !!node.subCategories && node.subCategories.length > 0,
            id: node.id,
            name: node.name,
            parentId: node.parentId,
            level,
        };
    }
}
