import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MAX_CURRENCY_VALUE } from '@symplast/app-constants';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    constructor() {}

    public validateCurrencyAmount(control: FormControl) {
        const currencyVal = +control.value;
        const hasError = !isNaN(currencyVal) && currencyVal < MAX_CURRENCY_VALUE ? null : { invalidAmount: true };

        if (hasError && control.untouched && currencyVal) {
            control.markAllAsTouched();
        }

        return hasError;
    }

    public isNullOrEmpty(obj: any) {
        if (!obj) {
            return true;
        }

        if (typeof obj !== 'object') {
            return false;
        }

        for (const key of Object.keys(obj)) {
            if (obj[key] && !this.isNullOrEmpty(obj[key])) {
                return false;
            }
        }

        return true;
    }

    public get validEmailRegex() {
        return new RegExp('[A-Z0-9a-z\\._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}');
    }

    public get passwordRegex() {
        return new RegExp(/^(?=.*[A-Z])(?=.*[\.\$\^\{\[\(\|\)\*\?\]\}@#-\/:;&"",!'%+=_\\~<>€£¥•])(?=.*[0-9])(?=.*[a-z]).{8,}$/);
    }

    public toUtcDateOnly($event: any, control: AbstractControl) {
        if (!control) {
            return;
        }

        if (!control.errors || !control.errors.matDatepickerParse) {
            const date = moment($event.value);

            control.setValue(date.local().format('YYYY-MM-DDT00:00:00'));
        }
    }

    public toShortDate(date: Date): string {
        if (!date) {
            return '';
        }

        if (typeof date === 'string') {
            date = new Date(date);
        }

        const monthString = `${date.getMonth() + 1}`.padStart(2, '0');
        const dayString = `${date.getDate()}`.padStart(2, '0');
        const shortString = `${date.getFullYear()}-${monthString}-${dayString}`;

        return shortString;
    }

    /* eslint-disable no-bitwise */
    public newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0;

            const v = c === 'x' ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });
    }
    /* eslint-enable no-bitwise */
}

export const stringToUTCDate = (date: string | Date): Date | null => {
    if (date instanceof Date) {
        return date;
    }

    if (/^(\d{4})-(\d{2})-(\d{2})/gm.test(date)) {
        const shortDate = date.split('T')[0];

        return new Date(`${shortDate}T00:00:00`);
    }

    return null;
};

export const stringToUTCTime = (time: string): Date => {
    const currentDateTime = new Date().toISOString();
    const shortDate = currentDateTime.split('T')[0];

    return new Date(`${shortDate}T${time || 'T00:00:00Z'}`);
};

export const clearDisplayNameFromNickname = (displayName: string): string => {
    return displayName.replace(/\"[^)]*\"\s/g, '');
};

export const getBrightness = (color: string): number => {
    if (!color) {
        color = '#FFFFFF';
    }
    const redBrightness = 299;
    const greenBrightness = 587;
    const blueBrightness = 114;

    const redString = color.substring(1, 3);
    const greenString = color.substring(3, 5);
    const blueString = color.substring(5);

    const R = parseInt(`0x${redString}`);
    const G = parseInt(`0x${greenString}`);
    const B = parseInt(`0x${blueString}`);

    const brightness = Math.round((R * redBrightness + G * greenBrightness + B * blueBrightness) / 1000);

    return brightness;
};

export const isBright = (color: string): boolean => {
    return getBrightness(color) > 127;
};

export const getSearchTextRegExp = (value: string): RegExp => {
    return new RegExp(value?.trim()?.replaceAll('\\', '\\\\'), 'i');
};
