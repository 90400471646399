/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApiResponseICollectionSecurityGroup } from '../models/api-response-icollection-security-group';
import { ApiResponseSecurityGroupDetails } from '../models/api-response-security-group-details';
import { SecurityGroupCreateRequest } from '../models/security-group-create-request';
import { ApiResponseOkResponse } from '../models/api-response-ok-response';
import { ApiResponsePermissionDetails } from '../models/api-response-permission-details';
import { ApiResponseSecurityUserDetails } from '../models/api-response-security-user-details';
@Injectable({
  providedIn: 'root',
})
class SecurityService extends __BaseService {
  static readonly GetAllGroupsPath = '/Security/Groups';
  static readonly CreateGroupPath = '/Security/Groups';
  static readonly GetGroupPath = '/Security/Groups/{groupId}';
  static readonly UpdateGroupPath = '/Security/Groups/{groupId}';
  static readonly DeleteGroupPath = '/Security/Groups/{groupId}';
  static readonly GetPermissionsPath = '/Security/Permissions';
  static readonly CurrentUserPath = '/Security/CurrentUser';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The get groups.
   * @return Success
   */
  GetAllGroupsResponse(): __Observable<__StrictHttpResponse<ApiResponseICollectionSecurityGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Security/Groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseICollectionSecurityGroup>;
      })
    );
  }
  /**
   * The get groups.
   * @return Success
   */
  GetAllGroups(): __Observable<ApiResponseICollectionSecurityGroup> {
    return this.GetAllGroupsResponse().pipe(
      __map(_r => _r.body as ApiResponseICollectionSecurityGroup)
    );
  }

  /**
   * The create group.
   * @param createRequest The create request.
   * @return Success
   */
  CreateGroupResponse(createRequest?: SecurityGroupCreateRequest): __Observable<__StrictHttpResponse<ApiResponseSecurityGroupDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Security/Groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSecurityGroupDetails>;
      })
    );
  }
  /**
   * The create group.
   * @param createRequest The create request.
   * @return Success
   */
  CreateGroup(createRequest?: SecurityGroupCreateRequest): __Observable<ApiResponseSecurityGroupDetails> {
    return this.CreateGroupResponse(createRequest).pipe(
      __map(_r => _r.body as ApiResponseSecurityGroupDetails)
    );
  }

  /**
   * The get group.
   * @param groupId The group Id.
   * @return Success
   */
  GetGroupResponse(groupId: number): __Observable<__StrictHttpResponse<ApiResponseSecurityGroupDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Security/Groups/${encodeURIComponent(String(groupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSecurityGroupDetails>;
      })
    );
  }
  /**
   * The get group.
   * @param groupId The group Id.
   * @return Success
   */
  GetGroup(groupId: number): __Observable<ApiResponseSecurityGroupDetails> {
    return this.GetGroupResponse(groupId).pipe(
      __map(_r => _r.body as ApiResponseSecurityGroupDetails)
    );
  }

  /**
   * The update group.
   * @param params The `SecurityService.UpdateGroupParams` containing the following parameters:
   *
   * - `groupId`: The group Id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateGroupResponse(params: SecurityService.UpdateGroupParams): __Observable<__StrictHttpResponse<ApiResponseSecurityGroupDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.updateRequest;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/Security/Groups/${encodeURIComponent(String(params.groupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSecurityGroupDetails>;
      })
    );
  }
  /**
   * The update group.
   * @param params The `SecurityService.UpdateGroupParams` containing the following parameters:
   *
   * - `groupId`: The group Id.
   *
   * - `updateRequest`: The update request.
   *
   * @return Success
   */
  UpdateGroup(params: SecurityService.UpdateGroupParams): __Observable<ApiResponseSecurityGroupDetails> {
    return this.UpdateGroupResponse(params).pipe(
      __map(_r => _r.body as ApiResponseSecurityGroupDetails)
    );
  }

  /**
   * The delete group.
   * @param groupId The group id.
   * @return Success
   */
  DeleteGroupResponse(groupId: number): __Observable<__StrictHttpResponse<ApiResponseOkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Security/Groups/${encodeURIComponent(String(groupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseOkResponse>;
      })
    );
  }
  /**
   * The delete group.
   * @param groupId The group id.
   * @return Success
   */
  DeleteGroup(groupId: number): __Observable<ApiResponseOkResponse> {
    return this.DeleteGroupResponse(groupId).pipe(
      __map(_r => _r.body as ApiResponseOkResponse)
    );
  }

  /**
   * The get permissions.
   * @return Success
   */
  GetPermissionsResponse(): __Observable<__StrictHttpResponse<ApiResponsePermissionDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Security/Permissions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponsePermissionDetails>;
      })
    );
  }
  /**
   * The get permissions.
   * @return Success
   */
  GetPermissions(): __Observable<ApiResponsePermissionDetails> {
    return this.GetPermissionsResponse().pipe(
      __map(_r => _r.body as ApiResponsePermissionDetails)
    );
  }

  /**
   * The current user.
   * @return Success
   */
  CurrentUserResponse(): __Observable<__StrictHttpResponse<ApiResponseSecurityUserDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Security/CurrentUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiResponseSecurityUserDetails>;
      })
    );
  }
  /**
   * The current user.
   * @return Success
   */
  CurrentUser(): __Observable<ApiResponseSecurityUserDetails> {
    return this.CurrentUserResponse().pipe(
      __map(_r => _r.body as ApiResponseSecurityUserDetails)
    );
  }
}

module SecurityService {

  /**
   * Parameters for UpdateGroup
   */
  export interface UpdateGroupParams {

    /**
     * The group Id.
     */
    groupId: number;

    /**
     * The update request.
     */
    updateRequest?: SecurityGroupCreateRequest;
  }
}

export { SecurityService }
