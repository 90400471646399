import { Injectable } from '@angular/core';

import {
    AppointmentsService as LegacyAppointmentsService,
    CitiesService,
    ConfigurationsService,
    EmrNotesService,
    LocationsService,
    PatientsService,
    ReportsService as LegacyReportsService,
    UsersService,
} from '@symplast/generated-clients/legacy';
import {
    ApiResponsePrivateTenantResponse,
    TenantIntegrationRequestsService,
    TenantIntegrationsService,
    TenantsService,
} from '@symplast/generated-clients/tenants';
import { AppointmentRequestsService } from '@symplast/generated-clients/appointments';
import {
    AppointmentsService as BiAppointmentsService,
    CreditNotesService as BiCreditNotesService,
    EstimatesService as BiEstimatesService,
    FinancialTransactionsService as BiFinancialTransactionsService,
    InvoicesService as BiInvoicesService,
    PatientsService as BiPatientsService,
    UserExportsService as BiUserExportsService,
} from '@symplast/generated-clients/bi';
import {
    BaseItemsService,
    BulkInventoryAdjustmentsService,
    CategoriesService,
    CommissionRatesService,
    CreditNotesService,
    EstimatesService,
    GendersService,
    HierarchicalCategoriesService as FinancialHierarchicalCategoriesService,
    InventoryAdjustmentsService,
    InvoicesService,
    ItemDetailsService,
    ItemGroupsService,
    ItemsService,
    ItemTypesService,
    LocalTaxOverridesService,
    LocationItemInventoryService,
    MembershipProgramsService,
    PatientCreditsService,
    PaymentModesService,
    PracticeSettingsService,
    ReportsService,
    TieredPricingSchedulesService,
    TransactionTypesService,
    UnitsService,
} from '@symplast/generated-clients/financial';
import { CampaignFilesService, CampaignsService } from '@symplast/generated-clients/marketing';
import { AlbumsService, FilesService, TagsService } from '@symplast/generated-clients/multimedia';
import {
    ActivityNotesService,
    ActivitySystemTypeService,
    ActivityTypesService,
    AddressService,
    AllocatedPaymentsService,
    AppointmentPurposesService,
    AppointmentServicesService,
    AppointmentsService,
    AppointmentStagesService,
    AppointmentStatusesService,
    AppointmentTypesPurposesService,
    AppointmentTypesService,
    ApxItemsService,
    AuditRecordsService,
    BrandsService,
    CalendarBlocksService,
    CasesService,
    ChargesService,
    ClaimsService,
    ClaimStatusesService,
    CompaniesService,
    CompanyService,
    ConfigurationSettingsService,
    DisclaimersService,
    DiscountReasonsService,
    DocumentsService,
    DocumentTypesService,
    EhrNotesService,
    EligibilityService,
    FiltersService,
    FinancialTagsService,
    FormsService,
    GaldermaAspireService,
    GlobalTaxDefaultsService,
    HierarchicalCategoriesService,
    IncomingDocumentsService,
    InsuranceService as WebApiInsuranceService,
    InvitesService,
    InvoicesService as WebApiInvoicesService,
    ItemsCptCodesService,
    ItemSettingsService,
    ItemsIcdCodesService,
    ItemsService as WebApiItemsService,
    LegalTextsService,
    LocationsService as WebApiLocationService,
    ManagementGroupsService,
    NdcService,
    NotificationsService,
    OccasionsService,
    OnlineAppointmentRequestsService,
    PatientBalanceService,
    PatientsHealthHistoryService,
    PatientsService as PatientsServiceWebApi,
    PaymentModesService as PaymentModesServiceWebApi,
    PaymentReasonsService,
    PaymentsService,
    PowerBIService,
    PracticeSettingsService as PracticeSettingsServiceWebApi,
    PriceListService,
    ReferralSourcesService,
    ReferringProvidersService,
    RemittanceFilesService,
    RemittancesService,
    ReportFiltersService,
    ReportsService as WebApiReportService,
    ScheduleTemplatesService,
    SecurityService,
    SimpayService,
    SubmissionsService,
    TasksService,
    TaxAuthoritiesService,
    TaxesService,
    TaxGroupsService,
    TaxRatesService,
    TeamsService,
    TemplatesService,
    UserDictionariesService,
    UserPasswordManagementService,
    UsersService as UsersServiceWebApi,
    KiosksService,
    PracticeLicensesService,
    LicenseGroupsService,
    PlivoVerificationService,
    AppointmentNotificationsService,
    RecurringPaymentsService,
    FinancialReportsService,
    DoseSpotService,
    ConsentFormsService,
    PatientReferralSettingsService,
    ResourceItemsService,
    EhrNoteTemplatesService,
} from '@symplast/generated-clients/web-portal';
import { TenantsEssentials } from 'apps/webportal/src/app/core/services/tenants-essentials.service';
import { SignalRService } from 'apps/webportal/src/app/core/services/signalr.service';

// TODO: (a.vakhrushin) Circular file chain
import { environment } from 'apps/webportal/src/environments/environment';
import { ITenant } from '@symplast/models/shared';
import { PrivateSettingsService } from '../services/private-settings.service';
import { InvoicePdfService } from '../services/invoice-pdf.service';
import { Store } from '@ngxs/store';
import { SetTenant } from '../stores';
import { Observable, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationApplicationService {
    constructor(
        private configurationsService: ConfigurationsService,
        private tenantsService: TenantsService,
        private usersService: UsersService,
        private reportsService: ReportsService,
        private albumsService: AlbumsService,
        private integrationService: TenantIntegrationsService,
        private integrationRequestsService: TenantIntegrationRequestsService,
        private filesService: FilesService,
        private tagsService: TagsService,
        private transactionTypesService: TransactionTypesService,
        private paymentModesService: PaymentModesService,
        private locationsService: LocationsService,
        private patientsService: PatientsService,
        private biAppointmentsService: BiAppointmentsService,
        private biPatientsService: BiPatientsService,
        private biCreditNotesService: BiCreditNotesService,
        private biEstimatesService: BiEstimatesService,
        private biInvoicesService: BiInvoicesService,
        private biFinancialTransactionsService: BiFinancialTransactionsService,
        private biUserExportsService: BiUserExportsService,
        private appointmentTypesService: AppointmentTypesService,
        private appointmentServicesService: AppointmentServicesService,
        private appointmentPurposesService: AppointmentPurposesService,
        private appointmentTypesPurposesService: AppointmentTypesPurposesService,
        private appointmentRequestsService: AppointmentRequestsService,
        private templatesService: TemplatesService,
        private eligibilityService: EligibilityService,
        private notificationsService: NotificationsService,
        private webApiLocationService: WebApiLocationService,
        private addressService: AddressService,
        private companyService: CompanyService,
        private configurationSettingsService: ConfigurationSettingsService,
        private webApiReportService: WebApiReportService,
        private webApiInsuranceService: WebApiInsuranceService,
        private appointmentsService: AppointmentsService,
        private appointmentStagesService: AppointmentStagesService,
        private appointmentStatusesService: AppointmentStatusesService,
        private membershipProgramsService: MembershipProgramsService,
        private claimsService: ClaimsService,
        private casesService: CasesService,
        private invoicesService: InvoicesService,
        private estimatesService: EstimatesService,
        private creditNotesService: CreditNotesService,
        private powerBIService: PowerBIService,
        private hierarchicalCategoriesService: HierarchicalCategoriesService,
        private referralSourcesService: ReferralSourcesService,
        private remittancesService: RemittancesService,
        private chargesService: ChargesService,
        private privateSettingsService: PrivateSettingsService,
        private itemsService: ItemsService,
        private legacyAppointmentsService: LegacyAppointmentsService,
        private itemSettingsService: ItemSettingsService,
        private invoicePdfService: InvoicePdfService,
        private activityTypesService: ActivityTypesService,
        private baseItemsService: BaseItemsService,
        private itemTypesService: ItemTypesService,
        private brandsService: BrandsService,
        private legalTextsService: LegalTextsService,
        private taxGroupsService: TaxGroupsService,
        private taxesService: TaxesService,
        private taxAuthoritiesService: TaxAuthoritiesService,
        private categoriesService: CategoriesService,
        private financialHierarchicalCategoriesService: FinancialHierarchicalCategoriesService,
        private unitsService: UnitsService,
        private gendersService: GendersService,
        private locationInventoryService: LocationItemInventoryService,
        private inventoryAdjustmentsService: InventoryAdjustmentsService,
        private localTaxOverridesService: LocalTaxOverridesService,
        private taxRatesService: TaxRatesService,
        private globalTaxDefaultsService: GlobalTaxDefaultsService,
        private itemGroupsService: ItemGroupsService,
        private itemDetailsService: ItemDetailsService,
        private activityNotesService: ActivityNotesService,
        private itemsCptCodesService: ItemsCptCodesService,
        private itemsIcdCodesService: ItemsIcdCodesService,
        private tieredPricingSchedulesService: TieredPricingSchedulesService,
        private bulkInventoryAdjustmentsService: BulkInventoryAdjustmentsService,
        private practiceSettingsService: PracticeSettingsService,
        private tasksService: TasksService,
        private commissionRatesService: CommissionRatesService,
        private managementGroupsService: ManagementGroupsService,
        private legacyReportsService: LegacyReportsService,
        private campaignsService: CampaignsService,
        private campaignFilesService: CampaignFilesService,
        private filtersService: FiltersService,
        private patientsServiceWebApi: PatientsServiceWebApi,
        private usersServiceWebApi: UsersServiceWebApi,
        private ehrNotesService: EhrNotesService,
        private patientsHealthHistoryService: PatientsHealthHistoryService,
        private discountReasonsService: DiscountReasonsService,
        private galdermaAspireService: GaldermaAspireService,
        private companiesService: CompaniesService,
        private formsService: FormsService,
        private invitesService: InvitesService,
        private emrNotesService: EmrNotesService,
        private submissionsService: SubmissionsService,
        private patientCreditsService: PatientCreditsService,
        private auditRecordsService: AuditRecordsService,
        private securityService: SecurityService,
        private scheduleTemplatesService: ScheduleTemplatesService,
        private calendarBlocksService: CalendarBlocksService,
        private userDictionariesService: UserDictionariesService,
        private userPasswordManagementService: UserPasswordManagementService,
        private paymentModesServiceWebApi: PaymentModesServiceWebApi,
        private referringProvidersService: ReferringProvidersService,
        private incomingDocumentsService: IncomingDocumentsService,
        private documentTypesService: DocumentTypesService,
        private remittanceFilesService: RemittanceFilesService,
        private financialTagsService: FinancialTagsService,
        private paymentReasonsService: PaymentReasonsService,
        private patientBalancesService: PatientBalanceService,
        private documentsService: DocumentsService,
        private citiesService: CitiesService,
        private occasionsService: OccasionsService,
        private oarService: OnlineAppointmentRequestsService,
        private apxItemsService: ApxItemsService,
        private store: Store,
        private priceListService: PriceListService,
        private teamsService: TeamsService,
        private disclaimersService: DisclaimersService,
        private simpayService: SimpayService,
        private ndcService: NdcService,
        private claimStatusesService: ClaimStatusesService,
        private webApiInvoicesService: WebApiInvoicesService,
        private paymentsService: PaymentsService,
        private webApiItemsService: WebApiItemsService,
        private allocatedPaymentsService: AllocatedPaymentsService,
        private practiceSettingsServiceWebApi: PracticeSettingsServiceWebApi,
        private reportFiltersService: ReportFiltersService,
        private activitySystemTypeService: ActivitySystemTypeService,
        private kiosksService: KiosksService,
        private signalRService: SignalRService,
        private practiceLicensesService: PracticeLicensesService,
        private licenseGroupsService: LicenseGroupsService,
        private plivoVerificationService: PlivoVerificationService,
        private appointmentNotificationsService: AppointmentNotificationsService,
        private recurringPaymentsService: RecurringPaymentsService,
        private financialReportService: FinancialReportsService,
        private doseSpotService: DoseSpotService,
        private consentFormsService: ConsentFormsService,
        private patientReferralSettingsService: PatientReferralSettingsService,
        private resourceItemsService: ResourceItemsService,
        private ehrNoteTemplatesService: EhrNoteTemplatesService,
    ) {}

    public static webAppFactory(service: ConfigurationApplicationService): Function {
        return () => service.initWebApp();
    }

    public initWebApp(): void {
        this.tenantsService.rootUrl = environment.apiTenantsUrlNoAuth;
        this.userPasswordManagementService.rootUrl = environment.nwpApiUrlNoAuth;
    }

    public configureAppServices(tenant: TenantsEssentials): Observable<ApiResponsePrivateTenantResponse> {
        this.configurationsService.rootUrl = tenant.tenantApiUrl;
        this.usersService.rootUrl = tenant.tenantApiUrl;
        if (tenant.tenantSettingsApi) {
            this.tenantsService.rootUrl = tenant.tenantSettingsApi;
        }

        // TODO: (a.vakhrushin) add catchError and define what to do in that case
        return this.tenantsService.GetSinglePrivate(tenant.tenantId).pipe(tap((tenantResponse) => this.retrieveRootUrls(tenantResponse)));
    }

    private retrieveRootUrls(tenantResponse: ApiResponsePrivateTenantResponse): void {
        if (!tenantResponse) {
            return;
        }
        if (
            !tenantResponse.result ||
            !tenantResponse.result.webPortalApi ||
            !tenantResponse.result.financials ||
            !tenantResponse.result.multimedia ||
            !tenantResponse.result.businessIntelligence ||
            !tenantResponse.result.marketing ||
            !tenantResponse.result.intakes ||
            !tenantResponse.result.tenantConfiguration ||
            !tenantResponse.result.appointmentRequests ||
            !tenantResponse.result.insurance
        ) {
            return;
        }
        const webPortalUrl = `${tenantResponse.result.webPortalUrl}/service`;
        const webPortalApiUrl = `${tenantResponse.result.webPortalApi.url}`;
        const financialApiUrl = `${tenantResponse.result.financials.url}`;
        const multimediaApiUrl = `${tenantResponse.result.multimedia.url}`;
        const biApiUrl = `${tenantResponse.result.businessIntelligence.url}`;
        const marketingApiUrl = `${tenantResponse.result.marketing.url}`;
        const tenantsConfigurationUrl = `${tenantResponse.result.tenantConfiguration.url}`;
        const appointmentRequestsUrl = `${tenantResponse.result.appointmentRequests.url}`;
        const signalRHubsUrl = `${tenantResponse.result.kioskHubUrl}`;

        this.tenantsService.rootUrl = tenantsConfigurationUrl;

        const currentTenant: ITenant = {
            name: tenantResponse.result.name ?? '',
            logoUrl: tenantResponse.result.logoUrl ?? '',
            defaultIntakeProvider: tenantResponse.result.intakes.defaultIntakeProvider,
        };

        // Configurations
        this.integrationService.rootUrl = tenantsConfigurationUrl;
        this.integrationRequestsService.rootUrl = tenantsConfigurationUrl;
        this.appointmentRequestsService.rootUrl = appointmentRequestsUrl;
        this.eligibilityService.rootUrl = webPortalApiUrl;
        this.privateSettingsService.weightLossManagementEnabled = tenantResponse.result.weightLossManagement?.isEnabled || false;
        this.privateSettingsService.wieghtLossManagementUrl = tenantResponse.result.weightLossManagement?.url;
        this.privateSettingsService.setMultiFactorAuthenticationFeatureState(
            tenantResponse.result.infrastructure?.securityConfiguration?.multiFactorAuthenticationEnabled,
        );
        this.privateSettingsService.insuranceEnabled = tenantResponse.result.insurance.enabled;
        this.privateSettingsService.insuranceContract = tenantResponse.result.insurance.contractStatus;
        this.privateSettingsService.financialsType = tenantResponse.result.financials.type;
        this.privateSettingsService.scheduleTemplatesEnabled =
            tenantResponse.result.apps?.practice?.isScheduleTemplatesFeatureEnabled ?? false;
        this.privateSettingsService.surchargesEnabled = tenantResponse.result.apps?.practice?.isSurchargesFeatureEnabled ?? false;
        this.privateSettingsService.kiosksEnabled = tenantResponse.result.apps?.practice?.isKioskFeatureEnabled ?? false;
        this.privateSettingsService.isRecurringPaymentsEnabled =
            tenantResponse.result.apps?.practice?.isRecurringPaymentsFeatureEnabled ?? false;
        this.privateSettingsService.calendarBlockStatus = tenantResponse.result.calendarBlocks?.isCalendarBlocksFeatureEnabled
            ? tenantResponse.result.calendarBlocks?.calendarBlockStatus ?? 'Inactive'
            : 'Inactive';
        this.privateSettingsService.calendarBlocksFeatureEnabled = !!tenantResponse.result.calendarBlocks?.isCalendarBlocksFeatureEnabled;
        this.privateSettingsService.IsCalendarBlocksRollOutModeAutomated = !!tenantResponse.result.calendarBlocks?.isRollOutModeAutomated;
        this.privateSettingsService.doseSpotUseV2 = tenantResponse.result?.infrastructure?.doseSpot?.useV2 || false;
        this.privateSettingsService.setFeatureFlags(tenantResponse.result.features);
        this.privateSettingsService.setCurrentTenant(currentTenant);

        // Marketing API
        this.campaignsService.rootUrl = marketingApiUrl;
        this.campaignFilesService.rootUrl = marketingApiUrl;

        // Multimedia API
        this.albumsService.rootUrl = multimediaApiUrl;
        this.filesService.rootUrl = multimediaApiUrl;
        this.tagsService.rootUrl = multimediaApiUrl;

        // BI API
        this.biPatientsService.rootUrl = biApiUrl;
        this.biAppointmentsService.rootUrl = biApiUrl;
        this.biCreditNotesService.rootUrl = biApiUrl;
        this.biEstimatesService.rootUrl = biApiUrl;
        this.biInvoicesService.rootUrl = biApiUrl;
        this.biFinancialTransactionsService.rootUrl = biApiUrl;
        this.biUserExportsService.rootUrl = biApiUrl;

        // Financial API
        this.invoicePdfService.rootUrl = financialApiUrl;
        this.itemsService.rootUrl = financialApiUrl;
        this.baseItemsService.rootUrl = financialApiUrl;
        this.itemTypesService.rootUrl = financialApiUrl;
        this.categoriesService.rootUrl = financialApiUrl;
        this.financialHierarchicalCategoriesService.rootUrl = financialApiUrl;
        this.unitsService.rootUrl = financialApiUrl;
        this.gendersService.rootUrl = financialApiUrl;
        this.locationInventoryService.rootUrl = financialApiUrl;
        this.inventoryAdjustmentsService.rootUrl = financialApiUrl;
        this.localTaxOverridesService.rootUrl = financialApiUrl;
        this.itemGroupsService.rootUrl = financialApiUrl;
        this.itemDetailsService.rootUrl = financialApiUrl;
        this.tieredPricingSchedulesService.rootUrl = financialApiUrl;
        this.bulkInventoryAdjustmentsService.rootUrl = financialApiUrl;
        this.practiceSettingsService.rootUrl = financialApiUrl;
        this.commissionRatesService.rootUrl = financialApiUrl;
        this.transactionTypesService.rootUrl = financialApiUrl;
        this.paymentModesService.rootUrl = financialApiUrl;
        this.reportsService.rootUrl = financialApiUrl;
        this.patientCreditsService.rootUrl = financialApiUrl;
        this.membershipProgramsService.rootUrl = financialApiUrl;
        this.invoicesService.rootUrl = financialApiUrl;
        this.estimatesService.rootUrl = financialApiUrl;
        this.creditNotesService.rootUrl = financialApiUrl;

        // Financials API moved to WP API items
        this.legalTextsService.rootUrl = webPortalApiUrl;
        this.taxAuthoritiesService.rootUrl = webPortalApiUrl;
        this.taxGroupsService.rootUrl = webPortalApiUrl;
        this.taxRatesService.rootUrl = webPortalApiUrl;
        this.taxesService.rootUrl = webPortalApiUrl;
        this.brandsService.rootUrl = webPortalApiUrl;
        this.globalTaxDefaultsService.rootUrl = financialApiUrl;
        this.apxItemsService.rootUrl = webPortalApiUrl;
        this.webApiInvoicesService.rootUrl = webPortalApiUrl;
        this.financialReportService.rootUrl = webPortalApiUrl;

        // Web Portal API
        // Insurance services
        this.filtersService.rootUrl = webPortalApiUrl;
        this.patientsServiceWebApi.rootUrl = webPortalApiUrl;
        this.ehrNotesService.rootUrl = webPortalApiUrl;
        this.patientsHealthHistoryService.rootUrl = webPortalApiUrl;
        this.auditRecordsService.rootUrl = webPortalApiUrl;
        this.webApiInsuranceService.rootUrl = webPortalApiUrl;
        this.claimsService.rootUrl = webPortalApiUrl;
        this.casesService.rootUrl = webPortalApiUrl;
        this.remittancesService.rootUrl = webPortalApiUrl;
        this.chargesService.rootUrl = webPortalApiUrl;
        this.itemsCptCodesService.rootUrl = webPortalApiUrl;
        this.itemsIcdCodesService.rootUrl = webPortalApiUrl;
        this.itemSettingsService.rootUrl = webPortalApiUrl;
        this.paymentModesServiceWebApi.rootUrl = webPortalApiUrl;
        this.referringProvidersService.rootUrl = webPortalApiUrl;
        this.remittanceFilesService.rootUrl = webPortalApiUrl;
        this.occasionsService.rootUrl = webPortalApiUrl;
        this.priceListService.rootUrl = webPortalApiUrl;
        this.simpayService.rootUrl = webPortalApiUrl;
        this.ndcService.rootUrl = webPortalApiUrl;
        this.claimStatusesService.rootUrl = webPortalApiUrl;
        this.paymentsService.rootUrl = webPortalApiUrl;
        this.allocatedPaymentsService.rootUrl = webPortalApiUrl;
        this.reportFiltersService.rootUrl = webPortalApiUrl;
        this.activitySystemTypeService.rootUrl = webPortalApiUrl;
        this.practiceLicensesService.rootUrl = webPortalApiUrl;
        this.licenseGroupsService.rootUrl = webPortalApiUrl;
        this.plivoVerificationService.rootUrl = webPortalApiUrl;
        this.appointmentNotificationsService.rootUrl = webPortalApiUrl;
        this.resourceItemsService.rootUrl = webPortalApiUrl;

        // Other
        this.discountReasonsService.rootUrl = webPortalApiUrl;
        this.webApiReportService.rootUrl = webPortalApiUrl;
        this.galdermaAspireService.rootUrl = webPortalApiUrl;
        this.companiesService.rootUrl = webPortalApiUrl;
        this.securityService.rootUrl = webPortalApiUrl;
        this.managementGroupsService.rootUrl = webPortalApiUrl;
        this.financialTagsService.rootUrl = webPortalApiUrl;
        this.activityTypesService.rootUrl = webPortalApiUrl;
        this.activityNotesService.rootUrl = webPortalApiUrl;
        this.notificationsService.rootUrl = webPortalApiUrl;
        this.addressService.rootUrl = webPortalApiUrl;
        this.companyService.rootUrl = webPortalApiUrl;
        this.webApiLocationService.rootUrl = webPortalApiUrl;
        this.templatesService.rootUrl = webPortalApiUrl;
        this.configurationSettingsService.rootUrl = webPortalApiUrl;
        this.appointmentsService.rootUrl = webPortalApiUrl;
        this.appointmentStagesService.rootUrl = webPortalApiUrl;
        this.appointmentStatusesService.rootUrl = webPortalApiUrl;
        this.powerBIService.rootUrl = webPortalApiUrl;
        this.hierarchicalCategoriesService.rootUrl = webPortalApiUrl;
        this.referralSourcesService.rootUrl = webPortalApiUrl;
        this.tasksService.rootUrl = webPortalApiUrl;
        this.formsService.rootUrl = webPortalApiUrl;
        this.invitesService.rootUrl = webPortalApiUrl;
        this.submissionsService.rootUrl = webPortalApiUrl;
        this.scheduleTemplatesService.rootUrl = webPortalApiUrl;
        this.calendarBlocksService.rootUrl = webPortalApiUrl;
        this.userDictionariesService.rootUrl = webPortalApiUrl;
        this.userPasswordManagementService.rootUrl = webPortalApiUrl;
        this.usersServiceWebApi.rootUrl = webPortalApiUrl;
        this.incomingDocumentsService.rootUrl = webPortalApiUrl;
        this.documentTypesService.rootUrl = webPortalApiUrl;
        this.patientBalancesService.rootUrl = webPortalApiUrl;
        this.documentsService.rootUrl = webPortalApiUrl;
        this.appointmentTypesService.rootUrl = webPortalApiUrl;
        this.appointmentServicesService.rootUrl = webPortalApiUrl;
        this.appointmentPurposesService.rootUrl = webPortalApiUrl;
        this.appointmentTypesPurposesService.rootUrl = webPortalApiUrl;
        this.oarService.rootUrl = webPortalApiUrl;
        this.teamsService.rootUrl = webPortalApiUrl;
        this.disclaimersService.rootUrl = webPortalApiUrl;
        this.webApiItemsService.rootUrl = webPortalApiUrl;
        this.practiceSettingsServiceWebApi.rootUrl = webPortalApiUrl;
        this.paymentReasonsService.rootUrl = webPortalApiUrl;
        this.kiosksService.rootUrl = webPortalApiUrl;
        this.signalRService.signalrUrl = signalRHubsUrl ?? environment.signalrUrl;
        this.doseSpotService.rootUrl = webPortalApiUrl;
        this.consentFormsService.rootUrl = webPortalApiUrl;
        this.patientReferralSettingsService.rootUrl = webPortalApiUrl;
        this.recurringPaymentsService.rootUrl = webPortalApiUrl;
        this.ehrNoteTemplatesService.rootUrl = webPortalApiUrl;

        // Legacy
        this.emrNotesService.rootUrl = webPortalUrl;
        this.legacyAppointmentsService.rootUrl = webPortalUrl;
        this.legacyReportsService.rootUrl = webPortalUrl;
        this.locationsService.rootUrl = webPortalUrl;
        this.patientsService.rootUrl = webPortalUrl;
        this.citiesService.rootUrl = webPortalUrl;

        this.store.dispatch(new SetTenant(tenantResponse.result.tenantId, tenantResponse.result.name as string));
    }
}
