import { Injectable, inject } from '@angular/core';
import { ConfirmationDialogRef, ConfirmationDialogService } from './dialogs';
import moment from 'moment';
import { PasswordResetRequiredStorage } from './password-reset-required.storage';

const getPromptText = (daysToRequiredChange: number): string =>
    daysToRequiredChange > 0
        ? `To continue using Symplast your password must be changed within the next ${daysToRequiredChange} days.`
        : 'You have less than 24 hours before you will need to update your password.';

function daysUntilDate(_targetDate: Date): number {
    const currentDate = moment();
    const targetDate = moment(_targetDate);

    // Calculate the difference in days between the current date and the target date
    const daysRemaining: number = Math.floor(targetDate.diff(currentDate, 'days', true));

    return daysRemaining >= 0 ? daysRemaining : 0;
}

@Injectable({ providedIn: 'root' })
export class PasswordResetPromptService {
    private confirmationDialog = inject(ConfirmationDialogService);
    private passwordResetRequiredStorage = inject(PasswordResetRequiredStorage);
    private showPasswordChangePopup = false;
    private daysToRequiredChange: number;

    public get canBeShown(): boolean {
        return this.showPasswordChangePopup && !this.passwordResetRequiredStorage.getPasswordResetRequired();
    }

    public init(showPasswordChangePopup: boolean, passwordExpirationDateTimeUtc: string): void {
        this.showPasswordChangePopup = showPasswordChangePopup && !!passwordExpirationDateTimeUtc;
        this.daysToRequiredChange = daysUntilDate(new Date(passwordExpirationDateTimeUtc));
    }

    public prompt(): ConfirmationDialogRef {
        return this.confirmationDialog.open({
            title: 'Reminder',
            content: getPromptText(this.daysToRequiredChange),
            deny: { label: 'Later' },
            confirm: { label: 'Change Password' },
            closeOnNavigation: false,
        });
    }
}
